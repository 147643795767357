import React from 'react'
import { inject, observer } from 'mobx-react'
import { DropdownMenu, FontIcon, MenuItem, Chip, ListSubheader } from 'react-md'
import { ListItem, Text, Card, Button, Dialog, DialogHeader, DialogContent, DialogTitle, DialogFooter } from 'react-md'

const Notification = (props, match, history) => {
    var notificationData = []
    var items = []
    try {
        notificationData = JSON.parse(localStorage.getItem('notification'))
        notificationData.map((d, index) => {
            if(index < 5){
                items.push(
                    <ListItem
                        leftAddon={d.status ? <Chip style={{ color: 'green' }}>{d.status}</Chip> : ''}
                        primaryText={d.type}
                        secondaryText={d.message}
                    ></ListItem>
                )
            }
        })
    } catch (e) { }
    var companyId = localStorage.getItem("cpId")
    return (
        <div className="mpk-notification">
            <DropdownMenu
                id="mpk-notification-menu"
                items={[
                    <ListSubheader>List Notification</ListSubheader>,
                    ...items,
                    <ListItem primaryText="Load More ... " onClick={()=> {
                       window.location.href = `/product/company/${companyId}/daftar-spt/notification`
                    }}></ListItem>
                ]}
                disableDropdownIcon
            >
                <FontIcon iconClassName="mdi mdi-bell" />
            </DropdownMenu>
        </div>
    )
}

export default inject('envStore', 'navigationStore')(observer(Notification));
