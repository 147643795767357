import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./TransaksiPembelian.service";
import KategoriPPh25Service from "../../adminpajakku/kategoriPph25/KategoriPph25.service";
import TipePengecualianPajakService from "../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service";
import Utils from "../../../libs/custom/Utils";
import AkunService from "../../master/Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import AsetService from "../Aset/Aset.service";
import TransaksiService from "./Transaksi.service";
import LawanTransaksiService from "../../master/LawanTransaksi/LawanTransaksi.service";
import EntitasService from "../../master/Entitas/Entitas.service";
import ItemService from "../../master/Item/Item.service";
import { toast } from "../../../libs/react-mpk/services";
import { TabsWrapper } from "../../../libs/react-mpk/wrapper";
import TransaksiPajakTerutangTable from "./TransaksiPajakTerutang.table";
import TransaksiPembelianAkunTable from "./TransaksiPembelianAkun.table";
import TransaksiPajakWpTable from "./TransaksiPajakWp.table";
import TransaksiPerjanjianPembelianService from "./TransaksiPerjanjianPembelian.service";
import { Button, FontIcon, TextIconSpacing } from "react-md";
import Modal from "../../../libs/react-mpk/components/Modal";
import { DataForm } from "../../../libs/react-mpk/components";

const TransaksiPembelianForm = ({
  envStore,
  match,
  history,
  sipStore,
  modalStore,
  navigationStore,
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({ loading: true, content: {} });
  const [entitasList, setEntitasList] = useState([]);
  const [lawanTransaksiList, setLawanTransaksiList] = useState([]);
  const [lawanTransaksiMap, setLawanTransaksiMap] = useState({});
  const [itemList, setItemList] = useState([]);
  const [itemMap, setItemMap] = useState({});
  const [perjanjianList, setPerjanjianList] = useState([]);
  const [perjanjianMap, setPerjanjianMap] = useState({});
  const [curData, setCurData] = useState({});

  // initial data
  useEffect(() => {
    async function initData() {
      await _getLawanTransaksi();
      await _getEntitasList();
      await _getItemList();
      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            perusahaanId: sipStore.companyId,
            periodeFiskalId: sipStore.periodeId,
            dipungut: false,
            dpp: "0",
            pph: "0",
            transaksiAktivaTetapAkun__nilai: "0",
            transaksiAktivaTetapPengecualians: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      if (!res.data.items) res.data.items = [];
      res.data.items = res.data.items.map((x) => Utils.formSerialize(x));
      res.data = Utils.formSerialize(res.data);
      setCurData(res.data);
      setIsEdit(true);
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  async function _getLawanTransaksi() {
    var res = await LawanTransaksiService.get({
      size: 10000,
      "perusahaanId.equals": sipStore.companyId,
    });
    setLawanTransaksiList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
    setLawanTransaksiMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _getPerjanjian(lawanTransaksiId) {
    var res = await TransaksiPerjanjianPembelianService.get({
      size: 10000,
      "lawanTransaksiId.equals": lawanTransaksiId,
    });
    setPerjanjianList(
      res.data.map((x) => ({
        value: x.id,
        label:
          x.nomor + " " + x.tanggal + " " + (x.keterangan ? x.keterangan : ""),
      }))
    );
    setPerjanjianMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _getEntitasList() {
    var res = await EntitasService.get({
      size: 10000,
      "perusahaanId.equals": sipStore.companyId,
    });
    setEntitasList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getItemList() {
    var res = await ItemService.get({
      size: 10000,
      "perusahaanId.equals": sipStore.companyId,
    });
    setItemList(
      res.data.map((x) => ({
        value: x.id,
        label: x.kode ? `${x.kode} - ${x.nama}` : `${x.nama}`,
      }))
    );
    setItemMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _generateDariPerjanjian() {
    console.log(curData);
    var items = [];

    curData.sumberDokumen.forEach((x) => {
      var d = perjanjianMap[x.perjanjianPembelianId];
      d.items.forEach((item) => {
        items.push({
          nama: item.nama,
          kode: item.kode,
          itemId: item.itemId,
          keterangan: item.keterangan,
          diskon: item.diskon,
          dpp: item.hargaTotal,
          hargaSatuan: item.hargaSatuan,
          hargaTotal: item.hargaTotal,
          ppn: 0,
          ppnBm: 0,
          qty: item.qty,
          tarifPpnBm: 0,
        });
      });
    });
    curData.items = items;

    FormWrapper.reload("transaksi-pembelian-form", curData);
  }

  async function uploadAttachment() {
    modalStore.showConfirm({
      title: "Upload Attachment",
      children: (
        <DataForm
          baseId={"attachment-upload"}
          hideActionButton
          defaultData={{}}
          definitions={[
            {
              inputType: FormWrapper.inputTypes.FILE_INPUT,
              label: "File",
              key: "file",
              theme: "filled",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              type: "text",
              width: "100%",
            },
          ]}
          // submitLabel="Import Data"
          onSubmit={async (d, callback) => {
            var formData = new FormData();
            formData.set("file", d.file);
            var uploadRes = await service.http.post(
              "/upload-attachment",
              formData
            );
            if (uploadRes.data) {
              var data = { ...curData };
              if (!data.pembelianAttachment) data.pembelianAttachment = [];
              data.pembelianAttachment.push({
                filename: uploadRes.data.name,
                originalFilename: uploadRes.data.origin,
                keterangan: d.keterangan,
              });
              FormWrapper.reload("transaksi-pembelian-form", data);
              setCurData(data);
            }
            callback("Data berhasil di upload", false);
          }}
        />
      ),
      onSubmit: async (callback) => {
        const f = document.getElementById(`attachment-upload-form-trigger`);
        if (f) f.click();
        callback();
      },
    });
  }

  async function importItem() {
    modalStore.showConfirm({
      title: "Upload Barang/Jasa",
      children: (
        <DataForm
          baseId={"import-upload"}
          hideActionButton
          defaultData={{}}
          definitions={[
            {
              inputType: FormWrapper.inputTypes.FILE_INPUT,
              label: "File",
              key: "file",
              theme: "filled",
              style: { width: 200 },
            },
          ]}
          // submitLabel="Import Data"
          onSubmit={async (d, callback) => {
            var formData = new FormData();
            formData.set("file", d.file);
            var uploadRes = await service.http.post("/upload", formData);
            if (uploadRes.data) {
              var data = { ...curData };
              var importRes = await service.http.get(
                `/pembelian-item-import/${uploadRes.data.name}/${sipStore.companyId}`
              );

              var data = { ...curData };
              data.items = importRes.data;
              FormWrapper.reload("transaksi-pembelian-form", data);
              setCurData(data);
            }
            callback("Data berhasil di upload", false);
          }}
        />
      ),
      onSubmit: async (callback) => {
        const f = document.getElementById(`import-upload-form-trigger`);
        if (f) f.click();
        callback();
      },
    });
  }

  const form = (
    <FormWrapper
      noValidate
      showCommandbar={match.params.id == "baru"}
      loading={data.loading}
      actions={
        [
          // {
          //   label: 'Generate',
          //   iconClassName: 'mdi mdi-inbox-full',
          //   onClick: async () => {
          //     modalStore.showConfirm({
          //       children: 'Generate data pajak terutang?',
          //       onRequestClose: ()=> {modalStore.closeConfirm()},
          //       onSubmit: async (callback) => {
          //         await service.http.get(`/pos/${match.params.id}/generate`);
          //         toast.success(`Data pajak terutang telah di generate.`);
          //         callback()
          //       }
          //     })
          //   }
          // }
        ]
      }
      backTo={"/onpremise/transaksi-pembelian"}
      baseId="transaksi-pembelian-form"
      title="Transaksi Pembelian"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if (key === "pembelianLawanTransaksi__sumberId") {
          var lt = lawanTransaksiMap[value];
          if (lt) {
            newObj.pembelianLawanTransaksi__jenisPemungutPPh22 = "";
            newObj.pembelianLawanTransaksi__nama = lt.nama;
            newObj.pembelianLawanTransaksi__nik = lt.nik;
            newObj.pembelianLawanTransaksi__npwp = lt.npwp;
            newObj.pembelianLawanTransaksi__pemungut = "";
            newObj.pembelianLawanTransaksi__sumber = "LawanTransaksi";
            newObj.pembelianLawanTransaksi__sumberId = lt.id;
            newObj.pembelianLawanTransaksi__tipeWajibPajak = lt.tipeWajibPajak;
            newObj.pembelianLawanTransaksi__domisiliWajibPajak =
              lt.domisiliWajibPajak;

            _getPerjanjian(value);
          }
        }
        if (key === "itemId") {
          var item = itemMap[value];
          if (item) {
            newObj.items[indexAtParent].nama = item.nama;
            newObj.items[indexAtParent].kode = item.kode;
          }
        }

        if (key == "hargaSatuan" || key == "qty" || key == "diskon") {
          newObj.items[indexAtParent][key] = parseInt(value);
          newObj.items[indexAtParent].hargaTotal =
            parseInt(newObj.items[indexAtParent].qty) *
              parseInt(newObj.items[indexAtParent].hargaSatuan) -
            parseInt(newObj.items[indexAtParent].diskon);
          newObj.items[indexAtParent].dpp =
            newObj.items[indexAtParent].hargaTotal;
        }

        if (key === "perjanjianPembelianId") {
          var pMap = perjanjianMap[value];

          if (pMap) {
            newObj.sumberDokumen[indexAtParent].nomorPerjanjian = pMap.nomor;
            newObj.sumberDokumen[indexAtParent].keterangan = pMap.keterangan;
          }
        }

        setCurData(newObj);
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: "Entitas",
          key: "entitasId",
          type: "text",
          required: true,
          options: entitasList,
          validation: "required",
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nomor Transaksi",
          key: "nomor",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          disabled: isEdit,
          validation: "required",
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal Transaksi",
          key: "tanggal",
          width: "49%",
          // style: {marginRight: '2%'},
          validation: "required",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Keterangan",
          key: "keterangan",
          type: "text",
          width: "100%",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Lawan Transaksi",
          key: "pembelianLawanTransaksi__sumberId",
          type: "text",
          // width: '49%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          options: lawanTransaksiList,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nama Lawan Transaksi",
          key: "pembelianLawanTransaksi__nama",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: "##.###.###.#-###.###",
          maskChar: "_",
          maskValue: "string",
          label: "NPWP Lawan Transaksi",
          key: "pembelianLawanTransaksi__npwp",
          type: "text",
          width: "49%",
          validation: "required",
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: "################",
          maskChar: "_",
          maskValue: "string",
          label: "NIK Lawan Transaksi",
          key: "pembelianLawanTransaksi__nik",
          type: "text",
          width: "49%",
          disabled: true,
          validation: [
            { required_if: ["pembelianLawanTransaksi__tipeWajibPajak", "OP"] },
          ],
          show: (d) => d.pembelianLawanTransaksi__domisiliWajibPajak == "DN",
        },
        {
          inputType: inputTypes.DIVIDER,
          // label: "Harga Total",
          key: "divider_3",
        },
        {
          inputType: inputTypes.INPUT,
          // mask: '################',
          // maskChar: '_',
          // maskValue: 'string',
          label: "Passport Lawan Transaksi",
          key: "pembelianLawanTransaksi__nik",
          type: "text",
          width: "49%",
          disabled: true,
          validation: [
            { required_if: ["pembelianLawanTransaksi__tipeWajibPajak", "OP"] },
          ],
          show: (d) => d.pembelianLawanTransaksi__domisiliWajibPajak == "LN",
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "# SUMBER DOKUMEN",
          key: "sumberDokumen",
          // hintMessage: 'Daftar Tarif',
          layout: "table",
          style: { minWidth: 1000 },
          defaultData: {
            keterangan: "-",
            nomorPerjanjian: "",
            pembelianId: "",
            perjanjianPembelianId: "",
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "PKS/PO",
              key: "perjanjianPembelianId",
              theme: "filled",
              style: { width: 1000 },
              validation: "required",
              options: perjanjianList,
            },
          ],
        },

        {
          inputType: inputTypes.DIVIDER,
          // label: "Harga Total",
          key: "divider_2",
        },

        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "# BARANG / JASA",
          key: "items",
          // hintMessage: 'Daftar Tarif',
          layout: "table",
          style: { minWidth: 1600 },
          extraAction: () => {
            return (
              <>
                <Button
                  themeType="outline"
                  className="mpk-margin-S margin-right"
                  onClick={() => {
                    importItem();
                  }}
                >
                  <TextIconSpacing
                    icon={<FontIcon iconClassName="mdi mdi-plus" />}
                  >
                    Import
                  </TextIconSpacing>
                </Button>
                <Button
                  themeType="outline"
                  className="mpk-margin-S margin-right"
                  onClick={() => {
                    _generateDariPerjanjian();
                  }}
                >
                  <TextIconSpacing
                    icon={<FontIcon iconClassName="mdi mdi-plus" />}
                  >
                    Generate dari data PKS/PO
                  </TextIconSpacing>
                </Button>
              </>
            );
          },
          defaultData: {
            itemId: "",
            keterangan: "-",
            diskon: 0,
            dpp: 0,
            hargaSatuan: 0,
            hargaTotal: 0,
            ppn: 0,
            ppnBm: 0,
            qty: 0,
            tarifPpnBm: 0,
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Barang/Jasa",
              key: "itemId",
              theme: "filled",
              style: { width: 600 },
              validation: "required",
              options: itemList,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Harga Satuan",
              key: "hargaSatuan",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "QTY",
              key: "qty",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Diskon",
              key: "diskon",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Harga Total",
              key: "hargaTotal",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              style: { width: 200 },
              disabled: true,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "DPP",
              key: "dpp",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              style: { width: 200 },
              // disabled: true,
            },
          ],
        },

        {
          inputType: inputTypes.DIVIDER,
          // label: "Harga Total",
          key: "divider_1",
        },

        // Below is field for attachments
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "# ATTACHMENT",
          key: "pembelianAttachment",
          // hintMessage: 'Daftar Tarif',
          layout: "table",
          style: { minWidth: 1600 },
          hideAddButton: true,
          defaultData: {
            keterangan: "-",
            filename: "",
            originalFilename: "",
          },
          extraAction: () => {
            return (
              <Button
                themeType="outline"
                className="mpk-margin-S margin-right"
                onClick={() => {
                  uploadAttachment();
                }}
              >
                <TextIconSpacing
                  icon={<FontIcon iconClassName="mdi mdi-plus" />}
                >
                  Upload Attachment
                </TextIconSpacing>
              </Button>
            );
          },
          definitions: [
            {
              inputType: inputTypes.INPUT,
              label: "Nama file",
              key: "filename",
              type: "text",
              disabled: true,
              // width: "100%",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT,
              label: "Nama file orifinal",
              key: "originalFilename",
              disabled: true,
              type: "text",
              // width: "100%",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              type: "text",
              // width: "100%",
              style: { width: 200 },
            },
          ],
        },

        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Pilih Aset',
        //   key: 'transaksiAktivaTetapAset__asetid',
        //   type: 'text',

        //   required: true,
        //   validation: 'required',
        //   options:asetList,
        // }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        try {
          data.transaksiId = match.params.transaksiId;
          data = Utils.formNormalize(data);
          // data.transaksiAktivaTetapAkun.akun = {
          //   id: data.transaksiAktivaTetapAkun__akunid
          // }
          data.items = data.items.map((x) => Utils.formNormalize(x));
          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push("/onpremise/transaksi-pembelian");
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );

  if (match.params.id != "baru") {
    return (
      <TabsWrapper
        title="Transaksi Pembelian"
        showCommandbar={true}
        baseId="mod-details-sample-aad"
        actions={[
          {
            label: "Back",
            iconClassName: "mdi mdi-arrow-left",
            onClick: () => history.push("/onpremise/transaksi-pembelian"),
          },
        ]}
        onChange={(tab, d) => {
          navigationStore.redirectTo(
            `/onpremise/transaksi-pembelian/${match.params.id}/${tab.key}`
          );
          d();
        }}
        // tabs={tabs}
        tabs={[
          {
            label: "Data",
            key: "form",
            render: form,
          },
          {
            label: "Pajak",
            key: "pajak-terutang",
            render: (
              <TransaksiPajakTerutangTable
                jenisMap={{}}
                history={history}
                transaksiId={null}
                pembelianId={match.params.id}
              />
            ),
          },
          {
            label: "Pajak Aplikasi WP",
            key: "pajak-wp",
            render: (
              <TransaksiPajakWpTable
                jenisMap={{}}
                history={history}
                transaksiId={null}
                pembelianId={match.params.id}
              />
            ),
          },
          {
            label: "Akun",
            key: "akun",
            render: (
              <TransaksiPembelianAkunTable
                history={history}
                pembelianId={match.params.id}
              />
            ),
          },
        ]}
      />
    );
  } else {
    return form;
  }
};

export default inject(
  "envStore",
  "sipStore",
  "modalStore",
  "navigationStore"
)(observer(TransaksiPembelianForm));
