import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import DashboardService from "./DashboardService";
import { DataForm } from "../../../libs/react-mpk/components";
import moment from "moment";

import {
  SortOrder,
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Caption,
} from "@react-md/table";
import { FontIcon } from "@react-md/icon";
import {
  AppBar,
  AppBarNav,
  AppBarTitle,
  AppBarAction,
} from "@react-md/app-bar";
import { useState } from "react";
import { Line, Circle } from "rc-progress";
import Utils from "../../../libs/custom/Utils";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "react-md";

function EkualisasiJurnalView({ sipStore, history }) {
  const [data, setData] = useState({
    loading: true,
    content: [],
    sortKey: "nama",
    sortOrder: "ascending",
    total: 0,
    param: {},
  });

  const [toggleFilter, setToggleFilter] = useState(false);

  useEffect(() => {
    initData("nama", {
      perusahaanId: sipStore.companyId,
      from: moment().startOf("month").format("YYYY-MM-DD"),
      to: moment().endOf("month").format("YYYY-MM-DD"),
      type: "Pajak Terutang",
    });
  }, []);

  async function initData(sortKey, param) {
    var d = data.content;
    if (param) {
      var res = await DashboardService.ekualisasiJurnal(sipStore.periodeId);
      d = res.data;
    }

    setData((prevState) => {
      const prevSortKey = prevState.sortKey;
      const prevSortOrder = prevState.sortOrder;

      let sortOrder;
      if (sortKey === prevSortKey) {
        // it's the same column, so toggle the sort order
        sortOrder = prevSortOrder === "ascending" ? "descending" : "ascending";
      } else {
        // it's a new column to sort by, so default to ascending for the name column
        // but descending for all the rest.
        sortOrder = sortKey === "nama" ? "ascending" : "descending";
      }

      console.log(sortKey, "asdasd");

      return {
        content: d.sort((a, b) => {
          const aValue = a[sortKey];
          const bValue = b[sortKey];
          const value =
            typeof aValue === "number"
              ? aValue - bValue
              : "" + aValue.localeCompare(bValue);
          return value * (sortOrder === "ascending" ? 1 : -1);
        }),
        sortKey,
        sortOrder,
        param,
        loading: false,
        total: d.reduce((pv, cv) => {
          pv = pv + cv.totalPph;
          return pv;
        }, 0),
      };
    });
  }

  const { content, sortKey, sortOrder } = data;

  return (
    <Card
      raiseable={true}
      // className="mpk-form-wrapper mpk-paper  padding-all mpk-animation slide-in"
      style={{
        width: "100%",
        maxWidth: 1200,
        margin: "0px auto",
        borderTop: "",
      }}
    >
      <CardHeader
        beforeChildren={
          <Avatar>
            <FontIcon iconClassName="mdi mdi-wallet" />
          </Avatar>
        }
      >
        <CardTitle>Dashboard Ekualisasi Transaksi Jurnal</CardTitle>
        <CardSubtitle>Pengenaan Pajak Catatan User dan Catatan pada apllikasi SIP</CardSubtitle>
      </CardHeader>
      {/* <AppBar theme="default">
        <AppBarTitle className="rmd-typography--capitalize">
          PEMBELIAN
        </AppBarTitle> */}

      {/* <AppBarAction first aria-label="Actions" onClick={()=> {setToggleFilter(!toggleFilter)}}>
          <FontIcon iconClassName="mdi mdi-filter" />
        </AppBarAction> */}
      {/* </AppBar> */}
      <div className="mpk-padding-N" style={{ height: 600, overflow: "auto" }}>
        {/* {toggleFilter && (
          <DataForm
            baseId={"pegawai-import"}
            // asDialog={true}
            defaultData={data.param}
            definitions={[
              {
                inputType: DataForm.inputTypes.DATEPICKER,
                label: "Dari Tanggal",
                key: "from",
                theme: "filled",
                validation: "required",
                style: { marginRight: "2%", width: "49%" },
              },
              {
                inputType: DataForm.inputTypes.DATEPICKER,
                label: "Ke Tanggal",
                key: "to",
                theme: "filled",
                validation: "required",
                style: { width: "49%" },
              },
              {
                inputType: DataForm.inputTypes.SELECT,
                label: "Kategori Pajak",
                key: "type",
                theme: "filled",
                validation: "required",
                options: [
                  { label: "Pajak Terutang", value: "Pajak Terutang" },
                  {
                    label: "Pajak Dibayar Dimuka",
                    value: "Pajak Dibayar Dimuka",
                  },
                ],
                style: { width: "29%" },
              },
            ]}
            // visible={uploadModal}
            submitLabel="Cari"
            onSubmit={async (d, callback) => {
              try {
                await initData(data.sortKey, {
                  ...d,
                  perusahaanId: sipStore.companyId,
                });
                callback();
              } catch (e) {
                console.log(e.response);
                callback(e.response.data.message);
              }
            }}
          />
        )} */}
        <TableContainer>
          <Table fullWidth className="bordered">
            {/* <Caption>{data.param.from} - {data.param.to} {data.param.type}</Caption> */}
            <TableHeader>
              <TableRow>
                <TableCell key="action" rowSpan={3}></TableCell>
                <TableCell
                  rowSpan={3}
                  key="nama"
                  aria-sort={"nama" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nama")}
                >
                  AKUN
                </TableCell>
                <TableCell
                  rowSpan={3}
                  key="nomor"
                  aria-sort={"nomor" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nomor")}
                >
                  NOMOR
                </TableCell>
                <TableCell
                  rowSpan={3}
                  key="selisih"
                  // aria-sort={"nomor" === sortKey ? sortOrder : "none"}
                  // onClick={() => initD/ata("nomor")}
                >
                  SELISIH
                </TableCell>
                {/* <TableCell
                  rowSpan={3}
                  key="nilaiJurnal"
                  aria-sort={"nilaiJurnal" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nilaiJurnal")}
                  style={{ textAlign: "right" }}
                >
                  NILAI LAPORAN KEUANGAN
                </TableCell> */}

                <TableCell
                  colSpan={11}
                  key="nilaiDipotongPajak"
                  // onClick={() => initData("nilaiDipotongPajak")}
                  style={{ textAlign: "center" }}
                >
                  PENGENAAN PAJAK MENURUT SIP
                </TableCell>
                <TableCell
                  colSpan={11}
                  key="nilaiDipotongPajak"
                  // onClick={() => initData("nilaiDipotongPajak")}
                  style={{ textAlign: "center" }}
                >
                  PENGENAAN PAJAK MENURUT SYSTEM WAJIB PAJAK
                </TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell
                  rowSpan={2}
                  key="nilaiDipotongPajak"
                  aria-sort={
                    "nilaiDipotongPajak" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("nilaiDipotongPajak")}
                  style={{ textAlign: "right" }}
                >
                  DIPOTONG PAJAK
                </TableCell> */}

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center " }}
                >
                  PPh 4(2)
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 15
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 21
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 22
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 23
                </TableCell>



                <TableCell
                  rowSpan={2}
                  key="nilaiJurnal"
                  aria-sort={"nilaiJurnal" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nilaiJurnal")}
                  style={{ textAlign: "right" }}
                >
                  BELUM/TIDAK DIPOTONG PAJAK
                </TableCell>
                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center " }}
                >
                  PPh 4(2)
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 15
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 21
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 22
                </TableCell>

                <TableCell
                  colSpan={2}
                  style={{ textAlign: "center" }}
                >
                  PPh 23
                </TableCell>



                <TableCell
                  rowSpan={2}
                  key="nilaiJurnal"
                  aria-sort={"nilaiJurnal" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nilaiJurnal")}
                  style={{ textAlign: "right" }}
                >
                  BELUM/TIDAK DIPOTONG PAJAK
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  key="pph4a2Dpp"
                  aria-sort={
                    "pph4a2Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph4a2Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph4a2Pph"
                  aria-sort={
                    "pph4a2Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph4a2Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph15Dpp"
                  aria-sort={
                    "pph15Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph15Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph15Pph"
                  aria-sort={
                    "pph15Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph15Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph21Dpp"
                  aria-sort={
                    "pph21Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph21Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph21Pph"
                  aria-sort={
                    "pph21Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph21Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph22Dpp"
                  aria-sort={
                    "pph22Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph22Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph22Pph"
                  aria-sort={
                    "pph22Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph22Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph23Dpp"
                  aria-sort={
                    "pph23Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph23Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph23Pph"
                  aria-sort={
                    "pph23Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph23Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>
                <TableCell
                  key="pph4a2Dpp"
                  aria-sort={
                    "pph4a2Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph4a2Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph4a2Pph"
                  aria-sort={
                    "pph4a2Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph4a2Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph15Dpp"
                  aria-sort={
                    "pph15Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph15Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph15Pph"
                  aria-sort={
                    "pph15Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph15Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph21Dpp"
                  aria-sort={
                    "pph21Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph21Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph21Pph"
                  aria-sort={
                    "pph21Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph21Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph22Dpp"
                  aria-sort={
                    "pph22Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph22Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph22Pph"
                  aria-sort={
                    "pph22Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph22Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>

                <TableCell
                  key="pph23Dpp"
                  aria-sort={
                    "pph23Dpp" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph23Dpp")}
                  style={{ textAlign: "right" }}
                >
                  DPP
                </TableCell>
                <TableCell
                  key="pph23Pph"
                  aria-sort={
                    "pph23Pph" === sortKey ? sortOrder : "none"
                  }
                  onClick={() => initData("pph23Pph")}
                  style={{ textAlign: "right" }}
                >
                  Pph
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.content.map((v, i) => {
                var c = colors[getRandomInt(34, 0)];
                return (
                  <TableRow>
                    <TableCell>
                      <Button
                        buttonType="icon"
                        aria-label="More"
                        onClick={() => {
                          history.push("/onpremise/transaksi-pembelian?akunId=" + v.id+"&title="+v.nama);
                        }}
                      >
                        <FontIcon>more</FontIcon>
                      </Button>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.nama}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.nomor}</div>
                    </TableCell>
                    <TableCell style={{background: v.pph4a2Pph === v.pph4a2WpPph && v.pph15Pph === v.pph15WpPph && v.pph21Pph === v.pph21WpPph && v.pph22Pph === v.pph22WpPph && v.pph23Pph === v.pph23WpPph ? '#a2cf6e':'#ff784e'}}>
                      <div style={{}}>{v.pph4a2Pph === v.pph4a2WpPph && v.pph15Pph === v.pph15WpPph && v.pph21Pph === v.pph21WpPph && v.pph22Pph === v.pph22WpPph && v.pph23Pph === v.pph23WpPph ? 'Tidak':'Ya'}</div>
                    </TableCell>
                    {/* <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.nilaiJurnal)}
                    </TableCell> */}

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph4a2Dpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph4a2Pph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph15Dpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph15Pph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph21Dpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph21Pph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph22Dpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph22Pph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph23Dpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph23Pph)}
                    </TableCell>


                    {/* <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.nilaiDipotongPajak)}
                    </TableCell> */}
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(
                        v.nilaiJurnal - (v.pph4a2Dpp+v.pph15Dpp+v.pph21Dpp+v.pph22Dpp+v.pph23Dpp)
                      )}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph4a2WpDpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph4a2WpPph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph15WpDpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph15WpPph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph21WpDpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph21WpPph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph22WpDpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph22WpPph)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph23WpDpp)}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.pph23WpPph)}
                    </TableCell>


                    {/* <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.nilaiDipotongPajak)}
                    </TableCell> */}
                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(
                        v.nilaiJurnal - (v.pph4a2WpDpp+v.pph15WpDpp+v.pph21WpDpp+v.pph22WpDpp+v.pph23WpDpp)
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br />
    </Card>
  );
}

export default inject(
  "sipStore",
  "authStore"
)(observer(EkualisasiJurnalView));

const colors = [
  "#ef5350",
  "#ff1744",
  "#ec407a",
  "#f50057",
  "#ab47bc",
  "#d500f9",
  "#7e57c2",
  "#651fff",
  "#5c6bc0",
  "#3d5afe",
  "#42a5f5",
  "#2979ff",
  "#29b6f6",
  "#00b0ff",
  "#26c6da",
  "#00e5ff",
  "#26a69a",
  "#1de9b6",
  "#66bb6a",
  "#00e676",
  "#9ccc65",
  "#76ff03",
  "#d4e157",
  "#c6ff00",
  "#ffee58",
  "#ffea00",
  "#ffca28",
  "#ffc400",
  "#ffa726",
  "#ff9100",
  "#ff7043",
  "#ff3d00",
  "#8d6e63",
  "#bdbdbd",
  "#78909c",
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
