import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './SandingPPh21Transaksi.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'
import { inject, observer } from 'mobx-react'
import AkunService from '../../master/Akun/Akun.service'

var _baseId = "mod-table-sanding-aset"

const SandingPPh21TransaksiTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  sipStore,
  navigationStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [akun, setAkun] = useState({})

  useEffect(() => {
    getAkun()
  }, [])

  const getAkun = async () => {
    var res = await AkunService.getOne(match.params.akunId)
    setAkun(res.data)
  }

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={'Detail Transaksi : '+akun.nama}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="namaAkun"
      columns={[
        {
          label: 'Nama Akun',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.akunNama)
        },
        {
          label: 'Nomor Akun',
          searchable: true,
          key: 'nomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.akunNomor)
        },
        {
          label: 'Nomor Transaksi',
          searchable: true,
          key: 'transaksiNomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.transaksiNomor)
        },
        {
          label: 'Kelengkapan Data',
          searchable: true,
          key: 'kelengkapanData',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.kelengkapanData
        },
        {
          label: 'Lawan Transaksi',
          searchable: true,
          key: 'lawanTransaksi',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.lawanTransaksi
        },
        {
          label: 'kode Objek Pajak',
          searchable: true,
          key: 'kodeObjekPajak',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.kodeObjekPajak
        },
        {
          label: 'keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.keterangan
        },
        {
          label: 'Nominal',
          searchable: true,
          key: 'nominal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.nominal))
        },

      ]}
      actions={[
        // new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/penyusutan-periode-aset/baru'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Detail Per Transaksi', 'mdi mdi-book-open', (item) => {
          history.push(`/onpremise/sanding-pph-transaksi/${item.id}`)
        }, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.periodeId) {
          query['periodeFiskalId.equals'] = sipStore.periodeId
        }
        query['akunId.equals'] = match.params.akunId
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}


export default inject('sipStore', 'navigationStore')(observer(SandingPPh21TransaksiTable))
