import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './SubSektor.service'
import SektorService from '../sektor/Sektor.service'
import Utils from '../../../libs/custom/Utils'

const SubSektorForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [sektor, setSektor] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      _getSektor()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {}})
        return true
      }
      const res = await service.getOne(match.params.id)
      Utils.formNormalize(res.data);
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  const _getSektor = async ()=> {
    var param = {}
    param.size = 10000;
    var res = await SektorService.get(param);

    setSektor(res.data);
  }

  return (
    <FormWrapper
      noValidate
      customRules={{
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/sub-sektor`}
      baseId="mod-form-sample"
      title={'Sub Sektor'}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Sektor',
          key: 'sektorId',
          type: 'text',
          required: true,
          options: sektor.map(p => ({label: p.nama, value: p.id})),
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          required: true,
          disabled: isEdit
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode ',
          key: 'kode',
          type: 'text',
          width: '100%',
          required: true,
          // disabled: isEdit
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        data = Utils.formNormalize(data);
        try {
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/sub-sektor')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(SubSektorForm))
