import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import VTransaksiItemTable from './VTransaksiItem.table'
import VTransaksiAkunTable from './VTransaksiAkun.table'
import VTransaksiAktivaTetapTable from './VTransaksiAktivaTetap.table'

const VTransaksiWrapper = ({envStore, match, history, sipStore, navigationStore}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  return <TabsWrapper
    title="Transaksi Penjualan"
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-details-migrasi"
    actions={[
      // {name: 'Add', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/onpremise/migrasi-transkrip')}
    ]}
    tabs={[
      {
        label: 'Transaksi Penjualan (Item)',
        key: 'transaksi-item',
        render: <VTransaksiItemTable match={match} history={history} />
      },
      {
        label: 'Transaksi Penjualan (Akun)',
        key: 'transaksi-akun',
        render: <VTransaksiAkunTable match={match} history={history} />
      },
      {
        label: 'Transaksi Penjualan (Aktiva Tetap)',
        key: 'transaksi-akun',
        render: <VTransaksiAktivaTetapTable match={match} history={history} />
      }
    ]}
  />
}

export default inject('sipStore', 'navigationStore')(observer(VTransaksiWrapper))
