import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Transaksi.service'
import Utils from '../../../libs/custom/Utils'
import TipeIndustriListService from '../../adminpajakku/tipeIndustri/TipeIndustri.service'
import NegaraService from '../../adminpajakku/negara/Negara.service'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import EntitasService from '../../master/Entitas/Entitas.service'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import LawanTransaksiService from '../../master/LawanTransaksi/LawanTransaksi.service'
import AkunService from '../../master/Akun/Akun.service'
import TransaksiPajakTerutangTable from './TransaksiPajakTerutang.table'
import TransaksiKoreksiFiskalTable from './TransaksiKoreksiFiskal.table'
import TransaksiBebanPromosiTable from './TransaksiBebanPromosi.table'
import TransaksiBebanEntertainmentTable from './TransaksiBebanEntertainment.table'
import TransaksiReferensiHutangTable from './TransaksiReferensiHutang.table'
import TransaksiReferensiPembayaranHutangTable from './TransaksiReferensiPembayaranHutang.table'
import TransaksiLabaRugiTable from './TransaksiLabaRugi.table'
import TransaksiAktivaTetapTable from './TransaksiAktivaTetap.table'
import TransaksiPenjualanAktivaTetapTable from './TransaksiPenjualanAktivaTetap.table'
import TransaksiReferensiPiutangTable from './TransaksiReferensiPiutang.table'
import TransaksiReferensiPembayaranPiutangTable from './TransaksiReferensiPembayaranPiutang.table'
import TransaksiPerubahanModalTable from './TransaksiPerubahanModal.table'
import moment from 'moment'
import iziToast from 'izitoast'
import PeriodeFiskalService from '../PeriodeFiskal/PeriodeFiskal.service'
import TransaksiPosTable from './TransaksiPos.table'
import queryString from 'query-string'
import TransaksiPajakWpTable from './TransaksiPajakWp.table'

const TransaksiForm = ({envStore, match, history, sipStore, navigationStore, location}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [curData, setCurData] = useState({})
  const [tipeIndustriList, setTipeIndustriList] = useState([])
  const [negaraList, setNegaraList] = useState([])
  const [akunList, setAkunList] = useState([])
  const [entitasList, setEntitasList] = useState([])
  const [perusahaanList, setPerusahaanList] = useState([])
  const [lawanTransaksiList, setLawanTransaksiList] = useState([])
  const [lawanTransaksiMap, setLawanTransaksiMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [periodeFiskalList, setPeriodeFiskalList] = useState([])
  const [jenisMap, setJenisMap] = useState({
    KOREKSI_FISKAL: false,
    BEBAN_ENTERTAINMENT: false,
    BEBAN_PROMOSI: false,
    PEMBELIAN_ASET: false,
    PENJUALAN_ASET: false,
    REFERENSI_HUTANG: false,
    PEMBAYARAN_HUTANG: false,
    REFERENSI_PEMBAYARAN_PIUTANG: false,
    REFERENSI_PEMBAYARAN_HUTANG: false,
    REFERENSI_PIUTANG: false,
    TRANSAKSI_PENJUALAN: false,
    PERUBAHAN_MODAL: false
  })

  const [query, setQuery] = useState({})
  const [backTo, setBackTo] = useState(`/onpremise/transaksi`)

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getPeriodeFiskalList()
      await _getEntitasList()
      await _getLawanTransaksi()
      await _getAkun()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          pembelian: false,
          perusahaanId: sipStore.companyId,
          periodeFiskal: {id: sipStore.periodeId},
          periodeFiskal__id: sipStore.periodeId,
          transaksiBebanEntertainmentDTOS: [],
          transaksiJurnals: [],
          transaksiKoreksiFiskalDTOS: [],
          transaksiPajakTerutangs: [],
          transaksiReferensiHutangDTOS: []
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      res.data.transaksiJurnals.forEach((d, i)=> {
        Utils.formSerialize(d)
      })
      setData({loading: false, content:res.data})
      localStorage.setItem("tgl", JSON.stringify(res.data.tanggal))

      if(res.data.tags) {
        setJenisMap({
          KOREKSI_FISKAL: res.data.tags.search("KOREKSI_FISKAL") >= 0,
          BEBAN_ENTERTAINMENT: res.data.tags.search("BEBAN_ENTERTAINMENT") >= 0,
          BEBAN_PROMOSI: res.data.tags.search("BEBAN_PROMOSI") >= 0,
          PEMBELIAN_ASET: res.data.tags.search("PEMBELIAN_ASET") >= 0,
          PENJUALAN_ASET: res.data.tags.search("PENJUALAN_ASET") >= 0,
          REFERENSI_HUTANG: res.data.tags.search("REFERENSI_HUTANG") >= 0,
          PEMBAYARAN_HUTANG: res.data.tags.search("PEMBAYARAN_HUTANG") >= 0,
          REFERENSI_PEMBAYARAN_PIUTANG: res.data.tags.search("REFERENSI_PEMBAYARAN_PIUTANG") >= 0,
          REFERENSI_PEMBAYARAN_HUTANG: res.data.tags.search("REFERENSI_PEMBAYARAN_HUTANG") >= 0,
          REFERENSI_PIUTANG: res.data.tags.search("REFERENSI_PIUTANG") >= 0,
          TRANSAKSI_PENJUALAN: res.data.tags.search("TRANSAKSI_PENJUALAN") >= 0,
          PERUBAHAN_MODAL: res.data.tags.search("PERUBAHAN_MODAL") >= 0,
        })
      }

      setCurData(res.data)
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  useEffect(()=> {
    var browserParams = queryString.parse(location.search)
    if(browserParams) {
      setQuery(browserParams)
      if(browserParams.parent) {
        setBackTo('/onpremise/'+browserParams.parent)
      }
    }
  }, [location.search])

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getLawanTransaksi() {
    var res = await LawanTransaksiService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setLawanTransaksiList(res.data.map(x => ({value: x.id, label: x.nama})))
    setLawanTransaksiMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getPeriodeFiskalList() {
    var res = await PeriodeFiskalService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId})
    setPeriodeFiskalList(res.data.map(x => ({value: x.id, label: x.tahun})))
  }

  async function _getAkun() {
    var res = await AkunService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setAkunList(res.data.map(x => ({value: x.id, label: x.nomor?`${x.nomor} - ${x.nama}`: `${x.nama}`})))
  }

  async function _check(data) {

  }

  var form = (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={backTo}
      baseId="mod-form-sample"
      title="Transaksi"
      showCommandbar={match.params.id == 'baru'}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key === 'transaksiLawanTransaksi__sumberId') {
          var lt = lawanTransaksiMap[value]
          if(lt) {
            newObj.transaksiLawanTransaksi__jenisPemungutPPh22 = ""
            newObj.transaksiLawanTransaksi__nama = lt.nama
            newObj.transaksiLawanTransaksi__nik = lt.nik
            newObj.transaksiLawanTransaksi__npwp = lt.npwp
            newObj.transaksiLawanTransaksi__pemungut = ""
            newObj.transaksiLawanTransaksi__sumber = "LawanTransaksi"
            newObj.transaksiLawanTransaksi__sumberId = lt.id
            newObj.transaksiLawanTransaksi__tipeWajibPajak = lt.tipeWajibPajak
          }
        }

        setCurData(newObj)
        if(parentKey) {

        }

        if(key == 'tanggal') {
          localStorage.setItem("tgl", JSON.stringify(value))
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Periode',
          key: 'periodeFiskal__id',
          type: 'text',
          disabled: isEdit,
          validation: 'required',
          options: periodeFiskalList,
          disabled: sipStore.periodeId != null
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitasId',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required'
        },

        {
          inputType: inputTypes.INPUT,
          label: 'Nomor Transaksi',
          key: 'nomor',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        }, {
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Transaksi',
          key: 'tanggal',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'required'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Apakah Transaksi Pembelian?',
          key: 'pembelian',
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
          width: '100%'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# LAWAN TRANSAKSI'
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: 'Lawan Transaksi',
          key: 'transaksiLawanTransaksi__sumberId',
          type: 'text',
          // width: '49%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          options: lawanTransaksiList,
          data: lawanTransaksiList
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP Lawan Transaksi',
          key: 'transaksiLawanTransaksi__npwp',
          type: 'text',
          width: '49%',
          disabled: isEdit,
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: true,
        }, {
          inputType: inputTypes.INPUT,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'Nik Lawan Transaksi',
          key: 'transaksiLawanTransaksi__nik',
          type: 'text',
          width: '49%',
          disabled: true,
          validation: [{required_if: ['transaksiLawanTransaksi__tipeWajibPajak', "OP"]}],
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Nama Lawan Transaksi',
        //   key: 'transaksiLawanTransaksi__nama',
        //   type: 'text',
        //   validation: 'required',
        //   disabled: true,
        // },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# JURNAL TRANSAKSI',
          key: 'transaksiJurnals',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 1000},
          defaultData: {
            keterangan: '-',
            debit: '0',
            kredit: '0'
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Akun',
              key: 'akun__id',
              theme: 'filled',
              style: {width: 600},
              validation: 'required',
              options: akunList
            },
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Posisi Akun',
              key: 'posisiAkun',
              theme: 'filled',
              validation: 'required',
              style: {width: 200},
              options: [{value: 'D', label: 'Debit'}, {value: 'K', label: 'Kredit'}]
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'Nilai',
              key: 'nilai',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric',
              style: {width: 200},
            },
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          var tanggalTransaksi = moment(data.tanggal).format("YYYY")
          var periode = moment(sipStore.periode.tahun)

          if(tanggalTransaksi != periode) {
            iziToast.warning({
              title:'Warning',
              message: 'Tanggal yang dapat diinput atau dipilih adalah tanggal pada tahun sesuai tahun periode yang dipilih'
            })
            callback('', true, false)
            return true
          }

          data.transaksiJurnals = data.transaksiJurnals.map((x)=>  Utils.formNormalize(x))
          var debit = 0;
          var kredit = 0;

          data.transaksiJurnals.forEach((x)=> {
            if(x.posisiAkun == 'D') {
              debit = debit + parseInt(x.nilai)
            }
            if(x.posisiAkun == 'K') {
              kredit = kredit + parseInt(x.nilai)
            }
          })

          if(debit != kredit) {
            callback('Data Transaksi tidak balance', true, false)
            return false
          }
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }

          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push(backTo)
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )

  if(match.params.id != 'baru') {
    var tabs = [
      {
        label: 'Data',
        key: 'form',
        render: form
      },
      {
        label: 'Pajak',
        key: 'pajak-terutang',
        render: <TransaksiPajakTerutangTable jenisMap={jenisMap} history={history} transaksiId={match.params.id} transaksi={data.content} />
      },
      {
        label: 'Pajak Aplikasi WP',
        key: 'pajak-wp',
        render: <TransaksiPajakWpTable jenisMap={jenisMap} history={history} transaksiId={match.params.id} transaksi={data.content} />
      }];

      if(jenisMap.KOREKSI_FISKAL) {
        tabs.push({
          label: 'Koreksi Fiskal',
          key: 'koreksi-fiskal',
          render: <TransaksiKoreksiFiskalTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.KOREKSI_FISKAL
        })
      }
      if(jenisMap.BEBAN_PROMOSI) {
        tabs.push({
          label: 'Beban Promosi',
          key: 'beban-promosi',
          render: <TransaksiBebanPromosiTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.BEBAN_PROMOSI
        })
      }
      if(jenisMap.BEBAN_ENTERTAINMENT) {
        tabs.push({
          label: 'Beban Entertainment',
          key: 'beban-entertainment',
          render: <TransaksiBebanEntertainmentTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.BEBAN_ENTERTAINMENT
        })
      }

      if( jenisMap.REFERENSI_HUTANG) {
        tabs.push({
          label: 'Referensi Hutang',
          key: 'referensi-hutang',
          render: <TransaksiReferensiHutangTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.REFERENSI_HUTANG
        })
      }

      if(jenisMap.REFERENSI_PEMBAYARAN_HUTANG) {
        tabs.push({
          label: 'Referensi Pembayaran Hutang',
          key: 'referensi-pembayaran-hutang',
          render: <TransaksiReferensiPembayaranHutangTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.REFERENSI_PEMBAYARAN_HUTANG
        })
      }

      if(jenisMap.PEMBELIAN_ASET) {
        tabs.push( {
          label: 'Aktiva Tetap',
          key: 'transaksi-aktiva-tetap',
          render: <TransaksiAktivaTetapTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.PEMBELIAN_ASET
        })
      }

      if(jenisMap.PENJUALAN_ASET) {
        tabs.push({
          label: 'Penjualan Aktiva Tetap',
          key: 'transaksi-penjualan-aktiva-tetap',
          render: <TransaksiPenjualanAktivaTetapTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.PENJUALAN_ASET
        })
      }

      if(jenisMap.REFERENSI_PIUTANG) {
        tabs.push({
          label: 'Referensi Piutang',
          key: 'transaksi-referensi-piutang',
          render: <TransaksiReferensiPiutangTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.REFERENSI_PIUTANG
        })
      }

      if(jenisMap.REFERENSI_PEMBAYARAN_PIUTANG) {
        tabs.push({
          label: 'Referensi Pembayaran Piutang',
          key: 'transaksi-referensi-pembayaran-piutang',
          render: <TransaksiReferensiPembayaranPiutangTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.REFERENSI_PEMBAYARAN_PIUTANG
        })
      }

      if(jenisMap.PERUBAHAN_MODAL) {
        tabs.push({
          label: 'Perubahan Modal',
          key: 'transaksi-perubahan-modal',
          render: <TransaksiPerubahanModalTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.PERUBAHAN_MODAL
        })
      }


      if(jenisMap.TRANSAKSI_PENJUALAN) {
        tabs.push({
          label: 'Transaksi POS',
          key: 'transaksi-pos',
          render: <TransaksiPosTable history={history} transaksiId={match.params.id} />,
          show: jenisMap.TRANSAKSI_PENJUALAN
        })
      }


    return <TabsWrapper
      title="Transaksi"
      showCommandbar={true}
      baseId="mod-details-sample-aad"
      actions={[
        {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push(backTo)}
      ]}
      onChange={ (tab, d) => {
        navigationStore.redirectTo(`/onpremise/transaksi/${match.params.id}/${tab.key}`)
        d()
      }}
      tabs={tabs}
      // tabs={[
      //   {
      //     label: 'Data',
      //     key: 'form',
      //     render: form
      //   },
      //   {
      //     label: 'Pajak',
      //     key: 'pajak-terutang',
      //     render: <TransaksiPajakTerutangTable jenisMap={jenisMap} history={history} transaksiId={match.params.id} />
      //   },
      //   {
      //     label: 'Koreksi Fiskal',
      //     key: 'koreksi-fiskal',
      //     render: <TransaksiKoreksiFiskalTable history={history} transaksiId={match.params.id} />,
      //     show: jenisMap.KOREKSI_FISKAL
      //   },
      //   {
      //     label: 'Beban Promosi',
      //     key: 'beban-promosi',
      //     render: <TransaksiBebanPromosiTable history={history} transaksiId={match.params.id} />,
      //     show: jenisMap.BEBAN_PROMOSI
      //   },
      //   {
      //     label: 'Beban Entertainment',
      //     key: 'beban-entertainment',
      //     render: <TransaksiBebanEntertainmentTable history={history} transaksiId={match.params.id} />,
      //     show: jenisMap.BEBAN_ENTERTAINMENT
      //   },
      //   {
      //     label: 'Referensi Hutang',
      //     key: 'referensi-hutang',
      //     render: <TransaksiReferensiHutangTable history={history} transaksiId={match.params.id} />,
      //     show: jenisMap.REFERENSI_HUTANG
      //   },
      //   {
      //     label: 'Referensi Pembayaran Hutang',
      //     key: 'referensi-pembayaran-hutang',
      //     render: <TransaksiReferensiPembayaranHutangTable history={history} transaksiId={match.params.id} />,
      //     show: jenisMap.REFERENSI_PEMBAYARAN_HUTANG
      //   },
      //   {
      //     label: 'Laba Rugi',
      //     key: 'laba-rugi',
      //     render: <TransaksiLabaRugiTable history={history} transaksiId={match.params.id} />,
      //     show: false //jenisMap.TRANSAKSI_PENJUALAN
      //   },
      //   {
      //     label: 'Aktiva Tetap',
      //     key: 'transaksi-aktiva-tetap',
      //     render: <TransaksiAktivaTetapTable history={history} transaksiId={match.params.id} />,
      //     show: jenisMap.PEMBELIAN_ASET
      //   },
      //   {
      //     label: 'Penjualan Aktiva Tetap',
      //     key: 'transaksi-penjualan-aktiva-tetap',
      //     render: <TransaksiPenjualanAktivaTetapTable history={history} transaksiId={match.params.id} />,
      //     show: jenisMap.PENJUALAN_ASET
      //   },
      // ]}
      // onChange={ tab => {
      //   // navigationStore.redirectTo(`/${envStore.env.applicationType}/details/${tab.key}`)
      // }}
    />
  } else {
    return form
  }
}

export default inject('sipStore', 'navigationStore')(observer(TransaksiForm))
