import React, {useEffect, useState} from 'react'
import { TableWrapper ,FormWrapper} from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Transaksi.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { DataForm } from '../../../libs/react-mpk/components'
import iziToast from 'izitoast'
import queryString from 'query-string'
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-Transaksi"
const TransaksiTable = ({
  className       = '',
  showCommandbar  = true,
  sipStore,
  history,
  location,
  ...props
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [uploadModal, setUploadModal] = useState(false)
  const [title, setTitle] = useState('Transaksi')
  const [query, setQuery] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }

  function _closeUploadModal() {
    setUploadModal(false)
  }

  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }


  useEffect(()=> {
    var browserParams = queryString.parse(location.search)
    if(browserParams && browserParams.title) {
      setTitle('Transaksi [AKUN '+browserParams.title +"]")
      setQuery(browserParams)
    }
  }, [location.search])



  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={title}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      tableFooter={query.title?(<>
        <div className="mpk-flex align-center direction-row justify-start flex">
          <div className="flex">Nilai Awal Akun [{query.posisiAkunAwal}]</div> <b>{Utils.numberFormat(query.nilaiAwal)}</b>
        </div>
        <div className="mpk-flex align-center direction-row justify-start flex">
          <div className="flex">Nilai Akhir Akun [{query.posisiAkunAkhir}]</div> <b>{Utils.numberFormat(query.nilaiAkhir)}</b>
        </div>
        </>):false
      }
      columns={[
        {
          label: 'Tanggal Transaksi',
          searchable: true,
          key: 'tanggal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tanggal)
        },{
          label: 'Nomor Transaksi',
          searchable: true,
          key: 'nomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nomor)
        },{
          label: 'Kelengkapan Data',
          searchable: true,
          key: 'alias',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.alias)
        },{
          label: 'Status Approve',
          searchable: true,
          key: 'alamat',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.alamat)
        },{
          label: 'Lawan Transaksi',
          searchable: true,
          key: 'lawanTransaksi',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.transaksiLawanTransaksi.nama)
        },
        {
          label: 'Debit',
          searchable: false,
          key: 'debit',
          type: TableWrapper.dataTypes.STRING,
          render: item => Utils.numberFormat(item.transaksiJurnals?item.transaksiJurnals.filter(x => x.posisiAkun == 'D').reduce((pv, cv)=> {
            if(query.akunId) {
              if(cv.akun.id == query.akunId) {
                pv = pv + cv.nilai
              }
            } else {
              pv = pv + cv.nilai
            }

            return pv
          }, 0):0)
        },{
          label: 'Kredit',
          searchable: false,
          key: 'kredit',
          type: TableWrapper.dataTypes.STRING,
          render: item =>  Utils.numberFormat(item.transaksiJurnals?item.transaksiJurnals.filter(x => x.posisiAkun == 'K').reduce((pv, cv)=> {
            if(query.akunId) {
              if(cv.akun.id == query.akunId) {
                pv = pv + cv.nilai
              }
            } else {
              pv = pv + cv.nilai
            }

            return pv
          }, 0):0)
        },
        {
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        },{
          label: 'Tags',
          searchable: true,
          key: 'tag',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tags)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/transaksi/baru'), true),
        new TableWrapper.action('Import Transaksi', 'mdi mdi-import', () => setUploadModal(true), true),
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/transaksi/${item.id}`), true),
        // new TableWrapper.action('Setting', 'mdi mdi-pencil', (item) => history.push(`/onpremise/Transaksi-setting/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
          query['periodeFiskalId.equals'] = sipStore.periodeId
        }

        var browserParams = queryString.parse(location.search)
        if(browserParams && browserParams.akunId) {
          query['akunId.equals'] = browserParams.akunId
        }

        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    <DataForm
      baseId={"transaksi-import"}
      asDialog={true}
      defaultData={{}}
      definitions={[{
        inputType: FormWrapper.inputTypes.FILE_INPUT,
        label: 'File',
        key: 'file',
        theme: 'filled',
        style: {width: 200}
      }]}
      visible={uploadModal}
      submitLabel='Import Data'
      onSubmit={async (d, callback)=> {
        var formData = new FormData()
        formData.set('file', d.file)
        var uploadRes = await service.http.post('/upload', formData)
        if(uploadRes.data) {
          var importRes = await service.http.post('/transaksi-import', {
            nama: uploadRes.data.name,
            origin: uploadRes.data.origin,
            perusahaanId: sipStore.companyId,
            periodeId: sipStore.periodeId,
            ext: 'xls'
          })
        }
        callback('Data sedang di import. Mohon pantau progress import di menu log import.', false)
      }}
      onRequestClose={_closeUploadModal} />
    </>
  )
}

export default inject('sipStore', 'navigationStore')(observer(TransaksiTable))
