import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./TransaksiBebanPromosi.service";
import KategoriPPh25Service from "../../adminpajakku/kategoriPph25/KategoriPph25.service";
import TipePengecualianPajakService from "../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service";
import Utils from "../../../libs/custom/Utils";
import AkunService from "../../master/Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import TransaksiService from "./Transaksi.service";
import { AKUN_JENIS_MAP } from "../../../clientConstant";
import TransaksiKoreksiFiskalNilaiSisaService from "./TransaksiKoreksiFiskalNilaiSisa.service";
import { ListItem } from "react-md";
import EntitasService from "../../master/Entitas/Entitas.service";

const TransaksiBebanPromosiForm = ({ envStore, match, history, sipStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({ loading: true, content: {} });
  const [akunList, setAkun] = useState([]);
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([]);
  const [journalMap, setJournalMap] = useState({});
  const [transaksiList, setTransaksi] = useState([]);
  const [customRules, setCustomeRules] = useState({});
  const [bps, setBps] = useState([]);
  const [bpMap, setBpMap] = useState([]);
  const [entitasList, setEntitasList] = useState([])
  var isFiskal = false;

  // initial data
  if (window.location.href.includes("daftar-nominatif-biaya-promosi")) {
    isFiskal = true;
  }
  useEffect(() => {
    async function initData() {
      _getAkun();
      _getKodeObjekPajak();
      _getEntitasList()
      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            perusahaanId: sipStore.companyId,
            periodeId: sipStore.periodeId,
            dipungut: false,
            dpp: "0",
            pph: "0",
            transaksiBebanPromosiAkun__nilai: "0",
            transaksiBebanPromosiPengecualians: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      res.data = Utils.formSerialize(res.data);
      res.data.transaksiBebanPromosiAkun__akunid =
        res.data.transaksiBebanPromosiAkun &&
        res.data.transaksiBebanPromosiAkun.akun
          ? res.data.transaksiBebanPromosiAkun.akun.id
          : null;
      if (!isFiskal) {
        const nilaiSisaFiskal =
          await TransaksiKoreksiFiskalNilaiSisaService.getOne(
            res.data.jurnal__id
          );
        res.data.nilaiSisa = nilaiSisaFiskal.data;
        setCustomeRules({
          jurnal__id: "required",
          jurnal__nilai: "required",
        });
      }
      setIsEdit(true);
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  async function _getAkun() {
    if (match.params.transaksiId) {
      var res = await TransaksiService.getOne(match.params.transaksiId);
      setJournalMap(
        res.data.transaksiJurnals.reduce((pv, cv) => {
          pv[cv.id] = cv;
          return pv;
        }, {})
      );
      setAkun(res.data.transaksiJurnals);
      setTransaksi(res.data);
    }
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({ size: 100000 });
    setKodeObjekPajak(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getDataBp(tahun, masa, npwp) {
    if (npwp && npwp.length >= 15) {
      var r = await TransaksiService.http.get(
        `/transaksi-beban-promosis-get-bp/${masa}/${tahun}/${npwp}`
      );
      setBps(
        r.data.map((x) => ({
          value: x.noBp,
          label: x.nama,
          children: (
            <ListItem secondaryText={`[${x.noBp}] ${x.totalPph}`}>
              {x.npwp} - {x.nama}
            </ListItem>
          ),
        }))
      );
      setBpMap(
        r.data.reduce((pv, cv) => {
          pv[cv.noBp] = cv;
          return pv;
        }, {})
      );
    }
  }

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.filter(x => x.tipeEntitas === 'HO' || x.tipeEntitas === 'Cabang').map(x => ({value: x.id, label: `${x.npwp} - ${x.nama}`})))
  }

  return (
    <FormWrapper
      noValidate
      customRules={{
        npwp: "required|size:15",
        tahunPajak: "required|size:4",
        ...customRules,
      }}
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={
        isFiskal
          ? "/onpremise/daftar-nominatif-biaya-promosi"
          : "/onpremise/transaksi/" +
            match.params.transaksiId +
            "/beban-promosi"
      }
      baseId="mod-form-sample"
      title={isFiskal ? "Daftar Nominatif Biaya Promosi" : "Beban Promosi"}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if (!isFiskal) {
          if (key == "jurnal__id") {
            const nilaiSisa =
              await TransaksiKoreksiFiskalNilaiSisaService.getOne(value);
            newObj.nilaiSisa = nilaiSisa.data;
            newObj.jurnal__nilai = journalMap[value].nilai;
            newObj.nilai = journalMap[value].nilai;
            newObj.masa =
              transaksiList.tanggal.split("-").length == 3
                ? parseInt(transaksiList.tanggal.split("-")[1]).toString()
                : "";
            newObj.tahunPajak =
              transaksiList.tanggal.split("-").length == 3
                ? transaksiList.tanggal.split("-")[0]
                : "";
          }
        } else {
          if (key === "masa" || key === "tahunPajak" || key === "npwp") {
            newObj[key] = value;

            if (newObj.tahunPajak && newObj.masa && newObj.npwp) {
              _getDataBp(newObj.tahunPajak, newObj.masa, newObj.npwp);
            }
          }
        }
        if (key === "epptId") {
          var bp = bpMap[value];

          if (bp) {
            newObj.nama = bp.nama;
            newObj.nilai = bp.totalBruto;
            newObj.alamat = bp.address;
            newObj.pph = bp.totalPph;
            newObj.epptNomorBupot = bp.noBp;
          }
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "# AKUN TRANSAKSI",
          show: () => !isFiskal,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Akun",
          key: "jurnal__id",
          type: "text",
          width: "49%",
          required: true,
          options: akunList
            .filter(
              (x) =>
                x.akun.akunJenisId == AKUN_JENIS_MAP["Akun Beban Promosi"].id
            )
            .map((x) => ({ value: x.id, label: x.akun.nama })),
          style: { marginRight: "2%" },
          show: () => !isFiskal,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai Jurnal",
          key: "jurnal__nilai",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          show: () => !isFiskal,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai Sisa",
          key: "nilaiSisa",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "100%",
          disabled: true,
          show: () => !isFiskal,
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitas__id',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Masa",
          key: "masa",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          required: true,
          validation: "required",
          options: [
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9", label: "9" },
            { value: "10", label: "10" },
            { value: "11", label: "11" },
            { value: "12", label: "12" },
          ],
        },
        {
          inputType: inputTypes.INPUT_MASK,
          label: "Tahun",
          key: "tahunPajak",
          width: "49%",
          mask: "****",
          alwaysShowMask: true,
          required: true,
          allowNegative: false,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Data ePPT",
          key: "epptId",
          type: "text",
          options: bps,
          // required: true,
          // validation: 'required',
          width: "100%",
          show: () => isFiskal,
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "# DATA PENERIMA",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: "##.###.###.#-###.###",
          maskChar: "_",
          maskValue: "string",
          label: "NPWP",
          key: "npwp",
          type: "text",
          width: "100%",
          // style: {marginRight: '2%'},
          validation: "required",
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nama",
          key: "nama",
          type: "text",
          required: true,
          validation: "required",
          width: "100%",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Alamat",
          key: "alamat",
          type: "text",
          required: true,
          validation: "required",
          width: "100%",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai",
          key: "nilai",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required|numeric",
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal",
          key: "tanggal",
          width: "49%",
          validation: "required",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Bentuk dan Jenis Biaya",
          key: "bentukDanJenisBiaya",
          type: "text",
          width: "100%",
          validation: "required",
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.INPUT,
          label: "Keterangan",
          key: "keterangan",
          type: "text",
          width: "100%",
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "# PEMOTONGAN PPh",
          show: () => isFiskal,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Jumlah PPh",
          key: "pph",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          style: { marginRight: "2%" },
          show: () => isFiskal,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nomor Bukti Potong",
          key: "epptNomorBupot",
          type: "text",
          // required: true,
          // validation: 'required',
          width: "49%",
          show: () => isFiskal,
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        var url = "";
        try {
          data.transaksiId = match.params.transaksiId;
          data = Utils.formNormalize(data);
          data.transaksiBebanPromosiAkun.akun = {
            id: data.transaksiBebanPromosiAkun__akunid,
          };

          if (isFiskal) {
            data.sumber = "Input Data";
            url = "/onpremise/daftar-nominatif-biaya-promosi";
          } else {
            var allres = await service.get({
              "transaksiId.equals": data.transaksiId,
              size: 100000,
            });
            var total = allres.data
              .filter((x) => x.id != data.id)
              .reduce((pv, cv) => {
                pv = pv + parseInt(cv.nilai);
                return pv;
              }, 0);

            total = total + parseInt(data.nilai);

            var akunId = journalMap[data.jurnal__id].akun.id;
            var totalJurnal = akunList.reduce((pv, cv) => {
              if (cv.akun.id == akunId) {
                pv = pv + parseInt(cv.nilai);
              }
              return pv;
            }, 0);

            if (total > totalJurnal) {
              // iziToast.warning({message: 'Total nilai beban promosi melebihi nilai jurnal'})
              callback(
                "Total nilai beban promosi melebihi nilai jurnal",
                true,
                false
              );
              return false;
            }
            data.sumber = "Transaksi";
            url =
              "/onpremise/transaksi/" +
              match.params.transaksiId +
              "/beban-promosi";
          }

          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push(url);
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject(
  "envStore",
  "sipStore"
)(observer(TransaksiBebanPromosiForm));
