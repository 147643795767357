import CrudCustom from '../../../libs/custom/CrudCustom';
import http from './../../../libs/custom/httpService.custom';

let AkunService = new CrudCustom('/akuns');

AkunService.approval = async(data)=> {
    return http.request({
      method: http.methods.PUT,
      url: `/akuns/status`,
      data
    })
}

AkunService.http = http;

export default AkunService;