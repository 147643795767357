import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import DashboardService from "./DashboardService";
import { DataForm } from "../../../libs/react-mpk/components";
import moment from "moment";

import {
  SortOrder,
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Caption,
} from "@react-md/table";
import { FontIcon } from "@react-md/icon";
import {
  AppBar,
  AppBarNav,
  AppBarTitle,
  AppBarAction,
} from "@react-md/app-bar";
import { useState } from "react";
import { Line, Circle } from "rc-progress";
import Utils from "../../../libs/custom/Utils";
import { Avatar, Card, CardHeader, CardTitle } from "react-md";
import {isEmpty} from 'lodash'

function PelaporanDashboardView({ sipStore }) {
  const [data, setData] = useState({
    loading: true,
    content: [],
    sortKey: "nama",
    sortOrder: "ascending",
    total: 0,
    param: {},
  });

  const [toggleFilter, setToggleFilter] = useState(false);

  useEffect(() => {
    initData("nama", {
      perusahaanId: sipStore.companyId,
      "tahun.equals": "" + sipStore.periode.tahun,
      "bulan.greaterThanOrEqual": "1",
      "bulan.lowerThanOrEqual": "12",
    });
  }, []);

  async function initData(sortKey, param) {
    var d = data.content;
    if (param) {
      param.pageSize = 10000000000;
      delete param.tahun;
      var pph21 = await DashboardService.pelaporanPph21(param);
      var pph22 = await DashboardService.pelaporanPph22(param);
      var pph23 = await DashboardService.pelaporanPph23(param);
      var pph4a2 = await DashboardService.pelaporanPph4a2(param);
      var ppn = await DashboardService.pelaporanPpn(param);

      d = [
        ...pph21.data.map((x) => ({ ...x, jenis: "PPH 21" })),
        ...pph22.data.map((x) => ({ ...x, jenis: "PPH 22" })),
        ...pph23.data.map((x) => ({ ...x, jenis: "PPH 23" })),
        ...pph4a2.data.map((x) => ({ ...x, jenis: "PPH 4(2)" })),
        ...ppn.data.map((x) => ({ ...x, jenis: "PPN" })),
      ];

      console.log(d, 'asdasd')
    }

    setData((prevState) => {
      const prevSortKey = prevState.sortKey;
      const prevSortOrder = prevState.sortOrder;

      let sortOrder;
      if (sortKey === prevSortKey) {
        // it's the same column, so toggle the sort order
        sortOrder = prevSortOrder === "ascending" ? "descending" : "ascending";
      } else {
        // it's a new column to sort by, so default to ascending for the name column
        // but descending for all the rest.
        sortOrder = sortKey === "name" ? "ascending" : "descending";
      }

      return {
        content: d.sort((a, b) => {
          const aValue = a[sortKey];
          const bValue = b[sortKey];
          const value =
            typeof aValue === "number"
              ? aValue - bValue
              : "" + aValue.localeCompare(bValue);
          return value * (sortOrder === "ascending" ? 1 : -1);
        }),
        sortKey,
        sortOrder,
        param,
        loading: false,
        total: d.reduce((pv, cv) => {
          pv = pv + cv.totalPph;
          return pv;
        }, 0),
      };
    });
  }

  const { content, sortKey, sortOrder } = data;

  return (
    <Card raiseable
      className="mpk-form-wrapper mpk-paper  padding-all mpk-animation slide-in"
      style={{
        width: "49%",
        maxWidth: 1200,
        margin: "0px auto",
        display: "inline-block",
      }}
    >
      <CardHeader
        beforeChildren={
          <Avatar >
            <FontIcon iconClassName="mdi mdi-calendar-text"/>
          </Avatar>
        }
        afterChildren={
          <AppBarAction first aria-label="Actions" onClick={()=> {setToggleFilter(!toggleFilter)}}>
          <FontIcon iconClassName="mdi mdi-filter" />
        </AppBarAction>
        }
      >
        <CardTitle>PELAPORAN</CardTitle>
        {/* <CardSubtitle>Daftar pembelian berdasarkan COA</CardSubtitle> */}
      </CardHeader>

      <div className="mpk-padding-N" style={{ height: 600, overflow: "auto" }}>
        {toggleFilter && (
          <DataForm
            baseId={"pegawai-import"}
            // asDialog={true}
            defaultData={data.param}
            definitions={[
              {
                inputType: DataForm.inputTypes.INPUT,
                label: "Tahun Pajak",
                key: "tahun.equals",
                theme: "filled",
                validation: "required",
                width: "49%",
                style: { marginRight: "2%", width: "100%" },
              },
              {
                inputType: DataForm.inputTypes.SELECT,
                label: "Masa Pajak Dari",
                key: "bulan.greaterThanOrEqual",
                type: "text",
                style: { marginRight: "2%" },
                width: "49%",
                required: true,
                validation: "required",
                options: [
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                  { value: "4", label: "4" },
                  { value: "5", label: "5" },
                  { value: "6", label: "6" },
                  { value: "7", label: "7" },
                  { value: "8", label: "8" },
                  { value: "9", label: "9" },
                  { value: "10", label: "10" },
                  { value: "11", label: "11" },
                  { value: "12", label: "12" },
                ],
              },
              {
                inputType: DataForm.inputTypes.SELECT,
                label: "Masa Pajak Sampai",
                key: "bulan.lowerThanOrEqual",
                type: "text",
                width: "49%",
                required: true,
                validation: "required",
                options: [
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                  { value: "4", label: "4" },
                  { value: "5", label: "5" },
                  { value: "6", label: "6" },
                  { value: "7", label: "7" },
                  { value: "8", label: "8" },
                  { value: "9", label: "9" },
                  { value: "10", label: "10" },
                  { value: "11", label: "11" },
                  { value: "12", label: "12" },
                ],
              },
              // {
              //   inputType: DataForm.inputTypes.SELECT,
              //   label: "Kategori Pajak",
              //   key: "type",
              //   theme: "filled",
              //   validation: "required",
              //   options: [
              //     { label: "Pajak Terutang", value: "Pajak Terutang" },
              //     {
              //       label: "Pajak Dibayar Dimuka",
              //       value: "Pajak Dibayar Dimuka",
              //     },
              //   ],
              //   style: { width: "29%" },
              // },
            ]}
            // visible={uploadModal}
            submitLabel="Cari"
            onSubmit={async (d, callback) => {
              try {
                await initData(data.sortKey, {
                  ...d,
                  perusahaanId: sipStore.companyId,
                });
                callback();
              } catch (e) {
                console.log(e.response);
                callback(e.response.data.message);
              }
            }}
          />
        )}
        <TableContainer style={{minHeight:600}}>
          <Table fullWidth>
            <Caption>
              {data.param["bulan.greaterThanOrEqual"]} -{" "}
              {data.param["bulan.lowerThanOrEqual"]}{" "}
              {data.param["tahun.equals"]}
            </Caption>
            <TableHeader>
              <TableRow>
                <TableCell
                  key="npwp"
                  aria-sort={"npwp" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("npwp")}
                >
                  NPWP
                </TableCell>
                <TableCell
                  key="nama"
                  aria-sort={"nama" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nama")}
                >
                  NAMA CABANG
                </TableCell>
                <TableCell
                  key="bulan"
                  aria-sort={"bulan" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("bulan")}
                >
                  MASA PAJAK
                </TableCell>
                <TableCell
                  key="tahun"
                  aria-sort={"tahun" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("tahun")}
                >
                  TAHUN PAJAK
                </TableCell>
                <TableCell
                  key="pembetulan"
                  aria-sort={"pembetulan" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("pembetulan")}
                >
                  PEMBETULAN
                </TableCell>
                <TableCell
                  key="jenis"
                  aria-sort={"jenis" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("jenis")}
                >
                  JENIS PAJAK
                </TableCell>
                <TableCell
                  key="ntpn"
                  aria-sort={"ntpn" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("ntpn")}
                >
                  NTPN
                </TableCell>
                <TableCell
                  key="tglSetor"
                  aria-sort={"tglSetor" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("tglSetor")}
                >
                  TGL SETOR
                </TableCell>
                <TableCell
                  key="ntte"
                  aria-sort={"ntte" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("ntte")}
                >
                  NTTE
                </TableCell>
                <TableCell
                  key="tglLapor"
                  aria-sort={"tglLapor" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("tglLapor")}
                >
                  TGL LAPOR
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.content.map((v, i) => {
                var c = colors[getRandomInt(34, 0)];
                return (
                  <TableRow>
                    <TableCell>
                      <div style={{}}>{v.npwp}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.nama}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.bulan}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.tahun}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.pembetulan}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.jenis}</div>
                    </TableCell>
                    <TableCell>{isEmpty(v.ntpn)?"Belum Setor":v.ntpn}</TableCell>
                    <TableCell>
                      <div style={{}}>{isEmpty(v.tglSetor)?"Belum Setor":v.tglSetor}</div>
                    </TableCell>
                    <TableCell>{isEmpty(v.ntte)?"Belum Lapor":v.ntpe}</TableCell>
                    <TableCell>
                      <div style={{}}>{isEmpty(v.tglLapor)?"Belum Lapor":v.tglLapor}</div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
}

export default inject(
  "sipStore",
  "authStore"
)(observer(PelaporanDashboardView));

const colors = [
  "#ef5350",
  "#ff1744",
  "#ec407a",
  "#f50057",
  "#ab47bc",
  "#d500f9",
  "#7e57c2",
  "#651fff",
  "#5c6bc0",
  "#3d5afe",
  "#42a5f5",
  "#2979ff",
  "#29b6f6",
  "#00b0ff",
  "#26c6da",
  "#00e5ff",
  "#26a69a",
  "#1de9b6",
  "#66bb6a",
  "#00e676",
  "#9ccc65",
  "#76ff03",
  "#d4e157",
  "#c6ff00",
  "#ffee58",
  "#ffea00",
  "#ffca28",
  "#ffc400",
  "#ffa726",
  "#ff9100",
  "#ff7043",
  "#ff3d00",
  "#8d6e63",
  "#bdbdbd",
  "#78909c",
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
