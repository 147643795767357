import CrudCustom from "../../../libs/custom/CrudCustom";

var DashboardService = new CrudCustom("/dashboard");

DashboardService.kontribusiPajak = (data) => {
  return DashboardService.http.post("/dashboard/kontribusi-pajak", data);
};

DashboardService.pembelian = (id) => {
  return DashboardService.http.get("/dashboard/pembelian/"+id);
};

DashboardService.pihakBerelasi = (id) => {
  return DashboardService.http.get("/dashboard/pihak-berelasi/"+id);
};

DashboardService.ekualisasi = (id) => {
  return DashboardService.http.get("/dashboard/ekualisasi-pajak/"+id);
};

DashboardService.ekualisasiJurnal = (id) => {
  return DashboardService.http.get("/dashboard/ekualisasi-jurnal/"+id);
};

DashboardService.ekualisasiPembelianItem = (id) => {
  return DashboardService.http.get("/dashboard/ekualisasi-pembelian-item/"+id);
};

DashboardService.pelaporanPph21 = (params) => {
  return DashboardService.http.get("/summary/pph21", params);
};

DashboardService.pelaporanPph22 = (params) => {
  return DashboardService.http.get("/summary/pph22", params);
};

DashboardService.pelaporanPph23 = (params) => {
  return DashboardService.http.get("/summary/pph23", params);
};

DashboardService.pelaporanPph4a2 = (params) => {
  return DashboardService.http.get("/summary/pph4a2", params);
};

DashboardService.pelaporanPph4a2Detail = (params) => {
  return DashboardService.http.get("/summary/pph4a2-detail", params);
};

DashboardService.pelaporanPpn = (params) => {
  return DashboardService.http.get("/summary/ppn", params);
};

DashboardService.skemaRemunerasi = (id, month) => {
  return DashboardService.http.get(`/dashboard/skema-remunerasi/${id}/${month}`,);
};

DashboardService.summaaryPajakBulanan = (id, year, month) => {
  return DashboardService.http.get(`/dashboard/summary-pph/${id}/${year}/${month}`,);
};

export default DashboardService;
