import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./SlipGaji.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import Utils from "../../../libs/custom/Utils";
import { inject, observer } from "mobx-react";
import iziToast from "izitoast";
import { DataForm } from "../../../libs/react-mpk/components";

var _baseId = "mod-table-slip-gaji";

const SlipGajiTable = ({
  className = "",
  showCommandbar = true,
  history,
  modalStore,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={"Daftar Penggajian"}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "NPWP Perusahaan / Cabang",
            searchable: true,
            key: "npwpEntitas",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.entitas.npwp,
          },
          {
            label: "Nama Perusahaan / Cabang",
            searchable: true,
            key: "namaEntitas",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.entitas.nama,
          },
          {
            label: "Tipe Pegawai",
            searchable: true,
            key: "tipePegawai",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.tipePegawai ? item.tipePegawai.nama : ""),
          },
          {
            label: "NPWP Pegawai",
            searchable: true,
            key: "npwp",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.npwp,
          },
          {
            label: "NIK / Passport Pegawai",
            searchable: true,
            key: "nik",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nik,
          },
          {
            label: "Nama Pegawai",
            searchable: true,
            key: "nama",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nama,
          },
          {
            label: "Take Home Pay (Rp)",
            searchable: true,
            key: "takeHomePay",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.gaji.takeHomePay),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => history.push("/pph-menu/slip/baru"),
            true
          ),
          new TableWrapper.action(
            "Export Slip Gaji",
            "mdi mdi-export",
            () => {
              var masa = localStorage["sip-menu-pph"]
                ? JSON.parse(localStorage["sip-menu-pph"])
                : {};
              modalStore.showConfirm({
                children: (
                  <DataForm
                    baseId={"slip-export"}
                    hideActionButton
                    defaultData={{}}
                    definitions={[
                      {
                        inputType: DataForm.inputTypes.SELECT,
                        label: "Jenis Laporan",
                        key: "type",
                        type: "text",
                        options: [
                          { label: "SLIP GAJI", value: "SLIP_GAJI_DEFAULT" },
                          { label: "SUMMARY", value: "SLIP_GAJI_SUMMARY" },
                          { label: "SUMMARY TEMPLATE 2", value: "SLIP_GAJI_SUMMARY_2" }
                        ],
                        style: { minWidth: 300 }
                      },
                      {
                        inputType: DataForm.inputTypes.SELECT,
                        label: "Jenis File",
                        key: "ext",
                        type: "text",
                        options: [{ label: "xlsx", value: "xlsx" }],
                        show: (d)=> d.type === 'SLIP_GAJI_SUMMARY' || d.type === 'SLIP_GAJI_SUMMARY_2',
                        style: { width: 300 }
                      },
                      {
                        inputType: DataForm.inputTypes.SELECT,
                        label: "Jenis File",
                        key: "ext",
                        type: "text",
                        options: [
                          { label: "pdf", value: "pdf" },
                          { label: "zip", value: "zip" },
                        ],
                        show: (d)=> d.type !== 'SLIP_GAJI_SUMMARY' && d.type !== 'SLIP_GAJI_SUMMARY_2',
                        style: { width: 300 }
                      },
                    ]}
                    // submitLabel="Import Data"
                    onSubmit={async (d, callback) => {
                      console.log(d, 'asdasd')
                      await service.http.post(
                        "/slip-export",
                        {
                          nama: d.type.replace("_", " "), ...d
                        },
                        {},
                        {},
                        {
                          "pemotonganPPh2126Id.equals": masa.id,
                          "perusahaanId.equals": masa.perusahaan.id,
                        }
                      );
                      callback(
                        "Silahkan check menu log export untuk memantau progress export.",
                        false
                      );
                    }}
                  />
                ),
                onSubmit: async (callback) => {
                  const f = document.getElementById(`slip-export-form-trigger`)
                  if(f) f.click()
                  callback();
                },
              });
            },
            true
          ),
          new TableWrapper.action(
            "Email Slip Gaji",
            "mdi mdi-email",
            () => {
              var masa = localStorage["sip-menu-pph"]
                ? JSON.parse(localStorage["sip-menu-pph"])
                : {};
              modalStore.showConfirm({
                children: (
                  <div>Apakah anda ingin mengirimkan slip gaji?</div>
                ),
                onSubmit: async (callback) => {
                  await service.http.post(
                    "/slip-export",
                    {
                      nama: "EMAIL SLIP GAJI",
                      type:  "EMAIL",
                      ext: "pdf",
                    },
                    {},
                    {},
                    {
                      "pemotonganPPh2126Id.equals": masa.id,
                      "perusahaanId.equals": masa.perusahaan.id,
                    }
                  );
                  toast.success(
                    `Mohon cek Log Export untuk melihat proses pengiriman`
                  );
                  callback()
                },
              });
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => history.push(`/pph-menu/slip/${item.id}`),
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          var masa = localStorage["sip-menu-pph"]
            ? JSON.parse(localStorage["sip-menu-pph"])
            : {};
          if (!query) query = {};
          query["pemotonganPPh2126Id.equals"] = masa.id;
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
    </>
  );
};

export default inject("modalStore")(observer(SlipGajiTable));
