import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TipeIndustri.service'
import JenisUsahaService from '../JenisUsaha/JenisUsaha.service'
import Utils from '../../../libs/custom/Utils'
import SektorService from '../sektor/Sektor.service'

const TipeIndustriForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper

  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [jenisUsahaList, setJenisUsahaList] = useState([])
  const [sektorList, setSektorList] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      await _getJenisUsaha()
      await _getSektor()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {}})
        return true
      }

      const res = await service.getOne(match.params.id)
      if(res.data.tmplLapPosisiKeuangan) res.data.tmplLapPosisiKeuangan = {name:  res.data.tmplLapPosisiKeuangan}
      if(res.data.tmplLapLabaRugi) res.data.tmplLapLabaRugi = {name:  res.data.tmplLapLabaRugi}
      if(res.data.tmplLapPerubahanEkuitas) res.data.tmplLapPerubahanEkuitas = {name:  res.data.tmplLapPerubahanEkuitas}
      if(res.data.tmplLapArusKas) res.data.tmplLapArusKas = {name:  res.data.tmplLapArusKas}
      if(res.data.tmplRekonLapKeuangan) res.data.tmplRekonLapKeuangan = {name:  res.data.tmplRekonLapKeuangan}
      if(res.data.tmplTranskipKeuangan) res.data.tmplTranskipKeuangan = {name:  res.data.tmplTranskipKeuangan}
      if(res.data.tmplLapKoreksiFiskal) res.data.tmplLapKoreksiFiskal = {name:  res.data.tmplLapKoreksiFiskal}

      setIsEdit(true)

      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  // method

  async function _getJenisUsaha() {
    var res = await JenisUsahaService.get()
    setJenisUsahaList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getSektor() {
    var res = await SektorService.get()
    setSektorList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      customRules={{
        nomor: 'required',
        nama: 'required',
        jenisUsahaId: 'required',
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/industri`}
      baseId="mod-form-sample"
      title={t.translate('modules.tipeIndustri.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT,
          label: 'Kode',
          key: 'nomor',
          type: 'text',
          vaalidation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.SELECT,
          label: 'Jenis Usaha',
          key: 'jenisUsahaId',
          type: 'text',
          options: jenisUsahaList,
          validation: 'required'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Mapping Integrasi SPT 1771',
          key: 'mappingSpt1771',
          type: 'text',
          options: [
            {label: '1 - MANUFAKTUR', value: "1 - MANUFAKTUR"},
            {label: '3 - DAGANG', value: "3 - DAGANG"},
            {label: '5 - BANK KONVENSIONAL', value: "5 - BANK KONVENSIONAL"},
            {label: '7 - BANK SYARIAH', value: "7 - BANK SYARIAH"},
            {label: '9 - PERUSAHAAN ASURANSI', value: "9 - PERUSAHAAN ASURANSI"},
            {label: '11 - NON KUALIFIKASI', value: "11 - NON KUALIFIKASI"},
            {label: '11 - LABA RUGI', value: "11 - LABA RUGI"},
            {label: '13 - DANA PENSIUN', value: "13 - DANA PENSIUN"},
            {label: '15 - PERUSAHAAN PENDANAAN', value: "15 - PERUSAHAAN PENDANAAN"},
          ],
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },

        {
          inputType: inputTypes.DIVIDER,
          label: "PENGATURAN SILK",
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Sektor',
          key: 'sektorId',
          type: 'text',
          options: sektorList,
          validation: 'required'
        },

        {
          inputType: inputTypes.DIVIDER,
          label: "TEMPLATE SILK",
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "Financial Statement Reporting",
        },
        {
          inputType: FormWrapper.inputTypes.FILE_INPUT,
          label: 'Laporan Posisi Keuangan',
          key: 'tmplLapPosisiKeuangan',
        },
        {
          inputType: FormWrapper.inputTypes.FILE_INPUT,
          label: 'Laporan Laba Rugi',
          key: 'tmplLapLabaRugi',
        },
        {
          inputType: FormWrapper.inputTypes.FILE_INPUT,
          label: 'Laporan Perubahan Ekuitas',
          key: 'tmplLapPerubahanEkuitas',
        },
        {
          inputType: FormWrapper.inputTypes.FILE_INPUT,
          label: 'Laporan Arus Kas',
          key: 'tmplLapArusKas',
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "Fiscal Correction Detail Profit and Loss",
        },
        {
          inputType: FormWrapper.inputTypes.FILE_INPUT,
          label: 'Rekonsiliasi Laporan Keuangan',
          key: 'tmplRekonLapKeuangan',
        },
        {
          inputType: FormWrapper.inputTypes.FILE_INPUT,
          label: 'Transkrip Laporan Posisi Keuangan',
          key: 'tmplTranskipKeuangan',
        },
        {
          inputType: FormWrapper.inputTypes.FILE_INPUT,
          label: 'Laporan Koreksi Fiskal',
          key: 'tmplLapKoreksiFiskal',
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {

          if(data.tmplLapPosisiKeuangan) {
            if(data.tmplLapPosisiKeuangan.type) {
              var tmplLapPosisiKeuangan = new FormData()
              tmplLapPosisiKeuangan.set('file', new File([data.tmplLapPosisiKeuangan], `${data.nomor}__${data.tmplLapPosisiKeuangan.name}`, {type: data.tmplLapPosisiKeuangan.type}))
              var tmplLapPosisiKeuanganRes = await service.http.post('/upload-template', tmplLapPosisiKeuangan)
              if(tmplLapPosisiKeuanganRes.data) data.tmplLapPosisiKeuangan = tmplLapPosisiKeuanganRes.data.name
            } else {
              data.tmplLapPosisiKeuangan =data.tmplLapPosisiKeuangan.name
            }
          }

          if(data.tmplLapLabaRugi) {
            if(data.tmplLapLabaRugi.type) {
              var tmplLapLabaRugi = new FormData()
              tmplLapLabaRugi.set('file', new File([data.tmplLapLabaRugi], `${data.nomor}__${data.tmplLapLabaRugi.name}`, {type: data.tmplLapLabaRugi.type}))
              var tmplLapLabaRugiRes = await service.http.post('/upload-template', tmplLapLabaRugi)
              if(tmplLapLabaRugiRes.data) data.tmplLapLabaRugi = tmplLapLabaRugiRes.data.name
            } else {
              data.tmplLapLabaRugi = data.tmplLapLabaRugi.name
            }

          }

          if(data.tmplLapPerubahanEkuitas) {
            if(data.tmplLapPerubahanEkuitas.type) {
              var tmplLapPerubahanEkuitas = new FormData()
              tmplLapPerubahanEkuitas.set('file', new File([data.tmplLapPerubahanEkuitas], `${data.nomor}__${data.tmplLapPerubahanEkuitas.name}`, {type: data.tmplLapPerubahanEkuitas.type}))
              var tmplLapPerubahanEkuitasRes = await service.http.post('/upload-template', tmplLapPerubahanEkuitas)
              if(tmplLapPerubahanEkuitasRes.data) data.tmplLapPerubahanEkuitas = tmplLapPerubahanEkuitasRes.data.name
            } else {
              data.tmplLapPerubahanEkuitas = tmplLapPerubahanEkuitasRes.data.name
            }

          }

          if(data.tmplLapArusKas) {
            if(data.tmplLapArusKas.type) {var tmplLapArusKas = new FormData()
              tmplLapArusKas.set('file', new File([data.tmplLapArusKas], `${data.nomor}__${data.tmplLapArusKas.name}`, {type: data.tmplLapArusKas.type}))
              var tmplLapArusKasRes = await service.http.post('/upload-template', tmplLapArusKas)
              if(tmplLapArusKasRes.data) data.tmplLapArusKas = tmplLapArusKasRes.data.name
            } else {
              data.tmplLapArusKas = data.tmplLapArusKas.name
            }
          }


          if(data.tmplRekonLapKeuangan) {
            if(data.tmplRekonLapKeuangan.type) {
              var tmplRekonLapKeuangan = new FormData()
              tmplRekonLapKeuangan.set('file', new File([data.tmplRekonLapKeuangan], `${data.nomor}__${data.tmplRekonLapKeuangan.name}`, {type: data.tmplRekonLapKeuangan.type}))
              var tmplRekonLapKeuanganRes = await service.http.post('/upload-template', tmplRekonLapKeuangan)
              if(tmplRekonLapKeuanganRes.data) data.tmplRekonLapKeuangan = tmplRekonLapKeuanganRes.data.name
            } else {
              data.tmplRekonLapKeuangan = data.tmplRekonLapKeuangan.name
            }

          }

          if(data.tmplTranskipKeuangan) {
            if(data.tmplTranskipKeuangan.type) {
              var tmplTranskipKeuangan = new FormData()
              tmplTranskipKeuangan.set('file', new File([data.tmplTranskipKeuangan], `${data.nomor}__${data.tmplTranskipKeuangan.name}`, {type: data.tmplTranskipKeuangan.type}))
              var tmplTranskipKeuanganRes = await service.http.post('/upload-template', tmplTranskipKeuangan)
              if(tmplTranskipKeuanganRes.data) data.tmplTranskipKeuangan = tmplTranskipKeuanganRes.data.name
            } else {
              data.tmplTranskipKeuangan =data.tmplTranskipKeuangan.name
            }

          }

          if(data.tmplLapKoreksiFiskal) {
            if(data.tmplLapKoreksiFiskal.type) {
              var tmplLapKoreksiFiskal = new FormData()
              tmplLapKoreksiFiskal.set('file', new File([data.tmplLapKoreksiFiskal], `${data.nomor}__${data.tmplLapKoreksiFiskal.name}`, {type: data.tmplLapKoreksiFiskal.type}))
              var tmplLapKoreksiFiskalRes = await service.http.post('/upload-template', tmplLapKoreksiFiskal)
              if(tmplLapKoreksiFiskalRes.data) data.tmplLapKoreksiFiskal = tmplLapKoreksiFiskalRes.data.name
            } else {
              data.tmplLapKoreksiFiskal = data.tmplLapKoreksiFiskal.name
            }

          }

          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/industri')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(TipeIndustriForm))
