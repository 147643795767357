import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import service from './SlipGaji.service'
import PerusahaanService from '../../master/Perusahaan/Perusahaan.service'
import TipeHubunganIstimewaService from '../../adminpajakku/tipeHubunganIstimewa/TipeHubunganIstimewa.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import TipePegawaiService from '../../adminpajakku/tipePegawai/TipePegawai.service'
import PerhitunganPPh21LainnyaService from '../PerhitunganPPh21Lainnya/PerhitunganPPh21Lainnya.service'
import PenambahPhBrutoService from '../../master/Pendapatan/Pendapatan.service'
import PengurangPhBrutoService from '../../master/Pengurang/Pengurang.service'
import Utils from '../../../libs/custom/Utils'
import PegawaiService from '../Pegawai/Pegawai.service'
import MasterLevelService from '../../master/MasterLevel/MasterLevel.service';
import cloneDeep from 'lodash/cloneDeep'
import { List, ListItem,  ListSubheader} from "@react-md/list";
import { ID_TIPE_PEGAWAI } from '../../../clientConstant'

const SlipGajiForm = ({envStore, match, history, data, onSubmit, sipStore, navigationStore, setShowInfoPegawai}) => {
  const { inputTypes, definition } = FormWrapper

  const [perusahaanList, setPerusahaanList] = useState([])
  const [tipeHubunganIstimewaList, setTipeHubunganIstimewaList] = useState([])
  const [entitasList, setEntitasList] = useState([])
  const [tipePegawaiList, setTipePegawaiList] = useState([])
  const [perhitunganPPh21LainnyaList, setPerhitunganPPh21LainnyaList] = useState([])
  const [pegawaiList, setPegawaiList] = useState([])
  const [pegawaiMap, setPegawaiMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      if(match.params.id != 'baru') {
        setIsEdit(true)
      }
      await _getPerusahaanList()
      await _getTipeHubunganIstimewaList()
      await _getEntitasList()
      await _getTipePegawaiList()
      await _getPerhitunganPPh21LainnyaList()
      _getPegawaiList({
        'entitasId.equals': data.content.entitasId
      })
    }
    initData()
  }, ['match.params.id'])

  async function _getPerusahaanList() {
    var res = await PerusahaanService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTipeHubunganIstimewaList() {
    var res = await TipeHubunganIstimewaService.get()
    setTipeHubunganIstimewaList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'tipeEntitas.notEquals': 'Cabang Pembantu', 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPegawaiList(param) {
    if(!param) param = {}

    param.size= 10000000000000
    var res = await PegawaiService.get(param)
    setPegawaiMap(res.data.reduce((pv,cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setPegawaiList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTipePegawaiList() {
    var res = await TipePegawaiService.get()
    setTipePegawaiList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPerhitunganPPh21LainnyaList() {
    var res = await PerhitunganPPh21LainnyaService.get()
    setPerhitunganPPh21LainnyaList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
    noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      showCommandbar={false}
      backTo={`/pph-menu/slip`}
      baseId="mod-form-sample"
      title={t.translate('modules.SlipGaji.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      editable={false}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName='mdi mdi-arrow-right'
      onSubmit={onSubmit}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'entitasId') {

        }

        if(key == 'tipePegawai__id') {
          if(value == ID_TIPE_PEGAWAI.TETAP) {
            setShowInfoPegawai(true)
          } else {
            setShowInfoPegawai(false)
          }
        }

        if(key == 'pegawaiId') {
          var pegawai = cloneDeep(pegawaiMap[value]?pegawaiMap[value]:{})
          delete pegawai.id
          delete pegawai.gaji.id
          delete pegawai.gaji.penambahs
          delete pegawai.gaji.pengurangs
          pegawai.gaji__penambahs = []
          pegawai.gaji__pengurangs = []
          pegawai.gaji.penambahs.forEach((d)=> {
            pegawai.gaji__penambahs.push({
              keterangan: d.keterangan,
              nilai: d.nilai,
              akunInfo__nomor: d.penambahPhBruto.tipePenambahPhBruto.akun?d.penambahPhBruto.tipePenambahPhBruto.akun.nomor:'',
              akunInfo__nama: d.penambahPhBruto.tipePenambahPhBruto.akun?d.penambahPhBruto.tipePenambahPhBruto.akun.nama:'',
              akunInfo__keterangan: d.penambahPhBruto.tipePenambahPhBruto.akun?d.penambahPhBruto.tipePenambahPhBruto.akun.keterangan:'',
              kopInfo__nama: d.penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak?d.penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak.nama:'',
              kopInfo__keterangan: d.penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak?d.penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak.keterangan:'',
              penghasilanInfo__nama: d.penambahPhBruto.nama,
              penghasilanInfo__keterangan: d.penambahPhBruto.keterangan,
              penghasilanInfo__alias: d.penambahPhBruto.alias,
              tipePenghasilanInfo__nama: d.penambahPhBruto.tipePenambahPhBruto.nama,
              tipePenghasilanInfo__keterangan: d.penambahPhBruto.tipePenambahPhBruto.keterangan,
              tipePenghasilanInfo__kode: d.penambahPhBruto.tipePenambahPhBruto.kode,
            })
          })
          pegawai.gaji.pengurangs.forEach((d)=> {
            pegawai.gaji__pengurangs.push({
              keterangan: d.keterangan,
              nilai: d.nilai,
              akunInfo__nomor: d.pengurangPhBruto.tipePengurangPhBruto.akun?d.pengurangPhBruto.tipePengurangPhBruto.akun.nomor:'',
              akunInfo__nama: d.pengurangPhBruto.tipePengurangPhBruto.akun?d.pengurangPhBruto.tipePengurangPhBruto.akun.nama:'',
              akunInfo__keterangan: d.pengurangPhBruto.tipePengurangPhBruto.akun?d.pengurangPhBruto.tipePengurangPhBruto.akun.keterangan:'',
              kopInfo__nama: d.pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak?d.pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak.nama:'',
              kopInfo__keterangan: d.pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak?d.pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak.keterangan:'',
              penghasilanInfo__nama: d.pengurangPhBruto.nama,
              penghasilanInfo__keterangan: d.pengurangPhBruto.keterangan,
              penghasilanInfo__alias: d.pengurangPhBruto.alias,
              tipePenghasilanInfo__nama: d.pengurangPhBruto.tipePengurangPhBruto.nama,
              tipePenghasilanInfo__keterangan: d.pengurangPhBruto.tipePengurangPhBruto.keterangan,
              tipePenghasilanInfo__kode: d.pengurangPhBruto.tipePengurangPhBruto.kode,
            })
          })

          pegawai = Utils.formSerialize(pegawai)

          newObj = {
            ...newObj,
            ...pegawai
          }

          console.log(pegawai, 'asdas')
        }

        return newObj
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFO DASAR',
        },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Perusahaan',
        //   key: 'perusahaanId',
        //   type: 'text',
        //   width: '100%',
        //   options: perusahaanList,
        //   disabled: sipStore.companyId != null,
        //   validation: 'required',
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitas__id',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          // required: true,
          options: entitasList,
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Alias',
          key: 'alias',
          type: 'text',
          width: '49%',
          disabled: isEdit
          // required: true,
        },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Pegawai',
        //   key: 'pegawaiId',
        //   type: 'text',
        //   width: '100%',
        //   style: {marginRight: '2%'},
        //   // required: true,
        //   options: pegawaiList,
        //   validation: 'required',
        //   disabled: isEdit
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Tipe Pegawai',
          key: 'tipePegawai__id',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          options: tipePegawaiList,
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama SlipGaji',
          key: 'nama',
          type: 'text',
          width: '49%',
          // required: true,
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.SELECT,
          label: 'Domisili Wajib Pajak',
          key: 'domisiliWajibPajak',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          options: [{value: "DN", label: "DN"}, {value: "LN", label: "LN"}],
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Negara',
          key: 'negara',
          type: 'text',
          width: '49%',
          validation: 'required',
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP',
          key: 'npwp',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          // required: true,
          validation: 'required',
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '################',
          maskChar: '_',
          maskValue: 'string',
          label: 'NIK',
          key: 'nik',
          type: 'text',
          width: '49%',
          validation: 'required',
          disabled: isEdit,
          // required: true,
          show: (d) => d.domisiliWajibPajak == 'DN'
        },{
          inputType: inputTypes.INPUT,
          // mask: '################',
          // maskChar: '_',
          // maskValue: 'string',
          label: 'Passport',
          key: 'nik',
          type: 'text',
          width: '49%',
          validation: 'required',
          disabled: isEdit,
          // required: true,
          show: (d) => d.domisiliWajibPajak == 'LN'
        },{
          inputType: inputTypes.SELECT,
          label: 'Jenis Kelamin',
          key: 'jenisKelamin',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required',
          options: [
            { label: 'Laki - Laki', value: 'L' },
            { label: 'Perempuan', value: 'P' }
          ]
        },{
          inputType: inputTypes.SELECT,
          label: 'PTKP',
          key: 'ptkp',
          type: 'text',
          width: '49%',
          // required: true,
          validation: 'required',
          disabled: isEdit,
          options: [
            { label: 'TK/0', value: 'TK/0' },
            { label: 'TK/1', value: 'TK/1' },
            { label: 'TK/2', value: 'TK/2' },
            { label: 'TK/3', value: 'TK/3' },
            { label: 'K/0',  value: 'K/0' },
            { label: 'K/1',  value: 'K/1' },
            { label: 'K/2',  value: 'K/2' },
            { label: 'K/3',  value: 'K/3' },
            { label: 'KI/0', value: 'KI/0' },
            { label: 'KI/1', value: 'KI/1' },
            { label: 'KI/2', value: 'KI/2' },
            { label: 'KI/3', value: 'KI/3' }
          ]
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Tempat Lahir',
          key: 'tempatLahir',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Lahir',
          key: 'tanggalLahir',
          width: '49%',
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Alamat',
          key: 'alamat',
          type: 'text',
          width: '100%',
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kecamatan',
          key: 'kecamatan',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kelurahan',
          key: 'kelurahan',
          type: 'text',
          width: '49%',
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Provinsi',
          key: 'provinsi',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kota',
          key: 'kota',
          type: 'text',
          width: '49%',
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Telepon',
          key: 'telepon',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Pos',
          key: 'kodePos',
          type: 'text',
          width: '49%',
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Agama',
          key: 'agama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'email',
          type: 'text',
          width: '49%',
          disabled: isEdit,
          validation: 'email'
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Kode Negara',
        //   key: 'kodeNegara',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   validation: 'required',
        //   disabled: isEdit,
        // },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Perhitungan PPh 21 Lainnya',
        //   key: 'perhitunganPPh21LainnyaId',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   options: perhitunganPPh21LainnyaList,
        //   disabled: isEdit,
        // },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Status',
        //   key: 'status',
        //   type: 'text',
        //   width: '49%',
        //   disabled: isEdit,
        // },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
          width: '100%',
          disabled: isEdit
        },
        // -----
      ]}
    />
  )
}

const PekerjaanForm = ({envStore, match, history, data, onSubmit, sipStore}) => {
  const { inputTypes, definition } = FormWrapper

  const [isEdit, setIsEdit] = useState(false)
  const [masterLevelList, setMasterLevelList] = useState([]);

  useEffect(() => {
    async function initData() {
      if(match.params.id != 'baru') {
        setIsEdit(true)
      }

      await _getMasterLevelList();
    }
    initData()
  }, ['match.params.id'])

  async function _getMasterLevelList() {
    var res = await MasterLevelService.get({ "perusahaanId.equals": sipStore.companyId });
    setMasterLevelList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      showCommandbar={false}
      // backTo={`/onpremise/SlipGaji`}
      backTo={`/pph-menu/slip`}
      baseId="mod-form-sample"
      title={t.translate('modules.SlipGaji.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      editable={false}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName='mdi mdi-arrow-right'
      defaultData={data.content}
      onSubmit={onSubmit}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFO PEKERJAAN',
        },
        {
          inputType: inputTypes.INPUT,
          label: 'NIP',
          key: 'nip',
          type: 'text',
          width: '100%',
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.INPUT,
          label: "Divisi",
          key: "divisi",
          type: "text",
          style: { marginRight: "2%" },
          width: "49%",
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Sub Divisi',
          key: 'departemen',
          type: 'text',
          width: "49%",
          style: { marginRight: "2%" },
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Jabatan',
          key: 'jabatan',
          type: 'text',
          width: '49%',
          disabled: isEdit
        },
        {
          inputType: inputTypes.SELECT,
          label: "Level Pegawai",
          key: "level__id",
          type: "text",
          width: "49%",
          options: masterLevelList,
          disabled: isEdit,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Status Pindahan",
          key: "gaji__statusPindahan",
          type: "text",
          validation: "required",
          show: (d) => data.content.status == "AKTIF",
          options: [
            { label: "Normal", value: "00" },
            {
              label:
                "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",
              value: "03",
            },
            { label: "Pegawai baru mulai bekerja", value: "04" },
            {
              label:
                "Pegawai Pindahan dari kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama/Pegawai Baru dari pemberi kerja lain/Pegawai Baru saja Pensiun untuk 21-100-02",
              value: "05",
            },
          ],
        },
        {
          inputType: inputTypes.SELECT,
          label: "Status Pindahan",
          key: "gaji__statusPindahan",
          type: "text",
          validation: "required",
          show: (d) => data.content.status == "TIDAK_AKTIF",
          options: [
            {
              label:
                "Dipindahkan ke kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama",
              value: "01",
            },
            {
              label:
                "Berhenti ke pemberi kerja lain/Berhenti tidak meninggalkan Indonesia/Berhenti karena pensiun",
              value: "02",
            },
            {
              label:
                "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",
              value: "03",
            },
          ],
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Masuk',
          key: 'gaji__tanggalMasuk',
          width: '49%',
          validation: 'required',
          style: {marginRight: '2%'},
          disabled: isEdit
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Keluar',
          key: 'gaji__tanggalKeluar',
          show: (d)=> d.gaji__statusPindahan == "01" || d.gaji__statusPindahan == "02" || d.gaji__statusPindahan == "03",
          validation: [{required_if: ['gaji__statusPindahan', "01"]}, {required_if: ['gaji__statusPindahan', "02"]}, {required_if: ['gaji__statusPindahan', "03"]}],
          width: '49%',
          disabled: isEdit
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'Pernah Bekerja diperusahaan lain',
          key: 'gaji__adaKantorLama',
          type: 'text',
          disabled: isEdit
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'Memiliki A1 Dari perusahaan lain',
          key: 'gaji__adaA1KantorLama',
          type: 'text',
          show: (d)=> d.gaji__adaKantorLama,
          disabled: isEdit
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'BPJS',
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'BPJS Kesehatan',
          key: 'gaji__bpjsKes',
          type: 'number',
          disabled: isEdit,
          show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'BPJS Kesehatan Ditanggung Perusahaan',
          key: 'gaji__bpjsKesDitanggungPrshn',
          type: 'number',
          disabled: isEdit,
          show: (d)=> d.gaji__bpjsKes && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP
        },{
          inputType: inputTypes.SELECT,
          label: 'Dasar Perhitungan BPJS Kesehatan',
          key: 'gaji__dasarHitungBPJSKes',
          type: 'text',
          width: '49%',
          validation: 'required',
          style: {marginRight: '2%'},
          disabled: isEdit,
          show: (d)=> d.gaji__bpjsKes && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          options: [
            { label: "GAJI", value: "GAJI" },
            { label: "LAINNYA", value: "LAINNYA" }
          ]
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nominal Lainnya jika dipilih',
          key: 'gaji__nominalDasarHitungBPJSKes',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          show: (d)=> d.gaji__dasarHitungBPJSKes == 'LAINNYA' && d.gaji__bpjsKes && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          validation: [{required_if: ['gaji__dasarHitungBPJSKes', "LAINNYA"]}],
          disabled: isEdit
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'BPJS Ketenagakerjaan',
          key: 'gaji__bpjstk',
          disabled: isEdit,
          show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP
        },{
          inputType: inputTypes.SELECT,
          label: 'Kelompok BPJS',
          key: 'gaji__bpjstkKelompok',
          disabled: isEdit,
          show: (d)=> d.gaji__bpjstk && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          options: [
            { value: "I",   label: "KELOMPOK 1" },
            { value: "II",  label: "KELOMPOK 2" },
            { value: "III", label: "KELOMPOK 3" },
            { value: "IV",  label: "KELOMPOK 4" },
            { value: "V",   label: "KELOMPOK 5" }
          ]
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'BPJS Ketenagakerjaan Ditanggung Perusahaan',
          key: 'gaji__bpjsDitanggungPmrth',
          disabled: isEdit,
          show: (d)=> d.gaji__bpjstk && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },{
          inputType: inputTypes.SELECT,
          label: 'Dasar Perhitungan BPJS Ketenagakerjaan',
          key: 'gaji__dasarHitungBPJSTK',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit,
          show: (d)=> d.gaji__bpjstk && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          options: [
            { label: "GAJI", value: "GAJI" },
            { label: "LAINNYA", value: "LAINNYA" }
          ]
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nominal Lainnya jika dipilih',
          key: 'gaji__nominalDasarHitungBPJSTK',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          show: (d)=> d.gaji__dasarHitungBPJSTK == 'LAINNYA' && d.gaji__bpjstk && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          validation: [{required_if: ['gaji__dasarHitungBPJSTK', "LAINNYA"]}],
          disabled: isEdit
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'Mendapat Jaminan Pensiun',
          key: 'gaji__dapatJaminanPensiun',
          type: 'number',
          disabled: isEdit,
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP
        },
      ]}
  />
  )
}

const SlipGajiGajiForm = ({envStore, match, history, data, onSubmit}) => {
  const { inputTypes, definition } = FormWrapper

  const [penambahPhBrutoList, setPenambahPhBrutoList] = useState([])
  const [pengurangPhBrutoList, setPengurangPhBrutoList] = useState([])
  const [penambahPhBrutoMap, setPenambahPhBrutoMap] = useState({})
  const [pengurangPhBrutoMap, setPengurangPhBrutoMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    async function initData() {
      if(match.params.id != 'baru') {
        setIsEdit(true)
      }
      await _getPenambahPhBrutoList()
      await _getPengurangPhBrutoList()
    }
    initData()
  }, ['match.params.id'])

  async function _getPenambahPhBrutoList() {
    var res = await PenambahPhBrutoService.get()
    setPenambahPhBrutoList(res.data.map(x => ({value: x.id, label: x.nama})))
    setPenambahPhBrutoMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getPengurangPhBrutoList() {
    var res = await PengurangPhBrutoService.get()
    setPengurangPhBrutoList(res.data.map(x => ({value: x.id, label: x.nama})))
    setPengurangPhBrutoMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  return <FormWrapper
  noValidate
  loading={data.loading}
  actions={[
    {
      label: 'Reload',
      iconClassName: 'mdi mdi-reload'
    }
  ]}
  showCommandbar={false}
  backTo={`/pph-menu/slip`}
  baseId="mod-form-sample"
  title={t.translate('modules.SlipGaji.formTitle')}
  // hintMessage={t.translate('modules.form.hint.message')}
  editable={false}
  hintShowIcon={false}
  defaultData={data.content}
  onSubmit={onSubmit}
  onChange={(newObj, key, value, parentKey, indexAtParent) => {
    if(parentKey == 'gaji__penambahs') {
      if(key == 'penambahPhBruto__id') {
        var penambahPhBruto = penambahPhBrutoMap[value]
        newObj.gaji__penambahs[indexAtParent].penghasilanInfo__nama = penambahPhBruto.nama
        newObj.gaji__penambahs[indexAtParent].penghasilanInfo__alias = penambahPhBruto.alias
        newObj.gaji__penambahs[indexAtParent].penghasilanInfo__keterangan = penambahPhBruto.keterangan

        newObj.gaji__penambahs[indexAtParent].akunInfo__nama = penambahPhBruto.akun?penambahPhBruto.akun.nama:''
        newObj.gaji__penambahs[indexAtParent].akunInfo__nomor = penambahPhBruto.akun?penambahPhBruto.akun.nomor:''
        newObj.gaji__penambahs[indexAtParent].akunInfo__keterangan = penambahPhBruto.akun?penambahPhBruto.akun.keterangan:''

        if(penambahPhBruto.tipePenambahPhBruto) {
        newObj.gaji__penambahs[indexAtParent].tipePenghasilanInfo__nama = penambahPhBruto.tipePenambahPhBruto.nama
        newObj.gaji__penambahs[indexAtParent].tipePenghasilanInfo__kode = penambahPhBruto.tipePenambahPhBruto.alias
        newObj.gaji__penambahs[indexAtParent].tipePenghasilanInfo__keterangan = penambahPhBruto.tipePenambahPhBruto.keterangan

        newObj.gaji__penambahs[indexAtParent].kopInfo__nama = penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak?penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak.nama:''
        newObj.gaji__penambahs[indexAtParent].kopInfo__kode = penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak?penambahPhBruto.tipePenambahPhBruto.kodeObjekPajak.nomor:''
        }
      }
    }
    if(parentKey == 'gaji__pengurangs') {
      if(key == 'pengurangPhBruto__id') {
        var pengurangPhBruto = pengurangPhBrutoMap[value]
        newObj.gaji__pengurangs[indexAtParent].penghasilanInfo__nama = pengurangPhBruto.nama
        newObj.gaji__pengurangs[indexAtParent].penghasilanInfo__alias = pengurangPhBruto.alias
        newObj.gaji__pengurangs[indexAtParent].penghasilanInfo__keterangan = pengurangPhBruto.keterangan

        newObj.gaji__pengurangs[indexAtParent].akunInfo__nama = pengurangPhBruto.akun?pengurangPhBruto.akun.nama:''
        newObj.gaji__pengurangs[indexAtParent].akunInfo__nomor = pengurangPhBruto.akun?pengurangPhBruto.akun.nomor:''
        newObj.gaji__pengurangs[indexAtParent].akunInfo__keterangan = pengurangPhBruto.akun?pengurangPhBruto.akun.keterangan:''

        if(pengurangPhBruto.tipePengurangPhBruto) {
        newObj.gaji__pengurangs[indexAtParent].tipePenghasilanInfo__nama = pengurangPhBruto.tipePengurangPhBruto.nama
        newObj.gaji__pengurangs[indexAtParent].tipePenghasilanInfo__kode = pengurangPhBruto.tipePengurangPhBruto.alias
        newObj.gaji__pengurangs[indexAtParent].tipePenghasilanInfo__keterangan = pengurangPhBruto.tipePengurangPhBruto.keterangan

        newObj.gaji__pengurangs[indexAtParent].kopInfo__nama = pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak?pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak.nama:''
        newObj.gaji__pengurangs[indexAtParent].kopInfo__kode = pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak?pengurangPhBruto.tipePengurangPhBruto.kodeObjekPajak.nomor:''
        }
      }
    }
  }}
  definitions={[
    // =====
    {
      inputType: inputTypes.DIVIDER,
      label: 'SlipGaji GAJI',
    },
    {
      inputType: inputTypes.SELECT,
      label: 'Metode Pemotongan Pajak',
      key: 'gaji__metodePotongPajak',
      type: 'text',
      // required: true,
      validation: 'required',
      disabled: isEdit,
      options: [
        { label: "Nett",     value: "Nett"},
        { label: "Gross",    value: "Gross"},
        { label: "Gross Up", value: "Gross Up"}
      ]
    },

    {
      inputType: inputTypes.DIVIDER,
      label: 'KARYAWAN TIDAK TETAP',
      show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP
    },

    {
      inputType: inputTypes.SELECT,
      label: 'Tipe Penghasilan',
      key: 'perhitunganPPh21Lainnya__tipePenghasilan',
      type: 'text',
      width: '49%',
      style: {marginRight: '2%'},
      disabled: isEdit,
      validation:  [{required_if: ['tipePegawai__id', ID_TIPE_PEGAWAI.TIDAK_TETAP]}],
      options: [
        { label: "BERKESINAMBUNGAN",         value: "BG" },
        { label: "TIDAK BERKESINAMBUNGAN",   value: "TBG" }
      ],
      show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP
    },
    {
      inputType: inputTypes.SELECT,
      label: 'Dipotong Oleh',
      key: 'perhitunganPPh21Lainnya__dipotongOleh',
      type: 'text',
      width: '49%',
      disabled: isEdit,
      validation:  [{required_if: ['tipePegawai__id', ID_TIPE_PEGAWAI.TIDAK_TETAP]}],
      options: [
        { label: "SATU PEMBERI KERJA",             value: "SPK" },
        { label: "LEBIH DARI SATU PEMBERI KERJA",  value: "LSPK" }
      ],
      show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP
    },
    {
      inputType: inputTypes.SELECT,
      label: 'Cara Pembayaran',
      key: 'perhitunganPPh21Lainnya__caraPembayaran',
      type: 'text',
      width: '49%',
      style: {marginRight: '2%'},
      disabled: isEdit,
      validation:  [{required_if: ['tipePegawai__id', ID_TIPE_PEGAWAI.TIDAK_TETAP]}],
      options: [
        { label: "BULANAN",        value: "B" },
        { label: "TIDAK BULANAN",  value: "TB" }
      ],
      show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP
    },
    {
      inputType: inputTypes.INPUT_MASK_NUMBER,
      label: 'Jumlah Hari Kerja',
      width: '49%',
      key: 'perhitunganPPh21Lainnya__jumlahHariKerja',
      type: 'number',
      disabled: isEdit,
      validation:  [{required_if: ['tipePegawai__id', ID_TIPE_PEGAWAI.TIDAK_TETAP]}],
      show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP
    },
    {
      inputType: inputTypes.SELECT,
      label: 'Golongan PNS',
      key: 'perhitunganPPh21Lainnya__golonganPNS',
      type: 'text',
      disabled: isEdit,
      options: [
        { label: "I",     value: "I" },
        { label: "II",    value: "II" },
        { label: "III",   value: "III" },
        { label: "IV",    value: "IV" }
      ],
      // show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP
    },
   {
      inputType: FormWrapper.inputTypes.LIST,
      label: 'SlipGaji Gaji Penambah',
      key: 'gaji__penambahs',
      layout: 'table',
      style: {width: 900},
      hideActionButton: true,
      conditionStyle: (data)=> {
        if(data.bukanSlipGaji) {
          return {background: '#fff3e0'}
        }
      },
      definitions: [
        {
          render: (d, parentKey, indexAtParent)=> {
            if(d.gaji__penambahs[indexAtParent] && d.gaji__penambahs[indexAtParent].penghasilanInfo) {
              return d.gaji__penambahs[indexAtParent].penghasilanInfo.nama
            }
            return ''
          }
        },
        // {
        //   inputType: FormWrapper.inputTypes.SELECT,
        //   label: 'Pilih Penambah Ph Bruto',
        //   key: 'penambahPhBruto__id',
        //   theme: 'filled',
        //   // validation: 'required',
        //   options: penambahPhBrutoList,
        // },
        // {
        //   render: (d, parentKey, indexAtParent)=> {

        //     console.log(d, parentKey, indexAtParent)
        //     return <><List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__penambahs[indexAtParent].penghasilanInfo__nama}
        //         threeLines
        //       >
        //         Nama Penambah
        //       </ListItem>
        //     </List>
        //     <List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__penambahs[indexAtParent].tipePenghasilanInfo__nama}
        //         threeLines
        //       >
        //         Jenis Penghasilan
        //       </ListItem>
        //     </List>

        //     <List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__penambahs[indexAtParent].kopInfo__nama}
        //         threeLines
        //       >
        //         KOP
        //       </ListItem>
        //     </List>
        //     <List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__penambahs[indexAtParent].akunInfo__nama}
        //         threeLines
        //       >
        //         AKUN
        //       </ListItem>
        //     </List>
        //     </>
        //   }
        // },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'nilai',
          theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required|numeric'
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          theme: 'filled'
        }
      ]
    },
    {render: (d)=> {
      var totalPenambah = 0;
      if(d.gaji__penambahs) {
        d.gaji__penambahs.forEach((x) => {
          if(!x.bukanSlipGaji && x.nilai) {
            totalPenambah += parseInt(x.nilai)
          }
        })
      }
      return <div style={{margin: '20px', width: '100%', fontSize: '20px'}}>
        <hr/>
        <div className="mpk-flex align-start direction-row">Total Penambah : <div className="flex" />{Utils.numberFormat(totalPenambah)}</div>
      </div>
    }},
    {
      inputType: FormWrapper.inputTypes.LIST,
      label: 'SlipGaji Gaji Pengurang',
      key: 'gaji__pengurangs',
      layout: 'table',
      style: {width: 900},
      hideActionButton: true,
      conditionStyle: (data)=> {
        if(data.bukanSlipGaji) {
          return {background: '#fff3e0'}
        }
      },
      definitions: [
        {
          render: (d, parentKey, indexAtParent)=> {
            if(d.gaji__pengurangs[indexAtParent] && d.gaji__pengurangs[indexAtParent].penghasilanInfo) {
              return d.gaji__pengurangs[indexAtParent].penghasilanInfo.nama
            }
            return null
          }
        },
        // {
        //   inputType: FormWrapper.inputTypes.SELECT,
        //   label: 'Pengurang Ph Bruto',
        //   key: 'pengurangPhBruto__id',
        //   theme: 'filled',
        //   // validation: 'required',
        //   options: pengurangPhBrutoList,
        // },
        // {
        //   render: (d, parentKey, indexAtParent)=> {

        //     console.log(d, parentKey, indexAtParent)
        //     return <><List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__pengurangs[indexAtParent].penghasilanInfo__nama}
        //         threeLines
        //       >
        //         Nama Pengurang
        //       </ListItem>
        //     </List>
        //     <List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__pengurangs[indexAtParent].tipePenghasilanInfo__nama}
        //         threeLines
        //       >
        //         Jenis Penghasilan
        //       </ListItem>
        //     </List>

        //     <List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__pengurangs[indexAtParent].kopInfo__nama}
        //         threeLines
        //       >
        //         KOP
        //       </ListItem>
        //     </List>
        //     <List style={{width: '50%'}}>
        //       <ListItem
        //         secondaryText={d.gaji__pengurangs[indexAtParent].akunInfo__nama}
        //         threeLines
        //       >
        //         AKUN
        //       </ListItem>
        //     </List>
        //     </>
        //   }
        // },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'nilai',
          theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required|numeric'
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          theme: 'filled'
        }
      ]
    },
    {render: (d)=> {
      var totalPenambah = 0;
      if(d.gaji__penambahs) {
        d.gaji__penambahs.forEach((x) => {
          if(!x.bukanSlipGaji && x.nilai) {
            totalPenambah += parseInt(x.nilai)
          }
        })
      }
      var totalPengurang = 0;
      if(d.gaji__pengurangs) {
        d.gaji__pengurangs.forEach((x) => {
          if(!x.bukanSlipGaji && x.nilai) {
            totalPengurang += parseInt(x.nilai)
          }
        })
      }
      return <div style={{margin: '20px', width: '100%', fontSize: '20px'}}>
        <hr/>
        <div className="mpk-flex align-start direction-row">Total Penambah : <div className="flex" />{Utils.numberFormat(totalPengurang)}</div>
        <div className="mpk-flex align-start direction-row">THP : <div className="flex" />{Utils.numberFormat(totalPenambah-totalPengurang)}</div>
      </div>
    }},
]}
/>
}

const SlipGajiWrapper = ({envStore, match, history, sipStore, navigationStore}) => {
  // initial data
  const [data, setData] = useState({loading: true, content: {}})
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }
  const [showInfoPegawai, setShowInfoPegawai] = useState(true)

  useEffect(() => {
    async function initData() {
      if(match.params.id == 'baru') {
        var pemotongan = localStorage['sip-menu-pph']?JSON.parse(localStorage['sip-menu-pph']):{}

        console.log(pemotongan, 'pemotongan')
        setData({loading: false, content: {
          "pemotonganPPh2126Id": pemotongan.id,
          perusahaanId: sipStore.companyId,
          entitasId: pemotongan.entitas.id,
          kodeNegara: '',
          gaji__pengurangs: [],
          gaji__penambahs: [],
          gaji__adaKantorLama: false,
          pphA1Sebelumnya: '0',
          gaji__adaA1KantorLama: false,
          nettoA1Sebelumnya: '0',
          gaji__dapatJaminanPensiun: false,
          gaji__bpjstk: false,
          gaji__bpjsDitanggungPmrth: false,
          gaji__dasarHitungBPJSTK: 'GAJI',
          gaji__dasarHitungBPJSKes: 'GAJI',
          gaji__nominalDasarHitungBPJSTK: '0',
          gaji__bpjsKes: false,
          gaji__bpjsKesDitanggungPrshn: false,
          gaji__nominalDasarHitungBPJSKes:'0',
          gaji__dapatJaminanPensiun: false,
          gaji__pengurangs: [],
          gaji__penambahs: []
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      res.data.gaji__penambahs = res.data.gaji__penambahs.filter(x => x.nilai != 0)
      res.data.gaji__pengurangs = res.data.gaji__pengurangs.filter(x => x.nilai != 0)
      if(res.data.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP) {
        setShowInfoPegawai(true)
      }  else {
        setShowInfoPegawai(false)
      }
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  return <TabsWrapper
    noValidate
    title="SlipGaji"
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-details-sample"
    actions={[
      {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/pph-menu/slip')}
    ]}
    tabs={[
      {
        label: 'Data',
        key: 'nested-form',
        render: <SlipGajiForm data={data} match={match} history={history}
          sipStore={sipStore} navigationStore={navigationStore}
          setShowInfoPegawai={setShowInfoPegawai}
          onSubmit={async (d, callback) => {
            // var res = {}
            // if(match.params.id == 'baru') {
            //   res = await service.post(data)
            // } else {
            //   res = await service.put(data)
            // }

            // callback(t.translate('global.dataTelahDiSimpan'), false)
            // history.push('/onpremise/SlipGaji')

            setData({loading: false, content: {...data.content, ...d}})
            console.log('data', data)
            callback('', false)
            setCurrentIndex(1)
          }}
        />
      },
      {
        label: 'Info Pekerjaan',
        key: 'data-pekerjaan',
        show: showInfoPegawai,
        render: <PekerjaanForm data={data} match={match} history={history}
        sipStore={sipStore} navigationStore={navigationStore}
          onSubmit={async (d, callback) => {
            // var res = {}
            // if(match.params.id == 'baru') {
            //   res = await service.post(data)
            // } else {
            //   res = await service.put(data)
            // }

            // callback(t.translate('global.dataTelahDiSimpan'), false)
            // history.push('/onpremise/SlipGaji')

            setData({loading: false, content: {...data.content, ...d}})
            console.log('data', data)
            callback('', false)
            setCurrentIndex(2)
          }}
        />
      },
      {
        label: 'Penghasilan dan Pemotongan',
        key: 'data-gaji',
        render: (
          <SlipGajiGajiForm data={data} match={match} history={history}
          onSubmit={async (d, callback) => {
            setData({...data.content, ...d})
            var res = {}
            try {
              d.gaji__pengurangs.forEach((d)=> {
                Utils.formNormalize(d)
              })
              d.gaji__penambahs.forEach((d)=> {
                Utils.formNormalize(d)
              })
              var nData = Utils.formNormalize({...data.content, ...d})
              if(match.params.id == 'baru') {
                res = await service.post(nData)
              } else {
                res = await service.put(nData)
              }

              callback(t.translate('global.dataTelahDiSimpan'), false)
              history.push('/onpremise/pph-menu/slip')
            } catch(e) {
              callback(e, true, false)
            }
          }}/>
        )
      },
    ]}
  />
}

export default inject('sipStore', 'navigationStore')(observer(SlipGajiWrapper))
