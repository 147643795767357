import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './SandingPPh21Akun.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'
import { inject, observer } from 'mobx-react'

var _baseId = "mod-table-sanding-aset"

const SandingPPh21AkunTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  sipStore,
  navigationStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Sanding Data PPh 21'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="namaAkun"
      columns={[
        {
          label: 'Nama Akun',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.namaAkun)
        },
        {
          label: 'Nomor Akun',
          searchable: true,
          key: 'nomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nomorAkun)
        },
        {
          label: 'Total',
          searchable: true,
          key: 'total',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.total))
        },
        {
          label: 'Belum dilakukan pemotongan',
          searchable: true,
          key: 'totalBelum',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.totalBelum))
        },
        {
          label: 'PPh Lainnya',
          searchable: true,
          key: 'totalPphLainnya',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.totalPphLainnya))
        },
        {
          label: 'PPh 21',
          searchable: true,
          key: 'totalPph21',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.totalPph21))
        },
        {
          label: 'Nominal Ph.Bruto',
          searchable: true,
          key: 'nominalPhBruto',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.nominalPhBruto))
        },

      ]}
      actions={[
        // new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/penyusutan-periode-aset/baru'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Detail Transaksi', 'mdi mdi-book-open', (item) => {
          history.push(`/onpremise/sanding-transaksi/${item.id}`)
        }, true),
        new TableWrapper.action('Detail Perhitungan', 'mdi mdi-book-open', (item) => {
          history.push(`/onpremise/sanding-perhitungan/${item.id}`)
        }, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.periodeId) {
          query['periodeFiskalId.equals'] = sipStore.periodeId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}


export default inject('sipStore', 'navigationStore')(observer(SandingPPh21AkunTable))
