import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Perusahaan.service'
import Utils from '../../../libs/custom/Utils'
import TipeIndustriListService from '../../adminpajakku/tipeIndustri/TipeIndustri.service'
import NegaraService from '../../adminpajakku/negara/Negara.service'
import SubSektorService from '../../adminpajakku/subSektor/SubSektor.service'

const PerusahaanForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [tipeIndustriList, setTipeIndustriList] = useState([])
  const [tipeIndustriMap, setTipeIndustriMap] = useState({})
  const [negaraList, setNegaraList] = useState([])
  const [subSektorList, setSubSektorList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [updatedData, setUpdatedData] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getTipeIndustriList()
      await _getNegaraList()
      await _getSubSektor()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {alias: '', status: '', keterangan: ''}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setUpdatedData(res.data)
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getTipeIndustriList() {
    var res = await TipeIndustriListService.get()
    setTipeIndustriList(res.data.map(x => ({value: x.id, label: x.nama})))
    setTipeIndustriMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getNegaraList() {
    var res = await NegaraService.get({size: 10000})
    setNegaraList(res.data.map(x => ({value: x.nama, label: x.nama})))
  }

  async function _getSubSektor() {
    var res = await SubSektorService.get({size: 10000})
    setSubSektorList(res.data.map(x => ({value: x.id, label: x.nama, sektorId: x.sektorId})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/perusahaan`}
      baseId="mod-form-sample"
      title={t.translate('modules.perusahaan.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        setUpdatedData(newObj);
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT,
          label: 'Nama Perusahaan',
          key: 'nama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP Perusahaan',
          key: 'npwp',
          type: 'text',
          width: '49%',
          disabled: isEdit,
          validation: 'required'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Tipe Industri',
          key: 'tipeIndustri__id',
          type: 'text',
          options: tipeIndustriList,
          validation: 'required'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Sub Sektor',
          key: 'subSektor__id',
          type: 'text',
          options: updatedData.tipeIndustri__id?subSektorList.filter(x => x.sektorId === tipeIndustriMap[updatedData.tipeIndustri__id].sektorId) : [],
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Alias',
          key: 'alias',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'}
        },{
          inputType: inputTypes.INPUT,
          label: 'Alamat Sesuai NPWP',
          key: 'alamat',
          type: 'text',
          width: '49%',
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kecamatan',
          key: 'kecamatan',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kelurahan',
          key: 'kelurahan',
          type: 'text',
          width: '49%',
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Provinsi',
          key: 'provinsi',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kota',
          key: 'kota',
          type: 'text',
          width: '49%',
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Telepon',
          key: 'telepon',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'email',
          type: 'text',
          width: '49%',
          validation: 'email'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Pos',
          key: 'kodePos',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required'
        },{
          inputType: inputTypes.SELECT,
          label: 'Negara Domisili',
          key: 'negara',
          type: 'text',
          width: '49%',
          options: negaraList,
          validation: 'required'
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Status',
        //   key: 'status',
        //   type: 'text'
        // },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }

          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/perusahaan')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore', 'temporaryStore')(observer(PerusahaanForm))
