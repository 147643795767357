import izi from 'izitoast';
import t from 'counterpart'

export const info = (message, options={}) => {
  const title = t.translate('mpk.status.info')
  izi.info({title, message, ...options})
}

export const success = (message, options={}) => {
  const title = t.translate('mpk.status.success')
  izi.success({title, message, ...options})
}

export const warning = (message, options={}) => {
  const title = t.translate('mpk.status.warning')
  izi.warning({title, message, ...options})
}

export const error = (message, options={}) => {
  const title = t.translate('mpk.status.error')
  izi.error({title, message, ...options})
}

export const errorRequest = (error, localeCode='id', options) => {
  console.log(error.message)
  try {
    const title = t.translate('mpk.status.error')
    const message = typeof error.message === 'object'
      ? ( error.message[localeCode]
        ? error.mesage[localeCode]
        : (
          error.message.detail || 'the error message object is not handle yet. please contact react-mpk administrator'
        )
      )
      : error.message

    izi.error({title, message})
  } catch(e){}
}
