import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { CustomWrapper, PageWrapper } from "../../../libs/react-mpk/wrapper";
import { List, ListItem } from "@react-md/list";
import { Button, FontIcon } from 'react-md'

const PajakTerutangMenu = ({history, match}) => {
  const _handleClick = (type)=> {
    if(type == 'Pasal 25') {
      history.push(`/onpremise/pph-25`)
      return true
    }
    if(type == 'PPh Unifikasi') {
      history.push(`/onpremise/periode-unifikasi`)
      return true
    }
    history.push(`/onpremise/pajak-terutang/${match.params.tipeBebanPajak}/${type}`)
  }
  return (
    <CustomWrapper title={tipeBebanPajakMapper[match.params.tipeBebanPajak]}>
      <div
        className="mpk-form-wrapper mpk-paper mpk-padding-N padding-all mpk-animation slide-in"
        style={{ width: "100%", maxWidth: 1200, margin: "0px auto" }}
      >
        <div className="mpk-width full-width mpk-margin-N margin-bottom">
          <div className="mpk-flex align-start direction-row justify-start mpk-full full-width wrap flex-none">
            <List style={{width: '100%'}}>
              <ListItem onClick={()=> _handleClick('Pasal 21__26')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPH 21</ListItem>
              <ListItem onClick={()=> _handleClick('Pasal 23__26')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPH 23</ListItem>
              <ListItem onClick={()=> _handleClick('Pasal 4a2')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPH 4 AYAT 2</ListItem>
              <ListItem onClick={()=> _handleClick('Pasal 22')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPH 22</ListItem>
              <ListItem onClick={()=> _handleClick('Pasal 15')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPH 15</ListItem>
              <ListItem onClick={()=> _handleClick('Pasal 25')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPH 25</ListItem>
              <ListItem onClick={()=> _handleClick('PPN')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPN</ListItem>

              <ListItem onClick={()=> _handleClick('PPh Unifikasi')} rightAddon={<FontIcon iconClassName="mdi mdi-arrow-right"/>}>PPh Unifikasi</ListItem>
            </List>
          </div>
        </div>
      </div>
    </CustomWrapper>
  );
};

export default inject(
  "modalStore",
  "sipStore",
  "navigationStore"
)(observer(PajakTerutangMenu));

const tipeBebanPajakMapper = {
  pajak_dibayar_dimuka: 'Pajak Dibayar Dimuka',
  pajak_terutang: 'Pajak Terutang'
}
