import http from './httpService.custom';

class CrudCustom{
  constructor(basePath='/', config={}, options={}){
    this.path = basePath
    this.config = config
    this.options = options
  }

  http = http

  setPath(path){
    this.path = path
  }

  get(query={}){
    return http.request({
      method: http.methods.GET,
      url: `${this.path}`,
      query,
      options: this.options,
      config: this.config
    })
  }

  post(data){
    return http.request({
      method: http.methods.POST,
      url: `${this.path}`,
      data,
      options: this.options,
      config: this.config
    })
  }

  put(data){
    return http.request({
      method: http.methods.PUT,
      url: `${this.path}`,
      data,
      options: this.options,
      config: this.config
    })
  }

  delete(id){
    return http.request({
      method: http.methods.DELETE,
      url: `${this.path}/${id}`,
      options: this.options,
      config: this.config
    })
  }

  getOne(id){
    return http.request({
      method: http.methods.GET,
      url: `${this.path}/${id}`,
      // query,
      options: this.options,
      config: this.config
    })
  }
  getLogin(login){
    return http.request({
      method: http.methods.GET,
      url: `${this.path}/${login}`,
      // query,
      options: this.options,
      config: this.config
    })
  }
}

export default CrudCustom
