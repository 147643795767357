import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TransaksiLabaRugi.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'


const TransaksiLabaRugiForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [akunList, setAkun] = useState([])
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      _getAkun()
      _getKodeObjekPajak()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {dipungut: false, dpp:'0', pph: '0', transaksiLabaRugiAkun__nilai: '0', transaksiLabaRugiPengecualians: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      res.data.transaksiLabaRugiAkun__akunid = res.data.transaksiLabaRugiAkun.akun.id
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getAkun() {
    var res = await AkunService.get({size: 100000})
    setAkun(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({size: 100000})
    setKodeObjekPajak(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/transaksi/'+match.params.transaksiId+'/laba-rugi'}
      baseId="mod-form-sample"
      title='Koreksi Fiskal'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'transaksiLabaRugiAkun__nilai') {
          newObj.nilai = value
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: '# AKUN TRANSAKSI'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun',
          key: 'transaksiLabaRugiAkun__akunid',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          options: akunList,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'transaksiLabaRugiAkun__nilai',
          // theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          validation: 'required|numeric'
        },

        {
          inputType: inputTypes.DIVIDER,
          label: '# KOREKSI FISKAL'
        },

        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Koreksi Fiskal',
          key: 'jenis',
          type: 'text',

          required: true,
          validation: 'required',
          options:[
            {value: 'Koreksi fiskal positif', label: 'Koreksi Fiskal Positif'},
            {value: 'Koreksi fiskal negatif', label: 'Koreksi Fiskal Negatif'}
          ],
        },

        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'nilai',
          // theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required|numeric'
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          data.transaksiLabaRugiAkun.akun = {
            id: data.transaksiLabaRugiAkun__akunid
          }
          console.log(data, 'data')
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }

          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/transaksi/'+match.params.transaksiId+'/laba-rugi')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(TransaksiLabaRugiForm))
