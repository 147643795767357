import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TransaksiPerjanjianPembelian.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import AsetService from '../Aset/Aset.service'
import TransaksiService from './Transaksi.service'
import LawanTransaksiService from '../../master/LawanTransaksi/LawanTransaksi.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import ItemService from '../../master/Item/Item.service'
import { toast } from '../../../libs/react-mpk/services'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import TransaksiPajakTerutangTable from './TransaksiPajakTerutang.table'
import TransaksiPajakWpTable from './TransaksiPajakWp.table'
import { DataForm } from "../../../libs/react-mpk/components";
import { Button, FontIcon, TextIconSpacing } from "react-md";

const TransaksiPerjanjianPembelianForm = ({envStore, match, history, sipStore, modalStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [lawanTransaksiList, setLawanTransaksiList] = useState([])
  const [lawanTransaksiMap, setLawanTransaksiMap] = useState({})
  const [itemList, setItemList] = useState([])
  const [itemMap, setItemMap] = useState({})
  const [curData, setCurData] = useState({});

  // initial data
  useEffect(() => {
    async function initData() {
      await _getLawanTransaksi()
      await _getEntitasList()
      await _getItemList()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {perusahaanId: sipStore.companyId, periodeFiskalId: sipStore.periodeId, dipungut: false, dpp:'0', pph: '0', transaksiAktivaTetapAkun__nilai: '0', transaksiAktivaTetapPengecualians: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      if(!res.data.items) res.data.items = []
      res.data.items = res.data.items.map(x => Utils.formSerialize(x))
      res.data = Utils.formSerialize(res.data)
      setIsEdit(true)
      setCurData(res.data);
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getLawanTransaksi() {
    var res = await LawanTransaksiService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setLawanTransaksiList(res.data.map(x => ({value: x.id, label: x.nama})))
    setLawanTransaksiMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getItemList() {
    var res = await ItemService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setItemList(res.data.map(x => ({value: x.id, label: x.kode?`${x.kode} - ${x.nama}`: `${x.nama}`})))
    setItemMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv;
      return pv
    }, {}))
  }

  async function importItem() {
    modalStore.showConfirm({
      title: 'Upload Barang/Jasa',
      children: (
        <DataForm
          baseId={"import-upload"}
          hideActionButton
          defaultData={{}}
          definitions={[
            {
              inputType: FormWrapper.inputTypes.FILE_INPUT,
              label: "File",
              key: "file",
              theme: "filled",
              style: { width: 200 },
            }
          ]}
          // submitLabel="Import Data"
          onSubmit={async (d, callback) => {
            var formData = new FormData();
            formData.set("file", d.file);
            var uploadRes = await service.http.post(
              "/upload",
              formData
            );
            if (uploadRes.data) {
              var data = { ...curData };
              var importRes = await service.http.get(`/pembelian-item-import/${uploadRes.data.name}/${sipStore.companyId}`)

              var data = { ...curData };
              data.items = importRes.data
              setCurData(data)
              FormWrapper.reload("mod-form-sample", data);
            }
            callback("Data berhasil di upload", false);
          }}
        />
      ),
      onSubmit: async (callback) => {
        const f = document.getElementById(`import-upload-form-trigger`);
        if (f) f.click();
        callback();
      },
    });
  }

  async function uploadAttachment() {
    modalStore.showConfirm({
      title: 'Upload Attachment',
      children: (
        <DataForm
          baseId={"attachment-upload"}
          hideActionButton
          defaultData={{}}
          definitions={[
            {
              inputType: FormWrapper.inputTypes.FILE_INPUT,
              label: "File",
              key: "file",
              theme: "filled",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              type: "text",
              width: "100%",
            },
          ]}
          // submitLabel="Import Data"
          onSubmit={async (d, callback) => {
            var formData = new FormData();
            formData.set("file", d.file);
            var uploadRes = await service.http.post(
              "/upload-attachment",
              formData
            );
            if (uploadRes.data) {
              var data = { ...curData };
              console.log(data, 'data')
              if (!data.perjanjianPembelianAttachment) data.perjanjianPembelianAttachment = [];
              data.perjanjianPembelianAttachment.push({
                filename: uploadRes.data.name,
                lokasi: uploadRes.data.origin,
                keterangan: d.keterangan,
              });
              setCurData(data)
              FormWrapper.reload("mod-form-sample", data);
            }
            callback("Data berhasil di upload", false);
          }}
        />
      ),
      onSubmit: async (callback) => {
        const f = document.getElementById(`attachment-upload-form-trigger`);
        if (f) f.click();
        callback();
      },
    });
  }


  const form =  (
    <FormWrapper
      noValidate
      // showCommandbar={match.params.id == 'baru'}
      loading={data.loading}
      actions={[
        // {
        //   label: 'Generate',
        //   iconClassName: 'mdi mdi-inbox-full',
        //   onClick: async () => {
        //     modalStore.showConfirm({
        //       children: 'Generate data pajak terutang?',
        //       onRequestClose: ()=> {modalStore.closeConfirm()},
        //       onSubmit: async (callback) => {
        //         await service.http.get(`/pos/${match.params.id}/generate`);
        //         toast.success(`Data pajak terutang telah di generate.`);
        //         callback()
        //       }
        //     })
        //   }
        // }
      ]}
      backTo={'/onpremise/transaksi-perjanjian-pembelian'}
      baseId="mod-form-sample"
      title='Transaksi Perjanjian'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key === 'lawanTransaksi__sumberId') {
          var lt = lawanTransaksiMap[value]
          if(lt) {
            newObj.lawanTransaksi__jenisPemungutPPh22 = ""
            newObj.lawanTransaksi__nama = lt.nama
            newObj.lawanTransaksi__nik = lt.nik
            newObj.lawanTransaksi__npwp = lt.npwp
            newObj.lawanTransaksi__pemungut = ""
            newObj.lawanTransaksi__sumber = "LawanTransaksi"
            newObj.lawanTransaksi__sumberId = lt.id
            newObj.lawanTransaksi__tipeWajibPajak = lt.tipeWajibPajak
            newObj.lawanTransaksi__domisiliWajibPajak = lt.domisiliWajibPajak
          }
        }
        if(key === 'itemId') {
          var item = itemMap[value]
          if(item) {
            newObj.items[indexAtParent].nama = item.nama
            newObj.items[indexAtParent].kode = item.kode
          }
        }

        if(key == 'hargaSatuan' || key == 'qty' || key == 'diskon') {
          newObj.items[indexAtParent][key] = parseInt(value)
          newObj.items[indexAtParent].hargaTotal = (parseInt(newObj.items[indexAtParent].qty)*parseInt(newObj.items[indexAtParent].hargaSatuan))-parseInt(newObj.items[indexAtParent].diskon)
          newObj.items[indexAtParent].dpp =newObj.items[indexAtParent].hargaTotal
        }

        setCurData(newObj);
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Transaksi',
          key: 'jenisTransaksi',
          type: 'text',
          required: true,
          options: [{
            value: 'Penyerahan Barang/Jasa',
            label: 'Penyerahan Barang/Jasa'
          }, {
            value: 'Pembelian Barang/Jasa',
            label: 'Pembelian Barang/Jasa'
          }],
          validation: 'required',
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Dokumen',
          key: 'jenisDokumen',
          type: 'text',
          required: true,
          options: [{
            value: 'PO',
            label: 'PO'
          }, {
            value: 'PKS',
            label: 'PKS'
          }],
          validation: 'required',
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nomor Transaksi',
          key: 'nomor',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Transaksi',
          key: 'tanggal',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Referensi',
          key: 'referensi',
          type: 'text',
          width: '100%'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitasId',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required',
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Lawan Transaksi',
          key: 'lawanTransaksi__sumberId',
          type: 'text',
          // width: '49%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          options: lawanTransaksiList
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama Lawan Transaksi',
          key: 'lawanTransaksi__nama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP Lawan Transaksi',
          key: 'lawanTransaksi__npwp',
          type: 'text',
          width: '49%',
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '################',
          maskChar: '_',
          maskValue: 'string',
          label: 'NIK Lawan Transaksi',
          key: 'lawanTransaksi__nik',
          type: 'text',
          width: '49%',
          disabled: true,
          validation: [{required_if: ['lawanTransaksi__tipeWajibPajak', "OP"]}],
          show: (d) => d.lawanTransaksi__domisiliWajibPajak == 'DN'
        },
        {
          inputType: inputTypes.INPUT,
          // mask: '################',
          // maskChar: '_',
          // maskValue: 'string',
          label: 'Passport Lawan Transaksi',
          key: 'lawanTransaksi__nik',
          type: 'text',
          width: '49%',
          disabled: true,
          validation: [{required_if: ['lawanTransaksi__tipeWajibPajak', "OP"]}],
          show: (d) => d.lawanTransaksi__domisiliWajibPajak == 'LN'
        },

        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
          width: '100%'
        },

        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# BARANG / JASA',
          key: 'items',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 1600},
          extraAction: () => {
            return (
              <>
                <Button
                  themeType="outline"
                  className="mpk-margin-S margin-right"
                  onClick={() => {
                    importItem();

                  }}
                >
                  <TextIconSpacing
                    icon={<FontIcon iconClassName="mdi mdi-plus" />}
                  >
                    Import
                  </TextIconSpacing>
                </Button>
              </>
            );
          },
          defaultData: {
            keterangan: '-',
            "diskon": 0,
            "dpp": 0,
            "hargaSatuan": 0,
            "hargaTotal": 0,
            "ppn": 0,
            "ppnBm": 0,
            "qty": 0,
            "tarifPpnBm": 0
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Barang/Jasa',
              key: 'itemId',
              theme: 'filled',
              style: {width: 600},
              validation: 'required',
              options: itemList
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'Harga Satuan',
              key: 'hargaSatuan',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'QTY',
              key: 'qty',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'Diskon',
              key: 'diskon',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'Harga Total',
              key: 'hargaTotal',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric',
              style: {width: 200},
              disabled: true,
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'DPP',
              key: 'dpp',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric',
              style: {width: 200},
              // disabled: true,
            },
          ]
        },
        {
          inputType: inputTypes.DIVIDER,
          // label: "Harga Total",
          key: "divider_1",
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "# ATTACHMENT",
          key: "perjanjianPembelianAttachment",
          // hintMessage: 'Daftar Tarif',
          layout: "table",
          style: { minWidth: 1600 },
          hideAddButton: true,
          defaultData: {
            keterangan: "-",
            filename: "",
            originalFilename: "",
          },
          extraAction: () => {
            return (
              <Button
                themeType="outline"
                className="mpk-margin-S margin-right"
                onClick={() => {
                  uploadAttachment();
                }}
              >
                <TextIconSpacing
                  icon={<FontIcon iconClassName="mdi mdi-plus" />}
                >
                  Upload Attachment
                </TextIconSpacing>
              </Button>
            );
          },
          definitions: [
            {
              inputType: inputTypes.INPUT,
              label: "Nama file",
              key: "filename",
              type: "text",
              disabled: true,
              // width: "100%",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT,
              label: "Nama file orifinal",
              key: "lokasi",
              disabled: true,
              type: "text",
              // width: "100%",
              style: { width: 200 },
            },
            {
              inputType: inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              type: "text",
              // width: "100%",
              style: { width: 200 },
            },
          ],
        },

        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Pilih Aset',
        //   key: 'transaksiAktivaTetapAset__asetid',
        //   type: 'text',

        //   required: true,
        //   validation: 'required',
        //   options:asetList,
        // }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          // data.transaksiAktivaTetapAkun.akun = {
          //   id: data.transaksiAktivaTetapAkun__akunid
          // }
          data.items = data.items.map((x)=>  Utils.formNormalize(x))
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/transaksi-perjanjian-pembelian')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )

  // if(match.params.id != 'baru') {
  //   return <TabsWrapper
  //     title="Transaksi Pembelian"
  //     showCommandbar={true}
  //     baseId="mod-details-sample-aad"
  //     actions={[
  //       {name: 'Add', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/onpremise/transaksi-pembelian')}
  //     ]}
  //     onChange={ (tab, d) => {
  //       navigationStore.redirectTo(`/onpremise/transaksi-pembelian/${match.params.id}/${tab.key}`)
  //       d()
  //     }}
  //     // tabs={tabs}
  //     tabs={[
  //       {
  //         label: 'Data',
  //         key: 'form',
  //         render: form
  //       }
  //     ]}

  //   />
  // } else {
  //   return form
  // }
  return form
}

export default inject('envStore', 'sipStore', 'modalStore', 'navigationStore')(observer(TransaksiPerjanjianPembelianForm))
