import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { TabsWrapper } from "../../../libs/react-mpk/wrapper";
import Utils from "../../../libs/custom/Utils";
import { ID_TIPE_PEGAWAI, TIPE_TRANSKRIP } from "../../../clientConstant";
import {
  ExpansionList,
  ExpansionPanel,
  usePanels,
} from "@react-md/expansion-panel";
import { List, ListItem } from "@react-md/list";
import { Grid } from "@react-md/utils";
import { Button } from "@react-md/button";
import { FontIcon } from "@react-md/icon";

import {
  Caption,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@react-md/table";

const TranskripListForm = ({
  envStore,
  match,
  history,
  data,
  onSubmit,
  sipStore,
  navigationStore,
  tipeTranskrip,
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [grantTotal, setGrantTotal] = useState([0, 0]);

  const [panels, onKeyDown] = usePanels({
    idPrefix: "saldo-awal",
    count: 1000,
    defaultExpandedIndex: 0,
  });

  // initial data
  useEffect(() => {
    async function initData() {
      if (match.params.id != "baru") {
        setIsEdit(true);
      }
    }
    initData();
  }, ["match.params.id"]);

  useEffect(() => {
    var pv = 0;
    var ev = 0;
    data[tipeTranskrip].forEach((d) => {
      if(d.saldoNeracaAkuns) {
        d.saldoNeracaAkuns.forEach((cv) => {
          if (cv.posisiAkunAwal == "K") {
            pv = pv - parseInt(cv.nilaiAwal);
          } else {
            pv = pv + parseInt(cv.nilaiAwal);
          }
          if (cv.posisiAkunAkhir == "K") {
            ev = ev - parseInt(cv.nilaiAkhir);
          } else {
            ev = ev + parseInt(cv.nilaiAkhir);
          }
        });
      }

    });
    setGrantTotal([pv, ev]);
  }, [JSON.stringify(data)]);

  return (
    <FormWrapper
      className="bg-none"
      noValidate
      // editable={false}
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      showCommandbar={false}
      backTo={`/onpremise/pegawai`}
      baseId="mod-form-sample"
      title="Saldo Awal"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName="mdi mdi-arrow-right"
      defaultData={data.saldoMap}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {}}
      definitions={[
        // =====
        {
          render: (d, pk, pi, render) => {
            return (
              <>
                <Table style={{ width: "700px", marginLeft: 10 }}>
                  <TableHeader>
                    {tipeTranskrip != "labaRugiList" && (
                      <TableRow>
                        <TableCell style={{ width: "30%" }}>
                          Nama Akun
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>Posisi</TableCell>
                        <TableCell style={{ width: "15%" }}>
                          Nilai Awal
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>Posisi</TableCell>
                        <TableCell style={{ width: "15%" }}>
                          Nilai Akhir
                        </TableCell>
                      </TableRow>
                    )}
                    {tipeTranskrip == "labaRugiList" && (
                      <TableRow>
                        <TableCell style={{ width: "40%" }}>
                          Nama Akun
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>
                          Saldo Akhir
                        </TableCell>
                        <TableCell style={{ width: "15%" }}></TableCell>
                        <TableCell style={{ width: "15%" }}>
                          Koreksi Fiskal
                        </TableCell>
                        <TableCell style={{ width: "15%" }}>Fiskal</TableCell>
                      </TableRow>
                    )}
                  </TableHeader>
                </Table>
                <ExpansionList onKeyDown={onKeyDown} style={{ width: "100%" }}>
                  {data[tipeTranskrip].map((d, i) => {
                    return (
                      <ExpansionPanel
                        {...panels[i]}
                        key={i}
                        header={
                          <div style={{ textAlign: "left" }}>
                            <div>
                              {d.elemenTranskrip.nomorUrut +
                                ". " +
                                d.elemenTranskrip.nama}
                            </div>
                            <div>
                              <Table style={{ width: "700px", marginLeft: 5 }}>
                                <TableBody>
                                  {tipeTranskrip != "labaRugiList" && (
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          width: "30%",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        TOTAL
                                        {/* {d.elemenTranskrip.nama} */}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {dork(d, "Awal")}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {Utils.numberFormat(
                                          Math.abs(d.nilaiAwal)
                                        )}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {dork(d, "Akhir")}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {Utils.numberFormat(
                                          Math.abs(d.nilaiAkhir)
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  {tipeTranskrip == "labaRugiList" && (
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          width: "40%",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        TOTAL
                                        {/* {d.elemenTranskrip.nama} */}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {Utils.numberFormat(
                                          d.nilaiAkhir
                                        )}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {d.koreksiFiskal < 0 && <div>-</div>}
                                        {d.koreksiFiskal >= 0 && <div>+</div>}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {Utils.numberFormat(
                                          Math.abs(d.koreksiFiskal)
                                        )}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "15%",
                                        }}
                                      >
                                        {Utils.numberFormat(
                                          Math.abs(d.nilaiAkhir+d.koreksiFiskal)
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </div>
                          </div>
                        }
                        // disablePadding
                      >
                        {/* {d.children.length > 0 && <ChildTranskrip data={d} />}
                {d.children.length == 0 && d.saldoNeracaAkuns ==0 && <div className="rmd-button-unstyled rmd-expansion-panel__header">
                  <div>{d.elemenTranskrip.nama}</div>
                </div>} */}
                        <ChildAkun
                          data={d}
                          history={history}
                          tipeTranskrip={tipeTranskrip}
                        />
                        {/* {render({
                  inputType: FormWrapper.inputTypes.LIST,
                  // label: 'Pegawai Gaji Pengurang',
                  key: d.elemenTranskrip.id,
                  elementKey: d.elemenTranskrip.id,
                  layout: 'table',
                  hideActionButton: true,
                  defaultData: {
                    keterangan: '-'
                  },
                  definitions: [
                    {
                      label: 'Akun',
                      render: (d, pk, pi)=> {
                        return data.akunMap[d[pk][pi].akunId]?data.akunMap[d[pk][pi].akunId].nama:''
                      }
                    },
                    {
                      inputType: FormWrapper.inputTypes.SELECT,
                      label: 'Posisi Akun',
                      key: 'posisiAkunAwal',
                      theme: 'filled',
                      options: [{value: 'D', label: 'Debit'}, {value: 'K', label: 'Kredit'}]
                    },
                    {
                      inputType: inputTypes.INPUT_MASK_NUMBER,
                      label: 'Nilai',
                      key: 'nilaiAwal',
                      theme: 'filled',
                      thousandSeparator: '.',
                      decimalSeparator: ',',
                      isNumericString: true,
                      validation: 'required|numeric'
                    }
                  ]
                }, '0')} */}
                      </ExpansionPanel>
                    );
                  })}
                </ExpansionList>
              </>
            );
          },
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default TranskripListForm;

function ChildTranskrip({ data }) {
  const [panels, onKeyDown] = usePanels({
    idPrefix: data.id,
    count: 1000,
    defaultExpandedIndex: 0,
  });

  if (data.children.length > 0) {
    return (
      <ExpansionList onKeyDown={onKeyDown} style={{ width: "100%" }}>
        {data.children &&
          data.children.map((d, i) => {
            if (d.saldoNeracaAkuns.length > 0) {
              return (
                <ExpansionPanel
                  {...panels[i]}
                  key={i}
                  header={d.elemenTranskrip.nama}
                  disablePadding
                >
                  {d.children.length == 0 && d.saldoNeracaAkuns.length > 0 && (
                    <ChildAkun data={d} />
                  )}
                </ExpansionPanel>
              );
            } else {
              return (
                <Table style={{ width: "100%", tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ width: "40%", wordWrap: "break-word" }}
                      >
                        {d.elemenTranskrip.nama}
                      </TableCell>
                      <TableCell>{d.posisiAkunAwal}</TableCell>
                      <TableCell>{Utils.numberFormat(d.nilaiAwal)}</TableCell>
                      <TableCell>{d.posisiAkunAkhir}</TableCell>
                      <TableCell>{Utils.numberFormat(d.nilaiAkhir)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              );
            }
          })}
      </ExpansionList>
    );
  }
}

function dork(d, awalAkhir) {
  var posisi = d["posisiAkun" + awalAkhir];
  if (d["nilai" + awalAkhir] < 0) {
    if (posisi == "D") {
      posisi = "K";
    } else posisi = "D";
  }

  console.log(d["posisiAkun" + d.awalAkhir], "asdasd", d, awalAkhir);

  return posisi;
}

function ChildAkun({ data, history, tipeTranskrip }) {
  const [panels, onKeyDown] = usePanels({
    idPrefix: data.id,
    count: 1000,
    defaultExpandedIndex: 0,
  });

  return (
    <Table style={{ width: "700px" }}>
      <TableBody>
        {data.saldoNeracaAkuns &&
          data.saldoNeracaAkuns.map((d, i) => {
            if (tipeTranskrip != "labaRugiList") {
              return (
                <TableRow key={i}>
                  <TableCell style={{ width: "10%", wordWrap: "break-word" }}>
                    <Button
                      buttonType="icon"
                      theme="secondary"
                      aria-label="Favorite"
                      onClick={() => {
                        history.push(
                          "/onpremise/transaksi?akunId=" +
                            d.akun.id +
                            "&title=" +
                            d.akun.nomor+" "+d.akun.nama +
                            "&nilaiAwal=" +
                            Math.abs(d.nilaiAwal) +
                            "&nilaiAkhir=" +
                            Math.abs(d.nilaiAkhir) +
                            "&posisiAkunAwal=" +
                            dork(d, "Awal") +
                            "&posisiAkunAkhir=" +
                            dork(d, "Akhir")
                        );
                      }}
                    >
                      <FontIcon>more</FontIcon>
                    </Button>
                  </TableCell>
                  <TableCell style={{ width: "20%", wordWrap: "break-word" }}>
                    {d.akun.nama}
                  </TableCell>
                  <TableCell style={{ width: "15%" }}>
                    {dork(d, "Awal")}
                  </TableCell>
                  <TableCell style={{ textAlign: "right", width: "15%" }}>
                    {Utils.numberFormat(Math.abs(d.nilaiAwal))}
                  </TableCell>
                  <TableCell style={{ width: "15%" }}>
                    {dork(d, "Akhir")}
                  </TableCell>
                  <TableCell style={{ textAlign: "right", width: "15%" }}>
                    {Utils.numberFormat(Math.abs(d.nilaiAkhir))}
                  </TableCell>
                </TableRow>
              );
            } else {
              console.log(d);
              return (
                <TableRow key={i}>
                  <TableCell style={{ width: "10%", wordWrap: "break-word" }}>
                    <Button
                      buttonType="icon"
                      theme="secondary"
                      aria-label="Favorite"
                      onClick={() => {
                        history.push(
                          "/onpremise/transaksi?akunId=" +
                            d.akun.id +
                            "&title=" +
                            d.akun.nama +
                            "&nilaiAwal=" +
                            Math.abs(d.nilaiAwal) +
                            "&nilaiAkhir=" +
                            Math.abs(d.nilaiAkhir) +
                            "&posisiAkunAwal=" +
                            dork(d, "Awal") +
                            "&posisiAkunAkhir=" +
                            dork(d, "Akhir")
                        );
                      }}
                    >
                      <FontIcon>more</FontIcon>
                    </Button>
                  </TableCell>
                  <TableCell style={{ width: "30%", wordWrap: "break-word" }}>
                    {d.akun.nama}
                  </TableCell>
                  <TableCell style={{ width: "15%" }}>
                    {Utils.numberFormat(Math.abs(d.nilaiAkhir))}
                  </TableCell>
                  <TableCell style={{ textAlign: "right", width: "15%" }}>
                    {d.koreksiFiskal < 0 && <div>-</div>}
                    {d.koreksiFiskal >= 0 && <div>+</div>}
                  </TableCell>
                  <TableCell style={{ width: "15%" }}>
                    {Utils.numberFormat(Math.abs(d.koreksiFiskal))}
                  </TableCell>
                  <TableCell style={{ textAlign: "right", width: "15%" }}>
                    {Utils.numberFormat(
                      Math.abs(d.nilaiAkhir + d.koreksiFiskal)
                    )}
                  </TableCell>
                </TableRow>
              );
            }
          })}
        {/* <TableRow>
          <TableCell></TableCell>
          <TableCell>Total</TableCell>
          <TableCell>{dork(data, "Awal")}</TableCell>
          <TableCell style={{ textAlign: "right" }}>
            {Utils.numberFormat(Math.abs(data.nilaiAwal))}
          </TableCell>
          <TableCell>{dork(data, "Akhir")}</TableCell>
          <TableCell style={{ textAlign: "right" }}>
            {Utils.numberFormat(Math.abs(data.nilaiAkhir))}
          </TableCell>
        </TableRow> */}
      </TableBody>
    </Table>
  );
}
