import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './PajakTerutangSanding.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { KOP_KHUSUS } from '../../../clientConstant'
import { Grid } from "@react-md/utils";
import { TextField } from "@react-md/form";
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-pemotongan-pph2126"

const PajakTerutangAkunPphTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  match,
  modalStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  const [confirmKirimEppt, setConfirmKirimEppt] = useState(false)
  const [confirmKirimEpptData, setConfirmKirimEpptData] = useState({})

  function _closeConfirmKirimEppt() {
    setConfirmKirimEppt(false)
  }

  async function _submitConfirmKirimEppt(callback) {
    var r = await service.http.post(
      `/transaksi-pajak-terutangs/send-to-eppt-kop-2840401-to-2840411`,

      {masa: confirmKirimEpptData.masa, tahun: confirmKirimEpptData.tahun, perusahaanId: sipStore.companyId }
    );
    toast.info(`Data ${confirmDeleteData.nama} sedang di kirim.`); callback()
    setConfirmKirimEppt(false)
  }

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      // title={"Kertas Kerja Sanding"}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        // {
        //   label: 'Tanggal Transaksi',
        //   searchable: true,
        //   key: 'tanggal',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.tanggal)
        // },
        {
          label: 'No Referensi',
          searchable: true,
          key: 'nomorReferensi',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nomorReferensi)
        },
        {
          label: 'Nomor Referensi Data Hitung',
          searchable: true,
          key: 'nomorReferensiDataHitung',
          type: TableWrapper.dataTypes.STRING,
          render: item => <div>
          {item.terutangs.map((v, i)=> {
            return <p>{v.transaksi.nomor} - {v.nomorReferensiHutang}</p>
          })}
        </div>
        },
        {
          label: 'No Transaksi Akun',
          searchable: true,
          key: 'jurnals__id',
          type: TableWrapper.dataTypes.STRING,
          render: item => <div>
          {item.jurnals.map((v, i)=> {
            return <p>{v.akun.nomor} - {v.akun.nama}</p>
          })}
          </div>
        },
        {
          label: 'Status Approve',
          searchable: true,
          key: 'statusApproval',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.statusApproval)
        },
        // {
        //   label: 'Status Sanding',
        //   searchable: true,
        //   key: 'statusSanding',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.statusSanding)
        // },
        // {
        //   label: 'Status Pengiriman',
        //   searchable: true,
        //   key: 'pengirimanStatus',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.pengirimanStatus)
        // },
        // {
        //   label: 'Nomor Bukti Potong/Faktur Pajak',
        //   searchable: true,
        //   key: 'nomorBpOrFaktur',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.nomorBpOrFaktur)
        // },
        // {
        //   label: 'Lawan Transaksi',
        //   searchable: true,
        //   key: 'lawanTransaksi',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.lawanTransaksi)
        // },
        // {
        //   label: 'KOP',
        //   searchable: true,
        //   key: 'tahunPajak',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.kodeObjekPajak.kode)
        // },
        {
          label: 'PPh',
          searchable: true,
          key: 'pph',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.pph))
        },
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/pajak-terutang-sanding/baru'), true),
        new TableWrapper.action('Kirim Eppt', 'mdi mdi-mail', () => {
          setConfirmKirimEppt(true);
        }, true),
      ]}
      itemActions={[
        new TableWrapper.action(
          "Kirim ePPT",
          "mdi mdi-mail",
          (item) => {
            modalStore.showConfirm({
              children: "Kirim data terpilih ke ePPT?",
              onRequestClose: () => {
                modalStore.closeConfirm();
              },
              onSubmit: async (callback) => {
                try {
                  await service.http.post(
                    `/transaksi-pajak-terutangs/${item.id}/send-to-eppt`,
                    {}
                  );
                  toast.success(
                    `Cek pada kolom status, untuk memantau status pengiriman anda!`
                  );
                } catch (e) {
                  var message = e.message
                  if(e.response) {
                    message = e.response.data.message+" "+e.response.data.detail
                  }
                  toast.warning(
                    message
                  );
                }

                TableWrapper.reload(_baseId);
                callback();
              },
            });
          },
          (d)=> {return d.kodeObjekPajak && !KOP_KHUSUS[d.kodeObjekPajak.kode]}
        ),
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['tipeBebanPajak.equals'] = tipeBebanPajakMapper[match.params.tipeBebanPajak]
          query['tipePajakKode.equals'] = match.params.type.replace('__', '/')
          query['perusahaanId.equals'] = sipStore.companyId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>

    <Modal.Confirm
      title="Kirim Data Pajak Terutang"
      submitLabel="Submit"
      visible={confirmKirimEppt}
      onRequestClose={_closeConfirmKirimEppt}
      onSubmit={_submitConfirmKirimEppt}
    >
      <div className="mpk-full full-width input mpk-margin-N margin-bottom flex-none ">
      <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none ">
        <TextField label="Masa" value={confirmKirimEpptData.masa} onChange={(e, d)=> {
          setConfirmKirimEpptData((draft) => {
            return {
              ...draft,
              masa: e.target.value
            }
          })
        }}  />
      </div>
      <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none ">
      <TextField label="Tahun" value={confirmKirimEpptData.tahun} onChange={(e, d)=> {
          setConfirmKirimEpptData((draft) => {
            return {
              ...draft,
              tahun: e.target.value
            }
          })
        }}  />
      </div>

      </div>


    </Modal.Confirm>
    </>
  )
}

const tipeBebanPajakMapper = {
  pajak_dibayar_dimuka: 'Pajak Dibayar Dimuka',
  pajak_terutang: 'Pajak Terutang'
}


export default inject("modalStore",'sipStore', 'navigationStore')(observer(PajakTerutangAkunPphTable))
