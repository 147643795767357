import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import onp from "../libs/custom/onp";
import { userDefaults } from "../libs/custom/user";
import { PageWrapper } from "../libs/react-mpk/wrapper";
import { inject, observer } from "mobx-react";
import {
  NegaraTable,
  NegaraForm,
  TipeAkunTable,
  TipeAkunForm,
  UsersTable,
  UsersForm,
  TipeHubunganIstimewaTable,
  TipeHubunganIstimewaForm,
  TipeIndustriTable,
  TipeIndustriForm,
  TipePajakTable,
  TipePajakForm,
  TipeBupotTable,
  TipeBupotForm,
  KodeObjekPajakTable,
  KodeObjekPajakForm,
  TableSample,
  FormSample,
  DetailsSample,
  DashboardSample,
  JenisBiayaTable,
  JenisBiayaForm,
  BatasUpahHarianForm,
  BatasUpahHarianTable,
  KategoriPph25Table,
  KategoriPph25Form,
  KomponenPenghasilanTable,
  KomponenPenghasilanForm,
  PengecualianPajakTable,
  PengecualianPajakForm,
  PengenaanPajakLainnyaTable,
  PengenaanPajakLainnyaForm,
  PenghasilanSptTahunanTable,
  PenghasilanSptTahunanForm,
  PenyesuaianFiskalNegatifTable,
  PenyesuaianFiskalNegatifForm,
  PenyesuaianFiskalPositifTable,
  PenyesuaianFiskalPositifForm,
  PtkpTable,
  PtkpForm,
  TarifBiayaTable,
  TarifBiayaForm,
  TipePengecualianPajakTable,
  TipePengecualianPajakForm,
  TipePicTable,
  TipePicForm,
  TipeStatusPajakTable,
  TipeStatusPajakForm,
  TipePengurangPajakTable,
  TipePengurangPajakForm,
  PengenaanPajakAkunTable,
  PengenaanPajakAkunForm,
  StatusPajakPembeliTable,
  StatusPajakPembeliForm,
  StatusPajakPenjualTable,
  StatusPajakPenjualForm,
  TipeDaftarNormatifTable,
  TipeDaftarNormatifForm,
  TipeKoreksiFiskalTable,
  TipeKoreksiFiskalForm,
  KelompokPenghasilanTable,
  KelompokPenghasilanForm,
  TipePenghasilanLamp1771ivsTable,
  TipePenghasilanLamp1771ivsForm,
  TarifTable,
  TarifForm,
  PengenaanPajakTable,
  PengenaanPajakForm,
  KelompokAsetTable,
  KelompokAsetForm,
  TipeAsetTable,
  TipeAsetForm,
  BiayaJabatanTable,
  BiayaJabatanForm,
  BiayaPensiunTable,
  BiayaPensiunForm,
  TarifProgresifPasal17Table,
  TarifProgresifPasal17Form,
  TarifProgresifPesangonTable,
  TarifProgresifPesangonForm,
  TarifProgresifPensiunTable,
  TarifProgresifPensiunForm,
  TarifPph29Table,
  TarifPph29Form,
  TarifPph25Table,
  TarifPph25Form,
  PengaturanPenghasilanSptTable,
  PengaturanPenghasilanSptForm,
  PengaturanBiayaSptTable,
  PengaturanBiayaSptForm,
  PengaturanPenghasilanPPh21Table,
  PengaturanPenghasilanPPh21Form,
  PengaturanPemotonganPPh21Table,
  PengaturanPemotonganPPh21Form,
  PerusahaanTable,
  PerusahaanForm,
  EntitasTable,
  EntitasForm,
  EntitasPajakForm,
  LawanTransaksiTable,
  LawanTransaksiForm,
  LawanTransaksiPajakForm,
  MetodePenyusutanTable,
  MetodePenyusutanForm,
  PendapatanTable,
  PendapatanForm,
  PengurangTable,
  PengurangForm,
  AkunTable,
  AkunForm,
  ItemForm,
  ItemTable,
  PerusahaanSettingForm,
  FixedAsetTable,
  FixedAsetForm,
  JenisUsahaTable,
  JenisUsahaForm,
  AkunPengenaanPajakForm,
  PegawaiTable,
  PegawaiForm,
  PemotonganPPh2126Table,
  PemotonganPPh2126Form,
  Dashboard,
  AsetTable,
  AsetForm,
  TransaksiTable,
  TransaksiForm,
  TransaksiPajakTerutangForm,
  TransaksiKoreksiFiskalForm,
  TransaksiBebanEntertainmentForm,
  TransaksiReferensiHutangForm,
  TransaksiReferensiPembayaranHutangForm,
  TransaksiLabaRugiForm,
  TransaksiReferensiPiutangForm,
  TransaksiReferensiPembayaranPiutangForm,
  TransaksiPerubahanModalForm,
  MigrasiWrapper,
  MigrasiTranskripTable,
  MigrasiTranskripForm,
  DetailModalForm,
  PenyusutanPeriodeTable,
  PenyusutanPeriodeForm,
  PenyusutanPeriodeAsetTable,
  PenyusutanPeriodeAkunTable,
  TransaksiAktivaTetapForm,
  SandingAsetTable,
  SandingAsetPerAsetTable,
  SandingAsetPerAkunTable,
  KertasKerjaSandingTable,
  TransaksiPenjualanAktivaTetapForm,
  PeriodeFiskalTable,
  PeriodeFiskalForm,
  VTransaksiWrapper,
  SandingPPh21Akun,
  TransaksiBebanPromosiForm,
  PajakTerutangWrapper,
  RoleTable,
  RoleForm,
  Member,
  MemberForm,
  TranskripWrapper,
  VTransaksiItemForm,
  LogImportTable,
  LogExportTable,
  PajakTerutangMenu,
  PPh25Wrapper,
  PPh25Form,
  SandingPPh21Transaksi,
  SandingPPh21Perhitungan,
  TransaksiPosForm,
  Spt1771Form,
  ReturPenjualanWrapper,
  ReturPenjualanPosForm,
  ReturPenjualanAkunForm,
  MasterMappingSpt1771Table,
  MasterMappingSpt1771Form,
  PajakTerutangSandingForm,
  TransaksiPembelianTable,
  TransaksiPembelianForm,
  TransaksiPembelianAkunTable,
  TransaksiPembelianAkunForm,
  MasterLevelTable,
  MasterLevelForm,
  TransaksiPajakWpForm,
  Ekualiasasi,
  TransaksiPerjanjianPembelianForm,
  TransaksiPerjanjianPembelianTable,
  DaftarNominatifPromosiTable,
  DaftarNominatifEntertainmentTable,
  KoreksiFiskalTable,
  SptProformaPPh,
  PeriodePphUnifikasiForm,
  PeriodePphUnifikasiTable,
  SilkTable,
  SilkForm
} from "../modules";
import MemberService from "../modules/master/Member/Member.service";

import OpLoginService from "./../modules/OpLogin/OpLogin.service";
import { List, ListItem } from "@react-md/list";
import iziToast from "izitoast";
import SektorForm from "../modules/adminpajakku/sektor/Sektor.form";
import SektorTable from "../modules/adminpajakku/sektor/Sektor.table";
import SubSektorTable from "../modules/adminpajakku/subSektor/SubSektor.table";
import SubSektorForm from "../modules/adminpajakku/subSektor/SubSektor.form";
import InformasiPemegangSahamForm from "../modules/adminpajakku/informasiPemegangSaham/InformasiPemegangSaham.form";
import InformasiPemegangSahamTable from "../modules/adminpajakku/informasiPemegangSaham/InformasiPemegangSaham.table";
import VKertasKerjaKoreksiFiskalTable from "../modules/user/Transaksi/VKertasKerjaKoreksiFiskal.table";

const HeaderSidebar = inject("sipStore")(
  observer(({ sipStore, history }) => {
    return (
      <List style={{ width: "100%", background: "rgba(0,0,0,.1)" }}>
        <ListItem
          onClick={() => history.push("/onpremise/dashboard")}
          secondaryText={
            sipStore.periodeId
              ? "Periode " + sipStore.periode.tahun
              : "Periode belum di pilih"
          }
        >
          <b>
            {sipStore.companyId
              ? sipStore.company.nama + ` (${sipStore.company.npwp})`
              : "Perusahaan belum di pilih"}
          </b>
        </ListItem>
      </List>
    );
  })
);

var unlisten = function() {

}

const Onpremise = ({ history, sipStore, authStore }) => {
  let [user, setUser] = useState({});
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    async function init() {
      unlisten()
      try {
        var query = {
          size: 10000,
          "perusahaanId.equals": sipStore.companyId,
          "userId.equals": authStore.user.id,
        };
        var roles = await MemberService.getMember(query);
        const menu = await _setRole(roles.data[0]);
        const menuMap = menu.reduce((pv, cv) => {
          if (cv.children && cv.children.length > 0) {
            var children = cv.children.reduce((p, c) => {
              p[c.path] = c;
              return p;
            }, {});
            pv = {
              ...pv,
              ...children,
            };
          }

          return pv;
        }, {});

        unlisten = history.listen((location) => {
          // var pathArray = location.pathname.split("/");

          // var path = "/" + pathArray[1] + "/" + pathArray[2];
          // console.log(path, 'path', menuMap)
          // if (!(path == "/onpremise" || path == "/onpremise/home" || path == "/onpremise/akun-pajak" || location.pathname.search('pph-menu') >= 0 || location.pathname.search('pajak-terutang-menu') >= 0 || location.pathname.search('pajak-terutang') >= 0 || location.pathname.search('login') >= 0) || location.pathname.search('transaksi-') >= 0) {
          //   if (!menuMap[path]) {
          //     iziToast.warning({
          //       title: 'Peringatan',
          //       message: 'Anda tidak memiliki hak akses.'
          //     })
          //     window.location.hash = "/onpremise";
          //   }
          // }

          // do your magic things here
          // reset the search: clear the results and the search input field
        });

        // var pathArray = history.location.pathname.split("/");
        // // console.log('sdaasdasd', history.location.pathname)
        //   var path = "/" + pathArray[1] + "/" + pathArray[2];
        //   if (!(path == "/onpremise" ||path == "/onpremise/home" || path == "/onpremise/akun-pajak" || history.location.pathname.search('pph-menu') >= 0 || history.location.pathname.search('pajak-terutang-menu') >= 0 || history.location.pathname.search('pajak-terutang') >= 0 || history.location.pathname.search('login') >= 0 || history.location.pathname.search('transaksi-') >= 0)) {
        //     if (!menuMap[path]) {
        //       iziToast.warning({
        //         title: 'Peringatan',
        //         message: 'Anda tidak memiliki hak akses.'
        //       })

        //       window.location.hash = "/onpremise/home";
        //       setLoading(false);

        //     } else {
        //       setLoading(false);
        //     }
        //   } else {
        //     setLoading(false);
        //   }
        setLoading(false);
      } catch (e) {
        throw e;
        // window.location.href = '/#/login'
      }


      return function cleanup() {
        unlisten();
      };
    }
    return init();
  }, []);

  async function _setRole(perusahaanUser) {
    var userClone = cloneDeep(userDefaults);
    if (perusahaanUser && perusahaanUser.role && perusahaanUser.role.permissions) {
      var roleMap = perusahaanUser.role.permissions.reduce((pv, cv) => {
        if (!pv[cv.permission.module.nama]) pv[cv.permission.module.nama] = {};
        pv[cv.permission.module.nama][cv.permission.nama] = cv.allow;

        return pv;
      }, {});
      const isAdmin = authStore.user.authorities.find((x) => x == "ROLE_ADMIN");
      var menu = [];
      menu = menu.concat(userClone.menu.filter((x) => x.global));

      // console.log(userClone.menu.filter(x => x.global), 'menu')

      if (authStore.user.authorities.find((x) => x == "ROLE_ADMIN")) {
        menu = menu.concat(userClone.menu.filter((x) => x.adminPajak));
      }

      if (!isAdmin) {
        userClone.menu
          .filter((x) => !(x.adminPajak || x.global))
          .forEach((x) => {
            if (x.children) {
              x.children = x.children.filter((y) => {
                return (
                  roleMap[y.permissionName] && roleMap[y.permissionName].View
                );
              });
              if (x.children.length > 0) menu.push(x);
            } else {
              menu.push(x);
            }
          });
      }

      authStore.setProfile({
        ...authStore.user,
        roleMap: roleMap,
        menu,
      });

      return menu;
    } else {
      var menu = [];
      menu = menu.concat(userClone.menu.filter((x) => x.global));

      if (authStore.user.authorities.find((x) => x == "ROLE_ADMIN")) {
        menu = menu.concat(userClone.menu.filter((x) => x.adminPajak));
      }

      authStore.setProfile({
        ...authStore.user,
        menu,
      });
    }

    console.log(menu.reduce((pv,cv)=> {
      if(cv.children) pv.push(...cv.children.map(p => p.label))
      return pv;
    } ,[]).join(','), 'menusss')
    return menu;
  }

  useEffect(() => {}, []);

  console.log(loading, 'Loading...');
  if(loading) {
    return <div >loading...</div>
  } else {
    return (
      <PageWrapper
        inverseTheme={true}
        sidebarCollapsible={true}
        sidebarDefaultCollapse={false}
        sidebarHeader={<HeaderSidebar history={history} />}
        handleLogout={() => {
          // let res = await OpLoginService.logout();
          localStorage.removeItem("id_token");
          window.location.href = "/#/login";
        }}
      >
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/onpremise/home"
              render={(props) => <div {...props} />}
            />
            <Route
              path="/onpremise/dashboard"
              render={(props) => <Dashboard {...props} />}
            />
            <Route
              path="/onpremise/proforma-pph"
              render={(props) => <SptProformaPPh {...props} />}
            />
            <Route
              path="/onpremise/ekualisasi"
              render={(props) => <Ekualiasasi {...props} />}
            />
            <Route
              path="/onpremise/negara/:id"
              render={(props) => <NegaraForm {...props} />}
            />
            <Route
              path="/onpremise/negara"
              render={(props) => <NegaraTable {...props} />}
            />
            <Route
              path="/onpremise/sektor/:id"
              render={(props) => <SektorForm {...props} />}
            />
            <Route
              path="/onpremise/sektor"
              render={(props) => <SektorTable {...props} />}
            />
            <Route
              path="/onpremise/informasi-pemegang-saham/:id"
              render={(props) => <InformasiPemegangSahamForm {...props} />}
            />
            <Route
              path="/onpremise/informasi-pemegang-saham"
              render={(props) => <InformasiPemegangSahamTable {...props} />}
            />
            <Route
              path="/onpremise/sub-sektor/:id"
              render={(props) => <SubSektorForm {...props} />}
            />
            <Route
              path="/onpremise/sub-sektor"
              render={(props) => <SubSektorTable {...props} />}
            />
            <Route
              path="/onpremise/user/:id"
              render={(props) => <UsersForm {...props} />}
            />
            <Route
              path="/onpremise/user"
              render={(props) => <UsersTable {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-penghasilan-tahunan/:id"
              render={(props) => <TipePenghasilanLamp1771ivsForm {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-penghasilan-tahunan"
              render={(props) => <TipePenghasilanLamp1771ivsTable {...props} />}
            />
            <Route
              path="/onpremise/kelompokPenghasilan/:id"
              render={(props) => <KelompokPenghasilanForm {...props} />}
            />
            <Route
              path="/onpremise/kelompokPenghasilan"
              render={(props) => <KelompokPenghasilanTable {...props} />}
            />
            <Route
              path="/onpremise/tipeKoreksiFiskal/:id"
              render={(props) => <TipeKoreksiFiskalForm {...props} />}
            />
            <Route
              path="/onpremise/tipeKoreksiFiskal"
              render={(props) => <TipeKoreksiFiskalTable {...props} />}
            />
            <Route
              path="/onpremise/tipeDaftarNormatif/:id"
              render={(props) => <TipeDaftarNormatifForm {...props} />}
            />
            <Route
              path="/onpremise/tipeDaftarNormatif"
              render={(props) => <TipeDaftarNormatifTable {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-biaya/:id"
              render={(props) => <PengaturanBiayaSptForm {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-biaya"
              render={(props) => <PengaturanBiayaSptTable {...props} />}
            />
            <Route
              path="/onpremise/tipeBupot/:id"
              render={(props) => <TipeBupotForm {...props} />}
            />
            <Route
              path="/onpremise/tipeBupot"
              render={(props) => <TipeBupotTable {...props} />}
            />
            <Route
              path="/onpremise/tipePajak/:id"
              render={(props) => <TipePajakForm {...props} />}
            />
            <Route
              path="/onpremise/tipePajak"
              render={(props) => <TipePajakTable {...props} />}
            />
            <Route
              path="/onpremise/industri/:id"
              render={(props) => <TipeIndustriForm {...props} />}
            />
            <Route
              path="/onpremise/industri"
              render={(props) => <TipeIndustriTable {...props} />}
            />
            <Route
              path="/onpremise/master-mapping-spt-1771/:id"
              render={(props) => <MasterMappingSpt1771Form {...props} />}
            />
            <Route
              path="/onpremise/master-mapping-spt-1771"
              render={(props) => <MasterMappingSpt1771Table {...props} />}
            />
            <Route
              path="/onpremise/tipeHubunganIstimewa/:id"
              render={(props) => <TipeHubunganIstimewaForm {...props} />}
            />
            <Route
              path="/onpremise/tipeHubunganIstimewa"
              render={(props) => <TipeHubunganIstimewaTable {...props} />}
            />
            <Route
              path="/onpremise/tipeAkun/:id"
              render={(props) => <TipeAkunForm {...props} />}
            />
            <Route
              path="/onpremise/kode-objek-pajak/:id"
              render={(props) => <KodeObjekPajakForm {...props} />}
            />
            <Route
              path="/onpremise/kode-objek-pajak"
              render={(props) => <KodeObjekPajakTable {...props} />}
            />

            <Route
              path="/onpremise/tipeAkun"
              render={(props) => <TipeAkunTable {...props} />}
            />

            <Route
              path="/onpremise/dashboard-s"
              render={(props) => <DashboardSample {...props} />}
            />
            <Route
              path="/onpremise/table"
              render={(props) => <TableSample {...props} />}
            />
            <Route
              path="/onpremise/form"
              render={(props) => <FormSample {...props} />}
            />
            <Route
              path="/onpremise/details/:tab"
              render={(props) => <DetailsSample {...props} />}
            />
            <Route
              path="/onpremise/jenis-biaya/:id"
              render={(props) => <JenisBiayaForm {...props} />}
            />
            <Route
              path="/onpremise/jenis-biaya"
              render={(props) => <JenisBiayaTable {...props} />}
            />
            <Route
              path="/onpremise/batas-upah-harian/:id"
              render={(props) => <BatasUpahHarianForm {...props} />}
            />
            <Route
              path="/onpremise/batas-upah-harian"
              render={(props) => <BatasUpahHarianTable {...props} />}
            />
            <Route
              path="/onpremise/kategori-p-ph-25-s/:id"
              render={(props) => <KategoriPph25Form {...props} />}
            />
            <Route
              path="/onpremise/kategori-p-ph-25-s"
              render={(props) => <KategoriPph25Table {...props} />}
            />
            <Route
              path="/onpremise/komponen-penghasilan/:id"
              render={(props) => <KomponenPenghasilanForm {...props} />}
            />
            <Route
              path="/onpremise/komponen-penghasilan"
              render={(props) => <KomponenPenghasilanTable {...props} />}
            />
            <Route
              path="/onpremise/pengecualian-pajak/:id"
              render={(props) => <PengecualianPajakForm {...props} />}
            />
            <Route
              path="/onpremise/pengecualian-pajak"
              render={(props) => <PengecualianPajakTable {...props} />}
            />
            <Route
              path="/onpremise/pengenaan-pajak-lainnya/:id"
              render={(props) => <PengenaanPajakLainnyaForm {...props} />}
            />
            <Route
              path="/onpremise/pengenaan-pajak-lainnya"
              render={(props) => <PengenaanPajakLainnyaTable {...props} />}
            />
            {/* <Route
              path='/onpremise/penghasilan-spt-tahunan/:id'
              render={props => (
                <PenghasilanSptTahunanForm {...props}/>
              )}
            />
            <Route
              path='/onpremise/penghasilan-spt-tahunan'
              render={props => (
                <PenghasilanSptTahunanTable {...props}/>
              )}
            /> */}
            <Route
              path="/onpremise/penyesuaian-fiskal-negatif/:id"
              render={(props) => <PenyesuaianFiskalNegatifForm {...props} />}
            />
            <Route
              path="/onpremise/penyesuaian-fiskal-negatif"
              render={(props) => <PenyesuaianFiskalNegatifTable {...props} />}
            />
            <Route
              path="/onpremise/penyesuaian-fiskal-positif/:id"
              render={(props) => <PenyesuaianFiskalPositifForm {...props} />}
            />
            <Route
              path="/onpremise/penyesuaian-fiskal-positif"
              render={(props) => <PenyesuaianFiskalPositifTable {...props} />}
            />
            <Route
              path="/onpremise/ptkp/:id"
              render={(props) => <PtkpForm {...props} />}
            />
            <Route
              path="/onpremise/ptkp"
              render={(props) => <PtkpTable {...props} />}
            />
            <Route
              path="/onpremise/tarif-biaya/:id"
              render={(props) => <TarifBiayaForm {...props} />}
            />
            <Route
              path="/onpremise/tarif-biaya"
              render={(props) => <TarifBiayaTable {...props} />}
            />
            <Route
              path="/onpremise/tipe-pengecualian-pajak/:id"
              render={(props) => <TipePengecualianPajakForm {...props} />}
            />
            <Route
              path="/onpremise/tipe-pengecualian-pajak"
              render={(props) => <TipePengecualianPajakTable {...props} />}
            />
            <Route
              path="/onpremise/tipe-pic/:id"
              render={(props) => <TipePicForm {...props} />}
            />
            <Route
              path="/onpremise/tipe-pic"
              render={(props) => <TipePicTable {...props} />}
            />
            <Route
              path="/onpremise/tipe-pengurang-pajak/:id"
              render={(props) => <TipePengurangPajakForm {...props} />}
            />
            <Route
              path="/onpremise/tipe-pengurang-pajak"
              render={(props) => <TipePengurangPajakTable {...props} />}
            />
            <Route
              path="/onpremise/pengenaan-pajak-akun/:id"
              render={(props) => <PengenaanPajakAkunForm {...props} />}
            />
            <Route
              path="/onpremise/pengenaan-pajak-akun"
              render={(props) => <PengenaanPajakAkunTable {...props} />}
            />
            <Route
              path="/onpremise/tipe-status-pajak/:id"
              render={(props) => <TipeStatusPajakForm {...props} />}
            />
            <Route
              path="/onpremise/tipe-status-pajak"
              render={(props) => <TipeStatusPajakTable {...props} />}
            />
            <Route
              path="/onpremise/status-pajak-pembeli/:id"
              render={(props) => <StatusPajakPembeliForm {...props} />}
            />
            <Route
              path="/onpremise/status-pajak-pembeli"
              render={(props) => <StatusPajakPembeliTable {...props} />}
            />
            <Route
              path="/onpremise/status-pajak-penjual/:id"
              render={(props) => <StatusPajakPenjualForm {...props} />}
            />
            <Route
              path="/onpremise/status-pajak-penjual"
              render={(props) => <StatusPajakPenjualTable {...props} />}
            />
            <Route
              path="/onpremise/tarif/:id"
              render={(props) => <TarifForm {...props} />}
            />
            <Route
              path="/onpremise/tarif"
              render={(props) => <TarifTable {...props} />}
            />
            <Route
              path="/onpremise/pengenaan-pajak/:id"
              render={(props) => <PengenaanPajakForm {...props} />}
            />
            <Route
              path="/onpremise/pengenaan-pajak"
              render={(props) => <PengenaanPajakTable {...props} />}
            />
            <Route
              path="/onpremise/kelompok-aset/:id"
              render={(props) => <KelompokAsetForm {...props} />}
            />
            <Route
              path="/onpremise/kelompok-aset"
              render={(props) => <KelompokAsetTable {...props} />}
            />
            <Route
              path="/onpremise/tipe-aset/:id"
              render={(props) => <TipeAsetForm {...props} />}
            />
            <Route
              path="/onpremise/tipe-aset"
              render={(props) => <TipeAsetTable {...props} />}
            />
            <Route
              path="/onpremise/biaya-jabatan/:id"
              render={(props) => <BiayaJabatanForm {...props} />}
            />
            <Route
              path="/onpremise/biaya-jabatan"
              render={(props) => <BiayaJabatanTable {...props} />}
            />
            <Route
              path="/onpremise/biaya-pensiun/:id"
              render={(props) => <BiayaPensiunForm {...props} />}
            />
            <Route
              path="/onpremise/biaya-pensiun"
              render={(props) => <BiayaPensiunTable {...props} />}
            />
            <Route
              path="/onpremise/biaya-pensiun/:id"
              render={(props) => <BiayaPensiunForm {...props} />}
            />
            <Route
              path="/onpremise/tarif-progresif-pasal-17/:id"
              render={(props) => <TarifProgresifPasal17Form {...props} />}
            />
            <Route
              path="/onpremise/tarif-progresif-pasal-17"
              render={(props) => <TarifProgresifPasal17Table {...props} />}
            />
            <Route
              path="/onpremise/tarif-progresif-pesangon/:id"
              render={(props) => <TarifProgresifPesangonForm {...props} />}
            />
            <Route
              path="/onpremise/tarif-progresif-pesangon"
              render={(props) => <TarifProgresifPesangonTable {...props} />}
            />
            <Route
              path="/onpremise/tarif-progresif-pensiun/:id"
              render={(props) => <TarifProgresifPensiunForm {...props} />}
            />
            <Route
              path="/onpremise/tarif-progresif-pensiun"
              render={(props) => <TarifProgresifPensiunTable {...props} />}
            />
            <Route
              path="/onpremise/tarif-pph-29/:id"
              render={(props) => <TarifPph29Form {...props} />}
            />
            <Route
              path="/onpremise/tarif-pph-29"
              render={(props) => <TarifPph29Table {...props} />}
            />
            <Route
              path="/onpremise/tarif-pph-25/:id"
              render={(props) => <TarifPph25Form {...props} />}
            />
            <Route
              path="/onpremise/tarif-pph-25"
              render={(props) => <TarifPph25Table {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-penghasilan/:id"
              render={(props) => <PengaturanPenghasilanSptForm {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-penghasilan"
              render={(props) => <PengaturanPenghasilanSptTable {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-penghasilan-pph/:id"
              render={(props) => <PengaturanPenghasilanPPh21Form {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-penghasilan-pph"
              render={(props) => <PengaturanPenghasilanPPh21Table {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-pemotongan-pph/:id"
              render={(props) => <PengaturanPemotonganPPh21Form {...props} />}
            />
            <Route
              path="/onpremise/pengaturan-pemotongan-pph"
              render={(props) => <PengaturanPemotonganPPh21Table {...props} />}
            />

            <Route
              path="/onpremise/perusahaan/:id"
              render={(props) => <PerusahaanForm {...props} />}
            />
            <Route
              path="/onpremise/perusahaan-setting/:id"
              render={(props) => <PerusahaanSettingForm {...props} />}
            />
            <Route
              path="/onpremise/perusahaan"
              render={(props) => <PerusahaanTable {...props} />}
            />
            <Route
              path="/onpremise/entitas/:id/:tab"
              render={(props) => <EntitasForm {...props} />}
            />
            <Route
              path="/onpremise/entitas/:id"
              render={(props) => <EntitasForm {...props} />}
            />

            <Route
              path="/onpremise/entitas"
              render={(props) => <EntitasTable {...props} />}
            />
            <Route
              path="/onpremise/entitas-pajak/:id/:entitasId"
              render={(props) => <EntitasPajakForm {...props} />}
            />
            <Route
              path="/onpremise/coa/:id/:tab"
              render={(props) => <AkunForm {...props} />}
            />
            <Route
              path="/onpremise/coa/:id"
              render={(props) => <AkunForm {...props} />}
            />
            <Route
              path="/onpremise/coa"
              render={(props) => <AkunTable {...props} />}
            />
            <Route
              path="/onpremise/akun-pajak/:id/:akunId"
              render={(props) => <AkunPengenaanPajakForm {...props} />}
            />
            <Route
              path="/onpremise/lawan-transaksi/:id/:tab"
              render={(props) => <LawanTransaksiForm {...props} />}
            />
            <Route
              path="/onpremise/lawan-transaksi/:id"
              render={(props) => <LawanTransaksiForm {...props} />}
            />

            <Route
              path="/onpremise/lawan-transaksi"
              render={(props) => <LawanTransaksiTable {...props} />}
            />
            <Route
              path="/onpremise/lawan-transaksi-pajak/:id/:lawanTransaksiId"
              render={(props) => <LawanTransaksiPajakForm {...props} />}
            />
            <Route
              path="/onpremise/metode-penyusutan/:id"
              render={(props) => <MetodePenyusutanForm {...props} />}
            />
            <Route
              path="/onpremise/metode-penyusutan"
              render={(props) => <MetodePenyusutanTable {...props} />}
            />
            <Route
              path="/onpremise/pendapatan/:id"
              render={(props) => <PendapatanForm {...props} />}
            />
            <Route
              path="/onpremise/pendapatan"
              render={(props) => <PendapatanTable {...props} />}
            />
            <Route
              path="/onpremise/pengurang/:id"
              render={(props) => <PengurangForm {...props} />}
            />
            <Route
              path="/onpremise/pengurang"
              render={(props) => <PengurangTable {...props} />}
            />
            <Route
              path="/onpremise/barang-dan-jasa/:id"
              render={(props) => <ItemForm {...props} />}
            />
            <Route
              path="/onpremise/barang-dan-jasa"
              render={(props) => <ItemTable {...props} />}
            />
            <Route
              path="/onpremise/fixed-aset/:id"
              render={(props) => <FixedAsetForm {...props} />}
            />
            <Route
              path="/onpremise/fixed-aset"
              render={(props) => <FixedAsetTable {...props} />}
            />
            <Route
              path="/onpremise/jenis-usaha/:id"
              render={(props) => <JenisUsahaForm {...props} />}
            />
            <Route
              path="/onpremise/jenis-usaha"
              render={(props) => <JenisUsahaTable {...props} />}
            />
            <Route
              path="/onpremise/pegawai/:id"
              render={(props) => <PegawaiForm {...props} />}
            />
            <Route
              path="/onpremise/pegawai"
              render={(props) => <PegawaiTable {...props} />}
            />
            <Route
              path="/onpremise/pemotongan-pph2126/:id"
              render={(props) => <PemotonganPPh2126Form {...props} />}
            />
            <Route
              path="/onpremise/pemotongan-pph2126"
              render={(props) => <PemotonganPPh2126Table {...props} />}
            />
            <Route
              path="/onpremise/aset/:id"
              render={(props) => <AsetForm {...props} />}
            />
            <Route
              path="/onpremise/aset"
              render={(props) => <AsetTable {...props} />}
            />
            <Route
              path="/onpremise/transaksi/:id/:tab"
              render={(props) => <TransaksiForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi/:id"
              render={(props) => <TransaksiForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi"
              render={(props) => <TransaksiTable {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pajak-terutang/:id/:transaksiId"
              render={(props) => <TransaksiPajakTerutangForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pajak-terutang-pos/:id/:posId"
              render={(props) => <TransaksiPajakTerutangForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pajak-terutang-pembelian/:id/:pembelianId"
              render={(props) => <TransaksiPajakTerutangForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pajak-wp/:id/:transaksiId"
              render={(props) => <TransaksiPajakWpForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pajak-wp-pos/:id/:posId"
              render={(props) => <TransaksiPajakWpForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pajak-wp-pembelian/:id/:pembelianId"
              render={(props) => <TransaksiPajakWpForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-koreksi-fiskal/:id/:transaksiId"
              render={(props) => <TransaksiKoreksiFiskalForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-beban-entertainment/:id/:transaksiId"
              render={(props) => <TransaksiBebanEntertainmentForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-beban-promosi/:id/:transaksiId"
              render={(props) => <TransaksiBebanPromosiForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-referensi-hutang/:id/:transaksiId"
              render={(props) => <TransaksiReferensiHutangForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-referensi-pembayaran-hutang/:id/:transaksiId"
              render={(props) => (
                <TransaksiReferensiPembayaranHutangForm {...props} />
              )}
            />
            <Route
              path="/onpremise/transaksi-laba-rugi/:id/:transaksiId"
              render={(props) => <TransaksiLabaRugiForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pos/:id/:transaksiId"
              render={(props) => <TransaksiPosForm {...props} />}
            />
            <Route
              path="/onpremise/periode-fiskal-saldo-awal/:id"
              render={(props) => <MigrasiWrapper {...props} />}
            />
            <Route
              path="/onpremise/periode-fiskal-saldo-awal"
              render={(props) => <MigrasiWrapper {...props} />}
            />
            <Route
              path="/onpremise/transkrip"
              render={(props) => <TranskripWrapper {...props} />}
            />
            <Route
              path="/onpremise/migrasi-transkrip/:id"
              render={(props) => <MigrasiTranskripForm {...props} />}
            />
            <Route
              path="/onpremise/migrasi-transkrip"
              render={(props) => <MigrasiTranskripTable {...props} />}
            />
            <Route
              path="/onpremise/detail-modal/:id/:migrasiTranskripId"
              render={(props) => <DetailModalForm {...props} />}
            />
            <Route
              path="/onpremise/penyusutan-periode/:id"
              render={(props) => <PenyusutanPeriodeForm {...props} />}
            />
            <Route
              path="/onpremise/penyusutan-periode"
              render={(props) => <PenyusutanPeriodeTable {...props} />}
            />
            <Route
              path="/onpremise/penyusutan-periode-aset/:penyusutanPeriodeId/:akunId"
              render={(props) => <PenyusutanPeriodeAsetTable {...props} />}
            />
            <Route
              path="/onpremise/transaksi-aktiva-tetap/:id/:transaksiId"
              render={(props) => <TransaksiAktivaTetapForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-penjualan-aktiva-tetap/:id/:transaksiId"
              render={(props) => <TransaksiPenjualanAktivaTetapForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-referensi-piutang/:id/:transaksiId"
              render={(props) => <TransaksiReferensiPiutangForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-referensi-pembayaran-piutang/:id/:transaksiId"
              render={(props) => (
                <TransaksiReferensiPembayaranPiutangForm {...props} />
              )}
            />
            <Route
              path="/onpremise/transaksi-perubahan-modal/:id/:transaksiId"
              render={(props) => <TransaksiPerubahanModalForm {...props} />}
            />
            <Route
              path="/onpremise/penyusutan-periode-akun/:penyusutanPeriodeId"
              render={(props) => <PenyusutanPeriodeAkunTable {...props} />}
            />
            <Route
              path="/onpremise/sanding-aset"
              render={(props) => <SandingAsetTable {...props} />}
            />
            <Route
              path="/onpremise/sanding-aset-peraset/:id"
              render={(props) => <SandingAsetPerAsetTable {...props} />}
            />
            <Route
              path="/onpremise/sanding-aset-perakun/:id"
              render={(props) => <SandingAsetPerAkunTable {...props} />}
            />
            <Route
              path="/onpremise/kertas-kerja-sanding/:id"
              render={(props) => <KertasKerjaSandingTable {...props} />}
            />
            <Route
              path="/onpremise/periode-fiskal/:id"
              render={(props) => <PeriodeFiskalForm {...props} />}
            />
            <Route
              path="/onpremise/periode-fiskal"
              render={(props) => <PeriodeFiskalTable {...props} />}
            />
            <Route
              path="/onpremise/periode-unifikasi/:id"
              render={(props) => <PeriodePphUnifikasiForm {...props} />}
            />
            <Route
              path="/onpremise/periode-unifikasi"
              render={(props) => <PeriodePphUnifikasiTable {...props} />}
            />
            <Route
              path="/onpremise/transaksi-penjualan/:id/:tab"
              render={(props) => <VTransaksiItemForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-penjualan/:id"
              render={(props) => <VTransaksiItemForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-penjualan"
              render={(props) => <VTransaksiWrapper {...props} />}
            />
            <Route
              path="/onpremise/retur-penjualan-akun/:id/:tab"
              render={(props) => <ReturPenjualanAkunForm {...props} />}
            />
            <Route
              path="/onpremise/retur-penjualan-akun/:id"
              render={(props) => <ReturPenjualanAkunForm {...props} />}
            />
            <Route
              path="/onpremise/retur-penjualan/:id/:tab"
              render={(props) => <ReturPenjualanPosForm {...props} />}
            />
            <Route
              path="/onpremise/retur-penjualan/:id"
              render={(props) => <ReturPenjualanPosForm {...props} />}
            />
            <Route
              path="/onpremise/retur-penjualan"
              render={(props) => <ReturPenjualanWrapper {...props} />}
            />
            <Route
              path="/onpremise/sanding-pph21"
              render={(props) => <SandingPPh21Akun {...props} />}
            />

            <Route
              path="/onpremise/sanding-transaksi/:akunId"
              render={(props) => <SandingPPh21Transaksi {...props} />}
            />

            <Route
              path="/onpremise/sanding-perhitungan/:akunId"
              render={(props) => <SandingPPh21Perhitungan {...props} />}
            />

            <Route
              path="/onpremise/pajak-terutang/:tipeBebanPajak/:type"
              render={(props) => <PajakTerutangWrapper {...props} />}
            />

            <Route
              path="/onpremise/pajak-terutang-menu/:tipeBebanPajak"
              render={(props) => <PajakTerutangMenu {...props} />}
            />
            <Route
              path="/onpremise/Pajak-terutang-sanding/:id"
              render={(props) => <PajakTerutangSandingForm {...props} />}
            />

            <Route
              path="/onpremise/pph-25/:id"
              render={(props) => <PPh25Form {...props} />}
            />

            <Route
              path="/onpremise/pph-25"
              render={(props) => <PPh25Wrapper {...props} />}
            />

            <Route
              path="/onpremise/member"
              render={(props) => <MemberForm {...props} />}
            />

            <Route
              path="/onpremise/role/:id"
              render={(props) => <RoleForm {...props} />}
            />

            <Route
              path="/onpremise/role"
              render={(props) => <RoleTable {...props} />}
            />
            <Route
              path="/onpremise/log-import"
              render={(props) => <LogImportTable {...props} />}
            />
            <Route
              path="/onpremise/log-export"
              render={(props) => <LogExportTable {...props} />}
            />
            <Route
              path="/onpremise/spt1771"
              render={(props) => <Spt1771Form {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pembelian/:id/:tab"
              render={(props) => <TransaksiPembelianForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pembelian/:id"
              render={(props) => <TransaksiPembelianForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pembelian"
              render={(props) => <TransaksiPembelianTable {...props} />}
            />
            <Route
              path="/onpremise/transaksi-perjanjian-pembelian/:id/:tab"
              render={(props) => <TransaksiPerjanjianPembelianForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-perjanjian-pembelian/:id"
              render={(props) => <TransaksiPerjanjianPembelianForm {...props} />}
            />
            <Route
              path="/onpremise/transaksi-perjanjian-pembelian"
              render={(props) => <TransaksiPerjanjianPembelianTable {...props} />}
            />
            <Route
              path="/onpremise/transaksi-pembelian-akun/:id/:pembelianId"
              render={(props) => <TransaksiPembelianAkunForm {...props} />}
            />
            <Route
              path="/onpremise/master-level/:id"
              render={(props) => <MasterLevelForm {...props} />}
            />
            <Route
              path="/onpremise/master-level"
              render={(props) => <MasterLevelTable {...props} />}
            />
            <Route
              path="/onpremise/daftar-nominatif-biaya-promosi/:id"
              render={(props) => <TransaksiBebanPromosiForm {...props} />}
            />
            <Route
              path="/onpremise/daftar-nominatif-biaya-promosi"
              render={(props) => <DaftarNominatifPromosiTable {...props} />}
            />
            <Route
              path="/onpremise/daftar-nominatif-biaya-entertainment/:id"
              render={(props) => <TransaksiBebanEntertainmentForm {...props} />}
            />
            <Route
              path="/onpremise/daftar-nominatif-biaya-entertainment"
              render={(props) => <DaftarNominatifEntertainmentTable {...props} />}
            />
            <Route
              path="/onpremise/koreksi-fiskal/:id"
              render={(props) => <TransaksiKoreksiFiskalForm {...props} />}
            />
            <Route
              path="/onpremise/koreksi-fiskal"
              render={(props) => <KoreksiFiskalTable {...props} />}
            />
            <Route
              path="/onpremise/kertas-kerja-koreksi-fiskal"
              render={(props) => <VKertasKerjaKoreksiFiskalTable {...props} />}
            />
            <Route
              path="/onpremise/data-silk/:id"
              render={(props) => <SilkForm {...props} />}
            />
            <Route
              path="/onpremise/data-silk"
              render={(props) => <SilkTable {...props} />}
            />

          </Switch>
        </Router>
      </PageWrapper>
    );
  }


};

export default onp({
  basePath: "/onpremise",
})(Onpremise);
