import React from 'react'
import Flex from '../Flex'
import { inject, observer } from 'mobx-react'
import './Sidebar.scss'
import Menu from '../Menu'
import { cloneDeep } from 'lodash';
import Footer from './Footer'
import { CompanyCard } from '..'

const Sidebar = ({
  baseId      = 'mpk-sidebar',
  className   = '',
  collapsible = false,
  header      = null,
  menu        = null,
  authStore,
  envStore,
  ...props
}) => {
  menu = menu || (authStore.isLoggedIn ? cloneDeep(authStore.user.menu.slice()) : null)

  let company = authStore.isLoggedIn ? authStore.user.company : null

  return (
    <Flex
      className={`mpk-sidebar mpk-full full-height ${className}`}
      direction={Flex.properties.direction.COLUMN}
      fit={false}
    >

      {header}
      {company ? (
        <CompanyCard
          value={company} thumbnail
          href={`${envStore.env.appConsole.userAccount.url}/company/${company.id}/profile`}
        />
      ) : null}
      <div className="flex mpk-full full-width">
        {menu && (
          <Menu
            baseId={baseId}
            className="mpk-full full-width full-height"
            collapsible={collapsible}
            items={menu}
            {...props}
          />
        )}
      </div>
      <Footer/>
    </Flex>
  )
}

export default inject('authStore', 'envStore')(observer(Sidebar))
