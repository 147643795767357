import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import service from './PPh21A1.service'
import Utils from '../../../libs/custom/Utils'
import SlipGajiService from '../SlipGaji/SlipGaji.service'
import TipePegawaiService from '../../adminpajakku/tipePegawai/TipePegawai.service'

const PPh21A1DataForm = ({envStore, match, history, data, onSubmit}) => {
  const { inputTypes, definition } = FormWrapper
  const [slipGajiList, setSlipGajiList] = useState([])
  const [slipGajiMap, setSlipGajiMap] = useState({})
  const [tipePegawaiList, setTipePegawaiList] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      var entitas = localStorage['sip-menu-pph']?JSON.parse(localStorage['sip-menu-pph']):{}
      await _getSlipGajiList(
        // {'entitasId.equals': entitas.id}
        )
      await _getTipePegawaiList()
    }
    initData()
  }, ['match.params.id'])

  async function _getSlipGajiList(params) {
    if(!params) params = {}

    params.size = 100000000
    var res = await SlipGajiService.get(params)
    setSlipGajiMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setSlipGajiList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTipePegawaiList() {
    var res = await TipePegawaiService.get()
    setTipePegawaiList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      showCommandbar={false}
      backTo={`/pph-menu/a1`}
      baseId="mod-form-sample"
      title='Pemotongan PPh 21 A1'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName='mdi mdi-arrow-right'
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'slipGajiPegawaiId') {
          newObj.slipGajiPegawai = Utils.formSerialize(slipGajiMap[value]?slipGajiMap[value]:{})
          newObj = Utils.formSerialize(newObj)
          console.log(newObj, 'newObj')
        }

        return newObj
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: 'IDENTITAS PEMOTONG',
        },{
          inputType: inputTypes.INPUT,
          label: 'NPWP Perusahaan',
          key: 'entitas__entitas__npwp',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Alias',
          key: 'entitas__entitas__alias',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama Perusahaan',
          key: 'entitas__entitas__nama',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          disabled: true
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'IDENTITAS PENERIMA PENGHASILAN',
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama Pegawai',
          key: 'slipGajiPegawai__nama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'NPWP Pegawai',
          key: 'slipGajiPegawai__npwp',
          type: 'text',
          width: '49%',
          disabled: true
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'NIK',
          key: 'slipGajiPegawai__nik',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'NIP',
          key: 'slipGajiPegawai__nip',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'required',
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'slipGajiPegawai__email',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          required: true,
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Telepon',
          key: 'slipGajiPegawai__telepon',
          type: 'text',
          width: '49%',
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Alamat',
          key: 'slipGajiPegawai__alamat',
          style: {marginRight: '2%'},
          type: 'text',
          width: '100%',
          disabled: true
        },{
          inputType: inputTypes.SELECT,
          label: 'Domisili Wajib Pajak',
          key: 'slipGajiPegawai__domisiliWajibPajak',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          options: [{value: "DN", label: "DN"}, {value: "LN", label: "LN"}],
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Negara',
          key: 'slipGajiPegawai__negara',
          type: 'text',
          width: '49%',
          validation: 'required',
          disabled: true
        }, {
          inputType: inputTypes.SELECT,
          label: 'PTKP',
          key: 'slipGajiPegawai__ptkp',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          style: {marginRight: '2%'},
          disabled: true,
          options: [
            { label: 'TK/0', value: 'TK/0' },
            { label: 'TK/1', value: 'TK/1' },
            { label: 'TK/2', value: 'TK/2' },
            { label: 'TK/3', value: 'TK/3' },
            { label: 'K/0',  value: 'K/0' },
            { label: 'K/1',  value: 'K/1' },
            { label: 'K/2',  value: 'K/2' },
            { label: 'K/3',  value: 'K/3' },
            { label: 'KI/0', value: 'KI/0' },
            { label: 'KI/1', value: 'KI/1' },
            { label: 'KI/2', value: 'KI/2' },
            { label: 'KI/3', value: 'KI/3' }
          ]
        },{
          inputType: inputTypes.INPUT,
          label: 'Agama',
          key: 'slipGajiPegawai__agama',
          type: 'text',
          width: '49%',
          disabled: true
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Status Pindahan',
          key: 'slipGajiPegawai__gaji__statusPindahan',
          type: 'text',
          width: '100%',
          required: true,
          validation: 'required',
          disabled:true,
          options : [
            { label: "Normal",    value: "0" },
            { label: "Dipindahkan ke kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama",    value: "1" },
            { label: "Berhenti ke pemberi kerja lain/Berhenti tidak meninggalkan Indonesia/Berhenti karena pensiun",    value: "2" },
            { label: "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",     value: "3" },
            { label: "Pegawai baru mulai bekerja",   value: "4" },
            { label: "Pegawai Pindahan dari kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama/Pegawai Baru dari pemberi kerja lain/Pegawai Baru saja Pensiun untuk 21-100-02",  value: "5" }
          ]
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Jabatan',
          key: 'slipGajiPegawai__jabatan',
          type: 'text',
          width: '49%',
          disabled: true,
          style: {marginRight: '2%'},
        },{
          inputType: inputTypes.SELECT,
          label: 'Metode Pemotongan Pajak',
          key: 'slipGajiPegawai__gaji__metodePotongPajak',
          type: 'text',
          required: true,
          width: '49%',
          validation: 'required',
          disabled:true,
          options: [
            { label: "Nett",     value: "Nett"},
            { label: "Gross",    value: "Gross"},
            { label: "Gross Up", value: "Gross Up"}
          ]
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Masuk',
          key: 'slipGajiPegawai__gaji__tanggalMasuk',
          width: '49%',
          style: {marginRight: '2%'},
          disabled:true,
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Keluar',
          key: 'slipGajiPegawai__gaji__tanggalKeluar',
          width: '49%',
          disabled:true
        },
        // -----
      ]}
      onSubmit={onSubmit}
    />
  )
}

const Pph21A1Form = ({envStore, match, history, data, onSubmit}) => {
  const { inputTypes, definition } = FormWrapper

  const [slipGajiList, setSlipGajiList] = useState([])

  useEffect(() => {
    async function initData() {
      await _getSlipGajiList()

    }
    initData()
  }, ['match.params.id'])

  async function _getSlipGajiList() {
    var res = await SlipGajiService.get()
    setSlipGajiList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      showCommandbar={false}
      backTo={`/pph-menu/a1`}
      baseId="mod-form-sample"
      title={t.translate('modules.pph21A1.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onSubmit={onSubmit}
      tableForm={true}
      definitions={{
        header: [
          {
            width: '49%',
            render: 'NAMA'
          },
        ],
        body:[
          [
            // {
            //   render: <div style={{paddingTop: 20}}>1. GAJI/PENSIUN ATAU THT/JHT</div>
            // },
            {
              inputType: inputTypes.SELECT,
              label: 'b00',
              key: 'kodeObjekPajak',
              type: 'text',
              style: {marginTop: 10},
              width: '100%',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              options: [{value: '21-100-01', label: '21-100-01 (Karyawan Tetap)'}, {value: '21-100-02', label: '21-100-02 (Karyawan Pensiunan)'}]
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>1. GAJI/PENSIUN ATAU THT/JHT</div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b01 (Rp)',
              key: 'b01',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>2. TUNJANGAN PPh</div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b02 (Rp)',
              key: 'b02',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>3. TUNJANGAN LAINNYA, UANG LEMBUR DAN SEBAGAINYA</div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b03 (Rp)',
              key: 'b03',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>4. HONORARIUM DAN IMBALAN LAIN SEJENISNYA</div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b04 (Rp)',
              key: 'b04',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>5.  PREMI ASURANSI YANG DIBAYAR PEMBERI KERJA</div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b05 (Rp)',
              key: 'b05',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>6.   PENERIMAAN DALAM BENTUK NATURA DAN KENIKMATAN LAINNYA YANG DIKENAKAN PEMOTONGAN PPh PASAL 21</div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b06 (Rp)',
              key: 'b06',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>7.   TANTIEM, BONUS, GRATIFIKASI, JASA PRODUKSI DAN THR</div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b07 (Rp)',
              key: 'b07',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>8.  JUMLAH PENGHASILAN BRUTO (1 S.D.7)              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b08 (Rp)',
              key: 'b08',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>9.   BIAYA JABATAN/ BIAYA PENSIUN              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b09 (Rp)',
              key: 'b09',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>10.    IURAN PENSIUN ATAU IURAN THT/JHT              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b10 (Rp)',
              key: 'b10',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>11.     JUMLAH PENGURANGAN (9 S.D 10)              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b11 (Rp)',
              key: 'b11',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>12.      JUMLAH PENGHASILAN NETO (8-11)              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b12 (Rp)',
              key: 'b12',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>13.      PENGHASILAN NETO MASA SEBELUMNYA              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b13 (Rp)',
              key: 'b13',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>14.      JUMLAH PENGHASILAN NETO UNTUK PENGHITUNGAN PPh PASAL 21 (SETAHUN/DISETAHUNKAN)              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b14 (Rp)',
              key: 'b14',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>15.       PENGHASILAN TIDAK KENA PAJAK (PTKP)              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b15 (Rp)',
              key: 'b15',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>16.        PENGHASILAN KENA PAJAK SETAHUN/DISETAHUNKAN (14 - 15)              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b16 (Rp)',
              key: 'b16',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>17.         PPh PASAL 21 ATAS PENGHASILAN KENA PAJAK SETAHUN/DISETAHUNKAN              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b17 (Rp)',
              key: 'b17',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>18.          PPh PASAL 21 YANG TELAH DIPOTONG MASA SEBELUMNYA              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b18 (Rp)',
              key: 'b18',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>19.           PPh PASAL 21 TERUTANG             </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b19 (Rp)',
              key: 'b19',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ],
          [
            {
              render: <div style={{paddingTop: 20}}>20.            PPh PASAL 21 DAN PPh PASAL 26 YANG TELAH DIPOTONG DAN DILUNASI              </div>
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'b20 (Rp)',
              key: 'b20',
              type: 'text',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required|numeric'
            }
          ]
        ],
      }
    }
  />
  )
}

const Pph21A1Wrapper = ({envStore, match, history}) => {
  // initial data
  const [data, setData] = useState({loading: true, content: {}})
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    async function initData() {
      var entitas = localStorage['sip-menu-pph']?JSON.parse(localStorage['sip-menu-pph']):{}

      if(match.params.id == 'baru') {
        setData({loading: false, content: Utils.formSerialize({
          "pemotonganPPh2126Id": localStorage['sip-menu-pph']?JSON.parse(localStorage['sip-menu-pph']).id:null,
          entitas: Utils.formSerialize(entitas),
          "kodeObjekPajak": "21-100-01",
          "b01": 0,
          "b02": 0,
          "b03": 0,
          "b04": 0,
          "b05": 0,
          "b06": 0,
          "b07": 0,
          "b08": 0,
          "b09": 0,
          "b10": 0,
          "b11": 0,
          "b12": 0,
          "b13": 0,
          "b14": 0,
          "b15": 0,
          "b16": 0,
          "b17": 0,
          "b18": 0,
          "b19": 0,
          "b20": 0,
          "brutoPayroll": 0,
          "pphPayroll": 0,
        })})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data.slipGajiPegawai = Utils.formSerialize(res.data.slipGajiPegawai)
      res.data.entitas = Utils.formSerialize(entitas)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  return <TabsWrapper
    title='Pemotongan PPh 21 A1'
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-details-sample"
    actions={[
      {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/pph-menu/a1')}
    ]}
    tabs={[
      {
        label: 'Pemotong & Penerima Penghasilan',
        key: 'nested-form',
        render: <PPh21A1DataForm data={data} match={match} history={history}
          onSubmit={async (d, callback) => {
            // var res = {}
            // if(match.params.id == 'baru') {
            //   res = await service.post(data)
            // } else {
            //   res = await service.put(data)
            // }

            // callback(t.translate('global.dataTelahDiSimpan'), false)
            // history.push('/onpremise/pegawai')

            setData({...data, ...d})
            callback('', false)
            setCurrentIndex(1)
          }}
        />
      },
      {
        label: 'Perhitungan PPh',
        key: 'data-perhitungan-pph',
        render: (
          <Pph21A1Form data={data} match={match} history={history}
          onSubmit={async (d, callback) => {
            setData({...data, ...d})
            var res = {}
            try {
              var ndata = Utils.formNormalize({...data, ...d})
              if(match.params.id == 'baru') {
                res = await service.post(ndata)
              } else {
                res = await service.put(ndata)
              }
              callback(t.translate('global.dataTelahDiSimpan'), false)
              history.push('/pph-menu/a1')
            } catch(e) {
              callback(e, true, false)
            }
          }}/>
        )
      },
    ]}
  />
}

export default inject('sipStore', 'navigationStore')(observer(Pph21A1Wrapper))
