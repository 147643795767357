import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Member.service'
import Utils from '../../../libs/custom/Utils'
import {
  Card,
  CardContent,
  CardTitle,
  CardSubtitle,
  CardHeader,
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Caption,
  TableCheckbox,
  Checkbox,
  List,
  ListItem,
  Avatar,
  Button,
  FontIcon,
  TextIconSpacing
} from 'react-md'
import RoleService from './../Role/Role.service'
import _ from 'lodash'
import PerusahaanService from '../Perusahaan/Perusahaan.service'
import UserService from '../../adminpajakku/users/Users.service'
import MemberService from './Member.service'
import RoleTable from './../Role/Role.table'

const MemberForm = ({ envStore, match, history, modalStore, sipStore, authStore }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({ loading: true, content: {} })
  const [perusahaan, setPerusahaan] = useState([])
  const [role, setRole] = useState([])
  const [user, setUser] = useState([])
  const [member, setMember] = useState([])
  const [page, setPage] = useState(0)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(async () => {
    setData({ loading: false, content: {
      perusahaan__id: sipStore.companyId
    } })
    try {
        var query = {
            size: 10000
        }
        var perusahaans = await MemberService.getPerusahaan(query);
        var options = []
        perusahaans.data.map((d)=> {
          options.push({
            label: d.nama,
            value: d.id
          })
        })
        setPerusahaan(options)
    } catch(e){}
    try {
        var query = {
            size: 10000
        }
        var users = await MemberService.getUser(query);
        var options = []
        users.data.map((d)=> {
          options.push({
            label: d.login,
            value: JSON.stringify({
              id: d.id,
              login: d.login,
              firstName: d.firstName,
              lastName: d.lastName
            })
          })
        })
        setUser(options)
    } catch(e){}
    try {
        var query = {
            size: 10000,
            perusahaanId: sipStore.companyId
        }
        var roles = await MemberService.getRoleId(query);
        var options = []
        roles.data.map((d)=> {
        options.push({
            label: d.nama,
            value: JSON.stringify(d)
        })
        })
        setRole(options)
    } catch(e){}
    refreshMember();
  }, [])

  var refreshMember = async (pagez)=> {
    var pagezx = 0
    if(pagez){
      pagezx = pagez
    }
    try {
        var query = {
            size: 20,
            page: pagezx
        }
        var members = await MemberService.getMember(query);
        var totalPage = Math.ceil(members.headers["x-total-count"]/20)
        setTotalPage(totalPage)
        setMember(members.data)
    } catch(e){}
  }

  var handleDelete = async(d, i)=> {
    modalStore.showConfirm({
        title: 'Hapus',
        children: 'Apa anda yakin akan menghapus user yang dipilih ?',
        onSubmit: async (callback)=> {
            var res = await service.delete(d.id)
            refreshMember();
            callback('', false)
        }
    })
  }

  return (
    <>
    <FormWrapper
      noValidate
      loading={data.loading}
      style={{
        maxWidth: '100%'
      }}
      // backTo={`/onpremise/Role`}
      baseId="mod-form-sample"
      title={t.translate('modules.member.formTitle')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (formData, key, value)=> {
        if(key == 'perusahaan_id'){
            try {
                var query = {
                    size: 10000,
                    perusahaanId: value
                }
                var roles = await MemberService.getRole(query);
                var options = []
                roles.data.map((d)=> {
                options.push({
                    label: d.nama,
                    value: JSON.stringify(d)
                })
                })
                setRole(options)
            } catch(e){}
        }
      }}
      definitions={[
        {
            inputType: inputTypes.SELECT,
            label: 'Perusahaan',
            key: 'perusahaan__id',
            type: 'text',
            width: '100%',
            // disabled:true,
            validation: 'required',
            options: perusahaan
          },
          {
            inputType: inputTypes.SELECT,
            label: 'User',
            key: 'user',
            type: 'text',
            width: '100%',
            validation: 'required',
            options: user
          },
          {
            inputType: inputTypes.SELECT,
            label: 'Role',
            key: 'role',
            type: 'text',
            width: '100%',
            validation: 'required',
            options: role
          },
          {
              render: ()=> {
                var role   = ""
                var firstName  = ""
                var lastName   = ""
                var perusahaan = ""
                return (
                    <Card style={{ width: '100%' }}>
                        <CardHeader>
                            <CardTitle>Daftar Member</CardTitle>
                        </CardHeader>
                        <CardContent>
                            {member && member.length > 0 &&
                            <List>
                                {member.map((d, index)=> {
                                    if(d && d.perusahaan && d.perusahaan.nama) perusahaan = d.perusahaan.nama
                                    if(d && d.user) firstName = d.user.firstName
                                    if(d && d.user) lastName = d.user.lastName
                                    if(d && d.role && d.role.nama) role = d.role.nama
                                    return <ListItem
                                        leftAddon={<Avatar>F</Avatar>}
                                        leftAddonType="avatar"
                                        secondaryText={<p style={{ fontSize: 14, color: 'grey' }}>Perusahaan: {perusahaan}</p>}
                                        rightAddon={
                                            <div>
                                                <Button theme="primary" themeType="contained" style={{ margin: '10px' }} onClick={(e)=> {
                                                    var q = Object.assign({}, d)
                                                    q.role = JSON.stringify(q.role)
                                                    q.perusahaan = JSON.stringify(q.perusahaan)
                                                    q.user = JSON.stringify(q.user)
                                                    setData({loading: false, content: q})
                                                }}>
                                                    <TextIconSpacing icon={<FontIcon className="mdi mdi-pencil"></FontIcon>}>
                                                        Edit
                                                    </TextIconSpacing>
                                                </Button>
                                                <Button theme="error" themeType="contained" onClick={()=> { handleDelete(d, index) }}>
                                                    <TextIconSpacing icon={<FontIcon className="mdi mdi-delete"></FontIcon>}>
                                                        Hapus
                                                    </TextIconSpacing>
                                                </Button>
                                            </div>
                                        }
                                    >
                                        <b>{role}</b> - {firstName} {lastName}
                                    </ListItem>
                                })}
                            </List>
                            }
                            <div style={{ textAlign: 'center' }}>
                            <Button onClick={async ()=> {
                              await setPage(0);
                              refreshMember(0);
                            }}><FontIcon className="mdi mdi-chevron-double-left"></FontIcon></Button>
                            {page > 0 &&
                              <Button onClick={async ()=> {
                                await setPage(page - 1);
                                refreshMember(page - 1);
                              }}><FontIcon className="mdi mdi-chevron-left"></FontIcon></Button>
                            }
                            <Button style={{ textAlign: 'center' }}>Page: {page + 1}/{totalPage}</Button>
                            {(page + 1) < totalPage &&
                              <Button onClick={async ()=> {
                                await setPage(page + 1);
                                refreshMember(page + 1);
                              }}><FontIcon className="mdi mdi-chevron-right"></FontIcon></Button>
                            }
                            <Button onClick={async ()=> {
                              await setPage(totalPage - 1);
                              refreshMember(totalPage - 1);
                            }}><FontIcon className="mdi mdi-chevron-double-right"></FontIcon></Button>
                            </div>
                        </CardContent>
                        {/* <RoleTable /> */}
                    </Card>
                )
              }
          }
      ]}
      onSubmit={async (values, callback) => {
        try {
          // if(authStore.user.roleMap?authStore.user.roleMap['Tambah Pengguna'].Create:false == true){
          //   callback('Anda tidak diizinkan untuk menyimpan data ini!', true)
          //   return false
          // }
            values.user = JSON.parse(values.user)
            values.role = JSON.parse(values.role)
            values = Utils.formNormalize(values)
            if(values.id){
                var res = await service.put(values)
            } else {
                var res = await service.post(values)
            }
            callback(t.translate('global.dataTelahDiSimpan'), false)
            setData({ loading: false, content: {} })
            refreshMember();
        } catch(e){
            callback(e, true, false)
        }
      }}
    />
    </>
  )
}

export default inject('envStore', 'temporaryStore', 'modalStore', 'sipStore', "authStore")(observer(MemberForm))
