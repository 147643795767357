import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import Utils from '../../../libs/custom/Utils'
import { ID_TIPE_PEGAWAI, TIPE_TRANSKRIP } from '../../../clientConstant'
import SaldoAwalForm from './SaldoAwal.form'
import ElemenTranskripService from '../../adminpajakku/ElemenTranskrip/ElemenTranskrip.service'
import AkunService from '../../master/Akun/Akun.service'
import SaldoAwalService from './SaldoAwal.service'
import MigrasiTranskripTable from './MigrasiTranskrip.table'
import DetailModalTable from './DetailModal.table'
import SaldoNeracaTranskripService from './SaldoNeracaTranskrip.service'
import AsetTable from '../Aset/Aset.table'

const PegawaiWrapper = ({envStore, match, history, sipStore, navigationStore}) => {
  // initial data
  const [data, setData] = useState({
    loading: true,
    content: {},
    saldoList: [],
    akunMap: {},
    etMap: {},
    saldoMap: {},
  })
  const [currentIndex, setCurrentIndex] = useState(0)
  const [etList, setEtList] = useState([])
  const [etMap, setEtMap] = useState({})
  const [akunList, setAkunList] = useState([])

  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    async function initData() {
      var e = await _getElemenTranskripList()
      setData({
        loading: false,
        saldoMap: e.saldoMap,
        saldoList: e.saldoList,
        akunMap: e.akunMap,
        etMap: e.etMap,
        content: {}
      })
    }
    initData()
  }, ['match.params.migrasiTranskripId'])

  async function _getElemenTranskripList() {
    var company = localStorage['sip-company-active']?JSON.parse(localStorage['sip-company-active']):{}

    var etRes = await ElemenTranskripService.get({'tipeIndustriId.equals': company.tipeIndustri.id, size: 100000, sort: 'nomorUrut,ASC'})
    var akunRes = await AkunService.get({'perusahaanId.equals': company.id, size: 200000})
    var saldoRes = await SaldoNeracaTranskripService.get({'periodeFiskalId.equals':sipStore.periodeId, size: 20000})
    var saldoListMap = saldoRes.data.reduce((pv, cv)=> {pv[cv.elemenTranskrip.id] = cv; return pv;}, {})
    var etMap = etRes.data.reduce((pv, cv)=> {
      pv[cv.id] = cv;
      return pv
    }, {})

    console.log(etRes, 'asdsad')

    setAkunList(akunRes.data)
    var saldoList = etRes.data.map((x)=> {
      if(saldoListMap[x.id]) {

        var saldoListAkunMap = saldoListMap[x.id].saldoNeracaAkuns.reduce((pv, cv)=> {
          pv[cv.akun?cv.akun.id:''] = cv
          return pv
        }, {})

        akunRes.data.filter(y => y.elemenTranskripId == x.id).forEach((d)=> {
          if(!saldoListAkunMap[d.id]) {
            saldoListMap[x.id].saldoNeracaAkuns.push({
              akun: {id: d.id},
              akunId:  d.id,
              nilaiAkhir: 0,
              nilaiAwal: 0,
              posisiAkunAkhir: 'D',
              posisiAkunAwal: 'D',
            })
          }
        })


        return saldoListMap[x.id]
      } else {
        return {
          elemenTranskrip: etMap[x.id],
          nilaiAkhir: 0,
          nilaiAwal: 0,
          periodeFiskal: {
            id: sipStore.periodeId,
          },
          koreksiFiskal: 0,
          posisiAkunAwal: 'D',
          saldoNeracaAkuns: akunRes.data.filter(y => y.elemenTranskripId == x.id).map(y => {
            return {
              akun: {id: y.id},
              akunId:  y.id,
              nilaiAkhir: 0,
              nilaiAwal: 0,
              posisiAkunAkhir: 'D',
              posisiAkunAwal: 'D',
            }
          })
        }
      }
    })

    var saldoMap = saldoList.reduce((pv, cv)=> {
      pv[cv.elemenTranskrip.id] = cv.saldoNeracaAkuns
      pv[cv.elemenTranskrip.id+"_nilaiAwal"] = cv.saldoNeracaAkuns.reduce((pv, cv) => {
        if (cv.posisiAkunAwal == "K") {
          pv = pv - parseInt(cv.nilaiAwal);
        } else {
          pv = pv + parseInt(cv.nilaiAwal);
        }
        return pv;
      }, 0);

      return pv;
    }, {})

    return {
      saldoList,
      saldoMap,
      akunMap: akunRes.data.reduce((pv, cv)=> {
        pv[cv.id] = cv;
        return pv
      }, {}),

      etMap: etMap
    }
  }

  // console.log(data.loading, 'asd')
  if(data.loading) {
    return <div />
  }

  return <TabsWrapper
    title={(match.params.id) ? "Saldo Awal" : "Migrasi"}
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-details-migrasi"
    actions={[
      {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/onpremise/periode-fiskal'), show : match.params.id ? true : false}
    ]}
    tabs={[
      {
        label: 'SALDO AWAL NERACA - AKTIVA',
        key: 'neraca-aktiva',
        render: <SaldoAwalForm data={data} match={match} history={history} tipeTranskrip={TIPE_TRANSKRIP.neracaAktiva}
          sipStore={sipStore} navigationStore={navigationStore}
          onSubmit={async (resData, callback) => {
            var datas = []
            data.saldoList.filter((x)=> data.etMap[x.elemenTranskrip.id].tipeTranskripId == TIPE_TRANSKRIP.neracaAktiva).forEach((d,i)=> {
              if(resData[d.elemenTranskrip.id]) {
                d.saldoNeracaAkuns = resData[d.elemenTranskrip.id]
                datas.push(d)
              }
            })

            await SaldoAwalService.http.post('/saldo-neraca-transkrip-bulk', datas)

            setData({...data, saldoMap: resData})
            callback('', false)
            setCurrentIndex(1)
          }}
        />
      },
      {
        label: 'SALDO AWAL NERACA - PASIVA',
        key: 'neraca-pasiva',
        render: (<SaldoAwalForm data={data} match={match} history={history} tipeTranskrip={TIPE_TRANSKRIP.neracaPasiva}
          sipStore={sipStore} navigationStore={navigationStore}
          onSubmit={async (resData, callback) => {

            var datas = []
            data.saldoList.filter((x)=> data.etMap[x.elemenTranskrip.id].tipeTranskripId == TIPE_TRANSKRIP.neracaPasiva).forEach((d,i)=> {
              if(resData[d.elemenTranskrip.id]) {
                d.saldoNeracaAkuns = resData[d.elemenTranskrip.id]
                datas.push(d)
              }
            })

            await SaldoAwalService.http.post('/saldo-neraca-transkrip-bulk',datas)

            setData({...data, saldoMap: resData})
            callback('', false)
            setCurrentIndex(2)
          }}
        />
        )
      },
      {
        label: 'DETAIL MODAL',
        key: 'detail-modal',
        render: <DetailModalTable transkripData={data} akunList={akunList} match={match} history={history} />
      },
      {
        label: 'DETAIL ASET TETAP',
        key: 'aset-tetap',
        render: <AsetTable transkripData={data} akunList={akunList} match={match} history={history} migrasi={true}   />
      }
    ]}
  />
}

export default inject('sipStore', 'navigationStore')(observer(PegawaiWrapper))
