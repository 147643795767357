import React, { useState, useEffect} from 'react'
import Picture from './Picture'
import { DropdownMenu, FontIcon } from 'react-md'
import t from 'counterpart'
import { inject, observer } from 'mobx-react';
import { Flex } from '..';
import Service from '../../../../modules/OpLogin/OpLogin.service'
import { DataForm } from "../../../react-mpk/components/index";
import { Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
import iziToast from 'izitoast'

const AppInfo = (props) => {
  return(
    <div>
      <div className="mpk-font size-L weight-B">{props.envStore.env.appInfo.name}</div>
      <div className="mpk-font size-NS">{props.envStore.env.appInfo.version}</div>
      <div className="mpk-font size-NS"> <a style={{ color: 'blue' }} href="/#/license" style={{ textAlign: 'center' }}>Cek Lisensi SIP</a></div>
    </div>
  )
}

const Profile = ({
  menuItems = [],
  ...props
}) => {
  const [name, setName] = useState('...');
  const [showDialog, setShowDialog] = useState(false)

  useEffect(() => {
    if(props.authStore.isLoggedIn){
      try { setName(props.authStore.user.name.split(' ')[0]); } catch(e){}
    }
    if(props.username){
      setName(props.username)
    }
  }, [props.authStore.isLoggedIn])

  useEffect(() => {
    if(props.username){
      setName(props.username)
    }
  }, [props.username])

  const handleProfile = () => {
    if(props.handleProfile){
      props.handleProfile()
    } else {
      if(window.location.href.indexOf('localhost') == -1 || window.location.href.indexOf('demo') == -1){
        window.open("https://user.pajakku.com/main/account/user")
      } else {
        window.open("https://user.bdg.pajakku.com/main/account/user")
      }
    }
  }

  const handleLogout = () => {
    if(props.handleLogout){
      props.handleLogout()
    } else {
      props.modalStore.showConfirm({
        title: t.translate('mpk.column.logout'),
        children: t.translate('mpk.sentence.logoutMessage'),
        onSubmit: () => {
          props.authStore.logout();
        }
      })
    }
  }

  const handleChangePassword = () => {
    setShowDialog(true)
  }

  const handleApplicationInfo = () => {
    props.modalStore.showInfo({
      title: t.translate('mpk.sentence.applicationInfo'),
      children: (<AppInfo {...props}/>)
    })
  }

  menuItems = [
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-account"/>,
      children: t.translate('mpk.column.profile'),
      onClick: handleProfile
    },
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-logout"/>,
      children: t.translate('mpk.column.logout'),
      onClick: handleLogout
    },
    'separator',
    ...menuItems,
    menuItems.length > 0 ? 'separator' : null,
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-lock-question"/>,
      children: t.translate('mpk.column.changePassword'),
      onClick: handleChangePassword
    },
    'separator',
    ...menuItems,
    menuItems.length > 0 ? 'separator' : null,
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-information"/>,
      children: t.translate('mpk.column.info'),
      onClick: handleApplicationInfo
    },
    {
      leftAddon: <FontIcon iconClassName="mdi mdi-information"/>,
      children: "Lisensi",
      onClick: () => { window.location.href = '/#/license'}
    },
  ]

  return (
    <div>
      <Dialog
        visible={showDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setShowDialog(false)
        }}
        style={{ width: 600 }}
      >
        <DialogHeader>
          <DialogTitle><b>GANTI PASSWORD</b></DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={''}
            defaultData={[]}
            hintShowIcon={true}
            submitLabel='Simpan'
            definitions={[
              {
                inputType: DataForm.inputTypes.INPUT,
                label: 'Password Lama',
                key: 'currentPassword',
                type: 'password',
                validation: 'required'
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                label: 'Password Baru',
                key: 'newPassword',
                type: 'password',
                validation: 'required'
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                label: 'Konfirmasi Password Baru',
                key: 'confirmPassword',
                type: 'password',
                validation: 'required'
              }
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => { setShowDialog(false) }}>Tutup</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              var newPassword = values.newPassword
              var confirmPassword = values.confirmPassword
              try{
                if(confirmPassword != newPassword){
                  iziToast.warning({
                    title:'Warning',
                    message: 'Password Baru dan Konfirmasi Password Tidak Sama'
                  })
                  callback('', true, false)
                  return true
                }
                
                let res = await Service.changePassword(values)     
                callback('Password Berhasil Diubah', false)
                setShowDialog(false)
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch {
                setShowDialog(false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
      <div className="mpk-profile">
        <DropdownMenu
          id="mpk-profile-menu"
          items={menuItems}
          disableDropdownIcon
        >
          <Flex                                                                                                                                                                                                                                                                                                                                                                                 
            align={Flex.properties.align.CENTER}
          >
            <span className="mpk-margin-N margin-right">{name}</span>
            <Picture/>
          </Flex>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default inject('modalStore', 'authStore', 'envStore')(observer(Profile));
