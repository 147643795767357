import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import service from './PPh21Final.service'
import Utils from '../../../libs/custom/Utils'
import SlipGajiService from '../SlipGaji/SlipGaji.service'

const PPh21FinalDataForm = ({envStore, match, history, data, onSubmit}) => {
  const { inputTypes, definition } = FormWrapper

  // initial data
  useEffect(() => {
    async function initData() {

    }
    initData()
  }, ['match.params.id'])

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      showCommandbar={false}
      backTo={`/pph-menu/final`}
      baseId="mod-form-sample"
      title={t.translate('modules.pph21Final.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName='mdi mdi-arrow-right'
      defaultData={data.content}
      definitions={[
        // =====
        // =====
        // {
        //   inputType: inputTypes.DIVIDER,
        //   label: 'IDENTITAS PEMOTONG',
        // },
        // {
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   mask: '##.###.###.#-###.###',
        //   maskChar: '_',
        //   maskValue: 'string',
        //   label: 'NPWP Perusahaan',
        //   key: 'npwp',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        // },{
        //   inputType: inputTypes.INPUT,
        //   label: 'Kode Cabang',
        //   key: 'kode',
        //   width: '49%',
        // },{
        //   inputType: inputTypes.INPUT,
        //   label: 'Nama Perusahaan',
        //   key: 'nama',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        // },
        {
          inputType: inputTypes.DIVIDER,
          label: 'IDENTITAS PENERIMA PENGHASILAN',
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama Pegawai',
          key: 'slipGajiPegawai__nama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Alias',
          key: 'slipGajiPegawai__alias',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'required',
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'NPWP Pegawai',
          key: 'slipGajiPegawai__npwp',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'NIK',
          key: 'slipGajiPegawai__nik',
          type: 'text',
          width: '49%',
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'slipGajiPegawai__email',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          required: true,
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Telepon',
          key: 'slipGajiPegawai__telepon',
          type: 'text',
          width: '49%',
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Alamat',
          key: 'slipGajiPegawai__alamat',
          width: '100%',
          disabled: true
        },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'WP LN',
        //   key: 'wpLn',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   options: [
        //     { label: 'YA', value: 'YA' },
        //     { label: 'TIDAK', value: 'TIDAK' }
        //   ],
        //   disabled: true
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Domisili Wajib Pajak',
          key: 'slipGajiPegawai__domisiliWajibPajak',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          options: [{value: "DN", label: "DN"}, {value: "LN", label: "LN"}],
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Negara',
          key: 'slipGajiPegawai__negara',
          type: 'text',
          width: '49%',
          validation: 'required',
          disabled: true
        },{
          inputType: inputTypes.SELECT,
          label: 'PTKP',
          key: 'slipGajiPegawai__ptkp',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          style: {marginRight: '2%'},
          disabled: true,
          options: [
            { label: 'TK/0', value: 'TK/0' },
            { label: 'TK/1', value: 'TK/1' },
            { label: 'TK/2', value: 'TK/2' },
            { label: 'TK/3', value: 'TK/3' },
            { label: 'K/0',  value: 'K/0' },
            { label: 'K/1',  value: 'K/1' },
            { label: 'K/2',  value: 'K/2' },
            { label: 'K/3',  value: 'K/3' },
            { label: 'KI/0', value: 'KI/0' },
            { label: 'KI/1', value: 'KI/1' },
            { label: 'KI/2', value: 'KI/2' },
            { label: 'KI/3', value: 'KI/3' }
          ]
        },{
          inputType: inputTypes.INPUT,
          label: 'Agama',
          key: 'slipGajiPegawai__agama',
          type: 'text',
          width: '49%',
          disabled: true
        },{
          inputType: inputTypes.SELECT,
          label: 'Status Pindahan',
          key: 'slipGajiPegawai__gaji__statusPindahan',
          style: {marginRight: '2%'},
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          disabled:true,
          options: [
            { label: "Dipindahkan ke cabang lain",              value: "1"},
            { label: "Berhenti ke perusahaan lain/pensiun",     value: "2"},
            { label: "Berhenti meninggal/pindah ke LN/dari LN", value: "3"},
            { label: "SlipGaji baru",                           value: "4"},
            { label: "Pindahan",                                value: "5"}
          ]
        },{
          inputType: inputTypes.SELECT,
          label: 'Metode Pemotongan Pajak',
          key: 'slipGajiPegawai__gaji__metodePotongPajak',
          type: 'text',
          required: true,
          width: '49%',
          validation: 'required',
          disabled:true,
          options: [
            { label: "Nett",     value: "Nett"},
            { label: "Gross",    value: "Gross"},
            { label: "Gross Up", value: "Gross Up"}
          ]
        },{
          inputType: inputTypes.DATE,
          label: 'Tanggal Masuk',
          key: 'slipGajiPegawai__gaji__tanggalMasuk',
          width: '49%',
          style: {marginRight: '2%'},
          disabled:true,
        },{
          inputType: inputTypes.DATE,
          label: 'Tanggal Keluar',
          key: 'slipGajiPegawai__gaji__tanggalKeluar',
          width: '49%',
          disabled:true
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Golongan PNS',
          key: 'slipGajiPegawai__perhitunganPPh21Lainnya__golonganPNS',
          type: 'text',
          disabled: true,
          options: [
            { label: "I",     value: "I" },
            { label: "II",    value: "II" },
            { label: "III",   value: "III" },
            { label: "IV",    value: "IV" }
          ],
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Akumulasi Penghasilan Kena Pajak',
        //   key: 'akumulasi',
        //   type: 'text',
        //   width: '49%'
        // },{
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   label: '50% Akumulasi Penghasilan Kena Pajak (Rp)',
        //   key: 'akumulasi50persen',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   thousandSeparator: '.',
        //   decimalSeparator: ',',
        //   isNumericString: true,
        // },
        // {
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   label: 'Jumlah Penghasilan Bruto (Rp)',
        //   key: 'jmlPenghasilanBruto',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   thousandSeparator: '.',
        //   decimalSeparator: ',',
        //   isNumericString: true,
        // },
        //{
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   label: 'PPh Pasal 21 Yang Dipotong (Rp)',
        //   key: 'pph21Dipotong',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   thousandSeparator: '.',
        //   decimalSeparator: ',',
        //   isNumericString: true,
        // },
        // -----
      ]}
      onSubmit={onSubmit}
    />
  )
}

const Pph21FinalForm = ({envStore, match, history, data, onSubmit}) => {
  const { inputTypes, definition } = FormWrapper

  const [slipGajiList, setSlipGajiList] = useState([])

  useEffect(() => {
    async function initData() {
      await _getSlipGajiList()

    }
    initData()
  }, ['match.params.id'])

  async function _getSlipGajiList() {
    var res = await SlipGajiService.get()
    setSlipGajiList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      showCommandbar={false}
      backTo={`/pph-menu/final`}
      baseId="mod-form-sample"
      title={t.translate('modules.pph21Final.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onSubmit={onSubmit}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT,
          label: 'Kode KOP',
          key: 'kopInfo__kode',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama KOP',
          key: 'kopInfo__nama',
          type: 'text',
          width: '100%',
          validation: 'required',
          disabled: true
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Bruto (Rp)',
          key: 'bruto',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required|numeric',
          disabled: true
        },
        // {
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   label: 'Bruto Payroll (Rp)',
        //   key: 'brutoPayroll',
        //   type: 'text',
        //   width: '49%',
        //   thousandSeparator: '.',
        //   decimalSeparator: ',',
        //   isNumericString: true,
        //   validation: 'required|numeric',
        //   disabled: true
        // }
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Tarif (%)',
          key: 'tarif',
          type: 'text',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required|numeric',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh (Rp)',
          key: 'pph',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required|numeric',
          disabled: true
        },
        // {
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   label: 'PPh Payroll (Rp)',
        //   key: 'pphhPayroll',
        //   type: 'text',
        //   width: '49%',
        //   thousandSeparator: '.',
        //   decimalSeparator: ',',
        //   isNumericString: true,
        //   validation: 'required|numeric',
        //   disabled: true
        // },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Pemotongan PPh 21/26',
        //   key: 'pemotonganPPh2126Id',
        //   type: 'text',
        //   disabled: true
        // },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Slip Gaji',
        //   key: 'slipGajiPegawaiId',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   validation: 'required',
        //   options: slipGajiList,
        //   disabled: true
        // },
        {
          inputType: inputTypes.INPUT,
          label: 'Perhitungan Notes',
          key: 'perhitunganNotes',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },{
          inputType: inputTypes.INPUT,
          label: 'Perhitungan Status',
          key: 'perhitunganStatus',
          type: 'text',
          width: '49%',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Status Data',
          key: 'statusData',
          type: 'text',
          width: '100%',
          disabled: true
        }
      ]}
  />
  )
}

const Pph21FinalWrapper = ({envStore, match, history}) => {
  // initial data
  const [data, setData] = useState({loading: true, content: {}})
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  useEffect(() => {
    async function initData() {
      if(match.params.id == 'baru') {
        setData({loading: false, content: {}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data.slipGajiPegawai = Utils.formSerialize(res.data.slipGajiPegawai)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  return <TabsWrapper
    title={t.translate('modules.pph21Final.formTitle')}
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-details-sample"
    actions={[
      {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/pph-menu/final')}
    ]}
    tabs={[
      {
        label: 'Pemotong & Penerima Penghasilan',
        key: 'nested-form',
        render: <PPh21FinalDataForm data={data} match={match} history={history}
          onSubmit={async (d, callback) => {
            // var res = {}
            // if(match.params.id == 'baru') {
            //   res = await service.post(data)
            // } else {
            //   res = await service.put(data)
            // }

            // callback(t.translate('global.dataTelahDiSimpan'), false)
            // history.push('/onpremise/pegawai')

            setData({...data, ...d})
            callback('', false)
            setCurrentIndex(1)
          }}
        />
      },
      {
        label: 'Perhitungan PPh',
        key: 'data-perhitungan-pph',
        render: (
          <Pph21FinalForm data={data} match={match} history={history}
          onSubmit={async (d, callback) => {
            setData({...data, ...d})
            var res = {}
            try {
              var ndata = Utils.formNormalize({...data, ...d})
              if(match.params.id == 'baru') {
                res = await service.post(ndata)
              } else {
                res = await service.put(ndata)
              }
              callback(t.translate('global.dataTelahDiSimpan'), false)
              history.push('/pph-menu/final')
            } catch(e) {
              callback(e, true, false)
            }
          }}/>
        )
      },
    ]}
  />
}

export default Pph21FinalWrapper
