import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PemotonganPPh2126.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import moment from 'moment'
import iziToast from 'izitoast'

const PemotonganPPh2126Form = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [perusahaanList, setPerusahaanList] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getEntitasList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaan__id: sipStore.companyId,
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    var allEntitas = await EntitasService.http.get('/entitas-list', {size: 10000, 'perusahaanId.equals': sipStore.companyId})
    var existingMap = res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv;
      return pv;
    }, {});
    var entitasMap = allEntitas.data.reduce((pv, cv)=> {
      pv[cv.id] = cv;
      return pv;
    }, {});

    var list = [];

    res.data.forEach((d)=> {
      if(d.tipeEntitas == 'Cabang Pembantu') {
        if(!existingMap[d.parentId]) {
          list.push(entitasMap[d.parentId]);
        }
      } else {
        list.push(d)
      }
    })

    console.log(list, 'list', existingMap)

    setEntitasList(list.map(x => ({value: x.id, label: x.nama})))
    setEntitasMap(list.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      customRules={{
        tahunPajak: 'required|size:4'
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/pemotongan-pph2126`}
      baseId="mod-form-sample"
      title={t.translate('modules.pemotonganPPh2126.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaan__id',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },
        {
          inputType: inputTypes.REACT_SELECT,
          isMulti: true,
          label: 'Entitas',
          key: 'entitas',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required',
          disabled: isEdit
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Masa Pajak',
          key: 'masaPajak',
          type: 'text',
          style: {marginRight: '2%'},
          width: '49%',
          required: true,
          validation: 'required',
          options: [
            {value: '1', label: '1'},
            {value: '2', label: '2'},
            {value: '3', label: '3'},
            {value: '4', label: '4'},
            {value: '5', label: '5'},
            {value: '6', label: '6'},
            {value: '7', label: '7'},
            {value: '8', label: '8'},
            {value: '9', label: '9'},
            {value: '10', label: '10'},
            {value: '11', label: '11'},
            {value: '12', label: '12'}
          ]
        },{
          inputType: inputTypes.INPUT_MASK,
          label: 'Tahun Pajak',
          key: 'tahunPajak',
          width: '49%',
          mask: '****',
          alwaysShowMask: true,
          required: true,
          allowNegative: false
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Pemotongan',
          key: 'tanggalPemotongan',
          width: '100%',
          required: true,
          validation: 'required'
        }
        // {
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 A1',
        //   key: 'pph21A1Created',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Final',
        //   key: 'pph21FinalCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Masa',
        //   key: 'pph21MasaCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Tidak Final',
        //   key: 'pph21TidakFinalCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'Slip Gaji',
        //   key: 'slipGajiCreated',
        //   type: 'text',
        //   required: true,
        // },
        // -----
      ]}
      onSubmit={async (d, callback) => {
        var res = {}
        try {
          console.log(entitasMap, 'asd')
          var data = Utils.formNormalize({...d})
          data.tahunPajak = parseInt(data.tahunPajak)
          data.entitas = data.entitas.map(d => entitasMap[d.value])
          if(moment(data.tanggalPemotongan).format("M") > data.masaPajak){
            iziToast.warning({
              message: "Tanggal Pemotongan Tidak Boleh Melebihi Masa Pajak"
            })
            callback("", false, false)
            return
          }
          data.masaPajak = parseInt(data.masaPajak)

          if(match.params.id == 'baru') {
            res = await service.http.post('/pemotongan-pph2126s-bulk', data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/pemotongan-pph2126')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(PemotonganPPh2126Form))
