import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./PajakTerutang.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import { KOP_KHUSUS } from "../../../clientConstant";
import { Grid } from "@react-md/utils";
import { TextField } from "@react-md/form";
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-pemotongan-pph2126";

const PajakTerutangTable = ({
  className = "",
  showCommandbar = true,
  history,
  sipStore,
  navigationStore,
  match,
  modalStore,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});

  const [confirmKirimEppt, setConfirmKirimEppt] = useState(false);
  const [confirmKirimEpptData, setConfirmKirimEpptData] = useState({});

  function _closeConfirmKirimEppt() {
    setConfirmKirimEppt(false);
  }

  async function _submitConfirmKirimEppt(callback) {
    var r = await service.http.post(
      `/transaksi-pajak-terutangs/send-to-eppt-kop-2840401-to-2840411`,

      {
        masa: confirmKirimEpptData.masa,
        tahun: confirmKirimEpptData.tahun,
        perusahaanId: sipStore.companyId,
      }
    );
    toast.info(`Data ${confirmDeleteData.nama} sedang di kirim.`);
    callback();
    setConfirmKirimEppt(false);
  }

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={
          match.path === "/pph-unifikasi-menu/daftar-hitung"
            ? "Daftar Hitung"
            : match.path === "/pph-unifikasi-menu/data-rincian-transaksi"
            ? "Data Rincian Transaksi"
            : null
        }
        // title={"Data Hitung"}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Tanggal Transaksi",
            searchable: true,
            key: "tanggal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => {
              if (item.pos) {
                return item.pos.tanggal;
              } else if (item.pembelian) {
                return item.pembelian.tanggal;
              } else return item.transaksi ? item.transaksi.tanggal : "";
            },
          },
          {
            label: "Sumber",
            searchable: true,
            key: "sumber",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.pos ? "POS" : "Transaksi"),
          },
          {
            label: "No Referensi",
            searchable: true,
            key: "nomorReferensiHutang",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nomorReferensiHutang,
          },
          {
            label: "No Transaksi",
            searchable: true,
            key: "nomor",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => {
              if (item.pos) {
                return item.pos.nomor;
              } else if (item.pembelian) {
                return item.pembelian.nomor;
              } else return item.transaksi ? item.transaksi.nomor : "";
            },
          },
          {
            label: "Lawan Transaksi",
            searchable: true,
            key: "lawanTransaksi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => {
              if (item.pos) {
                return item.pos.posLawanTransaksi.nama;
              } else if (item.pembelian) {
                return item.pembelian.pembelianLawanTransaksi.nama;
              } else
                return item.transaksi
                  ? item.transaksi.transaksiLawanTransaksi.nama
                  : "";
            },
          },
          {
            label: "Status Approve",
            searchable: true,
            key: "approvalStatus",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.approvalStatus,
          },
          {
            label: "Status Sanding",
            searchable: true,
            key: "sandingStatus",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sandingStatus,
          },
          {
            label: "Status Pengiriman",
            searchable: true,
            key: "pengirimanStatus",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.pengirimanStatus,
          },
          {
            label: "Catatan Pengiriman",
            searchable: true,
            key: "pengirimanNotes",
            type: TableWrapper.dataTypes.STRING,
            render: (item) =>
              item.pengirimanNotes
                ? item.pengirimanNotes.replaceAll("&quot;", '"')
                : "",
          },
          {
            label: "Nomor Bukti Potong/Faktur Pajak",
            searchable: true,
            key: "nomorBpOrFaktur",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nomorBpOrFaktur,
          },
          {
            label: "KOP",
            searchable: true,
            key: "tahunPajak",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.kodeObjekPajak.kode,
          },
          {
            label: "PPh",
            searchable: true,
            key: "pph",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (Utils.numberFormat(item.pph))
          },
        ]}
        actions={[
          // new TableWrapper.action(
          //   "Add",
          //   "mdi mdi-plus",
          //   () => history.push("/onpremise/pemotongan-pph2126/baru"),
          //   true
          // ),
          new TableWrapper.action(
            "Kirim DOSS/DOPP",
            "mdi mdi-mail",
            () => {
              setConfirmKirimEppt(true);
            },
            match.params.type != "PPN"
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            match.params && match.params.type === "PPN"
              ? "Kirim ke Tarra Efaktur"
              : "Kirim ke ePPT",
            "mdi mdi-mail",
            (item) => {
              modalStore.showConfirm({
                children:
                  match.params && match.params.type === "PPN"
                    ? "Kirim data terpilih ke Tarra Efaktur?"
                    : "Kirim data terpilih ke ePPT?",
                onRequestClose: () => {
                  modalStore.closeConfirm();
                },
                onSubmit: async (callback) => {
                  try {
                    await service.http.post(
                      `/transaksi-pajak-terutangs/${item.id}/send-to-eppt`,
                      {}
                    );
                    toast.success(
                      `Cek pada kolom status, untuk memantau status pengiriman anda!`
                    );
                  } catch (e) {
                    var message = e.message;
                    if (e.response) {
                      message =
                        e.response.data.message + " " + e.response.data.detail;
                    }
                    toast.warning(message);
                  }

                  // TableWrapper.reload(_baseId);
                  callback();
                },
              });
            },
            (d) => {
              return d.kodeObjekPajak && !KOP_KHUSUS[d.kodeObjekPajak.kode];
            }
          ),
          new TableWrapper.action(
            "Kirim eBunifikasi",
            "mdi mdi-mail",
            (item) => {
              modalStore.showConfirm({
                children: "Kirim data terpilih ke eBunifikasi?",
                onRequestClose: () => {
                  modalStore.closeConfirm();
                },
                onSubmit: async (callback) => {
                  try {
                    await service.http.post(
                      `/transaksi-pajak-terutangs/send-to-eppt-ebunifikasi`,
                      { id: item.id }
                    );
                    toast.success(
                      `Cek pada kolom status, untuk memantau status pengiriman anda!`
                    );
                  } catch (e) {
                    var message = e.message;
                    if (e.response) {
                      message =
                        e.response.data.message + " " + e.response.data.detail;
                    }
                    toast.warning(message);
                  }

                  // TableWrapper.reload(_baseId);
                  callback();
                },
              });
            },
            (d) => {
              return (
                d.kodeObjekPajak &&
                !KOP_KHUSUS[d.kodeObjekPajak.kode] &&
                (d.kodeObjekPajak.tipePajak.kode === "Pasal 23/26" ||
                  d.kodeObjekPajak.tipePajak.kode === "Pasal 4a2" ||
                  d.kodeObjekPajak.tipePajak.kode === "Pasal 22" ||
                  d.kodeObjekPajak.tipePajak.kode === "Pasal 15")
              );
            }
          ),
        ]}
        onFetchData={async (query) => {
          if (!query) query = {};
          console.log(navigationStore, match, "match, navigationStore");
          if (match.path === "/pph-unifikasi-menu/daftar-hitung") {
            var periode = JSON.parse(localStorage.getItem("sip-unifikasi-pph"));
            console.log("periode", periode);
            query["perusahaanId.equals"] = sipStore.companyId;
            // query["entitasId.equals"] = sipStore.
            query["entitasId.equals"] = periode.entitas.id;
            query["tanggalTransaksi.greaterThanOrEqual"] = moment(
              `${periode.tahunPajak}-${periode.masaPajak}-01`
            ).format("YYYY-MM-DD");
            query["tanggalTransaksi.lessThanOrEqual"] = moment(
              `${periode.tahunPajak}-${periode.masaPajak}-01`
            )
              .endOf("month")
              .format("YYYY-MM-DD");
            query["setorSendiri.equals"] = "false";
          } else if (match.path === "/pph-unifikasi-menu/data-rincian-transaksi") {
            var periode = JSON.parse(localStorage.getItem("sip-unifikasi-pph"));
            console.log("periode", periode);
            query["perusahaanId.equals"] = sipStore.companyId;
            // query["entitasId.equals"] = sipStore.
            query["entitasId.equals"] = periode.entitas.id;
            query["tanggalTransaksi.greaterThanOrEqual"] = moment(
              `${periode.tahunPajak}-${periode.masaPajak}-01`
            ).format("YYYY-MM-DD");
            query["tanggalTransaksi.lessThanOrEqual"] = moment(
              `${periode.tahunPajak}-${periode.masaPajak}-01`
            )
              .endOf("month")
              .format("YYYY-MM-DD");
            query["setorSendiri.equals"] = "true";
          } else {
            if (sipStore.companyId) {
              query["tipeBebanPajak.equals"] =
                tipeBebanPajakMapper[match.params.tipeBebanPajak];
              query["tipePajakKode.equals"] = match.params.type.replace(
                "__",
                "/"
              );
              query["perusahaanId.equals"] = sipStore.companyId;
            }
            if (sipStore.periodeId) {
              query["periodeId.equals"] = sipStore.periodeId;
            }
          }

          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>

      <Modal.Confirm
        title="Kirim Data Pajak Terutang"
        submitLabel="Submit"
        visible={confirmKirimEppt}
        onRequestClose={_closeConfirmKirimEppt}
        onSubmit={_submitConfirmKirimEppt}
      >
        <div className="mpk-full full-width input mpk-margin-N margin-bottom flex-none ">
          <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none ">
            <TextField
              label="Masa"
              value={confirmKirimEpptData.masa}
              onChange={(e, d) => {
                setConfirmKirimEpptData((draft) => {
                  return {
                    ...draft,
                    masa: e.target.value,
                  };
                });
              }}
            />
          </div>
          <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none ">
            <TextField
              label="Tahun"
              value={confirmKirimEpptData.tahun}
              onChange={(e, d) => {
                setConfirmKirimEpptData((draft) => {
                  return {
                    ...draft,
                    tahun: e.target.value,
                  };
                });
              }}
            />
          </div>
        </div>
      </Modal.Confirm>
    </>
  );
};

const tipeBebanPajakMapper = {
  pajak_dibayar_dimuka: "Pajak Dibayar Dimuka",
  pajak_terutang: "Pajak Terutang",
};

export default inject(
  "modalStore",
  "sipStore",
  "navigationStore"
)(observer(PajakTerutangTable));
