import React from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { DataForm } from '../../libs/react-mpk/components'

const NestedForm = () => {
  return (
    <FormWrapper
      baseId="mod-nested-form"
      className="mpk-padding-N padding-bottom"
      showCommandbar={false}
      defaultData={{}}
      definitions={[
        {
          inputType: FormWrapper.inputTypes.DIVIDER,
          label: t.translate('column.profile')
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate('column.applicationName'),
          key: 'name'
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: t.translate('column.code'),
          key: 'code'
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'Resource',
          key: 'resource',
          hintMessage: 'Daftar resource pada aplikasi',
          defaultData: {
            name: '',
            method: 'get',
            dataType: 'string'
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: 'Nama',
              key: 'name',
              theme: 'filled',
              required: true
            },
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Method',
              key: 'method',
              theme: 'filled',
              options: [
                {label: 'GET', value: 'get'},
                {label: 'POST', value: 'post'}
              ]
            }
          ]
        }
      ]}
      onBeforeChange={(key, value, parentKey) => {
        if(key === 'code') value = value.toUpperCase().replace(/ /g, '')
        return value
      }}
       onSubmit={(data, callback) => {
        console.log(data);
        setTimeout(callback, 2000)
      }}
    />
  )
}

export default NestedForm
