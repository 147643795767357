import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import DashboardService from "./DashboardService";
import { DataForm } from "../../../libs/react-mpk/components";
import moment from "moment";

import {
  SortOrder,
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Caption,
} from "@react-md/table";
import { FontIcon } from "@react-md/icon";
import {
  AppBar,
  AppBarNav,
  AppBarTitle,
  AppBarAction,
} from "@react-md/app-bar";
import { useState } from "react";
import { Line, Circle } from "rc-progress";
import Utils from "../../../libs/custom/Utils";
import { Avatar, Card, CardHeader, CardSubtitle, CardTitle } from "react-md";

function SkemaRemunerasiDashboardView({ sipStore }) {
  const [data, setData] = useState({
    loading: true,
    content: [],
    sortKey: "totalPph",
    sortOrder: "ascending",
    total: 0,
    param: {},
  });

  const [toggleFilter, setToggleFilter] = useState(false);

  useEffect(() => {
    initData("nama", {
      perusahaanId: sipStore.companyId,
      masa: moment().month(),
    });
  }, []);

  async function initData(sortKey, param) {
    var d = data.content;
    if (param) {
      var res = await DashboardService.skemaRemunerasi(
        sipStore.periodeId,
        param.masa ? param.masa : moment().month() + 1
      );
      d = res.data;
    }

    setData((prevState) => {
      const prevSortKey = prevState.sortKey;
      const prevSortOrder = prevState.sortOrder;

      let sortOrder = prevSortOrder;
      if (sortKey) {
        if (sortKey === prevSortKey) {
          // it's the same column, so toggle the sort order
          sortOrder =
            prevSortOrder === "ascending" ? "descending" : "ascending";
        } else {
          // it's a new column to sort by, so default to ascending for the name column
          // but descending for all the rest.
          sortOrder = sortKey === "name" ? "ascending" : "descending";
        }
      }

      if (!sortKey) sortKey = prevSortKey;

      return {
        content: d.sort((a, b) => {
          const aValue = a[sortKey];
          const bValue = b[sortKey];
          const value =
            typeof aValue === "number"
              ? aValue - bValue
              : "" + aValue.localeCompare(bValue);
          return value * (sortOrder === "ascending" ? 1 : -1);
        }),
        sortKey,
        sortOrder,
        param: {
          ...prevState.param,
          ...param,
        },
        loading: false,
        total: d.reduce((pv, cv) => {
          pv = pv + cv.totalPph;
          return pv;
        }, 0),
      };
    });
  }

  const { content, sortKey, sortOrder } = data;

  return (
    <Card
      raiseable
      className="mpk-form-wrapper mpk-paper  padding-all mpk-animation slide-in"
      style={{
        width: "100%",
        maxWidth: 1200,
        margin: "0px auto",
        // marginRight: "2%",
        display: "inline-block",
      }}
    >
      <CardHeader
        beforeChildren={
          <Avatar>
            <FontIcon iconClassName="mdi mdi-calendar-text" />
          </Avatar>
        }
        afterChildren={
          <AppBarAction
            first
            aria-label="Actions"
            onClick={() => {
              setToggleFilter(!toggleFilter);
            }}
          >
            <FontIcon iconClassName="mdi mdi-filter" />
          </AppBarAction>
        }
      >
        <CardTitle>SKEMA REMUNERASI</CardTitle>
        {/* <CardSubtitle>Daftar pembelian berdasarkan COA</CardSubtitle> */}
      </CardHeader>
      <div className="mpk-padding-N" style={{ height: 600, overflow: "auto" }}>
        {toggleFilter && (
          <DataForm
            baseId={"pegawai-import"}
            // asDialog={true}
            defaultData={data.param}
            definitions={[
              {
                inputType: DataForm.inputTypes.INPUT,
                label: "Masa",
                key: "masa",
                // theme: "filled",
                validation: "required",
                style: { width: "100%" },
              },
            ]}
            // visible={uploadModal}
            submitLabel="Cari"
            onSubmit={async (d, callback) => {
              try {
                console.log(d, "dd");
                await initData(null, {
                  ...d,
                  perusahaanId: sipStore.companyId,
                });
                callback();
              } catch (e) {
                console.log(e.response);
                callback(e.response.data.message);
              }
            }}
          />
        )}
        <TableContainer>
          <Table fullWidth>
            <Caption>
              {data.param.from} - {data.param.to} {data.param.type}
            </Caption>
            <TableHeader>
              <TableRow>
                <TableCell
                  key="entitasNpwp"
                  aria-sort={"entitasNpwp" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("entitasNpwp")}
                >
                  NPWP ENTITAS
                </TableCell>
                <TableCell
                  key="entitasNama"
                  aria-sort={"entitasNama" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("entitasNama")}
                >
                  NAMA ENTITAS
                </TableCell>
                <TableCell
                  key="nama"
                  aria-sort={"nama" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nama")}
                >
                  NAMA
                </TableCell>
                <TableCell
                  key="nip"
                  aria-sort={"nip" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nip")}
                >
                  NIP
                </TableCell>
                <TableCell
                  key="nik"
                  aria-sort={"nik" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nik")}
                >
                  NIK
                </TableCell>
                <TableCell
                  key="npwp"
                  aria-sort={"npwp" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("npwp")}
                >
                  NPWP
                </TableCell>
                <TableCell
                  key="takeHomePay"
                  aria-sort={"takeHomePay" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("takeHomePay")}
                >
                  THP
                </TableCell>
                <TableCell
                  key="levelPegawai"
                  aria-sort={"levelPegawai" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("levelPegawai")}
                >
                  LEVEL PEGAWAI
                </TableCell>
                <TableCell
                  key="minGaji"
                  aria-sort={"minGaji" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("minGaji")}
                >
                  MIN GAJI
                </TableCell>
                <TableCell
                  key="maxGaji"
                  aria-sort={"maxGaji" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("maxGaji")}
                >
                  MAX GAJI
                </TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.content.map((v, i) => {
                var c = colors[getRandomInt(34, 0)];
                return (
                  <TableRow>
                    <TableCell>
                      <div style={{}}>{v.entitasNpwp}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.entitasNama}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.nama}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.nip}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.nik}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.npwp}</div>
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.takeHomePay)}
                    </TableCell>

                    <TableCell>
                      <div style={{}}>{v.levelPegawai}</div>
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.minGaji)}
                    </TableCell>

                    <TableCell style={{ textAlign: "right" }}>
                      {Utils.numberFormat(v.maxGaji)}
                    </TableCell>


                    <TableCell>
                      {v.status}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
}

export default inject(
  "sipStore",
  "authStore"
)(observer(SkemaRemunerasiDashboardView));

const colors = [
  "#ef5350",
  "#ff1744",
  "#ec407a",
  "#f50057",
  "#ab47bc",
  "#d500f9",
  "#7e57c2",
  "#651fff",
  "#5c6bc0",
  "#3d5afe",
  "#42a5f5",
  "#2979ff",
  "#29b6f6",
  "#00b0ff",
  "#26c6da",
  "#00e5ff",
  "#26a69a",
  "#1de9b6",
  "#66bb6a",
  "#00e676",
  "#9ccc65",
  "#76ff03",
  "#d4e157",
  "#c6ff00",
  "#ffee58",
  "#ffea00",
  "#ffca28",
  "#ffc400",
  "#ffa726",
  "#ff9100",
  "#ff7043",
  "#ff3d00",
  "#8d6e63",
  "#bdbdbd",
  "#78909c",
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
