import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './StatusPajakPembeli.service'
import TipeStatusPajakService from '../tipeStatusPajak/TipeStatusPajak.service'
import PengenaanPajakService from '../pengenaanPajak/PengenaanPajak.service'

const StatusPajakPembeliForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [tipeStatusPajaks, setTipeStatusPajaks] = useState([])
  const [pengenaanPajaks, setPengenaanPajaks] = useState([])

  const getTipeStatusPajak = async ()=> {
    var tipeStatusPajakOP = []
    const tipeStatusPajak = await TipeStatusPajakService.get()
    tipeStatusPajak.data.map((d)=> { tipeStatusPajakOP.push({ label: d.nama, value: d.id }) })
    setTipeStatusPajaks(tipeStatusPajakOP)
  }

  const getPengenaanPajak = async ()=> {
    var pengenaanPajakOP = []
    const pengenaanPajak = await PengenaanPajakService.get()
    pengenaanPajak.data.map((d)=> { pengenaanPajakOP.push({ label: d.nama, value: d.id }) })
    setPengenaanPajaks(pengenaanPajakOP)
  }

  // initial data
  useEffect(() => {
    async function initData() {
      if(match.params.id == 'baru') {
        setData({loading: false, content: {}})
        return true
      }
      const res = await service.getOne(match.params.id)
      setData({loading: false, content:res.data})
    }
    getTipeStatusPajak()
    getPengenaanPajak()
    initData()
  }, ['match.params.id'])

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/status-pajak-pembeli`}
      baseId="mod-form-sample"
      title={t.translate('modules.statusPajakPembeli.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT,
          label: 'Pengenaan Pajak ID',
          key: 'pengenaanPajakId',
          type: 'text',
          required: true,
          options: pengenaanPajaks
        },{
          inputType: inputTypes.INPUT,
          label: 'Status',
          key: 'status',
          type: 'text',
          required: true,
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'Tidak',
          key: 'tidak',
          type: 'boolean',
          required: true,
        },{
          inputType: inputTypes.SELECT,
          label: 'Tipe Status Pajak ID',
          key: 'tipeStatusPajakId',
          type: 'text',
          required: true,
          option: tipeStatusPajaks
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/status-pajak-pembeli')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(StatusPajakPembeliForm))
