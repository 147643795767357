export default {
  appInfo: {
    name: 'sip',
    version: '1.0.11',
    appname: 'sip',
    subname: 'Sistem Informasi Perpajakan (v1.0.11)'
  },
  timestamp: '2021-01-13T04:38:25.948Z',
  theme: 'ad2700c315f98a65120c4a8503860c1b',
  apiGateway: {
    // host: 'http://192.168.3.113:8091',
    host: localStorage.host?localStorage.host:'',
    clientId: '8qKOfRjjsYIyzZHmxY8Va4PxpZw5XTHGMnqczkEz',
    state: 'mpk-starter-spa-product',
    baseUrl: '/api/v1'
  },
  sso: {
    host: 'https://api.bdg.pajakku.com',
    sso: 'http://sso.bdg.pajakku.com'
  },
  appConsole: {
    website: {
      label: 'Website Pajakku',
      url: 'https://portal.bdg.pajakku.com'
    },
    userAccount: {
      label: 'User Account Console',
      url: 'https://user.bdg.pajakku.com'
    },
    developer: {
      label: 'Developer Console',
      url: 'https://developer.bdg.pajakku.com'
    }
  },
  widgetInterface: {
    kbs: {
      url: 'https://kbs.pajakku.com/static/libs/kbs-widget.min.js'
    }
  },
  widgetGateway: {
    host: 'https://widget.bdg.pajakku.com',
    ws: 'wss://widget.bdg.pajakku.com'
  },
  kbs: [
    {
      label: 'kbs.manualGuide',
      type: 'documentation',
      code: 'DOC-STARTER-SPA',
      translate: true
    }
  ],
  applicationType: 'onpremise',
  locale: {
    code: 'id',
    options: [
      {
        label: 'Bahasa',
        value: 'id'
      },
      {
        label: 'English',
        value: 'en'
      }
    ]
  },
  portal: {
    channelName: '',
    forumUrl: 'https://pajakku.com/forum-categories'
  }
}
