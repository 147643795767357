import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./KodeObjekPajak.service";
import TipePajakListService from "../tipePajak/TipePajak.service";
import TipeBupotListService from "../tipeBupot/TipeBupot.service";
import KategoriPPh25Service from "../kategoriPph25/KategoriPph25.service";
import Utils from "../../../libs/custom/Utils";
//document.getElementById(`mpk-data-form-id-form-trigger`).click()
const KodeObjekPajakForm = ({ envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper;
  const [data, setData] = useState({ loading: true, content: {} });
  const [latestData, setLatestData] = useState({ });
  const [isEdit, setIsEdit] = useState(false);
  const [tipePajakList, setTipePajakList] = useState([]);
  const [tipeBupotList, setTipeBupotList] = useState([]);
  const [kategoriPPh25List, setKategoriPPh25] = useState([]);

  const kategoriEntitasArrayPenjual = [
    "Perencanaan Konstruksi (Dengan Kualifikasi Usaha)",
    "Perencanaan Konstruksi (Tanpa Kualifikasi Usaha)",
    "Pelaksanaan Konstruksi (Kualifikasi Usaha Kecil)",
    "Pelaksanaan Konstruksi (Kualifikasi Usaha Menengah dan Besar)",
    "Pelaksanaan Konstruksi (Tanpa Kualifikasi Usaha)",
    "Pengawasan Konstruksi (Dengan Kualifikasi Usaha)",
    "Pengawasan Konstruksi (Tanpa Kualifikasi Usaha)",
    "Pelayaran / Penerbangan",
    "Produsen Semen",
    "Produsen Baja",
    "Produsen Otomotif",
    "Produsen Farmasi",
    "Produsen Kertas",
    "ATPM, APM, dan importir umum kendaraan bermotor",
    "Pemegang Izin Usaha Pertambangan",
    "Produsen emas batangan",
    "Produsen /importir BBM, BBG, dan pelumas (Pertamina)",
    "Produsen /importir BBM, BBG, dan pelumas (selain Pertamina)",
    "Wajib Pajak yang terikat dalam perjanjian kerjasama pengusahaan pertambangan dan Kontrak Karya",
  ];

  const kategoriEntitasArrayPembeli = [
    // "Pembeli / Pihak yang menerima barang atau Jasa / Pihak y  ang memberi uang",
    "Bank Yang Didirikan Atau Bertempat Kedudukan Di Indonesia Atau Cabang Bank Luar Negeri Di Indonesia.",
    "Penyelenggara bursa efek",
    "BUMN/Badan Tertentu",
    "Instansi Pemerintah",
    "Distributor semen di dalam negeri",
    "Distributor baja di dalam negeri",
    "Distributor otomotif di dalam negeri",
    "Distributor farmasi di dalam negeri",
    "Distributor kertas di dalam negeri",
    "Badan usaha industri atau eksportir yang bergerak dalam sektor kehutanan, perkebunan, pertanian, peternakan, dan perikanan",
    "SPBU/Agen/Penyalur Pertamina",
    "SPBU/Agen/Penyalur/Lainnya non Pertamina",
    "Kawasan Bebas",
    "Tempat Penimbunan Berikut",
    "Kontraktor Perjanjian Karya Pengusahaan Pertambangan Batubara Generasi I",
    "Kontraktor Kerja Sama Migas yang mengikuti ketentuan Peraturan Pemerintah Nomor 27 Tahun 2017",
  ];

  const kategoriEntitasPPN = [
    {value: '01', label: 'Produsen/Importir BBM, BBG, dan pelumas (Pertamina)'},
    {value: '02', label: 'Produsen/Importir BBM, BBG, dan pelumas (selain Pertamina)'},
    {value: '03', label: 'Wajib Pajak yang terikat dalam perjanjian kerjasama pengusahaan pertambangan dan Kontrak Karya'},
    {value: '04', label: 'Kawasan Bebas'},
    {value: '05', label: 'Tempat Penimbunan Berikut'},
    {value: '06', label: 'Kontraktor Perjanjian Karya Pengusahaan Pertambangan Batubara Generasi 1'},
    {value: '07', label: 'Kontraktor Kerja Sama Migas yang mengikuti ketentuan Peraturan Pemerintah Nomor 27 Tahun 2017'}
  ]

  const formSubmitFunction = () => {};

  // initial data
  useEffect(() => {
    async function initData() {
      await _getTipePajakList();
      await _getTipeBupotList();
      await _getKategoriPPh25();

      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            pengenaanPajak__nilaiTransaksiMinimal: 0,
            pengenaanPajak__nilaiTransaksiMaksimal: 0,
            pengenaanPajak__hasNilaiTransaksiMaksimal: false,
            pengenaanPajak__hasNilaiTransaksiMinimal: false,
            pengenaanPajak__pajakMengurangiTagihan: false,
            pengenaanPajak__pemungutanPembeli: false,
            pengenaanPajak__pemungutanPenjual: false,
            tarifs: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      setIsEdit(true);
      res.data = Utils.formSerialize(res.data);
      setLatestData(res.data)
      if (!res.data.pengenaanPajak__nilaiTransaksiMaksimal)
        res.data.pengenaanPajak__nilaiTransaksiMaksimal = 0;
      if (!res.data.pengenaanPajak__nilaiTransaksiMinimal)
        res.data.pengenaanPajak__nilaiTransaksiMinimal = 0;
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  async function _getTipePajakList() {
    var res = await TipePajakListService.get();
    setTipePajakList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getTipeBupotList() {
    var res = await TipeBupotListService.get();
    setTipeBupotList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getKategoriPPh25() {
    var res = await KategoriPPh25Service.get({ size: 100000 });
    setKategoriPPh25(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  return (
    <FormWrapper
      noValidate
      customRules={{
        // tahunMulaiBerlaku: 'required|size:4',
        // tahunAkhirBerlaku: 'required|size:4'
      }}
      baseId="kop-form"
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={`/onpremise/kode-objek-pajak`}
      baseId="mod-form-sample"
      title={t.translate("modules.kodeObjekPajak.formTitle")}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      // submitStyle={{display: 'none'}}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        setLatestData(newObj)
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "INFORMASI UMUM",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Kode KOP",
          key: "kode",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nama KOP",
          key: "nama",
          type: "text",
          width: "49%",
          validation: "required",
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Tipe Pajak",
          key: "tipePajak__id",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          options: tipePajakList,
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kategori Pendapatan",
          key: "kategoriPendapatan",
          type: "text",
          width: "49%",
          // style: { marginRight: "2%" },
          validation: "required",
          options: [
            { value: "Final", label: "Final" },
            { value: "Tidak Final", label: "Tidak Final" },
          ],
          // disabled: isEdit,
        },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: "Tipe Bupot",
        //   key: "tipeBupotId",
        //   type: "text",
        //   width: "49%",
        //   validation: "required",
        //   options: tipeBupotList,
        //   // disabled: isEdit,
        // },
        {
          inputType: inputTypes.INPUT,
          label: "Keterangan",
          key: "keterangan",
          width: "49%",
          style: { marginRight: "2%" },
          type: "text",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Dasar Hukum",
          key: "dasarHukum",
          width: "49%",
          type: "text",
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "MAPPING DATA - UNTUK SPT TAHUNAN",
          show: (d) =>
            d.tipePajak__id == "00000002-5a04-21eb-bb01-132132205609" ||
            d.tipePajak__id == "00000003-6448-21eb-9001-7b47d5cdb54b",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Kode MAP",
          key: "kodeMap",
          width: "49%",
          style: { marginRight: "2%" },
          type: "text",
          show: (d) =>
            d.tipePajak__id == "00000002-5a04-21eb-bb01-132132205609" ||
            d.tipePajak__id == "00000003-6448-21eb-9001-7b47d5cdb54b",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Kode Jenis Penghasilan",
          key: "kjs",
          width: "49%",
          type: "text",
          show: (d) =>
            d.tipePajak__id == "00000002-5a04-21eb-bb01-132132205609" ||
            d.tipePajak__id == "00000003-6448-21eb-9001-7b47d5cdb54b",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Jenis Penghasilan",
          key: "jenisPenghasilan",
          type: "text",
          width: "100%",
          // style: { marginRight: "2%" },
          options: JENIS_PENGHASILAN_MAP[latestData.tipePajak__id]?JENIS_PENGHASILAN_MAP[latestData.tipePajak__id]:[],
          show: (d) =>
            d.tipePajak__id == "00000002-5a04-21eb-bb01-132132205609" ||
            d.tipePajak__id == "00000003-6448-21eb-9001-7b47d5cdb54b",
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "TERUTANG SAAT KONDISI - KONDISI DIBAWAH INI TERPENUHI",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Terdapat minimal nominal transaksi",
          key: "pengenaanPajak__hasNilaiTransaksiMinimal",
          style: { marginRight: "2%" },
          validation: "boolean",
          width: "49%",
          type: "text",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Masukan minimal nominal transaksi  (Rp)",
          key: "pengenaanPajak__nilaiTransaksiMinimal",
          width: "100%",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          show: (d) => d.pengenaanPajak__hasNilaiTransaksiMinimal,
          validation: [
            {
              required_unless: [
                "pengenaanPajak__hasNilaiTransaksiMinimal",
                true,
              ],
            },
          ],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609' && d.pengenaanPajak__hasNilaiTransaksiMinimal,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Terdapat maksimal nominal transaksi",
          key: "pengenaanPajak__hasNilaiTransaksiMaksimal",
          width: "49%",
          type: "text",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Masukan maksimal nominal transaksi (Rp)",
          key: "pengenaanPajak__nilaiTransaksiMaksimal",
          width: "100%",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          show: (d) => d.pengenaanPajak__hasNilaiTransaksiMaksimal,
          validation: [
            {
              required_unless: [
                "pengenaanPajak__hasNilaiTransaksiMaksimal",
                true,
              ],
            },
          ],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609' && d.pengenaanPajak__hasNilaiTransaksiMaksimal,
        },
        {
          inputType: inputTypes.DIVIDER,
          key: "div_1",
          label: "KATEGORI PENJUAL / PIHAK YANG MENYERAHKAN BARANG ATAU JASA",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Jenis wajib pajak",
          key: "pengenaanPajak__tipePenjual",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          required: true,
          options: [
            { value: "Badan", label: "Badan" },
            { value: "Orang Pribadi", label: "Orang Pribadi" },
            {
              value: "Badan dan Orang Pribadi",
              label: "Badan dan Orang Pribadi",
            },
          ],
          validation: "required",
          // validation: [{required_unless: ['tipePajak__id', '00000001-5a04-21eb-ba01-132132205609']}],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Negara asal wajib pajak",
          key: "pengenaanPajak__domisiliPenjual",
          type: "text",
          width: "49%",
          required: true,
          options: [
            { value: "DN", label: "Indonesia" },
            { value: "LN", label: "Selain Indonesia" },
          ],
          validation: "required",
          // validation: [{required_unless: ['tipePajak__id', '00000001-5a04-21eb-ba01-132132205609']}],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label:
            "Pemungut/Pemotongan oleh/ke penjual dengan kategori tertentu?",
          key: "pengenaanPajak__pemungutanPenjual",
          width: "100%",
          type: "text",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kategori Penjual / Pihak yang menyerahkan barang dan jasa",
          key: "pengenaanPajak__kategoriEntitasPPh22Penjual",
          width: "100%",
          // required: true,
          options: kategoriEntitasArrayPenjual.map((x) => ({
            value: x,
            label: x,
          })),
          // style: {marginRight: '2%'}
          show: (d) => d.tipePajak__id != '00000000-5a04-21eb-b901-132132205609' && d.pengenaanPajak__pemungutanPenjual,
          validation: [
            { required_if: ["pengenaanPajak__pemungutanPenjual", true] },
          ],
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kategori Penjual / Pihak yang menyerahkan barang dan jasa",
          key: "pengenaanPajak__kategoriEntitasPPh22Penjual",
          width: "100%",
          // required: true,
          options: kategoriEntitasPPN,
          // style: {marginRight: '2%'}
          show: (d) => d.tipePajak__id === '00000000-5a04-21eb-b901-132132205609' && d.pengenaanPajak__pemungutanPenjual,
          validation: [
            { required_if: ["pengenaanPajak__pemungutanPenjual", true] },
          ],
        },
        {
          inputType: inputTypes.DIVIDER,
          key: "div_2",
          label: "KATEGORI PEMBELI / PIHAK YANG MENERIMA BARANG ATAU JASA",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Jenis wajib pajak",
          key: "pengenaanPajak__tipePembeli",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          required: true,
          options: [
            { value: "Badan", label: "Badan" },
            { value: "Orang Pribadi", label: "Orang Pribadi" },
            {
              value: "Badan dan Orang Pribadi",
              label: "Badan dan Orang Pribadi",
            },
          ],
          validation: "required",
          // validation: [{required_unless: ['tipePajak__id', '00000001-5a04-21eb-ba01-132132205609']}],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Negara asal wajib pajak",
          key: "pengenaanPajak__domisiliPembeli",
          type: "text",
          width: "49%",
          required: true,
          options: [
            { value: "DN", label: "Indonesia" },
            { value: "LN", label: "Selain Indonesia" },
          ],
          validation: "required",
          // validation: [{required_unless: ['tipePajak__id', '00000001-5a04-21eb-ba01-132132205609']}],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label:
            "Pemungut/Pemotongan oleh/ke pembeli dengan kategori tertentu?",
          key: "pengenaanPajak__pemungutanPembeli",
          width: "100%",
          type: "text",
          validation: "required",
          // validation: [{required_unless: ['tipePajak__id', '00000001-5a04-21eb-ba01-132132205609']}],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kategori Pembeli / Pihak yang menerima barang dan jasa",
          key: "pengenaanPajak__kategoriEntitasPPh22Pembeli",
          width: "100%",
          // required: true,
          options: kategoriEntitasArrayPembeli.map((x) => ({
            value: x,
            label: x,
          })),
          // style: {marginRight: '2%'}
          show: (d) => d.tipePajak__id != '00000000-5a04-21eb-b901-132132205609' && d.pengenaanPajak__pemungutanPembeli,
          validation: [
            { required_if: ["pengenaanPajak__pemungutanPembeli", true] },
          ],
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kategori Pembeli / Pihak yang menerima barang dan jasa",
          key: "pengenaanPajak__kategoriEntitasPPh22Pembeli",
          width: "100%",
          // required: true,
          options: kategoriEntitasPPN,
          // style: {marginRight: '2%'}
          show: (d) => d.tipePajak__id === '00000000-5a04-21eb-b901-132132205609' && d.pengenaanPajak__pemungutanPembeli,
          validation: [
            { required_if: ["pengenaanPajak__pemungutanPembeli", true] },
          ],
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "KEWAJIBAN PEMOTONGAN / PEMUNGUTAN",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.SELECT,
          label: "Pemungut/Pemotongan/Penyetoran sendiri dilakukan oleh",
          key: "pengenaanPajak__pemotonganDilakukanOleh",
          width: "100%",
          options: [
            {
              value: "Penjual",
              label: "Penjual/Pihak yang menyerahkan barang atau jasa",
            },
            {
              value: "Pembeli",
              label: "Pembeli/Pihak yang menerima barang atau jasa",
            },
          ],
          validation: "required",
          // validation: [{required_unless: ['tipePajak__id', '00000001-5a04-21eb-ba01-132132205609']}],
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Perhitungan pajak mengurangi tagihan?",
          key: "pengenaanPajak__pajakMengurangiTagihan",
          width: "49%",
          type: "text",
          // show: (d) => d.tipePajak__id != '00000001-5a04-21eb-ba01-132132205609'
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Tarif",
          key: "tarifs",
          hintMessage: "Daftar Tarif",
          show: (d) =>
            d.tipePajak__id != "00000001-5a04-21eb-ba01-132132205609",
          layout: "table",
          style: { width: 1500 },
          defaultData: {
            tarifPajak: "0",
            keterangan: "-",
            tarifTanpaNpwp: "0",
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.INPUT_MASK,
              label: "Mulai Berlaku (Tahun)",
              key: "tahunMulaiBerlaku",
              theme: "filled",
              style: { width: 200 },
              mask: '****',
              alwaysShowMask: true,
              allowNegative: false
            },
            {
              inputType: FormWrapper.inputTypes.INPUT_MASK,
              label: "Akhir Berlaku (Tahun)",
              key: "tahunAkhirBerlaku",
              theme: "filled",
              style: { width: 200 },
              mask: '****',
              alwaysShowMask: true,
              allowNegative: false
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Tarif Pajak (%)",
              key: "tarifPajak",
              theme: "filled",
              type: "number",
              validation: "required",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              style: { width: 100 },
            },
            {
              inputType: FormWrapper.inputTypes.CHECKBOX,
              label: "Kenaikan tarif jika tidak memiliki NPWP?",
              key: "tarifNaikTanpaNpwp",
              theme: "filled",
              style: { width: 350 },
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Tarif Pajak (NoN NPWP) (%)",
              key: "tarifTanpaNpwp",
              theme: "filled",
              type: "number",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              style: { width: 200 },
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              theme: "filled",
              type: "text",
              style: { width: 200 },
            },
          ],
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        if (
          data.tipePajak__id != "00000001-5a04-21eb-ba01-132132205609" &&
          data.tarifs <= 0
        ) {
          // izitoast.warning({title:'Warning', message: 'Daftar susunan kepengurusan harus memiliki(menceklis) Pemimpin Perusahaan,Pejabat Spt Tahunan dan Kuasa Spt Tahunan'})
          callback({ message: "Tarif wajib di isi" }, true);
          return true;
        }
        var res = {};
        try {
          data = Utils.formNormalize(data);
          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push("/onpremise/kode-objek-pajak");
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject("envStore")(observer(KodeObjekPajakForm));

const JENIS_PENGHASILAN_MAP = {
  "00000002-5a04-21eb-bb01-132132205609": [
    {
      label: "Badan Usaha Industri Semen",
      value: "Badan Usaha Industri Semen",
    },
    {
      label: "Badan Usaha Industri Farmasi",
      value: "Badan Usaha Industri Farmasi",
    },
    {
      label: "Badan Usaha Industri Kertas",
      value: "Badan Usaha Industri Kertas",
    },
    { label: "Badan Usaha Industri Baja", value: "Badan Usaha Industri Baja" },
    {
      label: "Badan Usaha Industri Otomotif",
      value: "Badan Usaha Industri Otomotif",
    },
    {
      label: "Pembelian Barang Oleh Bendaharawan",
      value: "Pembelian Barang Oleh Bendaharawan",
    },
    {
      label: "Nilai Impor Bank Devisa / Ditjen Bea dan Cukai",
      value: "Nilai Impor Bank Devisa / Ditjen Bea dan Cukai",
    },
    { label: "Hasil Lelang", value: "Hasil Lelang" },
    {
      label: "Penjualan BBM, BBG dan Pelumas",
      value: "Penjualan BBM, BBG dan Pelumas",
    },
    {
      label: "Pembelian Barang Keperluan Industri dlm Sektor Perhutanan",
      value: "Pembelian Barang Keperluan Industri dlm Sektor Perhutanan",
    },
    {
      label: "Pembelian Barang Keperluan dlm Sektor Perkebunan",
      value: "Pembelian Barang Keperluan dlm Sektor Perkebunan",
    },
    {
      label: "Pembelian Barang Keperluan dlm Sektor Pertanian",
      value: "Pembelian Barang Keperluan dlm Sektor Pertanian",
    },
    {
      label: "Pembelian Barang Keperluan dlm Sektor Perikanan",
      value: "Pembelian Barang Keperluan dlm Sektor Perikanan",
    },
    {
      label: "Penjualan Emas Batangan oleh Badan Usaha",
      value: "Penjualan Emas Batangan oleh Badan Usaha",
    },
    {
      label: "Ekspor Komoditas Tambang, Minerba dan Mineral Bukan Logam",
      value: "Ekspor Komoditas Tambang, Minerba dan Mineral Bukan Logam",
    },
    {
      label: "Pembelian Barang oleh Badan Tertentu",
      value: "Pembelian Barang oleh Badan Tertentu",
    },
    {
      label: "Penjualan Kendaraan Bermotor DN",
      value: "Penjualan Kendaraan Bermotor DN",
    },
    {
      label: "Pembelian Minerba dan Mineral Bukan Logam dari Pemegang IUP",
      value: "Pembelian Minerba dan Mineral Bukan Logam dari Pemegang IUP",
    },
  ],
  "00000003-6448-21eb-9001-7b47d5cdb54b": [
    { label: "Dividen", value: "Dividen" },
    { label: "Bunga", value: "Bunga" },
    { label: "Royalti", value: "Royalti" },
    { label: "Hadiah dan Penghargaan", value: "Hadiah dan Penghargaan" },
    {
      label: "Bunga Simpanan yang Dibayarkan oleh Koperasi",
      value: "Bunga Simpanan yang Dibayarkan oleh Koperasi",
    },
    { label: "Imbalan / Jasa Lainnya", value: "Imbalan / Jasa Lainnya" },
    {
      label: "Sewa dan Penghasilan Lain Sehubungan dgn Penggunaan Harta",
      value: "Sewa dan Penghasilan Lain Sehubungan dgn Penggunaan Harta",
    },
  ],
};
