import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./TransaksiKoreksiFiskal.service";
import KategoriPPh25Service from "../../adminpajakku/kategoriPph25/KategoriPph25.service";
import TipePengecualianPajakService from "../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service";
import Utils from "../../../libs/custom/Utils";
import AkunService from "../../master/Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import TransaksiService from "./Transaksi.service";
import TipeKoreksiFiskalService from "../../adminpajakku/tipeKoreksiFiskal/TipeKoreksiFiskal.service";
import ElemenTranskripService from "../../adminpajakku/ElemenTranskrip/ElemenTranskrip.service";
import { ID_CONS } from "../../../clientConstant";

const TransaksiKoreksiFiskalForm = ({ envStore, match, history, sipStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({ loading: true, content: {} });
  const [akunList, setAkun] = useState([]);
  const [journalMap, setJournalMap] = useState({});
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([]);
  const [elemenTranskripList, setElemenTranskripList] = useState([]);
  const [elemenTranskripMap, setElemenTranskripMap] = useState({});
  const [tipeKoreksiFiskalList, setTipeKoreksiFiskal] = useState([]);
  const [tipeKoreksiMap, setTipeKoreksiMap] = useState([]);
  const [allAkunList, setAllAkun] = useState([]);
  var isFiskal = true

  // initial data
  if(window.location.href.includes('transaksi-koreksi-fiskal')){
    isFiskal = false
  }
  useEffect(() => {
    async function initData() {
      _getAkun();
      _getAllAkun();
      _getTipeKoreksiFiskal();
      // _getKodeObjekPajak();

      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            periodeId: sipStore.periodeId,
            dipungut: false,
            dpp: "0",
            pph: "0",
            transaksiKoreksiFiskalAkun__nilai: "0",
            transaksiKoreksiFiskalPengecualians: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      res.data = Utils.formSerialize(res.data);
      // res.data.transaksiKoreksiFiskalAkun__akunid = res.data.transaksiKoreksiFiskalAkun.akun.id
      setIsEdit(true);
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId);
    var eleMap = await _getElemenTranskripList();
    setJournalMap(
      res.data.transaksiJurnals.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setAkun(
      res.data.transaksiJurnals
        .filter((x) => {
          var tpAkun = eleMap[x.akun.elemenTranskripId]?eleMap[x.akun.elemenTranskripId].tipeAkun:{};
          return (
            tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_1 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_2 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_3 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_1 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_2 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_3
          );
        })
        .map((x) => ({ value: x.id, label: x.akun.nama }))
    );
  }

  // async function _getKodeObjekPajak() {
  //   var res = await KodeObjekPajakService.get({ size: 100000 });
  //   setKodeObjekPajak(res.data.map((x) => ({ value: x.id, label: x.nama })));
  // }

  async function _getTipeKoreksiFiskal() {
    var res = await TipeKoreksiFiskalService.get({ size: 100000 });
    setTipeKoreksiFiskal(res.data.map((x) => ({ value: x.id, label: x.nama })));
    setTipeKoreksiMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _getElemenTranskripList(param) {
    if (!param) param = {};
    param.size = 10000;
    param["tipeBaris.equals"] = "KATEGORI";
    var res = await ElemenTranskripService.get(param);
    await setElemenTranskripMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setElemenTranskripList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );

    return res.data.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {});
  }

  async function _getAllAkun() {
    var res = await AkunService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setAllAkun(res.data.map(x => ({value: x.id, label: x.nomor?`${x.nomor} - ${x.nama}`: `${x.nama}`})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={(isFiskal) ? "/onpremise/koreksi-fiskal" : "/onpremise/transaksi/" + match.params.transaksiId + "/koreksi-fiskal"}
      baseId="mod-form-sample"
      title="Koreksi Fiskal"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if (key == "jurnal__id") {
          newObj.jurnal__nilai = journalMap[value].nilai;
        }

        if (key == "tipeKoreksiFiskal__id") {                                                                                                                                                                                                                                                                                                                                                                                                                                              
          newObj.jenis = tipeKoreksiMap[value].tipeKoreksi
        }

        if(isFiskal){
          if (key == "nilaiKoreksi") {
            newObj.nilaiFiskal = parseFloat(newObj.nilai)-parseFloat(newObj.nilaiKoreksi)
          }
        } else {
          if (key == "nilaiKoreksi") {
            newObj.nilaiFiskal = parseFloat(newObj.jurnal__nilai)-parseFloat(newObj.nilaiKoreksi)
          }
        }
      }}
      definitions={[                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
        // =====
        {
          inputType: inputTypes.SELECT,
          label: "Metode Input",
          key: "metodeInput",
          type: "text",
          options: [
            {value: 'Sebagai Koreksi atas Transaksi Jurnal', label: 'Sebagai Koreksi atas Transaksi Jurnal'},
            {value: 'Sebagai Koreksi Fiskal di Kertas Kerja Koreksi Fiskal', label: 'Sebagai Koreksi Fiskal di Kertas Kerja Koreksi Fiskal'}
          ],
          show: () => isFiskal,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Akun",
          key: "akun__id",
          type: "text",
          width: "100%",
          required: true,
          options: allAkunList,
          show: () => isFiskal
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai Komersial",
          key: "nilai",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "100%",
          show: (d) => isFiskal && d.metodeInput == 'Sebagai Koreksi Fiskal di Kertas Kerja Koreksi Fiskal'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "# AKUN TRANSAKSI",
          show: () => !isFiskal
        },
        {
          inputType: inputTypes.SELECT,
          label: "Akun",
          key: "jurnal__id",
          type: "text",
          width: "49%",
          required: true,
          options: akunList,
          style: { marginRight: "2%" },
          show: () => !isFiskal
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai",
          key: "jurnal__nilai",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          show: () => !isFiskal
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "# KOREKSI FISKAL",
          show: () => !isFiskal
        },
        {
          inputType: inputTypes.SELECT,
          label: "Tipe Koreksi",
          key: "tipeKoreksiFiskal__id",
          type: "text",
          required: true,
          validation: "required",
          options: tipeKoreksiFiskalList
        },
        {
          inputType: inputTypes.INPUT,
          label: "Jenis Koreksi",
          key: "jenis",
          type: "text",
          validation: "required",
          disabled: true,
          width: "100%",
          // options:[
          //   {value: 'Koreksi fiskal positif', label: 'Koreksi Fiskal Positif'},
          //   {value: 'Koreksi fiskal negatif', label: 'Koreksi Fiskal Negatif'}
          // ],
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai Koreksi",
          key: "nilaiKoreksi",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required|numeric"
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai Fiskal",
          key: "nilaiFiskal",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          validation: "required|numeric"
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
          width: '100%'
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        var url = ""
        try {
          data.transaksi = {id: match.params.transaksiId}
          data = Utils.formNormalize(data);
          if(isFiskal){
            data.sumber = 'Input Data'
            url = "/onpremise/koreksi-fiskal"
          } else {
            data.sumber = 'Transaksi'
            url = "/onpremise/transaksi/" + match.params.transaksiId + "/koreksi-fiskal"
          }
          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push(url);
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject("envStore", "sipStore")(observer(TransaksiKoreksiFiskalForm));
