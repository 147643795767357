import React from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './TableSample.service'
import moment from 'moment'

const TableSample = ({
  className       = '',
  showCommandbar  = true
}) => {
  return (
    <TableWrapper
      baseId="mod-table-sample"
      title={t.translate('modules.table.title')}
      className={className}
      hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="date"
      columns={[
        {
          label: 'String',
          searchable: true,
          key:'string',
          render: item => (
            <TableWrapper.Link
              to={`/internal/inline-form`}
            >
              {item.string}
            </TableWrapper.Link>)
        },
        {
          label: 'Number',
          searchable: true,
          key: 'number',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.number)
        },
        {
          label: 'Boolean',
          type: TableWrapper.dataTypes.BOOLEAN,
          render: item => (
            <TableWrapper.Status
              type={item.boolean
                ? TableWrapper.Status.types.SUCCESS
                : TableWrapper.Status.types.ERROR
              }
            >
              {item.boolean ? 'TRUE' : 'FALSE'}
            </TableWrapper.Status>
          )
        },
        {
          label: 'Date',
          type: TableWrapper.dataTypes.DATE,
          render: item => (moment(item.date).format('lll'))
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => console.log('request add'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', () => console.log('request edit'), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', () => console.log('request delete'), true)
      ]}
      onFetchData={ async query => {
        return service.get(query)
      }}
      showCommandbar={false}
      showFilterPeriod={false}
    />
  )
}

export default TableSample
