import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import { Commandbar, Flex } from '../components'
import { TabsManager, Tabs, TabPanels, TabPanel, FontIcon, TabConfig } from 'react-md'
import { find } from 'lodash'

const TabsWrapper = ({
  title                 = null,
  baseId                = 'mpk-table-wrapper',
  backTo                = null,
  className             = '',
  actions               = null,
  showCommandbar        = true,
  tabs                  = [],
  onChange              = null,
  commandbar            = null,
  showIndex             = false,
  ...props
}) => {
  const { tab } = useParams()
  const tabList = tabs.filter(d => d.show === undefined ? true : d.show);
  const tabItem = find(tabList, {key: tab})
  const [currentIndex, setCurrentIndex] = useState(tabItem ? tabs.indexOf(tabItem) : 0)
  const handleTabChange = (index) => {
    if(onChange){
      onChange(tabs[index], (newIndex) => {
        setCurrentIndex(newIndex || index)
      })
    } else {
      setCurrentIndex(index)
    }
  }

  const onNextTab = () => {
    if(currentIndex < tabList.length - 1) setCurrentIndex(currentIndex+1)
  }

  const onPrevTab = () => {
    if(currentIndex > 0) setCurrentIndex(currentIndex-1)
  }


  return (
    <Flex 
      className={`mpk-details-wrapper mpk-full full-height ${className}`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      { showCommandbar && (
        <Commandbar
          title={title}
          className="flex-none mpk-border solid dark border-bottom"
          actions={actions}
          backTo={backTo}
          {...commandbar}
        />
      )}
      <Flex 
        fit
        direction={Flex.properties.direction.COLUMN}
        className="mpk-full full-width"
      >
        <TabsManager 
          tabsId={baseId}
          tabs={tabList.map((d, i) => {
            let label = `${showIndex ? `${i+1}. ` : ''}${d.label}`
            return d.iconClassName 
              ? {children: label, icon: (<FontIcon iconClassName={d.iconClassName} style={d.iconStyle}/>)}
              : {children: label}
          })}
          onActiveIndexChange={handleTabChange}
          activeIndex={currentIndex}
          {...props}
        >
          <Tabs/>
          <TabPanels
            className="flex mpk-full full-width"
            style={{height: 'auto', flex: 1}}
          >
            {tabList.map(tab => (
              <TabPanel
                key={`${baseId}-tab-${tab.key}`}
                style={{
                  willChange: 'unset'
                }}
                onClick={() => {}}
              >
                {typeof tab.render === 'function' ? tab.render({onNextTab, onPrevTab}) : tab.render}
              </TabPanel>
            ))}
          </TabPanels>
        </TabsManager>
      </Flex>
    </Flex>
  )
}

export default TabsWrapper
