import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./TransaksiPembelianAkun.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import Utils from "../../../libs/custom/Utils";

import { inject, observer } from "mobx-react";

var _baseId = "mod-table-transaksi-pembelian-akun";

const TransaksiPembelianAkunTable = ({
  className = "",
  history,
  pembelianId,
  modalStore,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={""}
        className={className}
        showCommandbar={false}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Barang/Jasa",
            searchable: true,
            key: "pembelianItem",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.pembelianItem.nama,
          },
          // {
          //   label: 'Aset',
          //   searchable: true,
          //   key: 'namaAset',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => <div>
          //   {item.transaksiPenjualanAktivaTetapAsets.map((v, i)=> {
          //     return <p>{v.aset.nama} - {v.aset.nomor}</p>
          //   })}
          // </div>
          // }
          // {
          //   label: 'Nomor Aset',
          //   searchable: true,
          //   key: 'nomorAset',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.jenis)
          // },{
          //   label: 'Tanggal Perolehan',
          //   searchable: true,
          //   key: 'nomorAset',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.jenis)
          // },{
          //   label: 'Harga Perolehan',
          //   searchable: true,
          //   key: 'nomorAset',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.jenis)
          // }
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () =>
              history.push(
                `/onpremise/transaksi-pembelian-akun/baru/${pembelianId}`
              ),
            true
          ),
          new TableWrapper.action(
            "Generate",
            "mdi mdi-inbox-full",
            async () => {
              modalStore.showConfirm({
                children: "Generate data Akun?",
                onRequestClose: () => {
                  modalStore.closeConfirm();
                },
                onSubmit: async (callback) => {
                  await service.http.get(`/pembelian/${pembelianId}/generate-akun`);

                  toast.success(`Data Akun telah di generate.`);
                  TableWrapper.reload(_baseId);
                  callback();
                },
              });
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) =>
              history.push(
                `/onpremise/transaksi-pembelian-akun/${item.id}/${pembelianId}`
              ),
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          query["pembelianId.equals"] = pembelianId;
          return service.get(query);
        }}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
    </>
  );
};

export default inject(
  "modalStore",
  "navigationStore"
)(observer(TransaksiPembelianAkunTable));
