import React, {useEffect, useState} from 'react'
import { TableWrapper, FormWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './PenyusutanPeriode.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'
import { inject, observer } from 'mobx-react'
import { DataForm } from '../../../libs/react-mpk/components'

var _baseId = "mod-table-penyusutan-periode"

const PenyusutanPeriodeTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [uploadModal, setUploadModal] = useState({ show: false, type: 'amortisasi'})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  function _closeUploadModal() {
    setUploadModal({show: false})
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Penyusutan & Amortisasi'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="tahun"
      columns={[
        {
          label: 'Tahun',
          searchable: true,
          key: 'tahun',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tahun)
        },
        {
          label: 'Masa',
          searchable: true,
          key: 'masa',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.masa)
        },
        {
          label: 'Penyusutan Fiskal',
          searchable: true,
          key: 'penyusutanFiskal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.penyusutanFiskal))
        },
        {
          label: 'Penyusutan Komersial',
          searchable: true,
          key: 'penyusutanKomersial',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.penyusutanKomersial))
        },
        {
          label: 'Koreksi Fiskal',
          searchable: true,
          key: 'koreksiFiskal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.koreksiFiskal))
        },
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/penyusutan-periode/baru'), true),
        new TableWrapper.action('Import Amortisasi', 'mdi mdi-import', () => setUploadModal({show: true, type: 'amortisasi'}), true),
        new TableWrapper.action('Import Penyusutan', 'mdi mdi-import', () => setUploadModal({show: true, type: 'penyusutan'}), true)
      ]}
      itemActions={[
        new TableWrapper.action('Buka Penyusutan Per Akun', 'mdi mdi-book-open', (item) => {
          history.push(`/onpremise/penyusutan-periode-akun/${item.id}`)
        }, true),
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/penyusutan-periode/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    <DataForm
      baseId={"amortisasi-penyusutan-import"}
      asDialog={true}
      defaultData={{}}
      definitions={[{
        inputType: FormWrapper.inputTypes.FILE_INPUT,
        label: 'File',
        key: 'file',
        theme: 'filled',
        style: {width: 200}
      }]}
      visible={uploadModal.show}
      submitLabel='Import Data'
      onSubmit={async (d, callback)=> {
        var formData = new FormData()
        formData.set('file', d.file)
        var uploadRes = await service.http.post('/upload', formData)
        if(uploadRes.data) {
          if(uploadModal.type == 'amortisasi') {
            var importRes = await service.http.post('/amortisasi-importer', {
              nama: uploadRes.data.name,
              origin: uploadRes.data.origin,
              perusahaanId: sipStore.companyId,
              periodeId: sipStore.periodeId,
              ext: 'xls'
            })
          } else {
            var importRes = await service.http.post('/penyusutan-importer', {
              nama: uploadRes.data.name,
              origin: uploadRes.data.origin,
              perusahaanId: sipStore.companyId,
              periodeId: sipStore.periodeId,
              ext: 'xls'
            })
          }
        }
        callback('Data sedang di import. Mohon pantau progress import di menu log import.', false)
      }}
      onRequestClose={_closeUploadModal} />
    </>
  )
}

export default inject('sipStore', 'navigationStore')(observer(PenyusutanPeriodeTable))
