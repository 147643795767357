import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './ReturPenjualanPos.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import AsetService from '../Aset/Aset.service'
import TransaksiService from './Transaksi.service'
import LawanTransaksiService from '../../master/LawanTransaksi/LawanTransaksi.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import ItemService from '../../master/Item/Item.service'
import { toast } from '../../../libs/react-mpk/services'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import TransaksiPajakTerutangTable from './TransaksiPajakTerutang.table'
import VTransaksiItemService from './VTransaksiItem.service'
import TransaksiPajakTerutangService from './TransaksiPajakTerutang.service'
import iziToast from 'izitoast'
import { DataTable } from '../../../libs/react-mpk/components'

const ReturPenjualanPosForm = ({envStore, match, history, sipStore, modalStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [lawanTransaksiList, setLawanTransaksiList] = useState([])
  const [lawanTransaksiMap, setLawanTransaksiMap] = useState({})
  const [vTransaksiItemList, setVTransaksiItem] = useState([])
  const [transaksiPajakTerutangList, setTransaksiPajakTerutang] = useState([])
  const [transaksiPajakTerutangMap, setTransaksiPajakTerutangMap] = useState({})
  const [kodeJenis, setKodeJenis] = useState([])
  const [show, setShow] = useState(false)
  var kodeJenisTransaksiList = [
    { label: "13 - Ekspor BKP Berwujud", value: "13 - Ekspor BKP Berwujud", noJenisTransaksi: '4' },
    { label: "14 - Ekspor BKP Tidak Berwujud", value: "14 - Ekspor BKP Tidak Berwujud", noJenisTransaksi: '4' },
    { label: "15 - Ekspor JKP", value: "15 - Ekspor JKP", noJenisTransaksi: '4' },
    { label: "01 - Kepada Pihak yang Bukan Pemungut PPN", value: "01 - Kepada Pihak yang Bukan Pemungut PPN", noJenisTransaksi: '5' },
    { label: "02 - Kepada Pemungut Bendaharawan", value: "02 - Kepada Pemungut Bendaharawan", noJenisTransaksi: '5' },
    { label: "03 - Kepada Pemungut selain Bendaharawan", value: "03 - Kepada Pemungut selain Bendaharawan", noJenisTransaksi: '5' },
    { label: "04 - DPP Nilai Lain", value: "04 - DPP Nilai Lain", noJenisTransaksi: '5' },
    { label: "06 - Penyerahan Lainnya, termasuk penyerahan kepada turis asing dalam rangka VAT refund", value: "06 - Penyerahan Lainnya, termasuk penyerahan kepada turis asing dalam rangka VAT refund", noJenisTransaksi: '5' },
    { label: "07 - Penyerahan yang PPN-nya tidak dipungut", value: "07 - Penyerahan yang PPN-nya tidak dipungut", noJenisTransaksi: '5' },
    { label: "08 - Penyerahan yang PPN-nya dibebaskan", value: "08 - Penyerahan yang PPN-nya dibebaskan", noJenisTransaksi: '5' },
    { label: "09 - Penyerahan Aktiva (Pasal 16D UU PPN)", value: "09 - Penyerahan Aktiva (Pasal 16D UU PPN)", noJenisTransaksi: '5' }
  ]

  // initial data
  useEffect(() => {
    async function initData() {
      setKodeJenis(kodeJenisTransaksiList)
      await _getLawanTransaksi()
      await _getEntitasList()
      await _getVTransaksiItemList()     
      if(match.params.id == 'baru') {
        setData({loading: false, content: {perusahaanId: sipStore.companyId, dipungut: false, dpp:'0', pph: '0', ppn:'0', ppnbm:'0', transaksiSebelumMenggunakanSip: false, tidakTerutangPpn: false}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      if(res.data.transaksiSebelumMenggunakanSip == false && res.data.posId){
        await _getTransaksiPajakTerutangList(res.data.posId)
        if(res.data.transaksiPajakTerutang.kodeObjekPajak.kode == "PPNT - tidak terutang PPN"){
          res.data.transaksiPajakTerutang__nomorBpOrFaktur = "-"
        }
      }
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getLawanTransaksi() {
    var res = await LawanTransaksiService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setLawanTransaksiList(res.data.map(x => ({value: x.id, label: x.nama})))
    setLawanTransaksiMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getVTransaksiItemList() {
    var res = await VTransaksiItemService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setVTransaksiItem(res.data.map(x => ({value: x.id, label: x.nomor})))
  }

  async function _getTransaksiPajakTerutangList(item) {
    var res = await TransaksiPajakTerutangService.get({size: 10000, 'posId.equals': item})
    setTransaksiPajakTerutang(res.data.map(x => ({value: x.id, label: x.kodeObjekPajak?`${x.kodeObjekPajak.kode} - ${x.kodeObjekPajak.nama}`: `${x.kodeObjekPajak}`})))
    setTransaksiPajakTerutangMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  return (
    <FormWrapper
      noValidate
      // showCommandbar={match.params.id == 'baru'}
      loading={data.loading}
      actions={[
        {
          label: 'Generate',
          iconClassName: 'mdi mdi-inbox-full',
          onClick: async () => {
            modalStore.showConfirm({
              children: 'Generate data pajak terutang?',
              onRequestClose: ()=> {modalStore.closeConfirm()},
              onSubmit: async (callback) => {
                await service.http.get(`/pos/${match.params.id}/generate`);
                toast.success(`Data pajak terutang telah di generate.`);
                callback()
              }
            })
          },
          show: match.params.id == 'baru'
        }
      ]}
      backTo={`/onpremise/retur-penjualan`}
      baseId="mod-form-sample"
      title={"Retur Penjualan"}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key === 'posLawanTransaksi__sumberId') {
          var lt = lawanTransaksiMap[value]
          if(lt) {
            newObj.posLawanTransaksi__jenisPemungutPPh22 = ""
            newObj.posLawanTransaksi__nama = lt.nama
            newObj.posLawanTransaksi__nik = lt.nik
            newObj.posLawanTransaksi__npwp = lt.npwp
            newObj.posLawanTransaksi__pemungut = ""
            newObj.posLawanTransaksi__sumber = "LawanTransaksi"
            newObj.posLawanTransaksi__sumberId = lt.id
            newObj.posLawanTransaksi__tipeWajibPajak = lt.tipeWajibPajak
            newObj.posLawanTransaksi__domisiliWajibPajak = lt.domisiliWajibPajak
          }
        }

        if(key == 'dokumenJenisTransaksi'){
          var jenisTransaksi = value.split(" ", 1)[0]
          var kodeJenisTransaksi = kodeJenisTransaksiList.filter(d => { return d.noJenisTransaksi == jenisTransaksi})
          setKodeJenis(kodeJenisTransaksi)
        }

        if(key == 'posId'){
          _getTransaksiPajakTerutangList(value)
        }

        if(key == 'transaksiPajakTerutang__id'){
          var tp = transaksiPajakTerutangMap[value]
          if(tp){
            newObj.transaksiPajakTerutang__dpp = tp.dpp
            newObj.transaksiPajakTerutang__ppn = tp.ppn
            newObj.transaksiPajakTerutang__ppnbm = tp.ppnbm
            if(tp.kodeObjekPajak.kode == "PPNT - tidak terutang PPN"){
              newObj.transaksiPajakTerutang__nomorBpOrFaktur = "-"
            } else {
              newObj.transaksiPajakTerutang__nomorBpOrFaktur = tp.nomorBpOrFaktur
            }
            if(tp.kodeObjekPajak.tipePajak.kode == "PPN"){
              setShow(true)
            } else {
              setShow(false)
            }
          }
        }

        
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitasId',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required',
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nomor Transaksi',
          key: 'nomor',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Transaksi',
          key: 'tanggal',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
          width: '100%'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# Pilih Lawan Transaksi',
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Lawan Transaksi',
          key: 'posLawanTransaksi__sumberId',
          type: 'text',
          // width: '49%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          options: lawanTransaksiList
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP Lawan Transaksi',
          key: 'posLawanTransaksi__npwp',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '################',
          maskChar: '_',
          maskValue: 'string',
          label: 'NIK Lawan Transaksi',
          key: 'posLawanTransaksi__nik',
          type: 'text',
          width: '49%',
          disabled: true,
          validation: [{required_if: ['posLawanTransaksi__tipeWajibPajak', "OP"]}],
          show: (d) => d.posLawanTransaksi__domisiliWajibPajak == 'DN'
        },
        {
          inputType: inputTypes.INPUT,
          // mask: '################',
          // maskChar: '_',
          // maskValue: 'string',
          label: 'Passport Lawan Transaksi',
          key: 'posLawanTransaksi__nik',
          type: 'text',
          width: '49%',
          disabled: true,
          validation: [{required_if: ['posLawanTransaksi__tipeWajibPajak', "OP"]}],
          show: (d) => d.posLawanTransaksi__domisiliWajibPajak == 'LN'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# DATA DOKUMEN TRANSAKSI YANG AKAN DIRETUR',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Data Transaksi Sebelum Menggunakan SIP',
          key: 'transaksiSebelumMenggunakanSip',
          type: 'text'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Nomor Transaksi Penjualan',
          key: 'posId',
          type: 'text',
          // width: '49%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          options: vTransaksiItemList,
          show: (d) => d.transaksiSebelumMenggunakanSip == false
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Transaksi Penjualan termasuk transaksi yang tidak terutang PPN?',
          key: 'tidakTerutangPpn',
          type: 'number',
          show: (d) => d.transaksiSebelumMenggunakanSip == true
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Dokumen yang akan diretur',
          key: 'jenisDokumen',
          type: 'text',
          width: '100%',
          options: [
            { label: "Faktur Pajak", value: "Faktur Pajak" },
            { label: "Dokumen Lain", value: "Dokumen Lain" }
          ],
          show: (d)=> d.tidakTerutangPpn == false && d.transaksiSebelumMenggunakanSip == true,
          // validation: [{required_if: ['tidakTerutangPpn', false]}, {required_if: ['transaksiSebelumMenggunakanSip', true]}]
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Masukan Nomor Faktur Keluaran yang akan diretur',
          key: 'nomorRetur',
          type: 'text',
          width: '100%',
          // disabled: isEdit,
          show: (d)=> d.jenisDokumen == "Faktur Pajak",
          validation: [{required_if: ['jenisDokumen', "Faktur Pajak"]}]
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Kode Objek Pajak yang akan diretur',
          key: 'transaksiPajakTerutang__id',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          options: transaksiPajakTerutangList,
          show: (d) => d.transaksiSebelumMenggunakanSip == false
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nomor Faktur Keluaran',
          key: 'transaksiPajakTerutang__nomorBpOrFaktur',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          disabled: true,
          show: (d) => d.transaksiSebelumMenggunakanSip == false
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Transaksi',
          key: 'dokumenJenisTransaksi',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          options: [
            { label: "4 - Penyerahan Luar Negeri / Ekspor", value: "4 - Penyerahan Luar Negeri / Ekspor" },
            { label: "5 - Penyerahan dengan Menggunakan Dokumen Tertentu", value: "5 - Penyerahan dengan Menggunakan Dokumen Tertentu" }
          ],
          show: (d) => d.transaksiSebelumMenggunakanSip == true && d.jenisDokumen == "Dokumen Lain"
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Kode Jenis Transaksi',
          key: 'dokumenKodeJenisTransaksi',
          type: 'text',
          width: '100%',
          disabled: isEdit,
          options: kodeJenis,
          show: (d) => d.transaksiSebelumMenggunakanSip == true && d.jenisDokumen == "Dokumen Lain"
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Masukan Nomor Dokumen yang akan diretur',
          key: 'nomorDokumenRetur',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          show: (d) => d.transaksiSebelumMenggunakanSip == true && d.jenisDokumen == "Dokumen Lain"
        },
        // {
        //  inputType: inputTypes.INPUT,
        //  label: 'Nomor Dokumen yang akan diretur',
        //  key: 'nomorDokumen',
        //  type: 'text',
        //  width: '100%',
        //  disabled: isEdit,
        //  show: (d) => d.transaksiSebelumMenggunakanSip == true && d.jenisDokumen == "Dokumen Lain"
        // },
        {
          inputType: inputTypes.DIVIDER,
          label: '# Nilai yang tersedia untuk diretur',
          show: (d) => d.transaksiSebelumMenggunakanSip == false
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'DPP',
          key: 'transaksiPajakTerutang__dpp',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          disabled: true,
          show: (d) => d.transaksiSebelumMenggunakanSip == false
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPN',
          key: 'transaksiPajakTerutang__ppn',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          disabled: true,
          show: (d) => show
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPnBM',
          key: 'transaksiPajakTerutang__ppnbm',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          disabled: true,
          show: (d) => show
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# Nilai yang akan diretur',
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'DPP',
          key: 'dpp',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPN',
          key: 'ppn',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          show: (d)=> d.tidakTerutangPpn == false || show
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPnBM',
          key: 'ppnbm',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          show: (d)=> d.tidakTerutangPpn == false || show
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        // var kode = []
        try {
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          // data.transaksiAktivaTetapAkun.akun = {
          //   id: data.transaksiAktivaTetapAkun__akunid
          // }
          if(data.dpp > data.transaksiPajakTerutang__dpp || data.ppn > data.transaksiPajakTerutang__ppn || data.ppnbm > data.transaksiPajakTerutang__ppnbm){
            iziToast.warning({
              message: "Nilai retur tidak boleh melebihi nilai yang tersedia untuk diretur"
            })
            callback("", false, false)
            return
          }

          if(data.transaksiSebelumMenggunakanSip) {
            data.posId = null
          }
          // delete data.transaksiSebelumMenggunakanSip
          delete data.transaksiPajakTerutang__nomorBpOrFaktur
          delete data.transaksiPajakTerutang__dpp
          delete data.transaksiPajakTerutang__ppn
          delete data.transaksiPajakTerutang__ppnbm
           

          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/retur-penjualan')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )

  // if(match.params.id != 'baru') {
  //   return <TabsWrapper
  //     title="Retur Penjualan"
  //     showCommandbar={true}
  //     baseId="mod-details-sample-aad"
  //     actions={[
  //       {name: 'Add', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/onpremise/retur-penjualan')}
  //     ]}
  //     onChange={ (tab, d) => {
  //       navigationStore.redirectTo(`/onpremise/retur-penjualan/${match.params.id}/${tab.key}`)
  //       d()
  //     }}
  //     // tabs={tabs}
  //     tabs={[
  //       {
  //         label: 'Data',
  //         key: 'form',
  //         render: form
  //       },
  //       {
  //         label: 'Pajak',
  //         key: 'pajak-terutang',
  //         render: <TransaksiPajakTerutangTable jenisMap={{}} history={history} transaksiId={null} posId={match.params.id} />
  //       },
  //     ]}

  //   />
  // } else {
  //   return form
  // }
}

export default inject('envStore', 'sipStore', 'modalStore', 'navigationStore')(observer(ReturPenjualanPosForm))
