import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './MasterLevel.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanService from '../Perusahaan/Perusahaan.service'

const MasterLevelForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [perusahaanList, setPerusahaanList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)

      // _getTipeBisnisAsetList({
      //   'kelompokAsetId.equals': res.data.kelompokAset__id
      // })

      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getPerusahaanList() {
    var res = await PerusahaanService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/master-level`}
      baseId="mod-form-sample"
      title={t.translate('modules.masterLevel.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          disabled: isEdit,
          validation: 'required',
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode',
          key: 'kode',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '100%',
          validation: 'required'
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Gaji Minimum (Rp)',
          key: 'minGaji',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Gaji Maksimum (Rp)',
          key: 'maxGaji',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        }
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/master-level')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(MasterLevelForm))
