import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import Spt1771Service from "./Spt1771.service";
import Utils from "../../../libs/custom/Utils";
import TranskripListService from "../Transkrip/TranskripList.service";
import {
  KATEGORI_BIAYA_LAMP_2,
  KELOMPOK_PENGHASILAN,
  MAPPER_HPP,
  MAPPER_PERSEDIAAN_AKHIR,
  TIPE_BIAYA_LAMP_2,
  TP_PENGHASILAN,
} from "./SektorMapper";
import TransaksiPajakTerutangService from "../Transaksi/TransaksiPajakTerutang.service";
import { AKUN_JENIS_MAP } from "../../../clientConstant";
import EntitasService from '../../master/Entitas/Entitas.service';
import DetailModalService from "../Migrasi/DetailModal.service";
import AsetService from "../Aset/Aset.service"
import FixedAsetService from "../../../modules/master/FixedAset/FixedAset.service"
import MetodePenyusutanService from "../../../modules/master/MetodePenyusutan/MetodePenyusutan.service"

const Spt1771Form = ({
  envStore,
  match,
  history,
  sipStore,
  navigationStore,
}) => {
  // initial data
  useEffect(() => {
    async function initData() {
      if (window.xml1771Pajakku) {
        var d = await Spt1771Service.http.get("/user/widget/login");
        const { company, periode, labaRugiMap, akunTranskrips } =
          await init_spt_data(sipStore);

        var Profile = await get_SPT_1771_Profile(company, periode);
        var LampI = await get_SPT_1771_LampI(company, periode);
        var LampII = await get_SPT_1771_LampII(
          company,
          periode,
          labaRugiMap,
          akunTranskrips
        );
        var PPH_22 = await get_SPT_1771_PPH_2(company, periode);
        var PPh23 = await get_SPT_1771_PPh23(company, periode);
        var HeaderLampIV = await get_SPT_1771_HeaderLampIV(
          company,
          periode,
          labaRugiMap,
          akunTranskrips
        );
        var DetailLampVPengurus = await get_SPT_1771_DetailLampVPengurus();
        var DetailLampVPemegangSaham = await get_SPT_1771_DetailLampVPemegangSaham(sipStore);
        var DetailLampKhus1A = await get_SPT_1771_DetailLampKhus1A();
        var DetailLampKhus1A2 = await get_SPT_1771_DetailLampKhus1A2();
        var DetailLampKhus5A = await get_SPT_1771_DetailLampKhus5A();


        console.log("DATA_SPT", {
          spt: {
            ...Profile,
            ...LampI,
            ...LampII,
            ...PPH_22,
            ...PPh23,
            ...HeaderLampIV,
            ...DetailLampVPengurus,
            ...DetailLampVPemegangSaham,
            ...DetailLampKhus1A,
            ...DetailLampKhus1A2,
            ...DetailLampKhus5A
          },
        })
        setTimeout(function () {
          window.xml1771Pajakku.render(
            "spt21-client-pajakku",
            {
              accessKey: d.data.accessKey.split("=")[1],
              client: "vR7jrTEzqU6lvQlgc0jq9hl6hSuqPIgJCOCo9JRE", // urlAccessCLientId
              applicationType: "private", // [private, internal]
              channel: "sip",
              requestTokenPeriod: 300, // in a sec,
              organizations: [
                {
                  npwp: company.npwp,
                  name: company.nama,
                  infoAddress: company.alamat,
                  infoPostal: company.kodePos,
                  infoCity: company.kota,
                  infoPhone: company.telepon,
                },
              ],
              spt: {
                ...Profile,
                ...LampI,
                ...LampII,
                ...PPH_22,
                ...PPh23,
                ...HeaderLampIV,
                ...DetailLampVPengurus,
                ...DetailLampVPemegangSaham,
                ...DetailLampKhus1A,
                ...DetailLampKhus1A2,
                ...DetailLampKhus5A
              },
            },
            function (eventName, data) {
              switch (eventName) {
                case "request-access-token":
                  // setTimeout(async function () {
                  //   var e = await Spt1771Service.http.get("/user/widget/login");

                  //   window.xml1771Pajakku.post("update-access-token", {
                  //     accessKey: e.data.accessKey.split("=")[1],
                  //   });
                  // }, 2000);
                  break;
                case "error":
                  // setTimeout(async function () {
                  //   var e = await Spt1771Service.http.get("/user/widget/login");

                  //   window.xml1771Pajakku.reload(data.id, {
                  //     accessKey: e.data.accessKey.split("=")[1]
                  //   });
                  // }, 2000);
                case "request-cert":
                  // Widget 1771 meminta meta data sertifikat atas NPWP pada profil spt
                  // Informasi NPWP didapatkan dari objek data
                  setTimeout(function () {
                    // Aplikasi parent posting meta data sertifikat npwp yang diperlukan
                    window.xml1771Pajakku.post("cert-data", {
                      npwp: "XXXXXXXXXXXXXXX",
                      publicKey: "TESTING123",
                      privateKey: "321",
                    });
                  }, 3000);
                  break;
              }
            }
          );
        }, 1000);
      }
    }
    initData();
  }, []);

  return (
    <div
      id="spt21-client-pajakku"
      style={{ width: "100%", height: "100%", border: "none !important" }}
    ></div>
  );
};

export default inject("sipStore", "navigationStore")(observer(Spt1771Form));

async function init_spt_data(sipStore) {
  var company = JSON.parse(localStorage.getItem("sip-company-active"));
  var periode = JSON.parse(localStorage.getItem("sip-periode-active"));
  var akun = await Spt1771Service.http.get(
    `/akuns?size=9999999&page=0&sort=createdDate,DESC&startDate=&endDate=&perusahaanId.equals=${company.id}`
  );
  var akunMap = akun.data.reduce((pv, cv) => {
    pv[cv.id] = cv;
    return pv;
  }, {});
  var res = await TranskripListService.http.get(
    "/saldo-neraca-transkrip-list",
    { periodeFiskalId: sipStore.periodeId }
  );

  var labaRugiMap = res.data.labaRugiList.reduce((pv, cv) => {
    pv[cv.masterFieldMappingSPT1771Id] = cv;
    return pv;
  }, {});

  window.akunMap = akunMap;

  var akunTranskrips = [];
  res.data.aktivaList.forEach((d) => {
    d.saldoNeracaAkuns.forEach((e) => {
      e.akunData = akunMap[e.akun.id];
      if(e.akunData) {
        akunTranskrips.push(e);
      }

    });
  });

  res.data.pasivaList.forEach((d) => {
    d.saldoNeracaAkuns.forEach((e) => {
      e.akunData = akunMap[e.akun.id];
      if(e.akunData) {
        akunTranskrips.push(e);
      }
    });
  });

  res.data.labaRugiList.forEach((d) => {
    d.saldoNeracaAkuns.forEach((e) => {
      e.akunData = akunMap[e.akun.id];
      if(e.akunData) {
        akunTranskrips.push(e);
      }
    });
  });
  return { company, periode, labaRugiMap, akunTranskrips };
}

async function get_SPT_1771_Profile(company, periode) {
  return {
    Profile: {
      "THN_PJK": periode.tahun,
      // "KD_PEMBETULAN_KE": "0",
      NPWP: company.npwp,
      NAMA_WP: company.nama,
      // "TGL_PENGUKUHAN": "",
      ALAMAT: company.alamat,
      KODE_POS: company.kodePos,
      // "KD_TLP": "",
      TELEPON: company.telepon,
      // "KD_FAX": "",
      // "FAX": "",
      // "JENIS_USAHA": "",
      // "KLU": "",
      // "BULAN_MULAI": "01",
      // "THN_MULAI": "21",
      // "BULAN_SELESAI": "12",
      // "THN_SELESAI": "21",
      NAMA_TTD: "",
      NPWP_TTD: "",
      NPWP_KUASA: "",
      NAMA_KUASA: "",
      NEG_DOM: company.negara,
      FlgTtdSPT: "1",
      ID_TEMPLATE: "1",
    },
  };
}

async function get_SPT_1771_LampI(company, periode) {
  var spt1771View = await Spt1771Service.http.get(
    `/periode-fiskals/${periode.id}/spt-1771`
  );
  var spt1771ViewParse = Utils.formNormalize(spt1771View.data);

  return {
    LampI: {
      ...spt1771ViewParse,
    },
  };
}

async function get_SPT_1771_LampII(
  company,
  periode,
  labaRugiMap,
  akunTranskrips
) {
  if(akunTranskrips === null) akunTranskrips = []
  console.log(akunTranskrips, 'asdasd')
  return {
    LampII: {
      d13:
        company.tipeIndustri &&
        company.tipeIndustri.mappingSpt1771 &&
        labaRugiMap[MAPPER_HPP[company.tipeIndustri.mappingSpt1771]]
          ? labaRugiMap[MAPPER_HPP[company.tipeIndustri.mappingSpt1771]]
          : 0,
      // "d14": 0,
      // "d15": 0,
      // "d16": 0,
      d23: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2[
                "Gaji, Upah, Bonus, Gratifikasi, honorium THR, dsb"
              ] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d24: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2[
                "Gaji, Upah, Bonus, Gratifikasi, honorium THR, dsb"
              ] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d25: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2[
                "Gaji, Upah, Bonus, Gratifikasi, honorium THR, dsb"
              ] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d26: 0,
      d33: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Transfortasi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d34: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Transfortasi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d35: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Transfortasi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d36: 0,
      d43: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Penyusunan dan Amortisasi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d44: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Penyusunan dan Amortisasi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d45: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Penyusunan dan Amortisasi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d46: 0,
      d53: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Sewa"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d54: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Sewa"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d55: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Sewa"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d56: 0,
      d63: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Bunga Pinjaman"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d64: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Bunga Pinjaman"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d65: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Bunga Pinjaman"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d66: 0,
      d73: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Sehubungan dengan Jas"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d74: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Sehubungan dengan Jas"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d75: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Sehubungan dengan Jas"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d76: 0,
      d83: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Piutang Tak Tertagih"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d84: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Piutang Tak Tertagih"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d85: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Piutang Tak Tertagih"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d86: 0,
      d93: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Royalti"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d94: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Royalti"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d95: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Royalti"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d96: 0,
      d103: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Pemasaran/Promosi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d104: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Pemasaran/Promosi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d105: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Pemasaran/Promosi"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d106: 0,
      d113: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Lainnya"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["HARGA POKOK PENJUALAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d114: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Lainnya"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA USAHA LAINNYA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      d115: akunTranskrips
        .filter(
          (x) =>
            x.akunData.tipeBiayaLamp1771IIId ===
              TIPE_BIAYA_LAMP_2["Biaya Lainnya"] &&
            x.akunData.kategoriBiayaLamp1771IIId ===
              KATEGORI_BIAYA_LAMP_2["BIAYA DARI LUAR USAHA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // d116: 0,
      d123: 0,
      // d124: 0,
      // d125: 0,
      // d126: 0,
      d133:
        company.tipeIndustri &&
        company.tipeIndustri.mappingSpt1771 &&
        labaRugiMap[
          MAPPER_PERSEDIAAN_AKHIR[company.tipeIndustri.mappingSpt1771]
        ]
          ? labaRugiMap[
              MAPPER_PERSEDIAAN_AKHIR[company.tipeIndustri.mappingSpt1771]
            ]
          : 0,
      // d134: 0,
      // d135: 0,
      // d136: 0,
      // dJml3: 0,
      // dJml4: 0,
      // dJml5: 0,
      // dJml6: 0,
    },
  };
}

async function get_SPT_1771_PPH_2(company, periode) {
  var pajakTerutangs = await TransaksiPajakTerutangService.get({
    size: 900000000,
    page: 0,
    sort: "createdDate,DESC",
    "tipeBebanPajak.equals": "Pajak Terutang",
    "tipePajakKode.equals": "Pasal 22",
    "periode.equals": periode.id,
  });

  console.log(pajakTerutangs, 'pajakTerutangs')

  var pph22Map = pajakTerutangs.data
    .filter((x) => x.transaksi)
    .filter((x)=> x.pph > 0)
    .map((x) => {
      return {
        NPWP: x.transaksi.transaksiLawanTransaksi.npwp,
        NMWP: x.transaksi.transaksiLawanTransaksi.nama,
        JnsPenghasilan: x.kodeObjekPajak.jenisPenghasilan?x.kodeObjekPajak.jenisPenghasilan:'',
        ObjPmt: x.dpp,
        PPhDPT: parseInt((x.pph?x.pph:0)),
        pphDPT: parseInt((x.pph?x.pph:0)),
        NoBukti: x.nomorBpOrFaktur,
        TglBukti: x.transaksi.tanggal,
        Alamat: x.transaksi.transaksiLawanTransaksi.alamat,
        KodeMAP: x.kodeObjekPajak.kodeMap,
        KodeJenis: x.kodeObjekPajak.kjs,
        NTPP: "",
      };
    });

  return {
    DetailLampIIIPPh22: {
      list: pph22Map,
    },
  };
}

async function get_SPT_1771_PPh23(company, periode) {
  var pajakTerutangs = await TransaksiPajakTerutangService.get({
    size: 900000000,
    page: 0,
    sort: "createdDate,DESC",
    "tipeBebanPajak.equals": "Pajak Terutang",
    "tipePajakKode.equals": "Pasal 23/26",
    "periode.equals": periode.id,
  });

  var pph23Map = pajakTerutangs.data
    .filter((x) => x.transaksi)
    .filter((x)=> x.pph > 0)
    .map((x) => {
      return {
        NPWP: x.transaksi.transaksiLawanTransaksi.npwp,
        NMWP: x.transaksi.transaksiLawanTransaksi.nama,
        JnsPenghasilan: x.kodeObjekPajak.jenisPenghasilan?x.kodeObjekPajak.jenisPenghasilan:'',
        ObjPmt: x.dpp,
        PPhDPT: parseInt((x.pph?x.pph:0)),
        pphDPT: parseInt((x.pph?x.pph:0)),
        NoBukti: x.nomorBpOrFaktur,
        TglBukti: x.transaksi.tanggal,
        Alamat: x.transaksi.transaksiLawanTransaksi.alamat,
        KodeMAP: x.kodeObjekPajak.kodeMap,
        KodeJenis: x.kodeObjekPajak.kjs,
        NTPP: "",
      };
    });

  return {
    DetailLampIIIPPh23: {
      list: pph23Map,
    },
  };
}

async function get_SPT_1771_HeaderLampIV(
  company,
  periode,
  labaRugiMap,
  akunTranskrips
) {
  console.log(akunTranskrips, "akunTranskrips");
  return {
    HeaderLampIV: {
      A13: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "BUNGA DEPOSITO / TABUNGAN,  DAN DISKONTO SBI / SBN"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A14: 0,
      // A15: 0,
      A23: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "BUNGA DEPOSITO / TABUNGAN,  DAN DISKONTO SBI / SBN"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A24: 0,
      // A25: 0,
      A33: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "PENGHASILAN PENJUALAN SAHAM   YANG DIPERDAGANGKAN DI BURSA EFEK"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A34: 0,
      // A35: 0,
      A43: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "PENGHASILAN PENJUALAN SAHAM MILIK PERUSAHAAN MODAL VENTURA"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A44: 0,
      // A45: 0,
      A53: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "PENGHASILAN USAHA PENYALUR / DEALER / AGEN PRODUK BBM"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A54: 0,
      // A55: 0,
      A63: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "PENGHASILAN PENGALIHAN HAK ATAS TANAH / BANGUNAN"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A64: 0,
      // A65: 0,
      A73: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "PENGHASILAN PENJUALAN SAHAM MILIK PERUSAHAAN MODAL VENTURA"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A74: 0,
      // A75: 0,
      A8a3: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL["PELAKSANA KONSTRUKSI"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A8a4: 0,
      // A8a5: 0,
      A8b3: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL["PERENCANA KONSTRUKSI"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A8b4: 0,
      // A8b5: 0,
      A8c3: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL["PENGAWAS KONSTRUKSI"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A8c4: 0,
      // A8c5: 0,
      A93: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL["PERWAKILAN DAGANG ASING"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A94: 0,
      // A95: 0,
      A103: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL["PELAYARAN / PENERBANGAN ASING"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A104: 0,
      // A105: 0,
      A113: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL["PELAYARAN DALAM NEGERI"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A114: 0,
      // A115: 0,
      A123: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL["PENILAIAN KEMBALI AKTIVA TETAP"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A124: 0,
      // A125: 0,
      A133: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId === KELOMPOK_PENGHASILAN.Final &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN.FINAL[
                "TRANSAKSI DERIVATIF YANG DIPERDAGANGKAN DI BURSA"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // A134: 0,
      // A135: 0,
      // JBA: 0,
      B13: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN["Bukan Objek Pajak"] &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN["Bukan Objek Pajak"]["BANTUAN / SUMBANGAN"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      B23: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN["Bukan Objek Pajak"] &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN["Bukan Objek Pajak"].HIBAH
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      B33: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN["Bukan Objek Pajak"] &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN["Bukan Objek Pajak"][
                "DIVIDEN / BAGIAN LABA DARI PENYERTAAN MODAL ADA BADAN USAHA DI INDONESIA (Pasal 4 Ayat (3) Huruf f UU PPh)"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      B43: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN["Bukan Objek Pajak"] &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN["Bukan Objek Pajak"][
                "IURAN DAN PENGHASILAN TERTENTU YANG DITERIMA DANA PENSIUN"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      B53: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN["Bukan Objek Pajak"] &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN["Bukan Objek Pajak"][
                "BAGIAN LABA YANG DITERIMA PERUSAHAAN MODAL VENTURA DARI BADAN PASANGAN USAHA"
              ]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      B63: akunTranskrips
        .filter(
          (x) =>
            x.akunData.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN["Bukan Objek Pajak"] &&
            x.akunData.tipePenghasilanLamp1771IVId ===
              TP_PENGHASILAN["Bukan Objek Pajak"]["SISA LEBIH YANG DITERIMA"]
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // JBB: 0,
    },
  };
}

async function get_SPT_1771_HeaderLampKhus1A(
  company,
  periode,
  labaRugiMap,
  akunTranskrips
) {
  return {
    HeaderLampKhus1A: {
      // JmlSutFis: 0,
      JmlSutKom: akunTranskrips
        .filter(
          (x) =>
            x.akunData.akunJenisId === AKUN_JENIS_MAP["Akun Beban Penyusutan"].id
        )
        .reduce((pv, cv) => {
          pv = pv + cv.nilaiAkhir;
          return pv;
        }, 0),
      // JmlSelSut: 0,
      // JmlAmFis: 0,
      JmlAmKom: akunTranskrips
      .filter(
        (x) =>
          x.akunData.akunJenisId === AKUN_JENIS_MAP["Akun Beban Amortisasi"].id
      )
      .reduce((pv, cv) => {
        pv = pv + cv.nilaiAkhir;
        return pv;
      }, 0),
      // JmlSelAm: 0,
    },
  };
}

async function get_SPT_1771_DetailLampVPengurus() {
  var company = JSON.parse(localStorage.getItem("sip-company-active"));
  var entitas = await EntitasService.http.get(
    `/entitas?tipeEntitas.equals=HO&perusahaanId.equals=${company.id}`
  );

  var dataEntitas = entitas.data[0].pics.map((d) => {
    return {
      NPWP: d.npwp,
      Nama: d.nama,
      Alamat: d.alamat,
      Nm_Jab: d.jabatan
    }
  })

  if(entitas.data[0].pics.length <= 0){
    return {
      DetailLampVPengurus: {
        list: []
      }
    };
  } else {
    return {
      DetailLampVPengurus: {
        list: dataEntitas
      }
    }
  }
}

async function get_SPT_1771_DetailLampVPemegangSaham(sipStore) {
  var detailModal = await DetailModalService.http.get(
    `/detail-modals?periodeFiskalId.equals=${sipStore.periodeId}`
  )

  var dataDetailModal = detailModal.data.map((d) => {
    return {
      NPWPSaham: d.npwp,
      Nama: d.nama,
      Alamat: d.alamat,
      JmlMdlRp: d.nilai
    }
  })

  if(detailModal.data.length <= 0){
    return {
      DetailLampVPemegangSaham: {
        list: []
      }
    };
  } else {
    return {
      DetailLampVPemegangSaham: {
        list: dataDetailModal
      }
    }
  }
}

async function get_SPT_1771_DetailLampKhus1A() {
  var company = JSON.parse(localStorage.getItem("sip-company-active"));
  var aset = await AsetService.http.get(
    `/asets?size=9999999&page=0&sort=createdDate,DESC&startDate=&endDate=&perusahaanId.equals=${company.id}&typeAsetId.in=00000000-69ec-21eb-b201-1514cc5a2992,00000001-69ec-21eb-b301-1514cc5a2992`
  )

  var metodePenyusutan = await MetodePenyusutanService.http.get(
    `/metode-penyusutans`
  )
  var metodePenyusutanMap = metodePenyusutan.data.reduce((pv, cv) => {
    pv[cv.id] = cv;
    return pv;
  }, {});

  var fixedAset = await FixedAsetService.http.get(
    `/kategori-asets?perusahaanId.equals=${company.id}`
  )
  var fixedAsetMap = fixedAset.data.reduce((pv, cv) => {
    pv[cv.id] = cv;
    return pv;
  }, {});

  console.log(aset, 'aset', fixedAsetMap)

  var dataAset = aset.data.map((d) => {
    return {
      JnsHarta: parseInt(fixedAsetMap[d.kategoriAsetId].tipeAset.kode),
      KelHarta: fixedAsetMap[d.kategoriAsetId].kelompokAset.kode.charAt(1),
      JnsKelHarta: d.nama,
      BlnPerolehan: d.masaPerolehan,
      ThnPerolehan: d.tahunPerolehan,
      HrgPerolehan: d.hargaTotalPerolehan,
      NlSisa: parseInt(d.nilaiBuku),
      MetSusutKom: d.metodePenyusutanKomersial,
      MetSusutFis: metodePenyusutanMap[d.metodePenyusutanFiskalId].tipeMetodePenyusutan.nama,
      SusutFiskal: parseInt(d.nilaiPenyusutanFiskal),
      Catatan: d.keterangan
    }
  })

  if(aset.data.length <= 0){
    return {
      DetailLampKhus1A: {
        list: []
      }
    };
  } else {
    return {
      DetailLampKhus1A: {
        list: dataAset
      }
    }
  }
}

async function get_SPT_1771_DetailLampKhus1A2() {
  var company = JSON.parse(localStorage.getItem("sip-company-active"));
  var aset = await AsetService.http.get(
    `/asets?size=9999999&page=0&sort=createdDate,DESC&startDate=&endDate=&perusahaanId.equals=${company.id}&typeAsetId.equals=00000002-69ec-21eb-b401-1514cc5a2992`
  )

  var metodePenyusutan = await MetodePenyusutanService.http.get(
    `/metode-penyusutans`
  )
  var metodePenyusutanMap = metodePenyusutan.data.reduce((pv, cv) => {
    pv[cv.id] = cv;
    return pv;
  }, {});

  var fixedAset = await FixedAsetService.http.get(
    `/kategori-asets?perusahaanId.equals=${company.id}`
  )
  var fixedAsetMap = fixedAset.data.reduce((pv, cv) => {
    pv[cv.id] = cv;
    return pv;
  }, {});

  var dataAset = aset.data.map((d) => {
    return {
      JnsHarta: fixedAsetMap[d.kategoriAsetId].tipeAset.nama,
      KelHarta: fixedAsetMap[d.kategoriAsetId].kelompokAset.nama,
      JnsKelHarta: d.nama,
      BlnPerolehan: d.masaPerolehan,
      ThnPerolehan: d.tahunPerolehan,
      HrgPerolehan: d.hargaTotalPerolehan,
      NlSisa: d.nilaiBuku,
      MetSusutKom: d.metodePenysusutanKomersial,
      MetSusutFis: metodePenyusutanMap[d.metodePenyusutanFiskalId].tipeMetodePenyusutan.nama,
      SusutFiskal: d.susutFiskal,
      Catatan: d.keterangan
    }
  })

  if(aset.data.length <= 0){
    return {
      DetailLampKhus1A2: {
        list: []
      }
    };
  } else {
    return {
      DetailLampKhus1A2: {
        list: dataAset
      }
    }
  }
}

async function get_SPT_1771_DetailLampKhus5A() {
  var company = JSON.parse(localStorage.getItem("sip-company-active"));
  var entitas = await EntitasService.http.get(
    `/entitas?tipeEntitas.equals=Cabang&perusahaanId.equals=${company.id}`
  );

  var cabangPembantu = await EntitasService.http.get(
    `/entitas?tipeEntitas.equals=Cabang+Pembantu&perusahaanId.equals=${company.id}`
  );

  var dataEntitas = entitas.data.map((d) => {
    return {
      NmcabUtm: d.nama,
      AlmtCabUtm: d.alamat,
      NPWP: d.npwp,
      JmlCabPbt: cabangPembantu.data.filter(x => x.parentId == d.id).length
    }
  })

  if(entitas.data.length <= 0){
    return {
      DetailLampKhus5A: {
        list: []
      }
    };
  } else {
    return {
      DetailLampKhus5A: {
        list: dataEntitas
      }
    }
  }
}
