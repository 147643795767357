import React, { useEffect, useState } from "react";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./LawanTransaksi.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import { DataForm } from "../../../libs/react-mpk/components";
import { FormWrapper, TableWrapper } from "../../../libs/react-mpk/wrapper";

var _baseId = "mod-table-LawanTransaksi";

const LawanTransaksiTable = ({
  className = "",
  showCommandbar = true,
  history,
  sipStore,
  navigationStore,
  authStore,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});

  const [uploadModal, setUploadModal] = useState(false);

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  function _closeUploadModal() {
    setUploadModal(false);
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title="Lawan Transaksi"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "NPWP Lawan Transaksi",
            searchable: true,
            key: "npwp",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.npwp,
          },
          {
            label: "Kode Lawan Transaksi",
            searchable: true,
            key: "supplierId",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.supplierId,
          },
          {
            label: "Nama Lawan Transaksi",
            searchable: true,
            key: "nama",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nama,
          },
          {
            label: "PIC Penanggung Jawab",
            searchable: true,
            key: "lawanTransaksiPICS",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => {
              var items = item.lawanTransaksiPICS;
              if (!items) items = [];
              return (
                <div>
                  {items.map((v, i) => {
                    return <p key={i}>{v.nama}</p>;
                  })}
                </div>
              );
            },
          },
          {
            label: "No Telepon",
            searchable: true,
            key: "telepon",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.telepon,
          },
          {
            label: "Email",
            searchable: true,
            key: "email",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.email,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => history.push("/onpremise/lawan-transaksi/baru"),
            authStore.user.roleMap
              ? authStore.user.roleMap["Lawan Transaksi"].Create
              : false
          ),
          new TableWrapper.action('Import Lawan Transaksi', 'mdi mdi-import', () => setUploadModal(true), true),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => history.push(`/onpremise/lawan-transaksi/${item.id}`),
            authStore.user.roleMap
              ? authStore.user.roleMap["Lawan Transaksi"].Edit
              : false
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            authStore.user.roleMap
              ? authStore.user.roleMap["Lawan Transaksi"].Delete
              : false
          ),
        ]}
        onFetchData={async (query) => {
          if (!query) query = {};
          if (sipStore.companyId) {
            query["perusahaanId.equals"] = sipStore.companyId;
          }
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
      <DataForm
        baseId={"lawan-import"}
        asDialog={true}
        defaultData={{}}
        definitions={[
          {
            inputType: FormWrapper.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            theme: "filled",
            style: { width: 200 },
          },
        ]}
        visible={uploadModal}
        submitLabel="Import Data"
        onSubmit={async (d, callback) => {
          var formData = new FormData();
          formData.set("file", d.file);
          var uploadRes = await service.http.post("/upload", formData);
          if (uploadRes.data) {
            var importRes = await service.http.post("/lawan-transaksi-import", {
              nama: uploadRes.data.name,
              origin: uploadRes.data.origin,
              perusahaanId: sipStore.companyId,
              type: d.jenis,
              ext: "xls",
            });
          }
          callback(
            "Data sedang di import. Mohon pantau progress import di menu log import.",
            false
          );
        }}
        onRequestClose={_closeUploadModal}
      />
    </>
  );
};

export default inject(
  "sipStore",
  "navigationStore",
  "authStore"
)(observer(LawanTransaksiTable));
