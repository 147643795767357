import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './MigrasiTranskrip.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'

const MigrasiTranskripForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [perusahaanList, setPerusahaanList] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      // await _getEntitasList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getPerusahaanList() {
    var res = PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
    noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/migrasi-transkrip`}
      baseId="mod-form-sample"
      title='Migrasi'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaaniId',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },{
          inputType: inputTypes.INPUT,
          label: 'Tahun',
          key: 'tahun',
          type: 'number',
          width: '100%',
          required: true,
          validation: 'required'
        },
        // {
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 A1',
        //   key: 'pph21A1Created',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Final',
        //   key: 'pph21FinalCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Masa',
        //   key: 'pph21MasaCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Tidak Final',
        //   key: 'pph21TidakFinalCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'Slip Gaji',
        //   key: 'slipGajiCreated',
        //   type: 'text',
        //   required: true,
        // },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          console.log(entitasMap, 'asd')
          data = Utils.formNormalize(data)
          data.tahunPajak = parseInt(data.tahunPajak)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/migrasi-transkrip')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(MigrasiTranskripForm))
