import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Error from './pages/Error';
import Internal from './pages/Internal.routes';
import Product from './pages/Product/Product.routes';
import Onpremise from './pages/Onpremise.routes';
import IndustriRoute from './pages/Industri.routes';
import PPhRoute from './pages/Pph.routes';
import OpLogin from './modules/OpLogin/OpLogin'
import OpResetPassword from './modules/OpLogin/OpResetPassword'
import OpRegister from './modules/OpLogin/OpRegister'
import OpResetForm from './modules/OpLogin/OpResetForm'
import UpdateLicenseForm from './modules/OpLogin/UpdateLicenseForm';
import PPhUnifikasiRoute from './pages/PphUnifikasi.routes';

const routes = () => (
  <Switch>
    <Route path="/license" render={(props) => (
      <UpdateLicenseForm {...props}/>
    )}/>
    <Route path="/reset-password-form" render={(props) => (
      <OpResetForm {...props}/>
    )}/>
    <Route path="/register" render={(props) => (
      <OpRegister {...props}/>
    )}/>
    <Route path="/reset-password" render={(props) => (
      <OpResetPassword {...props}/>
    )}/>
    <Route path="/login" render={(props) => (
      <OpLogin {...props}/>
    )}/>
    <Route path="/internal" render={(props) => (
      <Internal {...props}/>
    )}/>
    <Route path="/product" render={(props) => (
      <Product {...props}/>
    )}/>
    <Route path="/onpremise" render={(props) => (
      <Onpremise {...props}/>
    )}/>
    <Route path="/industri-menu" render={(props) => (
      <IndustriRoute {...props}/>
    )}/>
    <Route path="/pph-menu" render={(props) => (
      <PPhRoute {...props}/>
    )}/>
    <Route path="/pph-unifikasi-menu" render={(props) => (
      <PPhUnifikasiRoute {...props}/>
    )}/>
    <Route path="/error" render={(props) => (
      <Error {...props}/>
    )}/>
  </Switch>
);

export default routes
