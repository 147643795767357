import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './AkunPengenaanPajak.service'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import Utils from '../../../libs/custom/Utils'
import { KELOMPOK_PENGHASILAN_MAP } from '../../../clientConstant'

const AkunPengenaanPajakForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [akunList, setAkun] = useState([])
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      await _getAkun()
      await _getKodeObjekPajak()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {perusahaanId: sipStore.companyId, dasarHukum: '', keterangan: '', kops: [], kondisis: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data.kops = res.data.kops.map(d => Utils.formSerialize(d))
      res.data.kondisis = res.data.kondisis.map(d => Utils.formSerialize(d))
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getKodeObjekPajak() {
    var resCurrentAkun = await AkunService.getOne(match.params.akunId)

    var res = await KodeObjekPajakService.get({size: 100000})
    var kategoriPendapatan = ""
    if(resCurrentAkun.data && resCurrentAkun.data.kelompokPenghasilanId) {
      kategoriPendapatan = KELOMPOK_PENGHASILAN_MAP[resCurrentAkun.data.kelompokPenghasilanId]?KELOMPOK_PENGHASILAN_MAP[resCurrentAkun.data.kelompokPenghasilanId].nama:{}
    }

    console.log(kategoriPendapatan, 'asdasd')

    if(kategoriPendapatan == "Final" || kategoriPendapatan == "Tidak Final") {
      setKodeObjekPajak(res.data.filter(x => x.kategoriPendapatan == kategoriPendapatan).map(x => ({value: x.id, label: x.nama})))
    } else {
      setKodeObjekPajak(res.data.map(x => ({value: x.id, label: x.kode +" "+ x.nama})))
    }

  }

  async function _getAkun() {
    var res = await AkunService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId})
    setAkun(res.data.map(x => ({value: x.id, label: x.nomor +" "+x.nama})))



  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/coa/'+match.params.akunId+'/akun-pajak'}
      baseId="mod-form-sample"
      title={'Pengenaan Pajak'}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'Kondisi',
          key: 'kondisis',
          // hintMessage: 'Daftar Tarif',
          // layout: 'table',
          defaultData: {
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.REACT_SELECT,
              label: 'Lawan Akun',
              key: 'lawanAkun__id',
              validation: 'required',
              // theme: 'filled',
              options: akunList,
              // style: {width: 300},
              width: '49%',
              style: {marginRight: '2%'},
              required: true
            },
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Posisi',
              key: 'posisiLawanAkun',
              validation: 'required',
              // theme: 'filled',
              options:[{value: 'D', label: 'Debit'}, {value: 'K', label: 'Kredit'}],
              width: '49%',
              style: { marginTop: 20},
              required: true
            },
          ]
        },
        { inputType: FormWrapper.inputTypes.DIVIDER},
        {
          inputType: FormWrapper.inputTypes.SELECT,
          label: 'Posisi Wajib Pajak',
          key: 'posisiAkun',
          theme: 'filled',
          options: [{value: 'Penjual', label: 'Yang menyerahkan barang/jasa atau menerima pembayaran'}, {value: 'Pembeli', label: 'Yang menerima barang/jasa atau melaksanakan pembayaran'}],
          required: true
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'KOP',
          key: 'kops',
          // hintMessage: 'Daftar Tarif',
          defaultData: {
          },
          // layout: 'table',
          definitions: [
            {
              inputType: FormWrapper.inputTypes.REACT_SELECT,
              label: 'kodeObjekPajak',
              key: 'kodeObjekPajak__id',
              theme: 'filled',
              validation: 'required',
              options: kodeObjekPajakList,
              // style: {width: 500},
              required: true
            }
          ]
        },
        { inputType: FormWrapper.inputTypes.DIVIDER},
        {
          inputType: FormWrapper.inputTypes.SELECT,
          label: 'Terutang Saat',
          key: 'terutangSaat',
          theme: 'filled',
          options:[
            {value: 'Disediakan untuk pembayaran', label: 'Terutang saat disediakan untuk pembayaran'},
            {value: 'Pembayaran', label: 'Terutang saat pembayaran'},
            {value: 'Jatuh tempo', label: 'Terutang saat jatuh tempo'},
          ],
          required: true
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: 'Dasar Hukum',
          key: 'dasarHukum',
        },
        {
          inputType: FormWrapper.inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        if(data.kondisis <= 0) {
          callback({message: 'Kondisi Jurnal wajib di isi'}, true)
          return true
        }
        if(data.kops <= 0) {
          callback({message: 'KOP wajib di isi'}, true)
          return true
        }

        var res = {}
        try {
          data.akunId = match.params.akunId
          data.kops = data.kops.map(d => Utils.formNormalize(d))
          data.kondisis = data.kondisis.map(d => Utils.formNormalize(d))
          data = Utils.formSerialize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/coa/'+match.params.akunId+'/akun-pajak')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

// export default inject('envStore')(observer(AkunPengenaanPajakForm))
export default inject('sipStore', 'navigationStore')(observer(AkunPengenaanPajakForm))
