import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes} from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './DataSilk.service'
import Utils from '../../../libs/custom/Utils'

const SilkForm = ({envStore, match, history, sipStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      var company = JSON.parse(localStorage.getItem("sip-company-active"));
      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
          tipeIndustriId: company.tipeIndustri.id
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/data-silk`}
      baseId="mod-form-sample"
      title='Data Silk'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Masa Awal',
          key: 'masaAwal',
          type: 'text',
          style: {marginRight: '2%'},
          width: '49%',
          required: true,
          validation: 'required',
          // disabled: isEdit,
          options: [
            {value: '1', label: '1'},
            {value: '2', label: '2'},
            {value: '3', label: '3'},
            {value: '4', label: '4'},
            {value: '5', label: '5'},
            {value: '6', label: '6'},
            {value: '7', label: '7'},
            {value: '8', label: '8'},
            {value: '9', label: '9'},
            {value: '10', label: '10'},
            {value: '11', label: '11'},
            {value: '12', label: '12'}
          ]
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Masa Akhir',
          key: 'masaAkhir',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          // disabled: isEdit,
          options: [
            {value: '1', label: '1'},
            {value: '2', label: '2'},
            {value: '3', label: '3'},
            {value: '4', label: '4'},
            {value: '5', label: '5'},
            {value: '6', label: '6'},
            {value: '7', label: '7'},
            {value: '8', label: '8'},
            {value: '9', label: '9'},
            {value: '10', label: '10'},
            {value: '11', label: '11'},
            {value: '12', label: '12'}
          ]
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Laporan',
          key: 'jenisLaporan',
          type: 'text',
          options: [
            { label: 'Financial Statement Reporting', value: 'fr' },
            { label: 'Fiscal Correction - Detail Profit And Loss', value: 'fcdpl' }
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.tipeIndustriId = sipStore.company.tipeIndustri.id
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/data-silk')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore', 'sipStore')(observer(SilkForm))
