import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TarifProgresifPasal17.service'
import Utils from '../../../libs/custom/Utils'

const TarifProgresifPasal17Form = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      if(match.params.id == 'baru') {
        setData({loading: false, content: {details:[]}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  return (
    <FormWrapper
      noValidate
      customRules={{
        tahunMulaiBerlaku: 'required|size:4',
        tahunAkhirBerlaku: 'required|size:4'
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/tarif-progresif-pasal-17`}
      baseId="mod-form-sample"
      title={t.translate('modules.TarifProgresifPasal17.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT_MASK,
          label: 'Mulai Berlaku (Tahun)',
          key: 'tahunMulaiBerlaku',
          width: '49%',
          style: {marginRight: '2%'},
          mask: '****',
          alwaysShowMask: true,
          disabled: isEdit,
          allowNegative: false
        },{
          inputType: inputTypes.INPUT_MASK,
          label: 'Akhir Berlaku (Tahun)',
          key: 'tahunAkhirBerlaku',
          width: '49%',
          mask: '****',
          alwaysShowMask: true,
          disabled: isEdit,
          allowNegative: false
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'Detail',
          key: 'details',
          hintMessage: 'Daftar Tarif',
          layout: 'table',
          defaultData: {
            tarifPajak: 0,
            keterangan: '-'
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
              label: 'Dari (Rp)',
              key: 'dari',
              theme: 'filled',
              validation: 'required|numeric',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,

            },
            {
              inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
              label: 'Ke (Rp)',
              key: 'ke',
              theme: 'filled',
              validation: 'required|numeric',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
            },
            {
              inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
              label: 'Tarif Pajak (%)',
              key: 'tarif',
              theme: 'filled',
              validation: 'required|numeric',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
            }
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formSerialize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/tarif-progresif-pasal-17')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(TarifProgresifPasal17Form))
