import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PenyusutanPeriode.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import PeriodeFiskalService from '../PeriodeFiskal/PeriodeFiskal.service'

const PenyusutanPeriodeForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [perusahaanList, setPerusahaanList] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [periodeFiskalList, setPeriodeFiskalList] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      // await _getEntitasList()
      await _getPeriodeFiskalList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
          tahun: sipStore.periodeId,
          penyusutanKomersial: 0,
          penyusutanFiskal: 0,
          koreksiFiskal: 0
        }})

        // console.log( sipStore.companyId, ' sipStore.companyId')
        return true
      } else {
        const res = await service.getOne(match.params.id)
        res.data = Utils.formSerialize(res.data)
        res.data.tahun = res.data.periodeFiskal.id
        setData({loading: false, content:res.data})
        setIsEdit(true)
      }
    }
    initData()
  }, ['match.params.id'])

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPeriodeFiskalList() {
    var res = await PeriodeFiskalService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId})
    setPeriodeFiskalList(res.data.map(x => ({value: x.id, label: x.tahun})))
  }

  return (
    <FormWrapper
    noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/penyusutan-periode`}
      baseId="mod-form-sample"
      title='Penyusutan & Amortisasi'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },{
          inputType: inputTypes.SELECT,
          label: 'Tahun',
          key: 'tahun',
          type: 'text',
          disabled: isEdit,
          validation: 'required',
          options: periodeFiskalList,
          disabled: sipStore.periodeId != null
        },{
          inputType: inputTypes.SELECT,
          label: 'Masa',
          key: 'masa',
          type: 'text',
          width: '100%',
          required: true,
          validation: 'required',
          options: [
            {value: '1', label: '1'},
            {value: '2', label: '2'},
            {value: '3', label: '3'},
            {value: '4', label: '4'},
            {value: '5', label: '5'},
            {value: '6', label: '6'},
            {value: '7', label: '7'},
            {value: '8', label: '8'},
            {value: '9', label: '9'},
            {value: '10', label: '10'},
            {value: '11', label: '11'},
            {value: '12', label: '12'}
          ]
        },
        // {
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 A1',
        //   key: 'pph21A1Created',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Final',
        //   key: 'pph21FinalCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Masa',
        //   key: 'pph21MasaCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'PPh 21 Tidak Final',
        //   key: 'pph21TidakFinalCreated',
        //   type: 'text',
        //   required: true,
        // },{
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'Slip Gaji',
        //   key: 'slipGajiCreated',
        //   type: 'text',
        //   required: true,
        // },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        var p = JSON.parse(localStorage.getItem("sip-periode-active"));
        try {
          console.log(entitasMap, 'asd')
          data = Utils.formNormalize(data)
          data.tahunPajak = parseInt(data.tahunPajak)
          data.tahun = p.tahun
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/penyusutan-periode')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(PenyusutanPeriodeForm))
