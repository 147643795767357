import { cloneDeep } from "lodash"

const base_url = '/api/v1'
const user ={
  id: 75,
  username: 'sunny',
  name: 'Ismail Sunny',
  email: 'ismiletea@gmail.com',
  mobilePhone: '085221942971',
  activationKey: 'pjRLEy1vm0kwgH9g8qvD',
  expiredKey: '2019-11-14T11:22:52.469',
  servicePermission: [
    {
      id: 711,
      name: 'Get Dashboard Chart',
      code: 'DC-01',
      description: 'Get Dashboard Chart',
      method: 'GET',
      clientPath: '/dashboard/chart',
      url: '/dashboard/chart',
      baseUrl: base_url,
      backendServers: [
        {
          id: 313,
          applicationId: 234,
          name: 'Server Local',
          host: 'http://192.168.3.17:9100',
          baseUrl: '/api'
        }
      ],
      parameters: []
    }
  ],
  permission: [],
  role: {
    name: 'ROLE_USER',
    isAdmin: false
  },
  application: {
    id: 234,
    userId: 3,
    applicationTypeId: 6,
    name: 'Template SPA Internal',
    description: 'Template SPA Internal',
    baseUrl: base_url,
    createdBy: 'dwiargo',
    createdAt: '2020-12-15T10:48:57.609',
    updatedBy: 'dwiargo',
    updatedAt: '2021-01-07T12:33:43.684'
  },
  isActive: true,
  isDelete: false,
  createdBy: 'sunny',
  createdAt: '2019-11-13T11:22:52.469',
  updatedBy: 'sunny',
  updatedAt: '2020-07-01T11:27:41.104',
  menu: [
    // {
    //   label: 'Group 1',
    //   defaultCollapse: true
    // },
    // {
    //   label: 'Menu Lv 1',
    //   children: [
    //     {
    //       label: 'Negara',
    //       index: 'country, negara',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/negara',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Akun',
    //       index: 'tipe, akun',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipeAkun',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Hubungan Istimewa',
    //       index: 'tipe, hubungan, istimewa',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipeHubunganIstimewa',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Industri',
    //       index: 'tipe, industri',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipeIndustri',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Pajak',
    //       index: 'tipe, pajak',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipePajak',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Bupot',
    //       index: 'tipe, bupot',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipeBupot',
    //       childPath: []
    //     },
    //     {
    //       label: 'Kode Objek Pajak',
    //       index: 'kodeObjekPajak, KodeObjekPajak',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/kode-objek-pajak',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Biaya Lamp 1771 iis',
    //       index: 'tipe, biaya, lampiran, 1771, iis',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipeBiayaLamp1771iis',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Penghasilan Lamp 1771 ivs',
    //       index: 'tipe, penghasilan, lampiran, 1771, ivs',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipePenghasilanLamp1771ivs',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Daftar Normatif',
    //       index: 'tipe, daftar, normatif',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipeDaftarNormatif',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Koreksi Fiskal',
    //       index: 'tipe, koreksi, fiskal',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipeKoreksiFiskal',
    //       childPath: []
    //     },
    //     {
    //       label: 'Kelompok Penghasilan',
    //       index: 'kelompok, penghasilan',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/kelompokPenghasilan',
    //       childPath: []
    //     },
    //     {
    //       label: 'Dashboard',
    //       index: 'dasbor, dashboard',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/dashboard',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tabel',
    //       index: 'table, tabel',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi-table',
    //       path: '/onpremise/table',
    //       childPath: []
    //     },
    //     {
    //       label: 'Form',
    //       index: 'form, formulir',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi-bell',
    //       path: '/onpremise/form',
    //       childPath: []
    //     },
    //     {
    //       label: 'Details',
    //       index: 'details, detail',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi-send',
    //       path: '/onpremise/details/general',
    //       childPath: [
    //         '/onpremise/details/.*'
    //       ]
    //     }
    //   ]
    // },{
    //   label: 'Menu Lv 2',
    //   children: [
    //     {
    //       label: 'Jenis Biaya',
    //       index: 'jenis_biaya, jenis_biaya',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/jenis-biaya',
    //       childPath: []
    //     },
    //     {
    //       label: 'Kategori PPh 25',
    //       index: 'kategori_25, kategori_25',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/kategori-p-ph-25-s',
    //       childPath: []
    //     },
    //     {
    //       label: 'Komponen Penghasilan',
    //       index: 'komponen_penghasilan, komponen_penghasilan',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/komponen-penghasilan',
    //       childPath: []
    //     },
    //     {
    //       label: 'Pengenaan Pajak Lainnya',
    //       index: 'pengenaan_pajak_lainnya, pengenaan_pajak_lainnya',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pengenaan-pajak-lainnya',
    //       childPath: []
    //     },
    //     {
    //       label: 'Penghasilan Spt Tahunan',
    //       index: 'penghasilan_spt, penghasilan_spt',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/penghasilan-spt-tahunan',
    //       childPath: []
    //     }
    //   ]
    // },{
    //   label: 'Menu Lv 3',
    //   children: [
    //     {
    //       label: 'Tarif Biaya',
    //       index: 'tarif_biaya, tarif_biaya',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tarif-biaya',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tarif Progresif',
    //       index: 'tarif_progresif, tarif_progresif',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tarif-progresif',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe Pengecualian Pajak',
    //       index: 'tipe_pengecualian_pajak, tipe_pengecualian_pajak',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipe-pengecualian-pajak',
    //       childPath: []
    //     },
    //     {
    //       label: 'Tipe PIC',
    //       index: 'tipe_pic, tipe_pic',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipe-pic',
    //       childPath: []
    //     },
    //   ]
    // },{
    //   label: 'Menu Lv 4',
    //   children: [
    //     {
    //       label: 'Tipe Pengurang Pajak',
    //       index: 'tipe_pengurang_pajak',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipe-pengurang-pajak',
    //       childPath: []
    //     },{
    //       label: 'Pengenaan Pajak Akun',
    //       index: 'pengenaan_pajak_akun, pengenaan_pajak_akun',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pengenaan-pajak-akun',
    //       childPath: []
    //     },{
    //       label: 'Tipe Status Pajak',
    //       index: 'tipe_status_pajak, tipe_status_pajak',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipe-status-pajak',
    //       childPath: []
    //     },{
    //       label: 'Status Pajak Pembeli',
    //       index: 'status_pajak_pembeli, status_pajak_pembeli',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/status-pajak-pembeli',
    //       childPath: []
    //     },{
    //       label: 'Status Pajak Penjual',
    //       index: 'status_pajak_penjual, status_pajak_penjual',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/status-pajak-penjual',
    //       childPath: []
    //     }
    //   ]
    // },{
    //   label: 'Menu Lv 5',
    //   children: [
    //     {
    //       label: 'Tarif',
    //       index: 'tarif, tarif',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tarif',
    //       childPath: []
    //     },{
    //       label: 'Pengenaan Pajak',
    //       index: 'pengenaan_pajak, pengenaan_pajak',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pengenaan-pajak',
    //       childPath: []
    //     },{
    //       label: 'Kelompok Aset',
    //       index: 'kelompok_aset, kelompok_aset',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/kelompok-aset',
    //       childPath: []
    //     },{
    //       label: 'Tipe Aset',
    //       index: 'tipe_aset, tipe-aset',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/status-pajak-pembeli',
    //       childPath: []
    //     },{
    //       label: 'Tipe Bisnis Aset',
    //       index: 'tipe_bisnis_aset, tipe_bisnis_aset',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/tipe-bisnis-aset',
    //       childPath: []
    //     }
    //   ]
    // },
    {
      label: 'MAIN',
      global: true,
      children: [
        {
          label: 'Dashboard',
          index: 'dashboard, dashboard',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/dashboard',
          permissionName: 'Dashboard',
          childPath: [],
          adminPajak: true,
          user: true,
        },
        {
          label: 'Ekualiasasi',
          index: 'dashboard, dashboard',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/ekualisasi',
          permissionName: 'Dashboard',
          childPath: [],
          adminPajak: true,
          user: true,
        },
        {
          label: 'SPT Proforma PPh',
          index: 'proforma, pph',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/proforma-pph',
          permissionName: 'Dashboard',
          childPath: [],
          adminPajak: true,
          user: true,
        },
        {
          label: 'Period',
          index: 'period, period',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/periode-fiskal',
          permissionName: 'Period',
          childPath: []
        }
      ]
    },
    {
      label: 'ADMIN PAJAKKU',
      defaultCollapse: true,
      adminPajak: true
    },
    {
      label: 'MASTER DATA',
      adminPajak: true,
      children: [
        {
          label: 'Perusahaan',
          index: 'company, perusahaan',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/perusahaan',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Industri',
          index: 'industri, industri',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/industri',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Kode Negara',
          index: 'country, negara',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/negara',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Mapping Integrasi SPT 1771',
          index: 'master_mapping_spt_1771, master_mapping_spt_1771',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/master-mapping-spt-1771',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Sektor',
          index: 'sektor, sektor',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/sektor',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Sub Sektor',
          index: 'sub-sektor, sub-sektor',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/sub-sektor',
          adminPajak: true,
          childPath: []
        },{
          label: 'Informasi Pemegang Saham',
          index: 'informasi-pemegang-saham, informasi-pemegang-saham',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/informasi-pemegang-saham',
          adminPajak: true,
          childPath: []
        },
        // {
        //   label: 'Pengguna',
        //   index: 'users, users',
        //   resourceUri: '',
        //   iconClassName: 'mdi mdi mdi-view-dashboard',
        //   path: '/onpremise/testing',
        //   adminPajak: true,
        //   childPath: []
        // },
      ]
    },
    {
      label: 'SETTING PAJAK PPH 21',
      adminPajak: true,
      children: [
        {
          label: 'Batas Upah Harian',
          index: 'batas_upah_harian, batas_upah_harian',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/batas-upah-harian',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Biaya Jabatan',
          index: 'biaya_jabatan, biaya_jabatan',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/biaya-jabatan',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Biaya Pensiun',
          index: 'biaya_pensiun, biaya_pensiun',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/biaya-pensiun',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'PTKP',
          index: 'ptkp, ptkp',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/ptkp',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Tarif Progresif Pasal 17',
          index: 'tp_pasal17, tp_pasal17',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/tarif-progresif-pasal-17',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Tarif Progresif Pesangon',
          index: 'tp_pesangon, tp_pesangon',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/tarif-progresif-pesangon',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Tarif Progresif Pensiun',
          index: 'tp_pensiun, tp_pensiun',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/tarif-progresif-pensiun',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Pengaturan Penghasilan',
          index: 'Pengaturan_Penghasilan, Pengaturan_Penghasilan',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/pengaturan-penghasilan-pph',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Pengaturan Pemotongan',
          index: 'Pengaturan_Pemotongan, Pengaturan_Pemotongan',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/pengaturan-pemotongan-pph',
          adminPajak: true,
          childPath: []
        },
      ]
    },
    {
      label: 'SETTING SPT TAHUNAN',
      adminPajak: true,
      children: [{
        label: 'Tarif PPh 29',
        index: 'tarif_29, tarif_29',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        adminPajak: true,
        path: '/onpremise/tarif-pph-29',
        childPath: []
      },{
        label: 'Tarif PPh 25',
        index: 'tarif_25, tarif_25',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        adminPajak: true,
        path: '/onpremise/tarif-pph-25',
        childPath: []
      },{
        label: 'Pengaturan Penghasilan',
        index: 'pengaturan-penghasilan, pengaturan-penghasilan',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/pengaturan-penghasilan',
        adminPajak: true,
        childPath: []
      },{
        label: 'Pengaturan Biaya',
        index: 'pengaturan_biaya, pengaturan_biaya',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/pengaturan-biaya',
        adminPajak: true,
        childPath: []
      }]
    },
    {
      label: 'SETTING PAJAK LAINNYA',
      adminPajak: true,
      children: [
        {
          label: 'Kode Objek Pajak',
          index: 'kode_objek_pajak, kode_objek_pajak',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/kode-objek-pajak',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Pengecualian Pajak',
          index: 'pengecualian_pajak, pengecualian_pajak',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/pengecualian-pajak',
          adminPajak: true,
          childPath: []
        },
      ]
    },
    {
      label: 'MASTER KOREKSI FISKAL',
      adminPajak: true,
      children: [
        {
          label: 'Penyesuaian Fiskal Positif',
          index: 'penyesuaian-fiskal-positif, penyesuaian-fiskal-positif',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/penyesuaian-fiskal-positif',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Penyesuaian Fiskal Negatif',
          index: 'penyesuaian-fiskal-negatif, penyesuaian-fiskal-negatif',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/penyesuaian-fiskal-negatif',
          adminPajak: true,
          childPath: []
        },
        {
          label: 'Metode Penyusutan',
          index: 'metode-penyusutan, metode-penyusutan',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          adminPajak: true,
          path: '/onpremise/metode-penyusutan',
          childPath: []
        }
      ]
    },
    {
      label: 'ADMIN PERUSAHAAN',
      defaultCollapse: true
    },

    {
      label: 'AKSES USER',
      adminPajak: true,
      children: [
        {
          label: 'Pengguna',
          index: 'pengguna',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/user',
          childPath: []
        },
        {
          label: 'Role',
          index: 'role',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/role',
          childPath: []
        },
        {
          label: 'Member Perusahaan',
          index: 'add member',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/member',
          childPath: []
        },
      ]
    },
    {
      label: 'MASTER DATA',
      children: [
        // {
        //   label: 'Perusahaan',
        //   index: 'company, perusahaan',
        //   resourceUri: '',
        //   iconClassName: 'mdi mdi mdi-view-dashboard',
        //   path: '/onpremise/perusahaan',
        //   permissionName: 'Perusahaan',
        //   childPath: []
        // },
        {
          label: 'Entitas',
          index: 'entitas, entitas',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/entitas',
          permissionName: 'Entitas',
          childPath: []
        },
        // {
        //   label: 'Lawan Transaksi',
        //   index: 'lawan-transaksi, lawan-transaksi',
        //   resourceUri: '',
        //   iconClassName: 'mdi mdi mdi-view-dashboard',
        //   path: '/onpremise/lawan-transaksi',
        //   childPath: []
        // },
        {
          label: 'COA',
          index: 'coa, coa',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/coa',
          permissionName: 'COA',
          childPath: []
        },
        {
          label: 'Barang & Jasa',
          index: 'barang, jasa',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/barang-dan-jasa',
          permissionName: 'Barang & Jasa',
          childPath: []
        },
        {
          label: 'Jenis Usaha',
          index: 'jenis-usaha, jenis-usaha',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/jenis-usaha',
          permissionName: 'Jenis Usaha',
          childPath: []
        },
        {
          label: 'Fixed Aset',
          index: 'fixed-aset, fixed-aset',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/fixed-aset',
          permissionName: 'Fixed Aset',
          childPath: []
        },

      ]
    },
    // {
    //   label: 'MASTER PERHITUNGAN PPH 21',
    //   children: [
    //     {
    //       label: 'Pendapatan',
    //       index: 'pendapatan, pendapatan',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pendapatan',
    //       permissionName: 'Pendapatan',
    //       children: []
    //     },
    //     {
    //       label: 'Pengurang',
    //       index: 'pengurang, pengurang',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pengurang',
    //       permissionName: 'Pengurang',
    //       children: []
    //     },
    //     {
    //       label: 'Master Level',
    //       index: 'master, level',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/master-level',
    //       permissionName: 'Pendapatan',
    //       children: []
    //     },
        // {
        //   label: 'Pegawai Tetap',
        //   index: 'pegawai-tetap, pegawai-tetap',
        //   resourceUri: '',
        //   iconClassName: 'mdi mdi mdi-view-dashboard',
        //   path: '/onpremise/pegawai-tetap',
        //   children: []
        // },
        // {
        //   label: 'Pegawai Tidak Tetap',
        //   index: 'pegawai-tidak-tetap, pegawai-tidak-tetap',
        //   resourceUri: '',
        //   iconClassName: 'mdi mdi mdi-view-dashboard',
        //   path: '/onpremise/pegawai-tidak-tetap',
        //   children: []
        // }
    //   ]
    // },
    // {
    //   label: 'PENGATURAN',
    //   children: [
    //     {
    //       label: 'Setting Perusahaan',
    //       index: 'setting-perusahaan, setting-perusahaan',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/setting-perusahaan',
    //       childPath: []
    //     },
    //     {
    //       label: 'Setting Koreksi Fiskal',
    //       index: 'setting-koreksi-fiskal, setting-koreksi-fiskal',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/setting-koreksi-fiskal',
    //       childPath: []
    //     },
    //     {
    //       label: 'Setting Konfigurasi',
    //       index: 'setting-konfigurasi, setting-konfigurasi',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/setting-konfigurasi',
    //       childPath: []
    //     }
    //   ]
    // },
    // {
    //   label: 'MAPPING AKUN PAJAK',
    //   children: [
    //     {
    //       label: 'Setting Akun Pajak',
    //       index: 'setting-akun-pajak, setting-akun-pajak',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/setting-akun-pajak',
    //       childPath: []
    //     },
    //     {
    //       label: 'Setting Beban Penyusutan',
    //       index: 'setting-beban-penyusutan, setting-beban-penyusutan',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/setting-beban-penyusutan',
    //       childPath: []
    //     },
    //     {
    //       label: 'Setting Akun Gaji',
    //       index: 'setting-akun-gaji, setting-akun-gaji',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/setting-akun-gaji',
    //       childPath: []
    //     }
    //   ]
    // }
    {
      label: 'USER',
      defaultCollapse: true
    },

    {
      label: 'LAWAN TRANSAKSI',
      children: [
        {
          label: 'Lawan Transaksi',
          index: 'lawan-transaksi',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-dashboard',
          path: '/onpremise/lawan-transaksi',
          permissionName: 'Lawan Transaksi',
          childPath: []
        }
      ]
    },
    // {
    //   label: 'MIGRASI DATA AWAL',
    //   children: [
    //     {
    //       label: 'Migrasi',
    //       index: 'saldo-awal-neraca, saldo-awal-neraca',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/periode-fiskal-saldo-awal',
    //       permissionName: 'Migrasi',
    //       childPath: []
    //     }
    //   ]
    // },
    {
      label: 'FISKAL',
      children: [{
        label: 'Aset',
        index: 'aset, aset',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/aset',
        permissionName: 'Aset',
        childPath: []
      },
      {
        label: 'Penyusutan dan Amortisasi fiskal',
        index: 'penyusutan, penyusutan',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/penyusutan-periode',
        permissionName: 'Penyusutan dan Amortisasi Fiskal',
        childPath: []
      },
      {
        label: 'Sanding Aset',
        index: 'sanding, aset',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/sanding-aset',
        permissionName: 'Sanding Aset',
        childPath: []
      },{
        label: 'Daftar Nominatif Biaya Promosi',
        index: 'daftar-nominatif-biaya-promosi, daftar-nominatif-biaya-promosi',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/daftar-nominatif-biaya-promosi',
        permissionName: 'Transaksi (All)',
        childPath: []
      },{
        label: 'Daftar Nominatif Biaya Entertainment',
        index: 'daftar-nominatif-biaya-entertainment, daftar-nominatif-biaya-entertainment',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/daftar-nominatif-biaya-entertainment',
        permissionName: 'Transaksi (All)',
        childPath: []
      },{
        label: 'Koreksi Fiskal',
        index: 'koreksi, fiskal',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/koreksi-fiskal',
        permissionName: 'Transaksi (All)',
        childPath: []
      },{
        label: 'Kertas Kerja Koreksi Fiskal',
        index: 'Kertas kerja koreksi, fiskal',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/kertas-kerja-koreksi-fiskal',
        permissionName: 'Transaksi (All)',
        childPath: []
      },{
        label: 'Data Silk',
        index: 'silk, silk',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/data-silk',
        permissionName: 'Transaksi (All)',
        childPath: []
      }]
    },
    // {
    //   label: 'PPH 21',
    //   children: [

    //     {
    //       label: 'Transaksi Pemotongan PPh 21/26',
    //       index: 'transaksi-pemotongan-pph-21/26, transaksi-pemotongan-pph-21/26',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pemotongan-pph2126',
    //       permissionName: 'Transaksi Pemotongan PPh 21/26',
    //       childPath: []
    //     },
    //     {
    //       label: 'Pegawai',
    //       index: 'pegawai, pegawai',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pegawai',
    //       permissionName: 'Pegawai',
    //       childPath: []
    //     },
    //     {
    //       label: 'Pendapatan',
    //       index: 'pendapatan, pendapatan',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pendapatan',
    //       permissionName: 'Pendapatan',
    //       children: []
    //     },
    //     {
    //       label: 'Pengurang',
    //       index: 'pengurang, pengurang',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/pengurang',
    //       permissionName: 'Pengurang',
    //       children: []
    //     },
    //     {
    //       label: 'Sanding data PPh21',
    //       index: 'sading, pph21',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-dashboard',
    //       path: '/onpremise/sanding-pph21',
    //       permissionName: 'Sanding Data PPh21',
    //       children: []
    //     },
    //   ]
    // },
    {
      label: 'TRANSAKSI',
      children: [{
        label: 'Transaksi',
        index: 'transaksi, transaksi',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/transaksi',
        permissionName: 'Transaksi (All)',
        childPath: []
      },{
        label: 'Transaksi Penjualan',
        index: 'transaksi, transaksi',
        permissionName: 'Transaksi (All)',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/transaksi-penjualan',
        childPath: []
      },{
        label: 'Transaksi Perjanjian Pembelian/Penjualan',
        index: 'transaksi, Perjanjian Pembelian',
        permissionName: 'Transaksi (All)',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/transaksi-perjanjian-pembelian',
        childPath: []
      },{
        label: 'Transaksi Pembelian',
        index: 'transaksi, pembelian',
        permissionName: 'Transaksi (All)',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/transaksi-pembelian',
        childPath: []
      },{
        label: 'Transaksi Retur Penjualan',
        index: 'transaksi, transaksi',
        permissionName: 'Transaksi (All)',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/retur-penjualan',
        childPath: []
      },{
        label: 'Pajak Terutang',
        index: 'transaksi, transaksi',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/pajak-terutang-menu/pajak_terutang',
        permissionName: 'Transaksi (All)',
        childPath: [],
      },{
        label: 'Pajak Dibayar Dimuka',
        index: 'transaksi, transaksi',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/pajak-terutang-menu/pajak_dibayar_dimuka',
        permissionName: 'Transaksi (All)',
        childPath: [],
      }]
    },
    // {
    //   label: 'SPT 1771',
    //   children: [
    //     {
    //       label: 'SPT 1771',
    //       index: 'role',
    //       resourceUri: '',
    //       iconClassName: 'mdi mdi mdi-view-grid',
    //       path: '/onpremise/spt1771',
    //       permissionName: 'Transaksi (All)',
    //       childPath: []
    //     }
    //   ]
    // },
    {
      label: 'AKSES USER',
      children: [
        {
          label: 'Role',
          index: 'role',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/role',
          permissionName: 'Role',
          childPath: []
        },
        {
          label: 'Member Perusahaan',
          index: 'add member',
          resourceUri: '',
          iconClassName: 'mdi mdi mdi-view-grid',
          path: '/onpremise/member',
          permissionName: 'Tambah Pengguna',
          childPath: []
        },
      ]
    },
    {
      label: 'SUMMARY',
      global: true,
      children: [{
        label: 'Transkrip',
        index: 'transkrip, transkrip',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/transkrip',
        adminPajak: true,
        user: true,
        permissionName: 'Transkrip',
        childPath: []
      }]
    }, {
      label: 'LOG',
      global: true,
      children: [{
        label: 'Log Import',
        index: 'log, import',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/log-import',
        permissionName: 'Aset',
        childPath: [],
        adminPajak: true,
        user: true,
      },{
        label: 'Log Export',
        index: 'log export, export',
        resourceUri: '',
        iconClassName: 'mdi mdi mdi-view-dashboard',
        path: '/onpremise/log-export',
        permissionName: 'Aset',
        childPath: [],
        adminPajak: true,
        user: true,
      }]
    },
  ],
  isSupport: false
}

export const userDefaults = cloneDeep(user)

export default user
