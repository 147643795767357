import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './LogImport.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { List, ListItem } from "@react-md/list";

var _baseId = "mod-table-pemotongan-pph2126"

const LogImportTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  modalStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Log Import'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Nama',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nama)
        },
        {
          label: 'Modul',
          searchable: true,
          key: 'modul',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.modul)
        },{
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        },{
          label: 'Delimiter',
          searchable: true,
          key: 'delimiter',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.delimiter)
        },{
          label: 'Nama File',
          searchable: true,
          key: 'fileName',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.fileName)
        },{
          label: 'Status',
          searchable: true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.status)
        },
        // {
        //   label: 'Pesan',
        //   searchable: true,
        //   key: 'statusMsg',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.statusMsg)
        // },
        {
          label: 'Total Data',
          searchable: true,
          key: 'totalData',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.totalData)
        },{
          label: 'Total Tersimpan',
          searchable: true,
          key: 'totalTersimpan',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.totalTersimpan)
        },{
          label: 'Total Gagal',
          searchable: true,
          key: 'totalGagal',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.totalGagal)
        },{
          label: 'Dibuat Oleh',
          searchable: true,
          key: 'totalGagal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },{
          label: 'Dibuat Tanggal',
          searchable: true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.DATE,
          sortable: true,
          render: item => (moment(item.createdDate).format('DD-MM-YYYY HH:mm:ss'))
        },{
          label: 'Selesai',
          searchable: true,
          key: 'selesai',
          type: TableWrapper.dataTypes.DATE,
          sortable: true,
          render: item => (moment(item.selesai).format('DD-MM-YYYY HH:mm:ss'))
        },
      ]}
      actions={[
        // new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/pemotongan-pph2126/baru'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Download File', 'mdi mdi-download', async (item) => {
          await service.http.download(
            `/log-imports/${item.id}/download`
          );
        }, true),
        new TableWrapper.action('Detail Error', 'mdi mdi-information', (item) => {
          modalStore.showInfo({
            title: "Detail Error",
            children: (
              <div>
                <List>
                  {item.statusMsg.split(", ").map((d, i) => (
                    <ListItem key={i}>{d}</ListItem>
                  ))}
                </List>
              </div>
            ),
            onSubmit: async (callback) => {},
          });
        }, (d)=> d.status === 'Error'),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default inject('sipStore', 'navigationStore', 'modalStore')(observer(LogImportTable))
