import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Akun.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
import DataForm from '../../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../../libs/react-mpk/config/constant'

var _baseId = "mod-table-akun"

const AkunTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  authStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [showDialog, setShowDialog] = useState({item: {}, show: false})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <Dialog
      visible={showDialog.show}
      disableFocusOnMount={() => { }}
      onRequestClose={() => {
        setShowDialog({show: false})
      }}
      style={{
        width: 600
      }}
    >
      <DialogHeader>
        <DialogTitle>Approval</DialogTitle>
      </DialogHeader>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <DataForm
          defaultData={showDialog.item}
          hintShowIcon={true}
          definitions={[
            {
              inputType: inputTypes.SELECT,
              label: 'Status',
              key: 'approvalStatus',
              width: '100%',
              options: [
                {value: 'APPROVED', label: 'APPROVED'},
                {value: 'DRAFT', label: 'DRAFT'}
              ]
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Notes',
              key: "approvalNotes",
              type: "text",
              width: "100%"
            },
          ]}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button style={{ marginRight: 10, float: 'right' }} onClick={() => {
                  setShowDialog({show: false})
                }}>Tutup</Button>
              )
            }
          ]}
          onSubmit={async (values, callback) => {
            try {
              let res = await service.approval([values]);
              callback('Ganti Status Approval Berhasil', false);
              TableWrapper.reload(_baseId)
              setShowDialog({show: false})
            } catch (e) {
              callback('', false, false, false)
            }
          }}>
        </DataForm>
      </DialogContent>
    </Dialog>
    <TableWrapper
      baseId={_baseId}
      title={t.translate('modules.Akun.title')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Approval Status',
          searchable: true,
          key: 'approvalStatus',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.approvalStatus)
        },
        {
          label: 'Nomor',
          searchable: true,
          key: 'nomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nomor)
        },{
          label: 'Nama',
          searchable: true,
          key: 'npwp',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nama)
        },{
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/coa/baru'), authStore.user.roleMap?authStore.user.roleMap['COA'].Create:false)
      ]}
      itemActions={[
        new TableWrapper.action('Approval', 'mdi mdi-check-circle', (item) => setShowDialog({ data: item, show: true }), true),
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/coa/${item.id}`), authStore.user.roleMap?authStore.user.roleMap['COA'].Edit:false),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, authStore.user.roleMap?authStore.user.roleMap['COA'].Delete:false)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}


export default inject('sipStore', 'navigationStore', "authStore")(observer(AkunTable))
