import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./TransaksiKoreksiFiskal.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import Utils from "../../../libs/custom/Utils";
import TipeKoreksiFiskalService from "../../adminpajakku/tipeKoreksiFiskal/TipeKoreksiFiskal.service";
import { inject, observer } from "mobx-react";
var _baseId = "mod-table-transaksi-kertas-kerja-koreksi-fiskal";

const VKertasKerjaKoreksiFiskalTable = ({
  className = "",
  history,
  transaksiId,
  modalStore,
  sipStore
}) => {

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={"Kertas Kerja Koreksi Fiskal"}
        className={className}
        // showCommandbar={false}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="kodeAkun"
        defaultSort = "ASC"
        columns={[
          {
            label: "Kode Akun",
            searchable: true,
            key: "kodeAkun",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.kodeAkun
          },
          {
            label: "Nama Akun",
            searchable: true,
            key: "namaAkun",
            sortable: true,
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.namaAkun
          },
          {
            label: "Nilai Komersil",
            searchable: true,
            key: "nilaiKomersial",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.nilaiKomersial),
          },
          {
            label: "Koreksi Positif",
            searchable: true,
            key: "koreksiPositif",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.koreksiPositif),
          },
          {
            label: "Koreksi Negatif",
            searchable: true,
            key: "koreksiNegatif",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.koreksiNegatif),
          },
          {
            label: "Nilai Fiskal",
            searchable: true,
            key: "nilaiFiskal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.nilaiFiskal),
          },
        ]}
        actions={[]}
        itemActions={[]}
        onFetchData={async (query) => {
          query['periodeId.equals'] = sipStore.periodeId
          return service.http.get('/kertas-kerja-koreksi-fiskals', query);
        }}
        showFilterPeriod={false}
      />
    </>
  );
};

export default inject(
  "modalStore",
  "navigationStore",
  "sipStore",
)(observer(VKertasKerjaKoreksiFiskalTable));
