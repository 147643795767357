import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './FixedAset.service'
import Utils from '../../../libs/custom/Utils'
import TipeAsetService from '../../adminpajakku/tipeAset/TipeAset.service'
import TipeBisnisAsetService from '../JenisUsaha/JenisUsaha.service'
import KelompokAsetService from '../../adminpajakku/kelompokAset/KelompokAset.service'
import MetodePenyusutanService from '../MetodePenyusutan/MetodePenyusutan.service'
import PerusahaanService from '../Perusahaan/Perusahaan.service'
import PerusahaanSettingService from '../Perusahaan/PerusahaanSetting.service'
import { Chip } from "@react-md/chip";

const FixedAsetForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [tipeAsetList, setTipeAsetList] = useState([])
  const [tipeBisnisAsetList, setTipeBisnisAsetList] = useState([])
  const [tipeBisnisAsetMap, setTipeBisnisAsetMap] = useState({})
  const [kelompokAsetList, setKelompokAsetList] = useState([])
  const [metodePenyusutanFiskalList, setMetodePenyusutanFiskalList] = useState([])
  const [perusahaanSetting, setPerusahaanSetting] = useState({})
  const [metodePenyusutanKomList, setMetodePenyusutanKomList] = useState([])
  const [perusahaanList, setPerusahaanList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [jenisHartaMap, setJenisHartaMap] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getTipeAsetList()
      await _getTipeBisnisAsetList()
      // await _getKelompokAsetList()
      await _getMetodePenyusutanFiskalList()
      await _getMetodePenyusutanKomList()
      // await _getPerusahaanList()

      await _getPerusahaanSetting()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
          kodeSatuan: ''
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      res.data.kodeSatuan = res.data.kode.substring(3, 10);

      await _getKelompokAsetList({
        'tipeAsetId.equals': res.data.tipeAset__id
      })

      // _getTipeBisnisAsetList({
      //   'kelompokAsetId.equals': res.data.kelompokAset__id
      // })

      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getTipeAsetList() {
    var res = await TipeAsetService.get()
    setJenisHartaMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setTipeAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTipeBisnisAsetList(params) {
    if(!params) params = {'perusahaanId.equals': sipStore.companyId}
    params['perusahaanId.equals'] = sipStore.companyId
    params.size = 1000
    var res = await TipeBisnisAsetService.get(params)

    setTipeBisnisAsetMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))

    setTipeBisnisAsetList(res.data.map(x => ({value: x.id, label: x.nama, secondaryText:x.kelompokAset.nama})))
  }

  async function _getKelompokAsetList(params) {
    if(!params) params = {}
    params.size = 1000
    var res = await KelompokAsetService.get(params)
    console.log(res.data, 'asd')
    setKelompokAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getMetodePenyusutanFiskalList() {
    var res = await MetodePenyusutanService.get({size: 100000})
    setMetodePenyusutanFiskalList(res.data)
  }

  async function _getMetodePenyusutanKomList() {
    var res = await MetodePenyusutanService.get({size: 100000})
    setMetodePenyusutanKomList(res.data.map(x => ({value: x.id, label: x.kode})))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanService.get({size: 100000})
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPerusahaanSetting(companyId) {
    var res = await PerusahaanSettingService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId, 'kode.equals': 'METODE PENYUSUTAN'})
    setPerusahaanSetting(res.data[0]?res.data[0]:{})
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/fixed-aset`}
      baseId="mod-form-sample"
      title={t.translate('modules.fixedAset.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'tipeAset__id') {
          _getKelompokAsetList({
            'tipeAsetId.equals': value
          })
        }

        if(key == 'kelompokAset__id') {
          _getTipeBisnisAsetList({
            'kelompokAsetId.equals': value
          })
        }

        if(key == 'tipeBisnisAset__id') {
          newObj.kode = tipeBisnisAsetMap[value]? tipeBisnisAsetMap[value].kode+newObj.kodeSatuan: ''+newObj.kodeSatuan
        }

        if(key == 'kodeSatuan') {
          newObj.kode = tipeBisnisAsetMap[newObj.tipeBisnisAset__id]? tipeBisnisAsetMap[newObj.tipeBisnisAset__id].kode+value: ''+value
        }
      }}
      definitions={[
        // =====
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Perusahaan',
        //   key: 'perusahaanId',
        //   type: 'text',
        //   disabled: isEdit,
        //   validation: 'required',
        //   options: perusahaanList,
        //   disabled: sipStore.companyId != null
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Harta',
          key: 'tipeAset__id',
          type: 'text',
          validation: 'required',
          disabled: isEdit,
          options: tipeAsetList,
          width: '49%',
          style: {marginRight: '2%'},
        },{
          inputType: inputTypes.SELECT,
          label: 'Kelompok Harta',
          key: 'kelompokAset__id',
          type: 'text',
          validation: 'required',
          disabled: isEdit,
          options: kelompokAsetList,
          width: '49%',
        },{
          inputType: inputTypes.SELECT,
          label: 'Jenis Usaha',
          key: 'tipeBisnisAset__id',
          type: 'text',
          validation: 'required',
          disabled: isEdit,
          options: tipeBisnisAsetList,
          data: tipeBisnisAsetList
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode',
          key: 'kodeSatuan',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Lengkap',
          key: 'kode',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          disabled: true,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '100%',
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },{
          inputType: inputTypes.SELECT,
          label: 'Metode Penyusutan Fiskal GL',
          key: 'metodePenyusutanGlId',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          options: metodePenyusutanFiskalList.filter(x => x.tipeMetodePenyusutan && x.tipeMetodePenyusutan.id == '00000000-69eb-21eb-a501-77e5b90b7b50').map(x => ({value: x.id, label: x.kode}))
        },
        // {
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'Aktif',
        //   key: 'metodePenyusutanFiskalAktif',
        //   type: 'text',
        //   width: '49%',
        //   disabled: true
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Metode Penyusutan Fiskal SM',
          key: 'metodePenyusutanSmId',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          options: metodePenyusutanFiskalList.filter(x => x.tipeMetodePenyusutan && x.tipeMetodePenyusutan.id == '00000001-69eb-21eb-a601-77e5b90b7b50').map(x => ({value: x.id, label: x.kode}))
        },
        {
          label: 'asd',
          width: '49%',
          render: ()=> {
            console.log(perusahaanSetting, 'perusahaanSetting.asdasd')
            if(perusahaanSetting.value == 'Garis Lurus') {
              return <div className="mpk-full full-width input mpk-margin-N margin-bottom flex-none " style={{width: '49%', marginRight: '2%', textAlign: 'center'}}><Chip>AKTIF</Chip></div>
            }
            return <div className="mpk-full full-width input mpk-margin-N margin-bottom flex-none " style={{width: '49%', marginRight: '2%', textAlign: 'center'}}><Chip>TIDAK AKTIF</Chip></div>
          }
        },
        {
          width: '49%',
          style: {marginRight: '2%'},
          render: ()=> {
            if(perusahaanSetting.value == 'Saldo Menurun') {
              return <div className="mpk-full full-width input mpk-margin-N margin-bottom flex-none " style={{width: '49%', textAlign: 'center'}}><Chip>AKTIF</Chip></div>
            }
            return <div className="mpk-full full-width input mpk-margin-N margin-bottom flex-none " style={{width: '49%', textAlign: 'center'}}><Chip>TIDAK AKTIF</Chip></div>
          }
        },

        // {
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'Tidak Aktif',
        //   key: 'metodePenyusutanKomAktif',
        //   type: 'text',
        //   width: '49%',
        //   disabled: true
        // },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/fixed-aset')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(FixedAsetForm))
