import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PajakTerutangSanding.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import PajakTerutangDataHitungService from './PajakTerutang.service'
import TransaksiJurnalService from '../Transaksi/TransaksiJurnal.service'

const PajakTerutangSandingForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [perusahaanList, setPerusahaanList] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [pajakTerutangDataHitungList, setPajakTerutangDataHitung] = useState([])
  const [pajakTerutangDataHitungMap, setPajakTerutangDataHitungMap] = useState({})
  const [transaksiJurnalList, setTransaksiJurnalList] = useState([])
  const [transaksiJurnalMap, setTransaksiJurnalMap] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getEntitasList()
      await _getPajakTerutangDataHitung()
      await _getTransaksiJurnalList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          // perusahaan__id: sipStore.companyId,
          statusApproval: "-"
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      res.data.jurnals = res.data.jurnals.map(d => Utils.formSerialize(d))
      res.data.terutangs = res.data.terutangs.map(d => Utils.formSerialize(d))
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'tipeEntitas.notEquals': 'Cabang Pembantu', 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
    setEntitasMap(res.data.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPajakTerutangDataHitung() {
    var res = await PajakTerutangDataHitungService.get({size: 10000, 'sandingStatus.equals': 'Belum Tersanding', 'perusahaanId.equals': sipStore.companyId})
    // setPajakTerutangDataHitung(res.data.map(x => ({value: x.id, label: x.nomorReferensiHutang})))
    setPajakTerutangDataHitung(res.data.filter((x) => {
      return x.transaksi != null
    }).map(x => ({value: x.id, label: x.transaksi?`${x.transaksi.nomor} - ${x.nomorReferensiHutang}`: `${x.nomorReferensiHutang}`})))
    setPajakTerutangDataHitungMap(res.data.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }

  async function _getTransaksiJurnalList() {
    var res = await TransaksiJurnalService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setTransaksiJurnalList(res.data.map(x => ({value: x.id, label: x.akun?`${x.akun.nomor} - ${x.akun.nama}`: `${x.akun}`})))
    setTransaksiJurnalMap(res.data.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        new TableWrapper.action('Back', 'mdi mdi-arrow-left', () => history.goBack(), true),
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      // backTo={`/onpremise/pemotongan-pph2126`}
      baseId="mod-form-sample"
      title={"Kertas Kerja Sanding"}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(parentKey == 'jurnals') {
          if(key == 'jurnals__id') {
            var transaksiJurnal = transaksiJurnalMap[value]
            newObj.jurnals[indexAtParent].pph = transaksiJurnal.nilai
          }
        }

        if(parentKey == 'terutangs') {
          if(key == 'terutangs__id') {
            var tr = pajakTerutangDataHitungMap[value]
            newObj.terutangs[indexAtParent].lawanTransaksi__nama = tr.transaksi.transaksiLawanTransaksi.nama
            newObj.terutangs[indexAtParent].kodeObjekPajak__nama = tr.kodeObjekPajak.nama
            newObj.terutangs[indexAtParent].pph = tr.pph
          }
        }

      }}
      definitions={[
        // =====       
        {
          inputType: inputTypes.INPUT,
          label: 'No Referensi',
          key: 'nomorReferensi',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required',
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# PILIH TRANSAKSI AKUN PAJAK ',
          key: 'jurnals',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 1000},
          // defaultData: {
          //   "pph": 0
          // },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Nomor Transaksi',
              key: 'jurnals__id',
              theme: 'filled',
              style: {width: 700},
              validation: 'required',
              options: transaksiJurnalList
            },
            // {
            //   inputType: inputTypes.INPUT,
            //   label: 'Keterangan',
            //   key: 'keterangan',
            //   type: 'text',
            //   required: true,
            //   style: {width: 200},
            // },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'PPh',
              key: 'pph',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 300},
            },
          ]
        },
        {render: (d)=> {
          var totalPphJurnal = 0;
          if(d.jurnals) {
            d.jurnals.forEach((x) => {
              totalPphJurnal += parseInt(x.pph)
            })
          }
          return <div style={{margin: '20px', width: '100%', fontSize: '20px'}}>
            <hr/>
            <div className="mpk-flex align-start direction-row">Total : <div className="flex" />{Utils.numberFormat(totalPphJurnal)}</div>
          </div>
        }},
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# PILIH TRANSAKSI DATA HITUNG ',
          key: 'terutangs',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 1600},
          // defaultData: {
          //   "pph": 0,
          // },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Nomor Referensi',
              key: 'terutangs__id',
              theme: 'filled',
              style: {width: 400},
              // validation: 'required',
              options: pajakTerutangDataHitungList
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: 'Nama Lawan Transaksi',
              key: 'lawanTransaksi__nama',
              theme: 'filled',
              style: {width: 400},
              validation: 'required',
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: 'KOP',
              key: 'kodeObjekPajak__nama',
              theme: 'filled',
              style: {width: 400},
              validation: 'required',
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'PPh',
              key: 'pph',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
          ]
        },
        {render: (d)=> {
          var totalPphTerutang = 0;
          if(d.terutangs) {
            d.terutangs.forEach((x) => {
              totalPphTerutang += parseInt(x.pph)
            })
          }
          return <div style={{margin: '20px', width: '100%', fontSize: '20px'}}>
            <hr/>
            <div className="mpk-flex align-start direction-row">Total : <div className="flex" />{Utils.numberFormat(totalPphTerutang)}</div>
          </div>
        }},
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          data.jurnals = data.jurnals.map(d => Utils.formNormalize(d))
          data.terutangs = data.terutangs.map(d => Utils.formNormalize(d))

          var jurnalPph = 0
          var terutangPph = 0
          data.jurnals.forEach((x) => {
            jurnalPph += parseInt(x.pph)
            x.id = x.jurnals__id
            delete x.jurnals__id
            delete x.pph
          })
          data.terutangs.forEach((x) => {
            terutangPph += parseInt(x.pph)
            x.id = x.terutangs__id
            delete x.terutangs__id
            delete x.lawanTransaksi__nama
            delete x.kodeObjekPajak__nama
            delete x.pph
          })
          if(jurnalPph != terutangPph){
            callback({ message: "Total Transaksi Data Hitung harus sama dengan Total Transaksi Akun Pajak" }, true);
            return true;
          } else{
            data.pph = jurnalPph
          }

          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/pemotongan-pph2126')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(PajakTerutangSandingForm))
