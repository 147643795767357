import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PPh25.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import PeriodeFiskalService from '../PeriodeFiskal/PeriodeFiskal.service'
import EntitasPajakService from '../../master/EntitasPajak/EntitasPajak.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TarifPPh25Service from '../../adminpajakku/tarifPph25/TarifPph25.service'
import PPh25Service from './PPh25.service'

const PPh25Form = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [perusahaanList, setPerusahaanList] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [periodeFiskalList, setPeriodeFiskalList] = useState([])
  const [tipeKategori, setTipeKategori] = useState('')
  const [bankPph25, setBankPph25] = useState([])
  const [masaValue, setMasaValue] = useState('')
  var [field, setField] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getEntitasList()
      await _getPeriodeFiskalList()

      if(match.params.id == 'baru') {
        if(sipStore.periodeId){
          var tarifPPh = {}
          const res = await TarifPPh25Service.get()
          if(res.data){
            res.data.map((d) => {
              if(d.tahunMulaiBerlaku >= sipStore.periodeId <= d.tahunAkhirBerlaku){
                tarifPPh = {
                  tarifPajak17Ayat1b: d.tarifPajak17Ayat1b,
                  tarifPajak17Ayat2b: d.tarifPajak17Ayat2b
                }
              }
            })
          }
        }
        setData({loading: false, content: {
          perusahaan__id: sipStore.companyId,
          periodeId: sipStore.periodeId,
          tahun :sipStore.periode.tahun,
          ...tarifPPh
          // jenis: 'Wajib Pajak BUMN/BUMD'
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      if(res.data.tanggalLaporSebelumnya){
        res.data.perhitunganOtomatis = true
      } else {
        res.data.perhitunganManual = true
      }
      allField(res.data.entitasId)
      // res.data.entitasId = res.data.entitasId
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'tipeEntitas.equals': 'HO', 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
    setEntitasMap(res.data.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }


  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPeriodeFiskalList() {
    var res = await PeriodeFiskalService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId})
    setPeriodeFiskalList(res.data.map(x => ({value: x.id, label: x.tahun})))
  }

  async function allField(value) {
    var res = await EntitasPajakService.get({size: 10000, 'entitasId.equals': value, 'periodeId.equals': sipStore.periodeId })
    var x = Object.values(res.data)

    //pengecekan tanggal entitas = range periode
    var validDates = x.filter((obj) => {
      var a = sipStore.periode.dari
      var b = sipStore.periode.ke
      var start = obj.tanggalAwal;
      var end = obj.tanggalAkhir;
      return start >= a && end <= b;
    })

    var kategori = ''
    if(res.data.length > 0){
      var kategori25 = await KategoriPPh25Service.get({size: 10000})
      kategori = kategori25.data.filter(x => x.id == validDates[0].kategoriPPh25Id)
    }
    if(kategori.length > 0){
      setTipeKategori(kategori[0].nama)
    
    if(kategori[0].nama == "Wajib Pajak Umum"){
      field = [
        {
          inputType: inputTypes.DIVIDER,
          label: '# Wajib Pajak Umum'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Kategori SPT Tahunan Sebelumnya',
          key: 'kategoriSpt',
          type: 'text',
          width: '100%',
          options:[
            {value: '01', label: 'Wajib Pajak Baru'},
            {value: '02', label: 'Penyampaian SPT Tahunan PPh sebelum batas waktu'},
            {value: '03', label: 'Penyampaian SPT Tahunan PPh setelah batas waktu'},
            {value: '04', label: 'Penyampaian SPT Tahunan PPh diberikan perpanjangan jangka waktu'},
            {value: '05', label: 'Diterbitkan Surat Ketetapan Pajak'}
          ],
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Melakukan Perhitungan Otomatis",
          key: "perhitunganOtomatis",
          width: "49%",
          style: { marginRight: "2%" },
          type: "text"
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Melakukan Perhitungan Manual",
          key: "perhitunganManual",
          width: "49%",
          type: "text"
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal Lapor SPT untuk Tahun Pajak Sebelumnya",
          key: "tanggalLaporSebelumnya",
          width: "100%",
          style: {marginRight: '2%'},
          show: (d) => d.perhitunganOtomatis && (d.kategoriSpt == '02' || d.kategoriSpt == '03' || d.kategoriSpt == '04')
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal diterbitkannya SKP untuk Tahun Pajak Sebelumnya",
          key: "tanggalTerbitSkpSebelumnya",
          width: "100%",
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt == '05'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai angsuran PPh pasal 25 pada akhir Bulan Ditahun Pajak Sebelumnya',
          key: 'nilaiAngsuranPph25Sebelumnya',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          allowNegative: false,
          width: '100%',
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt != '01'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Bruto untuk Tahun Pajak Sebelumnya',
          key: 'brutoSebelumnya',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          allowNegative: false,
          width: '49%',
          style: {marginRight: '2%'},
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt != '01'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan yang menjadi Dasar Perhitungan Angsuran',
          key: 'penghasilanDasarAngsuran',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          allowNegative: false,
          width: '49%',
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt != '01'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Kompensasi Kerugian',
          key: 'kompensasiKerugian',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          allowNegative: false,
          width: '49%',
          style: {marginRight: '2%'},
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt != '01'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Kena Pajak',
          key: 'pkp',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          allowNegative: false,
          width: '49%',
          // disabled: true,
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt != '01'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Opsi Tarif PPh',
          key: 'jenisTarif',
          width: '100%',
          options: [
            {value: 'Pasal 31E ayat 1', label: 'Pasal 31E ayat 1'}
          ],
          show: (d) => d.perhitunganOtomatis && d.brutoSebelumnya <= 50000000000
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Opsi Tarif PPh',
          key: 'jenisTarif',
          width: '100%',
          options: [
            {value: 'Pasal 17 ayat 1b', label: 'Pasal 17 ayat 1b'},
            {value: 'Pasal 17 ayat 2b', label: 'Pasal 17 ayat 2b'}
          ],
          show: (d) => d.perhitunganOtomatis && d.brutoSebelumnya > 50000000000
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh Terutang',
          key: 'pphTerutang',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          allowNegative: false,
          width: '100%',
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt != '01'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Kredit Pajak',
          key: 'kreditPajak',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          allowNegative: false,
          width: '100%',
          show: (d) => d.perhitunganOtomatis
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Dasar Angsuran PPh pasal 25',
          key: 'dasarAngsuran',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%',
          disabled: true,
          show: (d) => d.perhitunganOtomatis && d.kategoriSpt != '01'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Angsuran PPh pasal 25',
          key: 'angsuranPph25',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Angsuran PPh pasal 25 yang telah disetor Sebelumnya',
          key: 'angsuranSetorSebelumnya',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Kekurangan Setoran PPh pasal 25',
          key: 'kekuranganSetoran',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%',
          disabled: true
        }
      ]
    } else if (kategori[0].nama == "Wajib Pajak Bank"){
      field = [
        {
          inputType: inputTypes.DIVIDER,
          label: '# Wajib Pajak Bank'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Neto',
          key: 'netto',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'},
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Neto sejak awal tahun sampai masa pajak ini',
          key: 'nettoAwalTahun',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          // disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Kompensasi Kerugian',
          key: 'kompensasiKerugian',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Kena Pajak',
          key: 'pkp',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh Terutang',
          key: 'pphTerutang',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh pasal 22 masa pajak ini',
          key: 'pphPasal22masaPajak',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh pasal 22 sejak awal tahun sampai masa pajak ini',
          key: 'pphPasal22AwalTahun',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh pasal 25 yang seharusnya dibayar sejak awal tahun sampai masa pajak ini',
          key: 'pph',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Angsuran yang harus dibayar untuk masa pajak ini',
          key: 'angsuranPph25',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%'
        }
      ]
    } else if (kategori[0].nama == "Wajib Pajak BUMN/BUMD"){
      field = [
        {
          inputType: inputTypes.DIVIDER,
          label: '# Wajib Pajak BUMN/BUMD'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Neto Fiskal teratur (berdasarkan RKAP)',
          key: 'netto',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Kompensasi Kerugian',
          key: 'kompensasiKerugian',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Kena Pajak',
          key: 'pkp',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh terutang',
          key: 'pphTerutang',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Kredit Pajak',
          key: 'kreditPajak',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Dasar angsuran PPh pasal 25',
          key: 'dasarAngsuran',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Angsuran PPh pasal 25',
          key: 'angsuranPph25',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true
        }
      ]
    } else if (kategori[0].nama == "Wajib Pajak Masuk Bursa"){
      field = [
        {
          inputType: inputTypes.DIVIDER,
          label: '#  WP  Wajib  Pajak  PMB  atau  WP  wajib  LK Berkala Lainnya'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai Angsuran PPh pasal 25 Masa Pajak terakhir Tahun pajak sebelumnya',
          key: 'nilaiAngsuranPph25Sebelumnya',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Neto sejak Awal Tahun Pajak s.d Triwulan yang dilaporkan',
          key: 'netto',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Kompensasi Kerugian',
          key: 'kompensasiKerugian',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Penghasilan Kena Pajak',
          key: 'pkp',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh Terutang',
          key: 'pphTerutang',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'}
        },
        // {
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   label: 'PPh pasal 22 dan PPh pasal 23 pada masa yang dilaporkan',
        //   key: 'pph2223MasaDilaporkan',
        //   thousandSeparator: '.',
        //   decimalSeparator: ',',
        //   isNumericString: true,
        //   width: '49%'
        // },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'PPh pasal 22 dan PPh pasal 23 sejak awal tahun pajak sampai dengan masa yang dilaporkan',
          key: 'pph2223AwalTahun',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%',
          // disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Angsuran PPh pasal 25 yang seharusnya dibayar sejak awal Tahun Pajak sampai dengan Masa Pajak sebelum Masa Pajak yang dilaporkan',
          key: 'angsuranPph25AwalTahun',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%',
          // disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Angsuran PPh pasal 25 yang masih harus dibayar',
          key: 'angsuranPph25Dibayar',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Angsuran PPh pasal 25',
          key: 'angsuranPph25',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true
        }
      ]
    }
    setField(field)
  }
}

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/pph-25`}
      baseId="mod-form-sample"
      title='Pajak Terutang PPh 25'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if (tipeKategori == 'Wajib Pajak Bank'){
          if(key == 'masa'){
            if (value > 1){
              var val  = value - 1;
              var res = await PPh25Service.http.get(`/transaksi-pajak-terutang-pph-25s/${val}/${sipStore.periode.tahun}/${sipStore.companyId}`)
              setBankPph25(res.data)
              setMasaValue('a')
            }else{
              setMasaValue('b')
            }          
          }
        }

        if(key == 'entitasId'){
          allField(value)
        }

        // Wajib Pajak Umum
        if(key == 'penghasilanDasarAngsuran' || key == 'kompensasiKerugian'){
          if(tipeKategori == 'Wajib Pajak Umum'){
            newObj.pkp = parseFloat(newObj.penghasilanDasarAngsuran)-parseFloat(newObj.kompensasiKerugian)
          }
        }

        if(key == 'pkp'){
          if(tipeKategori == 'Wajib Pajak Umum'){
            if(newObj.jenisTarif == 'Pasal 17 ayat 1b'){
              newObj.pphTerutang = parseFloat(newObj.pkp)*parseFloat(newObj.tarifPajak17Ayat1b)
            } else if(newObj.jenisTarif == 'Pasal 17 ayat 2b'){
              newObj.pphTerutang = parseFloat(newObj.pkp)*parseFloat(newObj.tarifPajak17Ayat2b)
            } else {
              if(newObj.brutoSebelumnya <= 4800000000 ){
                newObj.pphTerutang = parseFloat(newObj.pkp)*50*parseFloat(newObj.tarifPajak17Ayat1b)
              } else if(newObj.brutoSebelumnya > 4800000000 && newObj.brutoSebelumnya < 50000000000){
                newObj.pphTerutang = (4800000000/parseFloat(newObj.brutoSebelumnya))*parseFloat(newObj.pkp)*parseFloat(newObj.tarifPajak17Ayat1b)*50/100
              }
            }
          }
        }

        if(key == 'pphTerutang' || key == 'kreditPajak'){
          if(tipeKategori == 'Wajib Pajak Umum'){
            if(newObj.pphTerutang > newObj.kreditPajak){
              newObj.dasarAngsuran = parseFloat(newObj.pphTerutang)-parseFloat(newObj.kreditPajak)
            } else {
              newObj.dasarAngsuran = 0
            }
          }
        }

        if(key == 'kategoriSpt'){
          if(tipeKategori == 'Wajib Pajak Umum'){
            if(newObj.kategoriSpt == '01'){
              newObj.angsuranPph25 = 0
            } else if(newObj.kategoriSpt == '02'){
              if(newObj.tanggalLaporSebelumnya == '1' || newObj.tanggalLaporSebelumnya == '2' || newObj.tanggalLaporSebelumnya == '3'){
                newObj.angsuranPph25 = newObj.nilaiAngsuranPph25Sebelumnya
              } else {
                newObj.angsuranPph25 = 1/12*parseFloat(newObj.dasarAngsuran)
              }
            } else if(newObj.kategoriSpt == '03'){
              if(newObj.masa == '1' || newObj.masa == '2' || newObj.masa == '3'){
                newObj.angsuranPph25 = newObj.nilaiAngsuranPph25Sebelumnya
              } else {
                newObj.angsuranPph25 = 1/12*parseFloat(newObj.dasarAngsuran)
              }
            } else if(newObj.kategoriSpt == '04'){
              if(newObj.masa == '1' || newObj.masa == '2' || newObj.masa == '3'){
                newObj.angsuranPph25 = newObj.nilaiAngsuranPph25Sebelumnya
              } else {
                newObj.angsuranPph25 = 1/12*parseFloat(newObj.dasarAngsuran)
              }
            } else if(newObj.kategoriSpt == '05'){
              if(newObj.masa == '1' || newObj.masa == '2' || newObj.masa == '3'){
                newObj.angsuranPph25 = newObj.nilaiAngsuranPph25Sebelumnya
              } else {
                newObj.angsuranPph25 = 1/12*parseFloat(newObj.dasarAngsuran)
              }
            }
          }
        }

        if(key == 'angsuranPph25' || key == 'angsuranSetorSebelumnya'){
          if(tipeKategori == 'Wajib Pajak Umum'){
            newObj.kekuranganSetoran = parseFloat(newObj.angsuranPph25)-parseFloat(newObj.angsuranSetorSebelumnya)
          }
        }

        //Wajib Pajak Bank
        if(key == 'nettoAwalTahun' || key == 'kompensasiKerugian'){
          if(tipeKategori == 'Wajib Pajak Bank'){
            newObj.pkp = parseFloat(newObj.nettoAwalTahun)+parseFloat(newObj.kompensasiKerugian)
          }
        }

        if(key == 'pkp'){
          newObj.pphTerutang = parseFloat(newObj.tarifPajak17Ayat1b)*parseFloat(newObj.pkp)
        }

        if(key == 'pphTerutang' || key == 'pphPasal22AwalTahun' || key == 'pph'){
          if(tipeKategori == 'Wajib Pajak Bank'){
            newObj.angsuranPph25 = parseFloat(newObj.pphTerutang)-parseFloat(newObj.pphPasal22AwalTahun)-parseFloat(newObj.pph)
          }
        }

        if(masaValue == 'a'){
          if(tipeKategori == 'Wajib Pajak Bank'){
            newObj.nettoAwalTahun = parseFloat(newObj.netto)+bankPph25.nettoAwalTahun
            newObj.pphPasal22AwalTahun = parseFloat(newObj.pphPasal22masaPajak)+bankPph25.pphPasal22AwalTahun
          }
        }else{
          if(tipeKategori == 'Wajib Pajak Bank'){
            newObj.nettoAwalTahun = parseFloat(newObj.netto)
            newObj.pphPasal22AwalTahun = parseFloat(newObj.pphPasal22masaPajak)
          }
        }

        //Wajib Pajak BUMN/BUMD
        if(key == 'netto' || key == 'kompensasiKerugian'){
          if(tipeKategori == 'Wajib Pajak BUMN/BUMD'){
            newObj.pkp = parseFloat(newObj.netto)-parseFloat(newObj.kompensasiKerugian)
          }
        }

        if(key == 'pkp'){
          if(tipeKategori == 'Wajib Pajak BUMN/BUMD'){
            newObj.pphTerutang = parseFloat(newObj.tarifPajak17Ayat1b)*parseFloat(newObj.pkp)
          }
        }

        if(key == 'pphTerutang' || key == 'kreditPajak'){
          if(tipeKategori == 'Wajib Pajak BUMN/BUMD'){
            newObj.dasarAngsuran = parseFloat(newObj.pphTerutang)-parseFloat(newObj.kreditPajak)
          }
        }

        if(key == 'dasarAngsuran'){
          if(tipeKategori == 'Wajib Pajak BUMN/BUMD'){
            newObj.angsuranPph25 = parseFloat(newObj.dasarAngsuran)/12
          }
        }

        //Wajib Pajak Masuk Bursa
        if(key == 'netto' || key == 'kompensasiKerugian'){
          if(tipeKategori == 'Wajib Pajak Masuk Bursa'){
            newObj.pkp = parseFloat(newObj.netto)-parseFloat(newObj.kompensasiKerugian)
          }
        }

        if(key == 'pkp'){
          if(tipeKategori == 'Wajib Pajak Masuk Bursa'){
            newObj.pphTerutang = (19 / 100)*parseFloat(newObj.pkp)
          }
        }

        if(key == 'pphTerutang' || key == 'pph2223AwalTahun' || key == 'angsuranPph25AwalTahun'){
          if(tipeKategori == 'Wajib Pajak Masuk Bursa'){
            newObj.angsuranPph25Dibayar = parseFloat(newObj.pphTerutang)-parseFloat(newObj.pph2223AwalTahun)-parseFloat(newObj.angsuranPph25AwalTahun)
          }
        }
        
        if(key == 'angsuranPph25Dibayar'){
          if(tipeKategori == 'Wajib Pajak Masuk Bursa'){
            newObj.angsuranPph25 = parseFloat(newObj.angsuranPph25Dibayar)/3
          }
        }

      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaan__id',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitasId',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required',
          disabled: isEdit
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Masa Pajak',
          key: 'masa',
          type: 'text',
          style: {marginRight: '2%'},
          width: '49%',
          required: true,
          validation: 'required',
          disabled: isEdit,
          options: [
            {value: '1', label: '1'},
            {value: '2', label: '2'},
            {value: '3', label: '3'},
            {value: '4', label: '4'},
            {value: '5', label: '5'},
            {value: '6', label: '6'},
            {value: '7', label: '7'},
            {value: '8', label: '8'},
            {value: '9', label: '9'},
            {value: '10', label: '10'},
            {value: '11', label: '11'},
            {value: '12', label: '12'}
          ]
        },{
          inputType: inputTypes.INPUT,
          label: 'Tahun Pajak',
          key: 'tahun',
          width: '49%',
          type: 'text',
          disabled: isEdit,
          options: periodeFiskalList,
          // disabled: sipStore.periodeId != null
        }, 
        ...field
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          data.masa = parseInt(data.masa)
          // data.tahun = parseInt(sipStore.periode.tahun)         
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/pph-25')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(PPh25Form))
