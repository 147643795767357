import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { TabsWrapper } from "../../../libs/react-mpk/wrapper";
import Utils from "../../../libs/custom/Utils";
import { ID_TIPE_PEGAWAI, TIPE_TRANSKRIP } from "../../../clientConstant";
import TranskripListForm from "./TranskripList.form";
import ElemenTranskripService from "../../adminpajakku/ElemenTranskrip/ElemenTranskrip.service";
import AkunService from "../../master/Akun/Akun.service";
import TranskripListService from "./TranskripList.service";

const TranskripWrapper = ({
  envStore,
  match,
  history,
  sipStore,
  navigationStore,
}) => {
  // initial data
  const [data, setData] = useState({
    loading: true,
    aktivaList: [],
    pasivaList: [],
    labaRugiList: [],
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [etList, setEtList] = useState([]);
  const [etMap, setEtMap] = useState({});
  const [akunList, setAkunList] = useState([]);

  const handleTabChange = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    initData();
  }, ["match.params.migrasiTranskripId"]);

  async function initData() {
    var res = await TranskripListService.http.get(
      "/saldo-neraca-transkrip-list",
      { periodeFiskalId: sipStore.periodeId }
    );

    setData({
      loading: false,
      aktivaList: res.data.aktivaList ? res.data.aktivaList : [],
      pasivaList: res.data.pasivaList ? res.data.pasivaList : [],
      labaRugiList: res.data.labaRugiList ? res.data.labaRugiList : [],
    });
  }

  return (
    <TabsWrapper
      className="bg-none"
      title="Transkrip"
      showCommandbar={true}
      onActiveIndexChange={handleTabChange}
      activeIndex={currentIndex}
      baseId="mod-details-migrasi"
      actions={[
        {
          name: "Add",
          label: "Regenerate",
          iconClassName: "mdi mdi-inbox-full",
          onClick: async () => {
            setData({
              loading: true,
              aktivaList: [],
              pasivaList: [],
              labaRugiList: [],
            });
            await TranskripListService.http.get(
              "/patch/patch-init-periode-fiskal-saldo-neraca-transkrip/" +
                sipStore.periodeId
            );
            await initData();
          },
        },
      ]}
      tabs={[
        {
          label: "AKTIVA",
          key: "neraca-aktiva",
          render: (
            <TranskripListForm
              data={data}
              match={match}
              history={history}
              tipeTranskrip={"aktivaList"}
              sipStore={sipStore}
              navigationStore={navigationStore}
              onSubmit={async (resData, callback) => {
                callback("", false);
                setCurrentIndex(1);
              }}
            />
          ),
        },
        {
          label: "PASIVA",
          key: "neraca-pasiva",
          render: (
            <TranskripListForm
              data={data}
              match={match}
              history={history}
              tipeTranskrip={"pasivaList"}
              sipStore={sipStore}
              navigationStore={navigationStore}
              onSubmit={async (resData, callback) => {
                callback("", false);
                setCurrentIndex(2);
              }}
            />
          ),
        },
        {
          label: "LABA - RUGI",
          key: "labba-rugi",
          render: (
            <TranskripListForm
              data={data}
              match={match}
              history={history}
              tipeTranskrip={"labaRugiList"}
              sipStore={sipStore}
              navigationStore={navigationStore}
              onSubmit={async (resData, callback) => {
                callback("", false);
                setCurrentIndex(2);
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default inject(
  "sipStore",
  "navigationStore"
)(observer(TranskripWrapper));
