import React, { useEffect, useState } from "react";
import { TableWrapper, FormWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./Aset.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import Utils from "../../../libs/custom/Utils";
import { DataForm } from '../../../libs/react-mpk/components'
import { AKUN_JENIS_MAP } from "../../../clientConstant";

var _baseId = "mod-table-Aset";

const AsetTable = ({
  className = "",
  showCommandbar = true,
  history,
  sipStore,
  navigationStore,
  migrasi,
  akunList,
  transkripData,
  authStore
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});
  const [total, setTotal] = useState(0);
  const [totalAwal, setTotalAwal] = useState(0);
  const [uploadModal, setUploadModal] = useState(false)

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  function _closeUploadModal() {
    setUploadModal(false)
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={"Aset"}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        tableFooter={
          <>
            {migrasi && (
              <>
                <div className="mpk-flex align-center direction-row justify-start flex">
                  <div className="flex">Total Nilai</div>{" "}
                  <b>{Utils.numberFormat(total)}</b>
                </div>
                <div className="mpk-flex align-center direction-row justify-start flex">
                  <div className="flex">
                    Jumlah modal yang tercatat pada saldo Awal
                  </div>{" "}
                  <b>{Utils.numberFormat(totalAwal)}</b>
                </div>
                <div className="mpk-flex align-center direction-row justify-start flex">
                  <div className="flex">Selisih</div>{" "}
                  <b>{Utils.numberFormat(totalAwal - total)}</b>
                </div>
              </>
            )}
          </>
        }
        columns={[
          {
            label: "Nomor Aset",
            searchable: true,
            key: "nomor",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nomor,
          },
          {
            label: "Nama Aset",
            searchable: true,
            key: "nama",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nama,
          },
          {
            label: "Tanggal Perolehan",
            searchable: true,
            key: "tanggalPerolehan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tanggalPerolehan,
          },
          {
            label: "Harga Total Perolehan",
            searchable: true,
            key: "hargaTotalPerolehan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.hargaTotalPerolehan),
          },
          {
            label: "Nilai Akumulasi Penyusutan Fiskal",
            searchable: true,
            key: "nilaiPenyusutanFiskal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.nilaiPenyusutanFiskal),
          },
          {
            label: "Nilai Buku",
            searchable: true,
            key: "nilaiBuku",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.nilaiBuku),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => history.push("/onpremise/aset/baru"),
            authStore.user.roleMap?authStore.user.roleMap['Aset'].Create:false
          ),
          new TableWrapper.action('Import Aset', 'mdi mdi-import', () => setUploadModal(true), true),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => history.push(`/onpremise/aset/${item.id}`),
            authStore.user.roleMap?authStore.user.roleMap['Aset'].Edit:false
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            authStore.user.roleMap?authStore.user.roleMap['Aset'].Delete:false
          ),
        ]}
        onFetchData={async (query) => {
          if (!query) query = {};
          if (sipStore.companyId) {
            query["perusahaanId.equals"] = sipStore.companyId;
          }

          if (migrasi) {
            // query["tanggalPerolehan.greaterThanOrEqual"] =
            //   sipStore.periode.dari;
            // query["tanggalPerolehan.lessThanOrEqual"] = sipStore.periode.ke;
            query["tanggalPerolehan.lessThan"] = sipStore.periode.dari;
            var total = await service.http.get("/asets/sum", query);
            setTotal(total.data.nilai);

            var akunMap = akunList
              .filter(
                (a) => a.akunJenisId == AKUN_JENIS_MAP["Akun Aktiva Tetap"].id
              )
              .reduce((pv, cv) => {
                pv[cv.id] = cv;
                return pv;
              }, {});

            var t = 0;
            transkripData.saldoList.forEach((v) => {
              v.saldoNeracaAkuns
                .filter((a) => akunMap[a.akun.id])
                .forEach((a) => {
                  t = t + a.nilaiAwal;
                });
            });
            setTotalAwal(t);
          }
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
      <DataForm
      baseId={"aset-import"}
      asDialog={true}
      defaultData={{}}
      definitions={[{
        inputType: FormWrapper.inputTypes.FILE_INPUT,
        label: 'File',
        key: 'file',
        theme: 'filled',
        style: {width: 200}
      }]}
      visible={uploadModal}
      submitLabel='Import Data'
      onSubmit={async (d, callback)=> {
        var formData = new FormData()
        formData.set('file', d.file)
        var uploadRes = await service.http.post('/upload', formData)
        if(uploadRes.data) {
          var importRes = await service.http.post('/aset-import', {
            nama: uploadRes.data.name,
            origin: uploadRes.data.origin,
            perusahaanId: sipStore.companyId,
            periodeId: sipStore.periodeId,
            ext: 'xls'
          })
        }
        callback('Data sedang di import. Mohon pantau progress import di menu log import.', false)
      }}
      onRequestClose={_closeUploadModal} />
    </>
  );
};

export default inject("sipStore", "navigationStore", "authStore")(observer(AsetTable));
