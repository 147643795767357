import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './PPh21Final.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-pph-21-final"

const PPh21FinalTable = ({
  className       = '',
  showCommandbar  = true,
  history
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={t.translate('modules.pph21Final.title')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Nama',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.slipGajiPegawai.nama
        },
        {
          label: 'NPWP',
          searchable: true,
          key: 'npwp',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.slipGajiPegawai.npwp
        },
        {
          label: 'NIK',
          searchable: true,
          key: 'bruto',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.slipGajiPegawai.nik
        },
        {
          label: 'NIP',
          searchable: true,
          key: 'nip',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.slipGajiPegawai.nip
        },
        {
          label: 'Bruto (Rp)',
          searchable: true,
          key: 'bruto',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.bruto))
        },{
          label: 'Bruto Payroll (Rp)',
          searchable: true,
          key: 'brutoPayroll',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.brutoPayroll))
        }, {
          label: 'PPh (Rp)',
          searchable: true,
          key: 'pph',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.pph))
        },{
          label: 'PPh Payroll (Rp)',
          searchable: true,
          key: 'pphhPayroll',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.pphhPayroll))
        },{
          label: 'Slip Gaji',
          searchable: true,
          key: 'slipGajiPegawaiId',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.slipGajiPegawaiId)
        },{
          label: 'Tarif (%)',
          searchable: true,
          key: 'tarif',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tarif)
        },
        {
          label: 'Status Perhitungan',
          searchable: true,
          key: 'status',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.perhitunganStatus
        },
        {
          label: 'Notes',
          searchable: true,
          key: 'notes',
          type: TableWrapper.dataTypes.STRING,
          render: item => item.perhitunganNotes
        },
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/pph-menu/final/baru'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/pph-menu/final/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        var masa = localStorage['sip-menu-pph']?JSON.parse(localStorage['sip-menu-pph']):{}
        if(!query) query = {};
        query['pemotonganPPh2126Id.equals'] = masa.id
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default PPh21FinalTable
