import { makeAutoObservable } from 'mobx';

class SipStore {
  company={}
  companyId=null
  periode={}
  periodeId=null
  token=''
  user={}
  setting={}

  constructor(){
    makeAutoObservable(this)
  }

  setSetting(setting) {
    this.setting = setting;
  }

  setCompany(company) {
    this.company = company
    this.companyId = company.id
  }

  setPeriode(periode) {
    this.periode = periode
    this.periodeId = periode.id
  }

  setToken(token) {
    this.token = token
  }

  setUser(user) {
    this.user = user
  }
}


export default new SipStore()
