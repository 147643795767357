import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TransaksiReferensiPembayaranHutang.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import TransaksiReferensiHutangService from './TransaksiReferensiHutang.service'
import TransaksiService from './Transaksi.service'
import { AKUN_JENIS_MAP } from '../../../clientConstant'


const TransaksiReferensiPembayaranHutangForm = ({envStore, match, history, sipStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [akunList, setAkun] = useState([])
  const [journalMap, setJournalMap] = useState({})
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([])
  const [transaksi, setTransaksi] = useState({})
  const [transaksiReferensiHutangList, setTransaksiReferensiHutang] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      _getAkun()
      _getKodeObjekPajak()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {dipungut: false, dpp:'0', pph: '0', transaksiKoreksiFiskalAkun__nilai: '0', transaksiKoreksiFiskalPengecualians: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      // res.data.transaksiReferensiHutangAkun__akunid = res.data.transaksiReferensiHutangAkun.akun.id
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  // async function _getAkun() {
  //   var res = await AkunService.get({size: 100000})
  //   setAkun(res.data.map(x => ({value: x.id, label: x.nama})))
  // }

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId)
    await _getTransaksiReferensiHutang(res.data.transaksiLawanTransaksi.npwp)
    setTransaksi(res.data)
    setJournalMap(res.data.transaksiJurnals.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setAkun(res.data.transaksiJurnals)
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({size: 100000})
    setKodeObjekPajak(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTransaksiReferensiHutang(npwp) {
    var res = await TransaksiReferensiHutangService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId, 'lawanTransaksiNpwp.equals': npwp})
    setTransaksiReferensiHutang(res.data.map(x => ({value: x.id, label: x.nomor})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/transaksi/'+match.params.transaksiId+'/referensi-pembayaran-hutang'}
      baseId="mod-form-sample"
      title='Referensi Pembayaran Hutang'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        // if(key == 'transaksiReferensiHutangAkun__nilai') {
        //   newObj.nilai = value
        // }

        if(key == 'transaksiJurnal__id') {
          newObj.transaksiJurnal__nilai = journalMap[value].nilai
        }
      }}
      definitions={[
        // =====
        {
          label: '# LAWAN TRANSA',
          render: ()=> <React.Fragment>
            <div className="mpk-label mpk-font size-NS mpk-margin-N margin-bottom mpk-padding-N padding-top padding-bottom mpk-margin-XS margin-top input mpk-margin-N margin-bottom flex-none "># LAWAN TRANSAKSI</div>
            <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none" style={{width: '49%'}}>

            </div>
          </React.Fragment>
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# AKUN TRANSAKSI'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun',
          key: 'transaksiJurnal__id',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          options: akunList.filter(x => x.akun.akunJenisId == AKUN_JENIS_MAP["Akun Hutang Lainnya"].id).map(x => ({value: x.id, label: x.akun.nama})),
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'transaksiJurnal__nilai',
          // theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true,
          validation: 'required|numeric'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# NOMOR REFERENSI PEMBAYARAN HUTANG'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Nomor Referensi Hutang',
          key: 'transaksiReferensiHutang__id',
          type: 'text',
          width: '100%',
          // required: true,
          validation: 'required',
          options: transaksiReferensiHutangList
          // style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'nilai',
          // theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '100%',
          // style: {marginRight: '2%'},
          validation: 'required|numeric'
        },

        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          // data.transaksiReferensiHutangAkun.akun = {
          //   id: data.transaksiReferensiHutangAkun__akunid
          // }

          if(data.transaksiReferensiHutang__id) {
            var hutang = await TransaksiReferensiHutangService.getOne(data.transaksiReferensiHutang__id);
            
            if(data.nilai > hutang.data.nilai) {
              callback('Nilai yang dimasukkan sudah melebihi nilai hutang', true, false)
              return false
            }
          }
          
          var allres = await service.get({'transaksiId.equals': data.transaksiId, size: 100000});
          var total = allres.data.filter(x => x.id != data.id).reduce((pv, cv)=> {
            pv = pv+parseInt(cv.nilai);
            return pv
          }, 0)

          total = total + parseInt(data.nilai)

          var akunId = journalMap[data.transaksiJurnal__id].akun.id
          var totalJurnal = akunList.reduce((pv, cv)=> {
            if(cv.akun.id == akunId) {
              pv = pv+parseInt(cv.nilai)
            }
            return pv
          }, 0)

          if(total > totalJurnal) {
            // iziToast.warning({message: 'Total nilai beban promosi melebihi nilai jurnal'})
            callback('Total Nilai Pembayaran hutang yang dimasukkan melebihi nilai Akun Transaksi Pembayaran hutang', true, false)
            return false
          }

          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/transaksi/'+match.params.transaksiId+'/referensi-pembayaran-hutang')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'envStore')(observer(TransaksiReferensiPembayaranHutangForm))
