import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./TransaksiKoreksiFiskal.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import Utils from "../../../libs/custom/Utils";
import TipeKoreksiFiskalService from "../../adminpajakku/tipeKoreksiFiskal/TipeKoreksiFiskal.service";
import { inject, observer } from "mobx-react";
var _baseId = "mod-table-transaksi-pajak";

const TransaksiKoreksiFiskalTable = ({
  className = "",
  history,
  transaksiId,
  modalStore,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});
  const [tipeKoreksiFiskalList, setTipeKoreksiFiskal] = useState({});

  // initial data
  useEffect(() => {
    async function initData() {
      _getTipeKoreksiFiskal();
    }
    initData();
  }, []);

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  async function _getTipeKoreksiFiskal() {
    var res = await TipeKoreksiFiskalService.get({ size: 100000 });

    setTipeKoreksiFiskal(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={""}
        className={className}
        showCommandbar={false}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Akun",
            searchable: true,
            key: "akun",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.jurnal && item.jurnal.akun)?item.jurnal.akun.nama:'',
          },
          {
            label: "Nilai Nominal",
            searchable: true,
            key: "nilai",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.jurnal?item.jurnal.nilai:''),
          },
          {
            label: "Tipe Koreksi",
            searchable: true,
            key: "tipeKoreksi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.tipeKoreksiFiskal?item.tipeKoreksiFiskal.nama:''),
          },
          {
            label: "Jenis Koreksi",
            searchable: true,
            key: "jenis",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.jenis),
          },
          {
            label: "Nilai Fiskal",
            searchable: true,
            key: "nilaiFiskal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.nilaiFiskal),
          },
          {
            label: "Keterangan",
            searchable: true,
            key: "keterangan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => (item.keterangan),
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () =>
              history.push(
                `/onpremise/transaksi-koreksi-fiskal/baru/${transaksiId}`
              ),
            true
          ),
          new TableWrapper.action(
            "Generate",
            "mdi mdi-inbox-full",
            async () => {
              modalStore.showConfirm({
                children: "Generate koreksi fiskal?",
                onRequestClose: () => {
                  modalStore.closeConfirm();
                },
                onSubmit: async (callback) => {
                  await service.http.get(
                    `/transaksis/${transaksiId}/generate-koreksi-fiskal`
                  );
                  toast.success(`Data pajak terutang telah di generate.`);
                  TableWrapper.reload(_baseId);
                  callback();
                },
              });
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) =>
              history.push(
                `/onpremise/transaksi-koreksi-fiskal/${item.id}/${transaksiId}`
              ),
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          query["transaksiId.equals"] = transaksiId;
          return service.get(query);
        }}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
    </>
  );
};

export default inject(
  "modalStore",
  "navigationStore"
)(observer(TransaksiKoreksiFiskalTable));
