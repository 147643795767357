import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './JenisUsaha.service'
import Utils from '../../../libs/custom/Utils'
import KelompokAsetService from '../../adminpajakku/kelompokAset/KelompokAset.service'
import PerusahaanService from '../Perusahaan/Perusahaan.service'
import TipeAsetService from '../../adminpajakku/tipeAset/TipeAset.service'

const JenisUsahaForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [kelompokAsetList, setKelompokAsetList] = useState([])
  const [kelompokAsetMap, setKelompokAsetMap] = useState({})
  const [perusahaanList, setPerusahaanList] = useState([])
  const [tipeAsetList, setTipeAsetList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      // await _getKelompokAsetList()
      // await _getPerusahaanList()
      await _getTipeAsetList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaan__id: sipStore.companyId,
          kodeSatuan: ''
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      await _getKelompokAsetList({'tipeAsetId.equals': res.data.kelompokAset?res.data.kelompokAset.tipeAsetId: null})
      res.data = Utils.formSerialize(res.data)
      res.data.kodeSatuan = res.data.kode.substring(2, 10);
      res.data.tipeAsetId = res.data.kelompokAset?res.data.kelompokAset.tipeAsetId: null

      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getKelompokAsetList(params) {
    if(!params) params = {}
    params.size = 1000
    var res = await KelompokAsetService.get(params)
    setKelompokAsetMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))

    console.log(kelompokAsetMap, 'asdasd')
    setKelompokAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanService.get({size: 100000})
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTipeAsetList() {
    var res = await TipeAsetService.get({size: 100000})
    setTipeAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/jenis-usaha`}
      baseId="mod-form-sample"
      title={t.translate('modules.jenisUsaha.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'tipeAsetId') {
          _getKelompokAsetList({
            'tipeAsetId.equals': value
          })
        }

        if(key == 'kelompokAset__id') {
          newObj.kode = kelompokAsetMap[value]? kelompokAsetMap[value].kode+newObj.kodeSatuan: ''+newObj.kodeSatuan
        }

        if(key == 'kodeSatuan') {
          newObj.kode = kelompokAsetMap[newObj.kelompokAset__id]? kelompokAsetMap[newObj.kelompokAset__id].kode+value: ''+value
        }
      }}
      definitions={[
        // =====
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Perusahaan',
        //   key: 'perusahaan__id',
        //   type: 'text',
        //   options: perusahaanList,
        //   disabled: isEdit,
        //   validation: 'required',
        //   disabled: sipStore.companyId != null
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Harta',
          key: 'tipeAsetId',
          type: 'text',
          options: tipeAsetList,
          disabled: isEdit,
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required'
        },{
          inputType: inputTypes.SELECT,
          label: 'Kelompok Harta',
          key: 'kelompokAset__id',
          type: 'text',
          options: kelompokAsetList,
          disabled: isEdit,
          width: '49%',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Kode',
          key: 'kodeSatuan',
          type: '100',
          width: '100%',
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Lengkap',
          key: 'kode',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          disabled: true,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: '100',
          width: '100%',
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/jenis-usaha')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(JenisUsahaForm))
