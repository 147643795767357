
import React, {useEffect, useState} from 'react'
import { inject, observer } from 'mobx-react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import markdownFile from './toc-pajakku.md'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Dialog,
  DialogContainer,
  DialogContent,
  DialogFooter,
  Button
} from 'react-md'
import ReactMarkdown from 'react-markdown'
import t from 'counterpart'
import OpLoginService from './OpLogin.service'
import axios from 'axios'

const OpLogin = ({ authStore, modalStore, envStore, match, history, sipStore, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [md, setMd] = useState()
  const [dialog, setDialog] = useState(false)

  const baseName = `login`
  const basePath = `login`

  useEffect(() => {
    getLicense()
    fetch(markdownFile).then((response) => response.text()).then((text) => {
      setMd(text)
    })
  }, [])

  var syarat = async ()=> {
    setDialog(true)
  }

  const getLicense = async () => {
    try {
      var d = await OpLoginService.http.get(
        "/api/app/license",
        {},
        {},
        { useDefaultBaseUrl: false, useDefaultHost: true }
      );
    } catch (error) {
      window.location.href = `/#/license`
    }

  };

  return (
    <>
      <div className="App mpk-full viewport-height account">
      {/* <a style={{ color: 'red', position: 'absolute', bottom: 40, right: 40 }} href="/#/register">Cek lisensi SIP</a> */}
        <div className="bg">
          <div className="parallelogram-1"></div>
          <div className="parallelogram-2"></div>
          <div className="parallelogram-3"></div>
          <div className="parallelogram-4"></div>
          <div className="parallelogram-5"></div>
          <Dialog
            visible={dialog}
            disableFocusOnMount={true}
            style={{
              width: 800
            }}
            onRequestClose={()=> setDialog(false)}
          >
            <DialogContent>
              <div ><ReactMarkdown children={md} /></div>
            </DialogContent>
            <DialogFooter>
              <Button onClick={()=> setDialog(false)}>Tutup</Button>
            </DialogFooter>
          </Dialog>
          <div style={{ textAlign: 'center', marginTop: '100px', height: 54, }}>
            <img src="./images/sip-picturemark-d.svg" style={{ position: 'relative', height: 64, width: 92 }} />
            <img src="./images/sip-logotype-d.svg" style={{ position: 'relative', height: 45, margin: 9.5 }} />
          </div>
          <FormWrapper
            showCommandbar={false}
            style={{
              maxWidth: 450,
              padding: 40,
              position: 'relative',
              marginTop: 50,
              boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
            }}
            className="fullWidth"
            baseId={`mod-form-${baseName}`}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: 'Username',
                key: 'username',
                type: 'text',
                validation: 'required',
              },
              {
                inputType: inputTypes.INPUT,
                label: 'Password',
                key: 'password',
                type: 'password',
                validation: 'required',
              },
              {
                render: (
                  <p style={{ textAlign: 'center' }}>
                    Dengan melakukan sign-in, saya menyatakan telah membaca, mengerti, dan menyetujui <a style={{ color: 'blue' }} onClick={()=> syarat()}>syarat dan ketentuan</a> yang berlaku
                  </p>
                )
              },
              {
                render: (
                  <p style={{ textAlign: 'center', width: '100%' }}>
                    <a style={{ color: 'blue' }} href="/#/register" style={{ textAlign: 'center' }}>Daftar disini !</a>
                  </p>
                )
              }
            ]}
            submitLabel="Sign In"
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button onClick={()=> window.location.href=`/#/reset-password`}>Reset Password</Button>
                )
              },

            ]}
            onSubmit={ async (values, callback )=> {
              try {
                values.rememberMe = true
                let res = await OpLoginService.login(values)
                localStorage.setItem("id_token", res.data.id_token)
                sipStore.setToken(res.data.id_token);
                axios.defaults.headers.common['Authorization'] = "Bearer "+res.data.id_token
                window.location.href = '/#/onpremise'
              } catch(e){
                callback(e.response.data.detail, true, false)
              }
            }}
          >
          </FormWrapper>
        </div>
      </div>
    </>
  )
}

export default inject('authStore', 'envStore', 'modalStore', 'sipStore')(observer(OpLogin))
