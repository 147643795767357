import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './TransaksiPajakWp.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import Utils from '../../../libs/custom/Utils'
import TransaksiService from './Transaksi.service'

var _baseId = "mod-table-transaksi-pajak-wp"

const TransaksiPajakWpTable = ({
  className       = '',
  history,
  transaksiId,
  posId,
  pembelianId,
  modalStore,
  transaksi
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  var column = [
    {
      label: 'Akun',
      searchable: true,
      key: 'akun',
      hidden: true,
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.jurnal && item.jurnal.akun?item.jurnal.akun.nama:'')
    },{
      label: 'Tipe Pajak',
      searchable: true,
      key: 'typePajak',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.typePajak)
    },{
      label: 'DPP',
      searchable: true,
      key: 'dpp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (Utils.numberFormat(item.dpp))
    },{
      label: 'PPh/PPN',
      searchable: true,
      key: 'pph',
      type: TableWrapper.dataTypes.STRING,
      render: item => (Utils.numberFormat(item.nilai))
    }
  ]

  if(pembelianId) {
    column[0] =  {
      label: 'Barang/Jasa',
      searchable: true,
      key: 'item',
      hidden: true,
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.item && item.item?item.item.nama:'')
    }
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={''}
      className={className}
      showCommandbar={false}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={column}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => {
          if(posId) {
            history.push(`/onpremise/transaksi-pajak-wp-pos/baru/${posId}`)
          } else if(pembelianId) {
            history.push(`/onpremise/transaksi-pajak-wp-pembelian/baru/${pembelianId}`)
          } else {
            history.push(`/onpremise/transaksi-pajak-wp/baru/${transaksiId}`)
          }

        }, true)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
          if(posId) {
            history.push(`/onpremise/transaksi-pajak-wp-pos/${item.id}/${posId}`)
          } else if(pembelianId) {
            history.push(`/onpremise/transaksi-pajak-wp-pembelian/${item.id}/${pembelianId}`)
          } else {
            history.push(`/onpremise/transaksi-pajak-wp/${item.id}/${transaksiId}`)
          }
        }, true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(posId) {
          query['posId.equals'] = posId
        }
        if(transaksiId) {
          query['transaksiId.equals'] = transaksiId
          logging(transaksiId)
        }

        if(pembelianId) {
          query['pembelianId.equals'] = pembelianId
        }


        return service.get(query)
      }}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

async function logging(transaksiId) {
  const res = await TransaksiService.getOne(transaksiId)
  console.info('Trace Pengenaan   :', res.data.kelengkapanNotes)
}

export default inject('modalStore', 'navigationStore')(observer(TransaksiPajakWpTable))
