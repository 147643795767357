import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import Utils from "../../../libs/custom/Utils";
import { DataForm } from "../../../libs/react-mpk/components";
import EntitasService from './Entitas.service'

const PenomoranBpForm = ({
  envStore,
  entitasId,
  history,
  sipStore,
  navigationStore,
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [data, setData] = useState({ loading: true, content: {} });
  const [isEdit, setIsEdit] = useState(false);
  const penomoranList = ["BP-21-FINAL", "BP-21-TIDAK-FINAL", "BP-21-A1", "BP-23", "BP-26", "BP_4A2", "BP_15"]

  async function initData() {
    var res = await EntitasService.http.get(`/penomoran-bp-all/${entitasId}`);
    var noBpMap = res.data.reduce((pv, cv)=> {
      pv[cv.nama] = cv;
      return pv;
    }, {})

    penomoranList.forEach((nama)=> {
      if(!noBpMap[nama]) {
        res.data.push({
          "id" : null,
          "nama" : nama,
          "prefix" : "PREFIX",
          "suffix" : "SUFFIX",
          "angka" : "00000000000000",
          "entitasId" : entitasId
        })
      }
    })

    setData({
      loading: false,
      content: {
        nomors: res.data,
      },
    });
    return true;
  }

  // initial data
  useEffect(() => {

    initData();
  }, ["entitasId"]);

  return (
    <DataForm
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={`/onpremise/pendapatan`}
      baseId="mod-form-sample"
      title={t.translate("modules.pendapatan.formTitle")}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "PENOMORAN PAJAK",
          key: "nomors",
          // hintMessage: 'Daftar Tarif',
          defaultData: {},
          layout: "table",
          hideActionButton: true,
          definitions: [
            {
              inputType: inputTypes.INPUT,
              label: "Nama",
              key: "nama",
              type: "text",
              width: "100%",
              disabled: true,
              required: true,
              validation: "required",
              // style: {marginRight: '2%'}
            },
            {
              inputType: inputTypes.INPUT,
              label: "PREFIX",
              key: "prefix",
              type: "text",
              width: "100%",
              // required: true,
              // validation: "required",
              // style: {marginRight: '2%'}
            },
            {
              inputType: inputTypes.INPUT,
              label: "Angka",
              key: "angka",
              type: "text",
              width: "100%",
              required: true,
              validation: "required",
              // style: {marginRight: '2%'}
            },
            {
              inputType: inputTypes.INPUT,
              label: "SUFFIX",
              key: "suffix",
              type: "text",
              width: "100%",
              // required: true,
              // validation: "required",
              // style: {marginRight: '2%'}
            },
          ],
        },
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        try {
          data = Utils.formNormalize(data);
          var res = await EntitasService.http.put(`/penomoran-bp-all`, data.nomors.map((d)=> {
            d.entitasId = entitasId;
            return d;
          }));

          initData()
          // if(match.params.id == 'baru') {
          //   res = await service.post(data)
          // } else {
          //   res = await service.put(data)
          // }
          callback(t.translate("global.dataTelahDiSimpan"), false);
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject("sipStore", "navigationStore")(observer(PenomoranBpForm));
