import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PerusahaanSetting.service'
import KategoriPph25ListService from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipeIndustriListService from '../../adminpajakku/tipeIndustri/TipeIndustri.service'

const PerusahaanSettingForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [map, setMap] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {

      const res = await service.get({
        'perusahaanId.equals': match.params.id,
        size: 1000
      })
      var map = res.data.reduce((pv, cv)=> {
        pv[cv.kode.replace(" ", "_")] = cv
        return pv
      }, {})
      var initData = res.data.reduce((pv, cv)=> {
        pv[cv.kode.replace(" ", "_")] = cv.value
        return pv
      }, {})

      setMap(map)
      setData({loading: false, content: initData})
    }
    initData()
  }, ['match.params.id'])


  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/perusahaan`}
      baseId="mod-form-sample"
      title={'Pengaturan Perusahaan'}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Nomor aset diatur oleh',
        //   key: 'nomor_aset',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   options: [{value: 'System', label: 'System'}, {label: 'Manual', value: 'Manual'}]
        // },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Format penanggalan',
        //   key: 'format_penanggalan',
        //   type: 'text',
        //   width: '49%',
        //   // style: {marginRight: '2%'},
        //   options: [{value: 'DD-MM-YYYY', label: 'DD-MM-YYYY'}, {label: 'DD/MM/YYYY', value: 'DD/MM/YYYY'}]
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Metode penyusutan fiskal',
          key: 'METODE_PENYUSUTAN',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          options: [{value: 'Garis Lurus', label: 'Garis Lurus'}, {label: 'Saldo Menurun', value: 'Saldo Menurun'}]
        },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Metode penyusutan komersial',
        //   key: 'metode_penyusutan_komersial',
        //   type: 'text',
        //   width: '49%',
        //   // style: {marginRight: '2%'},
        //   options: [{value: 'Garis Lurus', label: 'Garis Lurus'}, {label: 'Saldo Menurun', value: 'Saldo Menurun'}]
        // },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Perhitungan akumulasi penyusutan fiskal & nilai sisa',
        //   key: 'perhitungan_akumulasi',
        //   type: 'text',
        //   width: '100%',
        //   // style: {marginRight: '2%'},
        //   options: [{value: 'System', label: 'System'}, {label: 'Manual', value: 'Manual'}]
        // },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          await Promise.all(Object.keys(data).map(async (key)=> {
            console.log(key)
            if(map[key]) {
              return await service.put({
                ...map[key],
                value: data[key],
                perusahaanId: match.params.id
              })
            } else {
              return await service.post({
                nama: key.replace("_", " "),
                kode: key.replace("_", " "),
                value: data[key],
                keterangan: "-",
                referensi: "-",
                perusahaanId: match.params.id
              })
            }
          }))
  
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/perusahaan')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(PerusahaanSettingForm))
