import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './ElemenTranskrip.service'
import TipeAkunService from '../tipeAkun/TipeAkun.service'
import Utils from '../../../libs/custom/Utils'
import MasterMappingSpt1771Service from '../MasterMappingSpt1771/MasterMappingSpt1771.service'

const tipeTranskrip = {
  'neraca-aktiva': '00000000-5a06-21eb-a501-45e67381d492',
  'neraca-pasiva': '00000001-5a06-21eb-a601-45e67381d492',
  'laba-rugi': '00000002-5a06-21eb-a701-45e67381d492'
}

const ElemenTransrkipForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [tipeAkunList, setTipeAkunList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [masterMappingSpt1771List, setMasterMappingSpt1771] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      await _getTipeAkun()
      await _getMasterMappingSpt1771List()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          tipeBaris: 'KATEGORI'
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getTipeAkun() {
    var res = await TipeAkunService.get({'jenisUsahaId.equals': JSON.parse(localStorage.getItem('sip-menu-industri')).jenisUsahaId })
    setTipeAkunList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getMasterMappingSpt1771List() {
    var res = await MasterMappingSpt1771Service.get({size: 10000, 'sektor.equals': JSON.parse(localStorage.getItem('sip-menu-industri')).mappingSpt1771, 'tipeTransakrip.equals': tipeTranskrip[match.params.tipe] })
    setMasterMappingSpt1771(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/industri-menu/transkrip/${match.params.tipe}`}
      baseId="mod-form-sample"
      title={'Transkrip'}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Tipe Baris',
        //   key: 'tipeBaris',
        //   type: 'text',
        //   width: '49%',
        //   options: [{label: 'HEADER', value: 'HEADER'}, {label: 'KATEGORI', value: 'KATEGORI'}],
        //   style: {marginRight: '2%'},
        //   validation: 'required'
        // },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '100%',
          validation: 'required'
        },
         {
          inputType: inputTypes.CHECKBOX,
          label: 'Tampilkan Nomor Urut',
          key: 'tampilkanNomorUrut',
          type: 'text',
          width: '50%',
          // required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nomor Urut',
          key: 'nomorUrut',
          type: 'number',
          width: '100%',
          validation: 'required'
          // show: (d)=> d.tampilkanNomorUrut
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Tipe Akun',
          key: 'tipeAkun__id',
          type: 'text',
          width: '100%',
          options: tipeAkunList,
          validation: [{required_if: ['tipeBaris', 'KATEGORI']}],
          show: (d)=> d.tipeBaris == 'KATEGORI'
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Parent',
        //   key: 'parentId',
        //   type: 'text',
        //   width: '100%',
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Munculkan pada kolom ke',
          key: 'posisiKolom',
          type: 'text',
          width: '100%',
          options: [{label: '1', value: 1}, {label: '2', value: 2}],
          validation: 'required'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Gunakan Huruf Tebal',
          key: 'teksTebal',
          type: 'text',
          width: '100%'
        },
        // {
        //   inputType: inputTypes.CHECKBOX,
        //   label: 'Tampilkan Hasil Kalkulasi',
        //   key: 'tampilkanNilai',
        //   type: 'text',
        //   width: '100%'
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Tipe Perhitungan',
          key: 'tipePerhitungan',
          type: 'text',
          width: '100%',
          options: [
            {label: 'PENJUMLAHAN AKUN', value: "PENJUMLAHAN AKUN"},
            {label: 'PERHITUNGAN ELEMEN LAIN', value: "PERHITUNGAN ELEMEN LAIN"},
            {label: 'INDUSTRI DAGANG TRANSKRIP PEMBELIAN', value: "INDUSTRI DAGANG TRANSKRIP PEMBELIAN"},
            {label: 'INDUSTRI DAGANG - SALDO BARANG DAGANGAN AWAL', value: "INDUSTRI DAGANG - SALDO BARANG DAGANGAN AWAL"},
            {label: 'INDUSTRI DAGANG - SALDO BARANG DAGANGAN AKHIR', value: "INDUSTRI DAGANG - SALDO BARANG DAGANGAN AKHIR"},
            {label: 'INDUSTRI MANUFAKTUR - BAHAN BAKU YANG DIGUNAKAN', value: "INDUSTRI MANUFAKTUR - BAHAN BAKU YANG DIGUNAKAN"},
            {label: 'INDUSTRI MANUFAKTUR - SALDO BARANG DALAM PROSES AWAL', value: "INDUSTRI MANUFAKTUR - SALDO BARANG DALAM PROSES AWAL"},
            {label: 'INDUSTRI MANUFAKTUR - SALDO BARANG DALAM PROSES AKHIR', value: "INDUSTRI MANUFAKTUR - SALDO BARANG DALAM PROSES AKHIR"},
            {label: 'INDUSTRI MANUFAKTUR - SALDO BARANG JADI AWAL', value: "INDUSTRI MANUFAKTUR - SALDO BARANG JADI AWAL"},
            {label: 'INDUSTRI MANUFAKTUR - SALDO BARANG JADI AKHIR', value: "INDUSTRI MANUFAKTUR - SALDO BARANG JADI AKHIR"}
          ]
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Kolom Penambah',
          key: 'kolomPenambah',
          type: 'text',
          style: {marginRight: '2%'},
          width: '49%',
          show: (d) => d.tipePerhitungan == 'PERHITUNGAN ELEMEN LAIN'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Kolom Pengurang',
          key: 'kolomPengurang',
          type: 'text',
          width: '49%',
          show: (d) => d.tipePerhitungan == 'PERHITUNGAN ELEMEN LAIN'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Mapping Integrasi SPT 1771',
          key: 'masterFieldMappingSPT1771Id',
          type: 'text',
          width: '100%',
          options: masterMappingSpt1771List
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.tipeTranskripId = tipeTranskrip[match.params.tipe]
          data.tipeIndustriId = JSON.parse(localStorage.getItem('sip-menu-industri')).id
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            if(match.params.parentId != 'none') data.parent = {id: match.params.parentId}
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push(`/industri-menu/transkrip/${match.params.tipe}`)
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}


export default inject('envStore')(observer(ElemenTransrkipForm))
