import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./Item.service";
import AkunListService from "../Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import Utils from "../../../libs/custom/Utils";
import PerusahaanListService from "../Perusahaan/Perusahaan.service";
import AkunService from '../../master/Akun/Akun.service'

const ItemForm = ({ envStore, match, history, sipStore, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [data, setData] = useState({ loading: true, content: {} });
  const [perusahaanList, setPerusahaanList] = useState([]);
  const [kodeObjekPajakList, setKodeObjekPajakList] = useState([]);
  const [kodeObjekPajakMap, setKodeObjekPajakMap] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [akunList, setAkunList] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      // await _getPerusahaanList();
      await _getKodeObjekPajakList();
      await _getAkun()

      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            perusahaanId: sipStore.companyId,
            pph: [],
            ppn: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      if(!res.data.pengenaanPajaks) res.data.pengenaanPajaks = []
      res.data.pph = res.data.pengenaanPajaks
        .filter((x) => x.jenisKOP == "PPH")
        .map((x) => Utils.formSerialize(x));
      res.data.ppn = res.data.pengenaanPajaks
        .filter((x) => x.jenisKOP == "PPN")
        .map((x) => Utils.formSerialize(x));
      res.data = Utils.formSerialize(res.data);
      setData({ loading: false, content: res.data });
      setIsEdit(true);
    }
    initData();
  }, ["match.params.id"]);

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.get({ size: 10000 });
    setPerusahaanList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getKodeObjekPajakList() {
    var res = await KodeObjekPajakService.get({ size: 2000 });

    setKodeObjekPajakMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setKodeObjekPajakList(
      res.data.map((x) => ({
        value: x.id,
        label: x.kode + " - " + x.nama,
        tipe: x.tipePajak.nama,
      }))
    );
  }

  async function _getAkun() {
    var res = await AkunService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setAkunList(res.data.map(x => ({value: x.id, label: x.nomor?`${x.nomor} - ${x.nama}`: `${x.nama}`})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={`/onpremise/barang-dan-jasa`}
      baseId="mod-form-sample"
      title={t.translate("modules.Item.formTitle")}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if (key == "kodeObjekPajakId" && parentKey == "pph") {
          if (kodeObjekPajakMap[value]) {
            newObj.pph[indexAtParent].dasarHukum =
              kodeObjekPajakMap[value].dasarHukum;
            newObj.pph[indexAtParent].keterangan =
              kodeObjekPajakMap[value].keterangan;
          }
        }
        if (key == "kodeObjekPajakId" && parentKey == "ppn") {
          if (kodeObjekPajakMap[value]) {
            newObj.ppn[indexAtParent].dasarHukum =
              kodeObjekPajakMap[value].dasarHukum;
            newObj.ppn[indexAtParent].keterangan =
              kodeObjekPajakMap[value].keterangan;
          }
        }
      }}
      definitions={[
        // =====
        // {
        //   inputType: inputTypes.SELECT,
        //   label: "Perusahaan",
        //   key: "perusahaanId",
        //   type: "text",
        //   width: "100%",
        //   style: { marginRight: "2%" },
        //   required: true,
        //   options: perusahaanList,
        //   disabled: sipStore.companyId != null,
        // },
        {
          inputType: inputTypes.INPUT,
          label: "Kode",
          key: "kode",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          disabled: isEdit,
          validation: "required",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nama",
          key: "nama",
          type: "text",
          width: "49%",
          disabled: isEdit,
          validation: "required",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Apakah Item Pembelian?',
          key: 'isPembelian',
          style: { marginRight: "2%" },
          width: '49%'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Apakah Item Penjualan?',
          key: 'isPenjualan',
          width: '49%'
        },
        {
          inputType: FormWrapper.inputTypes.SELECT,
          label: "Default Akun",
          key: "akun__id",
          theme: "filled",
          style: { width: 500 },
          validation: [
            { required_if: ["isPembelian", true] },
          ],
          options: akunList,
          show: (d) => d.isPembelian
        },
        {
          inputType: inputTypes.INPUT,
          label: "Keterangan",
          key: "keterangan",
          type: "text",
        },

        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Pengenaan Pajak PPh",
          key: "pph",
          // hintMessage: 'Daftar Tarif',
          defaultData: {
            dasarHukum: "-",
            keterangan: "-",
            saatTerutangPajak: "Terutang saat disediakan untuk pembayaran",
          },
          layout: "table",
          style: { minWidth: 1500 },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Kode Objek Pajak",
              key: "kodeObjekPajakId",
              theme: "filled",
              type: "number",
              validation: "required",
              style: { width: 500 },
              options: kodeObjekPajakList.filter((x) => x.tipe != "PPN"),
            },
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Saat Terutang Pajak",
              key: "saatTerutangPajak",
              theme: "filled",
              validation: "required",
              style: { width: 410 },
              options: [
                {
                  value: "Terutang saat disediakan untuk pembayaran",
                  label: "Terutang saat disediakan untuk pembayaran",
                },
                // {
                //   value: "Terutang saat pembayaran",
                //   label: "Terutang saat pembayaran",
                // },
                // {
                //   value: "Terutang saat Jatuh Tempo / pembayaran",
                //   label: "Terutang saat Jatuh Tempo / pembayaran",
                // },
              ],
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              theme: "filled",
              style: { width: 200 },
              disabled: true,
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Dasar Hukum",
              key: "dasarHukum",
              theme: "filled",
              style: { width: 200 },
              disabled: true,
            },
          ],
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Pengenaan Pajak PPN",
          key: "ppn",
          // hintMessage: 'Daftar Tarif',
          defaultData: {
            dasarHukum: "-",
            keterangan: "-",
            saatTerutangPajak: "Terutang saat disediakan untuk pembayaran",
          },
          layout: "table",
          style: { minWidth: 1500 },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Kode Objek Pajak",
              key: "kodeObjekPajakId",
              theme: "filled",
              type: "number",
              validation: "required",
              style: { width: 500 },
              options: kodeObjekPajakList.filter((x) => x.tipe.includes("PPN")),
            },
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Saat Terutang Pajak",
              key: "saatTerutangPajak",
              theme: "filled",
              validation: "required",
              // disabled: true,
              style: { width: 410 },
              options: [
                {
                  value: "Terutang saat disediakan untuk pembayaran",
                  label: "Terutang saat disediakan untuk pembayaran",
                },
                // {
                //   value: "Pembayaran",
                //   label: "Terutang saat pembayaran",
                // },
                // {
                //   value: "Jatuh tempo",
                //   label: "Terutang saat jatuh tempo",
                // },
              ],
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              theme: "filled",
              style: { width: 200 },
              disabled: true,
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Dasar Hukum",
              key: "dasarHukum",
              theme: "filled",
              style: { width: 200 },
              disabled: true,
            },
          ],
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        try {
          data = Utils.formNormalize(data);
          data.pengenaanPajaks = data.pph
            .map((x) => {
              x.jenisKOP = "PPH";
              return Utils.formNormalize(x);
            })
            .concat(
              data.ppn.map((x) => {
                x.jenisKOP = "PPN";
                return Utils.formNormalize(x);
              })
            );
          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push("/onpremise/barang-dan-jasa");
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject("sipStore", "navigationStore")(observer(ItemForm));
