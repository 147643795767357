import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './PenyusutanPeriodeAset.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'
import {
  Caption,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableContainer
} from "@react-md/table";
import PenyusutanPeriodeAkunService from './PenyusutanPeriodeAkun.service'

var _baseId = "mod-table-penyusutan-periode-aset"

const PenyusutanPeriodeAsetTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  match
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [sum, setSum] = useState({
    koreksiFiskal: 0,
    penyusutanFiskal: 0,
    penyusutanKomersial: 0
  })

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  useEffect(() => {
    async function initData() {
      const res = await PenyusutanPeriodeAkunService.http.get(PenyusutanPeriodeAkunService.path+"/sum", {
        'penyusutanPeriodeId.equals': match.params.penyusutanPeriodeId,
        'akunId.equals': match.params.akunId
      })
      setSum(res.data)
    }
    initData()
  }, ['match.params.penyusutanPeriodeId'])


  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Penyusutan & Amortisasi Per Aset'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Nama Aset',
          searchable: true,
          key: 'namaAset',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.aset.nama)
        },
        {
          label: 'Nomor Aset',
          searchable: true,
          key: 'nomorAset',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.aset.nomor)
        },
        {
          label: 'Quantity',
          searchable: true,
          key: 'qty',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.qty))
        },
        {
          label: 'Penyusutan Fiskal',
          searchable: true,
          key: 'penyusutanFiskal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.penyusutanFiskal))
        },
        {
          label: 'Nilai Buku Awal',
          searchable: true,
          key: 'nilaiBukuAwal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.nilaiBukuAwal))
        },
        {
          label: 'Nilai Buku Akhir',
          searchable: true,
          key: 'nilaiBukuAkhir',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.nilaiBukuAkhir))
        },

      ]}
      actions={[
        // new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/penyusutan-periode-aset/baru'), true)
        new TableWrapper.action('Back', 'mdi mdi-arrow-left', () => { window.history.back() })
      ]}
      itemActions={[
        // new TableWrapper.action('Buka Menu Penyusutan', 'mdi mdi-book-open', (item) => {
        //   history.push(`/onpremise/migrasi/${item.id}`)
        // }, true),
        // new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/penyusutan-periode-aset/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        query['penyusutanPeriodeId.equals'] =match.params.penyusutanPeriodeId
        query['akunId.equals'] =match.params.akunId
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
      tableFooter={<Table style={{width:'100%'}} >
      <TableBody fullWidth>
        <TableRow>
          <TableCell>Jumlah Penyusutan dan Amortisasi Fiskal</TableCell>
          <TableCell style={{textAlign: 'right'}}>{Utils.numberFormat(sum.penyusutanFiskal)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Jumlah Penyusutan dan Amortisasi Komersial</TableCell>
          <TableCell style={{textAlign: 'right'}}>{Utils.numberFormat(sum.penyusutanKomersial)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Jumlah Koreksi Fiskal</TableCell>
          <TableCell style={{textAlign: 'right'}}>{Utils.numberFormat(sum.koreksiFiskal)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default PenyusutanPeriodeAsetTable
