
import React, {useEffect, useState} from 'react'
import { inject, observer } from 'mobx-react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import markdownFile from './toc-pajakku.md'
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Dialog,
  DialogContainer,
  DialogContent,
  DialogFooter,
  Button
} from 'react-md'
import ReactMarkdown from 'react-markdown'
import t from 'counterpart'
import OpLoginService from './OpLogin.service'

const OpRegister = ({ authStore, modalStore, envStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [md, setMd] = useState()
  const [dialog, setDialog] = useState(false)

  const baseName = `login`
  const basePath = `login`

  useEffect(() => {
    fetch(markdownFile).then((response) => response.text()).then((text) => {
      setMd(text)
    })
  }, [])

  var syarat = async ()=> {
    setDialog(true)
  }

  return (
    <>
      <div className="App mpk-full viewport-height account">
        <div className="bg">
          <div className="parallelogram-1"></div>
          <div className="parallelogram-2"></div>
          <div className="parallelogram-3"></div>
          <div className="parallelogram-4"></div>
          <div className="parallelogram-5"></div>
          <Dialog
            visible={dialog}
            disableFocusOnMount={true}
            style={{
              width: 800
            }}
            onRequestClose={()=> setDialog(false)}
          >
            <DialogContent>
              <div ><ReactMarkdown children={md} /></div>
            </DialogContent>
            <DialogFooter>
              <Button onClick={()=> setDialog(false)}>Tutup</Button>
            </DialogFooter>
          </Dialog>
          <div style={{ textAlign: 'center', marginTop: '100px', height: 54, }}>
            <img src="./images/sip-picturemark-d.svg" style={{ position: 'relative', height: 64, width: 92 }} />
            <img src="./images/sip-logotype-d.svg" style={{ position: 'relative', height: 45, margin: 9.5 }} />
          </div>
          <FormWrapper 
            showCommandbar={false}
            style={{
              maxWidth: '20%',
              padding: 40,
              position: 'relative',
              marginTop: '36px',
            }}
            defaultData={data.content}
            className="fullWidth"
            baseId={`mod-form-${baseName}`}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: 'Email',
                key: 'email',
                type: 'text',
                validation: 'required',
              },
              {
                inputType: inputTypes.INPUT,
                label: 'Username',
                key: 'login',
                type: 'text',
                validation: 'required',
              },
              {
                inputType: inputTypes.INPUT,
                label: 'Password',
                key: 'password',
                type: 'password',
                validation: 'required',
              },
              {
                render: (
                  <p style={{ textAlign: 'center' }}>
                    Dengan melakukan sign-in, saya menyatakan telah membaca, mengerti, dan menyetujui <a style={{ color: 'blue' }} onClick={()=> syarat()}>syarat dan ketentuan</a> yang berlaku
                  </p>
                )
              }
            ]}
            submitLabel="Register"
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button onClick={()=> window.location.href=`/#/login`}>Login</Button>
                )
              }
            ]}
            onSubmit={ async (values, callback )=> {
              try {
                values.name = values.login
                let res = await OpLoginService.register(values)
                setData({ loading: false, content: {} })
                callback("Registrasi Berhasil.", false)
              } catch(e){
                callback(e.response.data.detail, true, false)
              }
            }}
          >
          </FormWrapper>
        </div>
      </div>
    </>
  )
}

export default inject('authStore', 'envStore', 'modalStore')(observer(OpRegister))
