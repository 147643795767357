import React, { useEffect, useState } from "react";
import { TableWrapper, FormWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./ReturPenjualanAkun.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import { inject, observer } from 'mobx-react'
import { DataForm } from '../../../libs/react-mpk/components'
import iziToast from 'izitoast'
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-Transaksi";

const ReturPenjualanAkunTable = ({
  className = "",
  showCommandbar = true,
  history,
  sipStore
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});
  const [uploadModal, setUploadModal] = useState(false)

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  function _closeUploadModal() {
    setUploadModal(false)
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title=""
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Tanggal Transaksi",
            searchable: true,
            key: "tanggal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tanggal,
          },
          {
            label: "Nomor Transasksi (GL)",
            searchable: true,
            key: "nomor",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nomor,
          },
          {
            label: "Kelengkapan Data",
            searchable: true,
            key: "kelengkapanData",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.kelengkapanData,
          },
          {
            label: "Status Approve",
            searchable: true,
            key: "approveStatus",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.approveStatus,
          },
          {
            label: "Status Sanding",
            searchable: true,
            key: "sandingStatus",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sandingStatus,
          },
          {
            label: "Lawan Transaksi",
            searchable: true,
            key: "lawanTransaksi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nama,
          },
          {
            label: "Keterangan",
            searchable: true,
            key: "keterangan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.keterangan,
          },
          {
            label: 'Total',
            searchable: true,
            key: 'total',
            type: TableWrapper.dataTypes.STRING,
            render: item => (Utils.numberFormat(item.total))
          },
          // {
          //   label: 'PPN',
          //   searchable: true,
          //   key: 'ppn',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (Utils.numberFormat(item.ppn))
          // },
          // {
          //   label: 'PPnBM',
          //   searchable: true,
          //   key: 'ppnbm',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (Utils.numberFormat(item.ppnbm))
          // },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => history.push("/onpremise/retur-penjualan-akun/baru"),
            true
          ),
          new TableWrapper.action('Import POS', 'mdi mdi-import', () => setUploadModal(true), true),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => history.push(`/onpremise/retur-penjualan-akun/${item.id}`),
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
      <DataForm
        baseId={"pos-import"}
        asDialog={true}
        defaultData={{}}
        definitions={[
          {
            inputType: FormWrapper.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            theme: "filled",
            style: { width: 200 },
          },
        ]}
        visible={uploadModal}
        submitLabel="Import Data"
        onSubmit={async (d, callback) => {
          var formData = new FormData();
          formData.set("file", d.file);
          var uploadRes = await service.http.post("/upload", formData);
          if (uploadRes.data) {
            var importRes = await service.http.post("/pos-import", {
              nama: uploadRes.data.name,
              origin: uploadRes.data.origin,
              perusahaanId: sipStore.companyId,
              ext: "xls",
            });
          }
          callback(
            "Data sedang di import. Mohon pantau progress import di menu log import.",
            false
          );
        }}
        onRequestClose={_closeUploadModal}
      />
    </>
  );
};

export default inject('sipStore', 'navigationStore')(observer(ReturPenjualanAkunTable))
