import CrudCustom from '../../../libs/custom/CrudCustom';
import http from './../../../libs/custom/httpService.custom';

let MemberService = new CrudCustom('/perusahaan-users');

MemberService.getRole = async(query)=> {
    return http.request({
      method: http.methods.GET,
      url: `/roles?size=10000&perusahaanId.equals=${query.perusahaanId}`,
    })
}

MemberService.getRoleId = async(query)=> {
  return http.request({
    method: http.methods.GET,
    url: `/roles?size=10000`,
  })
}

MemberService.getUser = async(query)=> {
    return http.request({
      method: http.methods.GET,
      url: `/users?size=10000`,
    })
}

MemberService.getPerusahaan = async(query)=> {
    return http.request({
      method: http.methods.GET,
      url: `/perusahaans?size=10000`,
    })
}

MemberService.getMember = async()=> {
  return http.request({
    method: http.methods.GET,
    url: `/perusahaan-users-current`,
  })
}

export default MemberService;
