import React, { useEffect, useState } from "react";
import { TableWrapper, FormWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./TransaksiPerjanjianPembelian.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import { DataForm } from "../../../libs/react-mpk/components";
import iziToast from "izitoast";
import queryString from "query-string";
import { Chip } from "react-md";

var _baseId = "mod-table-Transaksi";

const TransaksiPerjanjianPembelianTable = ({
  className = "",
  showCommandbar = true,
  history,
  sipStore,
  location,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});
  const [uploadModal, setUploadModal] = useState(false);
  const [title, setTitle] = useState("Transaksi Perjanjian Pembelian/Penjualan");
  const [query, setQuery] = useState({});

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    try {
      var r = await service.delete(confirmDeleteData.id);
      toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
      callback();
      setConfirmDelete(false);
      TableWrapper.reload(_baseId);
    } catch (e) {
      callback();
    }

  }

  function _closeUploadModal() {
    setUploadModal(false);
  }

  useEffect(() => {

    var browserParams = queryString.parse(location.search);
    if (browserParams && browserParams.title) {
      setTitle("Transaksi Pembelian [AKUN " + browserParams.title + "]");
      setQuery(browserParams);
    }
  }, [location.search]);

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={title}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Tanggal Transaksi",
            searchable: true,
            key: "nama",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tanggal,
          },
          {
            label: "Jenis Transaksi",
            searchable: true,
            key: "jenisTransaksi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.jenisTransaksi,
          },
          {
            label: "Jenis Dokumen",
            searchable: true,
            key: "jenisDokumen",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.jenisDokumen,
          },
          {
            label: "Nomor Transaksi",
            searchable: true,
            key: "nomor",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nomor,
          },
          {
            label: "Lawan Transaksi",
            searchable: true,
            key: "lawanTransaksi",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.lawanTransaksi.nama,
          },
          {
            label: "Realisasi Dokumen",
            searchable: true,
            key: "sumberDokumen",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.realisasiDokumen?item.realisasiDokumen.map((value, i) => <Chip style={{marginRight: 2}} key={value.id} onClick={()=> {history.push(`/onpremise/transaksi-perjanjian-pembelian/${value.pembelianId}`)}}>{value.nomorPembelian}</Chip>):null,
          },
          {
            label: "status",
            searchable: true,
            key: "status",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.status,
          },
          {
            label: "Keterangan",
            searchable: true,
            key: "keterangan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.keterangan,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => history.push("/onpremise/transaksi-perjanjian-pembelian/baru"),
            true
          ),
          new TableWrapper.action(
            "Import POS",
            "mdi mdi-import",
            () => setUploadModal(true),
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => history.push(`/onpremise/transaksi-perjanjian-pembelian/${item.id}`),
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          query["perusahaanId.equals"] = sipStore.companyId;
          query["periodeFiskalId.equals"] = sipStore.periodeId;

          var browserParams = queryString.parse(location.search);
          if (browserParams && browserParams.akunId) {
            query["akunId.equals"] = browserParams.akunId;
          }

          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
      <DataForm
        baseId={"pos-import"}
        asDialog={true}
        defaultData={{}}
        definitions={[
          {
            inputType: FormWrapper.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            theme: "filled",
            style: { width: 200 },
          },
        ]}
        visible={uploadModal}
        submitLabel="Import Data"
        onSubmit={async (d, callback) => {
          var formData = new FormData();
          formData.set("file", d.file);
          var uploadRes = await service.http.post("/upload", formData);
          if (uploadRes.data) {
            var importRes = await service.http.post("/pos-import", {
              nama: uploadRes.data.name,
              origin: uploadRes.data.origin,
              perusahaanId: sipStore.companyId,
              ext: "xls",
            });
          }
          callback(
            "Data sedang di import. Mohon pantau progress import di menu log import.",
            false
          );
        }}
        onRequestClose={_closeUploadModal}
      />
    </>
  );
};

export default inject(
  "sipStore",
  "navigationStore"
)(observer(TransaksiPerjanjianPembelianTable));
