import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TipePengurangPajak.service'

const TipePengurangPajakForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})

  // initial data
  useEffect(() => {
    async function initData() {
      if(match.params.id == 'baru') {
        setData({loading: false, content: {}})
        return true
      }
      const res = await service.getOne(match.params.id)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/tipe-pengurang-pajak`}
      baseId="mod-form-sample"
      title={t.translate('modules.tipePengurangPajak.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT,
          label: 'Kode',
          key: 'kode',
          type: 'text',
          width: '50%',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '50%',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nilai Minimal',
          key: 'nilaiMinimal',
          type: 'text',
          width: '50%',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Nilai Maksimal',
          key: 'nilaiMaksimal',
          type: 'text',
          width: '50%',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Nilai Pengurang Flat',
          key: 'nilaiPengurangFlat',
          type: 'text',
          width: '50%',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Referensi',
          key: 'referensi',
          type: 'text',
          width: '50%',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Tarif Pengurang Persen',
          key: 'tarifPengurangPersen',
          type: 'text',
          width: '50%',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Tipe Tarif',
          key: 'tipeTarif',
          type: 'text',
          width: '50%',
          required: true,
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          } 
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/tipe-pengurang-pajak')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(TipePengurangPajakForm))
