import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './TarifPph29.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-tarif-pph-29"

const TarifPph29Table = ({
  className       = '',
  showCommandbar  = true,
  history
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={t.translate('modules.tarifPph29.title')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Mulai Berlaku (Tahun)',
          searchable: true,
          key: 'tahunMulaiBerlaku',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tahunMulaiBerlaku)
        },{
          label: 'Sampai Berlaku (Tahun)',
          searchable: true,
          key: 'tahunAkhirBerlaku',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tahunAkhirBerlaku)
        },{
          label: 'Tarif (%)',
          searchable: true,
          key: 'tarifPajak',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tarifPajak)
        },{
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/tarif-pph-29/baru'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/tarif-pph-29/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default TarifPph29Table
