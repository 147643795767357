import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { TabsWrapper } from "../../../libs/react-mpk/wrapper";
import service from "./Pegawai.service";
import PerusahaanListService from "../../master/Perusahaan/Perusahaan.service";
import PegawaiGajiService from "../PegawaiGaji/PegawaiGaji.service";
import EntitasService from "../../master/Entitas/Entitas.service";
import TipePegawaiService from "../../adminpajakku/tipePegawai/TipePegawai.service";
import PerhitunganPPh21LainnyaService from "../PerhitunganPPh21Lainnya/PerhitunganPPh21Lainnya.service";
import PenambahPhBrutoService from "../../master/Pendapatan/Pendapatan.service";
import PengurangPhBrutoService from "../../master/Pengurang/Pengurang.service";
import NegaraService from "../../adminpajakku/negara/Negara.service";
import MasterLevelService from "../../master/MasterLevel/MasterLevel.service";
import Utils from "../../../libs/custom/Utils";
import { ID_TIPE_BUPOT, ID_TIPE_PEGAWAI } from "../../../clientConstant";
import { ListItem } from "react-md";

const PegawaiForm = ({
  envStore,
  match,
  history,
  data,
  onSubmit,
  sipStore,
  navigationStore,
  setShowInfoPegawai,
  setTipePegawai,
}) => {
  const { inputTypes, definition } = FormWrapper;

  const [perusahaanList, setPerusahaanList] = useState([]);
  const [pegawaiGajiList, setPegawaiGajiList] = useState([]);
  const [entitasList, setEntitasList] = useState([]);
  const [entitasMap, setEntitasMap] = useState([]);
  const [tipePegawaiList, setTipePegawaiList] = useState([]);
  const [perhitunganPPh21LainnyaList, setPerhitunganPPh21LainnyaList] =
    useState([]);
  const [negaraList, setNegaraList] = useState([]);
  const [negaraListFull, setNegaraListFull] = useState([]);
  const [negaraMap, setNegaraMap] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [customRules, setCustomeRules] = useState({});
  const [status, setStatus] = "";
  var options = [
    { label: "Normal", value: "0" },
    {
      label:
        "Dipindahkan ke kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama",
      value: "1",
    },
    {
      label:
        "Berhenti ke pemberi kerja lain/Berhenti tidak meninggalkan Indonesia/Berhenti karena pensiun",
      value: "2",
    },
    {
      label:
        "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",
      value: "3",
    },
    { label: "Pegawai baru mulai bekerja", value: "4" },
    {
      label:
        "Pegawai Pindahan dari kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama/Pegawai Baru dari pemberi kerja lain/Pegawai Baru saja Pensiun untuk 21-100-02",
      value: "5",
    },
  ];

  // initial data
  useEffect(() => {
    async function initData() {
      localStorage.setItem("option", JSON.stringify(options));
      if (match.params.id != "baru") {
        setIsEdit(true);
        const res = await service.getOne(match.params.id);
        if (res.data.domisiliWajibPajak == "DN") {
          setCustomeRules({
            nik: ["required", "size:16"],
            npwp: ["required", "size:15"],
          });
        }
        if (res.data.domisiliWajibPajak == "LN") {
          setCustomeRules({
            nik: ["required", "size:7"],
            npwp: ["required", "size:15"],
          });
        }
      }
      await _getPerusahaanList();
      await _getPegawaiGajiList();

      await _getTipePegawaiList();
      await _getPerhitunganPPh21LainnyaList();
      await _getNegaraList();
    }
    initData();
  }, ["match.params.id"]);

  useEffect(() => {
    _getEntitasList();
  }, [data.loading]);

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get("/my-perusahaans");
    setPerusahaanList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getPegawaiGajiList() {
    var res = await PegawaiGajiService.get();
    setPegawaiGajiList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getEntitasList() {
    var res = await EntitasService.get({
      size: 10000,
      "perusahaanId.equals": sipStore.companyId,
      sort: 'npwp,ASC'
    });
    if (match.params.id != "baru" && data.content.entitas) {
      console.log("baru", data);
      setEntitasList(
        res.data
          .filter((d) => d.npwp === data.content.entitas.npwp)
          .map((x) => ({ value: x.id, label: x.nama, children: <ListItem secondaryText={`${x.npwp} - ${x.tipeEntitas}`}>{x.nama}</ListItem>, }))
      );
    } else {
      setEntitasList(res.data.map((x) => ({ value: x.id, label: x.nama, children: <ListItem secondaryText={`${x.npwp} - ${x.tipeEntitas}`}>{x.nama}</ListItem>, })));
    }

    setEntitasMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _getTipePegawaiList() {
    var res = await TipePegawaiService.get();
    setTipePegawaiList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getPerhitunganPPh21LainnyaList() {
    var res = await PerhitunganPPh21LainnyaService.get();
    setPerhitunganPPh21LainnyaList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getNegaraList() {
    var res = await NegaraService.get({ size: 10000 });
    setNegaraList(res.data.map((x) => ({ value: x.nama, label: x.nama })));
    setNegaraListFull(res.data.map((x) => ({ value: x.nama, label: x.nama })));
    setNegaraMap(
      res.data.reduce((pv, cv) => {
        pv[cv.nama] = cv;
        return pv;
      }, {})
    );
  }

  return (
    <FormWrapper
      noValidate
      customRules={customRules}
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      showCommandbar={false}
      backTo={`/onpremise/pegawai`}
      baseId="pegawai-form-0"
      title={t.translate("modules.pegawai.formTitle")}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName="mdi mdi-arrow-right"
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if (key == "negara") {
          newObj.kodeNegara = negaraMap[value] ? negaraMap[value].kode1 : "";
        }

        if (key == "domisiliWajibPajak") {
          if (value == "DN") {
            setNegaraList(negaraListFull);
            newObj.negara = "Indonesia";
            newObj.kodeNegara = "IDN";
            setIsDisabled(true);
            setCustomeRules({
              nik: ["required", "size:16"],
              npwp: ["required", "size:15"],
            });
          } else {
            setNegaraList(negaraListFull.filter((x) => x.label != "Indonesia"));
            newObj.negara = "";
            newObj.kodeNegara = "";
            setIsDisabled(false);
            setCustomeRules({
              nik: ["required", "size:7"],
              npwp: ["required", "size:15"],
            });
          }
        }

        if (key == "entitasCheck__id") {
          newObj.entitas = entitasMap[value] ? entitasMap[value] : {};
          newObj.alias = entitasMap[value] ? entitasMap[value].alias : "";
        }

        if (key == "tipePegawai__id") {
          setTipePegawai(value);
          if (value == ID_TIPE_PEGAWAI.TETAP) {
            setShowInfoPegawai(true);
          } else {
            setShowInfoPegawai(false);
          }
        }

        if (key == "status") {
          if (value == "AKTIF") {
            options = [
              { label: "Normal", value: "0" },
              {
                label:
                  "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",
                value: "03",
              },
              { label: "Pegawai baru mulai bekerja", value: "04" },
              {
                label:
                  "Pegawai Pindahan dari kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama/Pegawai Baru dari pemberi kerja lain/Pegawai Baru saja Pensiun untuk 21-100-02",
                value: "05",
              },
            ];
          } else {
            options = [
              {
                label:
                  "Dipindahkan ke kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama",
                value: "01",
              },
              {
                label:
                  "Berhenti ke pemberi kerja lain/Berhenti tidak meninggalkan Indonesia/Berhenti karena pensiun",
                value: "02",
              },
              {
                label:
                  "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",
                value: "03",
              },
            ];
          }
          localStorage.setItem("option", JSON.stringify(options));
          localStorage.setItem("statusAktif", value);
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "INFO DASAR",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Perusahaan",
          key: "perusahaanId",
          type: "text",
          width: "100%",
          validation: "required",
          options: perusahaanList,
          disabled: sipStore.companyId != null,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Entitas",
          key: "entitasCheck__id",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          options: entitasList,
          validation: "required",
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Alias",
          key: "alias",
          type: "text",
          width: "49%",
          disabled: true,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Tipe Pegawai",
          key: "tipePegawai__id",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          options: tipePegawaiList,
          validation: "required",
        },
        {
          inputType: inputTypes.SELECT,
          label: "STATUS",
          key: "status",
          type: "text",
          width: "49%",
          // style: {marginRight: '2%'},
          options: [
            { label: "AKTIF", value: "AKTIF" },
            { label: "TIDAK AKTIF", value: "TIDAK_AKTIF" },
          ],
          validation: "required",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nama Pegawai",
          key: "nama",
          type: "text",
          width: "100%",
          validation: "required",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Domisili Wajib Pajak",
          key: "domisiliWajibPajak",
          type: "text",
          width: "49%",
          validation: "required",
          style: { marginRight: "2%" },
          options: [
            { value: "DN", label: "DN" },
            { value: "LN", label: "LN" },
          ],
        },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: "Negara",
          key: "negara",
          type: "text",
          width: "49%",
          data: negaraList,
          validation: "required",
          // disabled: (d) => d.domisiliWajibPajak == 'DN'
          disabled: isDisabled,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: "##.###.###.#-###.###",
          maskChar: "_",
          maskValue: "string",
          label: "NPWP",
          key: "npwp",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: "################",
          maskChar: "_",
          maskValue: "string",
          label: "NIK",
          key: "nik",
          type: "text",
          width: "49%",
          validation: "required",
          show: (d) => d.domisiliWajibPajak == "DN",
        },
        {
          inputType: inputTypes.INPUT,
          // mask: '################',
          // maskChar: '_',
          // maskValue: 'string',
          label: "Passport",
          key: "nik",
          type: "text",
          width: "49%",
          validation: "required",
          show: (d) => d.domisiliWajibPajak == "LN",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Jenis Kelamin",
          key: "jenisKelamin",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          options: [
            { label: "Laki - Laki", value: "L" },
            { label: "Perempuan", value: "P" },
          ],
        },
        {
          inputType: inputTypes.SELECT,
          label: "PTKP",
          key: "ptkp",
          type: "text",
          width: "49%",
          validation: "required",
          options: [
            { label: "TK/0", value: "TK/0" },
            { label: "TK/1", value: "TK/1" },
            { label: "TK/2", value: "TK/2" },
            { label: "TK/3", value: "TK/3" },
            { label: "K/0", value: "K/0" },
            { label: "K/1", value: "K/1" },
            { label: "K/2", value: "K/2" },
            { label: "K/3", value: "K/3" },
            { label: "KI/0", value: "KI/0" },
            { label: "KI/1", value: "KI/1" },
            { label: "KI/2", value: "KI/2" },
            { label: "KI/3", value: "KI/3" },
          ],
        },
        {
          inputType: inputTypes.INPUT,
          label: "Tempat Lahir",
          key: "tempatLahir",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal Lahir",
          key: "tanggalLahir",
          width: "49%",
          // show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP
        },
        {
          inputType: inputTypes.INPUT,
          label: "Alamat",
          key: "alamat",
          type: "text",
          width: "100%",
          validation: "required",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Kecamatan",
          key: "kecamatan",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.INPUT,
          label: "Kelurahan",
          key: "kelurahan",
          type: "text",
          width: "49%",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Provinsi",
          key: "provinsi",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.INPUT,
          label: "Kota",
          key: "kota",
          type: "text",
          width: "49%",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Telepon",
          key: "telepon",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.INPUT,
          label: "Kode Pos",
          key: "kodePos",
          type: "text",
          width: "49%",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Agama",
          key: "agama",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.INPUT,
          label: "Email",
          key: "email",
          type: "text",
          width: "49%",
          validation: "email",
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Kode Negara',
        //   key: 'kodeNegara',
        //   type: 'text',
        //   width: '100%',
        //   disabled: true
        // },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Perhitungan PPh 21 Lainnya',
        //   key: 'perhitunganPPh21LainnyaId',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'},
        //   options: perhitunganPPh21LainnyaList
        // },
        {
          inputType: inputTypes.INPUT,
          label: "Keterangan",
          key: "keterangan",
          type: "text",
          width: "100%",
        },
        // -----
      ]}
      onSubmit={onSubmit}
    />
  );
};

const PekerjaanForm = ({
  envStore,
  match,
  history,
  data,
  onSubmit,
  sipStore,
}) => {
  const { inputTypes, definition } = FormWrapper;

  const [isEdit, setIsEdit] = useState(false);
  const [entitasList, setEntitasList] = useState([]);
  const [entitasListPindahKe, setEntitasListFullPindahKe] = useState([]);
  const [entitasMap, setEntitasMap] = useState([]);
  const [option, setOption] = useState([]);
  const [customRules, setCustomeRules] = useState({});
  const [masterLevelList, setMasterLevelList] = useState([]);

  useEffect(() => {
    async function initData() {
      var options = JSON.parse(localStorage.getItem("option"));
      setOption(options);
      if (match.params.id != "baru") {
        setIsEdit(true);
        const res = await service.getOne(match.params.id);
        localStorage.setItem("statusAktif", res.data.status);
        // if(res.data.gaji.statusPindahan == "3" && res.data.status == "AKTIF"){
        //   setCustomeRules({gaji__tanggalKeluar: ''})
        // } else {
        //   setCustomeRules({gaji__tanggalKeluar: ['required']})
        // }
      }
      await _getEntitasList();
      await _getMasterLevelList();
    }
    initData();
  }, ["match.params.id"]);

  async function _getEntitasList() {
    var res = await EntitasService.http.get('/entitas-list', {
      size: 10000,
      "perusahaanId.equals": sipStore.companyId,
      sort: 'npwp,ASC'
    });
    setEntitasList(res.data.map((x) => ({ value: x.id, label: x.nama })));
    setEntitasListFullPindahKe(res.data);
    setEntitasMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _getMasterLevelList() {
    var res = await MasterLevelService.get({
      "perusahaanId.equals": sipStore.companyId,
    });
    setMasterLevelList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  return (
    <FormWrapper
      noValidate
      customRules={customRules}
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      showCommandbar={false}
      backTo={`/onpremise/pegawai`}
      baseId="pegawai-form-1"
      title={t.translate("modules.pegawai.formTitle")}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName="mdi mdi-arrow-right"
      defaultData={data.content}
      onSubmit={onSubmit}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if (key == "pindahKe__id") {
          newObj.pindahKe__npwp = entitasMap[value]
            ? entitasMap[value].npwp
            : "";
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "INFO PEKERJAAN",
        },
        {
          inputType: inputTypes.INPUT,
          label: "NIP",
          key: "nip",
          type: "text",
          width: "100%",

          validation: "required",
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Divisi",
          key: "divisi",
          type: "text",
          style: { marginRight: "2%" },
          width: "49%",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Sub Divisi",
          key: "departemen",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.INPUT,
          label: "Jabatan",
          key: "jabatan",
          type: "text",
          width: "49%",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Level Pegawai",
          key: "level__id",
          type: "text",
          width: "49%",
          options: masterLevelList,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Status Pindahan",
          key: "gaji__statusPindahan",
          type: "text",
          validation: "required",
          show: (d) => data.content.status == "AKTIF",
          options: [
            { label: "Normal", value: "00" },
            {
              label:
                "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",
              value: "03",
            },
            { label: "Pegawai baru mulai bekerja", value: "04" },
            {
              label:
                "Pegawai Pindahan dari kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama/Pegawai Baru dari pemberi kerja lain/Pegawai Baru saja Pensiun untuk 21-100-02",
              value: "05",
            },
          ],
        },
        {
          inputType: inputTypes.SELECT,
          label: "Status Pindahan",
          key: "gaji__statusPindahan",
          type: "text",
          validation: "required",
          show: (d) => data.content.status == "TIDAK_AKTIF",
          options: [
            {
              label:
                "Dipindahkan ke kantor pusat/kantor cabang lainnya dengan pemberi kerja yang sama",
              value: "01",
            },
            {
              label:
                "Berhenti ke pemberi kerja lain/Berhenti tidak meninggalkan Indonesia/Berhenti karena pensiun",
              value: "02",
            },
            {
              label:
                "Meninggal Dunia/Berhenti meninggalkan Indonesia selamanya/Pegawai dari LN yang baru berada di Indonesia",
              value: "03",
            },
          ],
        },
        {
          inputType: inputTypes.SELECT,
          label: "Entitas Baru",
          key: "pindahKe__id",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          options: entitasListPindahKe
            .filter((x) => x.npwp != data.content.entitas.npwp)
            .map((x) => ({
              label: `(${x.npwp}) ${x.nama}`,
              value: x.id,
              children: <ListItem secondaryText={`${x.npwp} - ${x.tipeEntitas}`}>{x.nama}</ListItem>,
            })),
          validation: [{ required_if: ["gaji__statusPindahan", "01"] }],
          show: (d) => d.gaji__statusPindahan == "01",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: "##.###.###.#-###.###",
          maskChar: "_",
          maskValue: "string",
          label: "NPWP Entitas Baru",
          key: "pindahKe__npwp",
          type: "text",
          width: "49%",
          validation: "required",
          disabled: true,
          validation: [{ required_if: ["gaji__statusPindahan", "01"] }],
          show: (d) => d.gaji__statusPindahan == "01",
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal Masuk",
          key: "gaji__tanggalMasuk",
          width: "49%",
          validation: "required",
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal Keluar",
          key: "gaji__tanggalKeluar",
          show: (d) => data.content.status == "TIDAK_AKTIF",
          validation: [
            { required_if: ["gaji__statusPindahan", "01"] },
            { required_if: ["gaji__statusPindahan", "02"] },
            // { required_if: ["gaji__statusPindahan", "03"] },
          ],
          width: "49%",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Pernah Bekerja diperusahaan lain",
          key: "gaji__adaKantorLama",
          type: "text",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Memiliki A1 Dari perusahaan lain",
          key: "gaji__adaA1KantorLama",
          type: "text",
          show: (d) => d.gaji__adaKantorLama,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Netto A1",
          key: "gaji__nettoA1Sebelumnya",
          width: "49%",
          style: { marginRight: "2%" },
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          show: (d) => d.gaji__adaA1KantorLama && d.gaji__adaKantorLama,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "PPh A1",
          key: "gaji__pphA1Sebelumnya",
          width: "49%",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          show: (d) => d.gaji__adaA1KantorLama && d.gaji__adaKantorLama,
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "BPJS",
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "BPJS Kesehatan",
          key: "gaji__bpjsKes",
          type: "number",
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "BPJS Kesehatan Ditanggung Perusahaan",
          key: "gaji__bpjsKesDitanggungPrshn",
          type: "number",
          show: (d) =>
            d.gaji__bpjsKes && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Dasar Perhitungan BPJS Kesehatan",
          key: "gaji__dasarHitungBPJSKes",
          type: "text",
          width: "49%",
          // validation: "required",
          style: { marginRight: "2%" },
          show: (d) =>
            d.gaji__bpjsKes && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          options: [
            { label: "GAJI", value: "GAJI" },
            { label: "LAINNYA", value: "LAINNYA" },
          ],
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nominal Lainnya jika dipilih",
          key: "gaji__nominalDasarHitungBPJSKes",
          width: "49%",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          show: (d) =>
            d.gaji__dasarHitungBPJSKes == "LAINNYA" &&
            d.gaji__bpjsKes &&
            d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          validation: [
            { required_if: ["gaji__dasarHitungBPJSKes", "LAINNYA"] },
          ],
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "BPJS Ketenagakerjaan",
          key: "gaji__bpjstk",
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kelompok BPJS",
          key: "gaji__bpjstkKelompok",
          show: (d) =>
            d.gaji__bpjstk && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          options: [
            { value: "I", label: "KELOMPOK 1" },
            { value: "II", label: "KELOMPOK 2" },
            { value: "III", label: "KELOMPOK 3" },
            { value: "IV", label: "KELOMPOK 4" },
            { value: "V", label: "KELOMPOK 5" },
          ],
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "BPJS Ketenagakerjaan Ditanggung Perusahaan",
          key: "gaji__bpjsDitanggungPmrth",
          show: (d) =>
            d.gaji__bpjstk && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Dasar Perhitungan BPJS Ketenagakerjaan",
          key: "gaji__dasarHitungBPJSTK",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          // validation: "required",
          show: (d) =>
            d.gaji__bpjstk && d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          options: [
            { label: "GAJI", value: "GAJI" },
            { label: "LAINNYA", value: "LAINNYA" },
          ],
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nominal Lainnya jika dipilih",
          key: "gaji__nominalDasarHitungBPJSTK",
          width: "49%",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          show: (d) =>
            d.gaji__dasarHitungBPJSTK == "LAINNYA" &&
            d.gaji__bpjstk &&
            d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
          validation: [{ required_if: ["gaji__dasarHitungBPJSTK", "LAINNYA"] }],
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Mendapat Jaminan Pensiun",
          key: "gaji__dapatJaminanPensiun",
          type: "number",
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP,
        },
      ]}
    />
  );
};

const PegawaiGajiForm = ({
  envStore,
  match,
  history,
  data,
  onSubmit,
  sipStore,
  tipePegawai,
}) => {
  const { inputTypes, definition } = FormWrapper;

  const [penambahPhBrutoList, setPenambahPhBrutoList] = useState([]);
  const [pengurangPhBrutoList, setPengurangPhBrutoList] = useState([]);

  const [penambahPhBrutoMap, setPenambahPhBrutoMap] = useState({});
  const [pengurangPhBrutoMap, setPengurangPhBrutoMap] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    async function initData() {
      if (match.params.id != "baru") {
        setIsEdit(true);
      }
      await _getPenambahPhBrutoList();
      await _getPengurangPhBrutoList();
    }
    initData();
  }, ["match.params.id", "tipePegawai"]);

  async function _getPenambahPhBrutoList() {
    var res = await PenambahPhBrutoService.get({
      "perusahaanId.equals": sipStore.companyId,
      size: 10000,
    });
    setPenambahPhBrutoMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setPenambahPhBrutoList(
      res.data
        .filter((x) => {
          console.log(x, "x.tipePenambahPhBruto");
          if (tipePegawai == ID_TIPE_PEGAWAI.TETAP) {
            return (
              !x.tipePenambahPhBruto ||
              x.tipePenambahPhBruto.kodeObjekPajak.kode == "21-100-01" ||
              x.tipePenambahPhBruto.kodeObjekPajak.kode == "21-100-02" ||
              x.tipePenambahPhBruto.kodeObjekPajak.kategoriPendapatan == "Final"
            );
          } else {
            return (
              !x.tipePenambahPhBruto ||
              (x.tipePenambahPhBruto.kodeObjekPajak.kategoriPendapatan ==
                "Tidak Final" &&
                !(
                  x.tipePenambahPhBruto.kodeObjekPajak.kode == "21-100-01" ||
                  x.tipePenambahPhBruto.kodeObjekPajak.kode == "21-100-02"
                )) ||
              x.tipePenambahPhBruto.kodeObjekPajak.kategoriPendapatan == "Final"
            );
          }
        })
        .map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getPengurangPhBrutoList() {
    var res = await PengurangPhBrutoService.get({
      "perusahaanId.equals": sipStore.companyId,
      size: 10000,
    });
    setPengurangPhBrutoMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setPengurangPhBrutoList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      showCommandbar={false}
      backTo={`/onpremise/pegawai`}
      baseId="pegawai-form-2"
      title={t.translate("modules.pegawai.formTitle")}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onSubmit={onSubmit}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if (key == "penambahPhBruto__id") {
          newObj.gaji__penambahs[indexAtParent].penambahPhBruto =
            penambahPhBrutoMap[value];
        }

        if (key == "pengurangPhBruto__id") {
          newObj.gaji__pengurangs[indexAtParent].pengurangPhBruto =
            pengurangPhBrutoMap[value];
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "NILAI PENGHASILAN DAN PEMOTONGAN",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Metode Pemotongan Pajak",
          key: "gaji__metodePotongPajak",
          type: "text",
          validation: "required",
          options: [
            { label: "Nett", value: "Nett" },
            { label: "Gross", value: "Gross" },
            { label: "Gross Up", value: "Gross Up" },
          ],
        },

        {
          inputType: inputTypes.DIVIDER,
          label: "KARYAWAN TIDAK TETAP",
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP,
        },

        {
          inputType: inputTypes.SELECT,
          label: "Tipe Penghasilan",
          key: "perhitunganPPh21Lainnya__tipePenghasilan",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: [
            { required_if: ["tipePegawai__id", ID_TIPE_PEGAWAI.TIDAK_TETAP] },
          ],
          options: [
            { label: "BERKESINAMBUNGAN", value: "BG" },
            { label: "TIDAK BERKESINAMBUNGAN", value: "TBG" },
          ],
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Dipotong Oleh",
          key: "perhitunganPPh21Lainnya__dipotongOleh",
          type: "text",
          width: "49%",
          validation: [
            { required_if: ["perhitunganPPh21Lainnya__tipePenghasilan", "BG"] },
          ],
          options: [
            { label: "SATU PEMBERI KERJA", value: "SPK" },
            { label: "LEBIH DARI SATU PEMBERI KERJA", value: "LSPK" },
          ],
          show: (d) =>
            d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP &&
            d.perhitunganPPh21Lainnya__tipePenghasilan == "BG",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Cara Pembayaran",
          key: "perhitunganPPh21Lainnya__caraPembayaran",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: [
            { required_if: ["tipePegawai__id", ID_TIPE_PEGAWAI.TIDAK_TETAP] },
          ],
          options: [
            { label: "BULANAN", value: "B" },
            { label: "TIDAK BULANAN", value: "TB" },
          ],
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Jumlah Hari Kerja",
          key: "perhitunganPPh21Lainnya__jumlahHariKerja",
          type: "number",
          width: "49%",
          validation: [
            { required_if: ["tipePegawai__id", ID_TIPE_PEGAWAI.TIDAK_TETAP] },
          ],
          show: (d) => d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Golongan PNS",
          key: "perhitunganPPh21Lainnya__golonganPNS",
          type: "text",
          options: [
            { label: "I", value: "I" },
            { label: "II", value: "II" },
            { label: "III", value: "III" },
            { label: "IV", value: "IV" },
          ],
          // show: (d)=> d.tipePegawai__id == ID_TIPE_PEGAWAI.TIDAK_TETAP
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Pegawai Gaji Penambah",
          key: "gaji__penambahs",
          elementKey: "gaji__penambahs",
          layout: "table",
          style: { minWidth: 1000 },
          defaultData: {
            keterangan: "-",
          },
          conditionStyle: (data) => {
            if (data.penambahPhBruto && data.penambahPhBruto.bukanSlipGaji) {
              return { background: "#fff3e0" };
            }
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Penambah Ph Bruto",
              key: "penambahPhBruto__id",
              theme: "filled",
              validation: "required",
              options: penambahPhBrutoList,
              style: { width: 400 },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Nilai",
              key: "nilai",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              style: { width: 200 },
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              theme: "filled",
              style: { width: 200 },
            },
          ],
        },
        {
          render: (d) => {
            var totalPenambah = 0;
            if (d.gaji__penambahs) {
              d.gaji__penambahs.forEach((x) => {
                if (
                  x.penambahPhBruto &&
                  !x.penambahPhBruto.bukanSlipGaji &&
                  x.nilai
                ) {
                  totalPenambah += parseInt(x.nilai);
                }
              });
            }
            return (
              <div style={{ margin: "20px", width: "100%", fontSize: "20px" }}>
                <hr />
                <div className="mpk-flex align-start direction-row">
                  Total Penambah : <div className="flex" />
                  {Utils.numberFormat(totalPenambah)}
                </div>
              </div>
            );
          },
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Pegawai Gaji Pengurang",
          key: "gaji__pengurangs",
          elementKey: "gaji__pengurangs",
          layout: "table",
          style: { minWidth: 1000 },
          defaultData: {
            keterangan: "-",
          },
          conditionStyle: (data) => {
            if (data.penambahPhBruto && data.penambahPhBruto.bukanSlipGaji) {
              return { background: "#fff3e0" };
            }
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Pengurang Ph Bruto",
              key: "pengurangPhBruto__id",
              theme: "filled",
              validation: "required",
              options: pengurangPhBrutoList,
              style: { width: 400 },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Nilai",
              key: "nilai",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              style: { width: 200 },
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Keterangan",
              key: "keterangan",
              theme: "filled",
              style: { width: 200 },
            },
          ],
        },
        {
          render: (d) => {
            var totalPenambah = 0;
            if (d.gaji__penambahs) {
              d.gaji__penambahs.forEach((x) => {
                if (
                  x.penambahPhBruto &&
                  !x.penambahPhBruto.bukanSlipGaji &&
                  x.nilai
                ) {
                  totalPenambah += parseInt(x.nilai);
                }
              });
            }
            var totalPengurang = 0;
            if (d.gaji__pengurangs) {
              d.gaji__pengurangs.forEach((x) => {
                if (
                  x.pengurangPhBruto &&
                  !x.pengurangPhBruto.bukanSlipGaji &&
                  x.nilai
                ) {
                  totalPengurang += parseInt(x.nilai);
                }
              });
            }
            return (
              <div style={{ margin: "20px", width: "100%", fontSize: "20px" }}>
                <hr />
                <div className="mpk-flex align-start direction-row">
                  Total Pengurang : <div className="flex" />
                  {Utils.numberFormat(totalPengurang)}
                </div>
                <div className="mpk-flex align-start direction-row">
                  THP : <div className="flex" />
                  {Utils.numberFormat(totalPenambah - totalPengurang)}
                </div>
              </div>
            );
          },
        },
      ]}
    />
  );
};

const PegawaiWrapper = ({
  envStore,
  match,
  history,
  sipStore,
  navigationStore,
}) => {
  // initial data
  const [data, setData] = useState({ loading: true, content: {} });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [entitasMap, setEntitasMap] = useState([]);
  const [tipePegawai, setTipePegawai] = useState("");

  async function _getEntitasList() {
    var res = await EntitasService.get({
      size: 10000,
      "perusahaanId.equals": sipStore.companyId,
    });
    setEntitasMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }
  const handleTabChange = (index) => {
    window.__trigger_from_tab = true;
    window.__trigger_tab_index = index;

    console.log(setShowInfoPegawai, currentIndex, "asdasd");
    setTimeout(() => {
      if (
        (showInfoPegawai && currentIndex !== 2) ||
        (!showInfoPegawai && currentIndex !== 1)
      ) {
        const f = document.getElementById(
          `pegawai-form-${currentIndex}-form-trigger`
        );
        if (f) f.click();
      } else {
        setCurrentIndex(index);
      }
    }, 200);
  };
  const [showInfoPegawai, setShowInfoPegawai] = useState(true);

  useEffect(() => {
    async function initData() {
      await _getEntitasList();
      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            kodeNegara: "",
            alias: "",
            perusahaanId: sipStore.companyId,
            gaji__pengurangs: [],
            gaji__penambahs: [],
            gaji__adaKantorLama: false,
            gaji__pphA1Sebelumnya: 0,
            gaji__adaA1KantorLama: false,
            gaji__nettoA1Sebelumnya: 0,
            gaji__dapatJaminanPensiun: false,
            gaji__bpjstk: false,
            gaji__bpjsDitanggungPmrth: false,
            gaji__dasarHitungBPJSTK: "GAJI",
            gaji__dasarHitungBPJSKes: "GAJI",
            gaji__bpjstkKelompok: "I",
            gaji__nominalDasarHitungBPJSTK: 0,
            gaji__bpjsKes: false,
            gaji__bpjsKesDitanggungPrshn: false,
            gaji__nominalDasarHitungBPJSKes: 0,
            gaji__dapatJaminanPensiun: false,
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);

      if (res.data.entitasCabang) {
        res.data.entitasCheck__id = res.data.entitasCabang.id;
      } else {
        res.data.entitasCheck__id = res.data.entitas.id;
      }

      if (res.data.gaji && res.data.gaji.pengurangs) {
        res.data.gaji.pengurangs.forEach((d) => {
          Utils.formSerialize(d);
        });
      } else {
        res.data.gaji.pengurangs = [];
      }
      if (res.data.gaji && res.data.gaji.penambahs) {
        res.data.gaji.penambahs.forEach((d) => {
          Utils.formSerialize(d);
        });

        if (res.data.perhitunganPPh21Lainnya) {
          res.data.perhitunganPPh21Lainnya = Utils.formSerialize(
            res.data.perhitunganPPh21Lainnya
          );
        }
      } else {
        res.data.gaji.penambahs = [];
      }
      res.data = Utils.formSerialize(res.data);

      if (!res.data.gaji__dasarHitungBPJSTK)
        res.data.gaji__dasarHitungBPJSTK = "Gaji";
      if (!res.data.gaji__dasarHitungBPJSKes)
        res.data.gaji__dasarHitungBPJSKes = "Gaji";

      setTipePegawai(res.data.tipePegawai__id);
      if (res.data.tipePegawai__id == ID_TIPE_PEGAWAI.TETAP) {
        setShowInfoPegawai(true);
      } else {
        setShowInfoPegawai(false);
      }
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  return (
    <TabsWrapper
      title="Pegawai"
      showCommandbar={true}
      onActiveIndexChange={handleTabChange}
      activeIndex={currentIndex}
      baseId="mod-details-sample"
      actions={[
        {
          label: "Back",
          iconClassName: "mdi mdi-arrow-left",
          onClick: () => history.push("/onpremise/pegawai"),
        },
      ]}
      tabs={[
        {
          label: "Info Dasar",
          key: "nested-form",
          render: (
            <PegawaiForm
              data={data}
              match={match}
              history={history}
              sipStore={sipStore}
              navigationStore={navigationStore}
              setShowInfoPegawai={setShowInfoPegawai}
              setTipePegawai={setTipePegawai}
              onSubmit={async (d, callback) => {
                // var res = {}
                // if(match.params.id == 'baru') {
                //   res = await service.post(data)
                // } else {
                //   res = await service.put(data)
                // }

                // callback(t.translate('global.dataTelahDiSimpan'), false)
                // history.push('/onpremise/pegawai')

                setData({ loading: false, content: { ...data.content, ...d } });
                callback("", false);
                console.log(
                  window.__trigger_from_tab,
                  window.__trigger_tab_index,
                  "ahhahaaaaaaaas"
                );
                if (!window.__trigger_from_tab) {
                  setCurrentIndex(1);
                } else {
                  setCurrentIndex(window.__trigger_tab_index);
                }
                window.__trigger_from_tab = false;
              }}
            />
          ),
        },
        {
          label: "Info Pekerjaan",
          key: "data-pekerjaan",
          show: showInfoPegawai,
          render: (
            <PekerjaanForm
              data={data}
              match={match}
              history={history}
              sipStore={sipStore}
              navigationStore={navigationStore}
              onSubmit={async (d, callback) => {
                // var res = {}
                // if(match.params.id == 'baru') {
                //   res = await service.post(data)
                // } else {
                //   res = await service.put(data)
                // }

                // callback(t.translate('global.dataTelahDiSimpan'), false)
                // history.push('/onpremise/pegawai')

                setData({ loading: false, content: { ...data.content, ...d } });
                callback("", false);
                if (!window.__trigger_from_tab) {
                  setCurrentIndex(2);
                } else {
                  setCurrentIndex(window.__trigger_tab_index);
                }
                window.__trigger_from_tab = false;
              }}
            />
          ),
        },
        {
          label: "Nilai Penghasilan dan Pemotongan",
          key: "data-gaji",
          render: (
            <PegawaiGajiForm
              tipePegawai={tipePegawai}
              data={data}
              match={match}
              history={history}
              sipStore={sipStore}
              onSubmit={async (d, callback) => {
                console.log(d, "asdasdasd");
                setData({ loading: false, content: { ...data.content, ...d } });
                var res = {};
                try {
                  var ndata = Utils.formNormalize({ ...data.content, ...d });
                  console.log(ndata, "asdasd");
                  if (ndata.entitasCheck__id) {
                    var en = entitasMap[ndata.entitasCheck__id];
                    if (en.tipeEntitas == "Cabang Pembantu") {
                      ndata.entitasCabang__id = en.id;
                      ndata.entitasCabang = { id: en.id };
                      ndata.entitas__id = en.parentId;
                      ndata.entitas = { id: en.parentId };
                    } else {
                      ndata.entitas__id = en.id;
                      ndata.entitas = { id: en.id };
                      ndata.entitasCabang__id = en.id;
                      ndata.entitasCabang = { id: en.id };
                    }
                  }

                  ndata.gaji.penambahs.forEach((d) => {
                    Utils.formNormalize(d);
                  });
                  ndata.gaji.pengurangs.forEach((d) => {
                    Utils.formNormalize(d);
                  });
                  if (match.params.id == "baru") {
                    res = await service.post(ndata);
                  } else {
                    res = await service.put(ndata);
                  }

                  callback(t.translate("global.dataTelahDiSimpan"), false);
                  history.push("/onpremise/pegawai");
                } catch (e) {
                  console.log(e, "asdasd");
                  callback(e, true, false);
                }
              }}
            />
          ),
        },
      ]}
    />
  );
};

export default inject("sipStore", "navigationStore")(observer(PegawaiWrapper));
