import CrudCustom from '../../libs/custom/CrudCustom';
import http from './../../libs/custom/httpService.custom';
import axios from 'axios'

let OpLoginService = new CrudCustom('/account');

OpLoginService.me = async(query)=> {
    var id_token = localStorage.getItem("id_token")
    return http.request({
      method: http.methods.GET,
      url: `/user`,
      query: {},
      config: {
        headers: {
          'Authorization': 'Bearer '+id_token
        }
      }
    })
}

OpLoginService.login = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/sign-in`,
    data
  })
}

OpLoginService.resetPassword = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/user/reset-password/init`,
    data
  })
}

OpLoginService.register = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/user/register`,
    data
  })
}

OpLoginService.changePassword = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/user/change-password`,
    data
  })
}

OpLoginService.changePasswordAdmin = async(data)=> {
  return http.request({
    method: http.methods.POST,
    url: `/user/change-password-admin/${data.id}`,
    data
  })
}

OpLoginService.http = http;


// OpLoginService.logout = async(data)=> {
//   return http.request({
//     method: http.methods.POST,
//     url: `/sign-in`,
//     data
//   })
// }

// /api/v1/sign-in

// OpLoginService.getRoleId = async(query)=> {
//   return http.request({
//     method: http.methods.GET,
//     url: `/roles?size=10000`,
//   })
// }

// OpLoginService.getUser = async(query)=> {
//     return http.request({
//       method: http.methods.GET,
//       url: `/users?size=10000`,
//     })
// }

// OpLoginService.getPerusahaan = async(query)=> {
//     return http.request({
//       method: http.methods.GET,
//       url: `/perusahaans?size=10000`,
//     })
// }

// OpLoginService.getMember = async(query)=> {
//   return http.request({
//     method: http.methods.GET,
//     url: `/perusahaan-users?page=${query.page}&size=${query.size}`,
//   })
// }

export default OpLoginService;
