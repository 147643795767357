import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './LawanTransaksi.service'
import PerusahaanListService from '../Perusahaan/Perusahaan.service'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import TableSample from '../../TableSample'
import LawanTransaksiPajakTable from '../LawanTransaksiPajak/LawanTransaksiPajak.table'
import NegaraService from '../../adminpajakku/negara/Negara.service'
import PengecualianPajakService from '../../adminpajakku/pengecualianPajak/PengecualianPajak.service'
import izitoast from 'izitoast'
import Utils from '../../../libs/custom/Utils'

const LawanTransaksiForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [perusahaanList, setPerusahaanList] = useState([])
  const [LawanTransaksiList, setLawanTransaksiList] = useState([])
  const [LawanTransaksiMap, setLawanTransaksiMap] = useState([])
  const [negaraList, setNegaraList] = useState([])
  const [negaraListFull, setNegaraListFull] = useState([])
  const [negaraMap, setNegaraMap] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [dataUp, setDataUp] = useState({})
  const [isDisabled, setIsDisabled] = useState(true)

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getNegaraList()
      await _getLawanTransaksiList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
          tarifs:[],
          lawanTransaksiPICS: []
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      setIsEdit(true)
      res.data = Utils.formSerialize(res.data)
      setDataUp(res.data)
      setData({loading: false, content:res.data})
      localStorage.setItem("lt", JSON.stringify(res.data))
    }
    initData()
  }, ['match.params.id'])

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getLawanTransaksiList() {
    var res = await service.get({size: 10000})
    setLawanTransaksiList(res.data.map(x => ({value: x.id, label: x.nama})))
    setLawanTransaksiMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getNegaraList() {
    var res = await NegaraService.get({size: 10000})
    setNegaraList(res.data.map(x => ({value: x.nama, label: x.nama})))
    setNegaraListFull(res.data.map(x => ({value: x.nama, label: x.nama})))
    setNegaraMap(res.data.reduce((pv, cv) => {
      pv[cv.nama] = cv
      return pv
    }, {}))
  }

  const form =  (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      className="mpk-padding-N padding-bottom"
      showCommandbar={match.params.id == 'baru' || data.content.tipeLawanTransaksi == 'Cabang Pembantu'}
      backTo={`/onpremise/lawan-transaksi`}
      baseId="mod-form-sample"
      title={''}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'negara') {
          newObj.kodeNegara = negaraMap[value]?negaraMap[value].kode2:''
        }

        if(key == 'domisiliWajibPajak') {
          if(value == 'DN') {
            setNegaraList(negaraListFull)
            newObj.negara = 'Indonesia'
            newObj.kodeNegara = 'ID'
            setIsDisabled(true)
          } else {
            setNegaraList(negaraListFull.filter(x => x.label != 'Indonesia'))
            newObj.negara = ''
            newObj.kodeNegara = ''
            setIsDisabled(false)
          }
        }

        // if(key == 'tipeLawanTransaksi') {
        //   if(value == 'HO') {
        //     newObj.nama = sipStore.company.nama
        //     newObj.alias = sipStore.company.alias
        //     newObj.email = sipStore.company.email
        //     newObj.npwp = sipStore.company.npwp
        //     newObj.alamat = sipStore.company.alamat
        //     newObj.kecamatan = sipStore.company.kecamatan
        //     newObj.kelurahan = sipStore.company.kelurahan
        //     newObj.propinsi = sipStore.company.propinsi
        //     newObj.Kota = sipStore.company.Kota
        //     newObj.telepon = sipStore.company.telepon
        //     newObj.kodePos = sipStore.company.kodePos
        //   }
        // }

        if(key == 'parentId') {
          newObj.npwp = LawanTransaksiMap[value]?LawanTransaksiMap[value].npwp:''
        }

        setDataUp(newObj)
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# INFORMASI GENERAL',
        },{
          inputType: inputTypes.SELECT,
          label: 'Tipe Wajib Pajak',
          key: 'tipeWajibPajak',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: 'required',
          options: [{value: 'Badan', label: 'Badan'}, {value: 'Orang Pribadi', label: 'Orang Pribadi'}],
          disabled: isEdit
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# INFORMASI GENERAL',
        },{
          inputType: inputTypes.SELECT,
          label: 'Domisili Wajib Pajak',
          key: 'domisiliWajibPajak',
          type: 'text',
          width: '49%',
          validation: 'required',
          style: {marginRight: '2%'},
          options: [{value: "DN", label: "DN"}, {value: "LN", label: "LN"}],
          disabled: isEdit
        },{
          inputType: inputTypes.AUTOCOMPLETE,
          label: 'Negara',
          key: 'negara',
          type: 'text',
          width: '49%',
          data: negaraList,
          validation: 'required',
          // disabled: (d) => d.domisiliWajibPajak == 'DN'
          disabled: isDisabled
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP Lawan Transaksi',
          key: 'npwp',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '################',
          maskChar: '_',
          maskValue: 'string',
          label: 'NIK',
          key: 'nik',
          type: 'text',
          width: '100%',
          disabled: isEdit,
          show: (d) => d.domisiliWajibPajak == 'DN' && d.tipeWajibPajak == 'Orang Pribadi',
          validation: [{required_if: ['tipeWajibPajak', 'Orang Pribadi']}]
        },{
          inputType: inputTypes.INPUT,
          // mask: '################',
          // maskChar: '_',
          // maskValue: 'string',
          label: 'Passport',
          key: 'nik',
          type: 'text',
          width: '100%',
          disabled: isEdit,
          show: (d) => d.domisiliWajibPajak == 'LN' && d.tipeWajibPajak == 'Orang Pribadi',
          validation: [{required_if: ['tipeWajibPajak', 'Orang Pribadi']}]
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Lawan Transaksi',
          key: 'supplierId',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit,
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama Lawan Transaksi',
          key: 'nama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
        },{
          inputType: inputTypes.INPUT,
          label: 'Alias',
          key: 'alias',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Telepon',
          key: 'telepon',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required'
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'email',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'email'
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Alamat',
          key: 'alamat',
          type: 'text',
          width: '100%',
          validation: 'required'
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kelurahan',
          key: 'kelurahan',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kecamatan',
          key: 'kecamatan',
          type: 'text',
          width: '49%',
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kota',
          key: 'kota',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Provinsi',
          key: 'provinsi',
          type: 'text',
          width: '49%',
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Pos',
          key: 'kodePos',
          type: 'text',
          width: '49%',
          // required: true,
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Kode Negara',
        //   key: 'kodeNegara',
        //   type: 'text',
        //   width: '100%',
        //   style: {marginRight: '2%'},
        //   disabled: true
        // },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# PIC Penanggung Jawab',
          key: 'lawanTransaksiPICS',
          layout: 'table',
          style: {width: 700},
          // defaultData: {
          //   keterangan: '-'
          // },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: 'Nama',
              key: 'nama',
              theme: 'filled',
              style: {width: 500},
              validation: 'required'
            },
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/lawan-transaksi')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )

  if(match.params.id != 'baru' && data.content.tipeLawanTransaksi != 'Cabang Pembantu') {
    return <TabsWrapper
      title="Lawan Transaksi"
      showCommandbar={true}
      baseId="mod-details-sample-aad"
      actions={[
        {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/onpremise/lawan-transaksi')}
      ]}
      onChange={ (tab, d) => {
        console.log(tab, 'asd', d)
        navigationStore.redirectTo(`/onpremise/lawan-transaksi/${match.params.id}/${tab.key}`)
        d()
      }}
      tabs={[
        {
          label: 'Data',
          key: 'form',
          render: form
        },
        {
          label: 'Periode',
          key: 'periode-form',
          render: (
            <LawanTransaksiPajakTable history={history} lawanTransaksiId={match.params.id} />
          )
        },
      ]}
      // onChange={ tab => {
      //   // navigationStore.redirectTo(`/${envStore.env.applicationType}/details/${tab.key}`)
      // }}
    />
  } else {
    return form
  }
}

export default inject('sipStore', 'navigationStore')(observer(LawanTransaksiForm))
