import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Role.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'

var _baseId = "mod-table-role"

const RoleTable = ({
	className = '',
	showCommandbar = true,
	history,
	authStore
}) => {

	const [confirmDelete, setConfirmDelete] = useState(false)
	const [confirmDeleteData, setConfirmDeleteData] = useState({})

	function _closeConfirmDelete() {
		setConfirmDelete(false)
	}
	async function _submitConfirmDelete(callback) {
		try {
			var r = await service.delete(confirmDeleteData.id)
			toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
			setConfirmDelete(false)
			TableWrapper.reload(_baseId)
		} catch (e) {
			toast.info('Perusahaan sedang di pakai, sehingga tidak dapat di hapus')
			setConfirmDelete(false)
			callback()
		}
	}

	return (
		<>
			<TableWrapper
				baseId={_baseId}
				title={t.translate('modules.Role.title')}
				className={className}
				// hintMessage={t.translate('modules.table.hint.message')}
				hintIconClassName="mdi mdi-information"
				hintMore={t.translate('modules.table.hint.more')}
				defaultData={[]}
				defaultSortBy="createdDate"
				columns={[
					{
						label: 'Nama',
						searchable: true,
						key: 'nama',
						type: TableWrapper.dataTypes.STRING,
						render: item => (item.nama)
					},
					{
						label: 'Deskripsi',
						searchable: true,
						key: 'description',
						type: TableWrapper.dataTypes.STRING,
						render: item => (item.description)
					},
				]}
				actions={[
					new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/role/baru'),  true
          // authStore.user.roleMap?authStore.user.roleMap['Role'].Create:false
          )
				]}
				itemActions={[
					new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/role/${item.id}`), true
          // authStore.user.roleMap?authStore.user.roleMap['Role'].Edit:false
          ),
					new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true) }, true
          // authStore.user.roleMap?authStore.user.roleMap['Role'].Delete:false
          )
				]}
				onFetchData={async query => {
          console.log(authStore.user)
					return service.get(query)
				}}
				showCommandbar={showCommandbar}
				showFilterPeriod={false}
			/>
			<Modal.Confirm
				// title="Hapus Data"
				visible={confirmDelete}
				onRequestClose={_closeConfirmDelete}
				onSubmit={_submitConfirmDelete}
			>Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
		</>
	)
}

export default inject("authStore")(observer(RoleTable))
