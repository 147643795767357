import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './DetailModal.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'
import {AKUN_JENIS_MAP} from '../../../clientConstant'
import { inject, observer } from 'mobx-react'

var _baseId = "mod-table-detail-modal"


const DetailModalTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  transkripData,
  akunList,
  sipStore,
  authStore
}) => {


  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [total, setTotal] = useState(0)
  const [totalAwal, setTotalAwal] = useState(0)

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  useEffect(() => {

  }, [transkripData])


  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Migrasi'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      tableFooter={<>
        <div className="mpk-flex align-center direction-row justify-start flex">
          <div className="flex">Total Nilai</div> <b>{Utils.numberFormat(total)}</b>

        </div>
        <div className="mpk-flex align-center direction-row justify-start flex">
          <div className="flex">Jumlah modal yang tercatat pada saldo Awal</div> <b>{Utils.numberFormat(totalAwal)}</b>
        </div>
        <div className="mpk-flex align-center direction-row justify-start flex">
          <div className="flex">Selisih</div> <b>{Utils.numberFormat((totalAwal-total))}</b>
        </div>
        </>
      }
      columns={[
        {
          label: 'Nama',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nama)
        },
        {
          label: 'NPWP',
          searchable: true,
          key: 'npwp',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.npwp)
        },
        {
          label: 'Alamat',
          searchable: true,
          key: 'alamat',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.alamat)
        },
        {
          label: 'Nilai',
          searchable: true,
          key: 'nilai',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.nilai))
        },
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/detail-modal/baru/'+match.params.migrasiTranskripId), authStore.user.roleMap?authStore.user.roleMap['Migrasi'].Create:false)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/detail-modal/${item.id}/${match.params.migrasiTranskripId}`), authStore.user.roleMap?authStore.user.roleMap['Migrasi'].Edit:false),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, authStore.user.roleMap?authStore.user.roleMap['Migrasi'].Delete:false)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        query['periodeFiskalId.equals'] = sipStore.periodeId
        // query.migrasiTranskripId = match.params.migrasiTranskripId
        var total = await service.http.get("/detail-modals/sum", query)
        setTotal(total.data.nilai)

        var akunMap = akunList.filter(a => a.akunJenisId == AKUN_JENIS_MAP['Akun List Modal'].id).reduce((pv, cv)=> {
          pv[cv.id] = cv
          return pv
        }, {});

        var t = 0
        transkripData.saldoList.forEach((v)=> {
          v.saldoNeracaAkuns.filter(a => akunMap[a.akun.id]).forEach((a)=> {
            t = t+a.nilaiAwal
          })
        })
        setTotalAwal(t)
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default inject('sipStore', 'navigationStore', "authStore")(observer(DetailModalTable))
