import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./TransaksiPajakWp.service";
import KategoriPPh25Service from "../../adminpajakku/kategoriPph25/KategoriPph25.service";
import TipePengecualianPajakService from "../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service";
import Utils from "../../../libs/custom/Utils";
import AkunService from "../../master/Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import TransaksiService from "./Transaksi.service";
import TransaksiItemService from "./VTransaksiItem.service";
import { TP_PAJAK, KELOMPOK_PENGHASILAN_MAP } from "../../../clientConstant";
import ItemService from "../../master/Item/Item.service";
import { List, ListItem } from "@react-md/list";
import PPh21FinalService from "../PPh21Final/PPh21Final.service";
import PPh21TidakFinalService from "../PPh21TidakFinal/PPh21TidakFinal.service";
import {
  Caption,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@react-md/table";
import AkunPengenaanPajakService from "../../master/AkunPengenaanPajak/AkunPengenaanPajak.service";
import TransaksiPembelianService from "./TransaksiPembelian.service";

const bpsMap = {};

const TransaksiPajakWpForm = ({ envStore, match, history, sipStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({
    loading: true,
    content: { transaksiPajakWpPph21s: [] },
  });
  const [akunList, setAkun] = useState([]);
  const [itemPembelian, setItemPembelian] = useState([]);
  const [itemPembelianMap, setItemPembelianMap] = useState({});
  const [journalMap, setJournalMap] = useState({});
  const [kodeObjekPajakListAll, setKodeObjekPajakListAll] = useState([]);
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([]);
  const [kodeObjekPajakMap, setKodeObjekPajakMap] = useState([]);
  const [transaksiItemList, setTransaksiItemList] = useState([]);
  const [transaksiItem, setTransaksiItem] = useState({});
  const [transaksiItemMap, setTransaksiItemMap] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [transaksi, setTransaksi] = useState({});
  const [itemList, setItemList] = useState([]);
  const [curTransaksiItem, setCurTransaksiItem] = useState({ items: [] });
  const [curData, setCurData] = useState({});
  const [curTransaksi, setCurTransaksi] = useState({});
  const [dataBps, setDataBps] = useState([]);
  const [limit, setLimit] = useState(0);

  // initial data
  useEffect(() => {
    async function initData() {
      // await _getItemList();

      if (match.params.transaksiId) {
        var transaksiRes = await _getAkun();
        if (transaksiRes.data) {
          transaksiRes.data.penjualan =
            transaksiRes.data.tags.search("TRANSAKSI_PENJUALAN") >= 0;
          setTransaksi(transaksiRes.data);
        }
      }

      if (match.params.pembelianId) {
        _getItem();
      }

      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            posId: match.params.postId,
            pembelianId: match.params.pembelianId,
            dipungut: true,
            dpp: "0",
            nilai: "0",
            keterangan: "-",
            transaksiPajakWpAkun__nilai: "0",
            transaksiPajakWpPph21s: [],
            transaksiPajakWpPengecualians: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      res.data = Utils.formSerialize(res.data);

      _getTransaksiPajakWPAll({
        transaksiId: match.params.transaksiId,
        posId: match.params.postId,
        pembelianId: match.params.pembelianId,
        itemId: res.data.item?res.data.item.id:null,
        jurnalId: res.data.jurnal?res.data.jurnal.id:null,
        nilai: res.data.jurnal?res.data.jurnal.nilai:(res.data.item?res.data.item.dpp:0)
      })

      if (res.data.posId) {
        _getTransaksiItem(res.data.posId);
      }
      setIsEdit(true);
      setCurData(res.data);
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId);
    setCurTransaksi(res.data);
    setJournalMap(
      res.data.transaksiJurnals.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setAkun(
      res.data.transaksiJurnals.map((x) => ({
        value: x.id,
        label: x.akun.nama,
      }))
    );

    return res;
  }

  async function _getItem() {
    var res = await TransaksiPembelianService.getOne(match.params.pembelianId);
    console.log("itemPembelian", res.data);
    res.data.transaksiLawanTransaksi = res.data.pembelianLawanTransaksi;
    setCurTransaksi(res.data);
    setItemPembelianMap(
      res.data.items.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setItemPembelian(
      res.data.items.map((x) => ({ value: x.id, label: x.nama }))
    );
    return res;
  }

  async function _getTransaksiItem(id) {
    var res = await TransaksiItemService.getOne(id);
    res.data.transaksiLawanTransaksi = res.data.posLawanTransaksi;

    setCurTransaksiItem(res.data);
  }


  async function _getItemList() {
    var res = await ItemService.get({ size: 10000 });
    setItemList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getTransaksiPajakWPAll({
    posId, transaksiId, pembelianId, jurnalId, itemId, nilai
  }) {
    var query = {
      size: 1000,
    }
    if(posId) {
      query['posId.equals'] = posId
    }
    if(transaksiId) {
      query['transaksiId.equals'] = transaksiId
    }

    if(pembelianId) {
      query['pembelianId.equals'] = pembelianId
    }

    var req = await service.get(query);

    if(jurnalId) {
      var l = req.data.reduce((pv, cv) => {
        console.log(cv.jurnal.id, jurnalId, 'jurnalId')
        if(cv.jurnal.id === jurnalId && match.params.id !== cv.id) {
          pv = pv - cv.dpp
        }
        return pv
      }, nilai)
      console.log(nilai, l, 'asdasd')
      setLimit(l)
    }

    if(itemId) {
      var l = req.data.reduce((pv, cv) => {
        if(cv.item.id === itemId && match.params.id !== cv.id) {
          pv = pv - cv.dpp
        }
        return pv
      }, nilai)
      setLimit(l)
    }
  }

  var backTo =
    "/onpremise/transaksi/" + match.params.transaksiId + "/pajak-wp";
  if (match.params.posId) {
    backTo =
      "/onpremise/transaksi-penjualan/" +
      match.params.posId +
      "/pajak-wp";
  }
  if (match.params.pembelianId) {
    backTo =
      "/onpremise/transaksi-pembelian/" +
      match.params.pembelianId +
      "/pajak-wp";
  }

  console.log((curData.item__dpp?curData.item__dpp:curData.jurnal__nilai?curData.jurnal__nilai:0), 'curdata')
  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={backTo}
      baseId="mod-form-sample"
      title="Pajak"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if (key == "jurnal__id") {
          newObj.jurnal__nilai = journalMap[value].nilai;
          newObj.jurnal = journalMap[value];
          newObj.dpp = journalMap[value].nilai;

          _getTransaksiPajakWPAll({transaksiId: match.params.transaksiId, jurnalId: value, nilai: journalMap[value].nilai})
        }

        if (key == "item__id") {
          newObj.item__dpp = itemPembelianMap[value].dpp;
          newObj.item = itemPembelianMap[value];
          _getTransaksiPajakWPAll({posId: match.params.posId, pembelianId: match.params.pembelianId, itemId: value, nilai: itemPembelianMap[value].dpp})
        }


        if (key == "posId") {
          setCurTransaksiItem(
            transaksiItemMap[value] ? transaksiItemMap[value] : { items: [] }
          );
          newObj.transaksiPajakWpItems = transaksiItemMap[value]
            ? transaksiItemMap[value].items.map((x) => ({
                item: x.item,
                item__id: x.item.id,
                nilai: x.hargaTotal,
              }))
            : [];
        }
        setCurData(newObj);
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "# AKUN TRANSAKSI",
          show: (d) => !match.params.pembelianId,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Akun",
          key: "jurnal__id",
          type: "text",
          width: "49%",
          required: true,
          // validation: 'required',
          options: akunList,
          style: { marginRight: "2%" },
          show: (d) => !match.params.pembelianId,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai",
          key: "jurnal__nilai",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          // validation: 'required|numeric',
          show: (d) => !match.params.pembelianId,
        },

        // ====

        {
          inputType: inputTypes.DIVIDER,
          label: "# BARANG/JASA",
          show: (d) => match.params.pembelianId,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Barang/JASA",
          key: "item__id",
          type: "text",
          width: "49%",
          required: true,
          // validation: 'required',
          options: itemPembelian,
          style: { marginRight: "2%" },
          show: (d) => match.params.pembelianId,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai",
          key: "item__dpp",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          // validation: 'required|numeric',
          show: (d) => match.params.pembelianId,
        },

        {
          inputType: inputTypes.DIVIDER,
          label: "# PAJAK TERUTANG",
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: "Tipa Pajak",
          key: "typePajak",
          type: "text",
          width: "100%",
          required: true,
          validation: "required",
          options: [
            { label: "PPN", value: "PPN" },
            { label: "Pasal 22", value: "Pasal 22" },
            { label: "Pasal 23/26", value: "Pasal 23/26" },
            { label: "Pasal 4a2", value: "Pasal 4a2" },
            { label: "Pasal 21/26", value: "Pasal 21/26" },
            { label: "Pasal 15", value: "Pasal 15" },
          ],
        },

        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nominal DPP",
          key: "dpp",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          style: { marginRight: "2%" },
          // validation: "",
          validation: 'required|numeric|max:'+limit
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "PPh/PPN",
          key: "nilai",
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          validation: "required|numeric",
        },
        {
          inputType: inputTypes.INPUT,
          label: "Keterangan",
          key: "keterangan",
          type: "text",
          width: "100%"
        },
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        try {
          if (match.params.transaksiId) {
            data.transaksi = {
              id: match.params.transaksiId,
            };
            data.transaksiId = match.params.transaksiId;
          }

          if (match.params.pembelianId) {
            data.pembelian = { id: match.params.pembelianId };
          }

          if (match.params.posId) {
            data.pos = { id: match.params.posId };
          }

          data = Utils.formNormalize(data);
          if (data.transaksiPajakWpItems) {
            data.transaksiPajakWpItems = data.transaksiPajakWpItems.map((x) =>
              Utils.formNormalize(x)
            );
          }
          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);

          if (match.params.transaksiId) {
            history.push(
              "/onpremise/transaksi/" +
                match.params.transaksiId +
                "/pajak-wp"
            );
          } else if (match.params.pembelianId) {
            history.push(
              "/onpremise/transaksi-pembelian/" +
                match.params.pembelianId +
                "/pajak-wp"
            );
          } else if (match.params.posId) {
            history.push(
              "/onpremise/transaksi-penjualan/" +
                match.params.posId +
                "/pajak-wp"
            );
          }
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject("envStore", "sipStore")(observer(TransaksiPajakWpForm));
