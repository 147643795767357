export const ID_CONS = {
  TIPE_INDUSTRI__MANUFAKTUR: "00000000-642d-21eb-9e01-71303b8ff076",
  TIPE_INDUSTRI__DAGANG: "00000001-642d-21eb-9f01-71303b8ff076",

  TIPE_TRANSKRIP__LABA_RUGI: "00000002-5a06-21eb-a701-45e67381d492",

  TIPE_AKUN__AKTIVA_1: "00000000-5a04-21eb-b901-132132205609",
  TIPE_AKUN__AKTIVA_2: "00000008-6448-21eb-9501-7b47d5cdb54b",
  TIPE_AKUN__AKTIVA_3: "0000000e-6448-21eb-9b01-7b47d5cdb54b",

  TIPE_AKUN__BEBAN_1: "00000004-6448-21eb-9101-7b47d5cdb54b",
  TIPE_AKUN__BEBAN_2: "0000000c-6448-21eb-9901-7b47d5cdb54b",
  TIPE_AKUN__BEBAN_3: "00000012-6448-21eb-9f01-7b47d5cdb54b",

  TIPE_AKUN__PENDAPATAN_1: "00000003-6448-21eb-9001-7b47d5cdb54b",
  TIPE_AKUN__PENDAPATAN_2: "0000000b-6448-21eb-9801-7b47d5cdb54b",
  TIPE_AKUN__PENDAPATAN_3: "00000011-6448-21eb-9e01-7b47d5cdb54b",

  TIPE_AKUN__MODAL_1: "00000002-5a04-21eb-bb01-132132205609",
  TIPE_AKUN__MODAL_2: "0000000a-6448-21eb-9701-7b47d5cdb54b",
  TIPE_AKUN__MODAL_3: "00000010-6448-21eb-9d01-7b47d5cdb54b",

  TIPE_AKUN__HUTANG_1: "00000001-5a04-21eb-ba01-132132205609",
  TIPE_AKUN__HUTANG_2: "00000009-6448-21eb-9601-7b47d5cdb54b",
  TIPE_AKUN__HUTANG_3: "0000000f-6448-21eb-9c01-7b47d5cdb54b",

  AKUN_JENIS__PERSEDIAAN: "00000000-6b4d-21eb-b901-9bae55efc9da",
};

export const ID_TIPE_METODE_PENYUSUTAN = {
  GL: "00000000-69eb-21eb-a501-77e5b90b7b50",
  SM: "00000001-69eb-21eb-a601-77e5b90b7b50",
};

export const ID_TIPE_PEGAWAI = {
  TETAP: "00000000-74d8-21eb-8a01-09c54ec88d28",
  TIDAK_TETAP: "00000001-74d8-21eb-8b01-09c54ec88d28",
};

export const ID_TIPE_BUPOT = {
  FINAL: "00000000-5a04-21eb-b901-132132205609",
  TIDAK_FINAL: "00000001-5a04-21eb-ba01-132132205609",
  A1: "00000002-5a04-21eb-bb01-132132205609",
};

export const KELOMPOK_PENGHASILAN_MAP = {
  "00000000-6b73-21eb-8501-ab962c431a27": {
    id: "00000000-6b73-21eb-8501-ab962c431a27",
    kode: "-",
    nama: "Bukan Objek Pajak",
    keterangan: "-",
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
  },
  "00000001-6b73-21eb-8601-ab962c431a27": {
    id: "00000001-6b73-21eb-8601-ab962c431a27",
    kode: "-",
    nama: "Final",
    keterangan: "-",
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
  },
  "00000002-6b73-21eb-8701-ab962c431a27": {
    id: "00000002-6b73-21eb-8701-ab962c431a27",
    kode: "-",
    nama: "Tidak Final",
    keterangan: "-",
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
  },
  "Bukan Objek Pajak": {
    id: "00000000-6b73-21eb-8501-ab962c431a27",
    kode: "-",
    nama: "Bukan Objek Pajak",
    keterangan: "-",
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
  },
  Final: {
    id: "00000001-6b73-21eb-8601-ab962c431a27",
    kode: "-",
    nama: "Final",
    keterangan: "-",
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
  },
  "Tidak Final": {
    id: "00000002-6b73-21eb-8701-ab962c431a27",
    kode: "-",
    nama: "Tidak Final",
    keterangan: "-",
    createdBy: null,
    createdDate: null,
    lastModifiedBy: null,
    lastModifiedDate: null,
  },
};

export const TIPE_TRANSKRIP = {
  neracaAktiva: "00000000-5a06-21eb-a501-45e67381d492",
  neracaPasiva: "00000001-5a06-21eb-a601-45e67381d492",
  labaRugi: "00000002-5a06-21eb-a701-45e67381d492",
};

export const TP_PAJAK = {
  pph21: "00000001-5a04-21eb-ba01-132132205609",
  "Pasal 21/26": "00000001-5a04-21eb-ba01-132132205609",
  "Pasal 23/26": "00000003-6448-21eb-9001-7b47d5cdb54b",
  "Pasal 22": "00000002-5a04-21eb-bb01-132132205609",
  "Pasal 4a2": "00000004-6448-21eb-9101-7b47d5cdb54b",
  "Pasal 15": "00000004-6448-21eb-9101-7b47d5cdb54c",
  PPN: "00000000-5a04-21eb-b901-132132205609",
  "Pasal 25": "00000002-5a04-21eb-bb01-132132205623",
};

export const AKUN_JENIS_MAP = {
  "Akun Persediaan": {
    id: "00000000-6b4d-21eb-b901-9bae55efc9da",
    nama: "Akun Persediaan",
    keterangan: "-",
  },
  "Akun Laba Ditahan": {
    id: "00000001-6b4d-21eb-ba01-9bae55efc9da",
    nama: "Akun Laba Ditahan",
    keterangan: "-",
  },
  "Akun Hutang Pajak": {
    id: "00000002-6b4d-21eb-bb01-9bae55efc9da",
    nama: "Akun Hutang Pajak",
    keterangan: "-",
  },
  "Akun Piutang Pajak": {
    id: "00000003-6b4d-21eb-bc01-9bae55efc9da",
    nama: "Akun Piutang Pajak",
    keterangan: "-",
  },
  "Akun Aktiva Tetap": {
    id: "00000004-6b4d-21eb-bd01-9bae55efc9da",
    nama: "Akun Aktiva Tetap",
    keterangan: "-",
  },
  "Akun Beban Entertainment": {
    id: "00000005-6b4d-21eb-be01-9bae55efc9da",
    nama: "Akun Beban Entertainment",
    keterangan: "-",
  },
  "Akun Beban Promosi": {
    id: "00000006-6b4d-21eb-bf01-9bae55efc9da",
    nama: "Akun Beban Promosi",
    keterangan: "-",
  },
  "Akun List Modal": {
    id: "00000007-6b4d-21eb-8001-9bae55efc9da",
    nama: "Akun List Modal",
    keterangan: "-",
  },
  "Akun Deviden": {
    id: "00000008-6b4d-21eb-8101-9bae55efc9da",
    nama: "Akun Deviden",
    keterangan: "-",
  },
  "Akun Penyerahan Barang / Jasa": {
    id: "00000009-6b4d-21eb-8201-9bae55efc9da",
    nama: "Akun Penyerahan Barang / Jasa",
    keterangan: "-",
  },
  "Akun Hutang Lainnya": {
    id: "0000000a-6b4d-21eb-8301-9bae55efc9da",
    nama: "Akun Hutang Lainnya",
    keterangan: "-",
  },
  "Akun Piutang Lainnya": {
    id: "0000000b-6b4d-21eb-8401-9bae55efc9da",
    nama: "Akun Piutang Lainnya",
    keterangan: "-",
  },
  "Akun Akumulasi Penyusutan": {
    id: "0000000c-6b4d-21eb-8402-9bae55efc9da",
    nama: "Akun Akumulasi Penyusutan",
    keterangan: "-",
  },
  "Akun Beban Penyusutan": {
    id: "0000000c-6b4d-21eb-8401-9bae55efc9da",
    nama: "Akun Beban Penyusutan",
    keterangan: "-",
  },
  "Akun Beban Amortisasi": {
    id: "0000000c-6b4d-21eb-8403-9bae55efc9da",
    nama: "Akun Beban Amortisasi",
    keterangan: "-",
  },
};

export const AKUN_JENIS_MAP_ID = {
  "00000000-6b4d-21eb-b901-9bae55efc9da": {
    id: "00000000-6b4d-21eb-b901-9bae55efc9da",
    nama: "Akun Persediaan",
    keterangan: "-",
  },
  "00000001-6b4d-21eb-ba01-9bae55efc9da": {
    id: "00000001-6b4d-21eb-ba01-9bae55efc9da",
    nama: "Akun Laba Ditahan",
    keterangan: "-",
  },
  "00000002-6b4d-21eb-bb01-9bae55efc9da": {
    id: "00000002-6b4d-21eb-bb01-9bae55efc9da",
    nama: "Akun Hutang Pajak",
    keterangan: "-",
  },
  "00000003-6b4d-21eb-bc01-9bae55efc9da": {
    id: "00000003-6b4d-21eb-bc01-9bae55efc9da",
    nama: "Akun Piutang Pajak",
    keterangan: "-",
  },
  "00000004-6b4d-21eb-bd01-9bae55efc9da": {
    id: "00000004-6b4d-21eb-bd01-9bae55efc9da",
    nama: "Akun Aktiva Tetap",
    keterangan: "-",
  },
  "00000005-6b4d-21eb-be01-9bae55efc9da": {
    id: "00000005-6b4d-21eb-be01-9bae55efc9da",
    nama: "Akun Beban Entertainment",
    keterangan: "-",
  },
  "00000006-6b4d-21eb-bf01-9bae55efc9da": {
    id: "00000006-6b4d-21eb-bf01-9bae55efc9da",
    nama: "Akun Beban Promosi",
    keterangan: "-",
  },
  "00000007-6b4d-21eb-8001-9bae55efc9da": {
    id: "00000007-6b4d-21eb-8001-9bae55efc9da",
    nama: "Akun List Modal",
    keterangan: "-",
  },
  "00000008-6b4d-21eb-8101-9bae55efc9da": {
    id: "00000008-6b4d-21eb-8101-9bae55efc9da",
    nama: "Akun Deviden",
    keterangan: "-",
  },
  "00000009-6b4d-21eb-8201-9bae55efc9da": {
    id: "00000009-6b4d-21eb-8201-9bae55efc9da",
    nama: "Akun Penyerahan Barang / Jasa",
    keterangan: "-",
  },
  "0000000a-6b4d-21eb-8301-9bae55efc9da": {
    id: "0000000a-6b4d-21eb-8301-9bae55efc9da",
    nama: "Akun Hutang Lainnya",
    keterangan: "-",
  },
  "0000000b-6b4d-21eb-8401-9bae55efc9da": {
    id: "0000000b-6b4d-21eb-8401-9bae55efc9da",
    nama: "Akun Piutang Lainnya",
    keterangan: "-",
  },
  "0000000c-6b4d-21eb-8402-9bae55efc9da": {
    id: "0000000c-6b4d-21eb-8402-9bae55efc9da",
    nama: "Akun Akumulasi Penyusutan",
    keterangan: "-",
  },
  "0000000c-6b4d-21eb-8401-9bae55efc9da": {
    id: "0000000c-6b4d-21eb-8401-9bae55efc9da",
    nama: "Akun Beban Penyusutan",
    keterangan: "-",
  },
};

export const KOP_KHUSUS = {
  "28-404-01": "28-404-01",
  "28-404-02": "28-404-02",
  "28-404-03": "28-404-03",
  "28-404-04": "28-404-04",
  "28-404-05": "28-404-05",
  "28-404-06": "28-404-06",
  "28-404-07": "28-404-07",
  "28-404-08": "28-404-08",
  "28-404-09": "28-404-09",
  "28-404-10": "28-404-10",
  "28-404-11": "28-404-11",
  "28-408-01": "28-408-01",
  "27-100-07": "27-100-07",
  "28-401-01": "28-401-01",
  "28-401-02": "28-401-02",
  "28-401-04": "28-401-04",
  "28-401-05": "28-401-05",
  "28-406-01": "28-406-01",
  "28-407-01": "28-407-01",
  "27-102-03": "27-102-03",
  "22-101-01": "22-101-01",
  "22-405-01": "22-405-01",
  "22-405-02": "22-405-02"
};
