import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PeriodePphUnifikasi.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import iziToast from 'izitoast'

const PeriodePphUnifikasiForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [perusahaanList, setPerusahaanList] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getEntitasList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaan__id: sipStore.companyId,
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'tipeEntitas.notEquals': 'Cabang Pembantu', 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
    setEntitasMap(res.data.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get("/my-perusahaans");
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      customRules={{
        tahunPajak: 'required|size:4'
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/periode-unifikasi`}
      baseId="mod-form-sample"
      title='Periode Unifikasi'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaan__id',
          type: 'text',
          width: '100%',
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitas__id',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT_MASK,
          label: 'Tahun Pajak',
          key: 'tahunPajak',
          width: '49%',
          style: {marginRight: '2%'},
          mask: '****',
          alwaysShowMask: true,
          allowNegative: false
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Masa Pajak',
          key: 'masaPajak',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          options: [
            {value: '1', label: '1'},
            {value: '2', label: '2'},
            {value: '3', label: '3'},
            {value: '4', label: '4'},
            {value: '5', label: '5'},
            {value: '6', label: '6'},
            {value: '7', label: '7'},
            {value: '8', label: '8'},
            {value: '9', label: '9'},
            {value: '10', label: '10'},
            {value: '11', label: '11'},
            {value: '12', label: '12'}
          ]
        },
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          data.masaPajak = parseInt(data.masaPajak)
          data.tahunPajak = parseInt(data.tahunPajak)
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
            iziToast.info({
              message: "Edit entitas untuk mengisi periode entitas"
            })
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/periode-unifikasi')
        } catch(e) {
          console.log(e)
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(PeriodePphUnifikasiForm))
