import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './SilkTranskrip.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'
import { Button, FontIcon } from 'react-md'

const tipeTranskrip = {
  'neraca-aktiva': '00000000-5a06-21eb-a501-45e67381d492',
  'neraca-pasiva': '00000001-5a06-21eb-a601-45e67381d492',
  'laba-rugi': '00000002-5a06-21eb-a701-45e67381d492'
}

var _baseId = "mod-table-elemen-transkrip"

const SilkTranskripTree = ({
  className       = '',
  showCommandbar  = true,
  history,
  match
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  console.log(match, 'match')

  return (
    <>
    <TableWrapper
      useFilter={false}
      showIndex={false}
      baseId={_baseId}
      title={`${match.params.group.toUpperCase()} - ${match.params.tipe.toUpperCase().replaceAll("-", " ")}`}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: '',
          searchable: true,
          key: 'nomorUrut',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => {
            var rows = [];
            for (var i = 0; i < item.level; i++) {
                rows.push(<div style={{width: 80}}><FontIcon style={{display: 'inline', float: 'left'}} iconClassName='mdi mdi-chevron-right' /></div>);
            }

            return <>{rows}</>
          }
        },
        {
          label: 'Nomor Urut',
          searchable: true,
          key: 'nomorUrut',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => (item.nomorUrut)
        },{
          label: 'Nama',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.namaAkun)
        },{
          label: 'Kode',
          searchable: true,
          key: 'kode',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.kode)
        },{
          label: 'Tipe Baris',
          searchable: true,
          key: 'tipeBaris',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tipeBaris)
        },{
          label: 'Tipe Akun',
          searchable: true,
          key: 'tipeAkun__id',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tipeAkun?item.tipeAkun.nama:'')
        },{
          label: 'Parent',
          searchable: true,
          key: 'parentId',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.parent?item.parent.nama:'')
        },{
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push(`/industri-menu/silk-transkrip/${match.params.group}/${match.params.tipe}/baru/none`), true)
      ]}
      itemActions={[
        new TableWrapper.action('Tambah Turunan', 'mdi mdi-plus', (item) => history.push(`/industri-menu/silk-transkrip/${match.params.group}/${match.params.tipe}/baru/${item.id}`), true),
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/industri-menu/transkrip/${match.params.tipe}/${item.id}/none`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        query['jenisLaporan.equals'] = match.params.tipe
        query['namaGroup.equals'] = match.params.group
        query['tipeIndustriId.equals'] = JSON.parse(localStorage.getItem('sip-menu-industri')).id
        query.size = 10000
        var res = await service.get(query)
        res.data = res.data.map(x => {
          x.parentId = x.parent?x.parent.id:null
          return x
        })
        var tree = Utils.toTree(res.data)
        var list = Utils.treeToList(tree)

        return {data: list, headers:res.headers}
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default SilkTranskripTree
