export const MAPPER_HPP = {
  "11 - NON KUALIFIKASI": "00000004-84cf-21ec-8a01-5df00c396115",
  "3 - DAGANG": "00000004-8012-21ec-bb01-419b7964299c",
  "1 - MANUFAKTUR": "0000000a-8004-21ec-9801-af5ab02b5063",
};

export const MAPPER_PERSEDIAAN_AKHIR = {
  "11 - NON KUALIFIKASI": "00000003-84cf-21ec-8901-5df00c396115",
};

export const KATEGORI_BIAYA_LAMP_2 = {
  "HARGA POKOK PENJUALAN": "00000000-6b81-21eb-8601-4719c5861ada",
  "BIAYA USAHA LAINNYA": "00000001-6b81-21eb-8701-4719c5861ada",
  "BIAYA DARI LUAR USAHA": "00000002-6b81-21eb-8801-4719c5861ada",
};

export const TIPE_BIAYA_LAMP_2 = {
  "Gaji, Upah, Bonus, Gratifikasi, honorium THR, dsb":
    "00000001-6695-21eb-b401-d5cef6c0ae8d",
  "Biaya Transfortasi": "00000002-6695-21eb-b501-d5cef6c0ae8d",
  "Biaya Penyusunan dan Amortisasi": "00000003-6695-21eb-b601-d5cef6c0ae8d",
  "Biaya Sewa": "00000004-6695-21eb-b701-d5cef6c0ae8d",
  "Biaya Bunga Pinjaman": "00000005-6695-21eb-b801-d5cef6c0ae8d",
  "Biaya Sehubungan dengan Jas": "00000006-6695-21eb-b901-d5cef6c0ae8d",
  "Biaya Piutang Tak Tertagih": "00000007-6695-21eb-ba01-d5cef6c0ae8d",
  "Biaya Royalti": "00000008-6695-21eb-bb01-d5cef6c0ae8d",
  "Biaya Pemasaran/Promosi": "00000009-6695-21eb-bc01-d5cef6c0ae8d",
  "Biaya Lainnya": "0000000a-6695-21eb-bd01-d5cef6c0ae8d",
  "Persediaan Awal": "0000000b-6695-21eb-be01-d5cef6c0ae8d",
  "Persediaan Akhir (-/-)": "0000000c-6695-21eb-bf01-d5cef6c0ae8d",
  "Pembelian bahan/barang dagangan": "00000000-6695-21eb-b301-d5cef6c0ae8d",
};

export const KELOMPOK_PENGHASILAN = {
  "Bukan Objek Pajak": "00000000-6b73-21eb-8501-ab962c431a27",
  Final: "00000001-6b73-21eb-8601-ab962c431a27",
  "Tidak Final": "00000002-6b73-21eb-8701-ab962c431a27",
};

export const TP_PENGHASILAN = {
  FINAL: {
    "BUNGA DEPOSITO / TABUNGAN,  DAN DISKONTO SBI / SBN":
      "00000000-6b77-21eb-b101-ed6906e19179",
    "BUNGA / DISKONTO OBLIGASI": "00000001-6b77-21eb-b201-ed6906e19179",
    "PENGHASILAN PENJUALAN SAHAM   YANG DIPERDAGANGKAN DI BURSA EFEK":
      "00000002-6b77-21eb-b301-ed6906e19179",
    "PENGHASILAN PENJUALAN SAHAM MILIK PERUSAHAAN MODAL VENTURA":
      "00000003-6b77-21eb-b401-ed6906e19179",
    "PENGHASILAN USAHA PENYALUR / DEALER / AGEN PRODUK BBM":
      "00000004-6b77-21eb-b501-ed6906e19179",
    "PENGHASILAN PENGALIHAN HAK ATAS TANAH / BANGUNAN":
      "00000005-6b77-21eb-b601-ed6906e19179",
    "PENGHASILAN PERSEWAAN ATAS TANAH / BANGUNAN":
      "00000006-6b77-21eb-b701-ed6906e19179",
    "PELAKSANA KONSTRUKSI": "00000007-6b77-21eb-b801-ed6906e19179",
    "PERENCANA KONSTRUKSI": "00000008-6b77-21eb-b901-ed6906e19179",
    "PENGAWAS KONSTRUKSI": "00000009-6b77-21eb-ba01-ed6906e19179",
    "PERWAKILAN DAGANG ASING": "0000000a-6b77-21eb-bb01-ed6906e19179",
    "PELAYARAN / PENERBANGAN ASING": "0000000b-6b77-21eb-bc01-ed6906e19179",
    "PELAYARAN DALAM NEGERI": "0000000c-6b77-21eb-bd01-ed6906e19179",
    "PENILAIAN KEMBALI AKTIVA TETAP": "0000000d-6b77-21eb-be01-ed6906e19179",
    "TRANSAKSI DERIVATIF YANG DIPERDAGANGKAN DI BURSA":
      "0000000e-6b77-21eb-bf01-ed6906e19179",
    "JENIS PENGHASILAN LAINNYA": "0000000f-6b77-21eb-8001-ed6906e19179",
  },
  "Bukan Objek Pajak": {
    "BANTUAN / SUMBANGAN": "00000010-6b77-21eb-8101-ed6906e19179",
    HIBAH: "00000011-6b77-21eb-8201-ed6906e19179",
    "DIVIDEN / BAGIAN LABA DARI PENYERTAAN MODAL ADA BADAN USAHA DI INDONESIA (Pasal 4 Ayat (3) Huruf f UU PPh)":
      "00000012-6b77-21eb-8301-ed6906e19179",
    "IURAN DAN PENGHASILAN TERTENTU YANG DITERIMA DANA PENSIUN":
      "00000014-6b77-21eb-8501-ed6906e19179",
    "BAGIAN LABA YANG DITERIMA PERUSAHAAN MODAL VENTURA DARI BADAN PASANGAN USAHA":
      "00000015-6b77-21eb-8601-ed6906e19179",
    "SISA LEBIH YANG DITERIMA": "00000016-6b77-21eb-8701-ed6906e19179",
    "JENIS PENGHASILAN LAINNYA": "00000017-6b77-21eb-8801-ed6906e19179",
  },
};
