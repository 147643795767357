import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './PPh25.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { KOP_KHUSUS } from '../../../clientConstant'
import { Grid } from "@react-md/utils";
import { TextField } from "@react-md/form";
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-pemotongan-pph2126"

const PPh25Table = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  match,
  modalStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  const [confirmKirimEppt, setConfirmKirimEppt] = useState(false)
  const [confirmKirimEpptData, setConfirmKirimEpptData] = useState({})

  function _closeConfirmKirimEppt() {
    setConfirmKirimEppt(false)
  }

  async function _submitConfirmKirimEppt(callback) {
    var r = await service.http.post(
      `/transaksi-pajak-terutangs/send-to-eppt-kop-2840401-to-2840411`,

      {masa: confirmKirimEpptData.masa, tahun: confirmKirimEpptData.tahun, perusahaanId: sipStore.companyId }
    );
    toast.info(`Data ${confirmDeleteData.nama} sedang di kirim.`); callback()
    setConfirmKirimEppt(false)
  }

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={""}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Status Approval',
          searchable: true,
          key: 'approvalStatus',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.approvalStatus)
        },
        {
          label: 'Status Kirim',
          searchable: true,
          key: 'pengirimanStatus',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.pengirimanStatus)
        },
        {
          label: 'Masa Pajak',
          searchable: true,
          key: 'masa',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.masa)
        },
        {
          label: 'Tahun Pajak',
          searchable: true,
          key: 'tahun',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tahun)
        },
        {
          label: 'Penghasilan sebagai Dasar perhitungan',
          searchable: true,
          key: 'penghasilanDasarAngsuran',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.penghasilanDasarAngsuran == null){
              return '-'
            } else {
              return (Utils.numberFormat(item.penghasilanDasarAngsuran))
            }
          }
        },
        {
          label: 'PPh pasal 25',
          searchable: true,
          key: 'pph',
          type: TableWrapper.dataTypes.NUMBER,
          render: item => {
            if(item.pph == null){
              return '-'
            } else {
              return (Utils.numberFormat(item.pph))
            }
          }
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/pph-25/baru'), true),
        new TableWrapper.action('Kirim Eppt', 'mdi mdi-mail', () => {
          setConfirmKirimEppt(true);
        }, true),
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/pph-25/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true),
        new TableWrapper.action(
          "Kirim ePPT",
          "mdi mdi-mail",
          (item) => {
            modalStore.showConfirm({
              children: "Kirim data terpilih ke ePPT?",
              onRequestClose: () => {
                modalStore.closeConfirm();
              },
              onSubmit: async (callback) => {
                await service.http.post(
                  `/transaksi-pajak-terutangs/${item.id}/send-to-eppt`,
                  {}
                );
                toast.success(
                  `Cek pada kolom status, untuk memantau status pengiriman anda!`
                );
                TableWrapper.reload(_baseId);
                callback();
              },
            });
          },
          (d)=> {return d.kodeObjekPajak && !KOP_KHUSUS[d.kodeObjekPajak.kode]}
        ),
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>

    <Modal.Confirm
      title="Kirim Data Pajak Terutang"
      submitLabel="Submit"
      visible={confirmKirimEppt}
      onRequestClose={_closeConfirmKirimEppt}
      onSubmit={_submitConfirmKirimEppt}
    >
      <div className="mpk-full full-width input mpk-margin-N margin-bottom flex-none ">
      <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none ">
        <TextField label="Masa" value={confirmKirimEpptData.masa} onChange={(e, d)=> {
          setConfirmKirimEpptData((draft) => {
            return {
              ...draft,
              masa: e.target.value
            }
          })
        }}  />
      </div>
      <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none ">
      <TextField label="Tahun" value={confirmKirimEpptData.tahun} onChange={(e, d)=> {
          setConfirmKirimEpptData((draft) => {
            return {
              ...draft,
              tahun: e.target.value
            }
          })
        }}  />
      </div>

      </div>


    </Modal.Confirm>
    </>
  )
}

const tipeBebanPajakMapper = {
  pajak_dibayar_dimuka: 'Pajak Dibayar Dimuka',
  pajak_terutang: 'Pajak Terutang'
}


export default inject("modalStore",'sipStore', 'navigationStore')(observer(PPh25Table))
