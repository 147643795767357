import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './PeriodeFiskal.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'


var _baseId = "mod-table-periode-fiskal"

const PeriodeFiskalTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  modalStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Periode Fiskal'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Tahun Fiskal',
          searchable: true,
          key: 'tahun',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tahun)
        },
        {
          label: 'Dari Tanggal',
          searchable: true,
          key: 'dari',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.dari)
        },
        {
          label: 'Sampai Tanggal',
          searchable: true,
          key: 'ke',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.ke)
        },
        {
          label: 'Status',
          searchable: true,
          key: 'open',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.open?"Berjalan":"Tutup")
        },
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/periode-fiskal/baru'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Buat/Edit Saldo Awal', 'mdi mdi-book-open', (item) => history.push(`/onpremise/periode-fiskal-saldo-awal/${item.id}`), true),
        new TableWrapper.action('Tutup Periode Fiskal', 'mdi mdi-close', (item) => {
          modalStore.showConfirm({
            title: "Konfirmasi",
            children: "Apakah yakin anda akan menutup periode ini?",
            onSubmit: async (callback) => {
              await service.http.post(
                `/periode-fiskals/${item.id}/closing`
              );
              callback("Periode telah ditutup")
              TableWrapper.reload(_baseId)
            },
          });
        }, (d)=> d.open),
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/periode-fiskal/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default inject('sipStore', 'navigationStore', 'modalStore')(observer(PeriodeFiskalTable))
