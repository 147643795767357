import React from 'react'
import './Label.scss'

const Label = ({
  children,
  className = '',
}) => {
  return (
    <div className={`mpk-label mpk-margin-N margin-bottom ${className} mpk-font size-NS`}>
      {children}
    </div>
  )
}

export default Label
