import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './BiayaPensiun.service'
import Utils from '../../../libs/custom/Utils'

const BiayaPensiunForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      if(match.params.id == 'baru') {
        setData({loading: false, content: {}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  return (
    <FormWrapper
      noValidate
      customRules={{
        tahunMulaiBerlaku: 'required|size:4',
        tahunAkhirBerlaku: 'required|size:4'
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/biaya-pensiun`}
      baseId="mod-form-sample"
      title={t.translate('modules.biayaPensiun.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT_MASK,
          label: 'Mulai Berlaku (Tahun)',
          key: 'tahunMulaiBerlaku',
          width: '49%',
          style: {marginRight: '2%'},
          mask: '****',
          alwaysShowMask: true,
          disabled: isEdit,
          allowNegative: false
        },{
          inputType: inputTypes.INPUT_MASK,
          label: 'Sampai Berlaku (Tahun)',
          key: 'tahunAkhirBerlaku',
          width: '49%',
          mask: '****',
          alwaysShowMask: true,
          disabled: isEdit,
          allowNegative: false
        },{
          inputType: inputTypes.INPUT,
          label: 'Tarif (%)',
          key: 'tarif',
          type: 'number',
          validation: 'required|numeric'
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Sebulan (Rp)',
          key: 'sebulan',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required|numeric',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Setahun (Rp)',
          key: 'setahun',
          width: '49%',
          validation: 'required|numeric',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/biaya-pensiun')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(BiayaPensiunForm))
