import React, { useState, useEffect } from 'react'
import t from 'counterpart'
import Flex from '../Flex'
import { FontIcon, MenuItemLink } from 'react-md'
import { inject, observer } from 'mobx-react'

const Group = ({
  item              = [],
  collapsible       = false,
  defaultCollapse   = true,
  nextGroup         = null,
  isTranslate       = false,
  ...props
}) => {
  const [collapse, setCollapse] = useState(collapsible ? defaultCollapse : true)
  const [currentPath, setCurrentPath] = useState(window.location.pathname)
  const isParent = item.children && item.children.length > 0

  const handleCollapse = () => {
    if(collapsible){
      setCollapse(!collapse)
    }
  }

  return isParent ? (
    <div className={`menu-group ${isParent && collapsible ? 'collapsible' : ''}`}>
      <Flex 
        className="subheader mpk-padding-N padding-top padding-bottom mpk-full full-width"
        onClick={(e)=> {
          handleCollapse()
          props.handlePick(props.index)
        }}
        align={Flex.properties.align.CENTER}
        justify={Flex.properties.justify.BETWEEN}
      >
        <div className="mpk-font weight-B">{item.translate ? t.translate(item.label) : item.label}</div>
        {isParent && collapsible && <FontIcon iconClassName={`mdi mdi-menu-${collapse ? 'up' : 'down'}`}/>}
      </Flex>
      { collapse && (
        <div className="menus mpk-animation slide-in-down">
          {item.children.map(d => {
            return (
              <MenuItemLink
                key={`${item.label}-${d.label}`}
                href={d.path}
                className={`menu-item ${currentPath === d.path ? 'active' : (d.childPath && d.childPath.length > 0 && currentPath.match(new RegExp(d.childPath.toString().replace(/,/g, '|'), 'ig')) !== null ? 'active' : '')}`}
                onClick={e => {
                  if(!d.path.match(/http|https|www/g)){
                    e.stopPropagation()
                    e.preventDefault()
                    setCurrentPath(d.path)
                    props.navigationStore.redirectTo(d.path)
                    document.title = `${props.envStore.env.appInfo.name} : ${item.label} | ${d.label}`
                  }
                }}
              >
                <Flex
                  align={Flex.properties.align.CENTER}
                  justify={Flex.properties.justify.BETWEEN}
                >
                  <FontIcon iconClassName={d.iconClassName}/>
                  <div className="flex mpk-margin-N margin-left">{d.translate ? t.translate(d.label) : d.label}</div>
                </Flex>
              </MenuItemLink>
            )
          })}
        </div>
      )}
    </div>
  ) : (
    nextGroup && nextGroup.children && nextGroup.children.length > 0 && (
      <div className="menu-group-divider mpk-font size-NS mpk-padding-S padding-top padding-bottom">
        {item.translate ? t.translate(item.label) : item.label}
      </div>
    )
  )
}

export default inject('navigationStore', 'envStore')(observer(Group))
