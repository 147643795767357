import cloneDeep from 'lodash/cloneDeep';
import numeral from 'numeral';

numeral.defaultFormat('0.0,00');

function toList (tree, lvl) {
  if(!lvl) lvl = 1
  let tmp = []
  const { key_child, empty_children } = {key_child: 'children', empty_children: false}
  for (let n in tree) {
    tree[n].level = lvl
    tmp.push(tree[n])
    if (tree[n][key_child] && tree[n][key_child].length) {
      tmp = [...tmp, ...toList(tree[n][key_child], lvl+1)]
    }
    if (empty_children) {
      delete tree[n][key_child]
    }
  }
  return tmp
}

export default {
 toTree: function(list) {
  var map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  },
  treeToList: toList,
  numberFormat: (n, format="0,0") => (numeral(n).format(format).replace(/\,/g, '&').replace(/\./g, ',').replace(/&/g, '.')),

  formNormalize: (data) => {
    Object.keys(data).forEach(d => {
      if(d.search('__') != -1) {
        var e = d.split('__')
        if(!data[e[0]]) data[e[0]] = {}
        data[e[0]][e[1]] = data[d]
      }
    })

    return data
  },
  formSerialize: (data) => {
    Object.keys(data).forEach(d => {
      // number to string
      if(typeof data[d] == 'number') {
        data[d] = data[d].toString()
      }

      if(data[d] && typeof data[d] == 'object' && data[d].length) {
        data[d].forEach(dataChild => {
          Object.keys(dataChild).forEach(e => {
            if(typeof dataChild[e] == 'number') {
              dataChild[e] = dataChild[e].toString()
            }
          })
        })
      }

      //objek to __ {parent: {id: 'asd'}} => {parent: {id: 'asd'}, parent__id: 'asd'}
      if(data[d] && typeof data[d] == 'object' && !data[d].length) {
        Object.keys(data[d]).forEach(e => {
          data[d+"__"+e] = data[d][e]
        })
      }
    })

    return data
  }
}
