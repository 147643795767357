class chart{
  constructor(
    label         = 'string', 
    onFetchData   = null, 
    onInitData    = null,
    dataConfig    = null,
    chartConfig   = null,
    hintMessage   = null,
    hintShowIcon      = true,
    hintIconClassName = null,
    hintMore          = null,
  ){
    this.label = label 
    this.onFetchData = onFetchData
    this.onInitData = onInitData
    this.dataConfig = dataConfig
    this.chartConfig = chartConfig
    this.hintMessage = hintMessage
    this.hintShowIcon = hintShowIcon
    this.hintIconClassName = hintIconClassName
    this.hintMore = hintMore
  }
}

export default chart