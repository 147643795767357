import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import PajakTerutangTable from './PajakTerutang.table'
import PajakTerutangAkunPphTable from './PajakTerutangAkunPph.table'
import PajakTerutangSandingTable from './PajakTerutangSanding.table'

const PajakTerutangWrapper = ({envStore, match, history, sipStore, navigationStore}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  return <TabsWrapper
    title={tipeBebanPajakMapper[match.params.tipeBebanPajak]+" "+match.params.type.replace('__', '/')}
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-pajak-terutang"
    actions={[
      {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => {
        history.goBack()
      }}
    ]}
    tabs={[
      {
        label: 'Data Hitung',
        key: 'data-hitung',
        render: <PajakTerutangTable match={match} history={history} />
      },
      {
        label: 'Akun PPh',
        key: 'akun-pph',
        render: <PajakTerutangAkunPphTable match={match} history={history} />
      },
      // {
      //   label: 'Kertas Kerja Sanding',
      //   key: 'sanding',
      //   render: <PajakTerutangSandingTable match={match} history={history} />
      // }
    ]}
  />
}

const tipeBebanPajakMapper = {
  pajak_dibayar_dimuka: 'Pajak Dibayar Dimuka',
  pajak_terutang: 'Pajak Terutang'
}

export default inject('sipStore', 'navigationStore')(observer(PajakTerutangWrapper))
