import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './KoreksiFiskal.service'
import moment from 'moment'
import Utils from '../../../libs/custom/Utils'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'

var _baseId = "mod-table-koreksi-fiskal"

const KoreksiFiskalTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  authStore,
  transaksiId
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Koreksi Fiskal'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Status Approval',
          searchable: true,
          key: 'approvalStatus',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.approvalStatus)
        },{
          label: 'Sumber',
          searchable: true,
          key: 'sumber',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.sumber)
        },
        {
          label: 'Nomor Transaksi',
          searchable: true,                                                                                                                                                                                                                                      
          key: 'nomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.transaksi == null){
              return '-'
            } else {
              return item.transaksi.nomor
            }
          }
        },
        {
          label: 'Tanggal Transaksi',
          searchable: true,
          key: 'tanggal',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.transaksi == null){
              return '-'
            } else {
              return item.transaksi.tanggal
            }
          }
        },
        {                                                                                                                                                                                                                                                                                                                                              
          label: 'Kode Akun',
          searchable: true,
          key: 'kodeAkun',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.jurnal == null){
              return item.akun.nomor
            } else {
              return (item.jurnal && item.jurnal.akun)?item.jurnal.akun.nomor:''
            }
          }
        },
        {
          label: 'Nama Akun',
          searchable: true,
          key: 'namaAkun',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.jurnal == null){
              return item.akun.nama
            } else {
              return (item.jurnal && item.jurnal.akun)?item.jurnal.akun.nama:''
            }
          }
        },
        {
          label: 'Nilai Komersial',
          searchable: true,
          key: 'nilai',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.metodeInput == 'Sebagai Koreksi atas Transaksi Jurnal'){
              return '-'
            } else {
              return (Utils.numberFormat(item.nilai))
            }
          }
        },
        {
          label: 'Koreksi Positif',
          searchable: true,
          key: 'nomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.tipeKoreksiFiskal){
              if(item.tipeKoreksiFiskal.tipeKoreksi == 'Positif'){
                return (Utils.numberFormat(item.nilaiKoreksi))
              } else{
                return '-'
              }
            } else {
              return '-'
            }
          }
        },{
          label: 'Koreksi Negatif',
          searchable: true,
          key: 'nomor',
          type: TableWrapper.dataTypes.STRING,
          render: item => {
            if(item.tipeKoreksiFiskal){
              if(item.tipeKoreksiFiskal.tipeKoreksi == 'Negatif'){
                return(Utils.numberFormat(item.nilaiKoreksi))
              } else{
                return '-'
              }
            } else {
              return '-'
            }
          }
        },{
          label: 'Nilai Fiskal',
          searchable: true,
          key: 'nilaiFiskal',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.nilaiFiskal))
        },{
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push("/onpremise/koreksi-fiskal/baru"), true)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => {
          if(item.transaksi == null){
            history.push(`/onpremise/koreksi-fiskal/${item.id}`)
          } else {
            history.push(`/onpremise/transaksi-koreksi-fiskal/${item.id}/${item.transaksi.id}`)
          }
        }, true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['periodeId.equals'] = sipStore.periodeId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}
                          
export default inject('sipStore', 'navigationStore', "authStore")(observer(KoreksiFiskalTable))
