import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import ReturPenjualanPosTable from './ReturPenjualanPos.table'
import ReturPenjualanAkunTable from './ReturPenjualanAkun.table'

const ReturPenjualanWrapper = ({envStore, match, history, sipStore, navigationStore}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  return <TabsWrapper
    title="Retur Penjualan"
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-details-migrasi"
    actions={[
      // {name: 'Add', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/onpremise/migrasi-transkrip')}
    ]}
    tabs={[
      {
        label: 'Retur Penjualan (POS)',
        key: 'retur-penjualan-pos',
        render: <ReturPenjualanPosTable match={match} history={history} />
      },
      {
        label: 'Retur Penjualan (Akun)',
        key: 'retur-penjualan-akun',
        render: <ReturPenjualanAkunTable match={match} history={history} />
      },
    ]}
  />
}

export default inject('sipStore', 'navigationStore')(observer(ReturPenjualanWrapper))
