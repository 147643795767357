import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import onp from '../libs/custom/onp';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import {NegaraTable, NegaraForm, ElemenTranskripTree, ElemenTranskripForm } from '../modules';
import { AppBar, AppBarAction, AppBarNav, AppBarTitle } from "@react-md/app-bar";
import { Button, FontIcon } from 'react-md'
import SilkTranskripTree from '../modules/adminpajakku/silkTranskrip/SilkTranskrip.tree';
import SilkTranskripForm from '../modules/adminpajakku/silkTranskrip/SilkTranskrip.form';


const Onpremise = ({history}) => {
  var label = JSON.parse(localStorage.getItem('sip-menu-industri')?localStorage.getItem('sip-menu-industri'):"{}")
  return (
    <PageWrapper
      sidebarCollapsible={false}
      sidebarMenu={sidebarMenu}
      sidebarHeader={
        <AppBar>
          <AppBarNav aria-label="Navigation">
            <FontIcon iconClassName='mdi mdi-arrow-left' onClick={()=>  history.push('/onpremise/industri')} />
          </AppBarNav>
          <AppBarTitle>{label.nama}</AppBarTitle>
        </AppBar>
      }
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/industri-menu/transkrip/:tipe/:id/:parentId'
            render={props => (
              <ElemenTranskripForm {...props}/>
            )}
          />
          <Route
            path='/industri-menu/transkrip/:tipe'
            render={props => (
              <ElemenTranskripTree {...props} key={props.match.params.tipe} />
            )}
          />
          <Route
            path='/industri-menu/silk-transkrip/:group/:tipe/:id/:parentId'
            render={props => (
              <SilkTranskripForm {...props}/>
            )}
          />
          <Route
            path='/industri-menu/silk-transkrip/:group/:tipe'
            render={props => (
              <SilkTranskripTree {...props} key={props.match.params.tipe} />
            )}
          />

        </Switch>
      </Router>
    </PageWrapper>
  )
}

const sidebarMenu = [
{
  label: 'Menu Utama',
  children: [
    {
      label: 'Neraca Aktiva',
      index: 'neraca, activa',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/transkrip/neraca-aktiva',
      childPath: []
    },
    {
      label: 'Neraca Pasiva',
      index: 'neraca, pasiva',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/transkrip/neraca-pasiva',
      childPath: []
    },
    {
      label: 'Laba Rugi',
      index: 'laba, rugi',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/transkrip/laba-rugi',
      childPath: []
    }
  ]
},{
  label: '(SILK) Financial Statement Reporting',
  children: [
    {
      label: 'Laporan Posisi Keuangan',
      index: 'posisi, keuangan',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/silk-transkrip/fr/laporan-posisi-keuangan',
      childPath: []
    },
    {
      label: 'Laporan Laba Rugi',
      index: 'laba, rugi',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/silk-transkrip/fr/laporan-laba-rugi',
      childPath: []
    },
    {
      label: 'Laporan Perubahan Ekuitas',
      index: 'perubahan, ekuitas',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/silk-transkrip/fr/laporan-perubahan-ekuitas',
      childPath: []
    },
    {
      label: 'Laporan Arus Kas',
      index: 'perubahan, ekuitas',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/silk-transkrip/fr/laporan-arus-kas',
      childPath: []
    }
  ]
},{
  label: '(SILK) Fiskal Correction - Detail Profit And Loss',
  children: [
    {
      label: 'Rekonsiliasi Laporan Keuangan',
      index: 'posisi, keuangan',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/silk-transkrip/fcdpl/rekonsiliasi-laporan-keuangan',
      childPath: []
    },
    {
      label: 'Transkrip Laporan Posisi Keuangan',
      index: 'laba, rugi',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/silk-transkrip/fcdpl/transkrip-laporan-posisi-keuangan',
      childPath: []
    },
    {
      label: 'Laporan Koreksi Fiskal',
      index: 'perubahan, ekuitas',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/industri-menu/silk-transkrip/fcdpl/laporan-koreksi-fiskal',
      childPath: []
    }
  ]
}]


export default onp({
  basePath: '/industri-menu'
})(Onpremise);
