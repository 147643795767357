import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './SandingAsetPerAkun.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import Utils from '../../../libs/custom/Utils'
import {
  Caption,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableContainer
} from "@react-md/table";

var _baseId = "mod-table-sanding-aset-perakun"

const SandingAsetPerAkunTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  match,
  sipStore,
  navigationStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [sum, setSum] = useState({
    koreksiFiskal: 0,
    penyusutanFiskal: 0,
    penyusutanKomersial: 0
  })

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  useEffect(() => {
    async function initData() {
      const res = await service.http.get(service.path+"/sum", {'periodeFiskalId.equals': sipStore.periodeId, 'akunId.equals': match.params.id})
      setSum(res.data)
    }
    initData()
  }, ['match.params.id'])

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Sanding Aset per Akun'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="nomor"
      columns={[
        {
          label: 'Tanggal Transaksi',
          searchable: true,
          key: 'tanggalTransaksi',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tanggal)
        },
        {
          label: 'Nomor Transaksi',
          searchable: true,
          key: 'nomorTransaksi',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nomor)
        },
        {
          label: 'Status Sanding',
          searchable: true,
          key: 'statusSanding',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.statusSanding)
        },
        {
          label: 'Harga Perolehan',
          searchable: true,
          key: 'hargaPerolehan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.hargaPerolehan))
        }

      ]}
      actions={[
        // new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/penyusutan-periode-aset/baru'), true)
      ]}
      itemActions={[
        // new TableWrapper.action('Sanding per Aset', 'mdi mdi-book-open', (item) => {
        //   history.push(`/onpremise/sanding-aset-peraset/${item.id}`)
        // }, true),
        // new TableWrapper.action('Sanding per Akun', 'mdi mdi-book-open', (item) => {
        //   history.push(`/onpremise/sanding-aset-perakun/${item.id}`)
        // }, true),
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.periodeId) {
          query['periodeFiskalId.equals'] = sipStore.periodeId
        }
        query['akunId.equals'] = match.params.id
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
      tableFooter={<Table style={{width:'100%'}} >
      <TableBody fullWidth>
        <TableRow>
          <TableCell>Harga Total Perolehan</TableCell>
          <TableCell style={{textAlign: 'right'}}>{Utils.numberFormat(sum.hargaTotalPerolehan)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default inject('sipStore', 'navigationStore')(observer(SandingAsetPerAkunTable))
