import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { FormWrapper } from "../../libs/react-mpk/wrapper";
import markdownFile from "./toc-pajakku.md";
import {
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Dialog,
  DialogContainer,
  DialogContent,
  DialogFooter,
  Button,
  Divider,
} from "react-md";
import ReactMarkdown from "react-markdown";
import t from "counterpart";
import OpLoginService from "./OpLogin.service";
import axios from "axios";
import { List, ListItem } from "@react-md/list";
import { DataForm } from "../../libs/react-mpk/components";
import iziToast from "izitoast";

const UpdateLicenseForm = ({
  authStore,
  modalStore,
  envStore,
  match,
  history,
  sipStore,
  ...props
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [data, setData] = useState({ loading: true, content: {} });
  const [curLisence, setCurLisence] = useState({});
  const [ploading, setPloading] = useState({ loading: false, message: "" });
  const [md, setMd] = useState();
  const [dialog, setDialog] = useState(false);

  const baseName = `login`;
  const basePath = `login`;

  useEffect(() => {
    getLicense();
    fetch(markdownFile)
      .then((response) => response.text())
      .then((text) => {
        setMd(text);
      });
  }, []);

  const getLicense = async () => {
    var d = await OpLoginService.http.get(
      "/api/app/license",
      {},
      {},
      { useDefaultBaseUrl: false, useDefaultHost: true }
    );
    setCurLisence(d.data);
    setData({ loading: false, content: d.data })
  };

  var syarat = async () => {
    setDialog(true);
  };

  return (
    <>
      <div className="App mpk-full viewport-height account">
        <div className="bg">
          <div className="parallelogram-1"></div>
          <div className="parallelogram-2"></div>
          <div className="parallelogram-3"></div>
          <div className="parallelogram-4"></div>
          <div className="parallelogram-5"></div>
          <Dialog
            visible={dialog}
            disableFocusOnMount={true}
            style={{
              width: 800,
            }}
            onRequestClose={() => setDialog(false)}
          >
            <DialogContent>
              <div>
                <ReactMarkdown children={md} />
              </div>
            </DialogContent>
            <DialogFooter>
              <Button onClick={() => setDialog(false)}>Tutup</Button>
            </DialogFooter>
          </Dialog>
          {/* <div style={{ textAlign: 'center', marginTop: '100px', height: 54, }}>
            <img src="./images/sip-picturemark-d.svg" style={{ position: 'relative', height: 64, width: 92 }} />
            <img src="./images/sip-logotype-d.svg" style={{ position: 'relative', height: 45, margin: 9.5 }} />
          </div> */}

          <DataForm
            showCommandbar={false}
            style={{
              maxWidth: "20%",
              padding: 40,
              position: "relative",
              marginTop: "36px",
            }}
            className="fullWidth"
            baseId={`mod-form-${baseName}`}
            loading={data.loading}
            defaultData={data.content}
            definitions={[
              {
                width: "100%",
                render: (
                  <>
                    <h3>Lisensi SIP Saat ini</h3>
                    <div style={{overflowX: 'scroll'}}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Nama</TableCell>
                            <TableCell>{curLisence.nama}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Tanggal Expire</TableCell>
                            <TableCell>{curLisence.tanggalExpire}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Npwp Perusahaan</TableCell>
                            <TableCell>{curLisence.npwpPerusahaan}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Npwp Entitas</TableCell>
                            <TableCell>{curLisence.npwpEntitas}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                    {/* <List style={{ width: "100%" }}>
                      <ListItem secondaryText={curLisence.nama}>Nama</ListItem>
                      <ListItem secondaryText={curLisence.tanggalExpire}>
                        Tanggal Expire
                      </ListItem>
                      <ListItem secondaryText={curLisence.npwpPerusahaan}>
                        Npwp Perusahaan
                      </ListItem>
                      <ListItem secondaryText={curLisence.npwpEntitas}>
                        Npwp Entitas
                      </ListItem>
                    </List> */}
                    <Divider />
                    <h3>Update Lisensi SIP</h3>
                  </>
                ),
              },
              {
                inputType: inputTypes.TEXTAREA,
                label: "Lisensi",
                key: "license",
                rows: 10,
                maxRows: 10,
                // areaStyle: {maxHeight: 300, overflowY: 'scroll'}
              },
            ]}
            submitLabel="Simpan"
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button onClick={() => (window.history.back())}>
                    Kembali
                  </Button>
                ),
              },
            ]}
            onSubmit={async (values, callback) => {
              try {
                values.rememberMe = true;
                let res = await OpLoginService.http.put('/api/app/license', {
                  kode: 'LICENSE',
                  value: values.license
                }, {}, { useDefaultBaseUrl: false, useDefaultHost: true })

                await getLicense()
                callback()
                iziToast.success({title: 'Berhasil', message: 'Lisensi SIP telah berhasil di update.'})
              } catch (e) {
                callback(e.response.data.detail, true, false);
              }
            }}
          ></DataForm>
        </div>
      </div>
    </>
  );
};

export default inject(
  "authStore",
  "envStore",
  "modalStore",
  "sipStore"
)(observer(UpdateLicenseForm));
