import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import http from '../custom/httpService.custom';
import { merge } from 'lodash';
import qs from 'query-string';
import { LoaderInfo, Modal } from '../react-mpk/components';
import t from 'counterpart';
import mpkEnv from '../react-mpk/config/env'
import envclient from './envclient';

const wrapper =  (options={}) => WrappedComponent => {
  let opt = merge({
    url      : `/api/env`,
  }, options)

  function Init(props){
    const [isLoading, setIsLoading ] = useState(true);

    const setupApplication = () => {
      let { envStore, authStore } = props;
      return new Promise(resolve => {
        let parseWidget = window.location.pathname.match(/widget/g);
        let asWidget = parseWidget ? parseWidget[0] === 'widget' : false;

        const parsedUrl = qs.parseUrl(window.location.href);
        let { accessToken, widgetClientId=null, accessKey, access_key, origin=null, redirectUri=null, updateUri=null } = parsedUrl.query;

        if(accessToken || accessKey) authStore.clearCookie();
        if(accessToken) authStore.setAccessToken(accessToken);
        if(accessKey || access_key) authStore.setAccessToken(accessKey || access_key);
        if(asWidget){
          envStore.setWidget({
            active: true,
            clientId: widgetClientId,
            parentOrigin: origin,
            redirectUri,
            updateUri
          })
        }

        resolve();
      })
    }

    const getEnvConfiguration = async () => {
      return new Promise(async (resolve, reject) => {
        try {
          let res = {data: envclient}

          // SETUP HOST
          const { widget } = props.envStore;
          const { widgetGateway, apiGateway } = res.data;
          http.setHost(widget.active
            ? widgetGateway.host
            : apiGateway.host
          )

          if(!widget.active)
            http.setClientId(apiGateway.clientId);

          // SETUP LOCALE
          const lang = localStorage.getItem('lang')
          let { locale, ...env } = res.data;
          props.envStore.setEnv(env);

          // SET BRAND
          const { channelName } = env.portal
          mpkEnv.setBrand(env.theme, channelName)

          locale.code = lang || locale.code;
          props.envStore.setLocale(locale);
          t.setLocale(locale.code);

          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      })
    }

    useEffect(() => {
      if(isLoading){
        async function fetchData(){
          try {
            await setupApplication();
            await getEnvConfiguration();
            setIsLoading(false);
          } catch (error) {
            // window.alert('Server is not running correctly')
          }
        }

        fetchData();
      }
    })

    return isLoading ? (
      <LoaderInfo>
        Getting application configuration..
      </LoaderInfo>
    ) : (
      <>
        <WrappedComponent {...props}/>
        <Modal.Confirm
          {...props.modalStore.confirmation}
          onRequestClose={() => props.modalStore.closeConfirm()}
        />
        <Modal.Info
          {...props.modalStore.information}
          onRequestClose={() => props.modalStore.closeInfo()}
        />
      </>
    )
  }

  return inject('envStore', 'navigationStore', 'modalStore', 'sipStore')(observer(Init));
}

export default wrapper;
