import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PajakTerutangGunggung.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import PajakTerutangDataHitungService from './PajakTerutang.service'
import TransaksiJurnalService from '../Transaksi/TransaksiJurnal.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'

const PajakTerutangGunggungForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [perusahaanList, setPerusahaanList] = useState([])
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [kodeObjekPajakListAll, setKodeObjekPajakListAll] = useState([]);
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([]);
  const [kodeObjekPajakMap, setKodeObjekPajakMap] = useState([]);

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getEntitasList()
      // await _getPajakTerutangDataHitung()
      // await _getTransaksiJurnalList()
      await _getKodeObjekPajak()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          // perusahaan__id: sipStore.companyId,
          statusApproval: "-"
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'tipeEntitas.notEquals': 'Cabang Pembantu', 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
    setEntitasMap(res.data.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getKodeObjekPajak(filter) {
    var res = await KodeObjekPajakService.get({ size: 100000 });
    setKodeObjekPajakMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setKodeObjekPajakListAll(res.data);
    setKodeObjekPajak(
      res.data.map((x) => ({ value: x.id, label: x.kode + " - " + x.nama }))
    );
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        new TableWrapper.action('Back', 'mdi mdi-arrow-left', () => history.goBack(), true),
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      // backTo={`/onpremise/pemotongan-pph2126`}
      baseId="mod-form-sample"
      title={"Data PPh Yang Digunggung"}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key === 'kodeObjekPajak__id') {
          newObj.jenisPajak = kodeObjekPajakMap[value].tipePajak.kode
        }
      }}
      definitions={[
        // =====

        {
          inputType: inputTypes.REACT_SELECT,
          label: "Kode Objek Pajak",
          key: "kodeObjekPajak__id",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          options: kodeObjekPajakList,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Jenis Pajak',
          key: 'jenisPajak',
          type: 'text',
          width: '49%',
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nominal DPP",
          key: "dpp",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required|numeric"
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "PPh",
          key: "pph",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          validation: "required|numeric"
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          const periode = JSON.parse(localStorage.getItem('sip-unifikasi-pph'))
          data.perusahaan = periode.perusahaan;
          data.entitas = periode.entitas;
          data.masaPajak = periode.masaPajak;
          data.tahunPajak = periode.tahunPajak;


          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/pph-unifikasi-menu/data-pph-digunggung')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(PajakTerutangGunggungForm))
