import React, { useEffect, useState } from "react";
import { FormWrapper, TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./DaftarNominatifEntertainment.service";
import moment from "moment";
import Utils from "../../../libs/custom/Utils";
import { toast } from "../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";
import { DataForm } from "../../../libs/react-mpk/components";
import iziToast from "izitoast";

var _baseId = "mod-table-daftar-nominatif-entertainment";

const DaftarNominatifEntertainmentTable = ({
  className = "",
  showCommandbar = true,
  history,
  sipStore,
  navigationStore,
  authStore,
  transaksiId,
  modalStore
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});
  const [uploadModal, setUploadModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }

  function _closeUploadModal() {
    setUploadModal(false);
  }

  function _closeExportModal() {
    setExportModal(false);
  }

  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title="Daftar Nominatif Biaya Entertainment"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Status Approval",
            searchable: true,
            key: "approvalStatus",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.approvalStatus,
          },
          {
            label: "Sumber",
            searchable: true,
            key: "sumber",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.sumber,
          },
          {
            label: "Masa",
            searchable: true,
            key: "masaPajak",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.masaPajak,
          },
          {
            label: "Tahun",
            searchable: true,
            key: "tahunPajak",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tahunPajak,
          },
          {
            label: "Tanggal",
            searchable: true,
            key: "tanggal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tanggal,
          },
          {
            label: "Tempat",
            searchable: true,
            key: "tempat",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tempat,
          },
          {
            label: "Jenis",
            searchable: true,
            key: "jenis",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.jenis,
          },
          {
            label: "Nilai",
            searchable: true,
            key: "nilai",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.nilai),
          },
          {
            label: "Nama",
            searchable: true,
            key: "nama",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nama,
          },
          {
            label: "Nama Perusahaan",
            searchable: true,
            key: "namaPerusahaan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.namaPerusahaan,
          },
          {
            label: "Jenis Usaha",
            searchable: true,
            key: "jenisUsaha",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.jenisUsaha,
          },
          {
            label: "Keterangan",
            searchable: true,
            key: "keterangan",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.keterangan,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () =>
              history.push(
                "/onpremise/daftar-nominatif-biaya-entertainment/baru"
              ),
            true
          ),
          new TableWrapper.action(
            "Import Daftar Nominatif",
            "mdi mdi-import",
            () => setUploadModal(true),
            true
          ),
          new TableWrapper.action(
            "Export Daftar Nominatif",
            "mdi mdi-export",
            () => {
              modalStore.showConfirm({
                title: "Export Daftar Nominatif",
                children: "",
                onSubmit: async (callback) => {
                  await service.http.post(
                    "/transaksi-beban-ent/export",
                    {
                      name:
                        "transaksi-beban-entertainment-" +
                        moment().format("YYYY-MM-DD HH:mm:ss"),
                      origin:
                        "transaksi-beban-entertainment-export-" +
                        moment().format("YYYY-MM-DD HH:mm:ss") +
                        ".xls",
                    },
                    {},
                    {},
                    {
                      "perusahaanId.equals": sipStore.companyId,
                    }
                  );

                  iziToast.success({
                    title: "Berhasil",
                    message:
                      "Data sedang di export. Mohon pantau progress export di menu log export.",
                  });
                  callback(
                    "Data sedang di export. Mohon pantau progress export di menu log export."
                  );
                },
              });
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Edit",
            "mdi mdi-pencil",
            (item) => {
              if (item.transaksiId == null) {
                history.push(
                  `/onpremise/daftar-nominatif-biaya-entertainment/${item.id}`
                );
              } else {
                history.push(
                  `/onpremise/transaksi-beban-entertainment/${item.id}/${item.transaksiId}`
                );
              }
            },
            true
          ),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          if (!query) query = {};
          if (sipStore.companyId) {
            query["periodeId.equals"] = sipStore.periodeId;
          }
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
      <DataForm
        baseId={"lawan-import"}
        asDialog={true}
        defaultData={{}}
        definitions={[
          {
            inputType: FormWrapper.inputTypes.FILE_INPUT,
            label: "File",
            key: "file",
            theme: "filled",
            style: { width: 200 },
          },
        ]}
        visible={uploadModal}
        submitLabel="Import Data"
        onSubmit={async (d, callback) => {
          var formData = new FormData();
          formData.set("file", d.file);
          var uploadRes = await service.http.post("/upload", formData);
          if (uploadRes.data) {
            var importRes = await service.http.post(
              "/transaksi-beban-ent/import",
              {
                nama: uploadRes.data.name,
                origin: uploadRes.data.origin,
                perusahaanId: sipStore.companyId,
                periodeId: sipStore.periodeId,
                type: d.jenis,
                ext: "xls",
              }
            );
          }
          callback(
            "Data sedang di import. Mohon pantau progress import di menu log import.",
            false
          );
        }}
        onRequestClose={_closeUploadModal}
      />
    </>
  );
};

export default inject(
  "sipStore",
  "navigationStore",
  "authStore",
  "modalStore"
)(observer(DaftarNominatifEntertainmentTable));
