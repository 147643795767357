import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TransaksiPos.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import TransaksiService from './Transaksi.service'
import TransaksiItemService from './VTransaksiItem.service'
import { DataForm } from '../../../libs/react-mpk/components'

const TransaksiPosForm = ({envStore, match, history, sipStore, modalStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [akunList, setAkun] = useState([])
  const [journalMap, setJournalMap] = useState({})
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([])
  const [transaksiItemList, setTransaksiItemList] = useState([])
  const [transaksiItemMap, setTransaksiItemMap] = useState({})
  const [curData, setCurData] = useState({});

  // initial data
  useEffect(() => {
    async function initData() {
      _getAkun()
      _getKodeObjekPajak()
      _getTransaksiItem()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {dipungut: false, dpp:'0', pph: '0', transaksiReferensiPiutangAkun__nilai: '0', transaksiBebanPromosiPengecualians: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      // res.data.transaksiReferensiPiutangAkun__akunid = res.data.transaksiReferensiPiutangAkun.akun.id
      setIsEdit(true)
      setCurData(res.data);
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId)
    setJournalMap(res.data.transaksiJurnals.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    console.log(res.data, 'asdasd')
    setAkun(res.data.transaksiJurnals.map(x => ({value: x.id, label: x.akun.nama})))
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({size: 100000})
    setKodeObjekPajak(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTransaksiItem() {
    var res = await TransaksiItemService.get({size: 100000, perusahaanId: sipStore.companyId})
    var map = res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {})
    setTransaksiItemMap(map)
    setTransaksiItemList(res.data.map(x => ({value: x.id, label: x.nomor+" "+x.tanggal+" "+x.posLawanTransaksi.nama})))

    return map
  }

  async function importItem() {
    modalStore.showConfirm({
      title: "Upload Barang/Jasa",
      children: (
        <DataForm
          baseId={"import-upload"}
          hideActionButton
          defaultData={{}}
          definitions={[
            {
              inputType: FormWrapper.inputTypes.FILE_INPUT,
              label: "File",
              key: "file",
              theme: "filled",
              style: { width: 200 },
            },
          ]}
          // submitLabel="Import Data"
          onSubmit={async (d, callback) => {
            var formData = new FormData();
            formData.set("file", d.file);
            var uploadRes = await service.http.post("/upload", formData);
            if (uploadRes.data) {
              var data = { ...curData };
              var importRes = await service.http.get(
                `/pembelian-item-import/${uploadRes.data.name}/${sipStore.companyId}`
              );

              var data = { ...curData };
              data.items = importRes.data;
              FormWrapper.reload("transaksi-pembelian-form", data);
              setCurData(data);
            }
            callback("Data berhasil di upload", false);
          }}
        />
      ),
      onSubmit: async (callback) => {
        const f = document.getElementById(`import-upload-form-trigger`);
        if (f) f.click();
        callback();
      },
    });
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/transaksi/'+match.params.transaksiId+'/transaksi-pos'}
      baseId="mod-form-sample"
      title='Transaksi POS'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'jurnal__id') {
          newObj.jurnal__nilai = journalMap[value].nilai
        }
        setCurData(newObj);
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: '# AKUN TRANSAKSI'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun',
          key: 'jurnal__id',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          options: akunList,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'jurnal__nilai',
          // theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true,
          validation: 'required|numeric'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# DATA TRANSAKSI POS'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Nomor POS',
          key: 'pos__id',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          validation: 'required',
          options: transaksiItemList
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          // data.transaksiReferensiPiutangAkun.akun = {
          //   id: data.transaksiReferensiPiutangAkun__akunid
          // }
          console.log(data, 'data')
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/transaksi/'+match.params.transaksiId+'/transaksi-pos')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore', 'sipStore', 'modalStore')(observer(TransaksiPosForm))
