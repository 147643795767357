import React, {useEffect, useState} from 'react'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Pegawai.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import { DataForm } from '../../../libs/react-mpk/components'
import iziToast from 'izitoast'

var _baseId = "mod-table-pegawai"

const PegawaiTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  modalStore,
  authStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [data, setData] = useState([])

  const [uploadModal, setUploadModal] = useState(false)
  const [exportModal, setExportModal] = useState(false)

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }

  function _closeUploadModal() {
    setUploadModal(false)
  }
  
  function _closeExportModal() {
    setExportModal(false)
  }

  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={t.translate('modules.pegawai.title')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      selectable={true}
      columns={[
        {
          label: 'NPWP Perusahaan / Cabang',
          searchable: true,
          key: 'npwpEntitas',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.entitas.npwp)
        },{
          label: 'Nama Perusahaan / Cabang',
          searchable: true,
          key: 'namaEntitas',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.entitas.nama)
        },{
          label: 'Tipe Pegawai',
          searchable: true,
          key: 'tipePegawai',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.tipePegawai?item.tipePegawai.nama:'')
        },{
          label: 'NPWP Pegawai',
          searchable: true,
          key: 'npwp',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.npwp)
        },{
          label: 'NIK / Passport Pegawai',
          searchable: true,
          key: 'nik',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nik)
        },{
          label: 'NIP',
          searchable: true,
          key: 'nip',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nip)
        },{
          label: 'Nama Pegawai',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nama)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/pegawai/baru'), authStore.user.roleMap?authStore.user.roleMap['Pegawai'].Create:false),
        new TableWrapper.action('Import Pegawai', 'mdi mdi-import', () => setUploadModal(true), true),
        new TableWrapper.action('Export Pegawai', 'mdi mdi-export', () => setExportModal(true), true),
        new TableWrapper.action('Delete Bulk', 'mdi mdi-delete-outline', () => {
          modalStore.showConfirm({
            title       : "Delete Bulk",
            children    : "Apakah anda ingin menghapus data-data terpilih?",
            onSubmit    : async (callback) => {
              var ids = []
              var selected = JSON.parse(TableWrapper.getSelected(_baseId))
              selected.map((d) => { 
                ids.push(data[d].id) 
              })
              try {
                let res = await service.http.post('/pegawais-bulk-delete', {ids})
                toast.success(
                  `Data terpilih berhasil dihapus`
                );
              } catch(e){
                toast.warning(`Data terpilih gagal dihapus`);
              }
              callback();

              try {
                var checkboxRoot = document.getElementById(`${_baseId}-selectable-rows-root-checkbox`)
                if (checkboxRoot.checked) {
                  if (checkboxRoot.getAttribute('aria-checked')) {
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                TableWrapper.reload(_baseId)
              } catch (e) {
                console.log(e, 'error')
              }
            }
          })
        }, true),
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/pegawai/${item.id}`), authStore.user.roleMap?authStore.user.roleMap['Pegawai'].Edit:false),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, authStore.user.roleMap?authStore.user.roleMap['Pegawai'].Delete:false)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
        }
        // return service.get(query)
        var res = await service.get(query)
        setData(res.data)
        return res
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>

    <DataForm
      baseId={"pegawai-import"}
      asDialog={true}
      defaultData={{}}
      definitions={[{
        inputType: FormWrapper.inputTypes.FILE_INPUT,
        label: 'File',
        key: 'file',
        theme: 'filled',
        style: {width: 200}
      },{
        inputType: FormWrapper.inputTypes.SELECT,
        label: 'Jenis Template',
        key: 'jenis',
        theme: 'filled',
        options: [{label: 'Template 1', value: "1"}, {label: "Template 2", value: "2"}],
        style: {width: 200}
      }]}
      visible={uploadModal}
      submitLabel='Import Data'
      onSubmit={async (d, callback)=> {
        var formData = new FormData()
        formData.set('file', d.file)
        var uploadRes = await service.http.post('/upload', formData)
        if(uploadRes.data) {
          var importRes = await service.http.post('/pegawai-import', {
            nama: uploadRes.data.name,
            origin: uploadRes.data.origin,
            perusahaanId: sipStore.companyId,
            type: d.jenis,
            ext: 'xls'
          })
        }
        callback('Data sedang di import. Mohon pantau progress import di menu log import.', false)
      }}
      onRequestClose={_closeUploadModal} />

    <DataForm
      baseId={"pegawai-export"}
      asDialog={true}
      defaultData={{}}
      definitions={[{
        inputType: FormWrapper.inputTypes.SELECT,
        label: 'Jenis Template',
        key: 'jenis',
        theme: 'filled',
        options: [{label: 'Template 1', value: "1"}, {label: "Template 2", value: "2"}],
        style: { minWidth: 400 },
      }]}
      visible={exportModal}
      submitLabel='Export Data'
      onSubmit={async (d, callback)=> {
        await service.http.post('/pegawai-export', {
          name: 'pegawai-export-'+moment().format('YYYY-MM-DD HH:mm:ss'),
          origin: 'pegawai-export-'+moment().format('YYYY-MM-DD HH:mm:ss')+".xls",
          perusahaanId: sipStore.companyId,
          type: d.jenis,
        }, {}, {
          'perusahaanId.equals': sipStore.companyId
        })

        callback('Data sedang di export. Mohon pantau progress export di menu log export.', false)
      }}
      onRequestClose={_closeExportModal} />
    </>
  )
}

export default inject('sipStore', 'navigationStore', 'modalStore', "authStore")(observer(PegawaiTable))
