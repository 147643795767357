import React, { useEffect, useState } from "react";
import {
  Flex,
  Commandbar,
  DataForm,
} from "../../../libs/react-mpk/components/index";
import {
  Checkbox,
  Fieldset,
  ListboxOption,
  Select,
  useChecked,
} from "@react-md/form";
import PerusahaanListService from "../../master/Perusahaan/Perusahaan.service";
import { List, ListItem, ListSubheader } from "@react-md/list";
import { inject, observer } from "mobx-react";
import PeriodeFiskalService from "../PeriodeFiskal/PeriodeFiskal.service";
import MemberService from "../../master/Member/Member.service";
import user, { userDefaults } from "../../../libs/custom/user";
import { cloneDeep } from "lodash";
import DashboardSample from "../../DashboardSample/DashboardSample";
import {
  DashboardWrapper,
  TableWrapper,
} from "../../../libs/react-mpk/wrapper";
import KontribusiPajakDashboardView from "./KontribusiPajakDashboardView";
import PelaporanDashboardView from "./PelaporanDashboardView";
import { TabsManager, Tabs, TabPanels, TabPanel } from "@react-md/tabs";
import { Text } from "@react-md/typography";
import {
  AppBar,
  AppBarNav,
  AppBarTitle,
  AppBarAction,
} from "@react-md/app-bar";
import SummarySpt21DashboardView from "./SummarySpt21DashboardView";
import SummarySpt22DashboardView from "./SummarySpt22DashboardView";
import SummarySpt23DashboardView from "./SummarySpt23DashboardView";
import SummarySpt4a2DashboardView from "./SummarySpt4a2DashboardView";
import SummaryPpnDashboardView from "./SummaryPpnDashboardView";
import iziToast from "izitoast";
import PembelianDashboardView from "./PembelianDashboardView";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardTitle,
  FontIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from "react-md";
import EkualisasiPajakDashboardView from "./EkualisasiPajakDashboardView";
import EkualisasiJurnalDashboardView from "./EkualisasiJurnalDashboardView";
import EkualisasiPembelianItemDashboardView from "./EkualisasiPembelianItemDashboardView";
import Utils from "../../../libs/custom/Utils";
import DashboardService from "./DashboardService";

function SptProformaPPh({ sipStore, authStore, modalStore, history }) {
  const [perusahaanList, setPerusahaanList] = useState([]);
  const [perusahaanMap, setPerusahaanMap] = useState({});

  const [periodeList, setPeriodeList] = useState([]);
  const [periodeMap, setPeriodeMap] = useState({});
  const [masa, setMasa] = useState((new Date().getMonth() + 1).toString());
  const [data, setData] = useState({});
  const [periode, setPeriode] = useState({});

  useEffect(() => {
    async function initData() {
      if (localStorage.getItem("sip-company-active")) {
        var d = JSON.parse(localStorage.getItem("sip-company-active"));
        sipStore.setCompany(d);
      }
      if (localStorage.getItem("sip-periode-active")) {
        var d = JSON.parse(localStorage.getItem("sip-periode-active"));
        sipStore.setPeriode(d);
      }
      await _getPerusahaanList();
      if (sipStore.companyId) {
        await _getPeriodeList(sipStore.companyId);
      }
    }
    initData();
  }, []);

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get("/my-perusahaans");
    setPerusahaanList(res.data.map((x) => ({ value: x.id, label: x.nama })));
    setPerusahaanMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _getPeriodeList(perusahaanId) {
    var res = await PeriodeFiskalService.get({
      "perusahaanId.equals": perusahaanId,
      size: 100000,
    });
    setPeriodeList(res.data.map((x) => ({ value: x.id, label: x.tahun })));
    setPeriodeMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _setRole(perusahaanUser) {
    console.log(perusahaanUser, "asdasd");
    var userClone = cloneDeep(userDefaults);
    if (
      perusahaanUser &&
      perusahaanUser.role &&
      perusahaanUser.role.permissions
    ) {
      var roleMap = perusahaanUser.role.permissions.reduce((pv, cv) => {
        if (!pv[cv.permission.module.nama]) pv[cv.permission.module.nama] = {};
        pv[cv.permission.module.nama][cv.permission.nama] = cv.allow;

        return pv;
      }, {});

      var menu = [];
      menu = menu.concat(userClone.menu.filter((x) => x.global));

      if (authStore.user.authorities.find((x) => x == "ROLE_ADMIN")) {
        menu = menu.concat(userClone.menu.filter((x) => x.adminPajak));
      }

      userClone.menu
        .filter((x) => !(x.adminPajak || x.global))
        .forEach((x) => {
          if (x.children) {
            x.children = x.children.filter((y) => {
              return (
                roleMap[y.permissionName] && roleMap[y.permissionName].View
              );
            });
            if (x.children.length > 0) menu.push(x);
          } else {
            menu.push(x);
          }
        });

      authStore.setProfile({
        ...authStore.user,
        roleMap: roleMap,
        menu,
      });
    } else {
      var menu = [];
      menu = menu.concat(userClone.menu.filter((x) => x.global));

      if (authStore.user.authorities.find((x) => x == "ROLE_ADMIN")) {
        menu = menu.concat(userClone.menu.filter((x) => x.adminPajak));
      }

      authStore.setProfile({
        ...authStore.user,
        menu,
      });
    }
  }

  async function submit() {
    var r = await DashboardService.summaaryPajakBulanan(
      sipStore.companyId,
      periode.tahun,
      masa
    );

    console.log(
      r.data.reduce((pv, cv) => {
        pv[cv.nama + "_" + cv.setorSendiri] = cv;
        return pv;
      }, {}),
      "r.data"
    );
    setData(
      r.data.reduce((pv, cv) => {
        pv[cv.nama + "_" + cv.setorSendiri] = cv;
        return pv;
      }, {})
    );
  }

  return (
    <Flex
      className={`mpk-flex align-start direction-column justify-start mpk-full full-width full-height mpk-position position-relative flex`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      <div className="mpk-flex align-start direction-row justify-start mpk-full full-width flexscrollable">
        <div className="mpk-flex align-start direction-column justify-start mpk-form-wrapper mpk-full full-width flex">
          <Commandbar title={"SPT Proforma Unifikasi(PPh)"} />

          <br />

          <Card
            raiseable
            className="mpk-form-wrapper mpk-paper mpk-padding-N padding-all mpk-animation slide-in"
            style={{ width: "100%", maxWidth: 1200, margin: "0px auto" }}
          >
            <div className="mpk-flex align-start direction-row justify-start mpk-full full-width wrap flex-none">
              <Select
                label="Pilih Perusahaan"
                style={{ width: "100%" }}
                id="custom-select-1"
                options={perusahaanList}
                value={sipStore.companyId}
                disabled
                className="mpk-margin-M margin-bottom"
                onChange={async (d) => {
                  localStorage.setItem(
                    "sip-company-active",
                    JSON.stringify(perusahaanMap[d])
                  );
                  sipStore.setCompany(perusahaanMap[d]);
                  var query = {
                    size: 10000,
                    "perusahaanId.equals": perusahaanMap[d].id,
                    "userId.equals": authStore.user.id,
                  };
                  var roles = await MemberService.getMember(query);
                  _setRole(roles.data[0]);
                  sipStore.setPeriode({});
                  _getPeriodeList(d);

                  var settings = await PerusahaanListService.http.get(
                    "/perusahaan-settings",
                    {
                      ["perusahaanId.equals"]: perusahaanMap[d].id,
                      size: 10000,
                    }
                  );

                  console.log(settings.data, "asd");

                  var setting = settings.data.reduce((pv, cv) => {
                    pv[cv.kode] = cv.value;
                    return pv;
                  }, {});

                  localStorage.setItem("sip_setting", JSON.stringify(setting));

                  sipStore.setSetting(setting);
                }}
              />
              <br />
              <Select
                label="Pilih Periode"
                style={{ width: "49%", marginRight: "2%" }}
                id="custom-select-2"
                options={periodeList}
                value={periode.id}
                onChange={(d) => {
                  setPeriode(periodeMap[d])
                  // localStorage.setItem(
                  //   "sip-periode-active",
                  //   JSON.stringify(periodeMap[d])
                  // );
                  // sipStore.setPeriode(periodeMap[d]);

                  // window.location.reload();
                }}
              />
              <Select
                label="Masa"
                value={masa}
                style={{ width: "49%" }}
                id="custom-select-2"
                options={[
                  {
                    value: 1,
                    label: "1",
                  },
                  {
                    value: 2,
                    label: "2",
                  },
                  {
                    value: 3,
                    label: "3",
                  },
                  {
                    value: 4,
                    label: "4",
                  },
                  {
                    value: 5,
                    label: "5",
                  },
                  {
                    value: 6,
                    label: "6",
                  },
                  {
                    value: 7,
                    label: "7",
                  },
                  {
                    value: 8,
                    label: "8",
                  },
                  {
                    value: 9,
                    label: "9",
                  },
                  {
                    value: 10,
                    label: "10",
                  },
                  {
                    value: 11,
                    label: "11",
                  },
                  {
                    value: 12,
                    label: "12",
                  },
                ]}
                onChange={(d) => {
                  setMasa(d);
                }}
              />
              <div
                className="mpk-flex justify-between direction-row"
                style={{ marginTop: 24 }}
              >
                <div className="mpk-flex" />
                <Button
                  theme="primary"
                  themeType="outline"
                  onClick={() => submit()}
                >
                  Tampilkan
                </Button>
              </div>
            </div>
          </Card>
          <br />
          <div style={{ width: "100%", maxWidth: 1200, margin: "0px auto" }}>
            <Card
              raiseable={true}
              style={{
                width: "100%",
                maxWidth: 1200,
                margin: "0px auto",
                borderTop: "",
              }}
            >
              <div
                className="mpk-padding-N"
                style={{ overflow: "auto" }}
              >
                <TableContainer>
                  <Table fullWidth className="bordered">
                    <TableHeader>
                      <TableRow>
                        <TableCell key="No">No</TableCell>
                        <TableCell key="URAIAN">Uraian</TableCell>
                        <TableCell key="URAIAN">PPh Terutang</TableCell>
                        <TableCell key="pph1">
                          Jumlah PPh yang ditanggung pemerintah
                        </TableCell>
                        <TableCell key="pph2">
                          Jumlah PPh yang telah disetor
                        </TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow>
                        <TableCell>I</TableCell>
                        <TableCell>
                          <b>PPh yang di setor sendiri</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>I.1</TableCell>
                        <TableCell>Pasal 4(2)</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 4a2_true"] &&
                          data["Pasal 4a2_true"].nilaiPajakPembelian +
                            data["Pasal 4a2_true"].nilaiPajakPenjualan +
                            data["Pasal 4a2_true"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 4a2_true"].nilaiPajakPembelian +
                                  data["Pasal 4a2_true"].nilaiPajakPenjualan +
                                  data["Pasal 4a2_true"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>I.2</TableCell>
                        <TableCell>Pasal 15</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 15_true"] &&
                          data["Pasal 15_true"].nilaiPajakPembelian +
                            data["Pasal 15_true"].nilaiPajakPenjualan +
                            data["Pasal 15_true"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 15_true"].nilaiPajakPembelian +
                                  data["Pasal 15_true"].nilaiPajakPenjualan +
                                  data["Pasal 15_true"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>I.3</TableCell>
                        <TableCell>Jumlah</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 15_true"] &&
                          data["Pasal 4a2_true"] &&
                          data["Pasal 15_true"].nilaiPajakPembelian +
                            data["Pasal 15_true"].nilaiPajakPenjualan +
                            data["Pasal 15_true"].nilaiPajakTransaksi +
                            (data["Pasal 4a2_true"].nilaiPajakPembelian +
                            data["Pasal 4a2_true"].nilaiPajakPenjualan +
                            data["Pasal 4a2_true"].nilaiPajakTransaksi) >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 15_true"].nilaiPajakPembelian +
                                  data["Pasal 15_true"].nilaiPajakPenjualan +
                                  data["Pasal 15_true"].nilaiPajakTransaksi +
                                  (data["Pasal 4a2_true"].nilaiPajakPembelian +
                                  data["Pasal 4a2_true"].nilaiPajakPenjualan +
                                  data["Pasal 4a2_true"].nilaiPajakTransaksi)
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>II</TableCell>
                        <TableCell>
                          <b>PPh yang telah dilakukan Pemotongan/Pemungutan</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>II.4</TableCell>
                        <TableCell>Pasal 22</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 22_false"] &&
                          data["Pasal 22_false"].nilaiPajakPembelian +
                            data["Pasal 22_false"].nilaiPajakPenjualan +
                            data["Pasal 22_false"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 22_false"].nilaiPajakPembelian +
                                  data["Pasal 22_false"].nilaiPajakPenjualan +
                                  data["Pasal 22_false"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>II.5</TableCell>
                        <TableCell>Pasal 23/26</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 23/26_false"] &&
                          data["Pasal 23/26_false"].nilaiPajakPembelian +
                            data["Pasal 23/26_false"].nilaiPajakPenjualan +
                            data["Pasal 23/26_false"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 23/26_false"].nilaiPajakPembelian +
                                  data["Pasal 23/26_false"]
                                    .nilaiPajakPenjualan +
                                  data["Pasal 23/26_false"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>II.6</TableCell>
                        <TableCell>Pasal 4(2)</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 4a2_false"] &&
                          data["Pasal 4a2_false"].nilaiPajakPembelian +
                            data["Pasal 4a2_false"].nilaiPajakPenjualan +
                            data["Pasal 4a2_false"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 4a2_false"].nilaiPajakPembelian +
                                  data["Pasal 4a2_false"].nilaiPajakPenjualan +
                                  data["Pasal 4a2_false"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>II.7</TableCell>
                        <TableCell>Pasal 15</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 15_false"] &&
                          data["Pasal 15_false"].nilaiPajakPembelian +
                            data["Pasal 15_false"].nilaiPajakPenjualan +
                            data["Pasal 15_false"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 15_false"].nilaiPajakPembelian +
                                  data["Pasal 15_false"].nilaiPajakPenjualan +
                                  data["Pasal 15_false"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>II.8</TableCell>
                        <TableCell>Jumlah</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 15_false"] &&
                          data["Pasal 4a2_false"].nilaiPajakPembelian +
                            data["Pasal 4a2_false"].nilaiPajakPenjualan +
                            data["Pasal 4a2_false"].nilaiPajakTransaksi +
                            data["Pasal 23/26_false"].nilaiPajakPembelian +
                            data["Pasal 23/26_false"].nilaiPajakPenjualan +
                            data["Pasal 23/26_false"].nilaiPajakTransaksi +
                            data["Pasal 22_false"].nilaiPajakPembelian +
                            data["Pasal 22_false"].nilaiPajakPenjualan +
                            data["Pasal 22_false"].nilaiPajakTransaksi +
                            data["Pasal 15_false"].nilaiPajakPembelian +
                            data["Pasal 15_false"].nilaiPajakPenjualan +
                            data["Pasal 15_false"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 4a2_false"].nilaiPajakPembelian +
                                  data["Pasal 4a2_false"].nilaiPajakPenjualan +
                                  data["Pasal 4a2_false"].nilaiPajakTransaksi +
                                  data["Pasal 23/26_false"]
                                    .nilaiPajakPembelian +
                                  data["Pasal 23/26_false"]
                                    .nilaiPajakPenjualan +
                                  data["Pasal 23/26_false"]
                                    .nilaiPajakTransaksi +
                                  data["Pasal 22_false"].nilaiPajakPembelian +
                                  data["Pasal 22_false"].nilaiPajakPenjualan +
                                  data["Pasal 22_false"].nilaiPajakTransaksi +
                                  data["Pasal 15_false"].nilaiPajakPembelian +
                                  data["Pasal 15_false"].nilaiPajakPenjualan +
                                  data["Pasal 15_false"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>III</TableCell>
                        <TableCell>
                          <b>Rekapitulasi PPh</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>III.9</TableCell>
                        <TableCell>
                          <b>Jumlah Total PPh</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          {data["Pasal 15_false"] &&
                          data["Pasal 15_true"].nilaiPajakPembelian +
                            data["Pasal 15_true"].nilaiPajakPenjualan +
                            data["Pasal 15_true"].nilaiPajakTransaksi +
                            (data["Pasal 15_true"].nilaiPajakPembelian +
                              data["Pasal 15_true"].nilaiPajakPenjualan +
                              data["Pasal 15_true"].nilaiPajakTransaksi) +
                            data["Pasal 4a2_false"].nilaiPajakPembelian +
                            data["Pasal 4a2_false"].nilaiPajakPenjualan +
                            data["Pasal 4a2_false"].nilaiPajakTransaksi +
                            data["Pasal 23/26_false"].nilaiPajakPembelian +
                            data["Pasal 23/26_false"].nilaiPajakPenjualan +
                            data["Pasal 23/26_false"].nilaiPajakTransaksi +
                            data["Pasal 22_false"].nilaiPajakPembelian +
                            data["Pasal 22_false"].nilaiPajakPenjualan +
                            data["Pasal 22_false"].nilaiPajakTransaksi +
                            data["Pasal 15_false"].nilaiPajakPembelian +
                            data["Pasal 15_false"].nilaiPajakPenjualan +
                            data["Pasal 15_false"].nilaiPajakTransaksi >
                            0
                            ? Utils.numberFormat(
                                data["Pasal 15_true"].nilaiPajakPembelian +
                                  data["Pasal 15_true"].nilaiPajakPenjualan +
                                  data["Pasal 15_true"].nilaiPajakTransaksi +
                                  (data["Pasal 15_true"].nilaiPajakPembelian +
                                    data["Pasal 15_true"].nilaiPajakPenjualan +
                                    data["Pasal 15_true"].nilaiPajakTransaksi) +
                                  data["Pasal 4a2_false"].nilaiPajakPembelian +
                                  data["Pasal 4a2_false"].nilaiPajakPenjualan +
                                  data["Pasal 4a2_false"].nilaiPajakTransaksi +
                                  data["Pasal 23/26_false"]
                                    .nilaiPajakPembelian +
                                  data["Pasal 23/26_false"]
                                    .nilaiPajakPenjualan +
                                  data["Pasal 23/26_false"]
                                    .nilaiPajakTransaksi +
                                  data["Pasal 22_false"].nilaiPajakPembelian +
                                  data["Pasal 22_false"].nilaiPajakPenjualan +
                                  data["Pasal 22_false"].nilaiPajakTransaksi +
                                  data["Pasal 15_false"].nilaiPajakPembelian +
                                  data["Pasal 15_false"].nilaiPajakPenjualan +
                                  data["Pasal 15_false"].nilaiPajakTransaksi
                              )
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell>III.10</TableCell>
                        <TableCell>
                          <b>Jumlah total PPh yang disetor pada SPT yang dibetulkan</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>III.11</TableCell>
                        <TableCell>
                          <b>Jumlah PPh yang kurang (lebih) disetor karena pembetulan</b>
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                        <TableCell style={{ textAlign: "right" }}>-</TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Flex>
  );
}

export default inject(
  "sipStore",
  "authStore",
  "modalStore"
)(observer(SptProformaPPh));
