import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./TransaksiAktivaTetap.service";
import KategoriPPh25Service from "../../adminpajakku/kategoriPph25/KategoriPph25.service";
import TipePengecualianPajakService from "../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service";
import Utils from "../../../libs/custom/Utils";
import AkunService from "../../master/Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import AsetService from "../Aset/Aset.service";
import TransaksiService from "./Transaksi.service";
import iziToast from "izitoast";
import { AKUN_JENIS_MAP } from "../../../clientConstant";

const TransaksiAktivaTetapForm = ({ envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({ loading: true, content: {} });
  const [akunList, setAkun] = useState([]);
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([]);
  const [asetList, setAsetList] = useState([]);
  const [journalMap, setJournalMap] = useState({});
  const [asetMap, setAsetMap] = useState({});

  const [latestAsetMap, setLatestAsetMap] = useState({});

  // initial data
  useEffect(() => {
    async function initData() {
      await _getAkun();
      _getKodeObjekPajak();
      // await _getAset()
      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            dipungut: false,
            dpp: "0",
            pph: "0",
            transaksiAktivaTetapAkun__nilai: "0",
            transaksiAktivaTetapPengecualians: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      await _getAset(res.data.jurnal.akun.id);
      res.data = Utils.formSerialize(res.data);
      res.data.transaksiAktivaTetapAsets.forEach((v) => {
        Utils.formSerialize(v);
      });
      // res.data.transaksiAktivaTetapAkun__akunid = res.data.transaksiAktivaTetapAkun.akun.id
      // res.data.transaksiAktivaTetapAset__asetid = res.data.transaksiAktivaTetapAset.aset.id
      setIsEdit(true);
      setLatestAsetMap(
        res.data.transaksiAktivaTetapAsets.reduce((pv, cv) => {
          pv[cv.id] = cv;
          return pv;
        }, {})
      );
      setData({ loading: false, content: res.data });
      console.log(res.data, "test");
    }
    initData();
  }, ["match.params.id"]);

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId);
    setJournalMap(
      res.data.transaksiJurnals.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setAkun(res.data.transaksiJurnals);
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({ size: 100000 });
    setKodeObjekPajak(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getAset(akunAktivaId) {
    var res = await AsetService.get({
      size: 100000,
      "akunAktivaId.equals": akunAktivaId,
    });
    var aset = res.data.filter((x) => {
      // return x.statusSanding == "Belum Tersanding"
      return true;
    });
    setAsetList(
      aset.map((x) => ({
        value: x.id,
        label: "[" + x.statusSanding + "] " + x.nama,
        statusSanding: x.statusSanding,
      }))
    );
    setAsetMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={
        "/onpremise/transaksi/" +
        match.params.transaksiId +
        "/transaksi-aktiva-tetap"
      }
      baseId="mod-form-sample"
      title="Aktiva Tetap"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if (key == "jurnal__id") {
          newObj.jurnal__nilai = journalMap[value].nilai;
          console.log(journalMap[value], "asd");
          await _getAset(journalMap[value].akun.id);
        }

        if (key == "aset__id") {
          var aset = asetMap[value] ? asetMap[value] : {};
          newObj.transaksiAktivaTetapAsets[
            indexAtParent
          ].aset__hargaTotalPerolehan = aset.hargaTotalPerolehan.toString();

          setLatestAsetMap(
            newObj.transaksiAktivaTetapAsets.reduce((pv, cv) => {
              pv[cv.aset__id] = cv;
              return pv;
            }, {})
          );
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "# AKUN TRANSAKSI",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Akun",
          key: "jurnal__id",
          type: "text",
          width: "49%",
          required: true,
          validation: "required",
          options: akunList
            .filter(
              (x) =>
                x.akun.akunJenisId == AKUN_JENIS_MAP["Akun Aktiva Tetap"].id
            )
            .map((x) => ({ value: x.id, label: x.akun.nama })),
          style: { marginRight: "2%" },
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai",
          key: "jurnal__nilai",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          validation: "required|numeric",
        },

        {
          inputType: inputTypes.DIVIDER,
          label: "# ASET",
        },

        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Daftar Aset",
          key: "transaksiAktivaTetapAsets",
          hintMessage: "",
          layout: "table",
          style: { width: 900 },
          defaultData: {
            tarifPajak: "0",
            keterangan: "-",
            tarifTanpaNpwp: "0",
          },
          definitions: [
            {
              inputType: inputTypes.SELECT,
              label: "Pilih Aset",
              key: "aset__id",
              type: "text",
              required: true,
              validation: "required",
              options: asetList.filter(
                (x) => x.statusSanding == "Belum Tersanding"
              ),
              style: { width: 400 },
              show: (d, key, i) => {
                if (d.transaksiAktivaTetapAsets) {
                  return !d.transaksiAktivaTetapAsets[i].id;
                } else {
                  return true
                }
              },
            },
            {
              inputType: inputTypes.SELECT,
              label: "Pilih Aset",
              key: "aset__id",
              type: "text",
              required: true,
              validation: "required",
              options: asetList.filter((x) => x.statusSanding == "Tersanding"),
              style: { width: 400 },
              disabled: true,
              show: (d, key, i) => {
                return (
                  d.transaksiAktivaTetapAsets &&
                  d.transaksiAktivaTetapAsets[i].id
                );
              },
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "Harga Total Perolehan",
              key: "aset__hargaTotalPerolehan",
              // theme: 'filled',
              thousandSeparator: ".",
              decimalSeparator: ",",
              // isNumericString: true,
              style: { width: 200 },
              disabled: true,
            },
          ],
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        try {
          data.transaksiId = match.params.transaksiId;
          data = Utils.formNormalize(data);
          // data.transaksiAktivaTetapAkun.akun = {
          //   id: data.transaksiAktivaTetapAkun__akunid
          // }

          // check duplicates
          var dupe = data.transaksiAktivaTetapAsets.reduce(
            (acc, cur) => {
              if (acc[cur.aset__id]) {
                acc.duplicates.push(cur);
              } else {
                acc[cur.aset__id] = true; //anything could go here
              }
              return acc;
            },
            { duplicates: [] }
          );

          if (dupe.duplicates.length > 0) {
            iziToast.warning({
              message: "Aset yang di daftarkan tidak boleh lebih dari 1",
            });
            callback("", false, false);
            return;
          }

          var dataSeluruhnya = await service.get({
            "transaksiId.equals": match.params.transaksiId,
            size: 1000000000000,
          });

          // check asset
          if (data.transaksiAktivaTetapAsets) {
            var total = 0;

            dataSeluruhnya.data
              .filter((x) => x.id != data.id && x.jurnal.id == data.jurnal.id)
              .forEach((d) => {
                d.transaksiAktivaTetapAsets.forEach((e) => {
                  total = total + e.aset.hargaTotalPerolehan;
                });
              });

            data.transaksiAktivaTetapAsets.forEach((v) => {
              Utils.formNormalize(v);
              total = total + v.aset.hargaTotalPerolehan;
            });
          }

          console.log(total, "asdasds");

          if (total < data.jurnal__nilai || total > data.jurnal__nilai) {
            iziToast.warning({
              message:
                "Total Nilai aset tetap yang dimasukkan melebihi nilai Akun Transaksi Aset Tetap",
            });
            callback("", false, false);
            return;
          }

          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push(
            "/onpremise/transaksi/" +
              match.params.transaksiId +
              "/transaksi-aktiva-tetap"
          );
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject("envStore")(observer(TransaksiAktivaTetapForm));
