import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './TransaksiPenjualanAktivaTetap.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import Utils from '../../../libs/custom/Utils'

var _baseId = "mod-table-transaksi-penjualan-aktiva-tetap"

const TransaksiPenjualanAktivaTetapTable = ({
  className       = '',
  history,
  transaksiId
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={''}
      className={className}
      showCommandbar={false}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Akun',
          searchable: true,
          key: 'akun',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.jurnal.akun.nomor +" - "+ item.jurnal.akun.nama)
        },{
          label: 'Aset',
          searchable: true,
          key: 'namaAset',
          type: TableWrapper.dataTypes.STRING,
          render: item => <div>
          {item.transaksiPenjualanAktivaTetapAsets.map((v, i)=> {
            return <p>{v.aset.nama} - {v.aset.nomor}</p>
          })}
        </div>
        }
        // {
        //   label: 'Nomor Aset',
        //   searchable: true,
        //   key: 'nomorAset',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.jenis)
        // },{
        //   label: 'Tanggal Perolehan',
        //   searchable: true,
        //   key: 'nomorAset',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.jenis)
        // },{
        //   label: 'Harga Perolehan',
        //   searchable: true,
        //   key: 'nomorAset',
        //   type: TableWrapper.dataTypes.STRING,
        //   render: item => (item.jenis)
        // }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push(`/onpremise/transaksi-penjualan-aktiva-tetap/baru/${transaksiId}`), true)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/transaksi-penjualan-aktiva-tetap/${item.id}/${transaksiId}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        query['transaksiId.equals'] = transaksiId
        return service.get(query)
      }}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default TransaksiPenjualanAktivaTetapTable
