import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './MetodePenyusutan.service'
import Utils from '../../../libs/custom/Utils'
import TipeMetodePenyusutanService from '../TipeMetodePenyusutan/TipeMetodePenyusutan.service'
import { ID_TIPE_METODE_PENYUSUTAN } from '../../../clientConstant'

const MetodePenyusutanForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [tipeMetodePenyusutanList, setTipeMetodePenyusutanList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      await _getTipeMetodePenyusutanList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {keterangan: ''}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setIsEdit(true)
      setData({loading: false, content:res.data})
      
    }
    initData()
  }, ['match.params.id'])

  async function _getTipeMetodePenyusutanList() {
    var res = await TipeMetodePenyusutanService.get()
    setTipeMetodePenyusutanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/metode-penyusutan`}
      baseId="mod-form-sample"
      title={t.translate('modules.metodePenyusutan.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'tipeMetodePenyusutan__id' || key == 'masaManfaat') {
          if(newObj.tipeMetodePenyusutan__id == ID_TIPE_METODE_PENYUSUTAN.GL && newObj.masaManfaat) {
            newObj.persentase = 100/(parseFloat(newObj.masaManfaat)/12)
            newObj.kode = "GL-"+parseInt((parseFloat(newObj.masaManfaat)/12))+'-'+parseInt(newObj.persentase)
          }
          if(newObj.tipeMetodePenyusutan__id == ID_TIPE_METODE_PENYUSUTAN.SM && newObj.masaManfaat) {
            newObj.persentase = (100/(parseFloat(newObj.masaManfaat)/12))*2
            newObj.kode = "SM-"+parseInt((parseFloat(newObj.masaManfaat)/12))+'-'+parseInt(newObj.persentase)
          }
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Metode',
          key: 'tipeMetodePenyusutan__id',
          type: 'text',
          validation: 'required',
          options: tipeMetodePenyusutanList
        },{
          inputType: inputTypes.SELECT,
          label: 'Masa Manfaat (Tahun)',
          key: 'masaManfaat',
          type: 'number',
          width: '49%',
          options: [
            // {value: '12', label: '1 Tahun'},
            // {value: '24', label: '2 Tahun'},
            // {value: '36', label: '3 Tahun'},
            // {value: '48', label: '4 Tahun'},
            // {value: '60', label: '5 Tahun'},
            // {value: '72', label: '6 Tahun'},
            // {value: '84', label: '7 Tahun'},
            // {value: '96', label: '8 Tahun'}
            {value: '48', label: '4 Tahun'},
            {value: '96', label: '8 Tahun'},
            {value: '120', label: '10 Tahun'},
            {value: '192', label: '16 Tahun'},
            {value: '240', label: '20 Tahun'}
          ],
          style: {marginRight: '2%'},
          validation: 'required'
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Persentase (%)',
          key: 'persentase',
          width: '49%',
          validation: 'required|numeric',
          thousandSeparator: '.',
          decimalSeparator: ',',
          disabled: true,
          isNumericString: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode',
          key: 'kode',
          type: 'text',
          disabled: true,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/metode-penyusutan')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(MetodePenyusutanForm))
