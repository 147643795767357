import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Pengurang.service'
import AkunListService from '../Akun/Akun.service'
import TipePengurangPhBrutoService from '../../adminpajakku/PengaturanPemotonganPPh21/PengaturanPemotonganPPh21.service'
import Utils from '../../../libs/custom/Utils'
import PerusahaanListService from '../Perusahaan/Perusahaan.service'

const PengurangForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [akunList, setAkunList] = useState([])
  const [tipePengurangPhBrutoList, setTipePengurangPhBrutoList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [perusahaanList, setPerusahaanList] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      await _getAkunList()
      await _getTipePengurangPhBrutoList()
      await _getPerusahaanList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
          approvalNotes: '',
          approvalStatus: '',
          kenaPajak: false,
          pengurangGaji: false,
          bukanSlipGaji: false
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getAkunList() {
    var res = await AkunListService.get({
      'perusahaanId.equals' : sipStore.companyId,
      size: 10000000
    })
    setAkunList(res.data.map(x => ({value: x.id, label: x.nomor +" - "+ x.nama})))
  }

  async function _getTipePengurangPhBrutoList() {
    var res = await TipePengurangPhBrutoService.get()
    setTipePengurangPhBrutoList(res.data.map(x => ({value: x.id, label: x.kodeObjekPajak?`${x.kodeObjekPajak.kode} - ${x.nama}`: `${x.nama}`})))
  }
  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/pengurang`}
      baseId="mod-form-sample"
      title={t.translate('modules.pengurang.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          required: true,
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Alias',
          key: 'alias',
          type: 'text',
          width: '49%',
        },{
          inputType: inputTypes.SELECT,
          label: 'Akun',
          key: 'akun__id',
          type: 'text',
          width: '100%',
          // style: {marginRight: '2%'},
          validation: 'required',
          options: akunList
        },{
          inputType: inputTypes.SELECT,
          label: 'Tipe Pengurang Bruto',
          key: 'tipePengurangPhBruto__id',
          type: 'text',
          width: '100%',
          validation: 'required',
          options: tipePengurangPhBrutoList
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Approval Notes',
        //   key: 'approvalNotes',
        //   type: 'text',
        //   width: '49%',
        //   style: {marginRight: '2%'}
        // },{
        //   inputType: inputTypes.INPUT,
        //   label: 'Approval Status',
        //   key: 'approvalStatus',
        //   type: 'text',
        //   width: '49%'
        // },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Apakah kena pajak?',
          key: 'kenaPajak',
          width: '49%',
          style: {marginRight: '2%'},
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Apakah mengurangi gaji?',
          key: 'pengurangGaji',
          width: '49%'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Tidak Ditampilkan di slip gaji karyawan',
          key: 'bukanSlipGaji',
          width: '100%'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/pengurang')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(PengurangForm))
