import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./PenyusutanPeriodeAkun.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import Utils from "../../../libs/custom/Utils";
import { inject, observer } from "mobx-react";

import {
  Caption,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableContainer,
} from "@react-md/table";
import iziToast from "izitoast";

var _baseId = "mod-table-penyusutan-periode-akun";

const PenyusutanPeriodeAkunTable = ({
  className = "",
  showCommandbar = true,
  sipStore,
  modalStore,
  history,
  match,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});
  const [sum, setSum] = useState({
    koreksiFiskal: 0,
    penyusutanFiskal: 0,
    penyusutanKomersial: 0,
  });

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  useEffect(() => {
    async function initData() {
      const res = await service.http.get(service.path + "/sum", {
        "penyusutanPeriodeId.equals": match.params.penyusutanPeriodeId,
      });
      setSum(res.data);
    }
    initData();
  }, ["match.params.penyusutanPeriodeId"]);

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title="Penyusutan & Amortisasi Per Akun"
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="nama"
        columns={[
          {
            label: "Nama Akun",
            searchable: true,
            key: "nama",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nama,
          },
          {
            label: "Nomor Akun",
            searchable: true,
            key: "nomor",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.nomor,
          },
          {
            label: "Penyusutan dan Amortisasi Fiskal",
            searchable: true,
            key: "penyusutanFiskal",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.penyusutanFiskal),
          },
          {
            label: "Penyusutan dan Amortisasi Komersial",
            searchable: true,
            key: "penyusutanKomersial",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => Utils.numberFormat(item.penyusutanKomersial),
          },
        ]}
        actions={[
          // new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/penyusutan-periode-aset/baru'), true)
          new TableWrapper.action("Back", "mdi mdi-arrow-left", () =>
            history.push("/onpremise/penyusutan-periode")
          ),
          new TableWrapper.action("Export Penyusutan", "mdi mdi-export", () => {
            modalStore.showConfirm({
              title: "Export Penyusutan",
              children: "",
              onSubmit: async (callback) => {
                await service.http.post(
                  "/penyusutan/export",
                  {
                    name:
                      "pegawai-export-" +
                      moment().format("YYYY-MM-DD HH:mm:ss"),
                    origin:
                      "pegawai-export-" +
                      moment().format("YYYY-MM-DD HH:mm:ss") +
                      ".xls",
                  },
                  {},
                  {},
                  {
                    "penyusutanPeriodeId.equals":
                      match.params.penyusutanPeriodeId,
                    "tipeAset.notEquals": "3",
                  }
                );

                iziToast.success({
                  title: "Berhasil",
                  message:
                    "Data sedang di export. Mohon pantau progress export di menu log export.",
                });
                callback(
                  "Data sedang di export. Mohon pantau progress export di menu log export."
                );
              },
            });
          }),
          new TableWrapper.action(
            "Export Amortisasi",
            "mdi mdi-export",
            () => {
              modalStore.showConfirm({
                title: "Export Amortisasi",
                children: "",
                onSubmit: async (callback) => {
                  await service.http.post(
                    "/penyusutan/export",
                    {
                      name:
                        "pegawai-export-" +
                        moment().format("YYYY-MM-DD HH:mm:ss"),
                      origin:
                        "pegawai-export-" +
                        moment().format("YYYY-MM-DD HH:mm:ss") +
                        ".xls",
                    },
                    {},
                    {},
                    {
                      "penyusutanPeriodeId.equals":
                        match.params.penyusutanPeriodeId,
                      "tipeAset.equals": "3",
                    }
                  );

                  iziToast.success({
                    title: "Berhasil",
                    message:
                      "Data sedang di export. Mohon pantau progress export di menu log export.",
                  });
                  callback(
                    "Data sedang di export. Mohon pantau progress export di menu log export."
                  );
                },
              });
            },
            true
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Buka Penyusutan Per Aset",
            "mdi mdi-book-open",
            (item) => {
              history.push(
                `/onpremise/penyusutan-periode-aset/${match.params.penyusutanPeriodeId}/${item.id}`
              );
            },
            true
          ),
        ]}
        onFetchData={async (query) => {
          if (!query) query = {};
          query["penyusutanPeriodeId.equals"] =
            match.params.penyusutanPeriodeId;
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
        tableFooter={
          <Table style={{ width: "100%" }}>
            <TableBody fullWidth>
              <TableRow>
                <TableCell>Jumlah Penyusutan dan Amortisasi Fiskal</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  {Utils.numberFormat(sum.penyusutanFiskal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Jumlah Penyusutan dan Amortisasi Komersial
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  {Utils.numberFormat(sum.penyusutanKomersial)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Jumlah Koreksi Fiskal</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  {Utils.numberFormat(sum.koreksiFiskal)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        }
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
    </>
  );
};

export default inject(
  "sipStore",
  "navigationStore",
  "modalStore",
  "authStore"
)(observer(PenyusutanPeriodeAkunTable));
