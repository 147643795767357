import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Aset.service'
import Utils from '../../../libs/custom/Utils'
import TipeAsetService from '../../adminpajakku/tipeAset/TipeAset.service'
import TipeBisnisAsetService from '../../master/JenisUsaha/JenisUsaha.service'
import KelompokAsetService from '../../adminpajakku/kelompokAset/KelompokAset.service'
import MetodePenyusutanService from '../../master/MetodePenyusutan/MetodePenyusutan.service'
import PerusahaanListService from '../../master/Perusahaan/Perusahaan.service'
import HartaService from '../../master/FixedAset/FixedAset.service'
import AkunService from '../../master/Akun/Akun.service'

import {Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  } from '@react-md/table'
import { Button } from "@react-md/button";
import PeriodeFiskalService from '../PeriodeFiskal/PeriodeFiskal.service'
import axios from 'axios'
import iziToast from 'izitoast'
import { AKUN_JENIS_MAP } from '../../../clientConstant'
import PerusahaanSettingService from '../../master/Perusahaan/PerusahaanSetting.service'
import moment from 'moment'
const AsetForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [curData, setCurData] = useState({})
  const [tipeAsetList, setTipeAsetList] = useState([])
  const [tipeBisnisAsetList, setTipeBisnisAsetList] = useState([])
  const [tipeBisnisAsetMap, setTipeBisnisAsetMap] = useState({})
  const [kelompokAsetList, setKelompokAsetList] = useState([])
  const [hartaList, setHartaList] = useState([])
  const [hartaMap, setHartaMap] = useState({})
  const [akunList, setAkunList] = useState([])
  const [metodePenyusutanFiskalList, setMetodePenyusutanFiskalList] = useState([])
  const [metodePenyusutanKomList, setMetodePenyusutanKomList] = useState([])
  const [metodePenyusutanMap, setMetodePenyusutanMap] = useState({})
  const [perusahaanList, setPerusahaanList] = useState([])
  const [periodeFiskalList, setPeriodeFiskalList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [perusahaanSetting, setPerusahaanSetting] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getTipeAsetList()
      // await _getTipeBisnisAsetList()
      // await _getKelompokAsetList()
      await _getMetodePenyusutanFiskalList()
      await _getMetodePenyusutanKomList()
      await _getPerusahaanList()
      await _getPeriodeFiskalList()
      await _getAkunList()
      var hartaMap2 = await _getHartaList()

      await _getPerusahaanSetting()


      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
          periodeFiskal__id: sipStore.periodeId,
            "akunAktivaId": null,
            "akunAkumulasiPenyusutanId": null,
            "asetBisaDisusutkan": true,
            "hargaSatuanPerolehan": '0',
            "hargaTotalPerolehan": '0',
            "jumlahItem": '0',
            "kategoriAsetId": null,
            "keterangan": "-",
            "masaManfaatTerpakai": "",
            "masaPerolehan": moment().format("M"),
            "metodePenyusutanFiskalId": "",
            "metodePenyusutanKomId": null,
            "nama": "",
            "nilaiBuku": '0',
            "nilaiPenyusutanFiskal": '0',
            "nomor": "",
            "statusData": "",
            "tahunPerolehan": moment().format("YYYY"),
            "tanggalPerolehan": moment().format("YYYY-MM-DD")
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      if(res.data.kategoriAsetId) {
        res.data.tipeAsetId = hartaMap2[res.data.kategoriAsetId]?hartaMap2[res.data.kategoriAsetId].tipeAset.nama:''
        res.data.kelompokAsetId = hartaMap2[res.data.kategoriAsetId]?hartaMap2[res.data.kategoriAsetId].kelompokAset.nama:''
        res.data.tipeBisnisAsetId = hartaMap2[res.data.kategoriAsetId]?hartaMap2[res.data.kategoriAsetId].tipeBisnisAset.nama:''
      }

      res.data = Utils.formSerialize(res.data)
      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  async function _getPerusahaanSetting(companyId) {
    var res = await PerusahaanSettingService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId, 'kode.equals': 'METODE PENYUSUTAN'})
    setPerusahaanSetting(res.data[0]?res.data[0]:{})
  }

  async function _getTipeAsetList() {
    var res = await TipeAsetService.get()
    setTipeAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getAkunList() {
    var res = await AkunService.get({
      size: 1000000,
      'perusahaanId.equals': sipStore.companyId
    })
    setAkunList(res.data)
  }

  async function _getHartaList(params) {
    if(!params) params = {}
    params.size = 1000
    params['perusahaanId.equals'] = sipStore.companyId
    var res = await HartaService.get(params)

    setHartaMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))

    setHartaList(res.data.map(x => ({value: x.id, label: x.kode?`${x.kode} - ${x.nama}`: `${x.nama}`})))

    return res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {})
  }

  async function _getTipeBisnisAsetList(params) {
    if(!params) params = {}
    params.size = 1000
    var res = await TipeBisnisAsetService.get(params)

    setTipeBisnisAsetMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))

    setTipeBisnisAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getKelompokAsetList(params) {
    if(!params) params = {}
    params.size = 1000
    var res = await KelompokAsetService.get(params)
    setKelompokAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getMetodePenyusutanFiskalList() {
    var res = await MetodePenyusutanService.get()
    setMetodePenyusutanFiskalList(res.data.map(x => ({value: x.id, label: x.kode})))
  }

  async function _getMetodePenyusutanKomList() {
    var res = await MetodePenyusutanService.get()
    setMetodePenyusutanMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setMetodePenyusutanKomList(res.data.map(x => ({value: x.id, label: x.kode})))
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getPeriodeFiskalList() {
    var res = await PeriodeFiskalService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId})
    setPeriodeFiskalList(res.data.map(x => ({value: x.id, label: x.tahun})))
  }

  async function _handleHitung() {
    var metodePenyusutan = metodePenyusutanMap[curData.metodePenyusutanFiskalId]?metodePenyusutanMap[curData.metodePenyusutanFiskalId]:{}

    var res = await service.http.post('/asets/penyusutan-aset', curData);

    curData.nilaiPenyusutanFiskal = res.data.akumulasiPenyusutan
    curData.nilaiBuku = res.data.sisaHargaPerolehan

    FormWrapper.reload('aset-form', curData)

    iziToast.info({message: 'Aset telah di hitung'})
  }

  return (
    <FormWrapper
      noValidate
      baseId='aset-form'
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/aset`}
      title='Aset'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {

        // if(key == 'tipeAsetId') {
        //   _getKelompokAsetList({
        //     'tipeAsetId.equals': value
        //   })
        // }

        // if(key == 'kelompokAsetId') {
        //   _getTipeBisnisAsetList({
        //     'kelompokAsetId.equals': value
        //   })
        // }

        // if(key == 'tipeBisnisAsetId') {
        //   _getHartaList({
        //     'tipeBisnisAsetId.equals': value
        //   })

        // }

        if(key == 'tanggalPerolehan') {
          newObj.masaManfaatTerpakai = (parseInt(moment(moment()).diff(value, 'months', true))).toString()
        }

        if(key == 'metodePenyusutanFiskalId') {
          var met = metodePenyusutanMap[value]? metodePenyusutanMap[value]:{}
          newObj.masaManfaatFiskal = met.masaManfaat.toString()
        }

        if(key == 'kategoriAsetId') {
          newObj.tipeAsetId = hartaMap[value]?hartaMap[value].tipeAset.nama:''
          newObj.kelompokAsetId = hartaMap[value]?hartaMap[value].kelompokAset.nama:''
          newObj.tipeBisnisAsetId = hartaMap[value]?hartaMap[value].tipeBisnisAset.nama:''
          // newObj.nomor = hartaMap[value]? hartaMap[value].kode: ''

          console.log(perusahaanSetting, 'perusahaanSetting.asdasd')
          if(perusahaanSetting.value == 'Garis Lurus') {
            newObj.metodePenyusutanFiskalId=hartaMap[value]? hartaMap[value].metodePenyusutanGlId: ''
          } else {
            newObj.metodePenyusutanFiskalId=hartaMap[value]? hartaMap[value].metodePenyusutanSmId: ''
          }

          var met = metodePenyusutanMap[newObj.metodePenyusutanFiskalId]? metodePenyusutanMap[newObj.metodePenyusutanFiskalId]:{}
          if(met.masaManfaat) {
            newObj.masaManfaatFiskal = met.masaManfaat.toString()
          }

        }

        if(key == 'tanggalPerolehan') {
          newObj.masaPerolehan = value.split('-').length == 3?parseInt(value.split('-')[1]).toString():''
          newObj.tahunPerolehan = value.split('-').length == 3?value.split('-')[0]:''
        }

        if(key == 'jumlahItem' || key == 'hargaSatuanPerolehan') {
          newObj.hargaTotalPerolehan = parseFloat(newObj.jumlahItem)*parseFloat(newObj.hargaSatuanPerolehan)
        }
        setCurData(newObj)
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          disabled: isEdit,
          validation: 'required',
          options: perusahaanList,
          disabled: sipStore.companyId != null
        }, {
          inputType: inputTypes.SELECT,
          label: 'Periode',
          key: 'periodeFiskal__id',
          type: 'text',
          disabled: isEdit,
          validation: 'required',
          options: periodeFiskalList,
          disabled: sipStore.periodeId != null
        }, {
          inputType: inputTypes.DIVIDER,
          label: 'DETAIL ASET'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nomor Aset',
          key: 'nomor',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama Aset',
          key: 'nama',
          type: 'text',
          width: '49%',
          disabled: isEdit,
          validation: 'required|max:255'
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal Perolehan',
          key: 'tanggalPerolehan',
          type: 'date',
          width: '100%',
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Masa Perolehan',
          key: 'masaPerolehan',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Tahun Perolehan',
          key: 'tahunPerolehan',
          type: 'text',
          width: '49%',
          disabled: true
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Quantity',
          key: 'jumlahItem',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          style: {marginRight: '2%'},
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Harga Satuan Perolehan',
          key: 'hargaSatuanPerolehan',
          width: '49%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          validation: 'required',
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Harga Total Perolehan',
          key: 'hargaTotalPerolehan',
          width: '100%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          disabled: true
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun Aktiva',
          key: 'akunAktivaId',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          options: akunList.filter(x => x.akunJenisId == AKUN_JENIS_MAP["Akun Aktiva Tetap"].id).map(x => ({value: x.id, label: x.nama}))
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun Akumulasi Penyusutan',
          key: 'akunAkumulasiPenyusutanId',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          options: akunList.filter(x => x.akunJenisId == AKUN_JENIS_MAP["Akun Akumulasi Penyusutan"].id).map(x => ({value: x.id, label: x.nama}))
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun Beban Penyusutan',
          key: 'akunPenyusutanId',
          type: 'text',
          width: '100%',
          validation: 'required',
          // style: {marginRight: '2%'},
          options: akunList.filter(x => x.akunJenisId == AKUN_JENIS_MAP["Akun Beban Penyusutan"].id).map(x => ({value: x.id, label: x.nama}))
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text'
        },{
          inputType: inputTypes.DIVIDER,
          label: 'KODE JENIS ASET'
        },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Jenis Harta',
        //   key: 'tipeAsetId',
        //   type: 'text',
        //   disabled: isEdit,
        //   options: tipeAsetList,
        //   width: '49%',
        //   style: {marginRight: '2%'},
        // },{
        //   inputType: inputTypes.SELECT,
        //   label: 'Kelompok Harta',
        //   key: 'kelompokAsetId',
        //   type: 'text',
        //   disabled: isEdit,
        //   options: kelompokAsetList,
        //   width: '49%',
        // },{
        //   inputType: inputTypes.SELECT,
        //   label: 'Jenis Usaha',
        //   key: 'tipeBisnisAsetId',
        //   type: 'text',
        //   disabled: isEdit,
        //   options: tipeBisnisAsetList,
        //   width: '49%',
        //   style: {marginRight: '2%'},
        // },{
        //   inputType: inputTypes.SELECT,
        //   label: 'Harta',
        //   key: 'kategoriAsetId',
        //   type: 'text',
        //   validation: 'required',
        //   disabled: isEdit,
        //   width: '49%',
        //   options: hartaList
        // },
        {
          inputType: inputTypes.SELECT,
          label: 'Kode Jenis Aset',
          key: 'kategoriAsetId',
          type: 'text',
          validation: 'required',
          disabled: isEdit,
          width: '49%',
          style: {marginRight: '2%'},
          options: hartaList
        },{
          inputType: inputTypes.INPUT,
          label: 'Jenis Harta',
          key: 'tipeAsetId',
          type: 'text',
          disabled: true,
          width: '49%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Kelompok Harta',
          key: 'kelompokAsetId',
          type: 'text',
          disabled: true,
          width: '49%',
          style: {marginRight: '2%'}
        },{
          inputType: inputTypes.INPUT,
          label: 'Jenis Usaha',
          key: 'tipeBisnisAsetId',
          type: 'text',
          disabled: true,
          width: '49%'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFO PENYUSUTAN'
        },{
          inputType: inputTypes.SELECT,
          label: 'Metode Penyusutan Fiskal',
          key: 'metodePenyusutanFiskalId',
          type: 'text',
          // width: '49%',
          // style: {marginRight: '2%'},
          options: metodePenyusutanKomList,
          disabled: true,
          validation: 'required',
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Masa Manfaat Fiskal',
          key: 'masaManfaatFiskal',
          width: '29%',
          style: {marginRight: '2%'},
          type: 'number',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          disabled: true
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Masa Manfaat Terpakai (Bulan)',
          key: 'masaManfaatTerpakai',
          width: '34%',
          style: {marginRight: '2%'},
          type: 'number',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          // disabled: true
        },
        {
          render: (d)=> {
            return <div>Masa Manfaat Sisa (Bulan) <br /> <b>{Utils.numberFormat(d.masaManfaatTerpakai > d.masaManfaatFiskal ? d.masaManfaatFiskal : d.masaManfaatFiskal - d.masaManfaatTerpakai) }</b></div>
          },
          width: '24%',
        },
        {
          inputType: inputTypes.DIVIDER,
        },
        {
          render: ()=> {
            return <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none" onClick={_handleHitung} style={{width: '100%'}}>
              <Button  theme="primary" themeType="outline">Hitung</Button>
            </div>

          }
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai Akumulasi Penyusutan Fiskal',
          key: 'nilaiPenyusutanFiskal',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai Buku',
          key: 'nilaiBuku',
          width: '49%',
          style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          disabled: true
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Metode Penyusutan Komersial',
          key: 'metodePenyusutanKomersial',
          type: 'text',
          // width: '49%',
          // style: {marginRight: '2%'},
          options: [
            {label: 'Garis Lurus', value: 'Garis Lurus'},
            {label: 'Jumlah Angka Tahun', value: 'Jumlah Angka Tahun'},
            {label: 'Saldo Menurun', value: 'Saldo Menurun'},
            {label: 'Saldo Menurun Ganda', value: 'Saldo Menurun Ganda'},
            {label: 'Jumlah Jam Jasa', value: 'Jumlah Jam Jasa'},
            {label: 'Jumlah Satuan Produksi', value: 'Jumlah Satuan Produksi'},
            {label: 'Metode Lainnya', value: 'Metode Lainnya'}
          ],
          validation: 'required',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Sebagai Aset yang Bisa Disusutkan',
          key: 'asetBisaDisusutkan',
          width: '100%',
        },
        // {
        //   render: (d)=> {
        //     var metodePenyusutan = metodePenyusutanMap[d.metodePenyusutanFiskalId]?metodePenyusutanMap[d.metodePenyusutanFiskalId]:{}

        //     if(d.metodePenyusutanFiskalId && d.metodePenyusutanFiskalId != '' && d.hargaTotalPerolehan && d.hargaTotalPerolehan != '' && d.masaPerolehan && d.masaPerolehan != '' && d.tahunPerolehan && d.tahunPerolehan != '') {
        //       var tahun = parseFloat(d.tahunPerolehan)
        //       var masa = parseFloat(d.masaPerolehan)
        //       var nilaiSisaBuku = d.hargaTotalPerolehan;
        //       var panjang = metodePenyusutan.masaManfaat?metodePenyusutan.masaManfaat:1
        //       var penyusutanPerBulan = d.hargaTotalPerolehan/panjang
        //       var counter = 0;

        //       var penyusutanList = Array.from(Array(panjang)).map((v, i) => {
        //         counter ++;
        //         masa++;
        //         if(masa == 13) {
        //           tahun = tahun+1;
        //           masa = 1;

        //           nilaiSisaBuku = nilaiSisaBuku - ((counter-1) * penyusutanPerBulan)
        //           counter = 1;
        //         }

        //         var akumulasiTotal = (i+1) * penyusutanPerBulan;
        //         var akumulasiPertahun = (counter) * penyusutanPerBulan;

        //         return {tahun, masa, penyusutanPerBulan, akumulasiPertahun, akumulasiTotal, nilaiSisaBuku}

        //       })

        //       return<Table>
        //         <TableHeader>
        //           <TableRow>
        //             <TableCell>No.</TableCell>
        //             <TableCell>Tahun</TableCell>
        //             <TableCell>Masa</TableCell>
        //             <TableCell>Nilai Penyusutan</TableCell>
        //             <TableCell>Akumulasi Pertahun</TableCell>
        //             <TableCell>Nilai Sisa Buku</TableCell>
        //             <TableCell>Akumulasi Total</TableCell>
        //           </TableRow>
        //         </TableHeader>
        //         <TableBody>
        //           {penyusutanList.map((d, i)=> {
        //             return <TableRow key={i}>
        //               <TableCell>{i+1}</TableCell>
        //               <TableCell>{d.tahun}</TableCell>
        //               <TableCell>{d.masa}</TableCell>
        //               <TableCell>{Utils.numberFormat(d.penyusutanPerBulan)}</TableCell>
        //               <TableCell>{Utils.numberFormat(d.akumulasiPertahun)}</TableCell>
        //               <TableCell>{Utils.numberFormat(d.nilaiSisaBuku)}</TableCell>
        //               <TableCell>{Utils.numberFormat(d.akumulasiTotal)}</TableCell>
        //             </TableRow>
        //           })}

        //         </TableBody>
        //       </Table>
        //     }
        //     return <div></div>
        //   }
        // }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          // var compareDate = moment(data.tanggalPerolehan, "YYYY-MM-DD");
          // var startDate   = moment(sipStore.periode.dari, "YYYY-MM-DD");
          // var endDate     = moment(sipStore.periode.ke, "YYYY-MM-DD");

          // if(!compareDate.isBetween(startDate, endDate)) {
          //   iziToast.warning({
          //     title:'Warning',
          //     message: 'Tanggal Perolehan harus di antara '+sipStore.periode.dari+' dan '+sipStore.periode.ke
          //   })
          //   callback('', true, false)
          //   return true
          // }


          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/aset')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('sipStore', 'navigationStore')(observer(AsetForm))
