import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Perusahaan.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'

var _baseId = "mod-table-perusahaan"

const PerusahaanTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  authStore,
  modalStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    try {
      var r = await service.delete(confirmDeleteData.id)
      toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
      setConfirmDelete(false)
      TableWrapper.reload(_baseId)
    } catch(e) {
      toast.info('Perusahaan sedang di pakai, sehingga tidak dapat di hapus')
      setConfirmDelete(false)
      callback()
    }
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={t.translate('modules.perusahaan.title')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Nama Perusahaan',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nama)
        },{
          label: 'NPWP Perusahaan',
          searchable: true,
          key: 'npwp',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.npwp)
        },{
          label: 'Alias',
          searchable: true,
          key: 'alias',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.alias)
        },{
          label: 'Alamat Sesuai NPWP',
          searchable: true,
          key: 'alamat',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.alamat)
        },{
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/perusahaan/baru'), true)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/perusahaan/${item.id}`), true),
        new TableWrapper.action('Setting', 'mdi mdi-pencil', (item) => history.push(`/onpremise/perusahaan-setting/${item.id}`), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(authStore.user.authorities.find(x => x == 'ROLE_USER')) {
          if(sipStore.companyId) {
            query['id.equals'] = sipStore.companyId
          }
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default inject('sipStore', 'navigationStore', 'authStore', 'modalStore')(observer(PerusahaanTable))
