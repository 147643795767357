import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import DashboardService from "./DashboardService";
import { DataForm } from "../../../libs/react-mpk/components";
import moment from "moment";

import {
  SortOrder,
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Caption,
} from "@react-md/table";
import { FontIcon } from "@react-md/icon";
import {
  AppBar,
  AppBarNav,
  AppBarTitle,
  AppBarAction,
} from "@react-md/app-bar";
import { useState } from "react";
import { Line, Circle } from "rc-progress";
import Utils from "../../../libs/custom/Utils";

function SummarySpt4a2DashboardView({ sipStore }) {
  const [data, setData] = useState({
    loading: true,
    content: [],
    sortKey: "nama",
    sortOrder: "ascending",
    total: 0,
    param: {},
  });

  const [toggleFilter, setToggleFilter] = useState(false);

  useEffect(() => {
    initData("nama", {
      perusahaanId: sipStore.companyId,
      "tahun.equals": "" + sipStore.periode.tahun,
      "bulan.greaterThanOrEqual": "1",
      "bulan.lowerThanOrEqual": "12",
    });
  }, []);

  async function initData(sortKey, param) {
    var d = data.content;
    if (param) {
      param.pageSize = 10000000000;
      delete param.tahun;
      var pph4a2 = await DashboardService.pelaporanPph4a2Detail(param);

      d = pph4a2.data
    }

    setData((prevState) => {
      const prevSortKey = prevState.sortKey;
      const prevSortOrder = prevState.sortOrder;

      let sortOrder;
      if (sortKey === prevSortKey) {
        // it's the same column, so toggle the sort order
        sortOrder = prevSortOrder === "ascending" ? "descending" : "ascending";
      } else {
        // it's a new column to sort by, so default to ascending for the name column
        // but descending for all the rest.
        sortOrder = sortKey === "name" ? "ascending" : "descending";
      }

      return {
        content: d.sort((a, b) => {
          const aValue = a[sortKey];
          const bValue = b[sortKey];
          const value =
            typeof aValue === "number"
              ? aValue - bValue
              : "" + aValue.localeCompare(bValue);
          return value * (sortOrder === "ascending" ? 1 : -1);
        }),
        sortKey,
        sortOrder,
        param,
        loading: false,
        total: d.reduce((pv, cv) => {
          pv = pv + cv.totalPph;
          return pv;
        }, 0),
      };
    });
  }

  const { content, sortKey, sortOrder } = data;

  return (
    <div
      className="mpk-form-wrapper mpk-paper  padding-all mpk-animation slide-in"
      style={{
        width: "100%",
        maxWidth: 1200,
        margin: "0px auto",
      }}
    >
      <AppBar theme="clear">
        <AppBarTitle className="rmd-typography--capitalize">
          {/* PELAPORAN */}
        </AppBarTitle>

        <AppBarAction
          first
          aria-label="Actions"
          onClick={() => {
            setToggleFilter(!toggleFilter);
          }}
        >
          <FontIcon iconClassName="mdi mdi-filter" />
        </AppBarAction>
      </AppBar>
      <div className="mpk-padding-N" style={{ height: 600, overflow: "auto" }}>
        {toggleFilter && (
          <DataForm
            baseId={"pegawai-import"}
            // asDialog={true}
            defaultData={data.param}
            definitions={[
              {
                inputType: DataForm.inputTypes.INPUT,
                label: "Tahun Pajak",
                key: "tahun.equals",
                theme: "filled",
                validation: "required",
                width: "49%",
                style: { marginRight: "2%", width: "100%" },
              },
              {
                inputType: DataForm.inputTypes.SELECT,
                label: "Masa Pajak Dari",
                key: "bulan.greaterThanOrEqual",
                type: "text",
                style: { marginRight: "2%" },
                width: "49%",
                required: true,
                validation: "required",
                options: [
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                  { value: "4", label: "4" },
                  { value: "5", label: "5" },
                  { value: "6", label: "6" },
                  { value: "7", label: "7" },
                  { value: "8", label: "8" },
                  { value: "9", label: "9" },
                  { value: "10", label: "10" },
                  { value: "11", label: "11" },
                  { value: "12", label: "12" },
                ],
              },
              {
                inputType: DataForm.inputTypes.SELECT,
                label: "Masa Pajak Sampai",
                key: "bulan.lowerThanOrEqual",
                type: "text",
                width: "49%",
                required: true,
                validation: "required",
                options: [
                  { value: "1", label: "1" },
                  { value: "2", label: "2" },
                  { value: "3", label: "3" },
                  { value: "4", label: "4" },
                  { value: "5", label: "5" },
                  { value: "6", label: "6" },
                  { value: "7", label: "7" },
                  { value: "8", label: "8" },
                  { value: "9", label: "9" },
                  { value: "10", label: "10" },
                  { value: "11", label: "11" },
                  { value: "12", label: "12" },
                ],
              },
              // {
              //   inputType: DataForm.inputTypes.SELECT,
              //   label: "Kategori Pajak",
              //   key: "type",
              //   theme: "filled",
              //   validation: "required",
              //   options: [
              //     { label: "Pajak Terutang", value: "Pajak Terutang" },
              //     {
              //       label: "Pajak Dibayar Dimuka",
              //       value: "Pajak Dibayar Dimuka",
              //     },
              //   ],
              //   style: { width: "29%" },
              // },
            ]}
            // visible={uploadModal}
            submitLabel="Cari"
            onSubmit={async (d, callback) => {
              try {
                await initData(data.sortKey, {
                  ...d,
                  perusahaanId: sipStore.companyId,
                });
                callback();
              } catch (e) {
                console.log(e.response);
                callback(e.response.data.message);
              }
            }}
          />
        )}
        <TableContainer style={{minHeight:600}}>
          <Table fullWidth>
            <Caption>
              {data.param["bulan.greaterThanOrEqual"]} -{" "}
              {data.param["bulan.lowerThanOrEqual"]}{" "}
              {data.param["tahun.equals"]}
            </Caption>
            <TableHeader>
              <TableRow>
                <TableCell
                  key="npwp"
                  aria-sort={"npwp" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("npwp")}
                >
                  NPWP
                </TableCell>
                <TableCell
                  key="nama"
                  aria-sort={"nama" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("nama")}
                >
                  NAMA CABANG
                </TableCell>
                <TableCell
                  key="bulan"
                  aria-sort={"bulan" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("bulan")}
                >
                  MASA PAJAK
                </TableCell>
                <TableCell
                  key="tahun"
                  aria-sort={"tahun" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("tahun")}
                >
                  TAHUN PAJAK
                </TableCell>
                <TableCell
                  key="pembetulan"
                  aria-sort={"pembetulan" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("pembetulan")}
                >
                  PEMBETULAN
                </TableCell>
                <TableCell
                  key="status"
                  aria-sort={"status" === sortKey ? sortOrder : "none"}
                >
                  STATUS
                </TableCell>
                <TableCell
                  key="tglLapor"
                  aria-sort={"tglLapor" === sortKey ? sortOrder : "none"}
                  onClick={() => initData("tglLapor")}
                >
                  PEMOTONGAN DAN PEMUNGUTAN PPH
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  PERSEWAAN TANAH DAN ATAU BANGUNGAN
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  PPH
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  {"Perencana Konstruksi".toUpperCase()}
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  PPH
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  {"Pelaksana Konstruksi".toUpperCase()}
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  PPH
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  {"Pengawas Konstruksi".toUpperCase()}
                </TableCell>
                <TableCell
                  key="tglLapor"
                >
                  PPH
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.content.map((v, i) => {
                var c = colors[getRandomInt(34, 0)];
                return (
                  <TableRow>
                    <TableCell>
                      <div style={{}}>{v.npwp}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.nama}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.bulan}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.tahun}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.pembetulan}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{v.ntte?"SUDAH LAPOR":"BELUM LAPOR"}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.totalPph)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.brutoSewaTanahSs)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.pphSewaTanahSs)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.brutoPerencanaanSs)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.pphPerencanaanSs)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.brutoPelaksanaSs)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.pphPelaksanaSs)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.brutoPengawasSs)}</div>
                    </TableCell>
                    <TableCell>
                      <div style={{}}>{Utils.numberFormat(v.pphPengawasSs)}</div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default inject(
  "sipStore",
  "authStore"
)(observer(SummarySpt4a2DashboardView));

const colors = [
  "#ef5350",
  "#ff1744",
  "#ec407a",
  "#f50057",
  "#ab47bc",
  "#d500f9",
  "#7e57c2",
  "#651fff",
  "#5c6bc0",
  "#3d5afe",
  "#42a5f5",
  "#2979ff",
  "#29b6f6",
  "#00b0ff",
  "#26c6da",
  "#00e5ff",
  "#26a69a",
  "#1de9b6",
  "#66bb6a",
  "#00e676",
  "#9ccc65",
  "#76ff03",
  "#d4e157",
  "#c6ff00",
  "#ffee58",
  "#ffea00",
  "#ffca28",
  "#ffc400",
  "#ffa726",
  "#ff9100",
  "#ff7043",
  "#ff3d00",
  "#8d6e63",
  "#bdbdbd",
  "#78909c",
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
