import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./TransaksiPembelianAkun.service";
import KategoriPPh25Service from "../../adminpajakku/kategoriPph25/KategoriPph25.service";
import TipePengecualianPajakService from "../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service";
import Utils from "../../../libs/custom/Utils";
import AkunService from "../../master/Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import AsetService from "../Aset/Aset.service";
import TransaksiService from "./Transaksi.service";
import ElemenTranskripService from "../../adminpajakku/ElemenTranskrip/ElemenTranskrip.service";
import { AKUN_JENIS_MAP, ID_CONS } from "../../../clientConstant";
import { Button } from "@react-md/button";
import iziToast from "izitoast";
import moment from "moment";
import TransaksiPembelianService from "./TransaksiPembelian.service";
import TransaksiPajakTerutangService from "./TransaksiPajakTerutang.service";

const TransaksiPembelianAkunForm = ({ envStore, match, history, sipStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({ loading: true, content: {} });
  const [akunList, setAkun] = useState([]);
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [itemMap, setItemMap] = useState({});
  const [asetMap, setAsepMap] = useState({});
  const [journalMap, setJournalMap] = useState({});
  const [metodePenyusutanKomList, setMetodePenyusutanKomList] = useState([]);
  const [metodePenyusutanMap, setMetodePenyusutanMap] = useState({});
  const [curAset, setCurAset] = useState({});
  const [curTransaksi, setCurTransaksi] = useState({});
  const [curData, setCurData] = useState({});
  const [jumlahItem, setJumlahItem] = useState([]);

  const [akunList2, setAkunList2] = useState([]);
  const [pjkTerutangMap, setPajakTerutangMap] = useState({});
  const [kopMap, setKopMap] = useState({});

  // initial data
  useEffect(() => {
    async function initData() {
      // await _getAkunList2()
      await _getPembelianItem();
      await _getAkunList2();
      // await _getMetodePenyusutanKomList()
      // _getKodeObjekPajak()x
      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            dipungut: false,
            dpp: "0",
            pph: "0",
            nilai: "0",
            transaksiPenjualanAktivaTetapAkun__nilai: "0",
            transaksiAktivaTetapPengecualians: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      res.data = Utils.formSerialize(res.data);
      res.data.details = res.data.details.map(p => {
        return Utils.formSerialize(p);
      })

      var pjkTerutang = await TransaksiPajakTerutangService.get({
        size: 100000,
        "itemId.equals": res.data.pembelianItem.id,
      });
      setPajakTerutangMap(
        pjkTerutang.data.reduce((pv, cv) => {
          pv[cv.kodeObjekPajak.id] = cv;
          return pv;
        }, {})
      );
      setKodeObjekPajak(
        pjkTerutang.data.map((x) => ({
          value: x.kodeObjekPajak.id,
          label: x.kodeObjekPajak.nama,
        }))
      );
      setKopMap(
        pjkTerutang.data
          .map((x) => x.kodeObjekPajak)
          .reduce((pv, cv) => {
            pv[cv.id] = cv;
            return pv;
          }, {})
      );
      // res.data.transaksiPenjualanAktivaTetapAkun__akunid = res.data.transaksiPenjualanAktivaTetapAkun.akun.id
      // res.data.transaksiPenjualanAktivaTetapAsets.forEach(d =>
      //  {
      //   Utils.formSerialize(d)
      //   jumlahItem.push(d.aset__jumlahItem)
      //  }
      // )
      setIsEdit(true);
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  async function _getPembelianItem() {
    var res = await TransaksiPembelianService.getOne(match.params.pembelianId);

    setItemList(
      res.data.items.map((item) => ({ value: item.id, label: item.nama }))
    );
    setItemMap(
      res.data.items.reduce((pv, cv) => {
        pv[cv.id] = cv;

        return pv;
      }, {})
    );
  }

  async function _getAkunList2() {
    var res = await AkunService.get({
      size: 100000,
      "perusahaanId.equals": sipStore.companyId,
    });

    setAkunList2(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({ size: 100000 });
    setKodeObjekPajak(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getElemenTranskripList(param) {
    if (!param) param = {};
    param.size = 10000;
    param["tipeBaris.equals"] = "KATEGORI";
    var res = await ElemenTranskripService.get(param);
    return res.data.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {});
  }

  async function _handleHitung(indexAtParent) {
    var aset = { ...curAset };
    aset.masaManfaatTerpakai = parseInt(
      moment(moment(curTransaksi.tanggal, "YYYY-MM-DD")).diff(
        aset.tanggalPerolehan,
        "months",
        true
      )
    ).toString();
    // console.log(curData, 'asdasd');
    var res = await service.http.post("/asets/penyusutan-aset", aset);

    aset.nilaiPenyusutanFiskal = res.data.akumulasiPenyusutan;
    aset.nilaiBuku = res.data.sisaHargaPerolehan;

    curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].akumPenyusutan =
      (aset.nilaiPenyusutanFiskal / aset.jumlahItem) *
      parseInt(curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].qty);
    curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].nilaiSisa =
      (aset.nilaiBuku / aset.jumlahItem) *
      parseInt(curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].qty);

    FormWrapper.reload("penjualan-aktiva-tetap", curData);

    iziToast.info({ message: "Aset telah di hitung" });
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={
        "/onpremise/transaksi-pembelian/" + match.params.pembelianId + "/akun"
      }
      baseId="penjualan-aktiva-tetap"
      title="Transaksi Pembelian Akun"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if (key == "pembelianItem__id") {
          newObj.pembelianItem = itemMap[value];
          newObj = Utils.formSerialize(newObj);

          newObj.dpp = newObj.pembelianItem__dpp

          // get kop
          var pjkTerutang = await TransaksiPajakTerutangService.get({
            size: 100000,
            "itemId.equals": value,
          });
          setPajakTerutangMap(
            pjkTerutang.data.reduce((pv, cv) => {
              pv[cv.kodeObjekPajak.id] = cv;
              return pv;
            }, {})
          );
          setKodeObjekPajak(
            pjkTerutang.data.map((x) => ({
              value: x.kodeObjekPajak.id,
              label: x.kodeObjekPajak.nama,
            }))
          );
          setKopMap(
            pjkTerutang.data
              .map((x) => x.kodeObjekPajak)
              .reduce((pv, cv) => {
                pv[cv.id] = cv;
                return pv;
              }, {})
          );
        }

        if (key == "kop__id") {
          console.log('newObj.pembelianItem__pph ', pjkTerutangMap[value])
          newObj.nilai = pjkTerutangMap[value] && pjkTerutangMap[value].pph > 0
            ? pjkTerutangMap[value].pph
            : 0;
          newObj.nilai = pjkTerutangMap[value] && pjkTerutangMap[value].ppn > 0
            ? pjkTerutangMap[value].ppn
            : newObj.nilai;
        }

        setCurData(newObj);
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "# DETAIL BARANG / JASA",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Barang / JASA",
          key: "pembelianItem__id",
          type: "text",
          width: "100%",
          required: true,
          validation: "required",
          options: itemList,
        },
        {
          inputType: inputTypes.SELECT,
          label: "KOP",
          key: "kop__id",
          type: "text",
          width: "100%",
          // required: true,
          options: kodeObjekPajakList,
          // validation: "required",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "DPP",
          key: "dpp",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          style: { marginRight: "2%" },
          validation: "required|numeric",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "PPh/PPN",
          key: "nilai",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          // disabled: true,
          validation: "required|numeric",
        },

        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Daftar Akun",
          key: "details",
          hintMessage: "",
          layout: "table",
          style: { width: 1500 },
          defaultData: {
            posisi: "D",
            keterangan: "-",
            dpp: "0",
            pph: "0",
          },
          definitions: [
            // {
            //   inputType: inputTypes.DIVIDER,
            //   label: '# Detail Akun'
            // },
            {
              inputType: inputTypes.SELECT,
              label: "Akun",
              key: "akun__id",
              type: "text",
              required: true,
              validation: "required",
              options: akunList2,
              style: { width: 500 },
            },
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: "Posisi Akun",
              key: "posisi",
              theme: "filled",
              validation: "required",
              style: {width: 150},
              options: [
                { value: "D", label: "Debit" },
                { value: "K", label: "Kredit" },
              ],
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "DPP",
              key: "dpp",
              // theme: 'filled',
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              // width: '49%',
              validation: "required|numeric",
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: "PPh/PPN",
              key: "nilai",
              // theme: 'filled',
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              // width: '49%',
              validation: "required|numeric",
            },
          ],
        },

        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'TransaksiPenjualan AktivaTetap',
        //   key: 'transaksiPenjualanAktivaTetapAset__transaksiPenjualanAktivaTetapId',
        //   type: 'text',
        //   width: '49%',
        //   required: true,
        //   validation: 'required',
        //   options: akunList,
        //   style: {marginRight: '2%'}
        // },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        var error = [];
        try {
          data.pembelianId = match.params.pembelianId;
          data = Utils.formNormalize(data);
          data.details.forEach((d) => {
            Utils.formNormalize(d);
          });

          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push(
            "/onpremise/transaksi-pembelian/" +
              match.params.pembelianId +
              "/akun"
          );
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject(
  "envStore",
  "sipStore"
)(observer(TransaksiPembelianAkunForm));
