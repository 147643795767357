import React, { useEffect, useState } from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Role.service'
import Utils from '../../../libs/custom/Utils'
import {
  Card,
  CardContent,
  CardTitle,
  CardSubtitle,
  CardHeader,
  TableContainer,
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Caption,
  TableCheckbox,
  Checkbox
} from 'react-md'
import RoleService from './Role.service'
import _ from 'lodash'
import PerusahaanService from '../Perusahaan/Perusahaan.service'
import MemberService from '../../master/Member/Member.service'

const RoleForm = ({ envStore, match, history }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({ loading: true, content: {} })
  const [methodList, setMethodList] = useState([])
  const [permission, setPermission] = useState([])
  const [perusahaan, setPerusahaan] = useState([])

  var setPermissionTable = async (editData) => {
    var permissions = await RoleService.getPermission()
    if(editData && editData.permissions && editData.permissions.length > 0){
      var editPermission = []
      editData.permissions.map((d)=> {
        d.permission.allow = d.allow
        d.permission.rolePermissionId = d.id
        editPermission.push(d.permission)
      })
      // editPermission.map((d)=> {
        
      // })
      permissions.data.map((d2)=> {
        editPermission.map((d)=> {
          if(d2.id == d.id){
            d2.edit = d
          }
        })
      })
    }
    var moduleList = []
    var methodList = []
    if (permissions && permissions.data && permissions.data.length > 0) {
      permissions.data.map((permit) => {
        moduleList.push(permit.module.nama)
        methodList.push(permit.nama)
      })
    }
    var moduleListFinal = _.uniqBy(moduleList)
    var methodListFinal = _.unionBy(methodList)
    setMethodList(methodListFinal)
    var permissionTemplate = []
    moduleListFinal.map((moduleName) => {
      var moduleObj = {
        module: moduleName,
        permission: []
      }
      var permits = []
      permissions.data.map((d) => {
        if (d.module.nama == moduleName) {
          permits.push(d)
        }
      })
      permits = _.uniqBy(permits, 'nama')
      moduleObj.permission = permits
      permissionTemplate.push(moduleObj)
      // console.log(moduleObj)
    })
    permissionTemplate.map((d) => {
      var newPermit = []
      methodList.map((method) => {
        var filtered = permissions.data.filter((d2) => {
          return (d2.module.nama == d.module && d2.nama == method)
        })
        if (filtered.length > 0) {
          if(filtered[0].edit && filtered[0].edit.allow){
            filtered[0].checked = true
          } else {
            filtered[0].checked = false
          }
          newPermit.push(filtered[0])
        } else {
          newPermit.push({
            id: 'nodb',
            nama: method,
            disabled: true,
          })
        }
      })
      newPermit = _.unionBy(newPermit, 'nama')
      // console.log(newPermit)
      d.permission = newPermit
    })
    setPermission(permissionTemplate)
    var counter = 0
    if(permissionTemplate && permissionTemplate.length > 0){
      permissionTemplate.map((d, index)=> {
        if(d.permission && d.permission.length > 0){
          var totalNoDb = d.permission.filter((d)=> {
            return d.id == 'nodb'
          })
          var totalYDb = d.permission.filter((d)=> {
            return d.id != 'nodb'
          })
          var totalYCheck = d.permission.filter((d)=> {
            return d.id != 'nodb' && d.checked == true
          })
          if(totalYDb.length == totalYCheck.length){
            counter = counter + 1
            try {
              var checkParent = document.getElementById(`check-${index}`)
              checkParent.checked = true
            } catch(e){}
          }
        }
      })
      try {
        if(counter == permissionTemplate.length){
          var checkAll = document.getElementById(`checkAll`)
          checkAll.checked = true
        }
      } catch(e){}
    }
  }

  useEffect(async () => {
    try {
      var query = {
        size: 10000
      }
      var perusahaans = await MemberService.getPerusahaan(query);
      var options = []
      perusahaans.data.map((d)=> {
        options.push({
          label: d.nama,
          value: d.id
        })
      })
      setPerusahaan(options)
    } catch(e){}
    if (match.params.id == "baru") {
      setData({ loading: false, content: {} })
      try {
        setPermissionTable()
      } catch (e) { }
    } else {
      const res = await service.getOne(match.params.id)
      setData({ loading: false, content: res.data })
      try {
        setPermissionTable(res.data)
      } catch (e) { }
    }
  }, ['permission'])

  var checkItem = async (moduleIndex, permissionIndex, e) => {
    try {
      permission[moduleIndex].permission[permissionIndex].checked = e.target.checked
      setPermission(permission)
    } catch (e) { }
  }

  var checkItemRow = (moduleIndex, e) => {
    try {
      permission[moduleIndex].permission.map((d) => {
        if (!d.disabled) {
          d.checked = e.target.checked
        }
      })
      setPermission(permission)
    } catch (e) { }
    try {
      permission[moduleIndex].permission.map((d, index) => {
        if (!d.disabled) {
          var getCheckbox = document.getElementById(`check-${moduleIndex}-${index}`)
          getCheckbox.checked = e.target.checked
        }
      })
    } catch(e){}
  }

  var checkAllItem = (e) => {
    try {
      permission.map((d) => {
        d.permission.map((d2) => {
          if (!d2.disabled) {
            d2.checked = e.target.checked
          }
        })
      })
      setPermission(permission)
    } catch (e) { }
    try {
      permission.map((d, index) => {
        var checkParent = document.getElementById(`check-${index}`)
        checkParent.checked = e.target.checked
        d.permission.map((d2, d2Index) => {
          if (!d2.disabled) {
            var getCheckbox = document.getElementById(`check-${index}-${d2Index}`)
            getCheckbox.checked = e.target.checked
          }
        })
      })
    } catch(e){}
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      style={{
        maxWidth: '100%'
      }}
      backTo={`/onpremise/Role`}
      baseId="mod-form-sample"
      title={t.translate('modules.Role.formTitle')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          width: '100%',
          validation: 'required',
          options: perusahaan
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '100%',
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'description',
          type: 'text',
          width: '100%',
          validation: 'required'
        },
        {
          show: true,
          render: () => (
            <Card style={{ width: '100%' }}>
              <CardHeader>
                <CardSubtitle>Permissions</CardSubtitle>
              </CardHeader>
              <CardContent>
                <Table style={{ width: '100%' }}>
                  <TableHeader>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>Module</TableCell>
                      <TableCell><Checkbox id="checkAll" onChange={(e) => checkAllItem(e)} /></TableCell>
                      {(methodList && methodList.length > 0) &&
                        <>
                          {methodList.map((d, index) => {
                            return <TableCell key={index}>{d}</TableCell>
                          })}
                        </>
                      }
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {(permission && permission.length > 0) &&
                      <>
                        {permission.map((d, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{d.module}</TableCell>
                              <TableCell><Checkbox id={`check-${index}`} onChange={(e) => checkItemRow(index, e)} /></TableCell>
                              {(d.permission && d.permission.length > 0) &&
                                <>
                                  {d.permission.map((d2, d2Index) => {
                                    return <TableCell key={d2Index}><Checkbox id={`check-${index}-${d2Index}`} defaultChecked={d2.checked} disabled={d2.disabled} onChange={(e) => checkItem(index, d2Index, e)} /></TableCell>
                                  })}
                                </>
                              }
                            </TableRow>
                          )
                        })}
                      </>
                    }
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )
        }
      ]}
      onSubmit={async (values, callback) => {
        // values.perusahaanId = "97e7e8b1-091d-408e-949d-2d0cb5e8fe23"
        values.permissions = []
        if (permission && permission.length > 0) {
          permission.map((d) => {
            if (d.permission && d.permission.length > 0) {
              d.permission.map((d2) => {
                if (!d2.disabled) {
                  if (d2.checked == true) {
                    if(d2.edit){
                      values.permissions.push({
                        allow: d2.checked,
                        permission: d2,
                        id: d2.edit.rolePermissionId,
                        roleId: data.content.id
                      })
                    } else {
                      values.permissions.push({
                        allow: d2.checked,
                        permission: d2
                      })
                    }
                  }
                }
              })
            }
          })
        }
        var res = {}
        try {
          if (match.params.id == 'baru') {
            res = await service.post(values)
          } else {
            res = await service.put(values)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/role')
        } catch (e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore', 'temporaryStore')(observer(RoleForm))
