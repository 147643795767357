import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TransaksiPenjualanAktivaTetap.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import AsetService from '../Aset/Aset.service'
import TransaksiService from './Transaksi.service'
import ElemenTranskripService from '../../adminpajakku/ElemenTranskrip/ElemenTranskrip.service'
import { AKUN_JENIS_MAP, ID_CONS } from '../../../clientConstant'
import { Button } from "@react-md/button";
import iziToast from 'izitoast'
import moment from 'moment'

const TransaksiPenjualanAktivaTetapForm = ({envStore, match, history, sipStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [akunList, setAkun] = useState([])
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([])
  const [asetList, setAsetList] = useState([])
  const [asetMap, setAsepMap] = useState({})
  const [journalMap, setJournalMap] = useState({})
  const [metodePenyusutanKomList, setMetodePenyusutanKomList] = useState([])
  const [metodePenyusutanMap, setMetodePenyusutanMap] = useState({})
  const [curAset, setCurAset] = useState({})
  const [curTransaksi, setCurTransaksi] = useState({})
  const [curData, setCurData] = useState({})
  const [jumlahItem, setJumlahItem] = useState([])

  const [akunList2, setAkunList2] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      // await _getAkunList2()
      await _getAkun()
      // await _getMetodePenyusutanKomList()
      // _getKodeObjekPajak()x
      if(match.params.id == 'baru') {
        setData({loading: false, content: {dipungut: false, dpp:'0', pph: '0', transaksiPenjualanAktivaTetapAkun__nilai: '0', transaksiAktivaTetapPengecualians: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      await _getAset(res.data.jurnal.akun.id)
      res.data = Utils.formSerialize(res.data)
      // res.data.transaksiPenjualanAktivaTetapAkun__akunid = res.data.transaksiPenjualanAktivaTetapAkun.akun.id
      res.data.transaksiPenjualanAktivaTetapAsets.forEach(d =>
       {
        Utils.formSerialize(d)
        jumlahItem.push(d.aset__jumlahItem)
       }
      )
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId)
    var eleMap = await _getElemenTranskripList();
    setCurTransaksi(res.data)
    setJournalMap(res.data.transaksiJurnals.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setAkun(res.data.transaksiJurnals.filter((x) => {
      return x.akun.akunJenisId == AKUN_JENIS_MAP["Akun Aktiva Tetap"].id
    }).map(x => ({value: x.id, label: x.akun.nama})))
    setAkunList2(res.data.transaksiJurnals.map(x => ({value: x.akun.id, label: x.akun.nama})))
  }

  async function _getAkunList2() {
    var res = await AkunService.get({size: 100000, "perusahaanId.equals": sipStore.companyId})

    setAkunList2(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({size: 100000})
    setKodeObjekPajak(res.data.map(x => ({value: x.id, label: x.nama})))
  }


  async function _getAset(akunAktivaId) {
    var res = await AsetService.get({size: 100000, 'perusahaanId.equals': sipStore.companyId, 'akunAktivaId.equals': akunAktivaId})
    setAsetList(res.data.map(x => ({value: x.id, label: x.nama})))
    setAsepMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getElemenTranskripList(param) {
    if (!param) param = {};
    param.size = 10000;
    param["tipeBaris.equals"] = "KATEGORI";
    var res = await ElemenTranskripService.get(param);
    return res.data.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {});
  }


  async function _handleHitung(indexAtParent) {
    var aset = {...curAset}
    aset.masaManfaatTerpakai = (parseInt(moment(moment(curTransaksi.tanggal, "YYYY-MM-DD")).diff(aset.tanggalPerolehan, 'months', true))).toString()
    // console.log(curData, 'asdasd');
    var res = await service.http.post('/asets/penyusutan-aset', aset);

    aset.nilaiPenyusutanFiskal = res.data.akumulasiPenyusutan
    aset.nilaiBuku = res.data.sisaHargaPerolehan

    curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].akumPenyusutan = (aset.nilaiPenyusutanFiskal/aset.jumlahItem)*parseInt(curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].qty)
    curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].nilaiSisa = (aset.nilaiBuku/aset.jumlahItem)*parseInt(curData.transaksiPenjualanAktivaTetapAsets[indexAtParent].qty)

    FormWrapper.reload('penjualan-aktiva-tetap', curData)

    iziToast.info({message: 'Aset telah di hitung'})
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/transaksi/'+match.params.transaksiId+'/transaksi-penjualan-aktiva-tetap'}
      baseId="penjualan-aktiva-tetap"
      title='Transaksi Penjualan Aktiva Tetap'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'jurnal__id') {
          newObj.jurnal__nilai = journalMap[value].nilai
          await _getAset(journalMap[value].akun.id)
        }
        if(key == 'aset__id') {
          if(asetMap[value]) {
            var aset = asetMap[newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].aset__id];
            setCurAset(aset)
            newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].hargaPerolehan = asetMap[value].hargaSatuanPerolehan
            newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].qty = asetMap[value].jumlahItem
            newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].akumPenyusutan = asetMap[value].nilaiPenyusutanFiskal
            newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].nilaiSisa = asetMap[value].nilaiBuku
            jumlahItem.push(asetMap[value].jumlahItem)
          }
        }
        if(key == 'qty'){
          // var aset = asetMap[newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].aset__id];
          // if(aset) {
          //   newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].akumPenyusutan = (aset.nilaiPenyusutanFiskal/aset.jumlahItem)*parseInt(value)
          //   newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].nilaiSisa = (aset.nilaiBuku/aset.jumlahItem)*parseInt(value)
          // }
        }

        if(key == 'hargaJual') {
          newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].labaPenjualan = parseFloat(value)-parseFloat(newObj.transaksiPenjualanAktivaTetapAsets[indexAtParent].nilaiSisa)
        }

        setCurData(newObj)
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: '# AKUN TRANSAKSI'
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Akun',
          key: 'jurnal__id',
          type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          options: akunList,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Nilai',
          key: 'jurnal__nilai',
          // theme: 'filled',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          width: '49%',
          disabled: true,
          validation: 'required|numeric'
        },


        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Daftar Aset",
          key: "transaksiPenjualanAktivaTetapAsets",
          hintMessage: "",
          // layout: "table",
          style: { width: 1500 },
          defaultData: {
            tarifPajak: "0",
            keterangan: "-",
            tarifTanpaNpwp: "0",
            akunLabaPenjualan: "0"
          },
          definitions: [{
            inputType: inputTypes.SELECT,
            label: 'Pilih Aset',
            key: 'aset__id',
            type: 'text',

            required: true,
            validation: 'required',
            options:asetList,
          },
          {
            inputType: inputTypes.DIVIDER,
            label: '# Detail Aktiva Tetap'
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: 'Harga Perolehan',
            key: 'hargaPerolehan',
            // theme: 'filled',
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            width: '49%',
            validation: 'required|numeric',
            style: {marginRight: '2%'}
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: 'QTY',
            key: 'qty',
            // theme: 'filled',
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            width: '49%',
            validation: 'required|numeric'
          },
          {
            render: (r, d, i)=> {
              console.log(r, d, i)
              return <div className="mpk-custom-input input mpk-margin-N margin-bottom flex-none" onClick={() => _handleHitung(i)} style={{width: '100%'}}>
                <Button  theme="primary" themeType="outline">Hitung</Button>
              </div>

            }
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: 'Akumulasi Penyusutan',
            key: 'akumPenyusutan',
            // theme: 'filled',
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            width: '49%',
            validation: 'required|numeric',
            style: {marginRight: '2%'},
            disabled: true,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: 'Nilai Sisa',
            key: 'nilaiSisa',
            // theme: 'filled',
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            width: '49%',
            validation: 'required|numeric',
            disabled: true,
          },
          {
            inputType: inputTypes.DIVIDER,
            label: '# Perhitungan Laba Rugi Aktiva Tetap'
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: 'Harga Jual',
            key: 'hargaJual',
            // theme: 'filled',
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            width: '49%',
            validation: 'required|numeric',
            style: {marginRight: '2%'}
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: 'Laba Penjualan',
            key: 'labaPenjualan',
            // theme: 'filled',
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            width: '49%',
            validation: 'required|numeric',
            disabled: true
            // style: {marginRight: '2%'}
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            label: 'Akumulasi Laba Penjualan',
            key: 'akunLabaPenjualan',
            // theme: 'filled',
            thousandSeparator: '.',
            decimalSeparator: ',',
            isNumericString: true,
            width: '100%',
            validation: 'required|numeric',
            // style: {marginRight: '2%'}
          },
          {
            inputType: inputTypes.SELECT,
            label: 'Akun Laba/Rugi penjualan aktiva tetap',
            key: 'akun__id',
            // theme: 'filled',
            type: 'text',
            width: '100%',
            required: true,
            validation: 'required',
            options:akunList2,
          },
        ]
        },


        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'TransaksiPenjualan AktivaTetap',
        //   key: 'transaksiPenjualanAktivaTetapAset__transaksiPenjualanAktivaTetapId',
        //   type: 'text',
        //   width: '49%',
        //   required: true,
        //   validation: 'required',
        //   options: akunList,
        //   style: {marginRight: '2%'}
        // },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        var error = []
        try {
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          data.transaksiPenjualanAktivaTetapAsets.forEach((d)=> {
            Utils.formNormalize(d)
          })

          data.transaksiPenjualanAktivaTetapAsets.map((d, index)=>{
            if(d.qty > jumlahItem[index]){
              error.push(index)
            }
          })
          if (error.length > 0) {
            iziToast.warning({
              message: "Qty aset yang dijual tidak dapat melebihi Qty aset yang tersedia"
            })
            callback("", false, false)
            return
          }

          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/transaksi/'+match.params.transaksiId+'/transaksi-aktiva-tetap')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore', 'sipStore')(observer(TransaksiPenjualanAktivaTetapForm))
