import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './TransaksiPerubahanModal.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import TransaksiService from './Transaksi.service'
import iziToast from 'izitoast'
import { AKUN_JENIS_MAP } from '../../../clientConstant'


const TransaksiPerubahanModalForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [akunList, setAkun] = useState([])
  const [journalMap, setJournalMap] = useState({})
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      _getAkun()
      _getKodeObjekPajak()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {dipungut: false, dpp:'0', pph: '0', transaksiBebanEntertainmentAkun__nilai: '0', transaksiBebanEntertainmentPengecualians: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data = Utils.formSerialize(res.data)
      // res.data.transaksiPerubahanModalAkun__akunid = res.data.transaksiPerubahanModalAkun.akun.id
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId)
    setJournalMap(res.data.transaksiJurnals.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setAkun(res.data.transaksiJurnals)
  }

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId)
    setJournalMap(res.data.transaksiJurnals.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
    setAkun(res.data.transaksiJurnals.map(x => ({value: x.id, label: x.akun.nama})))

    return res
  }

  async function _getKodeObjekPajak() {
    var res = await KodeObjekPajakService.get({size: 100000})
    setKodeObjekPajak(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/transaksi/'+match.params.transaksiId+'/perubahan-modal'}
      baseId="mod-form-sample"
      title='Perubahan Modal'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'jurnal__id') {
          newObj.nilai = journalMap[value].nilai
        }
      }}
      definitions={[
        // =====
        // {
        //   inputType: inputTypes.DIVIDER,
        //   label: '# AKUN TRANSAKSI'
        // },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Akun',
        //   key: 'jurnal__id',
        //   type: 'text',
        //   width: '49%',
        //   required: true,
        //   validation: 'required',
        //   options: akunList,
        //   style: {marginRight: '2%'}
        // },
        // {
        //   inputType: inputTypes.INPUT_MASK_NUMBER,
        //   label: 'Nilai',
        //   key: 'nilai',
        //   // theme: 'filled',
        //   thousandSeparator: '.',
        //   decimalSeparator: ',',
        //   isNumericString: true,
        //   width: '49%',
        //   // disabled: true,
        //   validation: 'required|numeric'
        // },
        {
          inputType: inputTypes.DIVIDER,
          label: '# Detail Perubahan Modal'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP',
          key: 'npwp',
          type: 'text',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Alamat',
          key: 'alamat',
          type: 'text',
          width: '100%',
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Jumlah Awal Modal Disetor (Rp)',
          key: 'nilaiAwal',
          width: '100%',
          // style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          // disabled: true
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Perubahan (Rp)',
          key: 'nilai',
          width: '100%',
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: 'Jumlah Akhir Modal Disetor (Rp)',
          key: 'nilaiAkhir',
          width: '100%',
          // style: {marginRight: '2%'},
          thousandSeparator: '.',
          decimalSeparator: ',',
          isNumericString: true,
          // disabled: true
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.transaksi = {
            id: match.params.transaksiId,
          }
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          // data.transaksiPerubahanModalAkun.akun = {
          //   id: data.transaksiPerubahanModalAkun__akunid
          // }

          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/transaksi/'+match.params.transaksiId+'/perubahan-modal')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(TransaksiPerubahanModalForm))
