import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PengecualianPajak.service'
import kopService from '../KodeObjekPajak/KodeObjekPajak.service'
import Utils from '../../../libs/custom/Utils'

const PengecualianPajakForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [kopList, setKopList] = useState([])
  const [isEdit, setIsEdit] = useState(false)

  // initial data
  useEffect(() => {
    async function initData() {
      await _getKop()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          kops1: [], kops2: [], memilikiSuratKeterangan: false
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      setIsEdit(true)
      res.data = Utils.formSerialize(res.data)
      if(res.data) {
        res.data.kops.forEach((d)=> {
          Utils.formSerialize(d)
        })
        res.data.kops1 = res.data.kops.filter(d => d.status == 'DIKECUALIKAN')
        res.data.kops2 = res.data.kops.filter(d => d.status == 'PENGGANTI')
      }
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getKop() {
    var res = await kopService.get({size: 50000})
    setKopList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/pengecualian-pajak`}
      baseId="mod-form-sample"
      
      title={t.translate('modules.pengecualianPajak.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT,
          label: 'Kode',
          key: 'kode',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '49%',
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'Kop yang dikecualikan',
          key: 'kops1',
          layout:"table",
          style: {width: 700},
          hintMessage: '',
          defaultData: {
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Kode Objek Pajak',
              key: 'kodeObjekPajak__id',
              theme: 'filled',
              type: 'number',
              validation: 'required',
              style: {width: 600},
              options: kopList
            }
          ]
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Dikenakan pajak tertentu?',
          key: 'memilikiSuratKeterangan',
          type: 'text',
          width: '100%',
          // required: true,
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'Kop pajak tertentu',
          key: 'kops2',
          layout:"table",
          style: {width: 700},
          show: (d) => d.memilikiSuratKeterangan,
          // hintMessage: '',
          defaultData: {
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Kode Objek Pajak',
              key: 'kodeObjekPajak__id',
              theme: 'filled',
              type: 'number',
              validation: 'required',
              style: {width: 600},
              options: kopList
            }
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data = Utils.formNormalize(data)
          data.kops = data.kops1.map(x => {x.status = "DIKECUALIKAN"; x = Utils.formNormalize(x); return x}).concat(data.kops2.map(x=> {x.status = "PENGGANTI"; x = Utils.formNormalize(x); return x}))
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/pengecualian-pajak')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(PengecualianPajakForm))
