import React, { useEffect, useState } from "react";
import {
  Flex,
  Commandbar,
  DataForm,
} from "../../../libs/react-mpk/components/index";
import {
  Checkbox,
  Fieldset,
  ListboxOption,
  Select,
  useChecked,
} from "@react-md/form";
import PerusahaanListService from "../../master/Perusahaan/Perusahaan.service";
import { List, ListItem, ListSubheader } from "@react-md/list";
import { inject, observer } from "mobx-react";
import PeriodeFiskalService from "../PeriodeFiskal/PeriodeFiskal.service";
import MemberService from "../../master/Member/Member.service";
import user, { userDefaults } from "../../../libs/custom/user";
import { cloneDeep } from "lodash";
import DashboardSample from "../../DashboardSample/DashboardSample";
import {
  DashboardWrapper,
  TableWrapper,
} from "../../../libs/react-mpk/wrapper";
import KontribusiPajakDashboardView from "./KontribusiPajakDashboardView";
import PelaporanDashboardView from "./PelaporanDashboardView";
import { TabsManager, Tabs, TabPanels, TabPanel } from "@react-md/tabs";
import { Text } from "@react-md/typography";
import {
  AppBar,
  AppBarNav,
  AppBarTitle,
  AppBarAction,
} from "@react-md/app-bar";
import SummarySpt21DashboardView from "./SummarySpt21DashboardView";
import SummarySpt22DashboardView from "./SummarySpt22DashboardView";
import SummarySpt23DashboardView from "./SummarySpt23DashboardView";
import SummarySpt4a2DashboardView from "./SummarySpt4a2DashboardView";
import SummaryPpnDashboardView from "./SummaryPpnDashboardView";
import iziToast from "izitoast";
import PembelianDashboardView from "./PembelianDashboardView";
import { Avatar, Card, CardHeader, CardSubtitle, CardTitle, FontIcon } from "react-md";
import EkualisasiPajakDashboardView from "./EkualisasiPajakDashboardView";
import SkemaRemunerasiDashboardView from "./SkemaRemunerasiDashboardView";
import PihakBerelasiDashboardView from "./PihakBerelasiDashboardView";

function Dashboard({ sipStore, authStore, modalStore , history}) {
  const [perusahaanList, setPerusahaanList] = useState([]);
  const [perusahaanMap, setPerusahaanMap] = useState({});

  const [periodeList, setPeriodeList] = useState([]);
  const [periodeMap, setPeriodeMap] = useState({});

  useEffect(() => {
    async function initData() {
      var pMap = await _getPerusahaanList();
      if (localStorage.getItem("sip-company-active")) {
        var d = JSON.parse(localStorage.getItem("sip-company-active"));
        sipStore.setCompany(pMap[d.id]?pMap[d.id]:d);
      }
      if (localStorage.getItem("sip-periode-active")) {
        var d = JSON.parse(localStorage.getItem("sip-periode-active"));
        sipStore.setPeriode(d);
      }

      if (sipStore.companyId) {
        await _getPeriodeList(sipStore.companyId);
      }
    }
    initData();
  }, []);

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get("/my-perusahaans");
    setPerusahaanList(res.data.map((x) => ({ value: x.id, label: x.nama })));
    var map = res.data.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {})
    setPerusahaanMap(
      map
    );

    return map;
  }

  async function _getPeriodeList(perusahaanId) {
    var res = await PeriodeFiskalService.get({
      "perusahaanId.equals": perusahaanId,
      size: 100000,
    });
    setPeriodeList(res.data.map((x) => ({ value: x.id, label: x.tahun })));
    setPeriodeMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
  }

  async function _setRole(perusahaanUser) {
    console.log(perusahaanUser, "asdasd");
    var userClone = cloneDeep(userDefaults);
    if (
      perusahaanUser &&
      perusahaanUser.role &&
      perusahaanUser.role.permissions
    ) {
      var roleMap = perusahaanUser.role.permissions.reduce((pv, cv) => {
        if (!pv[cv.permission.module.nama]) pv[cv.permission.module.nama] = {};
        pv[cv.permission.module.nama][cv.permission.nama] = cv.allow;

        return pv;
      }, {});

      var menu = [];
      menu = menu.concat(userClone.menu.filter((x) => x.global));

      if (authStore.user.authorities.find((x) => x == "ROLE_ADMIN")) {
        menu = menu.concat(userClone.menu.filter((x) => x.adminPajak));
      }

      userClone.menu
        .filter((x) => !(x.adminPajak || x.global))
        .forEach((x) => {
          if (x.children) {
            x.children = x.children.filter((y) => {
              return (
                roleMap[y.permissionName] && roleMap[y.permissionName].View
              );
            });
            if (x.children.length > 0) menu.push(x);
          } else {
            menu.push(x);
          }
        });

      authStore.setProfile({
        ...authStore.user,
        roleMap: roleMap,
        menu,
      });
    } else {
      var menu = [];
      menu = menu.concat(userClone.menu.filter((x) => x.global));

      if (authStore.user.authorities.find((x) => x == "ROLE_ADMIN")) {
        menu = menu.concat(userClone.menu.filter((x) => x.adminPajak));
      }

      authStore.setProfile({
        ...authStore.user,
        menu,
      });
    }
  }

  return (
    <Flex
      className={`mpk-flex align-start direction-column justify-start mpk-full full-width full-height mpk-position position-relative flex`}
      direction={Flex.properties.direction.COLUMN}
      fit
    >
      <div className="mpk-flex align-start direction-row justify-start mpk-full full-width flexscrollable">
        <div className="mpk-flex align-start direction-column justify-start mpk-form-wrapper mpk-full full-width flex">
          <Commandbar
            actions={[
              new TableWrapper.action(
                "Sync Data Dashboard",
                "mdi mdi-import",
                () => {
                  modalStore.showConfirm({
                    title: "Sinkronisasi data dashboard",
                    children: (
                      <DataForm
                        baseId={"transaksi-import"}
                        // asDialog={true}
                        defaultData={{}}
                        hideActionButton={true}
                        definitions={[
                          {
                            inputType: DataForm.inputTypes.SELECT,
                            label: "Masa",
                            key: "masa",
                            type: "text",
                            style: { marginRight: "2%" },
                            width: "49%",
                            required: true,
                            validation: "required",
                            options: [
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" },
                              { value: "4", label: "4" },
                              { value: "5", label: "5" },
                              { value: "6", label: "6" },
                              { value: "7", label: "7" },
                              { value: "8", label: "8" },
                              { value: "9", label: "9" },
                              { value: "10", label: "10" },
                              { value: "11", label: "11" },
                              { value: "12", label: "12" },
                            ],
                          },
                          {
                            inputType: DataForm.inputTypes.INPUT,
                            label: "Tahun",
                            key: "tahun",
                            theme: "filled",
                            width: "49%",
                          },
                        ]}
                        visible={true}
                        submitLabel=""
                        onSubmit={async (d, callback) => {
                          await PeriodeFiskalService.http.get(
                            `/dashboard/get-summary/${d.masa}/${d.tahun}`
                          );

                          iziToast.success({
                            title: "Berhasil",
                            message:
                              "Mohon cek log import untuk memantau proses sinkronisasi",
                          });
                        }}
                        onRequestClose={() => {}}
                      />
                    ),
                    onSubmit: async (callback) => {
                      var f = document.getElementById(
                        `transaksi-import-form-trigger`
                      );
                      if (f) f.click();

                      // iziToast.success({title: 'Berhasil', message: 'Data sedang di import. Mohon pantau progress import di menu log import.'})
                      callback();
                    },
                  });
                },
                true
              ),
            ]}
            title={"Dashboard"}
            // rightCorner={}
          />

          <br />

          <Card raiseable
            className="mpk-form-wrapper mpk-paper mpk-padding-N padding-all mpk-animation slide-in"
            style={{ width: "100%", maxWidth: 1200, margin: "0px auto" }}
          >
            <Select
              label="Pilih Perusahaan"
              style={{ width: "100%" }}
              id="custom-select-1"
              options={perusahaanList}
              value={sipStore.companyId}
              onChange={async (d) => {
                localStorage.setItem(
                  "sip-company-active",
                  JSON.stringify(perusahaanMap[d])
                );
                sipStore.setCompany(perusahaanMap[d]);
                var query = {
                  size: 10000,
                  "perusahaanId.equals": perusahaanMap[d].id,
                  "userId.equals": authStore.user.id,
                };
                var roles = await MemberService.getMember(query);
                _setRole(roles.data[0]);
                sipStore.setPeriode({});
                _getPeriodeList(d);

                var settings = await PerusahaanListService.http.get(
                  "/perusahaan-settings",
                  {
                    ["perusahaanId.equals"]: perusahaanMap[d].id,
                    size: 10000,
                  }
                );

                console.log(settings.data, "asd");

                var setting = settings.data.reduce((pv, cv) => {
                  pv[cv.kode] = cv.value;
                  return pv;
                }, {});

                localStorage.setItem("sip_setting", JSON.stringify(setting));

                sipStore.setSetting(setting);
              }}
            />
            <br />
            <Select
              label="Pilih Periode"
              style={{ width: "100%" }}
              id="custom-select-2"
              options={periodeList}
              value={sipStore.periodeId}
              onChange={(d) => {
                localStorage.setItem(
                  "sip-periode-active",
                  JSON.stringify(periodeMap[d])
                );
                sipStore.setPeriode(periodeMap[d]);

                window.location.reload();
              }}
            />
          </Card>
          <br />
          <div
            // className="mpk-form-wrapper mpk-paper mpk-padding-N padding-all mpk-animation slide-in"
            style={{ width: "100%", maxWidth: 1200, margin: "0px auto" }}
          >
            <KontribusiPajakDashboardView />
            {/* <br /> */}
            <PelaporanDashboardView />

            <br />

            <br />

            <Card
              raiseable
              className="mpk-form-wrapper mpk-paper  padding-all mpk-animation slide-in"
              style={{
                width: "100%",
                maxWidth: 1200,
                margin: "0px auto",
              }}
            >
              <CardHeader
                beforeChildren={
                  <Avatar>
                    <FontIcon iconClassName="mdi mdi-calendar-text" />
                  </Avatar>
                }
              >
                <CardTitle>SUMMARY SPT</CardTitle>
                {/* <CardSubtitle>Daftar pembelian berdasarkan COA</CardSubtitle> */}
              </CardHeader>
              <TabsManager
                tabs={["PPH 21", "PPH 22", "PPH 23", "PPH 4(2)", "PPN"]}
                tabsId="basic-usage-tabs"
              >
                <Tabs />
                <TabPanels persistent={true}>
                  <TabPanel>
                    <SummarySpt21DashboardView />
                  </TabPanel>
                  <TabPanel>
                    <SummarySpt22DashboardView />
                  </TabPanel>
                  <TabPanel>
                    <SummarySpt23DashboardView />
                  </TabPanel>
                  <TabPanel>
                    <SummarySpt4a2DashboardView />
                  </TabPanel>
                  <TabPanel>
                    <SummaryPpnDashboardView />
                  </TabPanel>
                  <TabPanel>
                    <SummarySpt21DashboardView />
                  </TabPanel>
                </TabPanels>
              </TabsManager>
            </Card>
            <br />
            <br />
            <PembelianDashboardView history={history} />
            <br />
            <br />

            <SkemaRemunerasiDashboardView history={history} />
            <br />
            <br />
            <PihakBerelasiDashboardView history={history} />
            <br />
            <br />
          </div>
        </div>
      </div>
    </Flex>
  );
}

export default inject(
  "sipStore",
  "authStore",
  "modalStore"
)(observer(Dashboard));
