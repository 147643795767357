import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Entitas.service'
import PerusahaanListService from '../Perusahaan/Perusahaan.service'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import TableSample from '../../TableSample'
import EntitasPajakTable from '../EntitasPajak/EntitasPajak.table'
import NegaraService from '../../adminpajakku/negara/Negara.service'
import izitoast from 'izitoast'
import Utils from '../../../libs/custom/Utils'
import PenomoranBpForm from './PenomoranBp.form'

const EntitasForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [perusahaanList, setPerusahaanList] = useState([])
  const [entitasList, setEntitasList] = useState([])
  const [entitasMap, setEntitasMap] = useState([])
  const [negaraList, setNegaraList] = useState([])
  const [negaraListFull, setNegaraListFull] = useState([])
  const [negaraMap, setNegaraMap] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [dataUp, setDataUp] = useState({})
  const [isDisabled, setIsDisabled] = useState(true)

  // initial data
  useEffect(() => {
    async function initData() {
      await _getPerusahaanList()
      await _getNegaraList()
      await _getEntitasList()

      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          perusahaanId: sipStore.companyId,
          tarifs:[],
          pics: []
        }})
        setDataUp({
          perusahaanId: sipStore.companyId,
          tarifs:[],
          pics: []
        })
        return true
      }
      const res = await service.getOne(match.params.id)
      setIsEdit(true)
      res.data = Utils.formSerialize(res.data)

      setDataUp(res.data)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')
    setPerusahaanList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getEntitasList() {
    var res = await service.get({size: 10000, 'tipeEntitas.notEquals': 'Cabang Pembantu', 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
    setEntitasMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getNegaraList() {
    var res = await NegaraService.get({size: 10000})
    setNegaraList(res.data.map(x => ({value: x.nama, label: x.nama})))
    setNegaraListFull(res.data.map(x => ({value: x.nama, label: x.nama})))
    setNegaraMap(res.data.reduce((pv, cv) => {
      pv[cv.nama] = cv
      return pv
    }, {}))
  }

  const form =  (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      className="mpk-padding-N padding-bottom"
      showCommandbar={match.params.id == 'baru' || data.content.tipeEntitas == 'Cabang Pembantu'}
      backTo={`/onpremise/entitas`}
      baseId="mod-form-sample"
      title={''}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'negara') {
          newObj.kodeNegara = negaraMap[value]?negaraMap[value].kode2:''
        }

        if(key == 'domisiliWajibPajak') {
          if(value == 'DN') {
            setNegaraList(negaraListFull)
            newObj.negara = 'Indonesia'
            newObj.kodeNegara = 'ID'
            setIsDisabled(true)
          } else {
            setNegaraList(negaraListFull.filter(x => x.label != 'Indonesia'))
            newObj.negara = ''
            newObj.kodeNegara = ''
            setIsDisabled(false)
          }
        }

        if(key == 'tipeEntitas') {
          if(value == 'HO') {
            newObj.nama = sipStore.company.nama
            newObj.alias = sipStore.company.alias
            newObj.email = sipStore.company.email
            newObj.npwp = sipStore.company.npwp
            newObj.alamat = sipStore.company.alamat
            newObj.kecamatan = sipStore.company.kecamatan
            newObj.kelurahan = sipStore.company.kelurahan
            newObj.propinsi = sipStore.company.propinsi
            newObj.Kota = sipStore.company.Kota
            newObj.telepon = sipStore.company.telepon
            newObj.kodePos = sipStore.company.kodePos
          }
        }

        if(key == 'parentId') {
          newObj.npwp = entitasMap[value]?entitasMap[value].npwp:''
        }

        setDataUp(newObj)
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Perusahaan',
          key: 'perusahaanId',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: 'required',
          options: perusahaanList,
          disabled: sipStore.companyId != null
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Tipe Entitas',
          key: 'tipeEntitas',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit,
          options: [{label: "HO", value: "HO"}, {label: "Cabang", value: "Cabang"}, {label: "Cabang Pembantu", value: "Cabang Pembantu"}]
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Wajib Pajak',
          key: 'tipeWajibPajak',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: 'required',
          options: [{value: 'Badan', label: 'Badan'}, {value: 'Orang Pribadi', label: 'Orang Pribadi'}, {value: 'Badan dan Orang Pribadi', label: 'Badan dan Orang Pribadi'}]
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Cabang Utama',
          key: 'parentId',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: [{required_if: ['tipeEntitas', true]}],
          options: entitasList,
          disabled: isEdit,
          show: (d)=> (d.tipeEntitas == "Cabang Pembantu")
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# INFORMASI GENERAL',
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Entitas',
          key: 'kodeEntitas',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          validation: 'required',
        },{
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP',
          key: 'npwp',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: dataUp.tipeEntitas == 'Cabang Pembantu' || dataUp.tipeEntitas == 'HO' || isEdit,
          validation: 'required',
        },{
          inputType: inputTypes.INPUT,
          label: 'Alias',
          key: 'alias',
          type: 'text',
          width: '49%',
          validation: 'required',
          // style: {marginRight: '2%'},
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Alamat Domisili',
          key: 'alamat',
          type: 'text',
          width: '100%',
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kecamatan',
          key: 'kecamatan',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kelurahan',
          key: 'kelurahan',
          type: 'text',
          width: '49%',
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Provinsi',
          key: 'propinsi',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kota',
          key: 'kota',
          type: 'text',
          width: '49%',
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Telepon',
          key: 'telepon',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Pos',
          key: 'kodePos',
          type: 'text',
          width: '49%',
          // required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'email',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          // required: true,
          validation: 'email'
        },{
          inputType: inputTypes.SELECT,
          label: 'Domisili Wajib Pajak',
          key: 'domisiliWajibPajak',
          type: 'text',
          width: '49%',
          validation: 'required',
          style: {marginRight: '2%'},
          options: [{value: "DN", label: "DN"}, {value: "LN", label: "LN"}]
        },{
          inputType: inputTypes.AUTOCOMPLETE,
          label: 'Negara',
          key: 'negara',
          type: 'text',
          width: '49%',
          data: negaraList,
          validation: 'required',
          // disabled: (d) => d.domisiliWajibPajak == 'DN',
          disabled: isDisabled
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Negara',
          key: 'kodeNegara',
          type: 'text',
          width: '100%',
          style: {marginRight: '2%'},
          disabled: true
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# SUSUNAN KEPENGURUSAN DAN KOMISARIS',
          key: 'pics',
          show: (d)=> d.tipeEntitas != 'Cabang Pembantu',
          // hintMessage: 'Daftar Tarif',
          defaultData: {
            tarifPajak: 0,
            keterangan: '-'
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: 'Nama',
              key: 'nama',
              theme: 'filled',
              width: '49%',
              style: {marginRight: '2%'},
              validation: 'required',
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              mask: '##.###.###.#-###.###',
              maskChar: '_',
              maskValue: 'string',
              label: 'NPWP',
              key: 'npwp',
              theme: 'filled',
              width: '49%',
              validation: 'required',
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: 'Jabatan',
              key: 'jabatan',
              theme: 'filled',
              // required: true
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: 'Alamat',
              key: 'alamat',
              theme: 'filled'
            },
            {
              inputType: FormWrapper.inputTypes.CHECKBOX,
              label: 'Pemimpin Perusahaan',
              key: 'pemimpinPerusahaan',
              theme: 'filled'
            },
            {
              inputType: FormWrapper.inputTypes.CHECKBOX,
              label: 'Pejabat Spt Tahunan',
              key: 'pejabatSptTahunan',
              theme: 'filled'
            },
            {
              inputType: FormWrapper.inputTypes.CHECKBOX,
              label: 'Kuasa Spt Tahunan',
              key: 'kuasaSptTahunan',
              theme: 'filled'
            },
            {
              inputType: FormWrapper.inputTypes.CHECKBOX,
              label: 'Penandatangan Bukti Potong',
              key: 'penandatanganBp',
              theme: 'filled'
            },
            {
              inputType: FormWrapper.inputTypes.CHECKBOX,
              label: 'Penandatangan Faktur Pajak',
              key: 'penandatanganFaktur',
              theme: 'filled'
            }
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}

        try {
          var pemimpinPerusahaan = false
          var pejabatSptTahunan = false
          // var kuasaSptTahunan = false
          var penandatanganBp = false
          var penandatanganFaktur = false

          var pemimpinPerusahaanNum = 0;
          var pejabatSptTahunanNum = 0;
          var penandatanganBpNum = 0;
          var penandatanganFakturNum = 0;

          data.pics.forEach((d)=> {
            // if(d.kuasaSptTahunan) kuasaSptTahunan = true
            if(d.pemimpinPerusahaan) {
              pemimpinPerusahaan = true
              pemimpinPerusahaanNum = pemimpinPerusahaanNum+1
            }
            if(d.pejabatSptTahunan) {
              pejabatSptTahunan = true
              pejabatSptTahunanNum = pejabatSptTahunanNum+1
            }
            if(d.penandatanganBp) {
              penandatanganBp = true
              penandatanganBpNum = penandatanganBpNum+1
            }
            if(d.penandatanganFaktur) {
              penandatanganFaktur = true
              penandatanganFakturNum = penandatanganFakturNum+1
            }
          })

          if(data.tipeEntitas === 'HO' && !(pemimpinPerusahaan && pejabatSptTahunan)) {
            // izitoast.warning({title:'Warning', message: 'Daftar susunan kepengurusan harus memiliki(menceklis) Pemimpin Perusahaan,Pejabat Spt Tahunan dan Kuasa Spt Tahunan'})
            callback({message: 'Daftar susunan kepengurusan harus memiliki(menceklis) Pemimpin Perusahaan dan Pejabat Spt Tahunan'}, true)
            return true
          }

          if(pemimpinPerusahaanNum > 1 || pejabatSptTahunanNum > 1) {
            callback({message: 'Pemimpin Perusahaan atau Pejabat Spt Tahunan tidak boleh lebih dari 1'}, true)
            return true
          }

          if(penandatanganBpNum > 1 || penandatanganFakturNum > 1) {
            callback({message: 'Penandatangan Bukti Potong atau Penandatangan Faktur Pajak tidak boleh lebih dari 1'}, true)
            return true
          }

          data = Utils.formNormalize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }

          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/entitas')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )

  if(match.params.id != 'baru' && data.content.tipeEntitas != 'Cabang Pembantu') {
    return <TabsWrapper
      title="Entitas"
      showCommandbar={true}
      baseId="mod-details-sample-aad"
      actions={[
        {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => history.push('/onpremise/entitas')}
      ]}
      onChange={ (tab, d) => {
        console.log(tab, 'asd', d)
        navigationStore.redirectTo(`/onpremise/entitas/${match.params.id}/${tab.key}`)
        d()
      }}
      tabs={[
        {
          label: 'Data',
          key: 'form',
          render: form
        },
        {
          label: 'Periode',
          key: 'periode-form',
          render: (
            <EntitasPajakTable history={history} entitasId={match.params.id} />
          )
        },
        {
          label: 'Penomoran BP',
          key: 'penomoranBp',
          render: (
            <PenomoranBpForm history={history} entitasId={match.params.id} />
          )
        },
      ]}
      // onChange={ tab => {
      //   // navigationStore.redirectTo(`/${envStore.env.applicationType}/details/${tab.key}`)
      // }}
    />
  } else {
    return form
  }
}

export default inject('sipStore', 'navigationStore')(observer(EntitasForm))
