import CrudCustom from '../../../libs/custom/CrudCustom';
import http from './../../../libs/custom/httpService.custom';

let RoleService = new CrudCustom('/roles');

RoleService.getPermission = async()=> {
    return http.request({
      method: http.methods.GET,
      url: `/permissions?size=1000`,
    })
}

RoleService.getRolePermission = async()=> {
    return http.request({
      method: http.methods.GET,
      url: `/role-permissions?size=1000`,
    })
}

export default RoleService;
