import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './PengenaanPajak.service'
import TarifService from '../tarif/Tarif.service'
import KodeObjekPajakService from '../KodeObjekPajak/KodeObjekPajak.service'

const PengenaanPajakForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [tarifs, setTarifs] = useState([])
  const [kodeObjekPajaks, setKodeObjekPajaks] = useState([])

  const getTarif = async ()=> {
    var tarifOP = []
    const tarif = await TarifService.get()
    tarif.data.map((d)=> { tarifOP.push({ label: d.nama, value: d.id }) })
    setTarifs(tarifOP)
  }

  const getKodeObjekPajak = async ()=> {
    var kodeObjekPajakOP = []
    const kodeObjekPajak = await KodeObjekPajakService.get()
    kodeObjekPajak.data.map((d)=> { kodeObjekPajakOP.push({ label: d.nama, value: d.id }) })
    setKodeObjekPajaks(kodeObjekPajakOP)
  }

  // initial data
  useEffect(() => {
    async function initData() {
      if(match.params.id == 'baru') {
        setData({loading: false, content: {}})
        return true
      }
      const res = await service.getOne(match.params.id)
      setData({loading: false, content:res.data})
    }
    getKodeObjekPajak()
    getTarif()
    initData()
  }, ['match.params.id'])

  return (
    <FormWrapper
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/pengenaan-pajak`}
      baseId="mod-form-sample"
      title={t.translate('modules.pengenaanPajak.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.INPUT,
          label: 'Domisili Pembeli',
          key: 'domisiliPembeli',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Domisili Penjual',
          key: 'domisiliPenjual',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.CHECKBOX,
          label: 'Akumulasi',
          key: 'akumulasi',
          type: 'boolean',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
        },{
          inputType: inputTypes.INPUT,
          label: 'Kode Objek Pajak ID',
          key: 'kodeObjekPajakId',
          type: 'text',
          required: true,
          width: '50%',
          options: kodeObjekPajaks
        },{
          inputType: inputTypes.INPUT,
          label: 'Tarif ID',
          key: 'tarifId',
          type: 'text',
          required: true,
          width: '50%',
          options: tarifs
        },{
          inputType: inputTypes.INPUT,
          label: 'Nilai Akumulasi Minimal',
          key: 'nilaiAkumulasiMinimal',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nilai Akumulasi Maksimal',
          key: 'nilaiAkumulasiMaksimal',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nilai Transaksi Minimal',
          key: 'nilaiTransaksiMinimal',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Nilai Transaksi Maksimal',
          key: 'nilaiTransaksiMaksimal',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Pembayar Pajak',
          key: 'pembayarPajak',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Pemungut Pajak',
          key: 'pemungutPajak',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Status',
          key: 'status',
          type: 'text',
          required: true,
        },{
          inputType: inputTypes.INPUT,
          label: 'Tipe Pembeli',
          key: 'tipePembeli',
          type: 'text',
          required: true,
          width: '50%'
        },{
          inputType: inputTypes.INPUT,
          label: 'Tipe Penjual',
          key: 'tipePenjual',
          type: 'text',
          required: true,
          width: '50%'
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/pengenaan-pajak')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(PengenaanPajakForm))
