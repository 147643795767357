import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Users.service'

const roleMap = {
  ROLE_USER: { label: 'Pengguna', value: 'ROLE_USER' },
  ROLE_ADMIN: { label: 'Administrator', value: 'ROLE_ADMIN' }
}

const UsersForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [perusahaanList, setPerusahaanList] = useState([]);
  const [entitasList, setEntitasList] = useState([]);
  const [entitasMap, setEntitasMap] = useState({});

 // initial data
  useEffect(() => {
    async function initData() {
      if(match.params.id == 'Tambah') {
        setData({loading: false, content: {entitasLimitation: [], useEntitasLimitation: false}})
        return true
      }
      const res = await service.getLogin(match.params.id)

      var npwpMap = await getPerusahaanList(res.data.id);

      res.data.authorities = res.data.authorities.map((p)=> roleMap[p])
      if(res.data.entitasLimitation && res.data.entitasLimitation != '') {
        res.data.entitasLimitation = res.data.entitasLimitation.split(',').map(p => ({value: npwpMap[p]?npwpMap[p].kodeEntitas+"__"+npwpMap[p].npwp:'-', label: npwpMap[p]?npwpMap[p].nama:'-'}))
        console.log('res.data.limitasiEntitas', res.data.limitasiEntitas)
      } else {
        res.data.entitasLimitation = []
      }


      setData({loading: false, content:res.data})
      setIsEdit(true)
    }
    initData()
  }, ['match.params.id'])

  const getPerusahaanList = async (userId)=> {
    if(match.params.id != 'Tambah') {
      var res = await service.http.get('/perusahaan-users', {
        'userId.equals': userId
      })

      var entitas = await service.http.get('/entitas', {
        // 'tipeEntitas.in': 'Cabang,HO',
        size: 100000000,
      })

      setEntitasList(entitas.data.map(p => ({label: `(${p.kodeEntitas})${p.nama}`, value: p.kodeEntitas+"__"+p.npwp})))
      setEntitasMap(entitas.data.reduce((pv, cv)=> {
        pv[cv.kodeEntitas+"__"+cv.npwp] = cv;
        return pv;
      }, {}))

      return entitas.data.reduce((pv, cv)=> {
        pv[cv.kodeEntitas+"__"+cv.npwp] = cv;
        return pv;
      }, {})
    }

  }

  return (
    <FormWrapper
      noValidate
      customRules={{
        firstName: 'required',
        lastName: 'required',
        email: 'required',
        login : 'required',
        activated : 'required',
        authorities : 'required',
      }}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={`/onpremise/user`}
      baseId="mod-form-sample"
      title={t.translate('modules.userResource.formTitle')}
      //hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: 'Tambah/Perubahan Pengguna',
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'firstName',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          required: true,
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama Belakang',
          key: 'lastName',
          type: 'text',
          width: '49%',
          required: true,
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Email',
          key: 'email',
          type: 'text',
          required: true,
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Login',
          key: 'login',
          type: 'text',
          required: true,
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Password',
          key: 'imageUrl',
          type: 'password',
          required: true,
          // disabled: isEdit
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: "Role Pengguna",
          key: 'authorities',
          type: 'text',
          width: '100%',
          isMulti: true,
          disabled: isEdit,
          options: [
              { label: 'Pengguna', value: 'ROLE_USER' },
              { label: 'Administrator', value: 'ROLE_ADMIN' },
          ]
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Active / Deactive',
          key: 'activated',
          width: '49%',
          type: 'text',
        },

        {
          inputType: inputTypes.CHECKBOX,
          label: 'Aktifkan limitasi entitas',
          key: 'useEntitasLimitation',
          width: '100%',
          type: 'text',
        },

        {
          inputType: inputTypes.REACT_SELECT,
          label: "Limitasi Entitas",
          key: 'entitasLimitation',
          type: 'text',
          width: '100%',
          isMulti: true,
          show: (d)=> d.useEntitasLimitation,
          options: entitasList
        },
        // -----
      ]}

      onSubmit={async (data, callback) => {
        console.log('data', data, data.entitasLimitation)
        var res = {}
        if(data.entitasLimitation.length > 0) {
          console.log('data.entitasLimitation', data.entitasLimitation)
          data.entitasLimitation = data.entitasLimitation.map(p => p.value).join(',')
        } else {
          data.entitasLimitation = ''
        }

        try {
          if(match.params.id == 'Tambah') {

            if(data.authorities && data.authorities.length > 0){

              var authoritiesIn = [];
              data.authorities.map((d)=> {
                authoritiesIn.push(d.value)
              })
              data.authorities = authoritiesIn
            }
            res = await service.post(data)
          } else {
            console.log(data, 'data')
            if(data.authorities && data.authorities.length > 0){
              var authoritiesIn = [];
              data.authorities.map((d)=> {
                authoritiesIn.push(d.value)
              })
              data.authorities = authoritiesIn
              console.log("authoritiesIn",authoritiesIn )
            }
            res = await service.put(data)
          }

          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/user')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(UsersForm))
