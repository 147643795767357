import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './Pengurang.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'


var _baseId = "mod-table-pengurang"

const PengurangTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore,
  authStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title={t.translate('modules.pengurang.title')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Nama',
          searchable: true,
          key: 'nama',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.nama)
        },{
          label: 'Alias',
          searchable: true,
          key: 'alias',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.alias)
        },{
          label: 'Akun',
          searchable: true,
          key: 'akunId',
          type: TableWrapper.dataTypes.STRING,
          // render: item => (item.akunId)
          render: item => (item.akun?item.akun.nama:'')
        },{
          label: 'Tipe Pengurang Bruto',
          searchable: true,
          key: 'tipePengurangPhBrutoId',
          type: TableWrapper.dataTypes.STRING,
          // render: item => (item.tipePengurangPhBruto?item.tipePengurangPhBruto.nama:'')
          render: item => {
            try {
              return (item.tipePengurangPhBruto?`${item.tipePengurangPhBruto.kodeObjekPajak.kode} - ${item.tipePengurangPhBruto.nama}`: `${item.tipePengurangPhBruto.nama}`)
            } catch(e) {
              return "-"
            }
          }
        },{
          label: 'Keterangan',
          searchable: true,
          key: 'keterangan',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.keterangan)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/pengurang/baru'), authStore.user.roleMap?authStore.user.roleMap['Pengurang'].Create:false)
      ]}
      itemActions={[
        new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/pengurang/${item.id}`), authStore.user.roleMap?authStore.user.roleMap['Pengurang'].Edit:false),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, authStore.user.roleMap?authStore.user.roleMap['Pengurang'].Delete:false)
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        if(sipStore.companyId) {
          query['perusahaanId.equals'] = sipStore.companyId
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default inject('sipStore', 'navigationStore', "authStore")(observer(PengurangTable))
