import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './EntitasPajak.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'

const EntitasPajakForm = ({envStore, match, history}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [kategoriPPh25List, setKategoriPPh25] = useState([])
  const [tipePengecualianPajakList, setTipePengecualianPajak] = useState([])

  // initial data
  useEffect(() => {
    async function initData() {
      await _getKategoriPPh25()
      await _getTipePengecualianPajak()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          entitasPajakPengecualians: [],
          metodePenyusutanFiskal: '',
          metodePenyusutanKomersial: '',
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      res.data.entitasPajakPengecualians = res.data.entitasPajakPengecualians.map(d => Utils.formSerialize(d))
      res.data = Utils.formSerialize(res.data)
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getTipePengecualianPajak() {
    var res = await TipePengecualianPajakService.get({size: 100000})
    setTipePengecualianPajak(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getKategoriPPh25() {
    var res = await KategoriPPh25Service.get({size: 100000})
    setKategoriPPh25(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/entitas/'+match.params.entitasId+'/periode-form'}
      baseId="mod-form-sample"
      title={t.translate('modules.EntitasPajak.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      definitions={[
        // =====
        {
          inputType: inputTypes.DATEPICKER,
          label: 'Periode Awal',
          key: 'tanggalAwal',
          // type: 'text',
          width: '49%',
          required: true,
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: isEdit
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Periode Akhir',
          key: 'tanggalAkhir',
          // type: 'text',
          width: '49%',
          required: true,
          validation: 'required',
          disabled: isEdit
        },
        {
          inputType: inputTypes.INPUT,
          label: 'KLU',
          key: 'klu',
          type: 'text',
          width: '100%',
          required: true,
          validation: 'required'
          // style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.SELECT,
          label: 'BUT',
          key: 'but',
          width: '100%',
          required: true,
          validation: 'required',
          options: [{value: 'BUT', label: 'BUT'}, {value: 'NON BUT', label: 'NON BUT'}],
          // style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Kategori PPh25',
          key: 'kategoriPPh25Id',
          width: '100%',
          // required: true,
          options: kategoriPPh25List,
          // style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'SPT Tahunan 5 Persen Lebih Rendah',
          key: 'sptTahunan5PersenLebihRendah',
          width: '49%',
          // required: true,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Kewajiban pelaporan realisasi PPh 21 DTP',
          key: 'pph21Dtp',
          width: '49%',
          // required: true,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFORMASI PPN'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'PKP',
          key: 'pkp',
          width: '49%',
          // required: true,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Pemusatan PPN',
          key: 'pemusatanPPn',
          width: '49%',
          // required: true,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Set Sebagai Pemusatan PPN',
          key: 'sebagaiTempatPemusatanPPn',
          width: '49%',
          // required: true,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'WAPU',
          key: 'wapu',
          width: '49%',
          // required: true,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Bendahara Pemerintah',
          key: 'bendaharaPemerintah',
          width: '100%',
          // required: true,
          // style: {marginRight: '2%'}
          options: [{value: 'WAPU Bendaharawan Pemerintah', label: 'WAPU Bendaharawan Pemerintah'}, {value: 'WAPU BUMN', label: 'WAPU BUMN Selain Bendaharawan pemerintah'}],
          show: (d)=> d.wapu,
          validation: [{required_if: ['wapu', true]}],
        },

        {
          inputType: inputTypes.DIVIDER,
          label: 'INFORMASI PPh 22'
        },

        {
          inputType: inputTypes.CHECKBOX,
          label: 'Pemungut PPh 22',
          key: 'pemungut',
          width: '49%',
          // required: true,
          style: {marginRight: '2%'}
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Jenis Pemungut PPh22',
          key: 'jenisPemungutPPh22',
          width: '100%',
          // required: true,
          // style: {marginRight: '2%'}
          options: [
            {value: 'Badan usaha industri atau eksportir yang bergerak dalam sektor kehutanan, perkebunan, pertanian, peternakan, dan perikanan', label: 'Badan usaha industri atau eksportir yang bergerak dalam sektor kehutanan, perkebunan, pertanian, peternakan, dan perikanan'},
            {value: 'Bank Yang Didirikan Atau Bertempat Kedudukan Di Indonesia Atau Cabang Bank Luar Negeri Di Indonesia', label: 'Bank Yang Didirikan Atau Bertempat Kedudukan Di Indonesia Atau Cabang Bank Luar Negeri Di Indonesia'},
            {value: 'BUMN/Badan Tertentu', label: 'BUMN/Badan Tertentu'},
            {value: 'Distributor baja di dalam negeri', label: 'Distributor baja di dalam negeri'},
            {value: 'Distributor farmasi di dalam negeri', label: 'Distributor farmasi di dalam negeri'},
            {value: 'Distributor kertas di dalam negeri', label: 'Distributor kertas di dalam negeri'},
            {value: 'Distributor otomotif di dalam negeri', label: 'Distributor otomotif di dalam negeri'},
            {value: 'Distributor semen di dalam negeri', label: 'Distributor semen di dalam negeri'},
            {value: 'Instansi Pemerintah', label: 'Instansi Pemerintah'},
            {value: 'Penyelenggara bursa efek', label: 'Penyelenggara bursa efek'},
            {value: 'SPBU/Agen/Penyalur Pertamina', label: 'SPBU/Agen/Penyalur Pertamina'},
            {value: 'SPBU/Agen/Penyalur/Lainnya non Pertamina', label: 'SPBU/Agen/Penyalur/Lainnya non Pertamina'},
            {value: 'ATPM, APM, dan importir umum kendaraan bermotor', label: 'ATPM, APM, dan importir umum kendaraan bermotor'},
            {value: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Kecil)', label: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Kecil)'},
            {value: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Menengah dan Besar)', label: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Menengah dan Besar)'},
            {value: 'Pelaksanaan Konstruksi (Tanpa Kualifikasi Usaha)', label: 'Pelaksanaan Konstruksi (Tanpa Kualifikasi Usaha)'},
            {value: 'Pelayaran / Penerbangan', label: 'Pelayaran / Penerbangan'},
            {value: 'Pemegang Izin Usaha Pertambangan', label: 'Pemegang Izin Usaha Pertambangan'},
            {value: 'Pengawasan Konstruksi (Dengan Kualifikasi Usaha)', label: 'Pengawasan Konstruksi (Dengan Kualifikasi Usaha)'},
            {value: 'Pengawasan Konstruksi (Tanpa Kualifikasi Usaha)', label: 'Pengawasan Konstruksi (Tanpa Kualifikasi Usaha)'},
            {value: 'Perencanaan Konstruksi (Dengan Kualifikasi Usaha)', label: 'Perencanaan Konstruksi (Dengan Kualifikasi Usaha)'},
            {value: 'Perencanaan Konstruksi (Tanpa Kualifikasi Usaha)', label: 'Perencanaan Konstruksi (Tanpa Kualifikasi Usaha)'},
            {value: 'Produsen /importir BBM, BBG, dan pelumas (Pertamina)', label: 'Produsen /importir BBM, BBG, dan pelumas (Pertamina)'},
            {value: 'Produsen /importir BBM, BBG, dan pelumas (selain Pertamina)', label: 'Produsen /importir BBM, BBG, dan pelumas (selain Pertamina)'},
            {value: 'Produsen Baja', label: 'Produsen Baja'},
            {value: 'Produsen emas batangan', label: 'Produsen emas batangan'},
            {value: 'Produsen Farmasi', label: 'Produsen Farmasi'},
            {value: 'Produsen Kertas', label: 'Produsen Kertas'},
            {value: 'Produsen Otomotif', label: 'Produsen Otomotif'},
            {value: 'Produsen Semen', label: 'Produsen Semen'}
          ],
          show: (d)=> d.pemungut,
          validation: [{required_if: ['pemungut', true]}],
        },

        {
          inputType: inputTypes.SELECT,
          label: 'Kategori Entitas (PPN)',
          key: 'kategoriEntitasPPn',
          width: '100%',
          // validation: 'required',
          options: [
            {value: '01', label: 'Produsen/Importir BBM, BBG, dan pelumas (Pertamina)'},
            {value: '02', label: 'Produsen/Importir BBM, BBG, dan pelumas (selain Pertamina)'},
            {value: '03', label: 'Wajib Pajak yang terikat dalam perjanjian kerjasama pengusahaan pertambangan dan Kontrak Karya'},
            {value: '04', label: 'Kawasan Bebas'},
            {value: '05', label: 'Tempat Penimbunan Berikut'},
            {value: '06', label: 'Kontraktor Perjanjian Karya Pengusahaan Pertambangan Batubara Generasi 1'},
            {value: '07', label: 'Kontraktor Kerja Sama Migas yang mengikuti ketentuan Peraturan Pemerintah Nomor 27 Tahun 2017'}
          ]
        },


        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Metode Penyusutan Fiskal',
        //   key: 'metodePenyusutanFiskal',
        //   width: '49%',
        //   options: [{value: 'Garis Lurus (GL)', label: 'Garis Lurus (GL)'}, {value: 'Saldo Menurun (SM)', label: 'Saldo Menurun (SM)'}],
        //   style: {marginRight: '2%'}
        // },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Metode Penyusutan Komersial',
        //   key: 'metodePenyusutanKomersial',
        //   width: '49%',
        //   options: [{value: 'Garis Lurus (GL)', label: 'Garis Lurus (GL)'}, {value: 'Saldo Menurun (SM)', label: 'Saldo Menurun (SM)'}],
        // },


        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'Pengecualian Pajak',
          key: 'entitasPajakPengecualians',
          // hintMessage: 'Daftar Tarif',
          defaultData: {
          },
          layout: 'table',
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Tipe Pengecualian Pajak',
              key: 'tipePengecualianPajak__id',
              theme: 'filled',
              type: 'number',
              options: tipePengecualianPajakList,
              style: {width: 1000},
              required: true,
              validation: 'required'
            },
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.entitasId = match.params.entitasId
          data.entitasPajakPengecualians = data.entitasPajakPengecualians.map(d => Utils.formNormalize(d))
          data = Utils.formSerialize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/entitas/'+match.params.entitasId+'/periode-form')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(EntitasPajakForm))
