import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './LawanTransaksiPajak.service'
import TipePengecualianPajakService from '../../adminpajakku//tipePengecualianPajak/TipePengecualianPajak.service'
import TipeHubunganIstimewaService from '../../adminpajakku/tipeHubunganIstimewa/TipeHubunganIstimewa.service'
import Utils from '../../../libs/custom/Utils'
import moment from 'moment'
import iziToast from 'izitoast'

const LawanTransaksiPajakForm = ({envStore, match, history, sipStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [tipePengecualianPajakList, setTipePengecualianPajak] = useState([])
  const [tipeHubunganIstimewaList, setTipeHubunganIstimewa] = useState([])
  const [customRules, setCustomRules] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getTipePengecualianPajak()
      await _getTipeHubunganIstimewa()
      var lt= JSON.parse(localStorage.getItem("lt"))
      if(match.params.id == 'baru') {
        setData({loading: false, content: {
          entitasId: '-',
          statusData: '-',
          lawanTransaksiPengecualianPajaks: [],
          domisiliWajibPajak: lt.domisiliWajibPajak
        }})
        return true
      }
      const res = await service.getOne(match.params.id)
      if(!res.data.lawanTransaksiPengecualianPajaks) res.data.lawanTransaksiPengecualianPajaks = []
      res.data.lawanTransaksiPengecualianPajaks = res.data.lawanTransaksiPengecualianPajaks.map(d => Utils.formSerialize(d))
      res.data = Utils.formSerialize(res.data)
      res.data.domisiliWajibPajak = lt.domisiliWajibPajak
      if(res.data.but == 'BUT') {
        setCustomRules({npwp: ['required', 'size:15']})
      }
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getTipePengecualianPajak() {
    var res = await TipePengecualianPajakService.get({size: 100000})
    setTipePengecualianPajak(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getTipeHubunganIstimewa() {
    var res = await TipeHubunganIstimewaService.get({size: 100000})
    setTipeHubunganIstimewa(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  return (
    <FormWrapper
      noValidate
      customRules={customRules}
      loading={data.loading}
      actions={[
        {
          label: 'Reload',
          iconClassName: 'mdi mdi-reload'
        }
      ]}
      backTo={'/onpremise/lawan-transaksi/'+match.params.lawanTransaksiId+'/periode-form'}
      baseId="mod-form-sample"
      title={t.translate('modules.LawanTransaksiPajak.formTitle')}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key == 'but') {
          if(value == 'BUT') {
            setCustomRules({npwp: ['required', 'size:15']})
          }
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DATEPICKER,
          label: 'Periode Awal',
          key: 'tanggalAwal',
          width: '49%',
          style: {marginRight: '2%'}
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Periode Akhir',
          key: 'tanggalAkhir',
          width: '49%'
        },{
          inputType: inputTypes.SELECT,
          label: 'BUT/NON BUT',
          key: 'but',
          width: '100%',
          options: [{value: 'BUT', label: 'BUT'}, {value: 'NON BUT', label: 'NON BUT'}],
          show: (d) => d.domisiliWajibPajak == "LN",
          validation: [{required_if: ['domisiliWajibPajak', 'LN']}]
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFORMASI BUT',
          key: 'infoBut',
          show: (d) => d.but == 'BUT',
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP',
          key: 'npwp',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: [{required_if: ['but', 'BUT']}],
          show: (d) => d.but == 'BUT',
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nama',
          key: 'nama',
          type: 'text',
          width: '49%',
          validation: [{required_if: ['but', 'BUT']}],
          show: (d) => d.but == 'BUT',
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Alamat',
          key: 'alamat',
          type: 'text',
          width: '100%',
          validation: [{required_if: ['but', 'BUT']}],
          show: (d) => d.but == 'BUT',
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFORMASI PPN',
          key: 'infoPpn'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Kategori Lawan Transaksi Tertentu (PPN)?',
          key: 'pkp',
          width: '100%',
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Kategori Entitas (PPN)',
          key: 'kategoriEntitasPPn',
          width: '100%',
          show: (d) => d.pkp == true,
          validation: [{required_if: ['pkp', true]}],
          options: [
            {value: '01', label: 'Produsen/Importir BBM, BBG, dan pelumas (Pertamina)'},
            {value: '02', label: 'Produsen/Importir BBM, BBG, dan pelumas (selain Pertamina)'},
            {value: '03', label: 'Wajib Pajak yang terikat dalam perjanjian kerjasama pengusahaan pertambangan dan Kontrak Karya'},
            {value: '04', label: 'Kawasan Bebas'},
            {value: '05', label: 'Tempat Penimbunan Berikut'},
            {value: '06', label: 'Kontraktor Perjanjian Karya Pengusahaan Pertambangan Batubara Generasi 1'},
            {value: '07', label: 'Kontraktor Kerja Sama Migas yang mengikuti ketentuan Peraturan Pemerintah Nomor 27 Tahun 2017'}
          ]
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFORMASI PPH22',
          key: 'infoPph22'
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Pemungut?',
          key: 'pemungutPPh22',
          width: '100%',
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Kategori Entitas (PPh22)',
          key: 'kategoriEntitasPPh22',
          width: '100%',
          style: {marginRight: '2%'},
          options: [
            {value: 'Bank Yang Didirikan Atau Bertempat Kedudukan Di Indonesia Atau Cabang Bank Luar Negeri Di Indonesia', label: 'Bank Yang Didirikan Atau Bertempat Kedudukan Di Indonesia Atau Cabang Bank Luar Negeri Di Indonesia'},
            {value: 'Penyelenggara bursa efek', label: 'Penyelenggara bursa efek'},
            {value: 'BUMN/Badan Tertentu', label: 'BUMN/Badan Tertentu'},
            {value: 'Instansi Pemerintah', label: 'Instansi Pemerintah'},
            {value: 'Distributor semen di dalam negeri', label: 'Distributor semen di dalam negeri'},
            {value: 'Distributor baja di dalam negeri', label: 'Distributor baja di dalam negeri'},
            {value: 'Distributor otomotif di dalam negeri', label: 'Distributor otomotif di dalam negeri'},
            {value: 'Distributor farmasi di dalam negeri', label: 'Distributor farmasi di dalam negeri'},
            {value: 'Distributor kertas di dalam negeri', label: 'Distributor kertas di dalam negeri'},
            {value: 'Badan usaha industri atau eksportir yang bergerak dalam sektor kehutanan, perkebunan, pertanian, peternakan, dan perikanan', label: 'Badan usaha industri atau eksportir yang bergerak dalam sektor kehutanan, perkebunan, pertanian, peternakan, dan perikanan'},
            {value: 'SPBU/Agen/Penyalur Pertamina', label: 'SPBU/Agen/Penyalur Pertamina'},
            {value: 'SPBU/Agen/Penyalur/Lainnya non Pertamina', label: 'SPBU/Agen/Penyalur/Lainnya non Pertamina'},
            {value: 'Perencanaan Konstruksi (Dengan Kualifikasi Usaha)', label: 'Perencanaan Konstruksi (Dengan Kualifikasi Usaha)'},
            {value: 'Perencanaan Konstruksi (Tanpa Kualifikasi Usaha)', label: 'Perencanaan Konstruksi (Tanpa Kualifikasi Usaha)'},
            {value: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Kecil)', label: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Kecil)'},
            {value: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Menengah dan Besar)', label: 'Pelaksanaan Konstruksi (Kualifikasi Usaha Menengah dan Besar)'},
            {value: 'Pelaksanaan Konstruksi (Tanpa Kualifikasi Usaha)', label: 'Pelaksanaan Konstruksi (Tanpa Kualifikasi Usaha)'},
            {value: 'Pengawasan Konstruksi (Dengan Kualifikasi Usaha)', label: 'Pengawasan Konstruksi (Dengan Kualifikasi Usaha)'},
            {value: 'Pengawasan Konstruksi (Tanpa Kualifikasi Usaha)', label: 'Pengawasan Konstruksi (Tanpa Kualifikasi Usaha)'},
            {value: 'Pelayaran / Penerbangan', label: 'Pelayaran / Penerbangan'},
            {value: 'Produsen Semen', label: 'Produsen Semen'},
            {value: 'Produsen Baja', label: 'Produsen Baja'},
            {value: 'Produsen Otomotif', label: 'Produsen Otomotif'},
            {value: 'Produsen Farmasi', label: 'Produsen Farmasi'},
            {value: 'Produsen Kertas', label: 'Produsen Kertas'},
            {value: 'ATPM, APM, dan importir umum kendaraan bermotor', label: 'ATPM, APM, dan importir umum kendaraan bermotor'},
            {value: 'Pemegang Izin Usaha Pertambangan', label: 'Pemegang Izin Usaha Pertambangan'},
            {value: 'Produsen emas batangan', label: 'Produsen emas batangan'},
          ],
          show: (d)=> d.pemungutPPh22,
          validation: [{required_if: ['pemungutPPh22', true]}],
        },
        {
          inputType: inputTypes.DIVIDER,
          label: 'INFORMASI HUBUNGAN ISTIMEWA',
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Pihak yang memiliki Hubungan Istimewa?',
          key: 'hasHubunganIstimewa',
          width: '100%',
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Bentuk Hubungan Istimewa',
          key: 'tipeHubunganIstimewaId',
          width: '100%',
          options: tipeHubunganIstimewaList,
          show: (d)=> d.hasHubunganIstimewa,
          validation: [{required_if: ['hasHubunganIstimewa', true]}],
        },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Entitas ID',
        //   key: 'entitasId',
        //   width: '49%',
          // required: true,
        //   style: {marginRight: '2%'}
        // },
        // {
        //   inputType: inputTypes.INPUT,
        //   label: 'Status Data',
        //   key: 'statusData',
        //   width: '49%',
          // required: true,
        // },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: 'Pengecualian Pajak',
          key: 'lawanTransaksiPengecualianPajaks',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          // style: {width: 700},
          defaultData: {
            status: 'AKTIF',
            keterangan: '-'
          },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Pengecualian Pajak',
              key: 'tipePengecualianPajak__id',
              theme: 'filled',
              // width: '49%',
              // style: {marginRight: '2%'},
              style: {width: 1000},
              // validation: 'required',
              options: tipePengecualianPajakList
            },
          ]
        }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        // if(data.lawanTransaksiPengecualianPajaks <= 0) {
        //   callback({message: 'Pengecualian Pajak wajib di isi'}, true)
        //   return true
        // }

        var res = {}
        try {
          if(data.tanggalAwal > data.tanggalAkhir){
            iziToast.warning({
              message: "Periode Invalid"
            })
            callback("", false, false)
            return
          }

          data.lawanTransaksiId = match.params.lawanTransaksiId
          data.lawanTransaksiPengecualianPajaks = data.lawanTransaksiPengecualianPajaks.map(d => Utils.formNormalize(d))
          data = Utils.formSerialize(data)
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/lawan-transaksi/'+match.params.lawanTransaksiId+'/periode-form')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore')(observer(LawanTransaksiPajakForm))
