import React, { useEffect, useState } from "react";
import { TableWrapper } from "../../../libs/react-mpk/wrapper";
import Modal from "../../../libs/react-mpk/components/Modal";
import t from "counterpart";
import service from "./PemotonganPPh2126.service";
import moment from "moment";
import { toast } from "../../../libs/react-mpk/services";
import { inject, observer } from "mobx-react";

var _baseId = "mod-table-pemotongan-pph2126";

const PemotonganPPh2126Table = ({
  className = "",
  showCommandbar = true,
  history,
  sipStore,
  navigationStore,
  modalStore,
  authStore
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteData, setConfirmDeleteData] = useState({});

  function _closeConfirmDelete() {
    setConfirmDelete(false);
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id);
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`);
    callback();
    setConfirmDelete(false);
    TableWrapper.reload(_baseId);
  }

  return (
    <>
      <TableWrapper
        baseId={_baseId}
        title={t.translate("modules.pemotonganPPh2126.title")}
        className={className}
        // hintMessage={t.translate('modules.table.hint.message')}
        hintIconClassName="mdi mdi-information"
        hintMore={t.translate("modules.table.hint.more")}
        defaultData={[]}
        defaultSortBy="createdDate"
        columns={[
          {
            label: "Nama Entitas",
            searchable: true,
            key: "nama",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.entitas.nama,
          },
          {
            label: "NPWP Entitas",
            searchable: true,
            key: "npwp",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.entitas.npwp,
          },
          {
            label: "Masa Pajak",
            searchable: true,
            key: "masaPajak",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.masaPajak,
          },
          {
            label: "Tahun Pajak",
            searchable: true,
            key: "tahunPajak",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.tahunPajak,
          },
          {
            label: "Status Pengiriman",
            searchable: true,
            key: "pengirimanStatus",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.pengirimanStatus,
          },
          {
            label: "Pesan",
            searchable: true,
            key: "pengirimanNotes",
            type: TableWrapper.dataTypes.STRING,
            render: (item) => item.pengirimanNotes,
          },
        ]}
        actions={[
          new TableWrapper.action(
            "Add",
            "mdi mdi-plus",
            () => history.push("/onpremise/pemotongan-pph2126/baru"),
            authStore.user.roleMap?authStore.user.roleMap['Transaksi Pemotongan PPh 21/26'].Create:false
          ),
        ]}
        itemActions={[
          new TableWrapper.action(
            "Buka Menu PPh",
            "mdi mdi-book-open",
            (item) => {
              localStorage.setItem("sip-menu-pph", JSON.stringify(item));
              history.push(`/pph-menu/index`);
            },
            true
          ),
          new TableWrapper.action(
            "Kirim ePPT",
            "mdi mdi-mail",
            (item) => {
              modalStore.showConfirm({
                children: "Kirim data terpilih ke ePPT?",
                onRequestClose: () => {
                  modalStore.closeConfirm();
                },
                onSubmit: async (callback) => {
                  await service.http.post(
                    `/pemotongan-pph2126s/${item.id}/send-to-eppt`,
                    {}
                  );
                  toast.success(
                    `Cek pada kolom status, untuk memantau status pengiriman anda!`
                  );
                  TableWrapper.reload(_baseId);
                  callback();
                },
              });
            },
            true
          ),
          // new TableWrapper.action('Edit', 'mdi mdi-pencil', (item) => history.push(`/onpremise/pemotongan-pph2126/${item.id}`), true),
          new TableWrapper.action(
            "Delete",
            "mdi mdi-delete",
            (item) => {
              setConfirmDeleteData(item);
              setConfirmDelete(true);
            },
            authStore.user.roleMap?authStore.user.roleMap['Transaksi Pemotongan PPh 21/26'].Delete:false
          ),
        ]}
        onFetchData={async (query) => {
          if (!query) query = {};
          if (sipStore.companyId) {
            query["perusahaanId.equals"] = sipStore.companyId;
          }
          if(sipStore.periodeId) {
            query["tahunPajak.equals"] = sipStore.periode.tahun
          }
          return service.get(query);
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
      <Modal.Confirm
        // title="Hapus Data"
        visible={confirmDelete}
        onRequestClose={_closeConfirmDelete}
        onSubmit={_submitConfirmDelete}
      >
        Hapus data {confirmDeleteData.nama}?
      </Modal.Confirm>
    </>
  );
};

export default inject(
  "modalStore",
  "sipStore",
  "navigationStore",
  "authStore"
)(observer(PemotonganPPh2126Table));
