import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { TabsWrapper } from "../../../libs/react-mpk/wrapper";
import Utils from "../../../libs/custom/Utils";
import { ID_TIPE_PEGAWAI, TIPE_TRANSKRIP } from "../../../clientConstant";
import {
  ExpansionList,
  ExpansionPanel,
  usePanels,
} from "@react-md/expansion-panel";
import { Table, TableBody, TableRow, TableCell } from "react-md";

import { Grid } from "@react-md/utils";

const SaldoAwalForm = ({
  envStore,
  match,
  history,
  data,
  onSubmit,
  sipStore,
  navigationStore,
  tipeTranskrip,
}) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [newData, setNewData] = useState({});
  const [grantTotal, setGrantTotal] = useState(0);
  const [map, setMap] = useState({});

  const [panels, onKeyDown] = usePanels({
    idPrefix: "saldo-awal",
    count: 50,
    defaultExpandedIndex: 0,
  });

  useEffect(() => {
    var newObj = {...data.saldoMap}

    data.saldoList
    .filter(
      (x) =>
        x.elemenTranskrip.tipeTranskripId == tipeTranskrip &&
        x.elemenTranskrip.tipePerhitungan == "PERHITUNGAN ELEMEN LAIN"
    )
    .forEach((x) => {
      var map = data.saldoList
        .filter((y) => y.elemenTranskrip.tipeTranskripId == tipeTranskrip)
        .reduce((pv, cv) => {
          pv[cv.elemenTranskrip.nomorUrut + ""] = newObj[
            cv.elemenTranskrip.id + "_nilaiAwal"
          ]
            ? parseInt(newObj[cv.elemenTranskrip.id + "_nilaiAwal"])
            : 0;
          return pv;
        }, {});

      if (!x.elemenTranskrip.kolomPenambah)
        x.elemenTranskrip.kolomPenambah = "";
      if (!x.elemenTranskrip.kolomPengurang)
        x.elemenTranskrip.kolomPengurang = "";
      var kolomPenambah = x.elemenTranskrip.kolomPenambah
        .split(",")
        .map((x) => x.trim());
      var kolomPengurang = x.elemenTranskrip.kolomPengurang
        .split(",")
        .map((x) => x.trim());

      var totalPenambah = kolomPenambah.reduce((pv, cv) => {
        if (!map[cv]) map[cv] = 0;
        pv = pv + map[cv];
        return pv;
      }, 0);

      var totalPengurang = kolomPengurang.reduce((pv, cv) => {
        if (!map[cv]) map[cv] = 0;
        pv = pv + map[cv];
        return pv;
      }, 0);

      newObj[x.elemenTranskrip.id + "_nilaiAwal"] =
        totalPenambah - totalPengurang;
    });

    setTimeout(() => {
      FormWrapper.reload('mod-form-saldo-awal', newObj)
    }, 500)


  }, [tipeTranskrip])


  return (
    <FormWrapper
      noValidate
      // editable={false}
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      showCommandbar={false}
      backTo={`/onpremise/pegawai`}
      baseId="mod-form-saldo-awal"
      title="Saldo Awal"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      submitLabel="Berikutnya"
      submitIconClassName="mdi mdi-arrow-right"
      defaultData={data.saldoMap}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if (key == "nilaiAwal" || key == "posisiAkunAwal") {
          newObj[parentKey + "_nilaiAwal"] = newObj[parentKey].reduce(
            (pv, cv) => {
              if (cv.posisiAkunAwal == "K") {
                pv = pv - parseInt(cv.nilaiAwal);
              } else {
                pv = pv + parseInt(cv.nilaiAwal);
              }
              return pv;
            },
            0
          );
        }

        data.saldoList
          .filter(
            (x) =>
              x.elemenTranskrip.tipeTranskripId == tipeTranskrip &&
              x.elemenTranskrip.tipePerhitungan == "PERHITUNGAN ELEMEN LAIN"
          )
          .forEach((x) => {
            var map = data.saldoList
              .filter((y) => y.elemenTranskrip.tipeTranskripId == tipeTranskrip)
              .reduce((pv, cv) => {
                pv[cv.elemenTranskrip.nomorUrut + ""] = newObj[
                  cv.elemenTranskrip.id + "_nilaiAwal"
                ]
                  ? parseInt(newObj[cv.elemenTranskrip.id + "_nilaiAwal"])
                  : 0;
                return pv;
              }, {});

            if (!x.elemenTranskrip.kolomPenambah)
              x.elemenTranskrip.kolomPenambah = "";
            if (!x.elemenTranskrip.kolomPengurang)
              x.elemenTranskrip.kolomPengurang = "";
            var kolomPenambah = x.elemenTranskrip.kolomPenambah
              .split(",")
              .map((x) => x.trim());
            var kolomPengurang = x.elemenTranskrip.kolomPengurang
              .split(",")
              .map((x) => x.trim());

            var totalPenambah = kolomPenambah.reduce((pv, cv) => {
              if (!map[cv]) map[cv] = 0;
              pv = pv + map[cv];
              return pv;
            }, 0);

            var totalPengurang = kolomPengurang.reduce((pv, cv) => {
              if (!map[cv]) map[cv] = 0;
              pv = pv + map[cv];
              return pv;
            }, 0);

            newObj[x.elemenTranskrip.id + "_nilaiAwal"] =
              totalPenambah - totalPengurang;
          });
        setNewData({ saldoMap: newObj });
      }}
      definitions={[
        // =====
        {
          render: (d, pk, pi, render) => {
            return (
              <div style={{ width: "100%" }}>
                <ExpansionList onKeyDown={onKeyDown} style={{ width: "100%" }}>
                  {data.saldoList
                    .filter(
                      (x) =>
                        data.etMap[x.elemenTranskrip.id].tipeTranskripId ==
                        tipeTranskrip
                    )
                    .map((d, i) => {
                      return (
                        <ExpansionPanel
                          {...panels[i]}
                          key={i}
                          header={
                            data.etMap[d.elemenTranskrip.id].nomorUrut +
                            ". " +
                            data.etMap[d.elemenTranskrip.id].nama
                          }
                          disablePadding
                        >
                          {render(
                            {
                              inputType: FormWrapper.inputTypes.LIST,
                              // label: 'Pegawai Gaji Pengurang',
                              key: d.elemenTranskrip.id,
                              elementKey: d.elemenTranskrip.id,
                              layout: "table",
                              footerTable: (dt) => {
                                var total =
                                  dt.data[d.elemenTranskrip.id + "_nilaiAwal"];
                                var p = 'Debit'
                                if(total < 0) p = 'Kredit'

                                return (
                                  <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{p}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>
                                      {Utils.numberFormat(Math.abs(total))}
                                    </TableCell>
                                  </TableRow>
                                );
                              },
                              hideActionButton: true,
                              defaultData: {
                                keterangan: "-",
                              },
                              definitions: [
                                {
                                  label: "Akun",
                                  render: (d, pk, pi) => {
                                    // return data.akunMap[d[pk][pi].akunId]?data.akunMap[d[pk][pi].akunId].nama:''
                                    return d[pk][pi].akun
                                      ? d[pk][pi].akun.nama
                                      : "";
                                  },
                                },
                                {
                                  inputType: FormWrapper.inputTypes.SELECT,
                                  label: "Posisi Akun",
                                  key: "posisiAkunAwal",
                                  theme: "filled",
                                  options: [
                                    { value: "D", label: "Debit" },
                                    { value: "K", label: "Kredit" },
                                  ],
                                },
                                {
                                  inputType: inputTypes.INPUT_MASK_NUMBER,
                                  label: "Nilai",
                                  key: "nilaiAwal",
                                  theme: "filled",
                                  inputStyle: { textAlign: "right" },
                                  thousandSeparator: ".",
                                  decimalSeparator: ",",
                                  isNumericString: true,
                                  validation: "required|numeric",
                                },
                              ],
                            },
                            "0"
                          )}
                        </ExpansionPanel>
                      );
                    })}
                </ExpansionList>
              </div>
            );
          },
        },
      ]}
      onSubmit={onSubmit}
    />
  );
};

export default SaldoAwalForm;
