import React, {useEffect, useState} from 'react'
import { FormWrapper, TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import services from './Users.service'
//import service from './User.service'
import Service from '../../../modules/OpLogin/OpLogin.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { DataForm } from '../../../libs/react-mpk/components'
import { Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
import iziToast from 'izitoast'

var _baseId = "mod-table-user"
const formatDate = (date) => {
  return new Date(date).toLocaleString("id-ID");
}


const UsersTable = ({
  className       = '',
  multiColumnFilter = true,
  showCommandbar  = true,
  history
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})
  const [uploadModal, setUploadModal] = useState(false)
  const [showDialog, setShowDialog] = useState({ data: {}, show: false })

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  function _closeUploadModal() {
    setUploadModal(false)
  }

  async function _submitConfirmDelete(callback) {
    var r = await services.delete(confirmDeleteData.login)
    toast.info(`Data ${confirmDeleteData.firstName} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <Dialog
        visible={showDialog.show}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setShowDialog(false)
        }}
        style={{ width: 600 }}
      >
        <DialogHeader>
          <DialogTitle><b>GANTI PASSWORD</b></DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={''}
            defaultData={showDialog.data}
            hintShowIcon={true}
            submitLabel='Simpan'
            definitions={[
              {
                inputType: DataForm.inputTypes.INPUT,
                label: 'Password Lama',
                key: 'currentPassword',
                type: 'password',
                validation: 'required'
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                label: 'Password Baru',
                key: 'newPassword',
                type: 'password',
                validation: 'required'
              },
              {
                inputType: DataForm.inputTypes.INPUT,
                label: 'Konfirmasi Password Baru',
                key: 'confirmPassword',
                type: 'password',
                validation: 'required'
              }
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => { setShowDialog(false) }}>Tutup</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              var newPassword = values.newPassword
              var confirmPassword = values.confirmPassword
              try{
                if(confirmPassword != newPassword){
                  iziToast.warning({
                    title:'Warning',
                    message: 'Password Baru dan Konfirmasi Password Tidak Sama'
                  })
                  callback('', true, false)
                  return true
                }

                let res = await Service.changePasswordAdmin(values)
                callback('Password Berhasil Diubah', false)
                setShowDialog({show: false})
                TableWrapper.reload(_baseId)
              } catch {
                setShowDialog({show: false})
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
    <TableWrapper
      baseId={_baseId}
      title={t.translate('modules.userResource.title')}
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      // multiColumnFilter
      defaultSortBy="createdDate"
      columns={[
        {
          label: 'Email',
          searchable: false,
          sortable: true,
          key: 'email',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.email)
        },
        {
          label: 'Login',
          sortable: true,
          searchable: false,
          key: 'login',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.login)
        },
        {
          label: 'Nama',
          searchable: true,
          key: 'firstName',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.firstName)
        },
        {
          label: 'Aktif',
          searchable: true,
          key: 'activated',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.activated.toString().toLocaleUpperCase())
        },
        {
          label: 'Dibuat',
          // sortable: true,
          searchable: true,
          key: 'createdDate',
          definition: {
            inputType: 'datepicker',
          },

          type: TableWrapper.dataTypes.DATE,
          render: item => formatDate(item.createdDate)
        },
        {
          label: 'Role Pengguna',
          searchable: false,
          key: 'authorities',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.authorities)
        }
      ]}
      actions={[
        new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/user/Tambah'), true),
        new TableWrapper.action('Import User', 'mdi mdi-import', () => setUploadModal(true), true),
      ]}
      itemActions={[
        new TableWrapper.action('More Info', 'mdi mdi-bell', (item) => history.push(`/onpremise/user/${item.login}`), true),
        new TableWrapper.action('Active/Deactive', 'mdi mdi-check', (item) => history.push(`/onpremise/user/${item.login}`), true),
        new TableWrapper.action('Ganti Password', 'mdi mdi-lock-question', (item) => setShowDialog({ data: item, show: true }), true),
        new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => { setConfirmDeleteData(item); setConfirmDelete(true)}, true)
      ]}
      onFetchData={ async query => {
        return services.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.login}?</Modal.Confirm>
    <DataForm
      baseId={"transaksi-import"}
      asDialog={true}
      defaultData={{}}
      definitions={[{
        inputType: FormWrapper.inputTypes.FILE_INPUT,
        label: 'File',
        key: 'file',
        theme: 'filled',
        style: {width: 200}
      }]}
      visible={uploadModal}
      submitLabel='Import Data'
      onSubmit={async (d, callback)=> {
        var formData = new FormData()
        formData.set('file', d.file)
        var uploadRes = await services.http.post('/upload', formData)
        if(uploadRes.data) {
          var importRes = await services.http.post('/users-import', {
            nama: uploadRes.data.name,
            origin: uploadRes.data.origin,
            ext: 'xls'
          })
        }
        callback('Data sedang di import. Mohon pantau progress import di menu log import.', false)
      }}
      onRequestClose={_closeUploadModal} />
    </>
  )
}

export default UsersTable
