import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { CustomWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./PemotonganPPh2126.service";
import EntitasService from "../../master/Entitas/Entitas.service";
import Utils from "../../../libs/custom/Utils";
import PerusahaanListService from "../../master/Perusahaan/Perusahaan.service";
import moment from "moment";
import iziToast from "izitoast";
import {
  Button,
  Checkbox,
  Chip,
  List,
  ListItem,
  ListSubheader,
} from "react-md";

const HitungPPh2126Form = ({
  envStore,
  match,
  history,
  sipStore,
  navigationStore,
  modalStore
}) => {
  const [data, setData] = useState({ loading: true, content: {} });
  const [entitasList, setEntitasList] = useState([]);
  const [checked, setChecked] = useState({});
  const [entitasMap, setEntitasMap] = useState({});

  // initial data
  useEffect(() => {
    _getEntitasList();
  }, []);

  async function _getEntitasList() {
    setData({ loading: true, content: {} });
    setEntitasList([]);
    var pemotongan = localStorage["sip-menu-pph"]
      ? JSON.parse(localStorage["sip-menu-pph"])
      : {};
    var res = await EntitasService.http.get(
      "/pemotongan-pph2126s/status/" + pemotongan.id
    );
    setEntitasList(res.data);
    setData({ loading: false, content: {} });
  }

  const _submit = async () => {
    var pemotongan = localStorage["sip-menu-pph"]
      ? JSON.parse(localStorage["sip-menu-pph"])
      : {};
    modalStore.showConfirm({
      title: "Hitung",
      children: 'Apakah anda yakin akan menghitung Entitas terpilih?',
      onSubmit: async (callback) => {
        var res = await EntitasService.http.post(
          "/pemotongan-pph2126s/hitung", {pemotonganId:pemotongan.id, entitasIds: Object.keys(checked).filter(p => checked[p])}
        );
        iziToast.success({title: 'Berhasil', message: 'Data sudah di hitung.'})

        _getEntitasList()

        callback()
      },
    });

    console.log(checked )
  }

  return (
    <CustomWrapper
      noValidate
      customRules={{
        tahunPajak: ["required", "min:4"],
      }}
      loading={data.loading}
      backTo={`/pph-menu`}
      baseId="mod-form-sample"
      title={"Hitung"}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={true}
    >
      {!data.loading && <div
        style={{ width: "100%", maxWidth: "800px", margin: "0px auto" }}
        className="mpk-form-wrapper mpk-paper mpk-padding-N padding-all mpk-animation slide-in "
      >
        <List>
          {/* <ListSubheader>Folders</ListSubheader> */}
          {entitasList.map((p) => {
            return (
              <ListItem
                key={p.id}
                id={p.id}
                secondaryText={`${p.entitas.kodeEntitas}`}
                leftAddonType="avatar"
                leftAddon={
                  <Checkbox
                    id="checkbox-1"
                    name="checkboxes"
                    value={checked[p.entitas.id]}
                    onChange={(e) =>
                      setChecked((c) => {
                        c[p.entitas.id] = e.target.checked;
                        return c;
                      })
                    }
                  />
                }
                rightAddonPosition="middle"
                rightAddon={p.statusData == 'SUDAH HITUNG'?<Chip style={{background: '#43a047'}}>{p.statusData}</Chip>:<Chip>{p.statusData}</Chip>}
              >
                {p.entitas.nama}
              </ListItem>
            );
          })}
        </List>

        <div className="mpk-flex direction-row" style={{padding: 12}}>
          <div className="flex" />
          <Button id="contained-button-1" theme="primary" themeType="contained" onClick={_submit}>
            Generate
          </Button>
        </div>
      </div>}
    </CustomWrapper>
  );
};

export default inject(
  "sipStore",
  "navigationStore",
  "modalStore"
)(observer(HitungPPh2126Form));
