import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import onp from '../libs/custom/onp';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import {
  SlipGajiTable, SlipGajiForm,
  PPh21FinalTable, PPh21FinalForm,
  PPh21TidakFinalTable, PPh21TidakFinalForm,
  PPh21MasaTable, PPh21MasaForm, LogExportTable,
  PPh21A1Table, PPh21A1Form, HitungPPh2126Form
} from '../modules';
import { AppBar, AppBarAction, AppBarNav, AppBarTitle } from "@react-md/app-bar";
import { Button, FontIcon } from 'react-md'
import { List, ListItem } from "@react-md/list";



const Onpremise = ({history}) => {
  var label = JSON.parse(localStorage.getItem('sip-menu-pph')?localStorage.getItem('sip-menu-pph'):"{}")
  console.log(label, 'asd')
  return (
    <PageWrapper
      sidebarCollapsible={false}
      sidebarMenu={sidebarMenu}
      sidebarHeader={
        <List style={{width:'100%', background: '#ff7961'}} >
          <ListItem
          leftAddon={<FontIcon iconClassName='mdi mdi-arrow-left' onClick={()=>  history.push('/onpremise/pph')} />}
          onClick={()=> history.push('/onpremise/dashboard')} secondaryText={label.tahunPajak+" - "+label.masaPajak}>
          {label.entitas.nama} - {label.entitas.npwp}
          </ListItem>
        </List>
      }
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/pph-menu/hitung'
            render={props => (
              <HitungPPh2126Form {...props} />
            )}
          />
          <Route
            path='/pph-menu/slip/:id'
            render={props => (
              <SlipGajiForm {...props}/>
            )}
          />
          <Route
            path='/pph-menu/slip'
            render={props => (
              <SlipGajiTable {...props} />
            )}
          />
          <Route
            path='/pph-menu/masa/:id'
            render={props => (
              <PPh21MasaForm {...props}/>
            )}
          />
          <Route
            path='/pph-menu/masa'
            render={props => (
              <PPh21MasaTable {...props}/>
            )}
          />
          <Route
            path='/pph-menu/a1/:id'
            render={props => (
              <PPh21A1Form {...props}/>
            )}
          />
          <Route
            path='/pph-menu/a1'
            render={props => (
              <PPh21A1Table {...props}/>
            )}
          />
          <Route
            path='/pph-menu/tidak-final/:id'
            render={props => (
              <PPh21TidakFinalForm {...props}/>
            )}
          />
          <Route
            path='/pph-menu/tidak-final'
            render={props => (
              <PPh21TidakFinalTable {...props}/>
            )}
          />
          <Route
            path='/pph-menu/final/:id'
            render={props => (
              <PPh21FinalForm {...props}/>
            )}
          />
          <Route
            path='/pph-menu/final'
            render={props => (
              <PPh21FinalTable {...props}/>
            )}
          />
          <Route
            path='/pph-menu/log-export'
            render={props => (
              <LogExportTable {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

const sidebarMenu = [
{
  label: 'Menu Utama',
  children: [
    {
      label: 'Hitung',
      index: 'daftar-hitung daftar-hitung',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/hitung',
      childPath: []
    },
    {
      label: 'Daftar Penggajian',
      index: 'daftar-penggajian daftar-penggajian',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/slip',
      childPath: []
    },
    {
      label: 'PPh 21 Masa',
      index: 'masa, masa',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/masa',
      childPath: []
    },
    {
      label: 'PPh 21 A1',
      index: 'a1, a1',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/a1',
      childPath: []
    },
    {
      label: 'PPh 21 Tidak Final',
      index: 'tidak-final, tidak-final',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/tidak-final',
      childPath: []
    },
    {
      label: 'PPh 21 Final',
      index: 'final, final',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/final',
      childPath: []
    },
  ]
},{
  label: 'Log',
  children: [
    {
      label: 'Log Export',
      index: 'log-export log-export',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/log-export',
      childPath: []
    },
  ]
}]


export default onp({
  basePath: '/pph-menu'
})(Onpremise);
