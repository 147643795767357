import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../../libs/react-mpk/wrapper'
import Modal from '../../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './SummaryUnifikasi.service'
import moment from 'moment'
import { toast } from '../../../libs/react-mpk/services'
import { inject, observer } from 'mobx-react'
import Utils from "../../../libs/custom/Utils";

var _baseId = "mod-summary-unifikasi"

const SummaryUnifikasiTable = ({
  className       = '',
  showCommandbar  = true,
  history,
  sipStore,
  navigationStore
}) => {

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeleteData, setConfirmDeleteData] = useState({})

  function _closeConfirmDelete() {
    setConfirmDelete(false)
  }
  async function _submitConfirmDelete(callback) {
    var r = await service.delete(confirmDeleteData.id)
    toast.info(`Data ${confirmDeleteData.nama} telah di hapus.`); callback()
    setConfirmDelete(false)
    TableWrapper.reload(_baseId)
  }

  return (
    <>
    <TableWrapper
      baseId={_baseId}
      title='Summary Unifikasi'
      className={className}
      // hintMessage={t.translate('modules.table.hint.message')}
      hintIconClassName="mdi mdi-information"
      hintMore={t.translate('modules.table.hint.more')}
      defaultData={[]}
      defaultSortBy="kodeKop"
      columns={[
        {
          label: 'Kode KOP',
          searchable: true,
          key: 'kodeKop',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.kodeKop)
        },
        {
          label: 'Nama KOP',
          searchable: true,
          key: 'namaKop',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.namaKop)
        },
        {
          label: 'Jumlah',
          searchable: true,
          key: 'jumlah',
          type: TableWrapper.dataTypes.STRING,
          render: item => (Utils.numberFormat(item.nilaiPajakDigunggung+item.nilaiPajakPembelian+item.nilaiPajakPenjualan+item.nilaiPajakTransaksi))
        }
      ]}
      actions={[
        // new TableWrapper.action('Add', 'mdi mdi-plus', () => history.push('/onpremise/pemotongan-pph2126/baru'), true)
      ]}
      itemActions={[
      ]}
      onFetchData={ async query => {
        if(!query) query = {}
        var data = JSON.parse(localStorage.getItem('sip-unifikasi-pph'))
        if(sipStore.companyId) {
          // query['perusahaanId.equals'] = sipStore.companyId
          query['entitasId.equals'] = data.entitas.id
          query['bulan.equals'] = data.masaPajak
          query['tahun.equals'] = data.tahunPajak
        }
        return service.get(query)
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={false}
    />
    <Modal.Confirm
      // title="Hapus Data"
      visible={confirmDelete}
      onRequestClose={_closeConfirmDelete}
      onSubmit={_submitConfirmDelete}
    >Hapus data {confirmDeleteData.nama}?</Modal.Confirm>
    </>
  )
}

export default inject('sipStore', 'navigationStore')(observer(SummaryUnifikasiTable))
