import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import onp from '../libs/custom/onp';
import { PageWrapper } from '../libs/react-mpk/wrapper';
import {
  SlipGajiTable, SlipGajiForm,
  PPh21FinalTable, PPh21FinalForm,
  PPh21TidakFinalTable, PPh21TidakFinalForm,
  PPh21MasaTable, PPh21MasaForm, LogExportTable,
  PPh21A1Table, PPh21A1Form, HitungPPh2126Form
} from '../modules';
import { AppBar, AppBarAction, AppBarNav, AppBarTitle } from "@react-md/app-bar";
import { Button, FontIcon } from 'react-md'
import { List, ListItem } from "@react-md/list";
import PajakTerutangTable from '../modules/user/PajakTerutang/PajakTerutang.table';
import PajakTerutangGunggungTable from '../modules/user/PajakTerutang/PajakTerutangGunggung.table';
import PajakTerutangGunggungForm from '../modules/user/PajakTerutang/PajakTerutangGunggung.form';
import SummaryUnifikasiTable from '../modules/user/PajakTerutang/SummaryUnifikasi.table';



const Onpremise = ({history}) => {
  var label = JSON.parse(localStorage.getItem('sip-unifikasi-pph')?localStorage.getItem('sip-unifikasi-pph'):"{}")
  console.log(label, 'asd')
  return (
    <PageWrapper
      sidebarCollapsible={false}
      sidebarMenu={sidebarMenu}
      sidebarHeader={
        <List style={{width:'100%', background: '#ff7961'}} >
          <ListItem
          leftAddon={<FontIcon iconClassName='mdi mdi-arrow-left' onClick={()=>  history.push('/onpremise/pph')} />}
          onClick={()=> history.push('/onpremise/dashboard')} secondaryText={label.tahunPajak+" - "+label.masaPajak}>
          {label.entitas.nama} - {label.entitas.npwp}
          </ListItem>
        </List>
      }
    >
      <Router history={history}>
        <Switch>
          <Route
            path='/pph-unifikasi-menu/daftar-hitung'
            render={props => (
              <PajakTerutangTable key="/pph-unifikasi-menu/daftar-hitung" {...props} />
            )}
          />
          <Route
            path='/pph-unifikasi-menu/data-rincian-transaksi'
            render={props => (
              <PajakTerutangTable key="/pph-unifikasi-menu/data-rincian-transaksi"  {...props}/>
            )}
          />
          <Route
            path='/pph-unifikasi-menu/data-pph-digunggung/:id'
            render={props => (
              <PajakTerutangGunggungForm {...props}/>
            )}
          />
          <Route
            path='/pph-unifikasi-menu/data-pph-digunggung/'
            render={props => (
              <PajakTerutangGunggungTable {...props}/>
            )}
          />
          <Route
            path='/pph-unifikasi-menu/summary-unifikasi'
            render={props => (
              <SummaryUnifikasiTable {...props}/>
            )}
          />
        </Switch>
      </Router>
    </PageWrapper>
  )
}

const sidebarMenu = [
{
  label: 'Menu Utama',
  children: [
    {
      label: 'Data Hitung PPh Terutang',
      index: 'daftar-hitung daftar-hitung',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-unifikasi-menu/daftar-hitung',
      childPath: []
    },
    {
      label: 'Data Rincian Transaksi',
      index: 'daftar-penggajian daftar-penggajian',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-unifikasi-menu/data-rincian-transaksi',
      childPath: []
    },
    {
      label: 'Data PPh yang Digunggung',
      index: 'masa, masa',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-unifikasi-menu/data-pph-digunggung',
      childPath: []
    },
    {
      label: 'Summary PPh Terutang',
      index: 'a1, a1',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-unifikasi-menu/summary-unifikasi',
      childPath: []
    },
  ]
},{
  label: 'Log',
  children: [
    {
      label: 'Log Export',
      index: 'log-export log-export',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/log-export',
      childPath: []
    },
    {
      label: 'Log Import',
      index: 'log-export log-export',
      resourceUri: '',
      iconClassName: 'mdi mdi mdi-view-dashboard',
      path: '/pph-menu/log-export',
      childPath: []
    },
  ]
}]


export default onp({
  basePath: '/pph-unifikasi-menu'
})(Onpremise);
