import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./Akun.service";
import PerusahaanListService from "../Perusahaan/Perusahaan.service";
import ElemenTranskripService from "../../adminpajakku/ElemenTranskrip/ElemenTranskrip.service";
import TipeIndustriService from "../../adminpajakku/tipeIndustri/TipeIndustri.service";
import TipeTranskripService from "../../adminpajakku/ElemenTranskrip/TipeTranskrip.service";
import KelompokPenghasilanService from "../../adminpajakku/kelompokPenghasilan/KelompokPenghasilan.service";
import TipePenghasilan1771isService from "../../adminpajakku/tipePenghasilanLamp1771is/TipePenghasilanLamp1771is.service";
import TipePenghasilan1771ivsService from "../../adminpajakku/tipePenghasilanLamp1771ivs/TipePenghasilanLamp1771ivs.service";
import TipeBiayaLamp1771IIService from "../../adminpajakku/PengaturanBiayaSpt/PengaturanBiayaSpt.service";
import KategoriBiayaLamp1771IIService from "../../adminpajakku/KategoriLamp1771iis/KategoriLamp1771iis.service";
import AkunJenisService from "../../adminpajakku/AkunJenis/AkunJenis.service";
import AkunJenisPersediaanService from "../../adminpajakku/AkunJenis/AkunJenisPersediaan.service";
import TipeKoreksiFiskalService from "../../adminpajakku/tipeKoreksiFiskal/TipeKoreksiFiskal.service";
import { TabsWrapper } from "../../../libs/react-mpk/wrapper";
import AkunPengenaanPajakTable from "../AkunPengenaanPajak/AkunPengenaanPajak.table";
import TableSample from "../../TableSample";
import {
  ID_CONS,
  KELOMPOK_PENGHASILAN_MAP,
  TIPE_TRANSKRIP,
  AKUN_JENIS_MAP,
  AKUN_JENIS_MAP_ID,
} from "../../../clientConstant";
import Utils from "../../../libs/custom/Utils";
// import AkunPajakTable from '../AkunPajak/AkunPajak.table'

const AkunForm = ({ envStore, match, history, sipStore, navigationStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({ loading: true, content: {} });
  const [perusahaanList, setPerusahaanList] = useState([]);
  const [perusahaanMap, setPerusahaanMap] = useState({});
  const [AkunList, setAkunList] = useState([]);
  const [elemenTranskripList, setElemenTranskripList] = useState([]);
  const [elemenTranskripMap, setElemenTranskripMap] = useState({});
  const [tipeTranskripList, setTipeTranskripList] = useState([]);
  const [kelompokPenghasilanList, setKelompokPenghasilanList] = useState([]);
  const [tipePenghasilan1771iList, setTipePenghasilan1771iList] = useState([]);
  const [tipePenghasilan1771ivList, setTipePenghasilan1771ivList] = useState(
    []
  );
  const [tipeBiayaLamp1771IIList, setTipeBiayaLamp1771IIList] = useState([]);
  const [kategoriBiayaLamp1771IIList, setKategoriBiayaLamp1771IIList] =
    useState([]);
  const [akunJenisList, setAkunJenisList] = useState([]);
  const [akunJenisPersediaanList, setAkunJenisPersediaanList] = useState([]);
  const [tipeKoreksiFiskalList, setTipeKoreksiFiskalList] = useState([]);

  const [industriMap, setIndustriMap] = useState({});

  const [penghasilanLabel, setPenghasilanLabel] = useState("");
  const [persediaanLabel, setPersediaanLabel] = useState("");
  const [akunJenis2List, setAkunJenis2List] = useState([]);
  const [dataUp, setDataUp] = useState([]);

  // initial data
  useEffect(() => {
    async function initData() {
      var pMap = await _getPerusahaanList();
      await _getIndustri();
      await _getTipeTranskripList();

      await _getKelompokPenghasilanList();
      await _getTipePenghasilan1771iList();
      // await _getTipePenghasilan1771ivList()
      await _getTipeBiayaLamp1771IIList();
      await _getKategoriBiayaLamp1771IIList();
      await _getAkunJenisList();
      // await _getAkunJenisPersediaanList()
      await _getTipeKoreksiFiskalList();
      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            perusahaanId: sipStore.companyId,
            tarifs: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      if (res.data) {
        if (res.data.kelompokPenghasilanId) {
          await _getTipePenghasilan1771ivList({
            "kelompokPenghasilanId.equals": res.data.kelompokPenghasilanId,
          });
        }
      }

      var eleMap = await _getElemenTranskripList({
        "tipeIndustriId.equals":
        pMap[res.data.perusahaanId].tipeIndustri.id,
        "tipeTranskripId.equals": res.data.tipeTranskripId,
      });

      if(res.data.akunJenisId) {
        await _getAkunJenisPersediaanList({
          "akunJenisId.equals": res.data.akunJenisId,
        }, res.data.akunJenisId, sipStore.company.tipeIndustri.id);
      }



      res.data = Utils.formSerialize(res.data);
      if (res.data.elemenTranskripId) {
        var arr = [{value:null, label: 'Kosong'}]
        if (
          res.data.tipeTranskripId == TIPE_TRANSKRIP.neracaAktiva &&
          eleMap[res.data.elemenTranskripId] &&
          eleMap[res.data.elemenTranskripId].tipeAkun &&
          (eleMap[res.data.elemenTranskripId].tipeAkun.id ==
            ID_CONS.TIPE_AKUN__AKTIVA_1 ||
            eleMap[res.data.elemenTranskripId].tipeAkun.id ==
              ID_CONS.TIPE_AKUN__AKTIVA_2 ||
              eleMap[res.data.elemenTranskripId].tipeAkun.id ==
              ID_CONS.TIPE_AKUN__AKTIVA_3)
        ) {
          arr.push(AKUN_JENIS_MAP["Akun Persediaan"])
            arr.push(AKUN_JENIS_MAP["Akun Aktiva Tetap"])
            arr.push(AKUN_JENIS_MAP["Akun Piutang Pajak"])
            arr.push(AKUN_JENIS_MAP["Akun Piutang Lainnya"])
            arr.push(AKUN_JENIS_MAP["Akun Akumulasi Penyusutan"])
        }


        if (
          eleMap[res.data.elemenTranskripId] &&
          eleMap[res.data.elemenTranskripId].tipeAkun
        ) {
          var tpAkun = eleMap[res.data.elemenTranskripId].tipeAkun;
          if (
            tpAkun.id == ID_CONS.TIPE_AKUN__MODAL_1 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__MODAL_2 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__MODAL_3
          ) {
            arr.push(AKUN_JENIS_MAP["Akun Laba Ditahan"]);
              arr.push(AKUN_JENIS_MAP["Akun List Modal"]);
              arr.push(AKUN_JENIS_MAP["Akun Deviden"]);
          }

          if (
            tpAkun.id == ID_CONS.TIPE_AKUN__HUTANG_1 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__HUTANG_2 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__HUTANG_3
          ) {
            arr.push(AKUN_JENIS_MAP["Akun Hutang Pajak"]);
              arr.push(AKUN_JENIS_MAP["Akun Hutang Lainnya"]);
          }

          //TODO IF TIPE AKUN == ASET, TIPE AKUN ASET GAK ADA

          if (
            tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_1 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_2 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_3
          ) {
            arr.push(AKUN_JENIS_MAP["Akun Beban Entertainment"])
            arr.push(AKUN_JENIS_MAP["Akun Beban Promosi"])
            arr.push(AKUN_JENIS_MAP["Akun Beban Penyusutan"])
          }
          if (
            tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_1 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_2 ||
            tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_3
          ) {
            arr.push(AKUN_JENIS_MAP["Akun Penyerahan Barang / Jasa"]);
          }

        }



        setAkunJenis2List(arr.map((d) => ({ value: d.id, label: d.nama })));
      }

      if(res.data.kelompokPenghasilanId) {
        setPenghasilanLabel(
          "Penghasilan " +
            KELOMPOK_PENGHASILAN_MAP[res.data.kelompokPenghasilanId].nama
        );

      }


      setIsEdit(true);
      setData({ loading: false, content: res.data });
      setDataUp(res.data)
    }
    initData();
  }, ["match.params.id"]);

  useEffect(() => {
    async function getEle() {
      if (data.perusahaanId) {
        await _getElemenTranskripList({
          "tipeIndustriId.equals":
            perusahaanMap[data.perusahaanId].tipeIndustri.id,
          "tipeTranskripId.equals": data.tipeTranskripId,
        });
      }
    }
  }, ["data.content"]);

  async function _getIndustri() {
    var res = await TipeIndustriService.get();
    setIndustriMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;

        return pv;
      }, {})
    );
  }

  async function _getElemenTranskripList(param) {
    if (!param) param = {};
    param.size = 10000;
    param["tipeBaris.equals"] = "KATEGORI";
    var res = await ElemenTranskripService.get(param);
    await setElemenTranskripMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setElemenTranskripList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );

    return res.data.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {})
  }

  async function _getTipeTranskripList() {
    var res = await TipeTranskripService.get();
    setTipeTranskripList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getKelompokPenghasilanList() {
    var res = await KelompokPenghasilanService.get();
    setKelompokPenghasilanList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getTipePenghasilan1771iList() {
    var res = await TipePenghasilan1771isService.get();
    setTipePenghasilan1771iList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getTipePenghasilan1771ivList(param) {
    if (!param) param = {};
    param.size = 1000;
    var res = await TipePenghasilan1771ivsService.get(param);
    setTipePenghasilan1771ivList(
      res.data
        .filter((x) => x.nama != "JENIS PENGHASILAN LAINNYA")
        .map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getTipeBiayaLamp1771IIList() {
    var res = await TipeBiayaLamp1771IIService.get();
    setTipeBiayaLamp1771IIList(
      res.data
        .filter(
          (x) =>
            !(
              x.nama.toLowerCase() ==
                "Pembelian bahan/barang dagangan".toLowerCase() ||
              x.nama.toLowerCase() == "Persediaan Awal".toLowerCase() ||
              x.nama.toLowerCase() == "Persediaan Akhir (-/-)".toLowerCase()
            )
        )
        .map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getKategoriBiayaLamp1771IIList() {
    var res = await KategoriBiayaLamp1771IIService.get();
    setKategoriBiayaLamp1771IIList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getAkunJenisList() {
    var res = await AkunJenisService.get();
    setAkunJenisList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  async function _getAkunJenisPersediaanList(param, akunJenisId, industriId) {
    if (!param) param = {};
    param.size = 1000;
    var res = await AkunJenisPersediaanService.get(param);

    if(akunJenisId === AKUN_JENIS_MAP["Akun Persediaan"].id && industriId === ID_CONS.TIPE_INDUSTRI__MANUFAKTUR) {
      res.data = res.data.filter((x) => x.id != "00000003-6b5e-21eb-8b01-f57c409cb931")
    }

    if(akunJenisId === AKUN_JENIS_MAP["Akun Persediaan"].id && industriId === ID_CONS.TIPE_INDUSTRI__DAGANG) {
      res.data = res.data.filter((x) => x.id == "00000003-6b5e-21eb-8b01-f57c409cb931")
    }

    setAkunJenisPersediaanList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getTipeKoreksiFiskalList() {
    var res = await TipeKoreksiFiskalService.get(10000);
    res.data.unshift({id: null, nama: 'Kosong'})
    setTipeKoreksiFiskalList(
      res.data.map((x) => ({ value: x.id, label: x.nama }))
    );
  }

  async function _getPerusahaanList() {
    var res = await PerusahaanListService.http.get('/my-perusahaans')

    await setPerusahaanMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setPerusahaanList(res.data.map((x) => ({ value: x.id, label: x.nama })));

    return res.data.reduce((pv, cv) => {
      pv[cv.id] = cv;
      return pv;
    }, {})
  }

  async function _getAkunList() {
    var res = await service.get({ size: 10000, "tipeAkun.equals": "Cabang" });
    setAkunList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  const form = (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      className="mpk-padding-N padding-bottom"
      showCommandbar={match.params.id == "baru"}
      backTo={`/onpremise/coa`}
      baseId="mod-form-sample"
      title={""}
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        setDataUp(newObj);

        if (key == "tipeTranskripId" && newObj.perusahaanId != null) {
          await _getElemenTranskripList({
            "tipeIndustriId.equals":
              perusahaanMap[newObj.perusahaanId].tipeIndustri.id,
            "tipeTranskripId.equals": newObj.tipeTranskripId,
          });
        }

        if (key == "kelompokPenghasilanId" && newObj.kelompokPenghasilanId) {
          await _getTipePenghasilan1771ivList({
            "kelompokPenghasilanId.equals": newObj.kelompokPenghasilanId,
          });
        }

        if (key == "kelompokPenghasilanId") {
          setPenghasilanLabel(
            "Penghasilan " + KELOMPOK_PENGHASILAN_MAP[value].nama
          );
        }

        if(key == 'elemenTranskripId') {
          var arr = [{value:null, label: 'Kosong'}]
          if(newObj.tipeTranskripId == TIPE_TRANSKRIP.neracaAktiva && elemenTranskripMap[newObj.elemenTranskripId] && elemenTranskripMap[newObj.elemenTranskripId].tipeAkun && (elemenTranskripMap[newObj.elemenTranskripId].tipeAkun.id == ID_CONS.TIPE_AKUN__AKTIVA_1 || elemenTranskripMap[newObj.elemenTranskripId].tipeAkun.id == ID_CONS.TIPE_AKUN__AKTIVA_2 || elemenTranskripMap[newObj.elemenTranskripId].tipeAkun.id == ID_CONS.TIPE_AKUN__AKTIVA_3)) {
            console.log(sipStore.company.tipeIndustri.id, 'asdasd')
            if(sipStore.company.tipeIndustri.id === ID_CONS.TIPE_INDUSTRI__MANUFAKTUR || sipStore.company.tipeIndustri.id === ID_CONS.TIPE_INDUSTRI__DAGANG) {
              arr.push(AKUN_JENIS_MAP["Akun Persediaan"])
            }

            arr.push(AKUN_JENIS_MAP["Akun Aktiva Tetap"])
            arr.push(AKUN_JENIS_MAP["Akun Piutang Pajak"])
            arr.push(AKUN_JENIS_MAP["Akun Piutang Lainnya"])
            arr.push(AKUN_JENIS_MAP["Akun Akumulasi Penyusutan"])
          }

          if (
            elemenTranskripMap[newObj.elemenTranskripId] &&
            elemenTranskripMap[newObj.elemenTranskripId].tipeAkun
          ) {
            var tpAkun = elemenTranskripMap[newObj.elemenTranskripId].tipeAkun;
            if (
              tpAkun.id == ID_CONS.TIPE_AKUN__MODAL_1 ||
              tpAkun.id == ID_CONS.TIPE_AKUN__MODAL_2 ||
              tpAkun.id == ID_CONS.TIPE_AKUN__MODAL_3
            ) {
              arr.push(AKUN_JENIS_MAP["Akun Laba Ditahan"]);
              arr.push(AKUN_JENIS_MAP["Akun List Modal"]);
              arr.push(AKUN_JENIS_MAP["Akun Deviden"]);
            }

            if (
              tpAkun.id == ID_CONS.TIPE_AKUN__HUTANG_1 ||
              tpAkun.id == ID_CONS.TIPE_AKUN__HUTANG_2 ||
              tpAkun.id == ID_CONS.TIPE_AKUN__HUTANG_3
            ) {
              arr.push(AKUN_JENIS_MAP["Akun Hutang Pajak"]);
              arr.push(AKUN_JENIS_MAP["Akun Hutang Lainnya"]);
            }

            //TODO IF TIPE AKUN == ASET, TIPE AKUN ASET GAK ADA

            if(tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_1 ||tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_2 ||tpAkun.id == ID_CONS.TIPE_AKUN__BEBAN_3 ) {
              arr.push(AKUN_JENIS_MAP["Akun Beban Entertainment"])
              arr.push(AKUN_JENIS_MAP["Akun Beban Promosi"])
              arr.push(AKUN_JENIS_MAP["Akun Beban Penyusutan"])
            }
            if (
              tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_1 ||
              tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_2 ||
              tpAkun.id == ID_CONS.TIPE_AKUN__PENDAPATAN_3
            ) {
              arr.push(AKUN_JENIS_MAP["Akun Penyerahan Barang / Jasa"]);
            }
          }

          setAkunJenis2List(arr.map(d => ({value: d.id, label:d.nama})))
        }

        if (key == "akunJenisId") {
          if(value) {
            var paramAkunJenisPersediaan = {
              "akunJenisId.equals": value,
            };

            if (value == AKUN_JENIS_MAP["Akun Persediaan"].id) {
              paramAkunJenisPersediaan["jenisUsahaId.equals"] = elemenTranskripMap[newObj.elemenTranskripId].tipeIndustri && industriMap[
                elemenTranskripMap[newObj.elemenTranskripId].tipeIndustri.id
              ]
                ? industriMap[
                    elemenTranskripMap[newObj.elemenTranskripId].tipeIndustri.id
                  ].jenisUsahaId
                : null;
            }

            await _getAkunJenisPersediaanList(paramAkunJenisPersediaan, value, sipStore.company.tipeIndustri.id);

            setPersediaanLabel("Jenis " + AKUN_JENIS_MAP_ID[value].nama);

            if (value == AKUN_JENIS_MAP["Akun Hutang Pajak"].id) {
              setPersediaanLabel("Kategori Hutang Pajak");
            }
            if (value == AKUN_JENIS_MAP["Akun Piutang Pajak"].id) {
              setPersediaanLabel("Kategori Piutang Pajak");
            }
          }

        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: "Perusahaan",
          key: "perusahaanId",
          type: "text",
          width: "100%",
          style: { marginRight: "2%" },
          required: true,
          validation: "required",
          options: perusahaanList,
          disabled: sipStore.companyId != null,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nomor",
          key: "nomor",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          validation: "required",
          required: true,
          disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: "Nama",
          key: "nama",
          type: "text",
          width: "49%",
          // style: {marginRight: '2%'},
          validation: "required",
          required: true,
          disabled: isEdit,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Pilih Elemen",
          key: "tipeTranskripId",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          required: true,
          options: tipeTranskripList,
          validation: "required",
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kategori Elemen Transkrip",
          key: "elemenTranskripId",
          type: "text",
          width: "49%",
          // style: {marginRight: '2%'},
          required: true,
          options: elemenTranskripList,
          validation: "required",
        },

        {
          inputType: inputTypes.SELECT,
          label: "Default Kategori Pendapatan",
          key: "kelompokPenghasilanId",
          type: "text",
          width:
            penghasilanLabel && tipePenghasilan1771ivList.length > 0
              ? "49%"
              : "100%",
          style: { marginRight: "2%" },
          required: true,
          options: kelompokPenghasilanList,
          show: (d) =>
            elemenTranskripMap[d.elemenTranskripId] &&
            (elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
              ID_CONS.TIPE_AKUN__PENDAPATAN_1 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_2 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_3),
          // validation: 'required'
        },
        {
          inputType: inputTypes.SELECT,
          label: penghasilanLabel,
          key: "tipePenghasilanLamp1771IVId",
          type: "text",
          width: "49%",
          // style: {marginRight: '2%'},
          options: tipePenghasilan1771ivList,
          show: (d) =>
            elemenTranskripMap[d.elemenTranskripId] &&
            (elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
              ID_CONS.TIPE_AKUN__PENDAPATAN_1 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_2 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_3) &&
            d.kelompokPenghasilanId &&
            tipePenghasilan1771ivList.length > 0,
        },

        {
          inputType: inputTypes.SELECT,
          label: "Kategori Lampiran 1",
          key: "tipePenghasilanLamp1771IId",
          type: "text",
          width: "100%",
          // style: {marginRight: '2%'},
          required: true,
          show: (d) => {
            // console.log(d.elemenTranskripId, "asd", elemenTranskripMap);
            if (elemenTranskripMap[d.elemenTranskripId]) {
              // console.log(
              //   elemenTranskripMap[d.elemenTranskripId].tipeAkun.id,
              //   "elemenTranskripMap[d.elemenTranskripId].tipeAkunId"
              // );
            }

            return (
              d.tipeTranskripId == ID_CONS.TIPE_TRANSKRIP__LABA_RUGI &&
              elemenTranskripMap[d.elemenTranskripId] &&
              elemenTranskripMap[d.elemenTranskripId].tipeAkun &&
              (elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_1 ||
                elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                  ID_CONS.TIPE_AKUN__PENDAPATAN_2 ||
                elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                  ID_CONS.TIPE_AKUN__PENDAPATAN_3)
            );
          },
          options: tipePenghasilan1771iList,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Jenis Biaya Lampiran 2 SPT Tahunan",
          key: "tipeBiayaLamp1771IIId",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          required: true,
          options: tipeBiayaLamp1771IIList,
          show: (d) => {
            return (
              d.tipeTranskripId == ID_CONS.TIPE_TRANSKRIP__LABA_RUGI &&
              elemenTranskripMap[d.elemenTranskripId] &&
              elemenTranskripMap[d.elemenTranskripId].tipeAkun &&
              (elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__BEBAN_1 ||
                elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                  ID_CONS.TIPE_AKUN__BEBAN_2 ||
                elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                  ID_CONS.TIPE_AKUN__BEBAN_3)
            );
          },
        },
        {
          inputType: inputTypes.SELECT,
          label: "Kategori",
          key: "kategoriBiayaLamp1771IIId",
          type: "text",
          width: "49%",
          // style: {marginRight: '2%'},
          required: true,
          options: kategoriBiayaLamp1771IIList,
          show: (d) => {
            return (
              d.tipeTranskripId == ID_CONS.TIPE_TRANSKRIP__LABA_RUGI &&
              elemenTranskripMap[d.elemenTranskripId] &&
              elemenTranskripMap[d.elemenTranskripId].tipeAkun &&
              (elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__BEBAN_1 ||
                elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                  ID_CONS.TIPE_AKUN__BEBAN_2 ||
                elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                  ID_CONS.TIPE_AKUN__BEBAN_3)
            );
          },
        },
        {
          inputType: inputTypes.SELECT,
          label: "Gunakan Akun Sebagai",
          key: "akunJenisId",
          type: "text",
          width: akunJenisPersediaanList.length > 0 ? "49%" : "49%",
          style: { marginRight: "2%" },
          // required: true,
          options: akunJenis2List,
          show: akunJenis2List.length > 0,
          // show: (d)=> {
          //   return perusahaanMap[d.perusahaanId] && (perusahaanMap[d.perusahaanId].tipeIndustri.id == ID_CONS.TIPE_INDUSTRI__MANUFAKTUR || perusahaanMap[d.perusahaanId].tipeIndustri.id == ID_CONS.TIPE_INDUSTRI__DAGANG)
          // }
        },
        {
          inputType: inputTypes.SELECT,
          label: persediaanLabel,
          key: "akunJenisPersediaanId",
          type: "text",
          width: "49%",
          // style: {marginRight: '2%'},
          // required: true,
          options: akunJenisPersediaanList,
          show: dataUp.akunJenisId != null && dataUp.akunJenisId !== '' && akunJenisPersediaanList.length > 0,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Default Koreksi Fiskal",
          key: "tipeKoreksiFiskalId",
          type: "text",
          width: "100%",
          // style: {marginRight: '2%'},
          // required: true,
          options: tipeKoreksiFiskalList,
          // validation: 'required'
          show: (d) =>
            elemenTranskripMap[d.elemenTranskripId] &&
            (elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
              ID_CONS.TIPE_AKUN__BEBAN_1 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__BEBAN_2 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__BEBAN_3 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_1 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_2 ||
              elemenTranskripMap[d.elemenTranskripId].tipeAkun.id ==
                ID_CONS.TIPE_AKUN__PENDAPATAN_3),
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: 'Set sebagai akun penggajian',
          key: "akunPenggajian",
          type: "text",
          width: "49%",
        },
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        try {
          data = Utils.formNormalize(data);
          if(data.akunJenisId == "") {
            data.akunJenisId = null;
          }
          if(data.tipeKoreksiFiskalId == "") {
            data.tipeKoreksiFiskalId = null;
          }
          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);
          history.push("/onpremise/coa");
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );

  if (match.params.id != "baru") {
    return (
      <TabsWrapper
        title="Akun"
        showCommandbar={true}
        baseId="mod-details-sample-aad"
        actions={[
          {
            label: "Back",
            iconClassName: "mdi mdi-arrow-left",
            onClick: () => history.push("/onpremise/coa"),
          },
        ]}
        onChange={(tab, d) => {

          navigationStore.redirectTo(
            `/onpremise/coa/${match.params.id}/${tab.key}`
          );
          d();
        }}
        tabs={[
          {
            label: "Data",
            key: "form",
            render: form,
          },
          {
            label: "Pengenaan Pajak",
            key: "akun-pajak",
            render: (
              <AkunPengenaanPajakTable
                history={history}
                akunId={match.params.id}
              />
            ),
          },
        ]}
        // onChange={ tab => {
        //   // navigationStore.redirectTo(`/${envStore.env.applicationType}/details/${tab.key}`)
        // }}
      />
    );
  } else {
    return form;
  }
};

export default inject("sipStore", "navigationStore")(observer(AkunForm));
