import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './ReturPenjualanAkun.service'
import KategoriPPh25Service from '../../adminpajakku/kategoriPph25/KategoriPph25.service'
import TipePengecualianPajakService from '../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service'
import Utils from '../../../libs/custom/Utils'
import AkunService from '../../master/Akun/Akun.service'
import KodeObjekPajakService from '../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service'
import AsetService from '../Aset/Aset.service'
import TransaksiService from './Transaksi.service'
import LawanTransaksiService from '../../master/LawanTransaksi/LawanTransaksi.service'
import EntitasService from '../../master/Entitas/Entitas.service'
import ItemService from '../../master/Item/Item.service'
import { toast } from '../../../libs/react-mpk/services'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import TransaksiPajakTerutangTable from './TransaksiPajakTerutang.table'
import VTransaksiItemService from './VTransaksiItem.service'
import TransaksiJurnalService from './TransaksiJurnal.service'

const ReturPenjualanAkunForm = ({envStore, match, history, sipStore, modalStore, navigationStore}) => {
  const { inputTypes, definition } = FormWrapper
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({loading: true, content: {}})
  const [entitasList, setEntitasList] = useState([])
  const [lawanTransaksiList, setLawanTransaksiList] = useState([])
  const [lawanTransaksiMap, setLawanTransaksiMap] = useState({})
  const [itemList, setItemList] = useState([])
  const [itemMap, setItemMap] = useState({})
  const [vTransaksiItemList, setVTransaksiItemList] = useState([])
  const [transaksiJurnalList, setTransaksiJurnalList] = useState([])
  const [transaksiJurnalMap, setTransaksiJurnalMap] = useState({})

  // initial data
  useEffect(() => {
    async function initData() {
      await _getLawanTransaksi()
      await _getEntitasList()
      await _getItemList()
      await _getVTransaksiItemList()
      await _getTransaksiJurnalList()
      if(match.params.id == 'baru') {
        setData({loading: false, content: {perusahaanId: sipStore.companyId, dipungut: false, dpp:'0', ppn:'0', ppnbm:'0', pph: '0', transaksiAktivaTetapAkun__nilai: '0', transaksiAktivaTetapPengecualians: []}})
        return true
      }
      const res = await service.getOne(match.params.id)
      // res.data.items = res.data.items.map(x => Utils.formSerialize(x))
      res.data = Utils.formSerialize(res.data)
      setIsEdit(true)
      setData({loading: false, content:res.data})
    }
    initData()
  }, ['match.params.id'])

  async function _getLawanTransaksi() {
    var res = await LawanTransaksiService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setLawanTransaksiList(res.data.map(x => ({value: x.id, label: x.nama})))
    setLawanTransaksiMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv
      return pv
    }, {}))
  }

  async function _getEntitasList() {
    var res = await EntitasService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setEntitasList(res.data.map(x => ({value: x.id, label: x.nama})))
  }

  async function _getItemList() {
    var res = await ItemService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setItemList(res.data.map(x => ({value: x.id, label: x.kode?`${x.kode} - ${x.nama}`: `${x.nama}`})))
    setItemMap(res.data.reduce((pv, cv)=> {
      pv[cv.id] = cv;
      return pv
    }, {}))
  }

  async function _getVTransaksiItemList() {
    var res = await VTransaksiItemService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setVTransaksiItemList(res.data.map(x => ({value: x.id, label: x.nomor})))
  }

  async function _getTransaksiJurnalList() {
    var res = await TransaksiJurnalService.get({size: 10000, 'perusahaanId.equals': sipStore.companyId})
    setTransaksiJurnalList(res.data.map(x => ({value: x.id, label: x.akun?`${x.akun.nomor} - ${x.akun.nama}`: `${x.akun}`})))
    setTransaksiJurnalMap(res.data.reduce((pv, cv) => {
      pv[cv.id] = cv
      return pv
    },{}))
  }

  return (
    <FormWrapper
      noValidate
      // showCommandbar={match.params.id == 'baru'}
      loading={data.loading}
      actions={[
        {
          label: 'Generate',
          iconClassName: 'mdi mdi-inbox-full',
          onClick: async () => {
            modalStore.showConfirm({
              children: 'Generate data pajak terutang?',
              onRequestClose: ()=> {modalStore.closeConfirm()},
              onSubmit: async (callback) => {
                await service.http.get(`/pos/${match.params.id}/generate`);
                toast.success(`Data pajak terutang telah di generate.`);
                callback()
              }
            })
          },
          show: match.params.id == 'baru'
        }
      ]}
      backTo={'/onpremise/retur-penjualan'}
      baseId="mod-form-sample"
      title='Retur Penjualan Akun'
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={(newObj, key, value, parentKey, indexAtParent) => {
        if(key === 'posLawanTransaksi__sumberId') {
          var lt = lawanTransaksiMap[value]
          if(lt) {
            newObj.posLawanTransaksi__jenisPemungutPPh22 = ""
            newObj.posLawanTransaksi__nama = lt.nama
            newObj.posLawanTransaksi__nik = lt.nik
            newObj.posLawanTransaksi__npwp = lt.npwp
            newObj.posLawanTransaksi__pemungut = ""
            newObj.posLawanTransaksi__sumber = "LawanTransaksi"
            newObj.posLawanTransaksi__sumberId = lt.id
            newObj.posLawanTransaksi__tipeWajibPajak = lt.tipeWajibPajak
            newObj.posLawanTransaksi__domisiliWajibPajak = lt.domisiliWajibPajak
          }
        }

        if(key === 'itemId') {
          var item = itemMap[value]
          if(item) {
            newObj.items[indexAtParent].nama = item.nama
            newObj.items[indexAtParent].kode = item.kode
          }
        }

        if(key == 'hargaSatuan' || key == 'qty' || key == 'diskon') {
          newObj.items[indexAtParent][key] = parseInt(value)
          newObj.items[indexAtParent].hargaTotal = (parseInt(newObj.items[indexAtParent].qty)*parseInt(newObj.items[indexAtParent].hargaSatuan))-parseInt(newObj.items[indexAtParent].diskon)
          newObj.items[indexAtParent].dpp =newObj.items[indexAtParent].hargaTotal
        }

        if(parentKey == 'jurnals') {
          if(key == 'jurnals__id') {
            var transaksiJurnal = transaksiJurnalMap[value]
            newObj.jurnals[indexAtParent].akun__nama = transaksiJurnal.nama
          }
        }
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.SELECT,
          label: 'Entitas',
          key: 'entitasId',
          type: 'text',
          required: true,
          options: entitasList,
          validation: 'required',
          // disabled: isEdit,
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Nomor Transaksi (GL)',
          key: 'nomorRetur',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          disabled: isEdit,
          validation: 'required'
        },{
          inputType: inputTypes.DATEPICKER,
          label: 'Tanggal',
          key: 'tanggal',
          width: '49%',
          // style: {marginRight: '2%'},
          validation: 'required'
        },
        {
          inputType: inputTypes.INPUT,
          label: 'Keterangan',
          key: 'keterangan',
          type: 'text',
          width: '100%'
        },
        {
          inputType: inputTypes.DIVIDER,
          label: '# PILIH LAWAN TRANSAKSI',
        },
        {
          inputType: inputTypes.SELECT,
          label: 'Lawan Transaksi',
          key: 'posLawanTransaksi__sumberId',
          type: 'text',
          // width: '49%',
          // style: {marginRight: '2%'},
          disabled: isEdit,
          options: lawanTransaksiList,
          data: lawanTransaksiList
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '##.###.###.#-###.###',
          maskChar: '_',
          maskValue: 'string',
          label: 'NPWP Lawan Transaksi',
          key: 'posLawanTransaksi__npwp',
          type: 'text',
          width: '49%',
          style: {marginRight: '2%'},
          validation: 'required',
          disabled: true
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          mask: '################',
          maskChar: '_',
          maskValue: 'string',
          label: 'NIK Lawan Transaksi',
          key: 'posLawanTransaksi__nik',
          type: 'text',
          width: '49%',
          disabled: true,
          validation: [{required_if: ['posLawanTransaksi__tipeWajibPajak', "OP"]}],
          show: (d) => d.posLawanTransaksi__domisiliWajibPajak == 'DN'
        },
        {
          inputType: inputTypes.INPUT,
          // mask: '################',
          // maskChar: '_',
          // maskValue: 'string',
          label: 'Passport Lawan Transaksi',
          key: 'posLawanTransaksi__nik',
          type: 'text',
          width: '49%',
          disabled: true,
          validation: [{required_if: ['posLawanTransaksi__tipeWajibPajak', "OP"]}],
          show: (d) => d.posLawanTransaksi__domisiliWajibPajak == 'LN'
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# JURNAL TRANSAKSI ',
          key: 'jurnals',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 1500},
          // defaultData: {
          //   "pph": 0
          // },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Kode Akun',
              key: 'jurnals__id',
              theme: 'filled',
              style: {width:400},
              validation: 'required',
              options: transaksiJurnalList
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Nama Akun',
              key: 'akun__nama',
              type: 'text',
              theme: 'filled',
              required: true,
              style: {width: 400},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'Debit',
              key: 'debit',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'Kredit',
              key: 'kredit',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
          ]
        },
        {render: (d)=> {
          var totalPphJurnal = 0;
          if(d.jurnals) {
            d.jurnals.forEach((x) => {
              totalPphJurnal += parseInt(x.pph)
            })
          }
          return <div style={{margin: '20px', width: '100%', fontSize: '20px'}}>
            <hr/>
            <div className="mpk-flex align-start direction-row">Total : <div className="flex" />{Utils.numberFormat(totalPphJurnal)}</div>
          </div>
        }},
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# Nomor Referensi Piutang ',
          key: 'referensiPiutangs',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 1500},
          // defaultData: {
          //   "pph": 0
          // },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Kode Akun',
              key: 'jurnals__id',
              theme: 'filled',
              style: {width:500},
              validation: 'required',
              options: transaksiJurnalList
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Nama Akun',
              key: 'akun__nama',
              type: 'text',
              theme: 'filled',
              required: true,
              style: {width: 400},
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Nomor Referensi Piutang',
              key: 'nomorReferensiPiutang',
              type: 'text',
              theme: 'filled',
              validation: 'required',
              style: {width: 400},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'Nominal Pembayaran',
              key: 'nilai',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
          ]
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# Data Retur Penjualan (per item) ',
          key: 'returPenjualans',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 2500},
          // defaultData: {
          //   "pph": 0
          // },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Kode Akun',
              key: 'jurnals__id',
              theme: 'filled',
              style: {width:300},
              validation: 'required',
              options: transaksiJurnalList
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Nama Akun',
              key: 'akun__nama',
              type: 'text',
              theme: 'filled',
              required: true,
              style: {width: 300},
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Nomor Transaksi',
              key: 'nomorTransaksi',
              type: 'text',
              theme: 'filled',
              validation: 'required',
              style: {width: 300},
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Lawan Transaksi',
              key: 'lawanTransaksi',
              type: 'text',
              theme: 'filled',
              validation: 'required',
              style: {width: 300},
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Keterangan',
              key: 'keterangan',
              type: 'text',
              theme: 'filled',
              validation: 'required',
              style: {width: 300},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'DPP',
              key: 'dpp',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'PPN',
              key: 'ppn',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'PPnBM',
              key: 'ppnbm',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
          ]
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: '# Pajak Terutang ',
          key: 'pajakTerutangs',
          // hintMessage: 'Daftar Tarif',
          layout: 'table',
          style: {minWidth: 1800},
          // defaultData: {
          //   "pph": 0
          // },
          definitions: [
            {
              inputType: FormWrapper.inputTypes.SELECT,
              label: 'Kode Akun',
              key: 'jurnals__id',
              theme: 'filled',
              style: {width:400},
              validation: 'required',
              options: transaksiJurnalList
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Nama Akun',
              key: 'akun__nama',
              type: 'text',
              theme: 'filled',
               required: true,
              style: {width: 400},
            },
            {
              inputType: inputTypes.INPUT,
              label: 'Kode Objek Pajak',
              key: 'kodeObjekPajak',
              type: 'text',
              theme: 'filled',
              validation: 'required',
              style: {width: 400},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'DPP',
              key: 'dpp',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'PPN',
              key: 'ppn',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: 'PPnBM',
              key: 'ppnbm',
              theme: 'filled',
              thousandSeparator: '.',
              decimalSeparator: ',',
              isNumericString: true,
              validation: 'required',
              style: {width: 200},
            },
          ]
        },     
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {}
        try {
          data.transaksiId = match.params.transaksiId
          data = Utils.formNormalize(data)
          // data.transaksiAktivaTetapAkun.akun = {
          //   id: data.transaksiAktivaTetapAkun__akunid
          // }
          // data.items = data.items.map((x)=>  Utils.formNormalize(x))
          if(match.params.id == 'baru') {
            res = await service.post(data)
          } else {
            res = await service.put(data)
          }
          callback(t.translate('global.dataTelahDiSimpan'), false)
          history.push('/onpremise/retur-penjualan-akun')
        } catch(e) {
          callback(e, true, false)
        }
      }}
    />
  )
}

export default inject('envStore', 'sipStore', 'modalStore', 'navigationStore')(observer(ReturPenjualanAkunForm))
