import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { TabsWrapper } from '../../../libs/react-mpk/wrapper'
import PPh25Table from './PPh25.table'

const PPh25Wrapper = ({envStore, match, history, sipStore, navigationStore}) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleTabChange = (index) => {
    setCurrentIndex(index)
  }

  return <TabsWrapper
    title='Pajak Terutang PPh 25'
    showCommandbar={true}
    onActiveIndexChange={handleTabChange}
    activeIndex={currentIndex}
    baseId="mod-pajak-terutang"
    actions={[
      {label: 'Back', iconClassName:'mdi mdi-arrow-left', onClick:() => {
        history.goBack()
      }}
    ]}
    tabs={[
      {
        label: 'Data Hitung',
        key: 'data-hitung',
        render: <PPh25Table match={match} history={history} />
      }
    ]}
  />
}

export default inject('sipStore', 'navigationStore')(observer(PPh25Wrapper))
