import React, {useState} from 'react'
import { DataForm } from '..'
import t from 'counterpart'
import { cloneDeep, merge } from 'lodash'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

const Filter = ({
  baseId            = 'mpk-data-view-filter-id',
  defaultData       = null,
  visible           = false,
  onRequestClose    = () => {},
  onSubmit          = null,
  maxPeriodValue    = 3,
  maxPeriodUnit     = 'month',
  usePeriod         = true,
  definitions       = [],
  envStore
}) => {
  // const periodDefinitions = [
  //   {
  //     inputType: DataForm.inputTypes.DATE,
  //     label: t.translate('mpk.column.startDate'),
  //     key: 'startDate',
  //     type: 'date'
  //   },
  //   {
  //     inputType: DataForm.inputTypes.DATE,
  //     label: t.translate('mpk.column.endDate'),
  //     key: 'endDate',
  //     type: 'date'
  //   }
  // ]

  // const defaultPeriod = {
  //   startDate: moment(envStore.env.timestamp).add(-1*maxPeriodValue, maxPeriodUnit).format('YYYY-MM-DD'),
  //   endDate: moment(envStore.env.timestamp).format('YYYY-MM-DD')
  // }

  // const [defaultFilterData, setDefaultFilterData] = useState(defaultData ? merge(defaultPeriod, defaultData) : defaultPeriod)

  return (
    <DataForm
      baseId={`${baseId}`}
      title={t.translate('mpk.column.filter')}
      visible={visible}
      onRequestClose={onRequestClose}
      asDialog={true}
      onSubmit={onSubmit}
      defaultData={defaultData}
      definitions={definitions}
      disableFocusOnMount={true}
      resetDataOnVisible={false}
    />
  )
}

Filter.inputTypes = DataForm.inputTypes

export default inject('envStore')(observer(Filter))
