import React, { useEffect, useState } from "react";
import { inputTypes } from "../../../libs/react-mpk/config/constant";
import { FormWrapper } from "../../../libs/react-mpk/wrapper";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import service from "./TransaksiPajakTerutang.service";
import KategoriPPh25Service from "../../adminpajakku/kategoriPph25/KategoriPph25.service";
import TipePengecualianPajakService from "../../adminpajakku/tipePengecualianPajak/TipePengecualianPajak.service";
import Utils from "../../../libs/custom/Utils";
import AkunService from "../../master/Akun/Akun.service";
import KodeObjekPajakService from "../../adminpajakku/KodeObjekPajak/KodeObjekPajak.service";
import TransaksiService from "./Transaksi.service";
import TransaksiItemService from "./VTransaksiItem.service";
import { TP_PAJAK, KELOMPOK_PENGHASILAN_MAP } from "../../../clientConstant";
import ItemService from "../../master/Item/Item.service";
import { List, ListItem } from "@react-md/list";
import PPh21FinalService from "../PPh21Final/PPh21Final.service";
import PPh21TidakFinalService from "../PPh21TidakFinal/PPh21TidakFinal.service";
import {
  Caption,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@react-md/table";
import AkunPengenaanPajakService from "../../master/AkunPengenaanPajak/AkunPengenaanPajak.service";
import TransaksiPembelianService from "./TransaksiPembelian.service";
import moment from "moment";

const bpsMap = {};
var curTransaksiCek = {}

const TransaksiPajakTerutangForm = ({ envStore, match, history, sipStore }) => {
  const { inputTypes, definition } = FormWrapper;
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState({
    loading: true,
    content: { transaksiPajakTerutangPph21s: [] },
  });
  const [akunList, setAkun] = useState([]);
  const [itemPembelian, setItemPembelian] = useState([]);
  const [itemPembelianMap, setItemPembelianMap] = useState({});
  const [journalMap, setJournalMap] = useState({});
  const [kodeObjekPajakListAll, setKodeObjekPajakListAll] = useState([]);
  const [kodeObjekPajakList, setKodeObjekPajak] = useState([]);
  const [kodeObjekPajakMap, setKodeObjekPajakMap] = useState([]);
  const [transaksiItemList, setTransaksiItemList] = useState([]);
  const [transaksiItem, setTransaksiItem] = useState({});
  const [transaksiItemMap, setTransaksiItemMap] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [transaksi, setTransaksi] = useState({});
  const [itemList, setItemList] = useState([]);
  const [curTransaksiItem, setCurTransaksiItem] = useState({ items: [] });
  const [curData, setCurData] = useState({});
  const [curTransaksi, setCurTransaksi] = useState({});

  const [dataBps, setDataBps] = useState([]);

  // initial data
  useEffect(() => {
    async function initData() {
      // var map = await _getTransaksiItem()

      await _getKodeObjekPajak();

      // await _getItemList();

      if (match.params.transaksiId) {
        var transaksiRes = await _getAkun();
        if (transaksiRes.data && transaksiRes.data.tags) {
          transaksiRes.data.penjualan =
            transaksiRes.data.tags.search("TRANSAKSI_PENJUALAN") >= 0;
          setTransaksi(transaksiRes.data);
        }
      }

      if (match.params.pembelianId) {
        _getItem();
      }

      if (match.params.id == "baru") {
        setData({
          loading: false,
          content: {
            posId: match.params.postId,
            pembelianId: match.params.pembelianId,
            dipungut: true,
            dpp: "0",
            pph: "0",
            transaksiPajakTerutangAkun__nilai: "0",
            transaksiPajakTerutangPph21s: [],
            transaksiPajakTerutangPengecualians: [],
          },
        });
        return true;
      }
      const res = await service.getOne(match.params.id);
      res.data = Utils.formSerialize(res.data);
      const newObj = res.data;

      if(newObj.kodeObjekPajak && newObj.kodeObjekPajak.tipePajak.id === "00000001-5a04-21eb-ba01-132132205609") {
        if (newObj.jurnal && newObj.jurnal.akun && newObj.jurnal.akun.kelompokPenghasilanId) {
          if (
            newObj.jurnal.akun.kelompokPenghasilanId ===
            KELOMPOK_PENGHASILAN_MAP.Final.id
          ) {
            _getDataBps("Final");
          } else if (
            newObj.jurnal.akun.kelompokPenghasilanId ===
            KELOMPOK_PENGHASILAN_MAP["Tidak Final"].id
          ) {
            _getDataBps("Tidak Final");
          } else {
            _getDataBps()
          }
        } else {
          _getDataBps()
        }
      }

      if (res.data.posId) {
        _getTransaksiItem(res.data.posId);
      }

      // res.data.transaksiPajakTerutangAkun__akunid = res.data.transaksiPajakTerutangAkun.akun.id
      setIsEdit(true);
      setCurData(res.data);

      if (
        res.data.kodeObjekPajak &&
        (res.data.kodeObjekPajak.kategoriPendapatan == "Tidak Final" ||
          res.data.kodeObjekPajak.kategoriPendapatan == "Final")
      ) {
        await _getDataBps(res.data.kodeObjekPajak.kategoriPendapatan);
      }
      setData({ loading: false, content: res.data });
    }
    initData();
  }, ["match.params.id"]);

  async function _getAkun() {
    var res = await TransaksiService.getOne(match.params.transaksiId);
    setCurTransaksi(res.data);
    curTransaksiCek = res.data
    setJournalMap(
      res.data.transaksiJurnals.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setAkun(
      res.data.transaksiJurnals.map((x) => ({
        value: x.id,
        label: x.akun.nama,
      }))
    );

    return res;
  }

  async function _getItem() {
    var res = await TransaksiPembelianService.getOne(match.params.pembelianId);
    console.log("itemPembelian", res.data);
    res.data.transaksiLawanTransaksi = res.data.pembelianLawanTransaksi;
    setCurTransaksi(res.data);
    curTransaksiCek = res.data
    setItemPembelianMap(
      res.data.items.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setItemPembelian(
      res.data.items.map((x) => ({ value: x.id, label: x.nama }))
    );
    return res;
  }

  async function _getTransaksiItem(id) {
    var res = await TransaksiItemService.getOne(id);
    res.data.transaksiLawanTransaksi = res.data.posLawanTransaksi;

    curTransaksiCek = res.data.trans
    setCurTransaksiItem(res.data);
  }

  async function _getDataBps(jenisBp) {
    var params = {}
    console.log(curTransaksi, 'curTransaksi')
    if(curTransaksiCek.tanggal) {
      var m = moment(curTransaksiCek.tanggal)

      params['tahunPajak.equals'] = m.year()
      params['masaPajak.equals'] = m.month()+1
      params['entitasId.equals'] = curTransaksiCek.entitasId

      console.log(curTransaksiCek.entitasId, 'asdasd')
    }
    if(jenisBp) {
      if (jenisBp == "Final") {
        var res = await PPh21FinalService.get({ size: 1000, ...params });
        res.data.forEach((v) => {
          v.kopType = "F";
          bpsMap[v.id] = v;
        });
        setDataBps(
          res.data.map((x) => ({
            value: x.id,
            label: `${x.slipGajiPegawai.nama} (F) - Bruto ${x.bruto} PPh ${x.pph}`,
          }))
        );
      } else {
        var res = await PPh21TidakFinalService.get({ size: 1000, ...params });
        res.data.forEach((v) => {
          v.kopType = "TF";
          bpsMap[v.id] = v;
        });
        setDataBps(
          res.data.map((x) => ({
            value: x.id,
            label: `${x.slipGajiPegawai.nama} (TF) - Bruto ${x.bruto} PPh ${x.pph}`,
          }))
        );
      }
    } else {
      var res = await PPh21FinalService.get({ size: 1000, ...params });
      res.data.forEach((v) => {
        v.kopType = "F";
        bpsMap[v.id] = v;
      });
      var res2 = await PPh21TidakFinalService.get({ size: 1000, ...params });
      res2.data.forEach((v) => {
        v.kopType = "TF";
        bpsMap[v.id] = v;
      });

      setDataBps(
        [...res.data, ...res2.data].map((x) => ({
          value: x.id,
          label: `${x.slipGajiPegawai?x.slipGajiPegawai.nama:"-"} (${x.kopType}) - Bruto ${x.bruto} PPh ${x.pph}`,
        }))
      );


    }

  }

  async function _getKodeObjekPajak(filter) {
    var res = await KodeObjekPajakService.get({ size: 100000 });
    setKodeObjekPajakMap(
      res.data.reduce((pv, cv) => {
        pv[cv.id] = cv;
        return pv;
      }, {})
    );
    setKodeObjekPajakListAll(res.data);
    setKodeObjekPajak(
      res.data.map((x) => ({ value: x.id, label: x.kode + " - " + x.nama }))
    );
  }

  async function _getItemList() {
    var res = await ItemService.get({ size: 10000 });
    setItemList(res.data.map((x) => ({ value: x.id, label: x.nama })));
  }

  var backTo =
    "/onpremise/transaksi/" + match.params.transaksiId + "/pajak-terutang";
  if (match.params.posId) {
    backTo =
      "/onpremise/transaksi-penjualan/" +
      match.params.posId +
      "/pajak-terutang";
  }
  if (match.params.pembelianId) {
    backTo =
      "/onpremise/transaksi-pembelian/" +
      match.params.pembelianId +
      "/pajak-terutang";
  }

  return (
    <FormWrapper
      noValidate
      loading={data.loading}
      actions={[
        {
          label: "Reload",
          iconClassName: "mdi mdi-reload",
        },
      ]}
      backTo={backTo}
      baseId="mod-form-sample"
      title="Pajak"
      // hintMessage={t.translate('modules.form.hint.message')}
      hintShowIcon={false}
      defaultData={data.content}
      onChange={async (newObj, key, value, parentKey, indexAtParent) => {
        if (key == "jurnal__id") {
          newObj.jurnal__nilai = journalMap[value].nilai;
          newObj.jurnal = journalMap[value];

          if (newObj.jurnal && newObj.jurnal.akun && newObj.jurnal.akun.kelompokPenghasilanId) {
            if (
              newObj.jurnal.akun.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN_MAP.Final.id
            ) {
              setKodeObjekPajak(
                kodeObjekPajakListAll
                  .filter((x) => x.kategoriPendapatan == "Final")
                  .map((x) => ({ value: x.id, label: x.nama }))
              );
            } else if (
              newObj.jurnal.akun.kelompokPenghasilanId ===
              KELOMPOK_PENGHASILAN_MAP["Tidak Final"].id
            ) {
              setKodeObjekPajak(
                kodeObjekPajakListAll
                  .filter((x) => x.kategoriPendapatan == "Tidak Final")
                  .map((x) => ({ value: x.id, label: x.nama }))
              );
            } else {
              setKodeObjekPajak(
                kodeObjekPajakListAll.map((x) => ({
                  value: x.id,
                  label: x.nama,
                }))
              );
            }
          }

          var d = await AkunPengenaanPajakService.get({
            "akunId.equals": newObj.jurnal.akun.id,
            size: 1000,
          });
          newObj.pengenaanPajaks = d.data;
          if (newObj.pengenaanPajaks) {
            newObj.pengenaanPajaks.forEach((d, i) => {
              d.kopsMap = d.kops.reduce((pv, cv) => {
                pv[cv.kodeObjekPajak.id] = cv;
                return pv;
              }, {});
            });
          }
        }

        if(key == 'item__id' && dataBps.length <= 0) {
          _getDataBps()
        }

        if (key == "item__id") {
          newObj.item__dpp = itemPembelianMap[value].dpp;
          newObj.item = itemPembelianMap[value];

          // if(newObj.jurnal.akun.kelompokPenghasilanId) {
          //   if(newObj.jurnal.akun.kelompokPenghasilanId === KELOMPOK_PENGHASILAN_MAP.Final.id) {
          //     setKodeObjekPajak(kodeObjekPajakListAll.filter(x => x.kategoriPendapatan == 'Final').map(x => ({value: x.id, label: x.nama})))
          //     _getDataBps()
          //   } else if(newObj.jurnal.akun.kelompokPenghasilanId === KELOMPOK_PENGHASILAN_MAP['Tidak Final'].id) {
          //     setKodeObjekPajak(kodeObjekPajakListAll.filter(x => x.kategoriPendapatan == 'Tidak Final').map(x=> ({value: x.id, label: x.nama})))
          //     _getDataBps()
          //   } else {
          //     setKodeObjekPajak(kodeObjekPajakListAll.map(x=> ({value: x.id, label: x.nama})))
          //   }
          // }

          // var d = await AkunPengenaanPajakService.get({'akunId.equals': newObj.jurnal.akun.id, size: 1000})
          // newObj.pengenaanPajaks = d.data
          // if(newObj.pengenaanPajaks) {
          //   newObj.pengenaanPajaks.forEach((d, i)=> {
          //     d.kopsMap = d.kops.reduce((pv, cv)=> {
          //       pv[cv.kodeObjekPajak.id] = cv
          //       return pv
          //     }, {})
          //   })
          // }
        }

        if (key == "jenisDpp") {
          if (value == "Harga Jual") {
            newObj.dpp = newObj.jurnal__nilai;
            if (match.params.pembelianId) {
              newObj.dpp = newObj.item__dpp;
            }
            setIsDisabled(true);
          } else {
            newObj.dpp = "";
            setIsDisabled(false);
          }
        }

        if (key == "kodeObjekPajak__id") {
          newObj.kodeObjekPajak = kodeObjekPajakMap[value]
            ? kodeObjekPajakMap[value]
            : {};

            if(newObj.kodeObjekPajak && newObj.kodeObjekPajak.tipePajak.id === "00000001-5a04-21eb-ba01-132132205609") {
              if (newObj.jurnal && newObj.jurnal.akun && newObj.jurnal.akun.kelompokPenghasilanId) {
                if (
                  newObj.jurnal.akun.kelompokPenghasilanId ===
                  KELOMPOK_PENGHASILAN_MAP.Final.id
                ) {
                  _getDataBps("Final");
                } else if (
                  newObj.jurnal.akun.kelompokPenghasilanId ===
                  KELOMPOK_PENGHASILAN_MAP["Tidak Final"].id
                ) {
                  _getDataBps("Tidak Final");
                } else {
                  _getDataBps()
                }
              } else {
                _getDataBps()
              }
            }

        }

        if (key == "jurnal__id" || key == "kodeObjekPajak__id") {
          if (newObj.pengenaanPajaks && newObj.kodeObjekPajak) {
            var pengenaanPajaks = newObj.pengenaanPajaks.filter(
              (d) => d.kopsMap[newObj.kodeObjekPajak.id]
            )[0];
            if (pengenaanPajaks) {
              console.log(pengenaanPajaks);
              if (
                pengenaanPajaks.posisiAkun == "Pembeli" &&
                newObj.kodeObjekPajak.pengenaanPajak.pemotonganDilakukanOleh ==
                  "Pembeli"
              ) {
                newObj.tipeBebanPajak = "Pajak Terutang";
              }
              if (
                pengenaanPajaks.posisiAkun == "Pembeli" &&
                newObj.kodeObjekPajak.pengenaanPajak.pemotonganDilakukanOleh ==
                  "Penjual"
              ) {
                newObj.tipeBebanPajak = "Pajak Dibayar Dimuka";
              }
              if (
                pengenaanPajaks.posisiAkun == "Penjual" &&
                newObj.kodeObjekPajak.pengenaanPajak.pemotonganDilakukanOleh ==
                  "Pembeli"
              ) {
                newObj.tipeBebanPajak = "Pajak Dibayar Dimuka";
              }
              if (
                pengenaanPajaks.posisiAkun == "Penjual" &&
                newObj.kodeObjekPajak.pengenaanPajak.pemotonganDilakukanOleh ==
                  "Penjual"
              ) {
                newObj.tipeBebanPajak = "Pajak Terutang";
              }
            }
          }
        }

        if (newObj.posId) {
          if (
            (key == "dpp" ||
              key == "kodeObjekPajak__id" ||
              key == "jenisDpp") &&
            newObj.kodeObjekPajak
          ) {
            if (
              newObj.kodeObjekPajak.tipePajak &&
              newObj.kodeObjekPajak.tipePajak.kode == "PPN"
            ) {
              if (key == "dpp") newObj.dpp = value;
              if (!newObj.dpp) newObj.dpp = 0;
              if (sipStore.periode) {
                var tahunAwal = parseInt(sipStore.periode.dari.split("-")[0]);
                var tahunAkhir = parseInt(sipStore.periode.ke.split("-")[0]);

                if (newObj.kodeObjekPajak.tarifs.length > 0) {
                  var tarif = newObj.kodeObjekPajak.tarifs.filter(
                    (d) =>
                      tahunAwal >= d.tahunMulaiBerlaku &&
                      tahunAkhir <= d.tahunAkhirBerlaku
                  )[0];
                  console.log(tarif, "tarif");
                  if (tarif) {
                    if (
                      tarif.tarifNaikTanpaNpwp &&
                      (curTransaksi.transaksiLawanTransaksi.npwp ==
                        "000000000000000" ||
                        curTransaksi.transaksiLawanTransaksi.npwp == "")
                    ) {
                      newObj.tarif = tarif.tarifTanpaNpwp;
                      newObj.ppn =
                        (parseFloat(value) * tarif.tarifTanpaNpwp) / 100;
                    } else {
                      newObj.tarif = tarif.tarifPajak;
                      newObj.ppn = (parseFloat(value) * tarif.tarifPajak) / 100;
                    }
                  }
                }
              }
            }
          }
        }

        if (
          (key == "dpp" || key == "kodeObjekPajak__id" || key == "jenisDpp") &&
          newObj.kodeObjekPajak
        ) {
          if (
            newObj.kodeObjekPajak.tipePajak &&
            newObj.kodeObjekPajak.tipePajak.kode != "PPN"
          ) {
            if (key == "dpp") newObj.dpp = value;
            if (!newObj.dpp) newObj.dpp = 0;

            if (sipStore.periode) {
              var tahunAwal = parseInt(sipStore.periode.dari.split("-")[0]);
              var tahunAkhir = parseInt(sipStore.periode.ke.split("-")[0]);
              if (
                newObj.kodeObjekPajak.tarifs &&
                newObj.kodeObjekPajak.tarifs.length > 0
              ) {
                var tarif = newObj.kodeObjekPajak.tarifs.filter(
                  (d) =>
                    tahunAwal >= d.tahunMulaiBerlaku &&
                    tahunAkhir <= d.tahunAkhirBerlaku
                )[0];
                if (tarif == null)
                  tarif = {
                    tarifTanpaNpwp: 0,
                    tarifPajak: 0,
                  };

                if (tarif) {
                  console.log(
                    curTransaksi.transaksiLawanTransaksi,
                    "curTransaksi.transaksiLawanTransaksi"
                  );
                  if (
                    tarif.tarifNaikTanpaNpwp &&
                    (curTransaksi.transaksiLawanTransaksi.npwp ==
                      "000000000000000" ||
                      curTransaksi.transaksiLawanTransaksi.npwp == "")
                  ) {
                    newObj.tarif = tarif.tarifTanpaNpwp;
                    newObj.pph =
                      (parseFloat(newObj.dpp) * tarif.tarifTanpaNpwp) / 100;
                  } else {
                    newObj.tarif = tarif.tarifPajak;
                    newObj.pph = (parseFloat(newObj.dpp) * tarif.tarifPajak) / 100;
                  }
                }
              }
            }
          }
        }

        if (key == "posId") {
          curTransaksiCek = transaksiItemMap[value] ? transaksiItemMap[value] : { items: [] }
          setCurTransaksiItem(
            transaksiItemMap[value] ? transaksiItemMap[value] : { items: [] }
          );
          newObj.transaksiPajakTerutangItems = transaksiItemMap[value]
            ? transaksiItemMap[value].items.map((x) => ({
                item: x.item,
                item__id: x.item.id,
                nilai: x.hargaTotal,
              }))
            : [];
        }

        if (key == "bp__id") {
          var bp = bpsMap[value];
          if (bp) {
            if (bp.bpType == "F") {
              newObj.transaksiPajakTerutangPph21s[
                indexAtParent
              ].tanggalPemotongan = bp.slipGajiPegawai.pph21FinalId = bp.id;
              newObj.transaksiPajakTerutangPph21s[
                indexAtParent
              ].tanggalPemotongan = bp.slipGajiPegawai.pph21TidakFinalId = null;
            } else {
              newObj.transaksiPajakTerutangPph21s[
                indexAtParent
              ].tanggalPemotongan = bp.slipGajiPegawai.pph21FinalId = null;
              newObj.transaksiPajakTerutangPph21s[
                indexAtParent
              ].tanggalPemotongan = bp.slipGajiPegawai.pph21TidakFinalId =
                bp.id;
            }
            newObj.transaksiPajakTerutangPph21s[
              indexAtParent
            ].tanggalPemotongan = bp.slipGajiPegawai.tanggalPemotong;
            newObj.transaksiPajakTerutangPph21s[indexAtParent].nomor = "";
            newObj.transaksiPajakTerutangPph21s[indexAtParent].bruto = bp.bruto;
            newObj.transaksiPajakTerutangPph21s[indexAtParent].kredit =
              bp.pph;
            newObj.pph = newObj.pph + bp.pph
          }
        }
        setCurData(newObj);
      }}
      definitions={[
        // =====
        {
          inputType: inputTypes.DIVIDER,
          label: "# AKUN TRANSAKSI",
          show: (d) => !match.params.pembelianId,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Akun",
          key: "jurnal__id",
          type: "text",
          width: "49%",
          required: true,
          // validation: 'required',
          options: akunList,
          style: { marginRight: "2%" },
          show: (d) => !match.params.pembelianId,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai",
          key: "jurnal__nilai",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          // validation: 'required|numeric',
          show: (d) => !match.params.pembelianId,
        },

        // ====

        {
          inputType: inputTypes.DIVIDER,
          label: "# BARANG/JASA",
          show: (d) => match.params.pembelianId,
        },
        {
          inputType: inputTypes.SELECT,
          label: "Barang/JASA",
          key: "item__id",
          type: "text",
          width: "49%",
          required: true,
          // validation: 'required',
          options: itemPembelian,
          style: { marginRight: "2%" },
          show: (d) => match.params.pembelianId,
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nilai",
          key: "item__dpp",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          disabled: true,
          // validation: 'required|numeric',
          show: (d) => match.params.pembelianId,
        },

        {
          inputType: inputTypes.DIVIDER,
          label: "# PAJAK TERUTANG",
        },

        {
          inputType: inputTypes.REACT_SELECT,
          label: "Kode Objek Pajak",
          key: "kodeObjekPajak__id",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          required: true,
          validation: "required",
          options: kodeObjekPajakList,
        },
        {
          inputType: inputTypes.REACT_SELECT,
          label: "Tipa Pajak",
          key: "tipeBebanPajak",
          type: "text",
          width: "49%",
          required: true,
          validation: "required",
          options: [
            { label: "Pajak Terutang", value: "Pajak Terutang" },
            { label: "Pajak Dibayar Dimuka", value: "Pajak Dibayar Dimuka" },
          ],
        },
        {
          inputType: inputTypes.SELECT,
          label: "Terutang Saat",
          key: "terutangSaat",
          type: "text",
          width: "49%",
          style: { marginRight: "2%" },
          required: true,
          validation: "required",
          options: [
            {
              value: "Terutang saat disediakan untuk pembayaran",
              label: "Terutang saat disediakan untuk pembayaran",
            },
            { value: "Pembayaran", label: "Terutang saat pembayaran" },
            { value: "Jatuh tempo", label: "Terutang saat jatuh tempo" },
          ],
        },
        {
          inputType: inputTypes.DATEPICKER,
          label: "Tanggal Jatuh Tempo",
          key: "tanggalJatuhTempo",
          width: "49%",
          show: (d) => d.terutangSaat == "Jatuh tempo",
          validation: [{ required_if: ["terutangSaat", "Jatuh tempo"] }],
        },
        {
          inputType: inputTypes.SELECT,
          label: "Dasar Pengenaan Pajak",
          key: "jenisDpp",
          type: "text",
          width: "49%",
          required: true,
          validation: "required",
          style: { marginRight: "2%" },
          options: [
            { value: "Harga Jual", label: "Harga Jual" },
            { value: "Nilai Lain", label: "Nilai Lain" },
          ],
        },

        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Nominal DPP",
          key: "dpp",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          validation: "required|numeric",
          disabled: isDisabled,
          show: (d) =>
            d.kodeObjekPajak &&
            d.kodeObjekPajak.tipePajak &&
            d.kodeObjekPajak.tipePajak.kode === "PPN",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "Bruto",
          key: "dpp",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          validation: "required|numeric",
          disabled: isDisabled,
          show: (d) =>
            d.kodeObjekPajak &&
            d.kodeObjekPajak.tipePajak &&
            d.kodeObjekPajak.tipePajak.kode !== "PPN",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "PPh",
          key: "pph",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          validation: "required|numeric",
          style: { marginRight: "2%" },
          disabled: true,
          show: (d) =>
            d.kodeObjekPajak &&
            d.kodeObjekPajak.tipePajak &&
            d.kodeObjekPajak.tipePajak.kode != "PPN",
        },
        {
          inputType: inputTypes.INPUT_MASK_NUMBER,
          label: "PPN",
          key: "ppn",
          // theme: 'filled',
          thousandSeparator: ".",
          decimalSeparator: ",",
          isNumericString: true,
          width: "49%",
          style: { marginRight: "2%" },
          disabled: true,
          validation: [{ required_if: ["kodeObjekPajak", "PPN"] }],
          show: (d) =>
            d.kodeObjekPajak &&
            d.kodeObjekPajak.tipePajak &&
            d.kodeObjekPajak.tipePajak.kode == "PPN",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: "Dipotong/dipungut",
          key: "dipungut",
          type: "text",
          width: "49%",
          // required: true,
          // validation: 'required',
          options: akunList,
          // style: {marginRight: '2%'}/
        },
        {
          inputType: inputTypes.INPUT,
          label: "Alasan",
          key: "alasan",
          type: "text",
          width: "100%",
          show: (d) => d.dipungut == false,
        },
        {
          inputType: FormWrapper.inputTypes.LIST,
          label: "Pilih Pemotongan PPh21",
          key: "transaksiPajakTerutangPph21s",
          show: (d) => {
            return (
              d.kodeObjekPajak &&
              d.kodeObjekPajak.tipePajak &&
              d.kodeObjekPajak.tipePajak.id == TP_PAJAK.pph21
            );
          },
          definitions: [
            {
              inputType: inputTypes.REACT_SELECT,
              label: "Pilih BP",
              key: "bp__id",
              width: "100%",
              required: true,
              validation: "required",
              options: dataBps,
            },
            {
              inputType: FormWrapper.inputTypes.DATEPICKER,
              label: "Tanggal Pemotongan",
              key: "tanggalPemotongan",
              theme: "filled",
              validation: "required",
              disabled: true
            },
            {
              inputType: FormWrapper.inputTypes.INPUT,
              label: "Nomor BP",
              key: "nomor",
              theme: "filled",
              validation: "required",
              disabled: true,
            },
            {
              inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
              label: "Bruto",
              key: "bruto",
              validation: "required|numeric",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              disabled: true,
            },
            {
              inputType: FormWrapper.inputTypes.INPUT_MASK_NUMBER,
              label: "PPh",
              key: "kredit",
              validation: "required|numeric",
              theme: "filled",
              thousandSeparator: ".",
              decimalSeparator: ",",
              isNumericString: true,
              validation: "required|numeric",
              disabled: true,
            },
          ],
        },
        {
          inputType: inputTypes.DIVIDER,
          label: "# DATA POS",
        },
        // {
        //   inputType: inputTypes.SELECT,
        //   label: 'Pilih Data POS',
        //   key: 'posId',
        //   type: 'text',
        //   width: '100%',
        //   // required: true,
        //   // validation: 'required',
        //   options: transaksiItemList
        // },
        {
          render: () => {
            if (curData.posId) {
              var label = "PPN";
              if (
                curData.kodeObjekPajak &&
                curData.kodeObjekPajak.tipePajak &&
                curData.kodeObjekPajak.tipePajak.kode != "PPN"
              ) {
                label = "PPh";
              }
              return (
                <div style={{ width: "100%", overflow: "auto" }}>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Nama</TableCell>
                        <TableCell>Harga Satuan</TableCell>
                        <TableCell>QTY</TableCell>
                        <TableCell>Diskon</TableCell>
                        <TableCell>Harga Total</TableCell>
                        <TableCell>DPP</TableCell>
                        <TableCell>{label}</TableCell>
                        <TableCell>Tarif PpnBm</TableCell>
                        <TableCell>PpnBm</TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {curTransaksiItem.items
                        .filter((x) => {
                          if (
                            curData.kodeObjekPajak &&
                            curData.kodeObjekPajak.tipePajak &&
                            curData.kodeObjekPajak.tipePajak.kode == "PPN"
                          ) {
                            if (!x.kopPPn) x.kopPPn = {};
                            return x.kopPPn.id == curData.kodeObjekPajak.id;
                          } else {
                            if (!x.kopPPh) x.kopPPh = {};
                            return x.kopPPh.id == curData.kodeObjekPajak.id;
                          }
                        })
                        .map((x, i) => {
                          return (
                            <TableRow>
                              <TableCell>{x.nama}</TableCell>
                              <TableCell>
                                {Utils.numberFormat(x.hargaSatuan)}
                              </TableCell>
                              <TableCell>{x.qty}</TableCell>
                              <TableCell>{x.diskon}</TableCell>
                              <TableCell>
                                {Utils.numberFormat(x.hargaTotal)}
                              </TableCell>
                              <TableCell>{Utils.numberFormat(x.dpp)}</TableCell>
                              <TableCell>
                                {Utils.numberFormat(
                                  (x.dpp * curData.tarif) / 100
                                )}
                              </TableCell>
                              <TableCell>{x.tarifPpnBm}</TableCell>
                              <TableCell>
                                {Utils.numberFormat(x.ppnBm)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
              );
            } else {
              return null;
            }
          },
        },
        // {
        //   inputType: FormWrapper.inputTypes.LIST,
        //   label: 'Data POS',
        //   key: 'transaksiPajakTerutangItems',
        //   show: (d)=> {
        //     return transaksi.penjualan
        //   },
        //   definitions: [
        //     {
        //       inputType: FormWrapper.inputTypes.SELECT,
        //       label: 'Barang/Jasa',
        //       key: 'item__id',
        //       theme: 'filled',
        //       style: {width: 500},
        //       validation: 'required',
        //       options: itemList
        //     },
        //     {
        //       inputType: inputTypes.INPUT_MASK_NUMBER,
        //       label: 'Total',
        //       key: 'nilai',
        //       // theme: 'filled',
        //       thousandSeparator: '.',
        //       decimalSeparator: ',',
        //       isNumericString: true,
        //       width: '100%',
        //       // disabled: true,
        //       validation: 'required|numeric'
        //     },
        //   ]
        // }
        // -----
      ]}
      onSubmit={async (data, callback) => {
        var res = {};
        try {
          if (match.params.transaksiId) {
            data.transaksi = {
              id: match.params.transaksiId,
            };
            data.transaksiId = match.params.transaksiId;
          }

          if (match.params.pembelianId) {
            data.pembelian = { id: match.params.pembelianId };
          }

          if (match.params.posId) {
            data.pos = { id: match.params.posId };
          }

          data = Utils.formNormalize(data);
          if (data.transaksiPajakTerutangItems) {
            data.transaksiPajakTerutangItems =
              data.transaksiPajakTerutangItems.map((x) =>
                Utils.formNormalize(x)
              );
          }
          // data.transaksiPajakTerutangAkun.akun = {
          //   id: data.transaksiPajakTerutangAkun__akunid
          // }
          console.log(data, "data");
          if (match.params.id == "baru") {
            res = await service.post(data);
          } else {
            res = await service.put(data);
          }
          callback(t.translate("global.dataTelahDiSimpan"), false);

          if (match.params.transaksiId) {
            history.push(
              "/onpremise/transaksi/" +
                match.params.transaksiId +
                "/pajak-terutang"
            );
          } else if (match.params.pembelianId) {
            history.push(
              "/onpremise/transaksi-pembelian/" +
                match.params.pembelianId +
                "/pajak-terutang"
            );
          } else if (match.params.posId) {
            history.push(
              "/onpremise/transaksi-penjualan/" +
                match.params.posId +
                "/pajak-terutang"
            );
          }
        } catch (e) {
          callback(e, true, false);
        }
      }}
    />
  );
};

export default inject(
  "envStore",
  "sipStore"
)(observer(TransaksiPajakTerutangForm));
